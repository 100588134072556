import {
  UPDATE_SEARCH_TYPE,
  UPDATE_SEARCH_RESULT,
  UPDATE_SEARCH_DETAILS,
  UPDATE_SEARCH_DETAILS_LIST,
  SEARCH_FETCH_SUCCESS,
  SEARCH_FETCH_ERROR,
  CLEAR_SEARCH_RESULT,
  SHOW_SEARCH_ERROR_MESSAGE,
  SEARCH_RECRUITMENT_USER,
  SET_SEARCH_LOADING,
  SET_SEARCH_LOADING_FALSE,
  SET_PREFETCH_SEARCH_LOADING,
  SUCCESS_PREFETCH_SEARCH,
  SUCCESS_PREFETCH_SEARCH_LIST,
  ERROR_PREFETCH_SEARCH,
  ERROR_PREFETCH_SEARCH_LIST,
  SEARCH_MORE_SUCCESS,
  SET_SEARCH_CHECKED_UPDATED_LIST_RESULT,
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  message: '',
  messageType: '', // success, error, info
  searchType: SEARCH_RECRUITMENT_USER, // current selected search type
  searchResult: [], // [{id, selected: , identity:{}, companyRecord: {}}, ..]]
  searchDetails: {}, // { searchType: '', isSearchGoingOn: ,payloadForm: {}, isEndOfRecord: true/false , isPrefetchGoingOn: true/false }
  searchDetailsList: [], // [{ searchType: '', isSearchGoingOn: ,payloadForm: {}, isEndOfRecord: true/false , isPrefetchGoingOn: true/false }]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_TYPE:
      return { ...state, searchType: action.payload };

    case UPDATE_SEARCH_RESULT: {
      let resList = [...state.searchResult, ...action.payload];
      return { ...state, searchResult: resList };
    }

    case UPDATE_SEARCH_DETAILS:
      return { ...state, searchDetails: { ...state.searchDetails, ...action.payload } };

    case UPDATE_SEARCH_DETAILS_LIST:
      let { idx, details } = action.payload;
      let list = state.searchDetailsList;
      list[idx] = details;
      return {
        ...state,
        searchDetailsList: list,
      };

    case SET_SEARCH_LOADING:
      return { ...state, loader: true };
    
    case SET_SEARCH_LOADING_FALSE:
      return { ...state, loader: false };

    case SEARCH_FETCH_SUCCESS: {
      let resList = [...state.searchResult, ...action.payload.searchResult];
      if (action.payload?.searchDetailsList?.length) {
        return {
          ...state,
          searchDetailsList: [...action.payload.searchDetailsList],
          searchResult: resList,
          loader: !resList.length,
        };
      }
      return {
        ...state,
        searchDetails: { ...action.payload.searchDetails },
        searchResult: resList,
        loader: false,
      };
    }

    case SEARCH_FETCH_ERROR:
      return { ...state, searchDetails: { ...action.payload }, searchResult: [], loader: false };

    case CLEAR_SEARCH_RESULT:
      return { ...state, searchDetailsList:[], searchDetails: {}, searchResult: [] };

    case SHOW_SEARCH_ERROR_MESSAGE:
      return { ...state, message: action.payload, messageType: 'error' };

    case SET_PREFETCH_SEARCH_LOADING:
      if (state.searchDetailsList.length)
        return {
          ...state,
          searchDetailsList: state.searchDetailsList.map(d => ({ ...d, isPrefetchGoingOn: true })),
        };
      return { ...state, searchDetails: { ...state.searchDetails, isPrefetchGoingOn: true } };

    case SUCCESS_PREFETCH_SEARCH:
      // let resList = [...state.searchResult, ...action.payload.searchResult ];
      return { ...state, searchDetails: { ...state.searchDetails, isPrefetchGoingOn: false } };

    case SUCCESS_PREFETCH_SEARCH_LIST:
      // let resList = [...state.searchResult, ...action.payload.searchResult ];
      let el = action.payload;
      let arr = state.searchDetailsList;
      arr[el]['isPrefetchGoingOn'] = false;
      return {
        ...state,
        searchDetailsList: arr,
      };

    case ERROR_PREFETCH_SEARCH:
      return {
        ...state,
        searchDetails: { ...state.searchDetails, isPrefetchGoingOn: false, isEndOfRecord: true },
      };
    
    case ERROR_PREFETCH_SEARCH_LIST:
      let searchList = state.searchDetailsList;
      searchList[action.payload.idx] = {
        ...searchList[action.payload.idx],
        isPrefetchGoingOn: false,
        isEndOfRecord: true,
      };
      return {
        ...state,
        searchDetailsList: searchList,
      };

    case SEARCH_MORE_SUCCESS: {
      let resList = [...state.searchResult, ...action.payload.searchResult];
      return {
        ...state,
        searchDetails: { ...action.payload.searchDetails },
        searchResult: resList,
        isPrefetchGoingOn: false,
      };
    }
    case SET_SEARCH_CHECKED_UPDATED_LIST_RESULT: {
      return {
        ...state,
        searchResult: [...action.payload],
      };
    }
    default:
      return state;
  }
};
