import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { useDispatch } from 'react-redux';
import { database } from '../firebase/firebase';
import {
  GET_CONTACTS_LIST,
  CREATE_CONTACT,
  MOVE_CONTACT,
  GET_LIST_NAMES,
  FETCH_ALL_CONTACT,

  /** */
  CREATE_CUSTOM_LIST,
  VIEW_CUSTOM_LIST,
  SAVE_TO_CUSTOM_LIST,
  GET_LIST_NAME_BY_USER,
  UPDATE_LIST_SUB,
  GET_DEFAULT_LIST,
  SET_DEFAULT_LIST,
  GET_LATEST_PEOPLE_N_RECORDS_FOR_USER,
  GET_LATEST_COMPANY_N_RECORDS_FOR_USER,
  GET_SPECIFIC_RECORDS_FOR_USER,
  MOVE_CONTACT_TO_LIST,
  MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS,
  MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS,
  DONE,
  GET_RE_ENRICH_1,
  UPDATE_PEOPLE_ENRICH_INFO,
  STARTED,
  SELECTED,
  GET_ENRICH_2_FROM_PEOPLE,
  REVIEW_THRESHOLD_ENRICH_COUNT,
  CHECK_UPDATE,
  FORCE_UPDATE,
  START_COMPANY_UPDATE,
} from 'constants/ActionTypes';

import { auth } from '../firebase/firebase';

import {
  baseurl,
  baseListUrl,
  contactsAPI,
  jobsRoute,
  createCustomListMethod,
  saveToCustomListMethod,
  viewCustomListMethod,
  getListNamesByUserMethod,
  updateListSubMethod,
  getLatestNRceordsForUserMethod,
  specificRecordsMethod,
  setDefaultListMethod,
  getDefaultListMethod,
  moveFromCustomListOneToAnotherMethod,
  crmBaseUrl,
  crmUserMainURL,
  crmCompanyMainURL,
} from 'util/urls';

import {
  contactRecordsModifyResult,
  enrichBusiness1,
  isEmpty,
  modifyEnrich1APIResponse,
  modifyEnrich2APIResponse,
  setEnrichmentPayload,
  getPeopleOrCompanyJSON,
  modifyPeopleJSON,
  enrichPersonal2,
} from '../util';

import {
  fetchContactsSuccess,
  showContactMessage,
  contactListSetResult,
  getListNamesSuccess,
  createContactSuccess,
  moveContactSuccess,
  createPeopleListSuccess,
  createCompanyListSuccess,
  viewPeopleListSuccess,
  viewCompanyListSuccess,
  saveToCompanyListNameSuccess,
  getPeopleListByUserSuccess,
  getCompanyListByUserSuccess,
  getDefListSuccess,
  updateListSuccess,
  setPeopleDefListSuccess,
  setCompanyDefListSuccess,
  getLatestPeopleNRecordsSuccess,
  getLatestCompanyNRecordsSuccess,
  getSpecificRecordsSuccess,
  createCustomListName,
  getListNameByUser,
  movePeopleContactToListSuccess,
  moveCompanyContactToListSuccess,
  updateSetDefaultListNameInPeopleList,
  updateSetDefaultListNameInCompanyhList,
  hideContactLoader,
  stopFetchingPeopleList,
  stopFetchingCompanyList,
  getReEnrich1Success,
  updatePeopleEnrichInfoSuccess,
  startContactLoader,
  getReEnrich1Failed,
  getEnrich2FromPeopleSuccess,
  getEnrich2FromPeopleFailed,
  showContactInfoMessage,
  checkUpdateSuccess,
  checkUpdateLoading,
  checkUpdateError,
  forceUpdateError,
  forceUpdateSuccess,
  hideCheckUpdateMatchList,
  startCompanyUpdate,
  companyUpdateLoading,
  companyUpdateSuccess,
} from 'actions/Contact';
import { getEnrichDetailsAPI_1, getEnrichDetailsAPI_2 } from './Jobs';
import { showCheckUpdateMatchList } from '../actions/Contact';

// const storeDispatch = useDispatch();

const getAuthToken = async () => await auth.currentUser.getIdToken();

const getPeopleListNames = state => state.contacts.peopleListNames;
const getCompanyListNames = state => state.contacts.companyListNames;

const getUserDetails = state => state.auth.userDetails;

const getDummyContacts = async () =>
  await database
    .ref('prod/contacts')
    .once('value')
    .then(snapshot => {
      const contacts = [];
      snapshot.forEach(rawData => {
        contacts.push(rawData.val());
      });
      return contacts;
    })
    .catch(error => error);

function* fetchContactRequest() {
  try {
    const fetchedContact = yield call(getDummyContacts);
    yield put(fetchContactsSuccess(fetchedContact));
  } catch (error) {
    yield put(showContactMessage(error));
  }
}

const getContactsListAPI = async (payload, token) => {
  try {
    return await fetch(baseurl + jobsRoute + contactsAPI, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

const getListNamesAPI = async (payload, token) => {
  try {
    return await fetch(baseurl + jobsRoute + contactsAPI, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    console.log('getListNamesAPI ==> ', error);
    return error;
  }
};

/**fire store list manager API's */
/**POST */
const contactsPostAPI = async (payload, url, token) => {
  try {
    return await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

const crmPostApi = async (payload, url, token) => {
  try {
    return await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(payload),
    }).then(async res => {
      return await res.json();
    });
  } catch (err) {
    return err;
  }
};

function* getContactListNames({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(getListNamesAPI, payload, token);
    if (response.statusCode == 200) {
      yield put(getListNamesSuccess(response.data));
    } else yield put(showContactMessage(response.message));
  } catch (error) {
    console.log('getContactListNames', error.message);
    yield put(showContactMessage(error.message));
  }
}

function* getContactsList({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(getContactsListAPI, payload, token);
    if (response.statusCode == 200) {
      yield put(contactListSetResult(response.data));
    } else yield put(showContactMessage(response.message));
  } catch (error) {
    yield put(showContactMessage(error));
  }
}

function* createNewContact({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(getListNamesAPI, payload, token);
    if (response.statusCode == 200) {
      yield put(createContactSuccess(response));
    } else yield put(showContactMessage(response.message));
  } catch (error) {
    yield put(showContactMessage(error));
  }
}

function* moveTheContact({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(getListNamesAPI, payload, token);
    if (response.statusCode == 200) {
      yield put(moveContactSuccess(response.message));
    } else yield put(showContactMessage(response.message));
  } catch (error) {
    yield put(showContactMessage(error));
  }
}

/**
 *
 *  set default list after creating the list
 *
 */
function* createListName({ payload }) {
  try {
    console.log('create List Name');
    const token = yield call(getAuthToken);

    const createAPIInput = { ...payload };
    delete createAPIInput.makeDefault;

    const response = yield call(
      contactsPostAPI,
      createAPIInput,
      baseListUrl + createCustomListMethod,
      token
    );
    if (!isEmpty(response) && !response.hasOwnProperty('Error')) {
      payload.cat == 'people'
        ? yield put(createPeopleListSuccess(response))
        : yield put(createCompanyListSuccess(response));

      if (payload.makeDefault == true) {
        let defPayload = {
          uid: payload.user_id,
          cat: payload.cat,
          lid: response.lid,
          // "lnm": payload.lnm
        };

        yield call(setDefList, { payload: defPayload });
      }
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

function* viewList({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(
      contactsPostAPI,
      payload,
      baseListUrl + viewCustomListMethod,
      token
    );
    if (response.hasOwnProperty('data')) {
      const userDetails = yield select(getUserDetails);

      let formattedDetails = contactRecordsModifyResult(
        payload.cat,
        response.data,
        'list',
        [],
        userDetails
      );

      payload.cat == 'people'
        ? yield put(viewPeopleListSuccess(formattedDetails))
        : yield put(viewCompanyListSuccess(formattedDetails));
    } else if (response.hasOwnProperty('Error'))
      payload.cat == 'people'
        ? yield put(stopFetchingPeopleList())
        : yield put(stopFetchingCompanyList());
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

function* saveToListName({ payload }) {
  try {
    const token = yield call(getAuthToken);

    //new list name
    if (payload[0].isNew == true) {
      delete payload[0].isNew;

      let input = {
        user_id: localStorage.getItem('user_id'),
        cat: payload[0].cat,
        lnm: payload[0].cat === 'people' ? payload[0].lidToName : payload[0].lnm,
        type: 'user',
      };

      const response = yield call(
        contactsPostAPI,
        input,
        baseListUrl + createCustomListMethod,
        token
      );
      if (!isEmpty(response) && !response.hasOwnProperty('Error')) {
        payload = payload.map(item => {
          item.lid = response.lid;
          return item;
        });
        payload[0].cat === 'people'
          ? yield put(createPeopleListSuccess(response))
          : yield put(createCompanyListSuccess(response));
      } else if (response.hasOwnProperty('Error')) {
        yield put(showContactMessage(response.Error.Msg));
        return;
      }
    }

    payload.map(item => delete item.isNew);

    const response = yield call(
      contactsPostAPI,
      payload,
      baseListUrl + saveToCustomListMethod,
      token
    );
    if (!isEmpty(response) && !response.hasOwnProperty('Error')) {
      yield put(saveToCompanyListNameSuccess(response));
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

/**get list names */
function* getListByUser({ payload }) {
  try {
    const token = yield call(getAuthToken);

    const response = yield call(
      contactsPostAPI,
      payload,
      baseListUrl + getListNamesByUserMethod,
      token
    );
    if (response.hasOwnProperty('data')) {
      payload.cat == 'people'
        ? yield put(getPeopleListByUserSuccess(response.data))
        : yield put(getCompanyListByUserSuccess(response.data));
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    console.log('get list by user error ', error.message);
    yield put(showContactMessage(error.message));
  }
}

//not using
function* updateList({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(contactsPostAPI, payload, baseListUrl + updateListSubMethod, token);
    if (response.statusCode == 200) {
      yield put(updateListSuccess(response));
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(showContactMessage(error));
  }
}

function* getDefList({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(
      contactsPostAPI,
      payload,
      baseListUrl + getDefaultListMethod,
      token
    );
    if (response.statusCode == 200) {
      yield put(getDefListSuccess(response));
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

function* setDefList({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(
      contactsPostAPI,
      payload,
      baseListUrl + setDefaultListMethod,
      token
    );

    console.log('setDefList response ', response);

    if (!isEmpty(response) && !response.hasOwnProperty('Error')) {
      payload.lnm = response.lnm;

      if (payload.cat == 'people') {
        let peopleListNames = yield select(getPeopleListNames);
        peopleListNames = peopleListNames.map(item => {
          if (item.lid == payload.lid) item.isDefault = true;
          else item.isDefault = false;
          return item;
        });
        console.log('peopleListNames ', peopleListNames);

        yield put(setPeopleDefListSuccess(peopleListNames));

        //update people enrch list and people JSON
        yield put(updateSetDefaultListNameInPeopleList(payload));
      } else if (payload.cat == 'company') {
        let companyListNames = yield select(getCompanyListNames);
        companyListNames = companyListNames.map(item => {
          if (item.lid == payload.lid) item.isDefault = true;
          else item.isDefault = false;
          return item;
        });
        console.log('companyListNames ', companyListNames);
        yield put(setCompanyDefListSuccess(companyListNames));

        //update company enrch list and company JSON
        yield put(updateSetDefaultListNameInCompanyhList(payload));
      }
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

function* getLatestNRecords({ payload }) {
  try {
    const token = yield call(getAuthToken);

    let peopleListNames = yield select(getPeopleListNames);
    let companyListNames = yield select(getCompanyListNames);

    if (
      (payload.cat == 'people' && peopleListNames.length == 0) ||
      (payload.cat == 'company' && companyListNames.length == 0)
    ) {
      let body = {
        uid: localStorage.getItem('user_id'),
        cat: payload.cat,
      };
      yield call(getListByUser, { payload: body });
    }

    const response = yield call(
      contactsPostAPI,
      payload,
      baseListUrl + getLatestNRceordsForUserMethod,
      token
    );

    if (response.hasOwnProperty('data')) {
      const userDetails = yield select(getUserDetails);

      let formattedDetails = contactRecordsModifyResult(
        payload.cat,
        response.data,
        'list',
        [],
        userDetails
      );

      payload.cat == 'people'
        ? yield put(getLatestPeopleNRecordsSuccess(formattedDetails))
        : yield put(getLatestCompanyNRecordsSuccess(formattedDetails));
    } else if (response.hasOwnProperty('Error'))
      payload.cat == 'people'
        ? yield put(stopFetchingPeopleList())
        : yield put(stopFetchingCompanyList());
    // yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

function* getSpecificRecords({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(
      contactsPostAPI,
      payload,
      baseListUrl + specificRecordsMethod,
      token
    );

    let records = [];
    if (response.hasOwnProperty('data')) {
      response.data.map(item => {
        if (item.hasOwnProperty('targetSet')) records.push(item.targetSet);
      });
      payload.cat == 'people'
        ? yield put(getSpecificRecordsSuccess(records))
        : yield put(getSpecificRecordsSuccess(records));
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

function* moveEachItem(payload, token) {
  const response = yield call(
    contactsPostAPI,
    payload,
    baseListUrl + moveFromCustomListOneToAnotherMethod,
    token
  );

  if (!isEmpty(response) && !response.hasOwnProperty('Error')) {
    // calls CONTACT reducer
    // calls job reducer

    if (payload.cat == 'people') yield put(movePeopleContactToListSuccess(response));
    else if (payload.cat == 'company') yield put(moveCompanyContactToListSuccess(response));
  } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
}

function* moveContactToListName({ payload }) {
  try {
    console.log('moveContactToListName payload ', payload);
    const token = yield call(getAuthToken);

    //new list name
    if (payload[0].isNew === true) {
      delete payload[0].isNew;

      let input = {
        user_id: localStorage.getItem('user_id'),
        cat: payload[0].cat,
        type: 'user',
        lnm: payload[0].lidToName,
      };

      const response = yield call(
        contactsPostAPI,
        input,
        baseListUrl + createCustomListMethod,
        token
      );
      if (!isEmpty(response) && !response.hasOwnProperty('Error')) {
        payload = payload.map(item => {
          item.lidTo = response.lid;
          return item;
        });
        payload[0].cat === 'people'
          ? yield put(createPeopleListSuccess(response))
          : yield put(createCompanyListSuccess(response));
      } else if (response.hasOwnProperty('Error')) {
        yield put(showContactMessage(response.Error.Msg));
        return;
      }
    }

    // yield payload.map(input => call(moveEachItem, input));

    let allResponses = yield Promise.all(
      payload.map(async (input, index) => {
        delete input.isNew;

        return await new Promise(res => {
          setTimeout(async () => {
            res(
              await contactsPostAPI(
                input,
                baseListUrl + moveFromCustomListOneToAnotherMethod,
                token
              )
            );
          }, index * 1000);
        });
      })
    );

    for (let i = 0; i < allResponses.length; i++) {
      let resp = allResponses[i];
      if (!isEmpty(resp) && !resp.hasOwnProperty('Error')) {
        if (resp.cat == 'people') yield put(movePeopleContactToListSuccess(resp));
        else if (resp.cat == 'company') yield put(moveCompanyContactToListSuccess(resp));
      } else if (resp.hasOwnProperty('Error')) yield put(showContactMessage(resp.Error.Msg));
      else if (resp.hasOwnProperty('error')) yield put(showContactMessage(resp.error.Reason));
    }
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

export function* fetchContactRequests() {
  takeEvery(FETCH_ALL_CONTACT, fetchContactRequest);
}

function* getReEnrich1Details({ payload }) {
  const idx = payload.id;

  payload.enrichmentDetails.isEnrichment_E1_GoingOn = SELECTED;
  yield put(getReEnrich1Success(payload));

  try {
    let reqPayloadFormat = setEnrichmentPayload([payload], enrichBusiness1.pid);

    payload.enrichmentDetails.isEnrichment_E1_GoingOn = STARTED;
    yield put(getReEnrich1Success(payload));

    const token = yield call(getAuthToken);
    let response = yield getEnrichDetailsAPI_1(reqPayloadFormat[0], token);

    if (response.statusCode === 200) {
      const responseData = response.data?.data;
      let formattedDetails = modifyEnrich1APIResponse(responseData, idx);
      yield put(getReEnrich1Success(formattedDetails));
    } else {
      console.log('re enrichment1 error', response);
      payload.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;
      yield put(getReEnrich1Failed(payload));
      yield put(showContactMessage(response.message));
    }
  } catch (error) {
    console.log('re enrichment1 error', error.message);
    payload.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;
    yield put(getReEnrich1Failed(payload));
    yield put(showContactMessage(error.message));
  }
}

function* getEnrichDetails({ payload }) {
  const idx = payload.id;

  yield put(getEnrich2FromPeopleSuccess(payload));

  try {
    let reqPayloadFormat = setEnrichmentPayload([payload], enrichPersonal2.pid);

    if (payload.enrichmentDetails.isEnrichment_E1_GoingOn === SELECTED) {
      payload.enrichmentDetails.isEnrichment_E1_GoingOn = STARTED;
    }

    if (payload.enrichmentDetails.isEnrichment_E2_GoingOn === SELECTED) {
      payload.enrichmentDetails.isEnrichment_E2_GoingOn = STARTED;
    }

    yield put(getEnrich2FromPeopleSuccess(payload));

    const token = yield call(getAuthToken);
    let response = yield getEnrichDetailsAPI_2(reqPayloadFormat[0], token);

    if (response.statusCode === 200) {
      const responseData = response.data?.data;
      let formattedDetails = modifyEnrich2APIResponse(responseData, idx);
      yield put(getEnrich2FromPeopleSuccess(formattedDetails));
    } else {
      console.log('people enrichment2 error', response);
      // payload.enrichmentDetails.reviewCount = REVIEW_THRESHOLD_ENRICH_COUNT;
      if (payload.enrichmentDetails.isEnrichment_E1_GoingOn === STARTED) {
        payload.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;
      }

      if (payload.enrichmentDetails.isEnrichment_E2_GoingOn === STARTED) {
        payload.enrichmentDetails.isEnrichment_E2_GoingOn = DONE;
      }
      yield put(getEnrich2FromPeopleFailed(payload));
      yield put(showContactMessage(response.message));
    }
  } catch (error) {
    console.log('people list enrichment2 error', error.message);
    // payload.enrichmentDetails.reviewCount = REVIEW_THRESHOLD_ENRICH_COUNT;
    if (payload.enrichmentDetails.isEnrichment_E1_GoingOn === STARTED) {
      payload.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;
    }

    if (payload.enrichmentDetails.isEnrichment_E2_GoingOn === STARTED) {
      payload.enrichmentDetails.isEnrichment_E2_GoingOn = DONE;
    }
    yield put(getReEnrich1Failed(payload));
    yield put(showContactMessage(error.message));
  }
}

function* updateProfileData({ payload }) {
  const idx = payload.id;
  const targetSet = getPeopleOrCompanyJSON(payload, 'people');
  const reqPayload = {
    ...payload.identity,
    username: undefined,
    targetSet,
  };
  yield put(startContactLoader());
  try {
    const token = yield call(getAuthToken);
    const response = yield call(
      contactsPostAPI,
      [reqPayload],
      baseListUrl + updateListSubMethod,
      token
    );

    let formattedDetails = modifyPeopleJSON(response[0], idx);
    yield put(updatePeopleEnrichInfoSuccess(formattedDetails));

    if (response.statusCode === 200) {
      let formattedDetails = modifyPeopleJSON(response[0], idx);
      yield put(updatePeopleEnrichInfoSuccess(formattedDetails));
    } else if (response.hasOwnProperty('Error')) yield put(showContactMessage(response.Error.Msg));
  } catch (error) {
    yield put(hideContactLoader());
    yield put(showContactMessage(error.message));
  }
}

function* checkUpdateCRM({ payload }) {
  const idx = payload.peopleRecord.id;
  const uid = payload.uid;
  const peopleRecordSet = getPeopleOrCompanyJSON(payload.peopleRecord, 'people');
  const reqPayload = {
    uid,
    peopleRecordSet,
  };
  const data = {
    idx,
    peopleRecordSet,
  };

  try {
    const token = yield call(getAuthToken);
    // start check update loading
    yield put(checkUpdateLoading(data));

    const response = yield call(
      crmPostApi,
      reqPayload,
      crmBaseUrl + crmUserMainURL + 'checkUpdate',
      token
    );
    if (response.hasOwnProperty('Error') || response.hasOwnProperty('error')) {
      yield put(showContactMessage(response['Error']?.Msg || response.error?.message));
      return;
    } else {
      let formattedData = modifyPeopleJSON(response[0], idx);
      const respSync = response[0].targetSet.identity?.sync;

      yield put(checkUpdateSuccess(formattedData));
      console.log(respSync);

      if (respSync.crm1.cStatus === 'SYNC') {
        yield put(showContactInfoMessage('Recored saved successfuly'));
      } else if (
        (respSync.crm1.cStatus === 'NT_SYNC' && respSync.crm1.cCode === 'SINGLE_MATCH_RETRY') ||
        respSync.crm1.cCode === 'MULTI_MATCH_RETRY'
      ) {
        yield put(
          showCheckUpdateMatchList({
            data: respSync.crm1.cData,
            peopleIdx: idx,
          })
        );
      }
      return;
    }
  } catch (error) {
    yield put(showContactMessage(error.message));
    yield put(checkUpdateError(data));
  }
}

function* forceUpdateCRM({ payload }) {
  const idx = payload.peopleRecord.id;
  const uid = payload.uid;
  const peopleRecordSet = getPeopleOrCompanyJSON(payload.peopleRecord, 'people');
  const reqPayload = {
    uid,
    peopleRecordSet,
    recordID: payload.recordID,
  };

  try {
    const token = yield call(getAuthToken);
    const response = yield call(
      crmPostApi,
      reqPayload,
      crmBaseUrl + crmUserMainURL + 'forceUpdate',
      token
    );
    if (response.hasOwnProperty('Error') || response.hasOwnProperty('error')) {
      yield put(showContactMessage(response['Error']?.Msg || response.error?.message));
      return;
    } else {
      let formattedData = modifyPeopleJSON(response[0], idx);
      const respSync = response[0].targetSet.identity?.sync;

      yield put(forceUpdateSuccess(formattedData));
      if (respSync.crm1.cStatus === 'SYNC') {
        yield put(showContactInfoMessage('Recored saved successfuly'));
        yield put(hideCheckUpdateMatchList());
      }
      return;
    }
  } catch (error) {
    yield put(showContactMessage(error.message));
    yield put(hideCheckUpdateMatchList());
  }
}

function* saveOrUpdateCompanyCrm({ payload }) {
  const idx = payload.id;
  const reqPayload = {
    companyRecordSet: payload,
  };
  const data = {
    idx,
    companyRecordSet: payload,
  };
  try {
    const token = yield call(getAuthToken);
    yield put(companyUpdateLoading(data));
    const response = yield call(
      crmPostApi,
      reqPayload,
      crmBaseUrl + crmUserMainURL + 'saveOrUpdateCompanyCrm',
      token
    );
    yield put(companyUpdateSuccess(data));
  } catch (error) {
    yield put(showContactMessage(error.message));
  }
}

export function* getContacts() {
  yield takeEvery(GET_CONTACTS_LIST, getContactsList);
}

export function* getListNames() {
  yield takeEvery(GET_LIST_NAMES, getContactListNames);
}

export function* createContact() {
  yield takeEvery(CREATE_CONTACT, createNewContact);
}

export function* moveContact() {
  yield takeEvery(MOVE_CONTACT, moveTheContact);
}

/**fire store list contacts */

export function* createCustomList() {
  yield takeEvery(CREATE_CUSTOM_LIST, createListName);
}

export function* viewCustomPeopleORCompanyList() {
  yield takeEvery(VIEW_CUSTOM_LIST, viewList);
}

export function* saveToList() {
  yield takeEvery(SAVE_TO_CUSTOM_LIST, saveToListName);
}

export function* getListNamesByUser() {
  yield takeEvery(GET_LIST_NAME_BY_USER, getListByUser);
}

export function* updateListSub() {
  yield takeEvery(UPDATE_LIST_SUB, updateList);
}

export function* getDefaultListNames() {
  yield takeEvery(GET_DEFAULT_LIST, getDefList);
}

export function* setDefaultListNames() {
  yield takeEvery(SET_DEFAULT_LIST, setDefList);
}

export function* getLatestPeopleNRecordsForUser() {
  yield takeEvery(GET_LATEST_PEOPLE_N_RECORDS_FOR_USER, getLatestNRecords);
}

export function* getLatestCompanyNRecordsForUser() {
  yield takeEvery(GET_LATEST_COMPANY_N_RECORDS_FOR_USER, getLatestNRecords);
}

export function* getSpecificRecordsForUser() {
  yield takeEvery(GET_SPECIFIC_RECORDS_FOR_USER, getSpecificRecords);
}

export function* moveContactToList() {
  yield takeEvery(MOVE_CONTACT_TO_LIST, moveContactToListName);
}

export function* getReEnrich_1() {
  yield takeEvery(GET_RE_ENRICH_1, getReEnrich1Details);
}

export function* getEnrich_2() {
  yield takeEvery(GET_ENRICH_2_FROM_PEOPLE, getEnrichDetails);
}

export function* updateProfile() {
  yield takeEvery(UPDATE_PEOPLE_ENRICH_INFO, updateProfileData);
}

export function* checkUpdate() {
  yield takeEvery(CHECK_UPDATE, checkUpdateCRM);
}

export function* forceUpdate() {
  yield takeEvery(FORCE_UPDATE, forceUpdateCRM);
}

export function* saveOrUpdateCompany() {
  yield takeEvery(START_COMPANY_UPDATE, saveOrUpdateCompanyCrm);
}

export default function* rootSaga() {
  yield all([
    fork(fetchContactRequests),
    fork(getContacts),
    fork(getListNames),
    fork(createContact),
    fork(moveContact),

    /**People and company list manager */
    fork(createCustomList),
    fork(viewCustomPeopleORCompanyList),
    fork(saveToList),
    fork(getListNamesByUser),
    fork(updateListSub),
    fork(getDefaultListNames),
    fork(setDefaultListNames),
    fork(getLatestPeopleNRecordsForUser),
    fork(getLatestCompanyNRecordsForUser),
    fork(getSpecificRecordsForUser),
    fork(moveContactToList),

    fork(updateProfile),

    // Enrichment
    fork(getReEnrich_1),
    fork(getEnrich_2),

    // CRM
    fork(checkUpdate),
    fork(forceUpdate),
    fork(saveOrUpdateCompany),
  ]);
}
