import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SUBSCRIBE_PAYMENT } from 'constants/ActionTypes';
import { basePaymentUrl, subscribePayment } from 'util/urls';
import { auth } from '../firebase/firebase';

import { showAuthMessage } from 'actions/Auth';
import { subscribePaymentSuccess, paymentFailed } from 'actions/payment';

const getAuthToken = async () => await auth.currentUser.getIdToken();

const subscribePaymentAPI = async (tid, token) =>
  await fetch(basePaymentUrl + subscribePayment, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({ tid }),
  })
    .then(async response => {
      return await response.json();
    })
    .catch(err => err);

function* subscription({ tid }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(subscribePaymentAPI, tid, token);
    if (response.statusCode == 200) {
      yield put(subscribePaymentSuccess(response.data));
    } else {
      yield put(paymentFailed(response.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(SUBSCRIBE_PAYMENT, subscription)]);
}
