import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Contact from './Contact';
import Auth from './Auth';
import Payment from './Payment';
import Jobs from './Jobs';
import Search from './Search';
import { createBrowserHistory } from 'history';
import { SIGNOUT_USER_SUCCESS } from 'constants/ActionTypes';

const history = createBrowserHistory();
const appReducer = combineReducers({
  router: connectRouter(history),
  settings: Settings,
  contacts: Contact,
  auth: Auth,
  payment: Payment,
  jobs: Jobs,
  search: Search,
});

export default (state, action) => {
  if (action.type === SIGNOUT_USER_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};
