import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {
  makeStyles,
  CircularProgress,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from 'actions/Auth';
import AlertUi from './Msg-UI/AlertUi';
import './authStylings.css';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '450px 1fr',
    minHeight: '80vh',
    backgroundColor: '#ffff',
    margin: '50px',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(196, 196, 196, 0.12)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '60px 60px 40px',
  },
  right: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#fff',
  },
}));

const SignIn = props => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPass, setShowPass] = useState('');
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  useEffect(() => {
    // if (showMessage) {
    //   // setTimeout(() => {
    //   dispatch(hideMessage());
    //   // }, 100);
    // }
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [authUser, props.history, dispatch]);

  return (
    <div className='auth-card'>
      <div className={classes.main}>
        <div className={classes.left + ' animated slideInUpTiny animation-duration-3'}>
          {/* Header */}
          <div style={{ marginBottom: '100px' }}>
            <h1
              style={{
                color: '#222F3C',
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              <IntlMessages id='appModule.signin' />
            </h1>
            <p style={{ fontSize: '14px' }}>Enter to continue and explore within</p>
          </div>
          {/* Form */}
          <div style={{ marginTop: '-200px' }}>
            {showMessage && (
              <AlertUi className='m-3' variant='error'>
                {alertMessage}
              </AlertUi>
            )}
            <form>
              <fieldset>
                <TextField
                  label={<IntlMessages id='signIn.email' />}
                  fullWidth
                  onChange={event => setEmail(event.target.value)}
                  defaultValue={email}
                  margin='normal'
                  className='mt-1 my-sm-3'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {' '}
                        <img src={require('assets/images/user-icon.svg')} alt='user' />
                      </InputAdornment>
                    ),
                  }}
                  required
                />
                <TextField
                  type={showPass ? 'text' : 'password'}
                  label={<IntlMessages id='appModule.password' />}
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                  margin='normal'
                  className='mt-1 my-sm-3'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          style={{ padding: '0px', opacity: '0.6' }}
                          aria-label='toggle password visibility'
                          onClick={handleClickShowPassword}
                        >
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
                <div className='my-3 d-flex align-items-center justify-content-between'>
                  <Button
                    onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userSignIn({ email, password }));
                    }}
                    variant='contained'
                    color='primary'
                    style={{ textTransform: 'capitalize', width: '50%' }}
                  >
                    {loader ? (
                      <CircularProgress
                        color='inherit'
                        size={'0.875rem'}
                        style={{ margin: '5px 0px' }}
                      />
                    ) : (
                      <IntlMessages id='appModule.signIn' />
                    )}
                  </Button>
                </div>
                <p>
                  <Link to='/forgotpassword'>
                    <IntlMessages id='signIn.forgotPassword' />
                  </Link>
                </p>
              </fieldset>
            </form>
          </div>
          {/* Foot */}
          <div>
            <h5>
              <Link to='/signup'>
                <IntlMessages id='signIn.signUp' />
              </Link>
            </h5>
          </div>
        </div>
        <div className={classes.right}>
          {/* <h2
            style={{
              color: "#717171",
              fontSize: "22px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Discover Accounts, Score and Qualify <br /> leads with us
          </h2> */}
          {/* <img width='70%' src={require('assets/images/conveyer-belt.png')} alt='conveyer' /> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
