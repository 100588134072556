import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField, Paper, Button, Box, CircularProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { hideMessage, showAuthMessage } from 'actions/Auth';
import { getAuthToken } from 'sagas/Auth';
import { auxBaseUrl } from 'util/urls';

const useStyles = makeStyles(theme => ({
  root: {
    height: '65vh',
    width: '55%',
    padding: theme.spacing(3, 4),
    margin: '3rem auto',
    background: theme.palette.background.default,
  },
  headerText: {
    marginBottom: '2rem',
  },
}));

export default function Help() {
  const classes = useStyles();
  const { alertMessage, showMessage, infoMessage, userDetails } = useSelector(({ auth }) => auth);
  const storeDispatch = useDispatch();

  const [formState, setFormState] = useState({
    subject: '',
    departmentId: '734879000000006907',
    contact: {
      email: '',
    },
    email: '',
    description: '',
  });
  const [loaderState, setLoaderState] = useState('form');

  const handleOnChange = ({ target: { name, value } }) => {
    if (name === 'email') {
      setFormState({
        ...setFormState,
        email: value,
        contact: { ...setFormState.contact, email: value },
      });
    }
    if (name === 'subject') {
      setFormState({ ...formState, subject: value });
    }
    if (name === 'description') {
      setFormState({ ...formState, description: value });
    }
  };

  useEffect(() => {
    if (userDetails) {
      setFormState({
        ...formState,
        email: userDetails.eml,
        contact: {
          ...formState.contact,
          email: userDetails.eml,
          firstName: userDetails.fn,
          lastName: userDetails.ln,
        },
      });
    }
  }, [userDetails]);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        storeDispatch(hideMessage());
      }, 2000);
    }
  }, [showMessage]);

  const formSubmitHandler = async e => {
    try {
      e.preventDefault();
      e.stopPropagation();
      let fields = [];
      const token = await getAuthToken();
      if (!formState.email) fields.push('Email');
      if (!formState.subject) fields.push('Subject');
      if (!formState.description) fields.push('Description');

      if (fields.length) {
        storeDispatch(showAuthMessage(`${fields.join(',')} fields are required.`));
        fields = [];
      } else {
        setLoaderState('loading');
        const response = await fetch(auxBaseUrl + 'support/createTicket', {
          method: 'POST',
          headers: new Headers({
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          }),
          body: JSON.stringify(formState),
        });
        if (response.error) {
          setLoaderState('error');
        } else {
          setLoaderState('success');
        }
      }
      // storeDispatch(showAuthMessage('Bruh'));
      console.log(formState);
    } catch (error) {
      console.log(error);
      setLoaderState('error');
    }
  };

  return (
    <div>
      <Paper className={classes.root}>
        {/* FORM */}
        {loaderState === 'form' && (
          <div>
            <div className={classes.headerText}>
              <h2>Hey this is Anthony from support 👋</h2>
              <h2>Shoot your problem and team with turn around within 24 Hours</h2>
            </div>
            <form>
              <Grid container spacing={4}>
                {showMessage && (
                  <Grid item xs={12}>
                    <Collapse in={showMessage}>
                      <Alert severity={infoMessage !== '' ? 'success' : 'error'} icon={false}>
                        {infoMessage !== '' ? infoMessage : alertMessage}
                      </Alert>
                    </Collapse>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='Your Email'
                    name='email'
                    type='email'
                    variant='outlined'
                    onChange={handleOnChange}
                    value={formState.email}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label='One line description of the issue'
                    variant='outlined'
                    name='subject'
                    onChange={handleOnChange}
                    value={formState.subject}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id='outlined-multiline-static'
                    label='Specific details of the issue'
                    multiline
                    rows={6}
                    variant='outlined'
                    name='description'
                    onChange={handleOnChange}
                    value={formState.description}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign='right'>
                    <Button color='primary' onClick={e => formSubmitHandler(e)} variant='contained'>
                      Create Ticket
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </div>
        )}
        {loaderState === 'success' && (
          <Box
            height={'100%'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              <h3>Thanks</h3>
              <h3>Our support team will turn around withing 24 hours</h3>
            </div>
          </Box>
        )}
        {loaderState === 'error' && (
          <Box
            height={'100%'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              <h3>Oops! Something went wrong.</h3>
              <h3>You can email us the issue at</h3>
              <h3>
                <a href='mailto:support@glowradiusmail.com'>support@glowradiusmail.com</a>
              </h3>
            </div>
          </Box>
        )}
        {loaderState === 'loading' && (
          <Box
            height={'100%'}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div>
              <CircularProgress />
            </div>
          </Box>
        )}
      </Paper>
    </div>
  );
}
