import React, { useEffect, useState } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const filter = createFilterOptions();

const AutoCompleteList = props => {
  const [listNames, setListNames] = useState([]);
  const { enabled, selected, dropDownSelected, dropDownNewItemCreated, width, data } = props;

  const [autocomplete, setAutoComplete] = useState(null);

  useEffect(() => {
    setListNames(data);
  }, [data]);

  useEffect(() => {
    setAutoComplete(selected ? selected.lnm : null);
  }, [selected]);

  /** */
  const onSearchChange = (event, newValue) => {
    //selected item
    if (typeof newValue === 'string') {
      setAutoComplete({
        lnm: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setAutoComplete({
        lnm: newValue.inputValue,
      });

      console.log('new item created ', newValue.inputValue);

      if (dropDownNewItemCreated) dropDownNewItemCreated(newValue.inputValue);

      // setCreateListButtons(true);
      // setMoveButtons(false);
    } else if (newValue) {
      //selected from dropdown
      setAutoComplete(newValue);

      console.log('existing item selected  ', newValue);

      if (dropDownSelected) dropDownSelected(newValue);
      // setMoveButtons(true);
      // setCreateListButtons(false);
    } else {
      //clear
      setAutoComplete(newValue);
      // setMoveButtons(false);
      // setCreateListButtons(false);
    }
  };

  // for every key up when keyword not availabe it will suggest user to create it
  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (
      params.inputValue !== '' &&
      listNames.filter(item => item.lnm == params.inputValue).length == 0
    ) {
      // setMoveButtons(false);
      // setCreateListButtons(false);
      filtered.push({
        inputValue: params.inputValue,
        lid: '',
        lnm: `Create a new list :  "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = option => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.lnm;
  };

  return (
    <Autocomplete
      value={autocomplete || ''}
      onChange={onSearchChange}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id='listNamesFilterAutocomplete'
      options={listNames}
      getOptionLabel={getOptionLabel}
      // getOptionDisabled={option => option.lid.indexOf(selected.lid) > -1}
      renderOption={option => option.lnm}
      style={{ width: width || 300 }}
      size='small'
      freeSolo
      renderInput={params => (
        <TextField {...params} label='Add to list' variant='outlined' size='small' />
      )}
      disabled={!enabled}
    />
  );
};

export default AutoCompleteList;
