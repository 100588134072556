import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CardBox from 'components/CardBox';
import { TextField } from '@material-ui/core';
import { addUserDetails } from 'actions/Auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from 'components/IconButton';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
}));

const UserProfile = props => {
  const initialState = {
    fn: { value: '', required: true, message: 'first name is required' },
    ln: { value: '', required: true, message: 'last name is required' },
    cntry: { value: '', required: false },
    cn: { value: '', required: true, message: 'company name is required' },
    cty: { value: '', required: false },
    des: { value: '', required: false },
    ph: { value: '', required: false },
    phCnExt: { value: '', required: false },
  };
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const { loader, initURL, userDetailsFailed } = useSelector(({ auth }) => auth);
  const classes = useStyles();

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      default:
        throw new Error();
    }
  }

  const handleOnChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  /**
   * Error message automatically disappears after 100ms
   */
  useEffect(() => {
    if (showMessage)
      // setTimeout(() => {
      setShowMessage(false);
    // }, 100);
  }, [showMessage]);

  /*  useEffect(() => {
        if (initURL == '/app/home' &&  !userDetailsFailed)
            props.history.push('/app/home');
    }, [initURL]) */

  const validate = () => {
    let inputAPI = {};
    for (var element in state) {
      if (state[element].required && state[element].value == '') {
        setMessage(state[element].message);
        setShowMessage(true);
        return false;
      }
      if (state[element].value != '') inputAPI[element] = state[element].value;
    }

    return inputAPI;
  };

  const handleProfileSubmit = () => {
    let inputObj = validate();
    if (inputObj != false) {
      inputObj.rol = 'Admin'; //role
      console.log(JSON.stringify(inputObj));
      storeDispatch(addUserDetails(inputObj));
    }
  };

  return (
    <div className='app-wrapper animated slideInUpTiny animation-duration-3'>
      <div className='row mb-md-3'>
        <CardBox styleName='col-lg-12'>
          <p className='jr-fs-xl font-weight-light'>Add Profile</p>

          <form className='row' noValidate autoComplete='off'>
            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='firstname'
                label='First Name'
                name='fn'
                margin='normal'
                helperText='mandatory field'
                onChange={handleOnChange}
                fullWidth
              />
            </div>
            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='lastname'
                label='Last Name'
                name='ln'
                margin='normal'
                helperText=''
                onChange={handleOnChange}
                fullWidth
              />
            </div>
            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='companyname'
                label='Company Name'
                name='cn'
                margin='normal'
                helperText='mandatory field'
                onChange={handleOnChange}
                fullWidth
              />
            </div>
            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='city'
                label='City Name'
                name='cty'
                margin='normal'
                helperText=''
                onChange={handleOnChange}
                fullWidth
              />
            </div>
            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='countryname'
                label='Country Name'
                name='cntry'
                margin='normal'
                helperText=''
                onChange={handleOnChange}
                fullWidth
              />
            </div>

            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='contactnumber'
                label='Contact Number'
                name='ph'
                margin='normal'
                helperText=''
                onChange={handleOnChange}
                type='number'
                fullWidth
              />
            </div>

            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='countryCode'
                label='Country code'
                name='phCnExt'
                margin='normal'
                helperText=''
                onChange={handleOnChange}
                type='number'
                fullWidth
              />
            </div>

            <div className='col-xs-12 col-lg-6'>
              <TextField
                id='designation'
                label='Designation'
                name='des'
                margin='normal'
                helperText=''
                onChange={handleOnChange}
                type='text'
                fullWidth
              />
            </div>

            <div className='col-12 jr-btn-group d-flex justify-content-end mt-2'>
              <IconButton
                variant='contained'
                color='primary'
                size='large'
                onClick={handleProfileSubmit}
              >
                <span>Submit</span>
              </IconButton>
            </div>
          </form>
        </CardBox>
      </div>

      <Backdrop className={classes.backdrop} open={loader}>
        <div className='fixContainer'>
          <div className='loader-view'>
            <CircularProgress />
          </div>
        </div>
      </Backdrop>

      {showMessage && message != '' && NotificationManager.error(message)}
      <NotificationContainer />
    </div>
  );
};

export default UserProfile;
