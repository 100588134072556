import React from 'react';
import PropTypes from 'prop-types';
import { IconButton as MuiIconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import parse from 'html-react-parser';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100vh',
    minWidth: 270,
    maxWidth: 350,
    color: theme.palette.text.primary,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2),

    '& > button': {
      position: 'absolute',
      right: '0',
      top: '0',
      transform: `translate(-20%, 50%)`,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  content: {
    padding: theme.spacing(2, 3),
    minHeight: '100%',
  },
  noteDiv: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const NotesPopupPanel = props => {
  const {
    open,
    setOpenNotesPanel,
    data: { notes },
  } = props;
  const classes = useStyles();

  const handleClose = () => setOpenNotesPanel(false);

  const parseNoteMsg = text => {
    try {
      let obj = text;
      return obj.html ? parse(obj.html) : 'No Notes';
    } catch (err) {
      return '';
    }
  };

  return (
    <Slide in={open} direction='left' className={classes.root} onClick={e => e.stopPropagation()}>
      <div className={classes.paper}>
        <div className={classes.header}>
          <Typography variant='h5' className={classes.fontBold}>
            Notes & Score
          </Typography>
          <MuiIconButton onClick={handleClose} size='small'>
            <CloseIcon />
          </MuiIconButton>
        </div>
        <div className={classes.content}>
          {/* <Typography variant='body1' className={classes.fontBold} gutterBottom>
            {title}
          </Typography> */}

          {/* {notes && notes.length === 0 && (
            <Typography variant='caption' gutterBottom>
              No notes to show.
            </Typography>
          )} */}

          {/* {
            notes &&
            notes.map((note, idx) => {
              let htmlStr = parseNoteMsg(note.msg);
              return (
                <div key={idx} className={classes.noteDiv}>
                  <Typography variant='subtitle2' gutterBottom>
                    {htmlStr}
                  </Typography>
                  <Typography variant='caption' color='textSecondary'>
                    {moment(note.date).format('MMM DD YYYY') === 'Jan 20 1970'
                      ? moment(note.date * 1000).format('MMM DD YYYY')
                      : moment(note.date).format('MMM DD YYYY')}
                  </Typography>
                </div>
              );
            })} */}

          {notes &&
            notes.map(({ msg, title }, idx) => {
              if (title === 'Personalization Notes') {
                return (
                  <div key={idx} className={classes.noteDiv}>
                    <Typography variant='body1' className={classes.fontBold} gutterBottom>
                      {title}
                    </Typography>
                    {!msg?.length
                      ? 'No notes'
                      : msg.map((m, i) => (
                          <div key={i}>
                            <Typography variant='caption' color='textSecondary'>
                              {!parseNoteMsg({ html: m.msg.html })
                                ? 'No notes to show'
                                : parseNoteMsg({ html: m.msg.html })}
                            </Typography>
                            <Typography variant='caption' color='textSecondary'>
                              {new Date(m.date).toLocaleString('en')}
                            </Typography>
                            {i < msg.length - 1 && (
                              <hr
                                style={{ width: '20%', textAlign: 'center', margin: '15px auto' }}
                              />
                            )}
                          </div>
                        ))}
                  </div>
                );
              }
              if (title === 'Account Notes') {
                return (
                  <div key={idx} className={classes.noteDiv}>
                    <Typography variant='body1' className={classes.fontBold} gutterBottom>
                      {title}
                    </Typography>
                    <Typography variant='caption' color='textSecondary'>
                      {!parseNoteMsg({ html: msg })
                        ? 'No notes to show'
                        : parseNoteMsg({ html: msg })}
                    </Typography>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </Slide>
  );
};

NotesPopupPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenNotesPanel: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

NotesPopupPanel.defaultProps = {
  title: 'Personalization Notes',
  notes: [
    {
      msg:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley ",
      date: '12 Nov 2021',
    },
    {
      msg:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley ",
      date: '12 Nov 2021',
    },
    {
      msg:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley ",
      date: '12 Nov 2021',
    },
  ],
};

export default NotesPopupPanel;
