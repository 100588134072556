import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import {
  Divider,
  FormControl,
  Grid,
  IconButton as MuiIconButton,
  InputBase,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from 'components/IconButton';
import { useDispatch } from 'react-redux';
import { SELECTED } from 'constants/ActionTypes';
import { getReEnrich1ResultFromAPI, startEnrichment1Fetching } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: 450,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[8],
  },
  header: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2, 3),

    '& > button': {
      position: 'absolute',
      right: '0',
      top: '0',
      transform: `translate(-40%, 50%)`,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  content: {
    padding: theme.spacing(2, 3),
    minHeight: '100%',
  },
  divider: {
    margin: theme.spacing(0, 0, 1, 0),
    backgroundColor: theme.palette.grey[400],
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const FindEmailFormPopup = props => {
  const { open, setOpenFindEmailForm, profileData, isEnrichment1Fetching } = props;
  const classes = useStyles();
  const storeDispatch = useDispatch();

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    domain: '',
  });

  useEffect(() => {
    if (open && profileData) {
      setValues({
        ...values,
        firstName: profileData?.contactRecord?.firstName,
        lastName: profileData?.contactRecord?.lastName,
        domain: profileData?.contactRecord?.website.domain,
      });
    }
  }, [open]);

  const handleClose = () => setOpenFindEmailForm(false);

  const handleOnChange = e =>
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });

  const handleSubmit = e => {
    e.preventDefault();
    console.log('submitting form');
    storeDispatch(startEnrichment1Fetching());
    profileData.enrichmentDetails.isEnrichment_E1_GoingOn = SELECTED;
    storeDispatch(getReEnrich1ResultFromAPI(profileData));
    handleClose();
  };

  return (
    <Fade in={open} className={classes.root} onClick={e => e.stopPropagation()}>
      <div className={classes.paper}>
        <div className={classes.header}>
          <Typography variant='h5' className={classes.fontBold}>
            Find Email
          </Typography>
          <MuiIconButton onClick={handleClose} size='small'>
            <CloseIcon />
          </MuiIconButton>
        </div>
        <div className={classes.content}>
          <Typography variant='body2' gutterBottom>
            Before we search again, could you please review the details
          </Typography>
          <form className={classes.form}>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor='firstName' required>
                    First Name
                  </InputLabel>
                  <BootstrapInput
                    type='text'
                    id='firstName'
                    name='firstName'
                    onChange={handleOnChange}
                    margin='none'
                    value={values.firstName}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor='lastName' required>
                    Last Name
                  </InputLabel>
                  <BootstrapInput
                    type='text'
                    id='lastName'
                    name='lastName'
                    onChange={handleOnChange}
                    margin='none'
                    value={values.lastName}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl className={classes.formControl} required>
                  <InputLabel shrink htmlFor='domain'>
                    Domain
                  </InputLabel>
                  <BootstrapInput
                    type='text'
                    id='domain'
                    name='domain'
                    onChange={handleOnChange}
                    margin='none'
                    value={values.domain}
                    required
                  />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Typography variant='caption' gutterBottom component='p' color='error'>
              * Please fill up all the required fields
            </Typography>
          </form>
        </div>
        <Divider className={classes.divider} />

        <div className={classes.buttonWrapper}>
          <IconButton
            className='jr-btn'
            variant='contained'
            aria-label='cancel'
            onClick={handleClose}
          >
            <span>Cancel</span>
          </IconButton>
          <IconButton
            disabled={isEnrichment1Fetching ? true : false}
            className='jr-btn'
            variant='contained'
            aria-label='Find'
            color='primary'
            onClick={handleSubmit}
          >
            <span>{isEnrichment1Fetching ? 'Finding ...' : 'Find'}</span>
          </IconButton>
        </div>
      </div>
    </Fade>
  );
};

FindEmailFormPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenFindEmailForm: PropTypes.func.isRequired,
};

export default FindEmailFormPopup;
