import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import CustomScrollbars from 'util/CustomScrollbars';
import { TextField, Typography, Box, Divider } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import MoveContactsPopup from '../../../components/MoveContactsPopup';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '../../../components/IconButton';
import './style.css';

/** */
import TablePagination from '../../../components/tablePagination';

import {
  getSNPT2JobsPubSubSuccess,
  getEnrich2ResultFromAPI,
  submitPeoplesJobSuccess,
} from 'actions/jobs';

import {
  hideContactMessage,
  showContactInfoMessage,
  getLatestPeopleNRecords,
  moveContactToList,
  updatePoepleEnrich,
  getViewCustomList,
  getEnrich2FromPeople,
  checkUpdate,
  deletePeopleList,
} from 'actions/Contact';

import { getReEnrich1ResultFromAPI, startEnrichment1Fetching } from 'actions';

import { STARTED, SELECTED, DONE, NOTSTARTED } from 'constants/ActionTypes';
import {
  handleDownloadSearchCSV,
  getNumberOfPeopleLatestFetchRecords,
  peopleTableDisplayData,
} from '../../../util';
import PeopleResultListTable from 'components/PeopleResultListTable';
import EditPeopleDataModal from 'components/EditPeopleDataModal';
import NotesPopupPanel from 'components/NotesPopupPanel';
import FindEmailFormPopup from 'components/FindEmailFormPopup';
import CrmUserMatchPopup from 'components/CrmUserMatchPopup';
import DeleteConfirmModal from './DeleteConfirmModal';

const People = props => {
  const {
    contactsLoader,
    contactsShowMessage,
    contactsAlertMessage,
    contactsInfoMessage,
    peopleEnrichedList,
    peopleListNames,
    fetchingPeopleEnrichListOver,
    isEnrichment1Fetching,
    showCrmUserMatchList,
  } = useSelector(({ contacts }) => contacts);

  const { userDetails } = useSelector(({ auth }) => auth);
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    if (showCrmUserMatchList) {
      setShowPopup(true);
    } else {
      setShowPopup(false);
    }
  }, [showCrmUserMatchList]);

  const storeDispatch = useDispatch();
  const classes = useStyles();

  /**
   * State variables
   */
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [movepopupOpen, setMovepopupOpen] = useState(false);
  const [moreInfoList, setMoreInfoList] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [designation, setDesignation] = useState('');

  const [selectedPeopleData, setSelectedPeopleData] = useState(null);
  const [peopleRecordSet, setPeopleRecordSet] = useState({});
  const [openNotesPanel, setOpenNotesPanel] = useState(false);
  const [openFindEmailForm, setOpenFindEmailForm] = useState(false);
  const [notes, setNotes] = useState({
    notes: [],
    title: '',
    isPersonalNotes: false,
  });
  const [selectedFindAgainData, setSelectedFindAgainData] = useState({});

  /** */
  const showFilter = false;

  const [currentPageList, setCurrentPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // 10 per page
  const [allItemSelected, setAllItemSelected] = useState(false);
  const [enrichanchor, setEnrichanchor] = React.useState(null);
  const [selectedListId, setSelectedListId] = React.useState(null);
  const [currentPeopleListAPIPage, setCurrentPeopleListAPIPage] = useState(0); //starts from 0
  const [selectedListItem, setSelectedListItem] = useState(null);

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  // const [counter, setCounter] = useState(0);
  // const [expectedAPITime, setexpectedAPITime] = useState(10); // 30 seconds

  const getDefaultPeopleListName = () => peopleListNames.find(item => item.isDefault == true);

  useEffect(() => {
    if (peopleEnrichedList.length > 0 && peopleEnrichedList.length >= (currentPage - 1) * perPage) {
      let showList = peopleEnrichedList.slice((currentPage - 1) * perPage, currentPage * perPage);

      let finalList = peopleTableDisplayData('People', showList, allItemSelected, false);
      console.log('final list', finalList);
      setCurrentPageList(finalList);

      if (!fetchingPeopleEnrichListOver && currentPage > peopleEnrichedList.length / perPage) {
        //peopleEnrichedList related to the selected listname
        if (selectedListId) getViewList(selectedListId, currentPeopleListAPIPage + 1);
        else
          getLatestNRecords(getNumberOfPeopleLatestFetchRecords * (currentPeopleListAPIPage + 2)); //50 *

        setCurrentPeopleListAPIPage(currentPeopleListAPIPage + 1);
      }
    } else setCurrentPageList([]);
  }, [currentPage, peopleEnrichedList]);

  useEffect(() => {
    const listItem = peopleListNames.find(list => list.lid === selectedListId);
    setSelectedListItem(listItem);
  }, [selectedListId]);

  const handleGoPrevious = changePage => setCurrentPage(changePage);
  const handleGoNext = changePage => setCurrentPage(changePage);

  /**
   * select All
   */
  const onAllCheckChange = () => {
    let selctAllItems = false;

    let checkedList = checkHowManySelected();
    checkedList.length == 0
      ? (selctAllItems = true)
      : checkedList.length == currentPageList.length
      ? (selctAllItems = false)
      : (selctAllItems = true);

    let checkedItemList = currentPageList.map(item => {
      item.checked.value = selctAllItems;
      return item;
    });

    let list;

    //If all items need to select
    if (selctAllItems)
      list = peopleEnrichedList.map(job => {
        return {
          ...job,
          selected: true,
        };
      });
    else
      list = peopleEnrichedList.map(job => {
        return {
          ...job,
          selected: false,
        };
      });

    setAllItemSelected(selctAllItems);
    setCurrentPageList([...checkedItemList]);

    // peopleEnrichedList = [...list];

    storeDispatch(updatePoepleEnrich([...list]));

    /*   if (snSearch == "people")
              storeDispatch(setUserJobsSelectUpdated(jobDetails));
          else
              storeDispatch(setCompanyJobsSelectUpdated(jobDetails)); */
  };

  const checkHowManySelected = () => currentPageList.filter(item => item.checked.value);

  /**
   * each item click event
   * @param {*} clickedIndex
   */
  const onindividualCheckChange = clickedIndex => {
    let selectedIndex = (currentPage - 1) * perPage + clickedIndex;
    let changeList = currentPageList.map((item, i) => {
      if (i == clickedIndex) {
        item.checked.value = !item.checked.value;
        peopleEnrichedList[selectedIndex].selected = !peopleEnrichedList[selectedIndex].selected;

        storeDispatch(updatePoepleEnrich([...peopleEnrichedList]));
      }

      return item;
    });

    let selectedList = checkHowManySelected();
    if (selectedList.length == currentPageList.length) setAllItemSelected(true);
    else setAllItemSelected(false);

    setCurrentPageList([...changeList]);
  };

  const selectedjobs = () => peopleEnrichedList.filter(contact => contact.selected);

  const isReachedEndOfPage = () => {
    if (fetchingPeopleEnrichListOver && currentPage >= peopleEnrichedList.length / perPage)
      return true;

    return false;
  };

  const handleListNameSelectChange = event => {
    let selectedLid = event.target.value;
    setSelectedListId(selectedLid);
    setCurrentPeopleListAPIPage(0);
    setCurrentPageList([]);
    setCurrentPage(1);
    setAllItemSelected(false);

    if (selectedLid == 'none') {
      //load N records
      getLatestNRecords(getNumberOfPeopleLatestFetchRecords);
    } else {
      //filter
      getViewList(selectedLid, 0);
    }
  };

  const handleConfirmDelListName = () => {
    let selectedLid = peopleListNames.find(list => list.isDefault);
    setSelectedListId(selectedLid);
    setCurrentPeopleListAPIPage(0);
    setCurrentPageList([]);
    setCurrentPage(1);

    if (selectedLid === 'none') {
      //load N records
      getLatestNRecords(getNumberOfPeopleLatestFetchRecords);
    } else {
      //filter
      getViewList(selectedLid, 0);
      storeDispatch(deletePeopleList(selectedLid.lid));
    }
  };

  /*
    *
  call people list api
   hide message
    */
  useEffect(() => {
    getLatestNRecords(getNumberOfPeopleLatestFetchRecords);

    /* let unsubscribe = auth.onAuthStateChanged(async function (user) {
            if (user) {
                getLatestNRecords(getNumberOfPeopleLatestFetchRecords);
            }else{
                storeDispatch(userSignOut());
            }
        });
 */
    return () => {
      storeDispatch(hideContactMessage());
      /*  if(unsubscribe)
                unsubscribe(); */
    };
  }, []);

  useEffect(() => {
    setOpenFindEmailForm(false);
    if (contactsShowMessage)
      // setTimeout(() => {
      storeDispatch(hideContactMessage());
    // }, 100);
  }, [contactsShowMessage]);

  const getLatestNRecords = recordCount => {
    let payload = {
      uid: localStorage.getItem('user_id'),
      cat: 'people',
      recordCount: recordCount,
    };

    storeDispatch(getLatestPeopleNRecords(payload));
  };

  const getViewList = (lid, page) => {
    let payload = {
      uid: localStorage.getItem('user_id'),
      lid: lid,
      cat: 'people',
      status: 'done',
      page: page,
    };

    storeDispatch(getViewCustomList(payload));
  };

  const noResultFound = () => {
    if (peopleEnrichedList.length > 0) return false;

    return true;
  };

  const handleClose = () => {
    setEnrichanchor(null);
  };

  const open = Boolean(enrichanchor);
  const popoverId = open ? 'simple-popover' : undefined;

  const downloadFile = () => {
    handleDownloadSearchCSV(selectedjobs(), 'people');
  };

  const handleResultListItemClick = (infoRow, field) => {
    let type = infoRow[field]?.clickType || field;
    let selectedIndex = infoRow.id.value;
    let enrichDetails;

    switch (type) {
      case 'Enrich1': {
        let selectedContact = peopleEnrichedList[selectedIndex];
        enrichDetails = { ...selectedContact.enrichmentDetails };

        if (
          enrichDetails.isEnrichment_E1_GoingOn !== STARTED ||
          enrichDetails.isEnrichment_E1_GoingOn !== DONE
        ) {
          storeDispatch(startEnrichment1Fetching());
          enrichDetails.enrichmentType = 'E1';
          enrichDetails.isEnrichment_E1_GoingOn = SELECTED;

          selectedContact.enrichmentDetails = enrichDetails;
          storeDispatch(getReEnrich1ResultFromAPI(selectedContact));
        }
        break;
      }

      case 'Enrich2': {
        let selectedContact = peopleEnrichedList[selectedIndex];
        enrichDetails = { ...selectedContact.enrichmentDetails };
        // hit E2 only if it hasn't started or done
        if (
          enrichDetails.isEnrichment_E2_GoingOn !== STARTED ||
          enrichDetails.isEnrichment_E2_GoingOn !== DONE
        ) {
          enrichDetails.enrichmentType = 'E2';
          enrichDetails.isEnrichment_E2_GoingOn = SELECTED;
          if (
            enrichDetails.isEnrichment_E1_GoingOn === STARTED ||
            enrichDetails.isEnrichment_E1_GoingOn === DONE
          ) {
            enrichDetails.isOnlyE2Required = true;
          } else {
            enrichDetails.isEnrichment_E1_GoingOn = SELECTED;
          }
          selectedContact.enrichmentDetails = enrichDetails;
          storeDispatch(getEnrich2FromPeople(selectedContact));
        }
        break;
      }
      case 'edit': {
        const peopleIdx = infoRow.id.value;
        const peopleData = peopleEnrichedList.find(people => people.id == peopleIdx);
        setSelectedPeopleData(peopleData);
        break;
      }

      case 'notes': {
        const peopleData = peopleEnrichedList.find(people => people.id === selectedIndex);
        console.log(peopleData);
        setNotes({
          notes: [
            // {
            //   title: 'Title Notes',
            //   msg: peopleData?.customInfo?.titleScore.reason.html,
            // },
            {
              title: 'Personalization Notes',
              msg: peopleData.customInfo?.personalizationNotes || [],
            },
            {
              title: 'Account Notes',
              msg: peopleData?.customInfo?.accountScore.reason.html,
            },
          ],
          // isPersonalNotes: true,
        });
        setOpenNotesPanel(true);
        break;
      }

      case 'pNotes': {
        // show personal notes
        const peopleData = peopleEnrichedList.find(people => people.id === selectedIndex);
        setNotes({
          notes: [
            {
              title: 'Personalization Notes',
              msg: peopleData.customInfo?.personalizationNotes || [],
            },
            {
              title: 'Account Notes',
              msg: peopleData?.customInfo?.accountScore.reason.html,
            },
          ],
          // isPersonalNotes: true,
        });
        setOpenNotesPanel(true);
        break;
      }

      case 'findEmail': {
        const peopleData = peopleEnrichedList.find(people => people.id === selectedIndex);
        console.log(peopleData);
        setSelectedFindAgainData(peopleData);
        setOpenFindEmailForm(true);
        break;
      }

      case 'crm-save': {
        const peopleData = peopleEnrichedList.find(people => people.id === selectedIndex);
        console.log(peopleData);
        setPeopleRecordSet({
          uid: userDetails.uid,
          peopleData,
        });
        saveDataOnCrm(peopleData);
        break;
      }

      default:
        console.log('Click type is not found', type);
        break;
    }
  };

  const saveDataOnCrm = async peopleRecord => {
    const payload = {
      uid: userDetails.uid,
      peopleRecord,
    };
    storeDispatch(checkUpdate(payload));
  };

  const handleEnrich2 = () => {
    handleClose();
    let enrichHitId = 0;
    let selectedforEnrich = peopleEnrichedList.map(job => {
      if (
        currentPageList.findIndex(
          selectedJob =>
            selectedJob.checked.value &&
            job.id == selectedJob.id.value &&
            job.enrichmentDetails.isEnrichment_E2_GoingOn == NOTSTARTED
        ) > -1
      ) {
        job = { ...job };
        job.enrichmentDetails.enrichmentType = 'E2';

        job.id = enrichHitId;

        enrichHitId++;

        job.enrichmentDetails.isEnrichment_E2_GoingOn = SELECTED;

        //is E1 is going on or E1 is already done, we don't have to again call E1
        job.enrichmentDetails.isOnlyE2Required =
          job.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED ||
          job.enrichmentDetails.isEnrichment_E1_GoingOn == DONE;

        return job;
      }
    });

    selectedforEnrich = selectedforEnrich.filter(item => item != undefined);
    hitEnrichment(selectedforEnrich);
  };

  const handlecloseMoreInfoPopup = () => setOpenMoreInfo(!openMoreInfo);

  // const handleFind = event => {
  //   let selectedItems = checkHowManySelected();
  //   if (selectedItems.length == 0)
  //     storeDispatch(showContactMessage('Please select at least one contact'));
  //   else setEnrichanchor(event.currentTarget);
  // };

  /**More info */
  const copyData = copyObj => {
    navigator.clipboard.writeText(copyObj.value);
    storeDispatch(showContactInfoMessage('Copied!!'));
  };

  const contactNumbersMoreInfo = infoRow => {
    let list = infoRow['Personal Phone'].allValues;
    list = list.map((item, index) => {
      return { name: 'Phone ' + (index + 1), value: item.number };
    });
    setMoreInfoList(list);
  };

  const hitEnrichment = selectedforEnrich => {
    let snp_jobResult = {
      type: 'T1',
      mode: 'People',
      peopleSearch: {
        page: 1,
        maxPagesRead: 100,
        searchString: '',
      },
      errorTrace: {},
      peopleJSON: [],
    };

    console.log('selectedforEnrich', selectedforEnrich);

    //all list with started status
    if (selectedforEnrich.length > 0) {
      let searchDeatils = {
        searchJobId: selectedforEnrich[0].enrichmentDetails.enrichmentJobId,
        searchType: selectedforEnrich[0].identity.cat,
        taskName: selectedforEnrich[0].identity.cat,
        task: selectedforEnrich[0].identity.cat,
        isSearchGoingOn: false,
        searchIterationsMax: 1,
        searchIterationsDoneCount: 1,
        payloadForm: {},
        timeStamp: new Date(),
      };

      //add search details
      storeDispatch(submitPeoplesJobSuccess(searchDeatils));

      console.log('handleEnrich2', selectedforEnrich);

      //add people json
      snp_jobResult.peopleJSON = [selectedforEnrich];

      storeDispatch(getSNPT2JobsPubSubSuccess(snp_jobResult));

      props.history.push({
        pathname: '/app/home',
        state: { status: 'enrichStarted' },
      });

      storeDispatch(getEnrich2ResultFromAPI(selectedforEnrich));
    } else console.log('Enrichment is happend already on the selected items');
  };

  const moreInfo = (infoRow, field) => {
    setOpenMoreInfo(!openMoreInfo);
    setCompanyName(infoRow.Name.value);
    setDesignation(infoRow.Name.hintText);
    switch (field) {
      case 'Personal Phone':
        contactNumbersMoreInfo(infoRow);
        break;
      default:
        console.log('something went wrong');
        break;
    }
  };

  //multi move selcted
  const multiMoveToSelectedListName = listObj => {
    let payloadList = [];
    peopleEnrichedList.map(job => {
      if (
        currentPageList.findIndex(
          selectedJob =>
            selectedJob.checked.value &&
            job.id == selectedJob.id.value &&
            job.identity.recordId != ''
        ) > -1 &&
        job.identity.lid != listObj.lid
      ) {
        let payload = {
          recodId: job.identity.recordId,
          cat: job.identity.cat,
          lidFrom: job.identity.lid,
          lidTo: listObj.lid,
          lidToName: listObj.lnm,
          isNew: false,
        };
        payloadList.push(payload);
      }
    });

    console.log('multiMoveToSelectedListName ', payloadList);

    if (payloadList.length > 0) storeDispatch(moveContactToList(payloadList));

    setMovepopupOpen(false);
  };

  //multi move to new list name
  const multiMoveToNewListName = lnm => {
    let payloadList = [];
    peopleEnrichedList.map(job => {
      if (
        currentPageList.findIndex(
          selectedJob =>
            selectedJob.checked.value &&
            job.id == selectedJob.id.value &&
            job.identity.recordId != ''
        ) > -1
      ) {
        let payload = {
          recodId: job.identity.recordId,
          cat: job.identity.cat,
          lidFrom: job.identity.lid,
          lidTo: '',
          lidToName: lnm,
          isNew: true,
        };
        payloadList.push(payload);
      }

      return job;
    });

    console.log('multiMoveToNewListName ', payloadList);

    if (payloadList.length > 0) storeDispatch(moveContactToList(payloadList));

    setMovepopupOpen(false);
  };

  //move item
  //when selected another listitem from the list
  const handleListItemSelected = (row, selectedListObj) => {
    let selectedIndex = row.id.value;
    let record = peopleEnrichedList[selectedIndex];
    let identityRow = record.identity;

    console.log('selected row', record);
    console.log('selected list name', selectedListObj);

    let payload = {
      recodId: identityRow.recordId,
      cat: identityRow.cat,
      lidFrom: identityRow.lid,
      lidTo: selectedListObj.lid,
      lidToName: selectedListObj.lnm,
      isNew: false,
    };

    storeDispatch(moveContactToList([payload]));
  };

  //create and move
  const handleNewListItemCreated = (row, lnm) => {
    let selectedIndex = row.id.value;
    let record = peopleEnrichedList[selectedIndex];
    let identityRow = record.identity;
    console.log('selected row', record);
    console.log('selected list name', lnm);

    let payload = {
      recodId: identityRow.recordId,
      cat: identityRow.cat,
      lidFrom: identityRow.lid,
      lidTo: '',
      lidToName: lnm,
      isNew: true,
    };

    storeDispatch(moveContactToList([payload]));
  };

  return (
    <div className='app-wrapper'>
      <div className='app-module animated slideInUpTiny animation-duration-3'>
        <div className='module-box max-100p'>
          {selectedPeopleData ? (
            <>
              <EditPeopleDataModal
                peopleData={selectedPeopleData}
                setSelectedPeopleData={setSelectedPeopleData}
              />
            </>
          ) : (
            <>
              {/* <div className={classes.headerTextBox}> */}
              <div className='module-box_head-content mb-3'>
                <Typography variant='h6'>
                  <Box fontWeight='fontWeightBold'>Buying Committee</Box>
                </Typography>
                <Typography variant='body2' gutterBottom>
                  Collection of profiles that including decision makers, budget owners, champions or
                  influencers
                </Typography>
              </div>
              <div className='module-box_head-content mb-0'>
                {
                  <div className='d-flex mt-2 mr-3'>
                    <TextField
                      id='outlined-select-names-native'
                      select
                      label='Pick one stream'
                      value={selectedListId || ''}
                      onChange={handleListNameSelectChange}
                      variant='outlined'
                      className={classes.dropDown}
                      SelectProps={{
                        native: true,
                      }}
                      size='small'
                    >
                      <option key={0} value='none'>
                        Recently added prospects
                      </option>

                      {peopleListNames
                        .sort((a, b) =>
                          a.lnm.toLowerCase() < b.lnm.toLowerCase()
                            ? -1
                            : a.lnm.toLowerCase() > b.lnm.toLowerCase()
                            ? 1
                            : 0
                        )
                        .map(option => (
                          <option key={option.lid} value={option.lid}>
                            {option.lnm}
                          </option>
                        ))}
                    </TextField>
                    <div className='d-flex flex-grow-1 align-items-center justify-content-end'>
                      <div className='mr-2'>
                        {/* Not using this "Find Contacts" btn as of now
                                            <IconButton aria-describedby={popoverId} className="jr-btn" size="medium" variant="contained" color="primary" aria-label="add"
                                                onClick={handleFind} >
                                                <span>Find Contacts</span>
                                            </IconButton> */}
                        <Popover
                          id={popoverId}
                          open={open}
                          anchorEl={enrichanchor}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                          <Menu
                            id={popoverId}
                            anchorEl={enrichanchor}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                          >
                            {/* <MenuItem onClick={handleEnrich1}>Business Email</MenuItem> */}
                            <MenuItem onClick={handleEnrich2}>
                              Business Email and Personal Phone
                            </MenuItem>
                          </Menu>
                        </Popover>
                      </div>

                      <IconButton
                        className='jr-btn'
                        variant='contained'
                        color='primary'
                        onClick={() => setMovepopupOpen(!movepopupOpen)}
                        enable_icon={require('assets/images/multiple_stop-white-18dp.svg')}
                        disable_icon={require('assets/images/multiple_stop-black-18dp.svg')}
                        disabled={checkHowManySelected().length == 0}
                        aria-label='move'
                      >
                        <span>Move</span>
                      </IconButton>
                      <IconButton
                        className='jr-btn'
                        variant='contained'
                        color='primary'
                        aria-label='download file'
                        enable_icon={require('assets/images/cloud_download-white-18dp.svg')}
                        disable_icon={require('assets/images/cloud_download-black-18dp.svg')}
                        disabled={checkHowManySelected().length == 0}
                        onClick={downloadFile}
                      >
                        <span>Download</span>
                      </IconButton>
                      <IconButton
                        className='jr-btn'
                        variant='contained'
                        color='primary'
                        aria-label='delete list'
                        disabled={
                          selectedListItem === null ||
                          selectedListItem?.isDefault === true ||
                          selectedListId === null ||
                          selectedListId === 'none'
                        }
                        onClick={() => setIsOpenDeleteModal(true)}
                      >
                        <span>Delete List</span>
                      </IconButton>
                    </div>
                  </div>
                }
              </div>
              <div className='module-box-content module_table'>
                <div
                  className='keep-center'
                  style={{
                    height: props.width >= 1200 ? 'calc(100vh - 230px)' : 'calc(100vh - 230px)',
                  }}
                >
                  {/* {contactsLoader && peopleEnrichedList.length == 0 &&
                                <div className="flex-grow-1 space-progress max-400p text-center">
                                    <strong>Fetching records..</strong>
                                    <LinearProgress variant="determinate" value={(100 / expectedAPITime) * counter} />
                                    <strong className="mt-2">{timeLeftToLoadAPI()} </strong>
                                </div>
                            } */}

                  {!contactsLoader && noResultFound() && (
                    <strong> Sorry !! No results found.</strong>
                  )}

                  <>
                    {peopleEnrichedList.length > 0 && (
                      <div className='flex-grow-1'>
                        <div className='col-12 p-0 mt-5'>
                          <CustomScrollbars
                            className='module-list-scroll scrollbar'
                            style={{
                              height:
                                props.width >= 1200 ? 'calc(100vh - 250px)' : 'calc(100vh - 250px)',
                            }}
                          >
                            <>
                              {currentPageList.length > 0 && (
                                <PeopleResultListTable
                                  result={currentPageList}
                                  showFilter={showFilter}
                                  selectionAvailable={true}
                                  onAllCheckChange={onAllCheckChange}
                                  onindividualCheckChange={onindividualCheckChange}
                                  moreInfo={moreInfo}
                                  itemClick={handleResultListItemClick}
                                  autoCompleteData={peopleListNames}
                                  autoSelected={handleListItemSelected}
                                  autonewItemCreated={handleNewListItemCreated}
                                />
                              )}
                            </>
                          </CustomScrollbars>

                          <TablePagination
                            currentPage={currentPage}
                            isreachedEnd={isReachedEndOfPage}
                            handleGoPrevious={handleGoPrevious}
                            handleGoNext={handleGoNext}
                          />
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={openMoreInfo}>
        <div className='popup-content' style={{ overflowY: 'auto', padding: '0px' }}>
          <div
            className='d-flex justify-content-between align-items-start'
            style={{
              padding: '20px 15px 20px',
              backgroundColor: '#EEF0FF',
            }}
          >
            <div>
              <p
                className='m-0'
                style={{
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '700',
                  lineHeight: '25px',
                  color: '#484848',
                }}
              >
                {companyName}
              </p>
              <p
                className='m-0'
                style={{
                  color: '#646A71',
                  fontSize: '12px',
                  fontWeight: '400',
                }}
              >
                {designation}
              </p>
            </div>
            <div
              onClick={handlecloseMoreInfoPopup}
              style={{
                width: '24px',
                height: '24px',
                cursor: 'pointer',
              }}
            >
              <img src={require('assets/images/closeInfo.png')} alt='close-button' />
            </div>
          </div>
          <div>
            <hr className='m-0' />
            <div className='mt-2 mb-2' style={{ padding: '13px 15px 0px 15px' }}>
              {moreInfoList.map((item, i) => (
                <div key={i} className='d-flex justify-content-between align-items-center mb-3'>
                  <div>
                    <div
                      style={{
                        height: '20px',
                        width: '20px',
                        display: 'inline',
                        marginRight: '15px',
                      }}
                    >
                      <img src={require('assets/images/phoneicon.png')} alt='copy-button' />
                    </div>
                    <span
                      className='m-0'
                      style={{
                        color: '#717171',
                        fontSize: '14px',
                        fontWeight: '400',
                      }}
                    >
                      {item.value}
                    </span>
                  </div>
                  <div
                    style={{
                      width: '14px',
                      height: '14px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    className='font-weight-light text-grey m-0 pointer'
                    onClick={() => copyData(item)}
                  >
                    <img src={require('assets/images/copy2.png')} alt='copy-button' />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Backdrop>

      <Backdrop className={classes.backdrop} open={movepopupOpen}>
        <MoveContactsPopup
          open={movepopupOpen}
          data={peopleListNames}
          selected={getDefaultPeopleListName}
          closePopup={() => setMovepopupOpen(false)}
          moveToSelectedListName={multiMoveToSelectedListName}
          moveToNewListName={multiMoveToNewListName}
        />
      </Backdrop>

      <Backdrop
        className={classes.backdrop}
        open={openNotesPanel}
        onClick={() => setOpenNotesPanel(false)}
      >
        <NotesPopupPanel open={openNotesPanel} setOpenNotesPanel={setOpenNotesPanel} data={notes} />
      </Backdrop>

      <Backdrop
        className={classes.backdrop}
        open={openFindEmailForm}
        onClick={() => setOpenFindEmailForm(false)}
      >
        <FindEmailFormPopup
          open={openFindEmailForm}
          setOpenFindEmailForm={setOpenFindEmailForm}
          profileData={selectedFindAgainData}
          isEnrichment1Fetching={isEnrichment1Fetching}
        />
      </Backdrop>

      <Backdrop className={classes.backdrop} open={contactsLoader}>
        <div className='fixContainer'>
          <div className='loader-view'>
            <CircularProgress />
          </div>
        </div>
      </Backdrop>

      {showCrmUserMatchList !== -1 && (
        <Backdrop className={classes.backdrop} open={showCrmUserMatchList !== -1}>
          <CrmUserMatchPopup />
        </Backdrop>
      )}

      {contactsShowMessage != '' &&
        contactsAlertMessage != '' &&
        NotificationManager.error(contactsAlertMessage)}
      {contactsShowMessage &&
        contactsInfoMessage != '' &&
        NotificationManager.success(contactsInfoMessage)}
      <NotificationContainer />
      <DeleteConfirmModal
        listItem={selectedListItem}
        openModal={isOpenDeleteModal}
        closeModal={setIsOpenDeleteModal}
        uid={userDetails.uid}
        onConfirmDelete={handleConfirmDelListName}
      />
    </div>
  );
};

export default People;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  root: {
    flexGrow: 1,
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 600,
  },
  dropDown: {
    width: 250,
  },
  searchGif: {
    maxWidth: 70,
  },

  formPaper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 600,
  },
}));

function TabContainer(props) {
  return <div style={{ padding: 5 }}>{props.children}</div>;
}
