import React, { useEffect, useState } from 'react';
import AppLayouts from './AppLayouts';
import Routes from '../../app/routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRouteDetails,
  getUserLoginDetails,
  setFirebaseAuthStateLoaded,
  showAuthLoader,
  userSignOut,
} from 'actions';
import { auth } from '../../firebase/firebase';
import { makeStyles } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
}));

const AppLayout = props => {
  const horizontalNavPosition = useSelector(({ settings }) => settings.horizontalNavPosition);
  const navigationStyle = useSelector(({ settings }) => settings.navigationStyle);
  const {
    authUser,
    initURL,
    userDetails,
    companyDetails,
    loader,
    isFirebaseAuthStateLoaded,
    routeDetails
  } = useSelector(({ auth }) => auth);
  const storeDispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [showRoutes, setShowRoutes] = useState(false);
  const [tierRoutes, setTierRoutes] = useState(null)
  const [grace, setGrace] = useState(false);
  const [firebaseAuthEmailVerified, setFirebaseAuthEmailVerified] = useState(null);

  const onGetLayout = layout => {
    switch (layout) {
      case 'inside_the_header':
        return 'InsideHeaderNav';

      case 'above_the_header':
        return 'AboveHeaderNav';

      case 'below_the_header':
        return 'BelowHeaderNav';
      default:
        return 'Vertical';
    }
  };

  useEffect(() => {
    let unsubscribe;
    if (!sessionStorage.getItem('emailconfirmed')) {
      unsubscribe = auth.onAuthStateChanged(async function(user) {
        if (user) {
          console.log('firebase email verified applayout ' + user.emailVerified);
          //save email verification
          setFirebaseAuthEmailVerified(user.emailVerified);
          storeDispatch(setFirebaseAuthStateLoaded());
        } else storeDispatch(userSignOut());
      });
    } else setFirebaseAuthEmailVerified(true);

    return () => {
      sessionStorage.removeItem('emailconfirmed');
      if (unsubscribe) unsubscribe();
    };
  }, []);

  useEffect(() => {
    let unsubscribe;
    if (firebaseAuthEmailVerified !== null) {
      if (
        authUser &&
        (Object.keys(userDetails).length === 0 || Object.keys(companyDetails).length === 0)
      ) {
        // have the userId but not userDetails & company detials
        unsubscribe = auth.onAuthStateChanged(async user => {
          if (user) {
            storeDispatch(getRouteDetails());
            storeDispatch(setFirebaseAuthStateLoaded());
            storeDispatch(showAuthLoader());
            storeDispatch(
              getUserLoginDetails({
                firebaseEmailVerified: firebaseAuthEmailVerified,
              })
            );
          } else {
            storeDispatch(userSignOut());
          }
        });
      } else {
        // we have all user related details
        setShowRoutes(true);
      }
    }

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [initURL, props.history.location.pathname, props.location.search, firebaseAuthEmailVerified]);

  useEffect(() => {
    if (
      !loader &&
      Object.keys(userDetails).length !== 0 &&
      Object.keys(companyDetails).length !== 0 &&
      routeDetails &&
      routeDetails?.length
    ) {
      const tArr = routeDetails.filter(d => parseInt(d.tid) === companyDetails.tid)[0];
      setTierRoutes(tArr);
      setShowRoutes(true);
    }
    // userDetails.emailCheck decides whether ot not to check email verification
    if (firebaseAuthEmailVerified !== null) {
      const user = auth.currentUser;
      // * 1000ms * 60s * 60mins * 24 hrs * 3 days (easier to change later)
      const duration = 1000 * 60 * 60 * 24 * 3,
        tDiff = Date.now() - new Date(user.metadata.creationTime).getTime(),
        inGrace = tDiff < duration ? true : false;
      if (userDetails && userDetails.emailCheck && !userDetails.firebaseEmailVerified) {
        inGrace
          ? setGrace({
              creationTime: new Date(user.metadata.creationTime).getTime(),
              duration,
            })
          : history.push('/email-confirmation-check');
      }
    }
  }, [loader, userDetails, companyDetails, routeDetails]);

  const Layout =
    AppLayouts[
      navigationStyle === 'vertical_navigation' ? 'Vertical' : onGetLayout(horizontalNavPosition)
    ];
  return (
    <Layout>
      {grace && (
        <Alert severity='warning'>
          Hey! Please verify your Email. Your grace period will end on{' '}
          {new Date(grace.creationTime + grace.duration).toString()}
        </Alert>
      )}
      {isFirebaseAuthStateLoaded && showRoutes ? (
        <Routes tierRoutes={tierRoutes} />
      ) : (
        <Backdrop className={classes.backdrop} open={true}>
          <div className='fixContainer'>
            <div className='loader-view'>
              <CircularProgress />
            </div>
          </div>
        </Backdrop>
      )}
    </Layout>
  );
};

export default AppLayout;
