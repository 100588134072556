import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

import { crmBaseUrl, crmCompanyMainURL, crmUserMainURL } from 'util/urls';
import { withStyles } from '@material-ui/styles';
import { InputBase, Button } from '@material-ui/core';
import { getAuthToken } from 'sagas/Auth';
import CrmMapForm from 'components/CrmMapForm';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    // margin : 'auto'
  },

  form: {
    marginTop: theme.spacing(1),
    '& label': {
      color: '#000',
    },
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0),
    '& > div': {
      padding: 0,
    },
    '& > select:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  pageHeader: {
    margin: theme.spacing(2, 3),
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  headerText: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  pageStats: {
    display: 'flex',
  },
  statBox: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  statHeading: {
    fontWeight: 600,
  },
  statContent: {
    fontWeight: 600,
    fontSize: '2rem',
    color: '#000',
  },
  secondaryStatContent: {
    marginTop: theme.spacing(1),
    fontSize: '1rem',
    color: theme.palette.text.primary,
  },
  fontBold: {
    fontWeight: 600,
  },
  backLink: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const crmAPI = async (token, payload, url) => {
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  return await fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload),
  })
    .then(async response => {
      return await response.json();
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const EditCrmMapFields = props => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = React.useState(0);
  const [crmInitialPeopleMap, setCrmInitialPeopleMap] = useState([]);
  const [crmInitialCompanyMap, setCrmInitialCompanyMap] = useState([]);
  const [crmName, setCrmName] = useState('');
  const [peopleCrmFields, setPeopleCrmFields] = useState([]);
  const [companyCrmFields, setCompanyCrmFields] = useState([]);

  const { userDetails, companyDetails } = useSelector(({ auth }) => auth);

  useEffect(() => {
    getInitialDetails();
  }, []);

  const showInfoOrAlertMessage = (msg, alert = false) => {
    alert ? NotificationManager.error(msg) : NotificationManager.success(msg);
  };

  const getInitialDetails = async () => {
    const token = await getAuthToken();
    const { uid } = userDetails;
    let defaultFields;
    try {
      defaultFields = await crmAPI(
        token,
        { uid },
        crmBaseUrl + crmUserMainURL + 'getMapForSpecificUser'
      );
      if (defaultFields.error) {
        // show message
        showInfoOrAlertMessage(defaultFields.error.message || 'Sorry, something went wrong!', true);
        return;
      }
      setCrmInitialPeopleMap(defaultFields.peopleMap);
      setCrmInitialCompanyMap(defaultFields.companyMap);
      setCrmName(defaultFields.crmNm);
      let crmFields = await crmAPI(
        token,
        { uid },
        crmBaseUrl + crmUserMainURL + 'getCrmFieldsForUser'
      );
      if (crmFields.error) {
        // show message
        showInfoOrAlertMessage(defaultFields.error.message || 'Sorry, something went wrong!', true);
        return;
      }
      setPeopleCrmFields(crmFields.peopleFields);
      setCompanyCrmFields(crmFields.companyFields);
      setActiveStep(prev => prev + 1);
    } catch (err) {
      //show message
    } finally {
      setLoading(false);
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 0: {
        return loading ? 'Loading Map fields!!' : 'Sorry, something went wrong!';
      }
      case 1:
        return (
          <CrmMapForm
            crmName={crmName}
            crmInitialMap={crmInitialPeopleMap}
            crmFields={peopleCrmFields}
            handleOnChangeFieldBoolean={handleOnChangeFieldBoolean}
            handleOnChangeFieldName={handleOnChangeFieldName}
            type='peopleMap'
            disableCheckBox={userDetails.rol === 'User'}
          />
        );

      case 2:
        return (
          <CrmMapForm
            crmName={crmName}
            crmInitialMap={crmInitialCompanyMap}
            crmFields={companyCrmFields}
            handleOnChangeFieldBoolean={handleOnChangeFieldBoolean}
            handleOnChangeFieldName={handleOnChangeFieldName}
            type='companyMap'
            disableCheckBox={userDetails.rol === 'User'}
          />
        );

      case 3:
        return <h1>Done!!</h1>;

      default:
        return 'Default text';
    }
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleOnChangeFieldName = e => {
    let targetName = e.target.name;
    if (targetName.includes('peopleMap')) {
      let sysField = targetName.substr(10);
      let updatedPeopleMap = crmInitialPeopleMap.map(field => {
        if (field.sysFieldName === sysField) {
          return {
            ...field,
            crmFieldName: e.target.value,
          };
        }
        return field;
      });
      setCrmInitialPeopleMap(updatedPeopleMap);
    } else if (targetName.includes('companyMap')) {
      let sysField = targetName.substr(11);
      let updatedCompanyMap = crmInitialCompanyMap.map(field => {
        if (field.sysFieldName === sysField) {
          return {
            ...field,
            crmFieldName: e.target.value,
          };
        }
        return field;
      });
      setCrmInitialCompanyMap(updatedCompanyMap);
    }
  };

  const handleOnChangeFieldBoolean = e => {
    if (userDetails.rol.toLowerCase() !== 'admin') {
      return;
    }
    let targetName = e.target.name;
    if (targetName.includes('peopleMap')) {
      let sysField = targetName.substr(10);
      let updatedPeopleMap = crmInitialPeopleMap.map(field => {
        if (field.sysFieldName === sysField) {
          return {
            ...field,
            cOrgWise: !field.cOrgWise,
          };
        }
        return field;
      });
      setCrmInitialPeopleMap(updatedPeopleMap);
    } else if (targetName.includes('companyMap')) {
      let sysField = targetName.substr(11);
      let updatedCompanyMap = crmInitialCompanyMap.map(field => {
        if (field.sysFieldName === sysField) {
          return {
            ...field,
            cOrgWise: !field.cOrgWise,
          };
        }
        return field;
      });
      setCrmInitialCompanyMap(updatedCompanyMap);
    }
  };

  const saveData = async () => {
    const token = await getAuthToken();
    const { rol, uid } = userDetails;
    const { cid } = companyDetails;
    setLoading(true);
    if (rol === 'Admin') {
      let payload = { cid };
      const peopleMapRes = await crmAPI(
        token,
        { ...payload, map: crmInitialPeopleMap },
        crmBaseUrl + crmCompanyMainURL + 'updatePeopleMapGlobal'
      );
      const companyMapRes = await crmAPI(
        token,
        { ...payload, map: crmInitialCompanyMap },
        crmBaseUrl + crmCompanyMainURL + 'updateCompanyMapGlobal'
      );
      if (peopleMapRes.error || companyMapRes.error) {
        // show message
      }
      setLoading(false);
      setActiveStep(prev => prev + 1);
    } else {
      let payload = { uid };
      const peopleMapRes = await crmAPI(
        token,
        { ...payload, map: crmInitialPeopleMap },
        crmBaseUrl + crmUserMainURL + 'setPeopleMapForSpecificUser'
      );
      const companyMapRes = await crmAPI(
        token,
        { ...payload, map: crmInitialCompanyMap },
        crmBaseUrl + crmUserMainURL + 'setCompanyMapForSpecificUser'
      );
      if (peopleMapRes.error || companyMapRes.error) {
        // show message
      }
      setLoading(false);
      setActiveStep(prev => prev + 1);
    }
  };

  return (
    <div className='app-wrapper'>
      <div className='app-module animated slideInUpTiny animation-duration-3'>
        <div className='module-box max-100p'>
          <div className='module-box-content module_table'>
            <div className='keep-center'>Edit fields</div>
            {!loading && getStepContent(activeStep)}
            {activeStep !== 0 && (
              <div>
                <Button
                  disabled={activeStep === 1}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Back
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={activeStep === 2 ? saveData : handleNext}
                >
                  {activeStep === 2 ? 'Save' : 'Next'}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={loading}>
        <div className='fixContainer'>
          <div className='loader-view'>
            <CircularProgress />
          </div>
        </div>
      </Backdrop>
      <NotificationContainer />
    </div>
  );
};

export default EditCrmMapFields;
