import React, { useEffect, useMemo, useState } from 'react';

import useVisNetwork from './useVisNetwork';

const options = {
  nodes: {
    shape: 'dot',
    size: 10,
  },
  layout: {
    hierarchical: {
      enabled: false,
    },
  },
};

export default function VisualiseNetwork({ accountName = '', intel = '' }) {
  const [nodes, setNodes] = useState(null);
  const [edges, setEdges] = useState(null);
  const { nodeCount, filteredNodes, filteredEdges } = useMemo(
    () => generateNetwork(accountName, intel),
    [accountName, intel]
  );

  useEffect(() => {
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  }, [accountName, intel]);
  return nodes ? <VisualiseNetworkMain nodes={nodes} edges={edges} /> : '';
}

function VisualiseNetworkMain({ nodes, edges }) {
  const { ref, network } = useVisNetwork(
    useMemo(() => {
      return { options, nodes, edges };
    }, [])
  );

  useEffect(() => {
    if (!network) return;
  }, [network]);

  return (
    <>
      <div style={{ height: 600, width: 600 }} ref={ref} />
    </>
  );
}

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function generateNetwork(accountName = '', rawIntel = '') {
  // Extar Edges will be added for even nodes
  // Last node will circle back to node 2
  var outputGraph = {
    nodeCount: 0,
    nodes: [],
    edge: [],
  };
  var _ri = rawIntel;
  _ri = _ri.split(' ');
  _ri = shuffleArray(_ri);
  // Check minimum network size constraint
  if (_ri.length < 5) {
    return outputGraph;
  }
  var _nodes1 = [];
  _nodes1.push(accountName);
  var _nodes2 = _ri.filter(function(el) {
    if ((el != '') & (el != '**') & (el != '·') & (el != '-') & (el != '—')) {
      //console.log(' Value of EL: ', el);
      return el;
    }
  });
  var nodes = _nodes1.concat(_nodes2);
  var nodeCount = nodes.length;
  // Form the outputGraph
  outputGraph = {
    nodeCount: nodeCount,
    nodes: nodes,
    edge: [],
  };
  var _temp;
  var arrayConcat = [];
  for (let _i = 1; _i <= nodeCount; _i++) {
    var _proxy_i = _i.toString();
    var _proxy_i_plus1;
    var _proxy_i_plus2;
    var _proxy_i_plus3;
    // NON Last node
    if (_i < nodeCount) {
      _proxy_i_plus1 = (_i + 1).toString();
      _proxy_i_plus2 = (_i + 2).toString();
      _proxy_i_plus3 = (_i + 3).toString();
      // Add first edge
      _temp = _proxy_i + ',' + _proxy_i_plus1;
      arrayConcat.push(_temp);
      // Extar Edges will be added for even nodes
      if (_i % 2 == 0) {
        // Add second edge
        if (_i + 2 <= nodeCount) {
          _temp = _proxy_i + ',' + _proxy_i_plus2;
          arrayConcat.push(_temp);
        }
        // Add third edge
        if (_i + 3 <= nodeCount) {
          _temp = _proxy_i + ',' + _proxy_i_plus3;
          arrayConcat.push(_temp);
        }
      } else {
        // Odd nodes will be loop backs
        if (_i != 1) {
          if (_i < nodeCount / 2) {
            let _loopEnd = nodeCount - _i;
            _temp = _proxy_i + ',' + _loopEnd.toString();
            arrayConcat.push(_temp);
          }
        }
      }
    } else {
      //Last node.. circle back
      _proxy_i_plus1 = '2';
      _temp = _proxy_i + ',' + _proxy_i_plus1;
      arrayConcat.push(_temp);
    }
  }
  outputGraph.edge = arrayConcat;
  // Output
  const filteredNodes = outputGraph.nodes.map((node, index) => {
    return { id: index + 1, label: node, group: index + 1 };
  });
  const filteredEdges = outputGraph.edge.map((edge, index) => {
    const num = edge.split(',');
    return { from: Number(num[0]), to: Number(num[1]), id: index + 1 };
  });
  return { nodeCount, filteredNodes, filteredEdges };
}
