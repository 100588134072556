import React, { useState, useRef, useEffect } from 'react';
import IntlMessages from 'util/IntlMessages';
import SuccessUi from './Msg-UI/SuccessUi.jsx';
import { iamRoute, baseIAMUrl } from '../util/urls';
import { makeStyles, CircularProgress, Button, TextField, InputAdornment } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

import AlertUi from './Msg-UI/AlertUi';
import './authStylings.css';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '450px 1fr',
    minHeight: '80vh',
    backgroundColor: '#ffff',
    margin: '50px',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(196, 196, 196, 0.12)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '60px 60px 40px',
  },
  right: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#fff',
  },
}));

const Waitlist = props => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const captchaRef = useRef(null);

  const [loader, setLoader] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [completed, setCompleted] = useState('');

  const postEmail = async () => {
    const emailReg = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const tempId = Math.random()
      .toFixed(6)
      .split('.')[1];

    if (email === '') {
      setShowMessage(true);
      setAlertMessage('Please fill in the email!');
      return;
    }

    if (!emailReg.test(email)) {
      setShowMessage(true);
      setAlertMessage('Invalid Email!');
      return;
    }

    const recaptchaValue = captchaRef.current.getValue();

    if (!recaptchaValue) {
      setShowMessage(true);
      setAlertMessage('Please complete the captcha!');
      return;
    }

    const requestOptions = {
      method: 'POST',
    };

    setLoader(true);
    try {
      const captchaRes = await fetch(baseIAMUrl + iamRoute + 'verifycaptcha', {
        method: 'POST',
        body: JSON.stringify({ recaptcha: recaptchaValue }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (!captchaRes.ok) {
        setLoader(false);
        setShowMessage(true);
        setAlertMessage('Something went wrong. invalid captcha!');
        return;
      }
      const successRes = await captchaRes.json();
      if (!successRes?.success) {
        setLoader(false);
        setShowMessage(true);
        setAlertMessage('Something went wrong. invalid captcha!');
        return;
      } else {
        const res = await fetch(
          `https://hook.integromat.com/b3n6wldqo41fme7l52969xje4xu1ao8l?email=${email}&id=${tempId}`,
          requestOptions
        );
        const result = await res.text();

        if (res.ok) {
          if (result === 'Accepted') {
            setLoader(false);
            setCompleted('Awesome! We will reach out to you with personalized onboarding');
          }
        } else {
          setCompleted('');
          setLoader(false);
          setShowMessage(true);
          setAlertMessage('Something went wrong!');
        }
      }
    } catch (error) {
      setCompleted('');
      setLoader(false);
      setShowMessage(true);
      setAlertMessage('Something went wrong!');
    }
  };

  return (
    <div className='auth-card'>
      <div className={classes.main}>
        <div className={classes.left + ' animated slideInUpTiny animation-duration-3'}>
          {completed ? (
            <>
              <div style={{ marginBottom: '100px' }}></div>
              <SuccessUi successMsg={completed} />
              <div></div>
            </>
          ) : (
            <>
              <div style={{ marginBottom: '100px' }}>
                <h1
                  style={{
                    color: '#222F3C',
                    fontSize: '32px',
                    fontWeight: 'bold',
                  }}
                >
                  Welcome. Let's get you started
                </h1>
                <p style={{ fontSize: '16px' }}>
                  Due to rush we are presently invite only. Please provide your email and we will
                  onboard you
                </p>
              </div>
              <div style={{ marginTop: '-200px' }}>
                {showMessage && (
                  <AlertUi className='m-3' variant='error'>
                    {alertMessage}
                  </AlertUi>
                )}
                <form>
                  <fieldset>
                    <TextField
                      label={<IntlMessages id='signIn.email' />}
                      fullWidth
                      onChange={event => setEmail(event.target.value)}
                      defaultValue={email}
                      margin='normal'
                      className='mt-1 my-sm-3'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {' '}
                            <img src={require('assets/images/user-icon.svg')} alt='user' />
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    />

                    <div className='my-3 d-flex align-items-center justify-content-between'>
                      <Button
                        onClick={() => postEmail()}
                        variant='contained'
                        color='primary'
                        style={{ textTransform: 'capitalize', width: '50%' }}
                      >
                        {loader ? (
                          <CircularProgress
                            color='inherit'
                            size={'0.875rem'}
                            style={{ margin: '5px 0px' }}
                          />
                        ) : (
                          'I want access'
                        )}
                      </Button>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div></div>
            </>
          )}
        </div>
        <div className={classes.right}>
          {/* <img width='70%' src={require('assets/images/conveyer-belt.png')} alt='conveyer' /> */}
        </div>
      </div>
    </div>
  );
};

export default Waitlist;
