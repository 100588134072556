import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import packageJson from '../package.json';
import { getBuildDate } from './util';
import withClearCache from './ClearCache';

import configureStore, { history } from './store';
import './firebase/firebase';
import App from './containers/App';

export const store = configureStore();

let MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path='/' component={App} />
      </Switch>
    </ConnectedRouter>
    {process.env.NODE_ENV !== 'development' ? (
      <p style={{ display: 'none' }}>Build date: {getBuildDate(packageJson.buildDate)}</p>
    ) : null}
  </Provider>
);

if (process.env.NODE_ENV !== 'development') {
  MainApp = withClearCache(MainApp);
}

export default MainApp;
