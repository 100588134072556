import React from 'react';
import { Badge, Button, Modal, Select, MenuItem, FormControl, InputBase } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import InputField from 'components/Input';
// import { DeleteCircle, Filter } from 'iconoir-react';

// drop down input style
const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'blue',
    },
  },
}))(InputBase);


const useStyles = makeStyles(theme=> ({
    modal: {
        display: "flex",
        justifyContent:'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        "& .modal_body": {
            height: 'auto',
            width: '400px',
            padding: '20px',
            borderRadius: '4px',
            background: '#fff',
            "& .MuiFormControl-root": {
                width: '100%',
                marginBottom: '20px'
            }
        }
    }
}))

const FilterRows = ({ updateTableRowsOnFilter, columns, clearFilterFromRows }) => {
  const [showInputBox, setShowInputBox] = useState(false);
  const [state, setState] = useState(false);

  const [filterKey, setFilterKey] = useState('');
  const [filterCondition, setFilterCondition] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isFilterApplied, setIsFilterApplied] = useState(false);


  const classes = useStyles();

  const [filterOptions, setFilterOptions] = useState([
    'contains',
    'does not contains',
    'is equal to',
    'is not equal to',
    'is empty',
    'is not empty',
  ]);

  const handlerCondition = v => {
    setFilterCondition(v.target.value);
    if (
      v.target.value === 'contains' ||
      v.target.value === 'does not contains' ||
      v.target.value === 'is equal to' ||
      v.target.value === 'is not equal to' ||
      v.target.value === ''
    ) {
      setShowInputBox(true);
    } else {
      setInputValue('');
      setShowInputBox(false);
    }
  };
  const fieldHandler = v => {
    setFilterKey(v.target.value);
  };

  const closeHandler = event => {
    setState(false);
  };

  const filterRowsData = () => {
    if (filterKey && filterCondition) {
      setIsFilterApplied(true);
      updateTableRowsOnFilter(filterKey, filterCondition, inputValue);

      setState(false);
    }
  };


  return (
    <div>
      <Modal
      className={classes.modal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={state}
        onClose={closeHandler}
      >
        <div className='modal_body'>
          <FormControl>
            <Select
              // scale={2 / 3}
            //   placeholder="Choose a field to filter"
              input={<BootstrapInput />}
              onChange={fieldHandler}
              value={filterKey}
            >
              {columns.map((cl, key) => (
                <MenuItem value={cl.title} key={key}>
                  {cl.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <Select
              // scale={2 / 3}
              onChange={handlerCondition}
              input={<BootstrapInput />}
              value={filterCondition}
            >
              {filterOptions.map((opt, key) => (
                <MenuItem key={key} value={opt}>
                  {opt}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showInputBox && (
            <FormControl>
              <InputField placeholder='enter value' onChange={e => setInputValue(e.target.value)} />
            </FormControl>
          )}

          <div>
            <Button
              // icon={<Filter />}
              color='primary'
              variant='contained'
              // scale={2 / 3}
              onClick={filterRowsData}
              width='100px'
            >
              Filter
            </Button>
            &nbsp; &nbsp;
            <Button
              // icon={<DeleteCircle />}
              // scale={2 / 3}
              onClick={() => {
                clearFilterFromRows();
                setIsFilterApplied(false);
              }}
              width='120px'
              variant='contained'
              color='secondary'
            >
              Clear Filter
            </Button>
          </div>
        </div>
      </Modal>
      <Button size='small' variant='contained' onClick={() => setState(true)}>
        {/* <Filter /> */}
        <span>Filter {isFilterApplied && <Badge>1</Badge>}</span>
      </Button>
    </div>
  );
};

export default FilterRows;
