import React, { useState } from "react";
import { hideMessage } from "actions/Auth";
import { useDispatch } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const AlertUi = ({ children, variant }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const hideAlert = () => {
    dispatch(hideMessage());
    setOpen(false);
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <Collapse in={open}>
        <Alert
          severity={variant}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={hideAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {children}
        </Alert>
      </Collapse>
    </div>
  );
};

export default AlertUi;
