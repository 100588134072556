import React from "react";
import { hideMessage } from "actions/Auth";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const SuccessUi = ({ initMsg, successMsg, path, btnTxt }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const goBack = () => {
    dispatch(hideMessage());
    history.push(path);
  };

  return (
    <div>
      <p style={{fontSize: '16px'}}>{successMsg}</p>
      {initMsg && (
        <p>
          {initMsg}{" "}
          <span
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "blue",
            }}
            onClick={goBack}
          >
           {btnTxt}
          </span>
        </p>
      )}
    </div>
  );
};

export default SuccessUi;
