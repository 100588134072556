import React, { useState } from "react";
import {
  CircularProgress,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { auth } from "../firebase/firebase";
import { useHistory } from "react-router-dom";
import AlertUi from "./Msg-UI/AlertUi";
import SuccessUi from "./Msg-UI/SuccessUi";
import './authStylings.css';
// Styles
const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    minHeight: '80vh',
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    backgroundColor: '#ffff',
    margin: '50px'
  },
  left: {
    display: "flex",
    backgroundColor: "rgba(196, 196, 196, 0.12)",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "60px 60px 40px",
  },
  right: {
    display: "grid",
    placeItems: "center",
    backgroundColor: "#fff",
  },
}));

// main component
const ConfirmPassword = ({ actionCode }) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassOne, setShowPassOne] = useState(false);
  const [showPassTwo, setShowPassTwo] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  // input eye icon handlers
  const handleClickShowPassword = () => {
    setShowPassOne(!showPassOne);
  };
  const handleClickConfShowPassword = () => {
    setShowPassTwo(!showPassTwo);
  };

  // form submit handler
  const handleSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      setShowMessage(true);
      setErrorMsg("Password did not match");
      return;
    }

    setIsLoading(true);

    try {
      await auth.confirmPasswordReset(actionCode, confirmNewPassword);
      setIsLoading(false);
      setSuccessMsg("Password changed successfully");
      setErrorMsg("");
      setShowMessage(true);
    } catch (err) {
      setIsLoading(false);
      setErrorMsg(err.message);
      setShowMessage(true);
    }
  };

  return (
    <div className="auth-card">
      <div className={classes.main}>
        <div
          className={
            classes.left + " animated slideInUpTiny animation-duration-3"
          }
        >
          <div>
            <h1
              style={{
                color: "#222F3C",
                fontSize: "32px",
                fontWeight: "bold",
              }}
            >
              Reset password
            </h1>
            <p style={{ fontSize: "14px" }}>Enter new password</p>
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "100%", width: "100%" }}
          >
            <div
              className="container"
              style={{ maxWidth: "400px", width: "100%" }}
            >
              {showMessage && errorMsg !== "" && (
                <AlertUi variant="error">{errorMsg}</AlertUi>
              )}
              {showMessage && successMsg !== "" ? (
                <div>
                  <SuccessUi
                    path="/signin"
                    successMsg={successMsg}
                    initMsg="Go back to"
                    btnTxt="login"
                  />
                </div>
              ) : (
                <form style={{ marginTop: "20px" }}>
                  <fieldset>
                    <TextField
                      type={showPassOne ? "text" : "password"}
                      label="New password"
                      fullWidth
                      onChange={event => setNewPassword(event.target.value)}
                      defaultValue={newPassword}
                      margin="normal"
                      className="mt-1 my-sm-3"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{ padding: "0px", opacity: "0.6" }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassOne ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    <TextField
                      type={showPassTwo ? "text" : "password"}
                      label="Confirm new password"
                      fullWidth
                      onChange={event =>
                        setConfirmNewPassword(event.target.value)
                      }
                      defaultValue={newPassword}
                      margin="normal"
                      className="mt-1 my-sm-3"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{ padding: "0px", opacity: "0.6" }}
                              aria-label="toggle password visibility"
                              onClick={handleClickConfShowPassword}
                            >
                              {showPassTwo ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                    <div className="my-3 d-flex align-items-center justify-content-between">
                      <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "capitalize", width: "50%" }}
                      >
                        {isLoading ? (
                          <CircularProgress
                            color="inherit"
                            size={"0.875rem"}
                            style={{ margin: "5px 0px" }}
                          />
                        ) : (
                          <span>change</span>
                        )}
                      </Button>
                    </div>
                  </fieldset>
                </form>
              )}
            </div>
          </div>
        </div>
        {/* right side image */}
        <div className={classes.right}>
          {/* <h2
              style={{
                color: "#717171",
                fontSize: "22px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Discover Accounts, Score and Qualify <br /> leads with us
            </h2> */}
          {/* <img
            width="70%"
            src={require("assets/images/conveyer-belt.png")}
            alt="conveyer"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;
