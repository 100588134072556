import {
  SUBSCRIBE_PAYMENT,
  SUBSCRIBE_PAYMENT_SUCCESS,
  PAYMENT_FAILED,
  PAYMENT_RESET,
  HIDE_MESSAGE,
} from 'constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  infoMessage: '',
  showMessage: false,
  rajorPayInput: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBSCRIBE_PAYMENT: {
      return {
        ...state,
        loader: true,
      };
    }
    case SUBSCRIBE_PAYMENT_SUCCESS: {
      return {
        ...state,
        loader: false,
        rajorPayInput: action.payload,
      };
    }
    case PAYMENT_RESET: {
      return {
        ...state,
        rajorPayInput: {},
      };
    }
    case PAYMENT_FAILED: {
      return {
        ...state,
        alertMessage: action.payload,
        infoMessage: false,
        showMessage: true,
        loader: false,
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        infoMessage: false,
        showMessage: false,
        loader: false,
      };
    }

    default:
      return state;
  }
};
