import React, { useCallback } from 'react';
import "@glideapps/glide-data-grid/dist/index.css";
import { GridCellKind, DataEditor } from "@glideapps/glide-data-grid";
import FilterRows from './FilterRows';
import SortTableRows from './SortTableRows';
import DownloadTableData from './DownloadTableData';
import { makeStyles } from '@material-ui/core';

export default function DataGridWrapper({ data, columns, index, clearFilterFromRows, updateTableRowsOnFilter, tableRows, updateTableRowsOnSort }) {
    const classes = useStyles();
    const getContent = useCallback((cell) => {
        const [col, row] = cell;
        const dataRow = data[row];
        const indexes = index;
        const d = dataRow?.[indexes?.[col]] || '';

        return {
            kind: GridCellKind.Text,
            allowOverlay: false,
            displayData: d ? String(d) : "",
            data: d ? String(d) : "",
        };
    }, [data, data.length, columns, index]);

    return (
        <div>
            <div className="d-flex justify-content-between p-1">
                <div className="d-flex mb-2">
                    <div className="mr-2">
                        <FilterRows
                            columns={columns}
                            updateTableRowsOnFilter={updateTableRowsOnFilter}
                            clearFilterFromRows={clearFilterFromRows}
                        />
                    </div>

                    <div>
                        <SortTableRows
                            columns={columns}
                            updateTableRows={updateTableRowsOnSort}
                        />
                    </div>
                </div>
                <div className="table-more-options">
                    <DownloadTableData
                        data={tableRows}
                        columns={columns}
                    />
                </div>
            </div>
            <div className={classes.main}>
                <DataEditor
                    getCellContent={getContent}
                    columns={columns}
                    rowMarkers="number"
                    rows={data.length}
                    getCellsForSelection={true}
                    overscrollX={0}
                    overscrollY={16}
                />
            </div>
        </div>
    );
}
const useStyles = makeStyles(theme => ({
    main: {
        width: "80vw",
        height: "80vh",
        margin: "0 auto",
        borderRadius: "12px",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    }
}));