import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Divider, Typography, Link } from '@material-ui/core';
import { getAllPricingPage } from 'util/stripeHandlers';

import Popup from './Popup';

const json = [
  {
    tierId: '0',
    cards: [
      {
        Index: '1',
        Title: 'Ice Breaker',
        Desc: 'Find angles to start cold conversation without researching for countless hours',
        Benefits: ['Cold response', 'Booked meetings'],
      },
      {
        Index: '2',
        Title: 'Personalize at scale',
        Desc:
          'Capture email personalization pointers from inside LinkedIn and magically everything is saved',
        Benefits: ['Email response rate', 'MQL'],
      },
      {
        Index: '3',
        Title: "Assess target buyer's profile",
        Desc: 'Quickly find whether they have the authority and likelihood to buy',
        Benefits: ['Demo to conversion', 'MQL goals'],
      },
      {
        Index: '4',
        Title: 'Take research notes',
        Desc: 'STake notes from inside LinkedIn and push to CSV/CRM in one click',
        Benefits: ['Cold response rate', 'MQL'],
      },
      {
        Index: '5',
        Title: 'Build pipeline from LinkedIn',
        Desc:
          'Build pipeline through LinkedIn search or SalesNavigator search. 1Click push to CRM/CSV',
        Benefits: ['Pipeline goal', 'MQL goal'],
      },
      {
        Index: '6',
        Title: 'Score and qualify',
        Desc:
          'Score accounts and persona. Red flag suspicious accounts and prioritize best fit ones',
        Benefits: ['Demo to conversion', 'MQL goals'],
      },
      {
        Index: '7',
        Title: 'Deep inspected email',
        Desc: 'Search companies showing interest towards topic that matters to you',
        Benefits: ['Qualified meeting', 'More demo'],
      },
      {
        Index: '8',
        Title: 'Find direct mobile',
        Desc: 'Find work-from-home phone in one click (Up to monthly limit)',
        Benefits: ['Qualified meeting', 'More demo'],
      },
      {
        Index: '9',
        Title: 'Template for Connection Request',
        Desc: 'Search companies showing buying intent towards topics (Up to monthly limit)',
        Benefits: ['Qualified meeting', 'MQL'],
      },
      {
        Index: '10',
        Title: 'Discover hot accounts - Pack1',
        Desc: 'SFind companies where your budget owner joined this month (Up to monthly limit)',
        Benefits: ['Qualified demo', 'Top1% close rate'],
      },
      {
        Index: '11',
        Title: 'Discover hot accounts - Pack2',
        Desc:
          'Search companies with investment spike expanding your userbase (Up to monthly limit)',
        Benefits: ['Qualified meeting', 'More demo'],
      },
      {
        Index: '12',
        Title: 'Discover hot accounts - Pack3',
        Desc: 'Find companies where your budget owner joined this month (Up to monthly limit)',
        Benefits: ['Qualified demo', 'Higher close rate'],
      },
      {
        Index: '13',
        Title: 'Discover hot accounts - Pack4',
        Desc:
          'Search companies that are investing in tools complimentary to your product (Up to monthly limit)',
        Benefits: ['Qualified meeting', 'MQL'],
      },
      {
        Index: '14',
        Title: 'Discover hot accounts - Pack5',
        Desc: "Search companies who are investing in your competitor's tool (Up to monthly limit)",
        Benefits: ['Preempt competition', 'MQL'],
      },
    ],
  },
  {
    tierId: 1,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 100\nMobile Credit (aka Premium Credit) 100\nSearch Limit 100',
      },
    ],
  },
  {
    tierId: 2,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
  {
    tierId: 3,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
  {
    tierId: 4,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
  {
    tierId: 5,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
  {
    tierId: 6,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
  {
    tierId: 7,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
  {
    tierId: 8,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
  {
    tierId: 9,
    cards: [
      {
        Index: '1',
        Title: 'Monthly limits',
        Desc:
          'Email Credit (aka Standard Credit) 200\nMobile Credit (aka Premium Credit) 200\nSearch Limit 200',
      },
    ],
  },
];

// material ui css
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '30px',
    '& .globCards': {
      padding: '0',
      margin: '0',
      display: 'grid',
      gridTemplateColumns: '300px 300px 300px 300px',
      gap: '30px',
    },
  },
  title: {
    textAlign: 'center',
    '& h1': {
      fontSize: '1.8rem',
      fontWeight: '900',
    },
  },
  pricingInfo: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    '& p': {
      color: 'black',
      maxWidth: '600px',
      fontWeight: 'bold',
    },
  },
  tabs: {
    textAlign: 'center',
  },
  tabBtn: {
    outline: 'none',
    background: '#fff',
    borderRadius: '5px',
    border: 'none',
    padding: '0.8rem 2rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    transition: 'all 100ms ease-in-out',
  },
  active: {
    outline: 'none',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    padding: '0.8rem 2rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    transform: 'scale(1.05)',
    transition: 'all 100ms ease-in-out',
  },
  pricingCards: {
    marginBottom: '50px',
    display: 'flex',
    gap: '47px',
  },
  card: {
    marginTop: '20px',
    borderRadius: '10px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 20px 12px rgba(0,0,0,0.1)',
    WebkitBoxShadow: '0px 4px 20px 12px rgba(0,0,0,0.1)',
    MozBoxShadow: '0px 4px 20px 12px rgba(0,0,0,0.1)',
    padding: '40px 20px 20px',
    display: 'flex',
    gap: '20px',
    minWidth: '437px',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  cardContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    maxWidth: '80%',
    width: '100%',
    margin: 'auto',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.light}`,
    borderTop: `5px solid ${theme.palette.primary.light} !important`,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: '8px',
  },
  plansCard: {
    boxShadow: '0px 0px 30px 9px #EBEBEB',
    borderRadius: '8px',
    padding: '20px',
    listStyle: 'none',
    color: '#252525',
    fontFamily: 'inherit',
    width: '300px',
    margin: '15px auto',
    backgroundColor: '#fff',
    '& .para': { fontSize: '12px', fontWeight: 400, whiteSpace: 'pre-line', height: '51px' },
    '& .chips': {
      fontSize: '12px',
      display: 'flex',
      gap: '10px',
      marginTop: '8px',
      '& span': {
        padding: '2px 10px',
        borderRadius: '50px',
        border: '1px solid #ef788d',
        background: '#ffc0cb26',
        fontWeight: 600,
      },
    },
    '& hr': {
      margin: '5px 0px',
    },
    '& .card-head': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '10px',

      '& button': { fontSize: '10px' },
      '& h6': {
        fontSize: '14px',
        fontWeight: 600,
        padding: '5px 12px',
        background: '#F0F3F7',
        color: '#585CA6',
        borderRadius: '5px',
      },
    },
  },
  cardIcon: {
    display: 'grid',
    placeItems: 'center',
  },
  cardTitle: {
    '& h1': {
      fontSize: '1.8rem',
      fontWeight: '1000',
      textAlign: 'center',
    },
    '& p': {
      textAlign: 'center',
      fontWeight: '1000',
      marginTop: '10px',
      fontSize: '1rem',
    },
  },
  cardPrice: {
    margin: '0 -20px',
    padding: '25px 0',
    display: 'grid',
    placeItems: 'center',
    borderTop: '1px solid rgba(0,0,0,0.2)',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
    '& h1': {
      color: theme.palette.primary.light,
      fontWeight: '1000',
      textAlign: 'center',
      fontSize: '2.6rem',
      margin: '0',
    },
  },
  cardFeature: {
    marginTop: '10px',
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    placeContent: 'center',
    '& h4': {
      color: 'black',
      fontWeight: '800',
    },
    '& li': {
      margin: '15px 0',
    },
  },
  cardBtn: {
    marginTop: 'auto',
    marginBottom: '-50px',
    textAlign: 'center',
    '& button': {
      width: '80%',
      padding: '15px 10px',
      fontWeight: '800',
      fontSize: '1.2rem',
    },
  },
}));

const Pricing = () => {
  const {
    root,
    title,
    pricingInfo,
    tabs,
    tabBtn,
    active,
    pricingCards,
    card,
    cardIcon,
    cardTitle,
    cardPrice,
    cardFeature,
    cardBtn,
    selected,
    plansCard,
    cardContainer,
  } = useStyles();
  const { companyDetails, userDetails } = useSelector(({ auth }) => auth);
  const [tab, setTab] = useState('mo');
  const [prices, setPrices] = useState([]);
  const [sub, setSub] = useState({});
  const [open, setOpen] = useState(false);
  const [type, setType] = useState();
  const [singleItem, setSingleItem] = useState({});
  // * The tier Customer selects after clicking get started / add seats
  const [tier, setTier] = useState('');
  const [loading, setLoading] = useState(true);
  const [appJson, setAppJson] = useState([]);
  const [commonCardsJson, setCommonCardsJson] = useState([]);

  const handleOpen = (item, tierName) => {
    setSingleItem(item);
    setTier(tierName);
    setType('payment');
    setOpen(true);
  };

  const cancelSubs = () => {
    setType('cancel');
    setOpen(true);
  };

  const fetchPrices = async () => {
    try {
      setLoading(true);
      const response = await getAllPricingPage(companyDetails.cid);
      if (!response?.error) {
        setPrices(response.pricingDetails);
        setSub(response.subDetails);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  useEffect(() => {
    if (prices.length) {
      const commonCards = json.filter(k => k.tierId === '0' && k.cards)[0].cards;

      const combinedJson = prices.map(i => {
        const filteredCards = json.filter(k => Number(i.tierId) === Number(k.tierId) && k?.cards)[0]
          .cards;
        return { tier: i, tierCards: filteredCards };
      });

      setCommonCardsJson(commonCards);
      setAppJson(combinedJson);
      setLoading(false);
    }
  }, [prices]);

  function renderMonthlyCards() {
    const cards =
      appJson.length > 0 &&
      appJson
        .filter(({ tier }) => tier.subFreq === '1M')
        .map(
          (
            { tier: { tierName, tierId, isSelected, priceUsd, stripePriceId } = {}, tierCards },
            index
          ) => {
            return (
              <div key={tierId} className={isSelected ? card + ' ' + selected : card}>
                <div className={cardIcon}>{/* <img src={j.icon} alt='icon' /> */}</div>
                <div className={cardTitle}>
                  <h1>{tierName.split('-')[0].trim()}</h1>
                  <p>per user / mo</p>
                </div>
                <div className={cardPrice}>
                  <h1>${priceUsd}</h1>
                </div>
                <div className={cardFeature}>
                  <h4>{tierName.split('-')[0].trim()} plan includes:</h4>
                  <ul style={{ padding: '0', margin: '0' }}>
                    {tierCards.map(({ Desc, Title, Benefits }) => (
                      <li className={plansCard} key={Math.random()}>
                        <div>
                          <div>
                            <div className='card-head'>
                              <Typography variant='h6'>{Title}</Typography>
                            </div>
                            <Typography className='para'>{Desc}</Typography>
                            <Divider />
                            <div className='chips'>
                              {Benefits && Benefits.map(b => <span key={b}>{b}</span>)}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={cardBtn}>
                  {tierName !== 'starter' ? (
                    <Button
                      style={{
                        background: isSelected ? '#fff' : '',
                        border: '2px solid #172EEC',
                        color: isSelected ? '#000' : '',
                      }}
                      onClick={() => handleOpen(stripePriceId, tierName)}
                      variant='contained'
                      size='large'
                      color='primary'
                    >
                      {isSelected ? 'Add seats' : 'Get Started'}
                    </Button>
                  ) : (
                    <Button variant='contained' size='large' color='primary'>
                      Get Started
                    </Button>
                  )}
                </div>
              </div>
            );
          }
        );

    return cards;
  }

  function renderYearlyCards() {
    const cards =
      appJson.length > 0 &&
      appJson
        .filter(({ tier }) => tier.subFreq === '1Y')
        .map(
          (
            { tier: { tierName, tierId, isSelected, priceUsd, stripePriceId } = {}, tierCards },
            index
          ) => {
            return (
              <div key={tierId} className={isSelected ? card + ' ' + selected : card}>
                <div className={cardIcon}>{/* <img src={j.icon} alt='icon' /> */}</div>
                <div className={cardTitle}>
                  <h1>{tierName.split('-')[0].trim()}</h1>
                  <p>per user / yr</p>
                </div>
                <div className={cardPrice}>
                  <h1>${priceUsd}</h1>
                </div>
                <div className={cardFeature}>
                  <h4>{tierName.split('-')[0].trim()} plan includes:</h4>
                  <ul style={{ padding: '0', margin: '0' }}>
                    {tierCards.map(({ Desc, Title, Benefits }) => (
                      <li className={plansCard} key={Math.random()}>
                        <div>
                          <div>
                            <div className='card-head'>
                              <Typography variant='h6'>{Title}</Typography>
                            </div>
                            <Typography className='para'>{Desc}</Typography>
                            <Divider />
                            <div className='chips'>
                              {Benefits && Benefits.map(b => <span key={b}>{b}</span>)}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={cardBtn}>
                  {tierName !== 'starter' ? (
                    <Button
                      style={{
                        background: isSelected ? '#fff' : '',
                        border: '2px solid #172EEC',
                        color: isSelected ? '#000' : '',
                      }}
                      onClick={() => handleOpen(stripePriceId, tierName)}
                      variant='contained'
                      size='large'
                      color='primary'
                    >
                      {isSelected ? 'Add seats' : 'Get Started'}
                    </Button>
                  ) : (
                    <Button variant='contained' size='large' color='primary'>
                      Get Started
                    </Button>
                  )}
                </div>
              </div>
            );
          }
        );

    return cards;
  }

  return (
    <>
      <Box className={root}>
        {loading ? (
          <Typography variant='h4' className='text-center'>
            Loading...
          </Typography>
        ) : (
          <div>
            <div className={title}>
              <h1>Pricing</h1>
            </div>
            <div className={pricingInfo}>
              {/* <p>
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                an unknown printer took a galley of type and scrambled it to make a type specimen
                book. It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged.
              </p> */}
            </div>
            <div className={tabs}>
              <button onClick={() => setTab('an')} className={(tab === 'an' && active) || tabBtn}>
                Annual
              </button>
              <button onClick={() => setTab('mo')} className={(tab === 'mo' && active) || tabBtn}>
                Monthly
              </button>
            </div>

            <div
              style={{
                margin: '50px auto',
                width: '80%',
              }}
            >
              <ul className='globCards'>
                {commonCardsJson.length > 0 &&
                  commonCardsJson.map(({ Desc, Title, Benefits }) => (
                    <li className={plansCard} key={Math.random()}>
                      <div>
                        <div>
                          <div className='card-head'>
                            <Typography variant='h6'>{Title}</Typography>
                          </div>
                          <Typography className='para'>{Desc}</Typography>
                          <Divider />
                          <div className='chips'>
                            {Benefits && Benefits.map(b => <span key={b}>{b}</span>)}
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            <div className={cardContainer}>
              <div className={pricingCards}>
                {prices.length && tab === 'mo' ? renderMonthlyCards() : ''}
                {prices.length && tab === 'an' ? renderYearlyCards() : ''}
              </div>
            </div>
            {sub.selectedTierId !== 'NO_TIER_SELECTED' && (
              <div style={{ textAlign: 'center', marginTop: '30px' }}>
                <Link href='#' onClick={cancelSubs}>
                  Cancel Subscription
                </Link>
              </div>
            )}
          </div>
        )}
      </Box>

      {/* modal */}
      <Popup
        open={open}
        close={setOpen}
        type={type}
        paymentId={singleItem}
        tierName={tier}
        prices={prices}
        userSub={sub}
      />
    </>
  );
};

export default Pricing;
