export const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

export const companySize = [
  {
    value: '1-10',
    label: '1-10',
  },
  {
    value: '11-50',
    label: '11-50',
  },
  {
    value: '51-200',
    label: '51-200',
  },
  {
    value: '201-500',
    label: '201-500',
  },
  {
    value: '501-1000',
    label: '501-1000',
  },
  {
    value: '1001-5000',
    label: '1001-5000',
  },
  {
    value: '5001-10000',
    label: '5001-10000',
  },
  {
    value: '10,000+',
    label: '10,000+',
  },
];

export const revenue = [
  {
    value: '<1M',
    label: '<1M',
  },
  {
    value: '1M-5M',
    label: '1M-5M',
  },
  {
    value: '5M-10M',
    label: '5M-10M',
  },
  {
    value: '10M-50M',
    label: '10M-50M',
  },
  {
    value: '50M-100M',
    label: '50M-100M',
  },
  {
    value: '100M-500M',
    label: '100M-500M',
  },
  {
    value: '500M-1B',
    label: '500M-1B',
  },
  {
    value: '1B+',
    label: '1B+',
  },
];

export const assertions = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const growthSignal = [
  {
    value: 'Extreme growth (>50% YoY)',
    label: 'Extreme growth (>50% YoY)',
  },
  {
    value: 'Moderate growth (25% - 50% YoY)',
    label: 'Moderate growth (25% - 50% YoY)',
  },
  {
    value: 'Low growth  (<25% YoY)',
    label: 'Low growth  (<25% YoY)',
  },
];

export const cityArray = [
  {
    value: 'San Francisco',
    label: 'San Francisco',
  },
];

export const countryArray = [
  {
    value: 'USA',
    label: 'USA',
  },
];
