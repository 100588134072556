// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

export const NOTSTARTED = 'NOTSTARTED';
export const SELECTED = 'SELECTED';
export const STARTED = 'STARTED';
export const DONE = 'DONE';
export const FETCH_TIMEOUT = '120000'; //enrichment
export const PULL_TIMEOUT = '120000'; //pull jobs
export const REVIEW_THRESHOLD_ENRICH_COUNT = '3';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const SHOW_INFO_MESSAGE = 'show_info_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const GET_ALL_CONTACT = 'get_all_contact';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';
export const START_CONTACT_LOADER = 'start_contact_loader';

//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const UPDATE_PROFILE = 'update_profile';
export const UPDATE_PROFILE_SUCCESS = 'update_profile_success';
export const UPDATE_PASSWORD = 'update_password';
export const UPDATE_PASSWORD_SUCCESS = 'update_password_success';
export const SET_FIREBASE_AUTH_STATE_SUCCESS = 'set_firebase_auth_state_success';
export const SET_FIREBASE_AUTH_STATE_FAILED = 'set_firebase_auth_state_failed';

export const PROFILE = 'profile';
export const PROFILE_SUCCESS = 'profile_success';
export const ADD_PROFILE_DETAILS = 'add_profile_details';
export const FORGOT_PASSWORD_RESET_EMAIL = 'forgot_password_reset_email';
export const FORGOT_PASSWORD_RESET_EMAIL_SUCCESS = 'forgot_password_reset_email_success';

export const ADD_INVITE = 'add_invite';
export const GET_INVITATION_DETAILS = 'get_invitation_details';
export const CONFIRM_INVITATION = 'confirmInvitation';
export const CONFIRM_INVITATION_SUCCESS = 'confirmInvitation_success';
export const SAVE_INVITATION_DETAILS = 'save_invitation_details';

export const GET_USER_DETAILS = 'get_user_details';
export const GET_USER_DETAILS_SUCCESS = 'get_user_details_success';
export const GET_ROUTE_DETAILS = 'get_route_details';
export const GET_ROUTE_DETAILS_SUCCESS = 'get_route_details_success';
export const GET_USER_DETAILS_FAILED = 'get_user_details_failed';

export const UPDATE_PROFILE_DETAILS = 'update_profile_details';

export const PROFILE_DETAILS_SUBMITTED = 'profile_details_submitted';
export const PROFILE_DETAILS_NOT_SUBMITTED = 'profile_details_not_submitted';

export const INIT_URL = 'init_url';

//JOBS

export const PLAYBOOK_DETAILS_EMPTY = 'playbook_details_empty';
export const SUBMIT_PLAYBOOK_EMPTY = 'submit_playbook_empty';
export const SUBMIT_JOB_RESULT = 'submit_job_result';
export const SUBMIT_PLAYBOOK = 'submit_playbook';
export const SUBMIT_PLAYBOOK_SUCCESS = 'submit_playbook_success';
export const SUBMIT_JOBS_SUCCESS = 'submit_jobs_success';
export const SUBMIT_PEOPLE_JOBS_SUCCESS = 'submit_people_jobs_success';
export const SUBMIT_COMPANY_JOBS_SUCCESS = 'submit_company_jobs_success';
export const SUBMIT_JOBS_SUCCESS_RESET = 'submit_jobs_success_reset';
export const GET_JOBS = 'get_jobs';
export const GET_JOBS_SUCCESS = 'get_jobs_success';
export const GET_JOB_DETAILS_EMPTY = 'get_job_details_empty';
export const GET_JOB_DETAILS_SUCCESS = 'get_job_details_success';
export const ASSIGN_PLAYBOOK_DETAILS = 'assign_playbook_details';
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const CONTACT_LIST_RESULT = 'CONTACT_LIST_RESULT';
export const SET_CHECKED_UPDATED_LIST_RESULT = 'set_chcecked_updated_list_result';
export const SET_USERINFO_CHECKED_UPDATED_LIST_RESULT = 'set_userinfo_chcecked_updated_list_result';
export const SET_COMPANYINFO_CHECKED_UPDATED_LIST_RESULT =
  'set_companyinfo_chcecked_updated_list_result';
export const GET_CONTACTS_LIST_RESET = 'get_contacts_list_reset';
export const SHOW_JOBS_MESSAGE = 'show_job_message';
export const SHOW_JOBS_INFO_MESSAGE = 'show_job_info_message';
export const HIDE_JOBS_MESSAGE = 'hide_jobs_message';
export const STOP_JOB_LOADER = 'stop_job_loader';

//SNP
export const CLEAR_SNP_ALL_DETAILS = 'clear_snp_all_details';
export const SAVE_SNP_PLAYBOOKINFO = 'save_snp_playbookinfo';
export const SAVE_SNP_SAVE_JOBID = 'save_snp_save_jobid';
export const SNP_PLAYBOOK_SUBMIT = 'snp_playbook_submit';
export const GET_SNP_JOBS_PUBSUB = 'get_snp_jobs_pubsub';
export const GET_SNP_T1_JOBS_PUBSUB_SUCCESS = 'get_snp_t1_jobs_pubsub_success';
export const GET_SNP_T2_JOBS_PUBSUB_SUCCESS = 'get_snp_t2_jobs_pubsub_success';

export const GET_SNP_T1_JOBS_PUBSUB_FAILED = 'get_snp_t1_jobs_pubsub_failed';
export const GET_SNP_T2_JOBS_PUBSUB_FAILED = 'get_snp_t2_jobs_pubsub_failed';

export const SNP_SUBMIT = 'snp_submit';
export const GET_SNP_JOB_DETAILS_SUCCESS = 'get_snp_job_details_success';

//SNC
export const CLEAR_SNC_ALL_DETAILS = 'clear_snc_all_details';
export const SAVE_SNC_PLAYBOOKINFO = 'save_snc_playbookinfo';
export const SAVE_SNC_SAVE_JOBID = 'save_snc_save_jobid';
export const SNC_PLAYBOOK_SUBMIT = 'snc_playbook_submit';
export const GET_SNC_JOBS_PUBSUB = 'get_snc_jobs_pubsub';

export const GET_SNC_T1_JOBS_PUBSUB_SUCCESS = 'get_snc_t1_jobs_pubsub_success';
export const GET_SNC_T2_JOBS_PUBSUB_SUCCESS = 'get_snc_t2_jobs_pubsub_success';

export const GET_SNC_T1_JOBS_PUBSUB_FAILED = 'get_snc_t1_jobs_pubsub_failed';
export const GET_SNC_T2_JOBS_PUBSUB_FAILED = 'get_snc_t2_jobs_pubsub_failed';

export const SNC_SUBMIT = 'snc_submit';
export const GET_SNC_JOB_DETAILS_SUCCESS = 'get_snc_job_details_success';

export const COMPANY_NAEMS_BATCH_LIST_ADD = 'company_names_batch_lsit_add';
export const COMPANY_NAEMS_BATCH_LIST_CLEAR = 'company_names_batch_lsit_clear';

export const SAVE_SALES_NAV_USER_PAYLOAD = 'save_sales_nav_user_payload';
export const SAVE_SALES_NAV_COMPANY_PAYLOAD = 'save_sales_nav_company_payload';

export const SALES_NAV_USER_SCRAPE = 'sales_nav_user_scrape';
export const SALES_NAV_COMPANY_SCRAPE = 'sales_nav_company_scrape';

export const GET_LIST_NAMES = 'get_list_names';
export const GET_LIST_NAMES_SUCCESS = 'get_list_names_success';
export const GET_LIST_NAMES_RESET = 'get_list_names_reset';

export const CREATE_CONTACT = 'create_contact';
export const NEW_CONTACT_CREATED = 'new_contact_created';

export const MOVE_CONTACT = 'move_contact';
export const CONTACT_MOVED = 'contact_moved';

export const PEOPLE = 'people';
export const COMPANY = 'company';

export const DELETE_PEOPLE_LIST_NAMES = 'delete_peopleList_names';

/** Fire store list manager **/

export const CREATE_CUSTOM_LIST = 'create_custom_list';
export const VIEW_CUSTOM_LIST = 'view_custom_list';
export const SAVE_TO_CUSTOM_LIST = 'save_to_custom_list';
export const GET_LIST_NAME_BY_USER = 'get_list_name_by_user';
export const UPDATE_LIST_SUB = 'update_list_sub';
export const GET_DEFAULT_LIST = 'get_default_list';
export const SET_DEFAULT_LIST = 'set_default_list';
export const GET_LATEST_PEOPLE_N_RECORDS_FOR_USER = 'get_latest_people_n_records_for_user';
export const GET_LATEST_COMPANY_N_RECORDS_FOR_USER = 'get_latest_company_n_records_for_user';
export const GET_SPECIFIC_RECORDS_FOR_USER = 'get_specific_records_for_user';
export const UPDATE_PEOPLE_ENRICH = 'update_people_enrich';
export const UPDATE_COMPANY_ENRICH = 'update_company_enrich';
export const UPDATE_PEOPLE_ENRICH_INFO = 'update_people_enrich_info';
export const UPDATE_PEOPLE_ENRICH_INFO_SUCCESS = 'update_people_enrich_info_success';

export const CREATE_PEOPLE_CUSTOM_LIST_SUCCESS = 'create_people_custom_list_success';
export const CREATE_COMPANY_CUSTOM_LIST_SUCCESS = 'create_company_custom_list_success';
export const VIEW_CUSTOM_PEOPLE_LIST_SUCCESS = 'view_custom_people_list_success';
export const VIEW_CUSTOM_COMPANY_LIST_SUCCESS = 'view_custom_company_list_success';
export const SAVE_TO_CUSTOM_LIST_SUCCESS = 'save_to_custom_list_success';
export const GET_PEOPLE_LIST_NAME_BY_USER_SUCCESS = 'get_people_list_name_by_user_success';
export const GET_COMPANY_LIST_NAME_BY_USER_SUCCESS = 'get_company_list_name_by_user_success';
export const UPDATE_LIST_SUB_SUCCESS = 'update_list_sub_success';
export const GET_DEFAULT_LIST_SUCCESS = 'get_default_list_success';
export const SET_DEFAULT_PEOPLE_LIST_SUCCESS = 'set_default_people_list_success';
export const SET_DEFAULT_COMPANY_LIST_SUCCESS = 'set_default_company_list_success';

export const STOP_FETCHING_PEOPLELIST = 'stop_fetching_peoplelist';
export const STOP_FETCHING_COMPANYLIST = 'stop_fetching_companylist';

export const UDPATE_SET_DEFAULT_LISTNAME_PEOPLELIST = 'update_set_default_listname_peopleList';
export const UDPATE_SET_DEFAULT_LISTNAME_COMPANYLIST = 'update_set_default_listname_companyList';

export const GET_LATEST_PEOPLE_N_RECORDS_FOR_USER_SUCCESS =
  'get_latest_people_n_records_for_user_success';
export const GET_LATEST_COMPANY_N_RECORDS_FOR_USER_SUCCESS =
  'get_latest_company_n_records_for_user_success';
export const GET_SPECIFIC_RECORDS_FOR_USER_SUCCESS = 'get_specific_records_for_user_success';
export const MOVE_CONTACT_TO_LIST = 'move_contact_to_list';
export const MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS = 'move_people_contact_to_list_success';
export const MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS = 'move_company_contact_to_list_success';

export const SHOW_CONTACT_MESSAGE = 'show_contact_message';
export const SHOW_CONTACT_INFO_MESSAGE = 'show_contact_info_message';
export const HIDE_CONTACT_MESSAGE = 'hide_contact_message';

//Enrich

export const GET_ENRICH_1 = 'get_enrich_1';
export const GET_ENRICH_2 = 'get_enrich_2';
export const ENRICH_ADD_JOB_ID_1 = 'enrich_add_jobid_1';
export const ENRICH_ADD_JOB_ID_2 = 'enrich_add_jobid_2';
export const GET_SNP_JOBS_PUBSUB_ENRICH_1 = 'get_snp_jobs_pubsub_enrich_1';
export const GET_SNP_JOBS_PUBSUB_ENRICH_2 = 'get_snp_jobs_pubsub_enrich_2';
export const GET_SNP_JOBS_PUBSUB_ENRICH_1_SUCCESS = 'get_snp_jobs_pubsub_enrich_1_success';
export const GET_SNP_JOBS_PUBSUB_ENRICH_2_SUCCESS = 'get_snp_jobs_pubsub_enrich_2_success';

export const SET_ENRICH_PEOPLE_CONTACTS_LIST = 'set_enrich_people_contacts_list';
export const SET_ENRICH_PEOPLE_CONTACTS_LIST_ITEM = 'set_enrich_people_contacts_list_item';
export const SET_ENRICH_COMPANY_CONTACTS_LIST = 'set_enrich_company_contacts_list';

export const START_ENRICHMENT_1_FETCHING = 'start_enrichment_1_fetching';
export const GET_RE_ENRICH_1 = 'get_re_enrich_1';
export const GET_RE_ENRICH_1_SUCCESS = 'get_re_enrich_1_success';
export const GET_RE_ENRICH_1_FAILED = 'get_re_enrich_1_failed';
export const GET_ENRICH_2_FROM_PEOPLE = 'get_enrich_2_from_people';
export const GET_ENRICH_2_FROM_PEOPLE_SUCCESS = 'get_enrich_2_from_people_success';
export const GET_ENRICH_2_FROM_PEOPLE_FAILED = 'get_enrich_2_from_people_failed';

//
export const GET_SNP_LIST = 'get_snp_list';
export const GET_SNC_LIST = 'get_snc_list';
export const ADD_SNP_LIST = 'add_snp_list';
export const ADD_SNC_LIST = 'add_snc_list';

// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const

export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

//payment
export const SUBSCRIBE_PAYMENT = 'subscribe_payment';
export const SUBSCRIBE_PAYMENT_SUCCESS = 'subscribe_payment_success';
export const PAYMENT_FAILED = 'payment_failed';
export const PAYMENT_RESET = 'payment_reset';

// crm
export const CHECK_UPDATE = 'check_update';
export const START_CHECK_UPDATE = 'start_check_update';
export const CHECK_UPDATE_SUCCESS = 'check_update_success';
export const CHECK_UPDATE_LOADING = 'check_update_loading';
export const CHECK_UPDATE_ERROR = 'check_update_error';
export const FORCE_UPDATE = 'force_update';
export const FORCE_UPDATE_SUCCESS = 'force_update_success';
export const FORCE_UPDATE_ERROR = 'force_update_error';
export const SHOW_CHECK_UPDATE_MATCH_LIST = 'show_check_update_match_list';
export const HIDE_CHECK_UPDATE_MATCH_LIST = 'hide_check_update_match_list';
export const START_COMPANY_UPDATE = 'start_company_update';
export const COMPANY_UPDATE_SUCCESS = 'company_update_success';
export const COMPANY_UPDATE_ERROR = 'company_update_error';
export const COMPANY_UPDATE_LOADING = 'company_update_loading';

// New Search
export const UPDATE_SEARCH_TYPE = 'update_search_type';
export const UPDATE_SEARCH_RESULT = 'update_search_result';
export const RESET_SEARCH_TYPE = 'reset_search_type';
export const UPDATE_SEARCH_DETAILS = 'update_search_details';
export const UPDATE_SEARCH_DETAILS_LIST = 'update_search_details_list';
export const FETCH_SEARCH_RESULT = 'fetch_search_result';
export const FETCH_SEARCH_LIST_RESULT = 'fetch_search_list_result';
export const SEARCH_FETCH_SUCCESS = 'search_fetch_success';
export const SEARCH_FETCH_ERROR = 'search_fetch_error';
export const SHOW_SEARCH_ERROR_MESSAGE = 'show_search_error_message';
export const HIDE_SEARCH_MESSAGE = 'hide_search_message';
export const CLEAR_SEARCH_RESULT = 'clear_search_result';
export const SET_SEARCH_LOADING = 'set_search_loading';
export const SET_SEARCH_LOADING_FALSE = 'set_search_loading_false';
export const SET_PREFETCH_SEARCH_LOADING = 'set_prefetch_search_loading';
export const START_PREFETCH_SEARCH = 'start_fetch_search_result';
export const START_PREFETCH_SEARCH_LIST = 'start_fetch_search_list_result';
export const SUCCESS_PREFETCH_SEARCH = 'success_fetch_search_result';
export const SUCCESS_PREFETCH_SEARCH_LIST = 'success_fetch_search_list_result';
export const ERROR_PREFETCH_SEARCH = 'error_fetch_search_result';
export const ERROR_PREFETCH_SEARCH_LIST = 'error_fetch_search_list_result';
export const SEARCH_MORE_SUCCESS = 'search_more_success';

export const SEARCH_EXEC_MOVEMENT = 'intent-exec-movement';
export const SEARCH_RECRUITMENT_BUYER = 'intent-recruitment-buyer';
export const SEARCH_RECRUITMENT_USER = 'intent-recruitment-user';
export const SEARCH_TOPIC_SURGE = 'intent-topic';
export const SEARCH_COMPANY_LABEL = 'intent-company-label';
export const SEARCH_SIMILAR_INTENT = 'similar-intent-search';
export const SEARCH_SAME_VC_INTENT = 'same-vc-intent-search';
export const SEARCH_DUMMY_3 = 'intent-dummy-search3';
export const SET_SEARCH_CHECKED_UPDATED_LIST_RESULT = 'set_search_chcecked_updated_list_result';
