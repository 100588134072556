import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '../../../components/IconButton';
import { Modal } from '@material-ui/core';

/** */
import ResultListTable from '../../../components/resultListTable';
import TablePagination from '../../../components/tablePagination';

import { displayMessage, hideMessage } from 'actions/jobs';
import { setSearchSelectUpdated } from 'actions/Search';
import { getListNameByUser, hideContactMessage } from 'actions/Contact';

import {
  handleDownloadSearchCSV,
  getSearchTableDisplayData,
  isEmpty,
  getSearchPayloadFromSearchType,
} from '../../../util';
import {
  startSearchResult,
  startSearchListResult,
  clearSearchResult,
  updateSearchType,
  startPrefetchSearch,
  startPrefetchSearchList,
} from 'actions/Search';
import TagsInput from 'components/DummySearchBox/NewSearch';
import DecisionMakerPopup from 'components/DummySearchBox/DecisionMakerPopup';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  root: {
    flexGrow: 1,
  },
  switchPaper: {},

  searchGif: {
    maxWidth: 70,
  },

  formPaper: {
    padding: '0px',
    margin: 'auto',
    maxWidth: 600,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    padding: '15px 20px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  bottom: {
    display: 'grid',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'minmax(20rem,20%) 1fr',
    },
    gridTemplateColumns: 'minmax(15.625rem,20%) 1fr',
    gridTemplateRows: '80vh',
    gap: '20px',
    height: '100%',
    padding: '20px',
  },
  searchBox: {
    display: 'grid',
    backgroundColor: '#FFFFFF',
    height: '100%',
    width: '100%',
    borderRadius: '5px',
  },
  resultBox: {
    display: 'grid',
    backgroundColor: '#FFFFFF',
    height: '100%',
    width: '100%',
    borderRadius: '5px',
  },
  resultGrid: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
  },
  form: {
    margin: '10px',
    marginLeft: '20px',
    overflowY: 'scroll',
    marginBottom: '90px',
    paddingRight: '10px',
  },
  background: {
    height: '100%',
    width: '100%',
    diplay: 'grid',
    placeContent: 'center',
  },
  beforeSearch: {
    height: '100%',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': { textAlign: 'center' },
    '& img': {
      width: '100%',
      maxWidth: '300px',
    },
  },
  loadingSearch: {
    height: '100%',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': { color: '#182EEC' },
    '& img': {
      width: '100%',
      maxWidth: '300px',
    },
  },
  searchScroll: {
    overflowY: 'scroll',
    height: '76vh',
  },
  notFound: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': { textAlign: 'center' },
    '& img': {
      width: '100%',
    },
  },
  modal: {
    display: 'grid',
    placeItems: 'center',
  },
}));

let T1_page1 = 60;
let T1_page2 = 20;
let T2_page1 = 30;
let T2_page2 = 30;
let T3_page1 = 30;
let T3_page2 = 30;

const Search = props => {
  const {
    loader,
    message,
    messageType,
    searchType,
    searchResult,
    searchDetails,
    searchDetailsList,
  } = useSelector(({ search }) => search);

  const {
    contactsLoader,
    contactsAlertMessage,
    contactsShowMessage,
    contactsInfoMessage,
    peopleListNames,
    companyListNames,
  } = useSelector(({ contacts }) => contacts);

  const { companyDetails, userDetails } = useSelector(({ auth }) => auth);
  const storeDispatch = useDispatch();

  const classes = useStyles();

  //#region ==> State variable
  const [counter, setCounter] = useState(0);
  const [expectedAPITime, setexpectedAPITime] = useState(30); // 30 seconds for T1, 60 for T2
  const [userClickedSearchFirstTime, setUserClickedSearchFirstTime] = useState(false);

  /** */
  const showFilter = false;

  const [currentPageList, setCurrentPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // 10 per page
  const [allItemSelected, setAllItemSelected] = useState(false);
  const [fdmSelected, setFdmSelected] = useState([]);
  const [contextSearch, setContextSearch] = useState('');

  /** */
  // const resultJSONKey = 'companyJSON';

  const {
    isSearchGoingOn,
    searchIterationsMax,
    searchJobId,
    searchIterationsDoneCount,
    isEndOfRecord,
    isPrefetchGoingOn,
  } = searchDetails;

  /** */
  const [decisionModal, setDecisionModal] = useState(false);
  const [geoForward, setGeoForward] = useState('');



  const removeDuplicateItem =(items)=> {
    const uniqueArr = items.reduce((accumulator, current) => {
      
      const isDuplicate = accumulator.some((item) => item.companyRecord?.name === current.companyRecord?.name);
      
      if (!isDuplicate) {
        accumulator.push(current);
      }
    
      return accumulator;
    }, []);


    setFdmSelected(uniqueArr);
  }
  //#endregion

  useEffect(() => {
    removeDuplicateItem(searchResult.filter((item)=> (item.selected)));
  }, [searchResult])
  
  //#region --> all useEffect
  //mounting
  useEffect(() => {
    /**get all list names */

    (function() {
      if (peopleListNames.length === 0) {
        let payload = {
          uid: localStorage.getItem('user_id'),
          cat: 'people',
        };
        storeDispatch(getListNameByUser(payload));
      }

      if (companyListNames.length === 0) {
        let payload = {
          uid: localStorage.getItem('user_id'),
          cat: 'company',
        };
        storeDispatch(getListNameByUser(payload));
      }
    })();
    // getListNames();
  }, []);

  useEffect(() => {
    if (searchResult.length > 0 && searchResult.length >= (currentPage - 1) * perPage) {
      let showList = searchResult.slice((currentPage - 1) * perPage, currentPage * perPage);

      let finalList = getSearchTableDisplayData(searchType, showList, allItemSelected, contextSearch);
      setCurrentPageList(finalList);

      // **** if first search result returns less than 10 record but not 0 then prefetch next **** not applicable if multiTitle search
      if (
        currentPage === 1 &&
        searchResult.length < perPage &&
        searchResult.length &&
        !searchDetailsList.length
      ) {
        prefetchNextResult();
      }
    } else setCurrentPageList([]);
  }, [currentPage, searchResult]);

  useEffect(() => {
    if (
      searchDetailsList.length &&
      searchDetailsList.map(d => d.isSearchGoingOn).includes(true) &&
      currentPage !== 1
    ) {
      prefetchNextResult();
    } else if (isSearchGoingOn && currentPage !== 1) {
      prefetchNextResult();
    }
  }, [currentPage]);

  //TODO: update here
  useEffect(() => {
    if (message) storeDispatch(hideMessage());
    storeDispatch(hideContactMessage());
  }, [message, contactsShowMessage]);

  useEffect(() => {
    if (loader && searchType !== '') {
      stopProgress();
      let isLoadingFirst = isLoadingFirstPage();
      let expectedTime = expectedAPITime;

      switch (searchType) {
        case 'T1':
          expectedTime = isLoadingFirst ? T1_page1 : T1_page2;
          break;
        case 'T2':
          expectedTime = isLoadingFirst ? T2_page1 : T2_page2;
          break;
        case 'T3':
          expectedTime = isLoadingFirst ? T3_page1 : T3_page2;
          break;
        default:
          break;
      }

      setexpectedAPITime(expectedTime);

      let counterCopy = 0;
      window.progressInterval = setInterval(() => {
        counterCopy = startProgress(counterCopy, expectedTime);
      }, 1000);
    } else stopProgress();
  }, [loader, searchType]);

  //unmounting

  /** to clear time interval on component unmount
   * //TODO: see if we can remove this part as well
   */
  useEffect(() => {
    return () => {
      setUserClickedSearchFirstTime(false);
      clearSNTimeInterval();
      clearEnrichTimeInterval();
    };
  }, []);

  /**
   * useEffect to clear message notifcation
   */
  useEffect(() => {
    return () => {
      storeDispatch(hideMessage());
      storeDispatch(hideContactMessage());
    };
  }, []);

  /**
   * useEffect to reset scroll of result grid on pagination
   */
  const resultsScroll = useRef();
  useEffect(() => {
    if (resultsScroll.current) {
      resultsScroll.current.scrollTo(0, 0);
    }
  }, [currentPage]);

  //#endregion

  //#region --> utils function

  const handleGoPrevious = changePage => setCurrentPage(changePage);
  const handleGoNext = changePage => setCurrentPage(changePage);

  const checkHowManySelected = () => currentPageList.filter(item => item.checked.value);
  const selectedjobs = () => searchResult.filter(contact => contact.selected);

  const isReachedEndOfPage = () => {
    if (searchDetailsList.length) return currentPage > searchResult.length / perPage && searchDetailsList.map(d => d.isEndOfRecord).every((el, i, arr) => el === arr[0]);
    return currentPage > searchResult.length / perPage && isEndOfRecord;
  };

  const paginationLoading = () => {
    if (searchDetailsList.length)
      return (
        searchDetailsList.map(d => d.isPrefetchGoingOn).some(el => el === true) &&
        currentPage > searchResult.length / perPage
      );
    return (isPrefetchGoingOn && currentPage > searchResult.length / perPage);
  }

  const startProgress = (counter, expectedTime) => {
    if (counter >= expectedTime) {
      stopProgress();
    } else {
      counter += 1;
      setCounter(counter);
    }
    return counter;
  };

  const stopProgress = () => {
    if (window.progressInterval) window.clearInterval(window.progressInterval);
    window.progressInterval = undefined;
    // setProgressBar(0);
  };

  const timeLeftToLoadAPI = () => {
    // let timeLeft = expectedAPITime - Math.round(progressBar/(100/expectedAPITime));
    let timeLeft = expectedAPITime - counter;
    if (timeLeft > 0) return `${timeLeft} seconds left...`;
    //if(isExepctAPITimeOver)
    else return "It's taking more time than usual";
  };

  const showError = message => {
    storeDispatch(displayMessage(message));
  };

  const noResultFound = () => {
    if (searchDetailsList.length && isEmpty(searchDetails) && searchResult.length > 0) return false;
    if (searchDetailsList.length && isEmpty(searchDetails)) return true;
    if (!isEmpty(searchDetails) && searchResult.length > 0) return false;
    if (!isEmpty(searchDetails)) return true;
    return false;
  };

  const isLoadingFirstPage = () => {
    if (!isEmpty(searchDetails) && searchDetails.searchIterationsDoneCount == 0)
      //1 page
      return true;
    else if (isEmpty(searchDetails)) return true;

    return false;
  };

  const clearSNTimeInterval = () => {
    if (window.snPullInterval != undefined && window.snPullInterval != 'undefined') {
      window.clearInterval(window.snPullInterval);
    }

    if (window.pullTimeOutInterval != undefined && window.pullTimeOutInterval != 'undefined') {
      window.clearTimeout(window.pullTimeOutInterval);
    }
  };

  const clearEnrichTimeInterval = () => {
    if (window.enrichPullInterval != undefined && window.enrichPullInterval != 'undefined') {
      window.clearInterval(window.enrichPullInterval);
    }
  };

  //#endregion

  //#region --> search submit related functions

  //Atleast one should be filled up
  const validateForm = formData => {
    let keys = Object.keys(formData);
    let found = keys.filter(
      item =>
        (formData[item].hasOwnProperty('chip') && formData[item].chip.chipData.length > 0) ||
        (!formData[item].hasOwnProperty('chip') && formData[item].value != '')
    );

    if (found.length > 0) return true;
    return false;
  };

  const prefetchNextResult = () => {
    if (searchDetailsList.length) {
      let recordNeed = (currentPage + 2) * perPage;
      let recordGot = searchResult.length;
      if (
        recordNeed > recordGot &&
        !searchDetailsList.map(d => d.isPrefetchGoingOn).some((el) => el === true) &&
        !searchDetailsList.map(d => d.isEndOfRecord).some((el) => el === true)
      ) {
        let searchInputArr = [];
        searchInputArr = searchDetailsList
          .map(d => d.payloadForm)
          .map(i => {
            i.intentSpec.pageNum += 1;
            return { ...i };
          });
        // dispatch prefetch action
        storeDispatch(startPrefetchSearchList({ searchInputArr, recordNeed, recordGot }));
      }
    } else {
      let recordNeed = (currentPage + 2) * perPage;
      let recordGot = searchResult.length;
      if (recordNeed > recordGot && !isPrefetchGoingOn && !isEndOfRecord) {
        let searchInput = {};
        searchInput = { ...searchDetails.payloadForm };
        searchInput.intentSpec.pageNum += 1;
        // dispatch prefetch action
        storeDispatch(startPrefetchSearch({ searchInput, recordNeed, recordGot }));
      }
    }
  };

  const companySearchSubmit = (formData, selectedST) => {
    try {
      let searchInput = {};
      if (!formData) {
        // undefined in case of next page call
        searchInput = { ...searchDetails.payloadForm };
        searchInput.intentSpec.pageNum += 1;
        // dispatch prefetch action
        storeDispatch(startPrefetchSearch({ searchInput }));
      } else {
        emptyCompanyStoreData();
        setUserClickedSearchFirstTime(true);
        storeDispatch(clearSearchResult());
        storeDispatch(updateSearchType(selectedST));

        searchInput = getSearchPayloadFromSearchType(
          formData,
          selectedST,
          { uid: userDetails.uid, cid: companyDetails.cid },
          1
        );
        if (Array.isArray(searchInput)) {
          storeDispatch(startSearchListResult(searchInput));
        } else {
          storeDispatch(startSearchResult(searchInput));
        }
      }
    } catch (error) {
      console.log(error.message);
      storeDispatch(displayMessage('Something went wrong'));
    }
  };

  const emptyCompanyStoreData = () => {
    unSelectAllList();
    setCurrentPage(1);
    setCounter(0);
    //TODO: which resets snDisplayList component
    // storeDispatch(clearSNCompanyTaskResult());
  };

  //#endregion

  //#region --> handle click event functions
  /**
   * each item click event
   * @param {Number} clickedIndex
   */
  const onindividualCheckChange = clickedIndex => {
    let selectedIndex = (currentPage - 1) * perPage + clickedIndex;
    let changeList = currentPageList.map((item, i) => {
      if (i == clickedIndex) {
        item.checked.value = !item.checked.value;
        searchResult[selectedIndex].selected = !searchResult[selectedIndex].selected;

        storeDispatch(setSearchSelectUpdated(searchResult));
      }

      return item;
    });

    let selectedList = checkHowManySelected();
    if (selectedList.length == currentPageList.length) setAllItemSelected(true);
    else setAllItemSelected(false);

    setCurrentPageList([...changeList]);
  };

  /**
   * select All
   */
  const onAllCheckChange = () => {
    let list;
    let selctAllItems = false;
    let checkedList = checkHowManySelected();
    checkedList.length === 0
      ? (selctAllItems = true)
      : checkedList.length === currentPageList.length
      ? (selctAllItems = false)
      : (selctAllItems = true);

    let checkedItemList = currentPageList.map(item => {
      item.checked.value = selctAllItems;
      return item;
    });

    //If all items need to select
    if (selctAllItems)
      list = searchResult.map(job => {
        return {
          ...job,
          selected: true,
        };
      });
    else
      list = searchResult.map(job => {
        return {
          ...job,
          selected: false,
        };
      });

    setAllItemSelected(selctAllItems);
    setCurrentPageList([...checkedItemList]);

    storeDispatch(setSearchSelectUpdated(list));
  };

  /* Un select list items if selected in prior search */
  const unSelectAllList = () => {
    if (allItemSelected) {
      let checkedItemList = currentPageList.map(item => {
        item.checked.value = false;
        return item;
      });

      let list = searchResult.map(job => {
        return {
          ...job,
          selected: false,
        };
      });

      setAllItemSelected(false);
      setCurrentPageList([...checkedItemList]);

      storeDispatch(setSearchSelectUpdated(list));
    }
  };

  const downloadFile = () => {
    const selectedItems = selectedjobs();
    handleDownloadSearchCSV(selectedItems, 'company');
  };

  //for company search
  const handleFind = event => {
    let selectedItems = checkHowManySelected();
    setDecisionModal(true);
    if (selectedItems.length === 0)
      return storeDispatch(displayMessage('Please select at least one contact'));
  };

  const moreInfo = (infoRow, field) => {
    switch (field) {
      default:
        console.log('something went wrong');
        break;
    }
  };

  const handleResultListItemClick = (infoRow, field) => {
    let type = infoRow[field].clickType;
    let selectedIndex = infoRow.id.value;
    let enrichDetails;

    switch (type) {
      default:
        console.log('Click type not found');
        break;
    }
  };

  //#endregion

  return (
    <div>
      <div>
        <div>
          <div>
            <div className={classes.box}>
              <div className={classes.top}>
                <div>
                  <h1 style={{ margin: '20px 20px 0.2rem', fontWeight: 600 }}>Pipeline Builder</h1>
                  <p style={{ margin: 0, marginLeft: '20px', fontWeight: 600 }}>
                    Find accounts that show buying signals. Build custom cohorts for targeted
                    SDR/paid ad campaigns.
                  </p>
                </div>
                <>
                  {/* Top bar in right section */}
                  {!loader && currentPageList.length > 0 && (
                    <div style={{ display: 'grid', alignItems: 'end' }}>
                      <div className='d-flex justify-content-end mt-2 mr-3'>
                        <>
                          <IconButton
                            className='jr-btn'
                            size='medium'
                            variant='contained'
                            color='primary'
                            onClick={handleFind}
                            enable_icon={require('assets/images/person_search-white-18dp.svg')}
                            disable_icon={require('assets/images/person_search-black-18dp.svg')}
                            disabled={checkHowManySelected().length === 0}
                          >
                            <span>Find Decision Makers ({fdmSelected.length})</span>
                          </IconButton>
                          {/* <IconButton
                            className='jr-btn'
                            variant='contained'
                            color='primary'
                            enable_icon={require('assets/images/cloud_download-white-18dp.svg')}
                            disable_icon={require('assets/images/cloud_download-black-18dp.svg')}
                            // disabled={checkHowManySelected().length === 0}
                            onClick={downloadFile}
                          >
                            <span>Download</span>
                          </IconButton>

                          <IconButton
                            className='jr-btn'
                            variant='contained'
                            color='primary'
                            aria-label='add'
                            onClick={() => setMovepopupOpen(!movepopupOpen)}
                            enable_icon={require('assets/images/multiple_stop-white-18dp.svg')}
                            disable_icon={require('assets/images/multiple_stop-black-18dp.svg')}
                            // disabled={checkHowManySelected().length == 0}
                          >
                            <span>Save To List</span>
                          </IconButton> */}
                        </>
                      </div>
                    </div>
                  )}
                </>
              </div>
              <div className={classes.bottom}>
                <div className={classes.searchBox}>
                  <Paper className={classes.switchPaper}>
                    <TagsInput
                      searchSubmit={companySearchSubmit}
                      searchType={searchType}
                      loading={loader}
                      setContextSearch={setContextSearch}
                      setGeoForward={setGeoForward}
                    />
                  </Paper>
                </div>
                {/* resultbox */}
                <div className={classes.resultBox}>
                  <div>
                    {!userClickedSearchFirstTime && !loader && (
                      <div className={classes.beforeSearch}>
                        <img
                          style={{ maxWidth: '500px', height: '70%' }}
                          src={require('assets/images/cue_screen.svg')}
                          alt={'no result found'}
                        />
                        {/* <p>
                          Find companies that are using a particular technology, belong <br />
                          to particular industry etc. across 10 Million company profiles.
                        </p> */}
                      </div>
                    )}
                    {/* Loading */}
                    {loader &&
                      (isLoadingFirstPage() ? (
                        <div className={classes.loadingSearch}>
                          <img
                            style={{ maxWidth: '500px' }}
                            src={require('assets/images/search_in_progress.svg')}
                            alt={'loading'}
                          />
                          {/* <p>Searching across 10 Million companies...</p> */}
                          <p>{timeLeftToLoadAPI()}</p>
                        </div>
                      ) : (
                        <div className={classes.loadingSearch}>
                          <img
                            src={require('assets/images/SearchIllustration.svg')}
                            alt={'loading'}
                          />
                          <p>Fetching next batch of records...</p>
                          <p>{timeLeftToLoadAPI()}</p>
                        </div>
                      ))}
                    {/* is not loading and NO data */}
                    {!loader && userClickedSearchFirstTime && noResultFound() && (
                      <div className={classes.notFound}>
                        <img
                          style={{
                            width: '100%',
                            maxWidth: '500px',
                            marginBottom: '60px',
                          }}
                          src={require('assets/images/no_result.svg')}
                          alt='seaBottle'
                        />
                        {/* <h3>
                          No results found for this search criteria. Please try with a <br />{' '}
                          different filter.
                        </h3>
                        <div>
                          <p>
                            Search Tips:
                            <li>Try widening your search with more keywords.</li>
                            <li>Use less filters fields.</li>
                          </p>
                        </div> */}
                      </div>
                    )}
                    {/* Is not loading and has data and not has clicked first Time */}
                    {!loader && userClickedSearchFirstTime && searchResult.length > 0 && (
                      <div className={classes.resultGrid}>
                        <div className={classes.searchScroll}>
                          <ResultListTable
                            result={currentPageList}
                            showFilter={showFilter}
                            selectionAvailable={true}
                            onAllCheckChange={onAllCheckChange}
                            onindividualCheckChange={onindividualCheckChange}
                            moreInfo={moreInfo}
                            autoCompleteData={companyListNames}
                            itemClick={handleResultListItemClick}
                            // autoSelected={handleListItemSelected}
                            // autonewItemCreated={handleNewListItemCreated}
                            className={classes.background}
                            resultType='companySearch'
                            ref={resultsScroll}
                          />
                        </div>
                        <TablePagination
                          currentPage={currentPage}
                          isreachedEnd={isReachedEndOfPage}
                          handleGoPrevious={handleGoPrevious}
                          handleGoNext={handleGoNext}
                          loading={paginationLoading()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            {/* <Backdrop className={classes.backdrop} open={movepopupOpen}>
            <MoveContactsPopup
              open={movepopupOpen}
              data={companyListNames}
              selected={getDefaultCompanyListName}
              closePopup={() => setMovepopupOpen(false)}
              moveToSelectedListName={multiSaveOrMoveToSelectedListName}
              moveToNewListName={multiSaveOrMoveToNewListName}
            />
            )
          </Backdrop>

          <Backdrop className={classes.backdrop} open={openDefaultListName}>
            <div className='popup-content'>
              <div className='align-self-end'>
                <h6
                  className='font-weight-light pointer m-2'
                  onClick={handlecloseOpenDefaultListName}
                >
                  Close
                </h6>
              </div>
              <div className='d-flex flex-column flex-grow-1'>
                <p className='text-dark'>Choose default list name ?</p>
                <form className='flex-grow-1'>
                  <AutoCompleteListName
                    selected={getDefaultCompanyListName()}
                    data={companyListNames}
                    enabled={true}
                    dropDownSelected={handleSetDropDownSelected}
                    dropDownNewItemCreated={handleSetDropDownNewItemCreated}
                  />
                </form>
                <div className='d-flex justify-content-end'>
                  <IconButton
                    className='jr-btn'
                    variant='contained'
                    aria-label='cancel'
                    onClick={cancelDefaultListpopup}
                  >
                    <span>Cancel</span>
                  </IconButton>

                  {existingListNameBool && (
                    <IconButton
                      className='jr-btn'
                      size='small'
                      color='primary'
                      variant='contained'
                      aria-label='set'
                      onClick={handleSetDefault}
                    >
                      <span>Set</span>
                    </IconButton>
                  )}

                  {createNewAndSetBool && (
                    <IconButton
                      className='jr-btn'
                      size='small'
                      color='primary'
                      variant='contained'
                      aria-label='set'
                      onClick={handleCreateNewSetDefault}
                    >
                      <span>Create & Set</span>
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </Backdrop> */}
          </>

          <Backdrop className={classes.backdrop} open={contactsLoader}>
            <div className='fixContainer'>
              <div className='loader-view'>
                <CircularProgress />
              </div>
            </div>
          </Backdrop>

          {message !== '' && messageType === 'error' && NotificationManager.error(message)}
          {message !== '' && messageType === 'success' && NotificationManager.success(message)}

          {contactsShowMessage != '' &&
            contactsAlertMessage != '' &&
            NotificationManager.error(contactsAlertMessage)}
          {contactsShowMessage &&
            contactsInfoMessage != '' &&
            NotificationManager.success(contactsInfoMessage)}

          <NotificationContainer />
        </div>
      </div>
      {/* Modal */}
      <Modal className={classes.modal} open={decisionModal} onClose={() => setDecisionModal(false)}>
        <DecisionMakerPopup
          name={''}
          closeModal={() => setDecisionModal(false)}
          headerText={'Find Decision Makers'}
          buttonText={'Submit'}
          selectedItems={checkHowManySelected()}
          fdmSelectedUniqueItem={fdmSelected}
          geoForward={geoForward}
        />
      </Modal>
    </div>
  );
};

export default Search;
