import {
  SUBSCRIBE_PAYMENT,
  SUBSCRIBE_PAYMENT_SUCCESS,
  PAYMENT_FAILED,
  PAYMENT_RESET,
  HIDE_MESSAGE,
} from 'constants/ActionTypes';

export const subscribePayment = tid => {
  return {
    type: SUBSCRIBE_PAYMENT,
    tid: tid,
  };
};

export const subscribePaymentSuccess = data => {
  return {
    type: SUBSCRIBE_PAYMENT_SUCCESS,
    payload: data,
  };
};

export const paymentReset = () => {
  return {
    type: PAYMENT_RESET,
  };
};

export const paymentFailed = message => {
  return {
    type: PAYMENT_FAILED,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
