import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../../components/IconButton';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ResultListTable from 'components/resultListTable';
import CustomScrollbars from 'util/CustomScrollbars';
import moment from 'moment';

import {
  showAuthLoader,
  hideAuthLoader,
  hideMessage,
} from 'actions/Auth';
import { isEmpty } from '../../../util';

import { auth } from '../../../firebase/firebase';

import {
  baseSubscriptionUrl,
  subscriptionUser,
  getCompanyUsersMethod,
  getCompanyDetailsMethod,
  addUserToTierMethod,
  removeUserFromTierMethod,
  assignStdCreditsToUserMethod,
  assignPremCreditsToUserMethod,
  getCompanyTierMethod,
} from 'util/urls';
import TransitionModal from 'components/TransistionModal';
import {
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  NativeSelect,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    // margin : 'auto'
  },
  smallPaper: {
    padding: theme.spacing(2),
    maxWidth: 250,
  },
  tierInfo: {
    borderLeft: `5px solid #38BF08`,
    paddingLeft: 5,
    margin: theme.spacing(3, 0),
  },
  tierText: {
    display: 'inline-block',
    background: '#38BF08',
    padding: '1px 5px',
    color: '#fff',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0),
    '& > div': {
      padding: 0,
    },
    '& > select:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  pageHeader: {
    margin: theme.spacing(2, 3),
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  headerText: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  pageStats: {
    display: 'flex',
  },
  statBox: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  statHeading: {
    fontWeight: 600,
  },
  statContent: {
    fontWeight: 600,
    fontSize: '2rem',
    color: '#000',
  },
  secondaryStatContent: {
    marginTop: theme.spacing(1),
    fontSize: '1rem',
    color: theme.palette.text.primary,
  },
  fontBold: {
    fontWeight: 600,
  },
  backLink: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const creditTypeList = [
  { name: 'Standard', value: 'Standard' },
  { name: 'Premium', value: 'Premium' },
];

const ManageUsers = props => {
  const initialState = {
    selectedUserId: { value: '', required: false },
    changeName: { value: '', required: false },
    changeTierName: { value: '', required: false },
    assignName: { value: '', min: 3, required: false },
    creditType: { value: '', required: false },
    assignAmount: { value: '', required: true, message: 'Valid amount' },
  };

  const {
    loader,
    alertMessage,
    showMessage,
    infoMessage,
    companyDetails,
    userDetails,
  } = useSelector(({ auth }) => auth);
  const [usersList, setUsersList] = useState([]);
  const [usersListResponse, setUsersListResponse] = useState([]);

  const [companyStatInfo, setCompanyStatInfo] = useState({
    tierName: '',
    seatsTotal: 0,
    seatsUsed: 0,
    premCreditTotal: 0,
    premCreditUsed: 0,
    stanCreditTotal: 0,
    stanCreditUsed: 0,
    nextRenDate: '',
  });
  const [tierList, setTierList] = useState([]);
  const [showPopupLoader, setShowPopupLoader] = useState(false);
  const [subsccompanyDetails, setSubsccompanyDetails] = useState({});
  const [openchangeTierPopup, setopenchangeTierPopup] = useState(false);
  const [changeTierLoader, setChangeTierLoader] = useState(false);
  const [changeTierMessage, setChangeTierMessage] = useState({
    type: 'info',
    msg: '',
  });
  const [assignCreditMessage, setAssignCreditMessage] = useState({
    type: 'info',
    msg: '',
  });
  const [assignCreditLoader, setAssignCreditLoader] = useState(false);
  const [openAssignCreditPopup, setOpenAssignCreditPopup] = useState(false);
  const [formError, setFormError] = useState('');
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const classes = useStyles();
  const [userSubDetails, setUserSubDetails] = useState({});
  const [ownerType, setOwnerType] = useState(userDetails.rol);

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      case 'reset':
        return initialState;
      default:
        throw new Error();
    }
  }

  const clearForm = () => {
    dispatch({ type: 'reset' });
    if (formError != '') setFormError('');
    setChangeTierMessage({ type: 'info', msg: '' });
    setAssignCreditMessage({ type: 'info', msg: '' });
  };

  useEffect(() => {
    if (changeTierMessage.msg) {
      setTimeout(() => {
        setChangeTierMessage(prev => ({ ...prev, msg: '' }));
      }, 3000);
    }
    if (assignCreditMessage.msg) {
      setTimeout(() => {
        setAssignCreditMessage(prev => ({ ...prev, msg: '' }));
      }, 3000);
    }
  }, [changeTierMessage.msg, assignCreditMessage.msg]);

  useEffect(() => {
    if (showMessage) {
      // setTimeout(() => {
      storeDispatch(hideMessage());
      // }, 1000);
    }
  }, [showMessage]);

  useEffect(() => {
    if (userDetails) {
      if (ownerType === 'Admin') {
        getUsersList();
      } else {
        getSubUsers();
      }
    }
  }, [userDetails]);

  const handleOnChange = ({ target: { name, value } }) => {
    if (name === 'assignAmount' && parseInt(value) > 15) {
      setAssignCreditMessage({
        type: 'error',
        msg: 'Maximum 15 Credits can be assigned',
      });
      dispatch({ type: 'addInput', payload: { name, value: '15' } });
      return;
    }
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const handleChangeNameSelection = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
    dispatch({
      type: 'addInput',
      payload: { name: 'selectedUserId', value: value },
    });
    //update tier type
    if (openchangeTierPopup) setTierDropdown(value);
  };

  //open popup
  const handleChangeTier = () => {
    setopenchangeTierPopup(true);
    dispatch({
      type: 'addInput',
      payload: { name: 'changeName', value: usersList[0].Name },
    });
    dispatch({
      type: 'addInput',
      payload: { name: 'changeTierName', value: usersList[0].TierName },
    });
    setTierDropdown();
  };

  //open popup
  const handleAssignCredit = () => {
    setOpenAssignCreditPopup(true);
    dispatch({
      type: 'addInput',
      payload: { name: 'assignName', value: usersList[0].Name },
    });
    dispatch({
      type: 'addInput',
      payload: { name: 'creditType', value: creditTypeList[0].value },
    });
    dispatch({
      type: 'addInput',
      payload: { name: 'assignAmount', value: '' },
    });
    setFirstUserAsSelected();
  };

  const handleChangeClosePopup = () => {
    setopenchangeTierPopup(false);
    clearForm();
  };

  const handleAssignClosePopup = () => {
    setOpenAssignCreditPopup(false);
    clearForm();
  };

  const handleChangeUpdate = async () => {
    if (isEmpty(companyDetails) && isEmpty(userDetails))
      setChangeTierMessage({
        type: 'error',
        msg: 'These details must be there',
      });
    else if (
      state.changeTierName.value !== 'Lite' &&
      subsccompanyDetails.seatsTotal - subsccompanyDetails.seatsUsed === 0
    )
      setChangeTierMessage({
        type: 'error',
        msg: "Sorry!! you have exhausted all seats. Can't allocate seat",
      });
    // setFormError("Sorry!! you have exhausted all seats. Can't allocate seat");
    else {
      let changeTierResponse;

      let tierObjInd = usersListResponse.tierList.findIndex(
        i => i.tnm === state.changeTierName.value
      );
      // setShowPopupLoader(true);
      setChangeTierLoader(true);

      if (state.changeTierName.value === 'Lite')
        changeTierResponse = await postAPI(
          {
            uid: state.selectedUserId.value,
          },
          removeUserFromTierMethod
        );
      else
        changeTierResponse = await postAPI(
          {
            uid: state.selectedUserId.value,
            tid: usersListResponse.tierList[tierObjInd].tid,
          },
          addUserToTierMethod
        );

      if (changeTierResponse.hasOwnProperty('Error')) {
        setChangeTierMessage({
          type: 'error',
          msg: changeTierResponse.Error.Msg,
        });
        // setFormError(changeTierResponse.Error.Msg);
      } else {
        setChangeTierMessage({
          type: 'success',
          msg: 'Details Updated successfullly',
        });
        setTimeout(() => {
          handleChangeClosePopup();
        }, 3000);
        getUsersList();
      }
      setChangeTierLoader(false);
    }
  };

  const handleAssign = async () => {
    if (isEmpty(companyDetails) && isEmpty(userDetails)) console.log('These details must be there');
    else if (state.assignAmount.value === '' || parseInt(state.assignAmount.value) <= 0)
      setAssignCreditMessage({
        type: 'error',
        msg: 'Please enter valid amount',
      });
    // setFormError("Please enter valid amount");
    else if (
      state.creditType.value === 'Standard' &&
      parseInt(state.assignAmount.value) >
        subsccompanyDetails.stanCredTotal - subsccompanyDetails.stanCredUsed
    )
      setAssignCreditMessage({
        type: 'error',
        msg: 'Sorry!! your company has exhausted standard credits',
      });
    // setFormError("Sorry!! your company has exhausted standard credits");
    else if (
      state.creditType.value === 'Premium' &&
      parseInt(state.assignAmount.value) >
        subsccompanyDetails.premCredTotal - subsccompanyDetails.premCredUsed
    )
      setAssignCreditMessage({
        type: 'error',
        msg: 'Sorry!! your company has exhausted premium credits',
      });
    // setFormError("Sorry!! your company has exhausted premium credits");
    else if (isLiteUser())
      setAssignCreditMessage({
        type: 'error',
        msg: 'Sorry!! Cannot add credits to Lite user',
      });
    // setFormError("Sorry!! Cannot add credits to Lite user");
    else {
      let assignResponse;

      setAssignCreditLoader(true);
      // setShowPopupLoader(true);

      if (state.creditType.value == 'Standard')
        assignResponse = await postAPI(
          {
            uid: state.selectedUserId.value,
            cid: companyDetails.cid,
            amount: parseInt(state.assignAmount.value),
          },
          assignStdCreditsToUserMethod
        );
      else if (state.creditType.value == 'Premium')
        assignResponse = await postAPI(
          {
            uid: state.selectedUserId.value,
            cid: companyDetails.cid,
            amount: state.assignAmount.value,
          },
          assignPremCreditsToUserMethod
        );

      if (assignResponse.hasOwnProperty('Error')) {
        setAssignCreditMessage({
          type: 'error',
          msg: assignResponse.Error.Msg,
        });
        // setFormError(assignResponse.Error.Msg);
      } else {
        setAssignCreditMessage({
          type: 'success',
          msg: 'Details Updated successfullly',
        });
        setTimeout(() => {
          handleAssignClosePopup();
        }, 3000);
        getUsersList();
      }
      setAssignCreditLoader(false);
      // setShowPopupLoader(false);
    }
  };

  const getAuthToken = async () => await auth.currentUser.getIdToken();

  async function getUsersList() {
    if (isEmpty(companyDetails) && isEmpty(userDetails)) {
      console.log('These details must be there');
    } else {
      storeDispatch(showAuthLoader());
      const usersListresponse = await postAPI(
        {
          cid: companyDetails.cid,
        },
        getCompanyUsersMethod
      );

      const companyDetailsresponse = await postAPI(
        {
          cid: companyDetails.cid,
        },
        getCompanyDetailsMethod
      );

      await getCompanyTierDetails();

      if (
        usersListresponse &&
        !usersListresponse.hasOwnProperty('Error') &&
        companyDetailsresponse &&
        !companyDetailsresponse.hasOwnProperty('Error')
      ) {
        let modifiedList = [];
        usersListresponse.users.map(item => {
          let tierType = usersListresponse.tierList.filter(i => i.tid == item.tid);
          modifiedList.push({
            Name: item.fn + ' ' + item.ln,
            Role: item.rol,
            TierName: tierType[0].tnm,
            // "Tier": item.tid,
            'Standard Credits Used': item.stanCredUsed,
            'Standard Credits Left': item.stanCredTot - item.stanCredUsed,
            'Premium Credits Used': item.premCredUsed,
            'Premium Credits Left': item.premCredTot - item.premCredUsed,
            id: item.uid,
          });
        });
        setUsersList(modifiedList);
        setUsersListResponse(usersListresponse);
        setSubsccompanyDetails(companyDetailsresponse);

        setCompanyStatInfo(prevState => ({
          ...prevState,
          premCreditTotal: companyDetailsresponse.premCredTotal,
          premCreditUsed: companyDetailsresponse.premCredUsed,
          seatsTotal: companyDetailsresponse.seatsTotal,
          seatsUsed: companyDetailsresponse.seatsUsed,
          stanCreditTotal: companyDetailsresponse.stanCredTotal,
          stanCreditUsed: companyDetailsresponse.stanCredUsed,
          nextRenDate: companyDetailsresponse.nextRenDate,
        }));
      }

      storeDispatch(hideAuthLoader());
    }
  }

  async function getCompanyTierDetails() {
    if (isEmpty(companyDetails)) {
      console.log('strange!!🤯 these details must be popluated in the store');
      return;
    }
    try {
      const companyTierInfo = await postAPI(
        {
          cid: companyDetails.cid,
        },
        getCompanyTierMethod
      );

      setCompanyStatInfo(prevState => ({
        ...prevState,
        tierName: companyTierInfo.tnm,
      }));
      console.log('after getting comapny teir', companyStatInfo);
    } catch (error) {
      console.log(error);
    }
  }

  const setTierDropdown = selectedUserId => {
    console.log('uid ', selectedUserId);

    if (subsccompanyDetails.tierId == 0)
      //all users would be in lite
      setTierList([{ name: 'Lite', value: 0 }]);
    else {
      let selctedUserItem;

      //get tid from uid
      if (selectedUserId)
        selctedUserItem = usersListResponse.users.filter(item => item.uid == selectedUserId);
      else selctedUserItem = setFirstUserAsSelected();

      let usersTierObj = usersListResponse.tierList.filter(i => i.tid == selctedUserItem[0].tid); //from user
      let tiers = [];
      tiers.push({
        name: 'Lite',
        value: 0,
        disable: usersTierObj[0].tnm == 'Lite',
      });
      let tierObj = usersListResponse.tierList.filter(i => i.tid == subsccompanyDetails.tierId); //from company
      tiers.push({
        name: tierObj[0].tnm,
        value: tierObj[0].tid,
        disable: usersTierObj[0].tnm != 'Lite',
      });
      setTierList(tiers);
    }
  };

  const setFirstUserAsSelected = () => {
    dispatch({
      type: 'addInput',
      payload: {
        name: 'selectedUserId',
        value: usersListResponse.users[0].uid,
      },
    });
    return usersListResponse.users;
  };

  const isLiteUser = () => {
    let selctedUserItem = usersListResponse.users.filter(
      item => item.uid == state.selectedUserId.value
    );
    if (selctedUserItem[0].tid == 0) return true;
    return false;
  };

  const postAPI = async (payload, method) => {
    const token = await getAuthToken();
    let headers = new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    return await fetch(baseSubscriptionUrl + subscriptionUser + method, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(err => err);
  };

  // users details
  const getSubUsers = async () => {
    storeDispatch(showAuthLoader());
    if (userDetails) {
      const res = await postAPI(
        {
          uid: userDetails.uid,
        },

        'getUserSubDetails'
      );
      setUserSubDetails({ ...res, seatsTotal: 1, seatsUsed: 1 });

      let updatedUser = [];

      updatedUser.push({
        Name: res.fn + ' ' + res.ln,
        Role: res.rol,
        Tier: res.tnm,
        'Standard Credits Used': res.stanCredUsed,
        'Standard Credits Left': res.stanCredTot - res.stanCredUsed,
        'Premium Credits Used': res.premCredUsed,
        'Premium Credits Left': res.premCredTot - res.premCredUsed,
      });

      setUsersList(updatedUser);

      storeDispatch(hideAuthLoader());
    }
  };

  const { premCredTot, premCredUsed, stanCredTot, stanCredUsed } = userSubDetails;

  const userPremSum = premCredTot - premCredUsed;
  const userStanSum = stanCredTot - stanCredUsed;
  const compStanSum = companyStatInfo.stanCreditTotal - companyStatInfo.stanCreditUsed;
  const compPremSum = companyStatInfo.premCreditTotal - companyStatInfo.premCreditUsed;

  let stanCreditInfo = null;
  let premCreditInfo = null;

  switch (ownerType) {
    case 'Admin':
      stanCreditInfo = compStanSum;
      premCreditInfo = compPremSum;
      break;
    case 'User':
      stanCreditInfo = userStanSum;
      premCreditInfo = userPremSum;
      break;
    default:
  }

  // valid date
  let formattedDate = null;

  if (ownerType === 'Admin') {
    if (companyStatInfo.nextRenDate === '') {
      formattedDate = 'N/A';
    } else {
      formattedDate = moment(companyStatInfo.nextRenDate).format('MMM d, YYYY');
    }
  } else {
    if (userSubDetails.nextRenDate === '') {
      formattedDate = 'N/A';
    } else {
      formattedDate = moment(userSubDetails.nextRenDate).format('MMM d, YYYY');
    }
  }

  return (
    <div className='app-wrapper'>
      <div className='app-module animated slideInUpTiny animation-duration-3'>
        <div className='module-box max-100p'>
          <div className={classes.pageHeader}>
            <Typography
              variant='subtitle2'
              className={classes.backLink}
              onClick={() => props.history.goBack()}
            >
              {'< '}Back
            </Typography>
            <div className={classes.headerText}>
              <Typography variant='h5' className={classes.fontBold}>
                User Management
              </Typography>
              <Typography variant='subtitle2' gutterBottom>
                Assign seats, add/limit credits, change tiers of users
              </Typography>
            </div>
            <Divider />
            <div className={classes.pageStats}>
              <StatBox
                title='Tier'
                content={
                  loader
                    ? '...'
                    : ownerType === 'Admin'
                    ? companyStatInfo.tierName
                    : userSubDetails.tnm
                }
              />
              <StatBox
                title='Seats'
                content={
                  loader
                    ? '...'
                    : ownerType === 'Admin'
                    ? companyStatInfo.seatsUsed
                    : userSubDetails.seatsUsed
                }
                secondayContent={`used of ${
                  loader
                    ? '...'
                    : ownerType === 'Admin'
                    ? companyStatInfo.seatsTotal
                    : userSubDetails.seatsTotal
                }`}
              />
              <StatBox
                title='Standard Credit'
                content={loader ? '...' : stanCreditInfo}
                secondayContent='unused'
              />
              <StatBox
                title='Premium Credit'
                content={loader ? '...' : premCreditInfo}
                secondayContent='unused'
              />
              <StatBox title='Next Renewal Date' content={loader ? '...' : formattedDate} />
            </div>
            <Divider />
          </div>
          {ownerType === 'Admin' && !loader && usersList.length > 0 && (
            <div className='d-flex justify-content-end mr-3'>
              <IconButton
                className='jr-btn'
                size='medium'
                variant='contained'
                color='primary'
                aria-label='add'
                enable_icon={require('assets/images/supervised_user_circle-white-18dp.svg')}
                disable_icon={require('assets/images/supervised_user_circle-black-18dp.svg')}
                onClick={handleChangeTier}
              >
                <span>Change Tier</span>
              </IconButton>

              <IconButton
                className='jr-btn'
                size='medium'
                variant='contained'
                color='primary'
                aria-label='add'
                enable_icon={require('assets/images/supervised_user_circle-white-18dp.svg')}
                disable_icon={require('assets/images/supervised_user_circle-black-18dp.svg')}
                onClick={handleAssignCredit}
              >
                <span>Assign Credit</span>
              </IconButton>
            </div>
          )}
          <div className='module-box-content module_table'>
            <div
              className='keep-center'
              style={{
                height: props.width >= 1200 ? 'calc(100vh - 300px)' : 'calc(100vh - 300px)',
              }}
            >
              {!loader && usersList.length == 0 && <strong> Sorry !! No results found.</strong>}

              {!loader && usersList.length > 0 && (
                <div className='flex-grow-1'>
                  <div className='col-12 p-0 mt-5'>
                    <CustomScrollbars
                      className='module-list-scroll scrollbar'
                      style={{
                        height: props.width >= 1200 ? 'calc(100vh - 300px)' : 'calc(100vh - 300px)',
                      }}
                    >
                      <ResultListTable
                        result={usersList}
                        showFilter={false}
                        selectionAvailable={false}
                      />
                    </CustomScrollbars>
                  </div>
                </div>
              )}
            </div>
          </div>
          {!loader && usersList.length > 0 && (
            <div className='m-2 ml-3'>
              <Paper className={classes.smallPaper}>
                <div className='row'>
                  <p className='jr-fs-l m-2 col-6'>
                    <IntlMessages id='manageUsers.seatsused' />
                  </p>
                  <p className='jr-fs-l m-2 col-3'>{subsccompanyDetails.seatsUsed}</p>

                  <p className='jr-fs-l m-2 col-6'>
                    <IntlMessages id='manageUsers.seatsleft' />
                  </p>
                  <p className='jr-fs-l m-2 col-3'>
                    {subsccompanyDetails.seatsTotal - subsccompanyDetails.seatsUsed}
                  </p>
                </div>
              </Paper>
            </div>
          )}
        </div>
      </div>

      <TransitionModal
        open={openchangeTierPopup}
        handleClose={handleChangeClosePopup}
        modalTitle='Change tier of a user'
        description='You can change user’s tier. Downgrading user tier will open up seat. Upgrading user tier will consume seat.'
        actionButtonText='Update'
        actionLoadingButtonText='Updating ...'
        actionButtonHandler={handleChangeUpdate}
        showMessage={!!changeTierMessage.msg}
        message={changeTierMessage.msg}
        severity={changeTierMessage.type}
        loading={changeTierLoader}
      >
        <div className={classes.tierInfo}>
          <Typography variant='body2' component='div'>
            You are in <span className={classes.tierText}>{companyStatInfo.tierName} Tier</span>{' '}
          </Typography>
          <Typography variant='body2' component='div'>
            You have consumed {subsccompanyDetails.seatsUsed} out of available{' '}
            {subsccompanyDetails.seatsTotal} seats
          </Typography>
        </div>
        <form>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor='changeName'>
              Select the user who you want to upgrade/downgrade
            </InputLabel>
            <NativeSelect
              name='changeName'
              onChange={handleChangeNameSelection}
              value={state.changeName.value}
              className='col-xs-12 col-lg-12'
              variant='outlined'
              inputProps={{
                id: 'changeName',
              }}
              size='small'
              fullWidth
              input={<BootstrapInput />}
              // label={<IntlMessages id="manageUsers.name" />}
            >
              {usersList.map((value, key) => (
                <option key={key} value={value.id}>
                  {value.Name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor='changeTierName'>
              Select the new tier for user
            </InputLabel>
            <NativeSelect
              name='changeTierName'
              onChange={handleOnChange}
              value={state.changeTierName.value}
              className='col-xs-12 col-lg-12'
              variant='outlined'
              inputProps={{
                id: 'changeName',
              }}
              size='small'
              fullWidth
              input={<BootstrapInput />}
            >
              {tierList.map((item, key) => (
                <option key={key} value={item.name} disabled={item.disable}>
                  {item.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </form>
      </TransitionModal>

      <TransitionModal
        open={openAssignCreditPopup}
        handleClose={handleAssignClosePopup}
        modalTitle='Assign credit to a user'
        description='You can assign credit to any user above and beyond their planned amount'
        actionButtonText='Assign'
        actionLoadingButtonText='Assiging ...'
        actionButtonHandler={handleAssign}
        showMessage={!!assignCreditMessage.msg}
        message={assignCreditMessage.msg}
        severity={assignCreditMessage.type}
        loading={assignCreditLoader}
      >
        <div className={classes.tierInfo}>
          <Typography variant='body2' component='div'>
            You have {subsccompanyDetails.premCredTotal - subsccompanyDetails.premCredUsed} premium
            credits left (out of {subsccompanyDetails.premCredTotal})
          </Typography>
          <Typography variant='body2' component='div'>
            You have {subsccompanyDetails.stanCredTotal - subsccompanyDetails.stanCredUsed} standard
            credits left (out of {subsccompanyDetails.stanCredTotal})
          </Typography>
        </div>
        <form>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor='assignName'>
              Select the user who you want to assign credit
            </InputLabel>
            <NativeSelect
              name='assignName'
              onChange={handleChangeNameSelection}
              value={state.assignName.value}
              className='col-xs-12 col-lg-12'
              variant='outlined'
              inputProps={{
                id: 'assignName',
              }}
              size='small'
              fullWidth
              input={<BootstrapInput />}
              // label={<IntlMessages id="manageUsers.name" />}
            >
              {usersList.map((value, key) => (
                <option key={key} value={value.id}>
                  {value.Name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor='creditType'>
              What type of credit you want to assign?
            </InputLabel>
            <NativeSelect
              name='creditType'
              onChange={handleOnChange}
              value={state.creditType.value}
              className='col-xs-12 col-lg-12'
              variant='outlined'
              inputProps={{
                id: 'creditType',
              }}
              size='small'
              fullWidth
              input={<BootstrapInput />}
              // label={<IntlMessages id="manageUsers.name" />}
            >
              {creditTypeList.map((value, key) => (
                <option key={key} value={value.name}>
                  {value.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor='assignAmount'>
              Chose the amount
            </InputLabel>
            <BootstrapInput
              type='number'
              id='assignAmount'
              name='assignAmount'
              onChange={handleOnChange}
              margin='none'
              className='col-xs-12 col-lg-12'
              fullWidth
              inputProps={{
                min: '1',
                max: '15',
              }}
              value={state.assignAmount.value}
            />
          </FormControl>
          <Typography variant='caption' component='div' gutterBottom>
            Maximum 15 Credits can be assigned
          </Typography>
        </form>
      </TransitionModal>

      {/* <Backdrop className={classes.backdrop} open={openchangeTierPopup}>
                <div>
                    <div className="pop-head">

                    </div>
                    <div className="module-box-content mt-0">
                        <Paper className={classes.switchPaper}>
                            <p className="jr-fs-xl m-2"><IntlMessages id="manageUsers.changeTier" /></p>
                            <div className="app-login-form">
                                <div className="m-2 ml-3">
                                    <Paper className={classes.smallPaper}>
                                        <div className="row">
                                            <p className="jr-fs-l m-2 col-6">Seats Used</p>
                                            <p className="jr-fs-l m-2 col-3">{subsccompanyDetails.seatsUsed}</p>

                                            <p className="jr-fs-l m-2 col-6">Seats Left</p>
                                            <p className="jr-fs-l m-2 col-3">{subsccompanyDetails.seatsTotal - subsccompanyDetails.seatsUsed}</p>
                                        </div>
                                    </Paper>
                                </div>
                                <form>
                                    <fieldset>
                                        <div className="row m-0">

                                            <TextField
                                                select
                                                type="name"
                                                name="changeName"
                                                onChange={handleChangeNameSelection}
                                                value={state.changeName.value}
                                                label={<IntlMessages id="manageUsers.name" />}
                                                margin="normal"
                                                className="col-xs-12 col-lg-12"
                                                variant="outlined"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                fullWidth

                                            >

                                                {usersList.map((value, key) => (
                                                    <option key={key} value={value.id}>
                                                        {value.Name}
                                                    </option>
                                                ))}

                                            </TextField>
                                            <TextField
                                                select
                                                type="changeTier"
                                                name="changeTierName"
                                                onChange={handleOnChange}
                                                value={state.changeTierName.value}
                                                label={<IntlMessages id="manageUsers.changeTier" />}
                                                margin="normal"
                                                className="col-xs-12 col-lg-12"
                                                variant="outlined"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                fullWidth

                                            >

                                                {tierList.map((item, key) => (
                                                    <option key={key} value={item.name} disabled={item.disable}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </TextField>

                                        </div>
                                        {formError != "" && <p className="error mt-2">{formError}</p>}
                                        <div className="mt-3 text-right">
                                            <IconButton className="mr-2" variant="contained" onClick={handleChangeClosePopup}>
                                                <IntlMessages id="manageUsers.cancel" />
                                            </IconButton>
                                            <IconButton variant="contained" onClick={handleChangeUpdate} color="primary">
                                                <IntlMessages id="manageUsers.update" />
                                            </IconButton>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>

                        </Paper>
                    </div>
                </div>
            </Backdrop> */}

      {/* <Backdrop className={classes.backdrop} open={openAssignCreditPopup}>
          <div>
            <div className="pop-head"></div>
            <div className="module-box-content mt-0">
              <Paper className={classes.switchPaper}>
                <p className="jr-fs-xl m-2">
                  {<IntlMessages id="manageUsers.assignCredit" />}
                </p>
                <div className="app-login-form">
                  <div className="m-2 ml-3">
                    <Paper className={classes.switchPaper}>
                      <div className="row">
                        <p className="jr-fs-l m-2 col-6">
                          Premium credits (left/total)
                        </p>
                        <p className="jr-fs-l m-2 col-4">{`${subsccompanyDetails.premCredTotal -
                          subsccompanyDetails.premCredUsed} / ${
                          subsccompanyDetails.premCredTotal
                        }`}</p>

                        <p className="jr-fs-l m-2 col-6">
                          Standard credits (left/total)
                        </p>
                        <p className="jr-fs-l m-2 col-4">{`${subsccompanyDetails.stanCredTotal -
                          subsccompanyDetails.stanCredUsed} / ${
                          subsccompanyDetails.stanCredTotal
                        }`}</p>
                      </div>
                    </Paper>
                  </div>
                  <form>
                    <fieldset>
                      <div className="row m-0">
                        <TextField
                          select
                          type="name"
                          name="assignName"
                          onChange={handleChangeNameSelection}
                          label={<IntlMessages id="manageUsers.name" />}
                          margin="normal"
                          className="col-xs-12 col-lg-12"
                          value={state.assignName.value}
                          variant="outlined"
                          SelectProps={{
                            native: true,
                          }}
                          fullWidth
                        >
                          {usersList.map((value, key) => (
                            <option key={key} value={value.id}>
                              {value.Name}
                            </option>
                          ))}
                        </TextField>
                        <TextField
                          select
                          type="name"
                          name="creditType"
                          onChange={handleOnChange}
                          label={<IntlMessages id="manageUsers.assignCredit" />}
                          margin="normal"
                          className="col-xs-12 col-lg-12"
                          value={state.creditType.value}
                          variant="outlined"
                          SelectProps={{
                            native: true,
                          }}
                          fullWidth
                        >
                          {creditTypeList.map((value, key) => (
                            <option key={key} value={value.name}>
                              {value.name}
                            </option>
                          ))}
                        </TextField>

                        <TextField
                          type="number"
                          name="assignAmount"
                          onChange={handleOnChange}
                          value={state.assignAmount.value}
                          label={<IntlMessages id="manageUsers.amount" />}
                          margin="none"
                          className="col-xs-12 col-lg-12"
                          fullWidth
                        />
                      </div>
                      {formError != "" && (
                        <p className="error mt-2">{formError}</p>
                      )}
                      <div className="mt-3 text-right">
                        <IconButton
                          className="mr-2"
                          variant="contained"
                          onClick={handleAssignClosePopup}
                        >
                          <IntlMessages id="manageUsers.cancel" />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          onClick={handleAssign}
                          color="primary"
                        >
                          <IntlMessages id="manageUsers.assign" />
                        </IconButton>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </Paper>
            </div>
          </div>
        </Backdrop> */}

      <Backdrop className={classes.backdrop} open={loader || showPopupLoader}>
        <div className='fixContainer'>
          <div className='loader-view'>
            <CircularProgress />
          </div>
        </div>
      </Backdrop>

      {showMessage && infoMessage != '' && NotificationManager.success(infoMessage)}
      {showMessage && alertMessage != '' && NotificationManager.error(alertMessage)}
      <NotificationContainer />
    </div>
  );
};

export default ManageUsers;

const StatBox = ({ title, content, secondayContent }) => {
  const classes = useStyles();
  return (
    <div className={classes.statBox}>
      <div className={classes.statHeading}>{title}</div>
      <div className={classes.statContent}>
        {content} <span className={classes.secondaryStatContent}>{secondayContent}</span>
      </div>
    </div>
  );
};
