import React from 'react';
import { Paper, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '70%',
    width: '100%',
    height: '100%',
    margin: 'auto',
  },
  paper: {
    marginTop: '100px',
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '625px',
    '& .help-btns': {
      display: 'flex',
      gap: '157px',
      justifyContent: 'center',
      display: 'flex',
      marginTop: '116px',
    },
  },
  headings: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '32px',
    lineHeight: '39px',
    textAlign: 'center',
    color: '#484848',
  },
}));

export default function SuccessOrErrScreen({ success }) {
  const history = useHistory();
  const { root, paper, headings } = useStyles();
  return (
    <Box className={root}>
      <Paper className={paper}>
        <Box className='text-center'>
          <img
            className='text-center'
            src={require(`assets/images/${success ? 'check-circle.png' : 'alert-circle.png'}`)}
            alt='check-circle'
          />
          {success ? (
            <Box className='text-center mt-4'>
              <Typography variant='h4' className={`mb-2 ${headings}`}>
                Payment Successful
              </Typography>
              <Typography>Go and crush your pipeline goals</Typography>
            </Box>
          ) : (
            <Box className='mt-4'>
              <Typography variant='h4' className={`mb-2 ${headings}`}>
                Something went wrong.
              </Typography>
              <Typography>We couldn’t complete the payment step</Typography>

              <Box className='help-btns'>
                <Button variant='outlined' color='primary' onClick={() => history.push('help')}>
                  Talk to help
                </Button>
                <Button variant='contained' color='primary' onClick={() => history.push('pricing')}>
                  Try again
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
