import {
  UPDATE_SEARCH_TYPE,
  UPDATE_SEARCH_RESULT,
  RESET_SEARCH_TYPE,
  UPDATE_SEARCH_DETAILS,
  UPDATE_SEARCH_DETAILS_LIST,
  SEARCH_FETCH_SUCCESS,
  SEARCH_FETCH_ERROR,
  CLEAR_SEARCH_RESULT,
  SHOW_ERROR_MESSAGE,
  FETCH_SEARCH_RESULT,
  FETCH_SEARCH_LIST_RESULT,
  SHOW_SEARCH_ERROR_MESSAGE,
  SET_SEARCH_LOADING,
  SET_SEARCH_LOADING_FALSE,
  START_PREFETCH_SEARCH,
  START_PREFETCH_SEARCH_LIST,
  SUCCESS_PREFETCH_SEARCH,
  SUCCESS_PREFETCH_SEARCH_LIST,
  ERROR_PREFETCH_SEARCH,
  ERROR_PREFETCH_SEARCH_LIST,
  SET_PREFETCH_SEARCH_LOADING,
  SEARCH_MORE_SUCCESS,
  SET_SEARCH_CHECKED_UPDATED_LIST_RESULT,
} from 'constants/ActionTypes';

export const updateSearchType = searchType => {
  return {
    type: UPDATE_SEARCH_TYPE,
    payload: searchType,
  };
};

export const updateSearchResult = data => {
  return {
    type: UPDATE_SEARCH_RESULT,
    payload: data,
  };
};

export const updateSearchDetails = data => {
  return {
    type: UPDATE_SEARCH_DETAILS,
    payload: data,
  };
};

export const updateSearchDetailsList = data => {
  return {
    type: UPDATE_SEARCH_DETAILS_LIST,
    payload: data,
  };
};

export const startSearchResult = data => {
  return {
    type: FETCH_SEARCH_RESULT,
    payload: data,
  };
};

export const startSearchListResult = data => {
  return {
    type: FETCH_SEARCH_LIST_RESULT,
    payload: data,
  };
};

export const searchFetchSuccess = data => {
  return {
    type: SEARCH_FETCH_SUCCESS,
    payload: data,
  };
};

// to update first search 2nd request results
export const searchMoreSuccess = data => {
  return {
    type: SEARCH_MORE_SUCCESS,
    payload: data,
  };
};

export const searchFetchError = data => {
  return {
    type: SEARCH_FETCH_ERROR,
    payload: data,
  };
};

export const clearSearchResult = () => {
  return {
    type: CLEAR_SEARCH_RESULT,
  };
};

export const showSearchErrorMsg = data => {
  return {
    type: SHOW_SEARCH_ERROR_MESSAGE,
    payload: data,
  };
};

export const setSearchLoading = () => {
  return {
    type: SET_SEARCH_LOADING,
  };
};

export const setSearchLoadingFalse = () => {
  return {
    type: SET_SEARCH_LOADING_FALSE,
  };
};

export const startPrefetchSearch = data => {
  return {
    type: START_PREFETCH_SEARCH,
    payload: data,
  };
};

export const startPrefetchSearchList = data => {
  return {
    type: START_PREFETCH_SEARCH_LIST,
    payload: data,
  };
};

export const successPrefetchSearch = data => {
  return {
    type: SUCCESS_PREFETCH_SEARCH,
    payload: data,
  };
};

export const successPrefetchSearchList = data => {
  return {
    type: SUCCESS_PREFETCH_SEARCH_LIST,
    payload: data,
  };
};

export const errorPrefetchSearch = data => {
  return {
    type: ERROR_PREFETCH_SEARCH,
    payload: data,
  };
};

export const errorPrefetchSearchList = data => {
  return {
    type: ERROR_PREFETCH_SEARCH_LIST,
    payload: data,
  };
};

export const setPrefetchSearchLoading = () => {
  return {
    type: SET_PREFETCH_SEARCH_LOADING,
  };
};

export const setSearchSelectUpdated = data => {
  return {
    type: SET_SEARCH_CHECKED_UPDATED_LIST_RESULT,
    payload: data,
  };
};
