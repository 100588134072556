import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from '../../../util';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    padding: '100px 30px',
  },

  card: {
    '& .innerCard': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': { fontSize: '14px', fontWeight: '400', fontStyle: 'normal' },
      '& .card-icon': {
        height: '60px',
        width: '60px',
        marginBottom: '40px',
        '& img': {
          height: '100%',
          width: '100%',
        },
      },
    },
    backgroundColor: '#ffff',
    padding: theme.spacing(2),
    width: '270px',
    height: '445px',
    borderRadius: '10px',
    boxShadow: ' 0px 4px 20px 12px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& h4': {
      fontWeight: '700',
      fontStyle: 'normal',
      fontSize: '20px',
      lineheight: '24.2px',
    },
  },

  buttons: {
    background: '#fff',
    '&:hover': {
      background: '#182EEC',
      transition: '0.3s',
      color: '#fff',
    },
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '20px',
    lineheight: '24.2px',
    color: '#182EEC',
    width: '194.4px',
    height: '54px',
    borderRadius: '4px',
    padding: '15px 60px 15px 60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 60px',
    border: '1px solid#182EEC',
    cursor: 'pointer',
    textAlign: 'center',
    margin: '15px 0px',
  },
}));

// main component
const Settings = props => {
  const [userType, setUserType] = useState(null);
  const { userDetails } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  useEffect(() => {
    if (!isEmpty(userDetails)) setUserType(userDetails.rol);
  }, []);

  const handleActions = links => {
    props.history.push('/app/' + links);
  };

  return (
    <div className='app-wrapper'>
      <div className='app-module animated slideInUpTiny animation-duration-3'>
        <div className='d-flex flex-column'>
          <div className='p-2'>
            <p className='p-4' style={{ fontSize: '24px', fontWeight: '900' }}>
              Settings
            </p>
          </div>
          <div className={classes.wrapper}>
            <div className={classes.card}>
              <div className='innerCard'>
                <div className='card-icon'>
                  <img src={require('assets/images/user-management.png')} alt='user-management' />
                </div>
                <div>
                  <h4>User Management</h4>
                </div>
                <div className={classes.buttons} onClick={() => handleActions('manageUsers')}>
                  <span>Select</span>
                </div>
                <p>Assign seats, add/limit credits, change tiers of users</p>
              </div>
            </div>
            {userType && userType.toLowerCase() == 'admin' && (
              <div className={classes.card}>
                <div className='innerCard'>
                  <div className='card-icon'>
                    <img
                      src={require('assets/images/invitation-management.png')}
                      alt='invitation-management'
                    />
                  </div>
                  <div>
                    <h4>Invitation Management</h4>
                  </div>
                  <div className={classes.buttons} onClick={() => handleActions('invitedUsers')}>
                    <span>Select</span>
                  </div>
                  <p>Lorem contacts to Salesforce directly from the plugin.</p>
                </div>
              </div>
            )}
            <div className={classes.card}>
              <div className='innerCard'>
                <div className='card-icon'>
                  <img
                    src={require('assets/images/profile-management.png')}
                    alt='profile-management'
                  />
                </div>
                <div>
                  <h4> Profile Management</h4>
                </div>
                <div className={classes.buttons} onClick={() => handleActions('manageProfile')}>
                  <span>Select</span>
                </div>
                <p>Manage your profile information, credentials</p>
              </div>
            </div>
            {/* <div className={classes.card}>
              <div className='innerCard'>
                <div className='card-icon'>
                  <img src={require('assets/images/user-management.png')} alt='integration' />
                </div>
                <div>
                  <h4>Integration</h4>
                </div>
                <div className={classes.buttons} onClick={() => handleActions('integration')}>
                  <span>Select</span>
                </div>
                <p>Lorem contacts to Salesforce directly from the plugin.</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
