import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Paper, Typography, Box, Snackbar } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import MoveContactsPopup from '../../../components/MoveContactsPopup';
import CircularProgress from '@material-ui/core/CircularProgress';

/** */
import ResultListTable from '../../../components/resultListTable';
import TablePagination from '../../../components/tablePagination';

import {
  hideContactMessage,
  getLatestPeopleNRecords,
  moveContactToList,
  updateCompanyEnrich,
  getViewCustomList,
  startCompanyUpdate,
} from 'actions/Contact';

import {
  handleDownloadSearchCSV,
  getNumberOfCompanyLatestFetchRecords,
  companyTableDisplayData,
} from '../../../util';
import { auxBaseUrl, auxReportUrl } from 'util/urls';

import MoveIcon from 'assets/images/MoveIcon.svg';
import CloudDownload from 'assets/images/CloudDownload.svg';
import { getAuthToken } from 'sagas/Auth';
import { Alert } from '@material-ui/lab';


const fetchAPI = async (path) => {
  const token = await getAuthToken();
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });

  try {
    const response = await fetch(path, {
      method: "GET",
      headers: headers,
    })
    return await response.json();
  } catch (error) {
    console.log("Failed to fetch Reports");
  }
};
const fetchPost = async (path, body) => {
  const token = await getAuthToken();
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });

  try {
    const response = await fetch(path, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
    return await response.json();
  } catch (error) {
    console.log("Failed to download Report");
  }

};

const IntentTracker = props => {
  /**
   * State variables
   */
  /** */
  const [reportDataList, setReportDataList] = useState({ data: [], isLoading: true });
  const [currentPageList, setCurrentPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // 10 per page(0); //starts from 0
  const [toast, setToast] = useState({ isOpen: false, severity: "", message: "" });

  useEffect(() => {
    async function reportData() {
      let { data } = await fetchAPI(auxBaseUrl + auxReportUrl);
      setReportDataList({ data, isLoading: false });
    };
    reportData();
  }, []);

  useEffect(() => {
    if (!reportDataList.isLoading) {
      let showList = reportDataList.data.slice((currentPage - 1) * perPage, currentPage * perPage);
      let finalList = companyTableDisplayData('IntentTracker', showList, false, false);
      setCurrentPageList(finalList);
    }
  }, [currentPage, reportDataList]);

  const handleToast = async (severity, message) => {
    setToast({ isOpen: true, severity, message });
  }
  const handleToastClose = async (severity, message) => {
    setToast({ isOpen: false, severity: "", message: "" });
  }

  const handleDownload = async (obj, field) => {
    try {
      if (field === 'Action') {
        handleToast('info', `Downloading ${obj.Report.value}`)
        const reportId = obj.id.value;
        const reportName = obj.Report.value;
        const fileData = await fetchPost(auxBaseUrl + auxReportUrl + '/download-report', { reportId });

        const uint8Array = new Uint8Array(fileData.file.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
        const blob = new Blob([uint8Array], { type: fileData.mime });
        const blobURL = window.URL.createObjectURL(blob);
        let downloadElement = document.createElement('a');
        downloadElement.href = blobURL;
        downloadElement.setAttribute('download', `${reportName}.${fileData.mime.split('/')[1]}`);
        downloadElement.click();
        downloadElement.remove();
        handleToast('success', `Downloaded ${reportName}.${fileData.mime.split('/')[1]} successfully!`)
      }
    } catch (error) {
      console.log(`Download Error`, error)
    }
  }
  return (
    <div className='app-wrapper'>
      <div style={{ margin: "0 0 20px" }}>
        <h1>Intent Tracker</h1>
        <h4>Track and download your reports</h4>
      </div>
      <Paper elevation={0} style={{ padding: "5px", minHeight: "80vh" }}>
        {/* Not Loading && Has Data */}
        {currentPageList.length && !reportDataList.isLoading ? (
          <ResultListTable
            result={currentPageList}
            itemClick={handleDownload}
            // selectionAvailable={true}
            resultType='intentTracker'
          />
        ) : ""}
        {/* Loading && No Data */}
        {!currentPageList.length && reportDataList.isLoading && (
          <div style={{ display: "grid", height: "80vh", placeContent: "center" }}>
            <CircularProgress />
          </div>
        )}
        {/* Not Loading && No Data */}
        {!currentPageList.length && !reportDataList.isLoading && (
          <div style={{ display: "grid", height: "80vh", placeContent: "center" }}>
            <h4>No reports found!</h4>
          </div>
        )}
      </Paper>
      <Snackbar onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={toast.isOpen} autoHideDuration={2000}>
        <Alert severity={toast.severity}>
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default IntentTracker;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  root: {
    flexGrow: 1,
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 600,
  },
  dropDown: {
    width: 250,
  },
  searchGif: {
    maxWidth: 70,
  },
  resultGrid: {
    display: 'grid',
    gridTemplateRows: '1fr',
  },
  searchScroll: {
    overflowY: 'hidden',
    height: '100%',
  },

  formPaper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 600,
  },

  mainGrid: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    // backgroundColor: "red",
    height: '86vh',
  },
  listGrid: {
    maxHeight: '74vh',
    display: 'grid',
    alignItems: 'center',
    gridTemplateRows: '1fr auto',
    marginTop: '6px',
    width: '85%',
  },
}));
