import React from 'react';
import { Button } from '@material-ui/core';
import ArrowLeft from '@material-ui/icons/ArrowBackIos';
import ArrowRight from '@material-ui/icons/ArrowForwardIos';

const TablePagination = props => {
  let { currentPage, isreachedEnd, loading } = props;
  const handleGoPrevious = () => props.handleGoPrevious(parseInt(currentPage) - 1);
  const handleGoNext = () => props.handleGoNext(parseInt(currentPage) + 1);

  return (
    <div className='d-flex justify-content-end align-items-center table-pagination'>
      {loading ? (
        'Fetching more data...'
      ) : (
        <>
          <Button className='buttonIcon' disabled={currentPage == 1} onClick={handleGoPrevious}>
            <ArrowLeft className='arrows' />
          </Button>
          {/* <span>Page <b>{currentPage}</b> of <b>{totalPages}</b></span> */}
          <span>
            Page <b>{currentPage}</b>
          </span>
          <Button onClick={handleGoNext} className='buttonIcon' disabled={isreachedEnd()}>
            <ArrowRight className='arrows' />
          </Button>
        </>
      )}
    </div>
  );
};

export default TablePagination;
