const { InputBase } = require('@material-ui/core');
const { withStyles } = require('@material-ui/styles');

const InputField = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: '31px',
    },
  },
  input: {
    width: '100%',
    borderRadius: 5,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #dddddd',
    fontSize: 14,
    padding: '10px 12px',
  },
}))(InputBase);

export default InputField;
