import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import {
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  NativeSelect,
  Select,
  Typography,
  Switch,
  Button,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    // margin : 'auto'
  },
  smallPaper: {
    padding: theme.spacing(2),
    maxWidth: 250,
  },
  tierInfo: {
    borderLeft: `5px solid #38BF08`,
    paddingLeft: 5,
    margin: theme.spacing(3, 0),
  },
  tierText: {
    display: 'inline-block',
    background: '#38BF08',
    padding: '1px 5px',
    color: '#fff',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0),
    '& > div': {
      padding: 0,
    },
    '& > select:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  pageHeader: {
    margin: theme.spacing(2, 3),
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  headerText: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  pageStats: {
    display: 'flex',
  },
  statBox: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  statHeading: {
    fontWeight: 600,
  },
  statContent: {
    fontWeight: 600,
    fontSize: '2rem',
    color: '#000',
  },
  secondaryStatContent: {
    marginTop: theme.spacing(1),
    fontSize: '1rem',
    color: theme.palette.text.primary,
  },
  fontBold: {
    fontWeight: 600,
  },
  backLink: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    cursor: 'pointer',
  },
  row: {
    margin: 'auto -58px',
    padding: '15px',
    borderBottom: '1px solid #A6AAAF',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
}));

const whenUpdateOption = [
  { value: 'ALWAYS_UPDATE', text: 'Always Update' },
  { value: 'NEVER_UPDATE', text: 'Never Update' },
  { value: 'BLANK_UPDATE', text: 'Update if blank' },
];

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const REQUIRED_SYS_FIELDS = [
  'FIRST_NAME',
  'LAST_NAME',
  'WEBSITE',
  'COMPANY_NAME',
  'COMPANY_DOMAIN',
];

const CrmMapForm = props => {
  const classes = useStyles();

  const {
    crmInitialMap,
    crmFields,
    crmName,
    handleOnChange,
    type,
    disableCheckBox,
    addField,
    systemFields,
    deleteField,
  } = props;

  return (
    <div className='app-wrapper'>
      <div className='app-module animated slideInUpTiny animation-duration-3'>
        <div className='module-box max-100p'>
          <div className='module-box-content module_table'>
            <div className='keep-center' style={{ padding: '2rem 0rem', flexDirection: 'column' }}>
              <form style={{ width: '100%' }}>
                <div className={classes.row + ' row'}>
                  <div className='col-sm-3'>
                    <h4>GLOWRADIUS FIELDS</h4>
                  </div>
                  <div className='col-sm-3'>
                    <h4>{crmName.toUpperCase()} FIELDS</h4>
                  </div>
                  <div className='col-sm-3'>
                    <h4>WHEN TO UPDATE</h4>
                  </div>
                  <div className='col-sm-2 text-center'>
                    <h4>TEAM LEVEL LOCK</h4>
                  </div>
                </div>
                {crmInitialMap.map((field, idx) => {
                  let fieldUniqueId = `${type +
                    (type === 'peopleMap' && crmName === 'salesforce' ? field.destination : '')}_${
                    field.sysFieldName
                  }`;
                  return (
                    <div key={field?.id} className={classes.row + ' row'}>
                      <div className='col-sm-3'>
                        <FormControl className={classes.formControl}>
                          <Select
                            defaultValue={field.sysFieldName}
                            onChange={e =>
                              handleOnChange(
                                e.target.value,
                                type,
                                field.id,
                                'sysFieldChange',
                                field.destination
                              )
                            }
                            name={'sysField' + fieldUniqueId}
                            inputProps={{ id: 'sysField' + fieldUniqueId }}
                            size='small'
                            fullWidth
                            input={<BootstrapInput />}
                          >
                            {type === 'peopleMap'
                              ? systemFields.peopleFields.map((f, idx) => (
                                  <MenuItem
                                    key={idx + 1}
                                    value={f.name}
                                    selected={f.name === field.sysFieldName}
                                    disabled={f.selected}
                                  >
                                    {f.name}
                                  </MenuItem>
                                ))
                              : systemFields.companyFields.map((f, idx) => (
                                  <MenuItem
                                    key={idx + 1}
                                    value={f.name}
                                    selected={f.name === field.sysFieldName}
                                    disabled={f.selected}
                                  >
                                    {f.name}
                                  </MenuItem>
                                ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-sm-3'>
                        <FormControl className={classes.formControl}>
                          <Select
                            value={field.crmFieldName}
                            onChange={e =>
                              handleOnChange(
                                e.target.value,
                                type,
                                field.id,
                                'crmFieldNameChange',
                                field.destination
                              )
                            }
                            name={fieldUniqueId}
                            inputProps={{ id: fieldUniqueId }}
                            size='small'
                            fullWidth
                            disabled={disableCheckBox && field.cOrgWise}
                            input={<BootstrapInput />}
                          >
                            {crmFields.map((crmField, idx) => (
                              <MenuItem
                                key={idx + 1}
                                value={crmField.name}
                                selected={field.crmFieldName === field.name}
                                disabled={crmField.selected}
                              >
                                {`${crmField.displayName || crmField.name} (type=${
                                  crmField.type
                                }, maxlength=${crmField.length !== -1 ? crmField.length : 'NA'})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-sm-3'>
                        <FormControl className={classes.formControl}>
                          <Select
                            value={field.whenUpdate}
                            onChange={e =>
                              handleOnChange(
                                e.target.value,
                                type,
                                field.id,
                                'crmFieldUpdateChange',
                                field.destination
                              )
                            }
                            name={fieldUniqueId}
                            inputProps={{
                              id: fieldUniqueId,
                            }}
                            size='small'
                            fullWidth
                            disabled={disableCheckBox && field.cOrgWise}
                            input={<BootstrapInput />}
                          >
                            {whenUpdateOption.map((op, idx) => (
                              <MenuItem
                                key={idx + 1}
                                value={op.value}
                                selected={field.whenUpdate === field.value}
                              >
                                {op.text}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className='col-sm-1 text-center'>
                        <FormControl
                          className={classes.formControl}
                          style={{ alignItems: 'center' }}
                        >
                          <Switch
                            checked={field.cOrgWise}
                            onChange={e =>
                              handleOnChange(
                                e.target.value,
                                type,
                                field.id,
                                'crmFieldBooleanChange',
                                field.destination
                              )
                            }
                            name={fieldUniqueId}
                            color='primary'
                            disabled={disableCheckBox}
                          />
                        </FormControl>
                      </div>
                      <div className='col-sm-1 text-center'>
                        <div style={{ alignItems: 'center', marginTop: '11px' }}>
                          <IconButton
                            color='secondary'
                            size='small'
                            onClick={() => deleteField(field.id)}
                            disabled={REQUIRED_SYS_FIELDS.some(
                              fields => fields === field.sysFieldName
                            )}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </form>
              <p
                style={{
                  cursor: 'pointer',
                  textTransform: 'uppercase',
                  color: '#172EEC',
                  fontSize: '15px',
                  fontWeight: 'bold',
                }}
                onClick={() => addField(type)}
              >
                + Add field mapping
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <Backdrop className={classes.backdrop} open={loading}>
        <div className="fixContainer">
          <div className="loader-view">
            <CircularProgress />
          </div>
        </div>
      </Backdrop> */}

      {/* {showMessage &&
        message.error ? NotificationManager.success(message.value) : NotificationManager.alert(message.value)} */}
      <NotificationContainer />
    </div>
  );
};

export default CrmMapForm;
