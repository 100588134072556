import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '../../../components/IconButton';

import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import ResultListTable from 'components/resultListTable';
import CustomScrollbars from 'util/CustomScrollbars';

import {
  showAuthLoader,
  hideAuthLoader,
  hideMessage,
  showAuthMessage,
  add_invite,
} from 'actions/Auth';
import { isEmpty, emailRegex } from '../../../util';

import { auth } from '../../../firebase/firebase';

import {
  baseIAMUrl,
  iamRoute,
  getInvitedUsersListMethod,
  baseSubscriptionUrl,
  subscriptionUser,
  getActiveUserCountMethod,
} from 'util/urls';
import { withStyles } from '@material-ui/styles';
import TransitionModal from 'components/TransistionModal';
import {
  Divider,
  FormControl,
  InputBase,
  InputLabel,
  NativeSelect,
  Typography,
} from '@material-ui/core';

// const tierOptions = ['Free', 'Basic', 'Premium'];

const roleOptions = ['User', 'Admin'];

/* const typeOfUser = [
    "INVITE_NEW",
    "INVITE_EXISTING"
] */

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    // margin : 'auto'
  },

  form: {
    marginTop: theme.spacing(1),
    '& label': {
      color: '#000',
    },
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0),
    '& > div': {
      padding: 0,
    },
    '& > select:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
  pageHeader: {
    margin: theme.spacing(2, 3),
    '& hr': {
      margin: theme.spacing(1, 0),
    },
  },
  headerText: {
    margin: theme.spacing(2, 0, 3, 0),
  },
  pageStats: {
    display: 'flex',
  },
  statBox: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  statHeading: {
    fontWeight: 600,
  },
  statContent: {
    fontWeight: 600,
    fontSize: '2rem',
    color: '#000',
  },
  secondaryStatContent: {
    marginTop: theme.spacing(1),
    fontSize: '1rem',
    color: theme.palette.text.primary,
  },
  fontBold: {
    fontWeight: 600,
  },
  backLink: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    cursor: 'pointer',
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const InvitedUsers = props => {
  const initialState = {
    email: {
      value: '',
      required: true,
      regex: emailRegex,
      message: 'Valid email Id is required',
    },
    fName: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid first name is required. Must be at least 3 characters',
    },
    lName: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid last name is required. Must be at least 3 characters',
    },
    tierId: { value: 'Lite', required: false },
    role: { value: 'User', required: false },
    // "type": { value: "INVITE_NEW", required: false },
  };

  const {
    loader,
    alertMessage,
    showMessage,
    infoMessage,
    companyDetails,
    userDetails,
  } = useSelector(({ auth }) => auth);
  const [invitedUsersList, setInvitedUsersList] = useState([]);
  const [openInvitePopup, setOpenInvitePopup] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const classes = useStyles();

  const [activeUserCount, setActiveUserCount] = useState(0);
  const [totalInvitedUsers, setTotalInvitedUsers] = useState(0);
  const [yetToJoinUsers, setYetToJoinUsers] = useState(0);
  const [adminUsers, setAdminUsers] = useState(0);

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      case 'reset':
        return initialState;
      default:
        throw new Error();
    }
  }

  const handleOnChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const handleInvite = () => {
    let inputObj = validate();
    if (inputObj != false) {
      console.log('form ' + JSON.stringify(inputObj));

      //something went wrong
      if (isEmpty(companyDetails) && isEmpty(userDetails)) {
        //TO-DO
        console.log('These details must be there');
      } else {
        //add more
        inputObj.cid = companyDetails.cid;
        inputObj.cn = companyDetails.cn;
        inputObj.senderId = userDetails.uid;
        storeDispatch(add_invite(inputObj));
      }
    }
  };

  const handleInviteNewUser = () => setOpenInvitePopup(true);
  const handleInviteClosePopup = () => {
    setOpenInvitePopup(false);
    storeDispatch(hideMessage());
    clearForm();
  };

  const validate = () => {
    let inputAPI = {};
    for (var element in state) {
      if (validInput(state[element])) {
        console.log(state[element]);
        storeDispatch(showAuthMessage(state[element].message));
        return false;
      }
      if (state[element].value != '') inputAPI[element] = state[element].value;
    }

    return inputAPI;
  };

  const validInput = input => {
    return input.required
      ? input.value == '' ||
          (input.min ? input.value.length < input.min : false) ||
          (input.hasOwnProperty('regex') ? !input.value.match(input.regex) : false)
      : input.value != '' &&
          (input.min ? input.value.length < input.min : false) &&
          (input.hasOwnProperty('regex') ? !input.value.match(input.regex) : false);
  };

  const clearForm = () => {
    dispatch({ type: 'reset' });
  };

  useEffect(() => {
    getInvitationList();
  }, []);

  useEffect(() => {
    if (showMessage) {
      getInvitationList();
      // setTimeout(() => {
      // storeDispatch(hideMessage());
      // }, 1000);
    }
  }, [showMessage]);

  //infoMessage called in this component only after add invitation
  useEffect(() => {
    if (infoMessage != '') getInvitationList();
  }, [infoMessage]);

  async function getInvitationList() {
    storeDispatch(showAuthLoader());
    const response = await getInvitedListAPI({
      uid: localStorage.getItem('user_id'),
    });

    const getActiveUserResponse = await getActiveUserCountAPI({
      cid: companyDetails.cid,
    });

    if (!response.hasOwnProperty('Error') && !getActiveUserResponse.hasOwnProperty('Error')) {
      setInvitedUsersList(response);
      console.log(getActiveUserResponse);
      setActiveUserCount(getActiveUserResponse.activeUsers);
      setTotalInvitedUsers(response.length);
      setYetToJoinUsers(response.filter(item => item.Status == 'Pending').length);
      setAdminUsers(
        response.filter(
          item => item.Status.toLowerCase() == 'joined' && item.Role.toLowerCase() == 'admin'
        ).length
      );
    }
    // else
    //     storeDispatch(showAuthMessage(response.Error.Msg));

    storeDispatch(hideAuthLoader());
  }

  const getAuthToken = async () => await auth.currentUser.getIdToken();

  const getInvitedListAPI = async payload => {
    const token = await getAuthToken();
    let headers = new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    return await fetch(baseIAMUrl + iamRoute + getInvitedUsersListMethod, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(err => err);
  };

  const getActiveUserCountAPI = async (payload, method) => {
    const token = await getAuthToken();
    let headers = new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    return await fetch(baseSubscriptionUrl + subscriptionUser + getActiveUserCountMethod, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(err => err);
  };

  console.log('user details from invite', userDetails);
  console.log('showMessage', showMessage);

  return (
    <div className='app-wrapper'>
      <div className='app-module animated slideInUpTiny animation-duration-3'>
        <div className='module-box max-100p'>
          <div className={classes.pageHeader}>
            <Typography
              variant='subtitle2'
              className={classes.backLink}
              onClick={() => props.history.goBack()}
            >
              {'< '}Back
            </Typography>
            <div className={classes.headerText}>
              <Typography variant='h5' className={classes.fontBold}>
                Manage Users
              </Typography>
              <Typography variant='subtitle2' gutterBottom>
                Invite users, change user roles
              </Typography>
            </div>
            <Divider />
            <div className={classes.pageStats}>
              <StatBox title='Active Users' content={activeUserCount} />
              <StatBox
                title='Yet to join'
                content={yetToJoinUsers}
                secondayContent={'invited ' + totalInvitedUsers}
              />
              <StatBox title='Admin' content={adminUsers} />
            </div>
            <Divider />
          </div>
          <div className='d-flex justify-content-end mt-4 mr-3'>
            <IconButton
              className='jr-btn'
              size='medium'
              variant='contained'
              color='primary'
              aria-label='add'
              enable_icon={require('assets/images/supervised_user_circle-white-18dp.svg')}
              disable_icon={require('assets/images/supervised_user_circle-black-18dp.svg')}
              onClick={handleInviteNewUser}
            >
              <span>Invite New Member</span>
            </IconButton>
          </div>
          <div className='module-box-content module_table'>
            <div
              className='keep-center'
              style={{
                height: props.width >= 1200 ? 'calc(100vh - 190px)' : 'calc(100vh - 190px)',
              }}
            >
              {!loader && invitedUsersList.length == 0 && (
                <strong> Sorry !! No results found.</strong>
              )}

              {!loader && invitedUsersList.length > 0 && (
                <div className='flex-grow-1'>
                  <div className='col-12 p-0 mt-5'>
                    <CustomScrollbars
                      className='module-list-scroll scrollbar'
                      style={{
                        height: props.width >= 1200 ? 'calc(100vh - 250px)' : 'calc(100vh - 250px)',
                      }}
                    >
                      <ResultListTable
                        result={invitedUsersList}
                        showFilter={false}
                        selectionAvailable={false}
                      />
                    </CustomScrollbars>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <Backdrop className={classes.backdrop} open={openInvitePopup}>
          <div>
            <div className="pop-head"></div>
            <div className="module-box-content mt-0">
              <Paper className={classes.switchPaper}>
                <p className="jr-fs-l m-2">
                  <IntlMessages id="appModule.invitepeople" />
                </p>
                <div className="app-login-form">
                  <form>
                    <fieldset>
                      <div className="row m-0">
                        <TextField
                          type="email"
                          name="email"
                          onChange={handleOnChange}
                          label={<IntlMessages id="appModule.email" />}
                          margin="none"
                          className="col-xs-12 col-lg-12"
                          value={state.email.value}
                          fullWidth
                        />

                        <TextField
                          id="firstname"
                          label="First Name"
                          type="text"
                          name="fName"
                          margin="normal"
                          onChange={handleOnChange}
                          className="col-xs-12 col-lg-12"
                          value={state.fName.value}
                          fullWidth
                        />
                        <TextField
                          id="lastname"
                          label="Last Name"
                          name="lName"
                          margin="normal"
                          onChange={handleOnChange}
                          className="col-xs-12 col-lg-12 ml-auto"
                          value={state.lName.value}
                          fullWidth
                        />

                        {/* <TextField
                                                select
                                                type="tier"
                                                name="tierId"
                                                onChange={handleOnChange}
                                                label={<IntlMessages id="appModule.tier" />}
                                                margin="normal"
                                                className="col-xs-12 col-lg-12"
                                                value={state.tierId.value}
                                                variant="outlined"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                fullWidth

                                            >

                                                {tierOptions.map((value, key) => (
                                                    <option key={key} value={key}>
                                                        {value}
                                                    </option>
                                                ))}

                                            </TextField>
                        <TextField
                          select
                          type="role"
                          name="role"
                          onChange={handleOnChange}
                          label={<IntlMessages id="appModule.role" />}
                          margin="normal"
                          className="col-xs-12 col-lg-12"
                          value={state.role.value}
                          variant="outlined"
                          SelectProps={{
                            native: true,
                          }}
                          fullWidth
                        >
                          {roleOptions.map((value, key) => (
                            <option key={key} value={value}>
                              {value}
                            </option>
                          ))}
                        </TextField>

                       <TextField
                                                select
                                                type="type"
                                                name="type"
                                                onChange={handleOnChange}
                                                label={<IntlMessages id="appModule.type" />}
                                                margin="normal"
                                                className="col-xs-12 col-lg-12"
                                                value={state.type.value}
                                                variant="outlined"
                                                SelectProps={{
                                                    native: true,
                                                }}
                                                fullWidth

                                            >

                                                {typeOfUser.map((value, key) => (
                                                    <option key={key} value={value}>
                                                        {value}
                                                    </option>
                                                ))}

                                            </TextField>

                      </div>
                      <div className="mt-3 text-right">
                        <IconButton
                          className="mr-2"
                          variant="contained"
                          onClick={handleInviteClosePopup}
                        >
                          <IntlMessages id="appModule.cancel" />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          onClick={handleInvite}
                          color="primary"
                        >
                          <IntlMessages id="appModule.invite" />
                        </IconButton>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </Paper>
            </div>
          </div>
        </Backdrop> */}

      <TransitionModal
        open={openInvitePopup}
        handleClose={handleInviteClosePopup}
        modalTitle='Invite a user'
        description='You can invite user from your organization and they will receive a mail with joining instruction'
        actionButtonText='Invite'
        actionLoadingButtonText='Inviting ...'
        actionButtonHandler={handleInvite}
        showMessage={showMessage}
        message={infoMessage || alertMessage}
        severity={infoMessage !== '' ? 'success' : 'error'}
        loading={loader}
      >
        {showMessage && infoMessage !== '' ? (
          true
        ) : (
          <form className={classes.form}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='fName' required>
                First Name
              </InputLabel>
              <BootstrapInput
                type='text'
                id='fName'
                name='fName'
                onChange={handleOnChange}
                margin='none'
                className='col-xs-12 col-lg-12'
                fullWidth
                value={state.fName.value}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='lName' required>
                Last Name
              </InputLabel>
              <BootstrapInput
                type='text'
                id='lName'
                name='lName'
                onChange={handleOnChange}
                margin='none'
                className='col-xs-12 col-lg-12'
                fullWidth
                value={state.lName.value}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='email' required>
                Email
              </InputLabel>
              <BootstrapInput
                type='email'
                id='email'
                name='email'
                onChange={handleOnChange}
                margin='none'
                className='col-xs-12 col-lg-12'
                fullWidth
                value={state.email.value}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='role' required>
                Role
              </InputLabel>
              <NativeSelect
                name='role'
                onChange={handleOnChange}
                value={state.role.value}
                className='col-xs-12 col-lg-12'
                variant='outlined'
                inputProps={{
                  id: 'role',
                }}
                size='small'
                fullWidth
                input={<BootstrapInput />}
              >
                {roleOptions.map((value, key) => (
                  <option disabled={value === 'Admin' ? true : false} key={key} value={value}>
                    {value}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </form>
        )}
      </TransitionModal>

      <Backdrop style={{ zIndex: '9999' }} className={classes.backdrop} open={loader}>
        <div className='fixContainer'>
          <div className='loader-view'>
            <CircularProgress />
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default InvitedUsers;

const StatBox = ({ title, content, secondayContent }) => {
  const classes = useStyles();
  return (
    <div className={classes.statBox}>
      <div className={classes.statHeading}>{title}</div>
      <div className={classes.statContent}>
        {content} <span className={classes.secondaryStatContent}>{secondayContent}</span>
      </div>
    </div>
  );
};
