import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import {
  Typography,
  IconButton as MuiIconButton,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import IconButton from 'components/IconButton';
import { useHistory } from 'react-router';
import InputBase from '@material-ui/core/InputBase';
import {
  createCheckout,
  updateQuantity,
  upgradePlan,
  cancelPlan,
} from 'util/stripeHandlers';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 500,
    width: '100%',
    border: 'none',
    outline: 'none',
    borderRadius: 6,
    boxShadow: `0px 4px 24px rgba(0, 0, 0, 0.16)`,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& > h6': {
      fontWeight: 600,
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: `${theme.spacing(1)}px`,
    padding: theme.spacing(1, 0, 0),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  select: {
    width: '100%',
    maxWidth: '69%',
  },
  seaTtitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    margin: '6px 0',
    color: '#000000',
  },
  header_text: {
    marginBottom: '17px',
  },
  tiers: {
    fontSize: '1rem',
    fontWeight: 600,
  },
  modalContent: {
    padding: theme.spacing(1, 0),
    '& .MuiAlert-root': {
      margin: theme.spacing(1, 0),
    },
  },

  alertMsg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& .alert-circle': {
      height: '188px',
      width: '188px',
      '& img': { height: '100%', width: '100%' },
    },
  },
}));

//  * paymentId is StripePriceId
export default function Popup({ open, close, paymentId, type, tierName, prices, userSub }) {
  const history = useHistory();
  const [result, setResult] = useState('');
  const [seats, setSeats] = useState(1);
  const { companyDetails, userDetails } = useSelector(({ auth }) => auth);
  const classes = useStyles();

  const confirmCancel = async () => {
    const response = await cancelPlan(companyDetails.cid);
    if (response.status === 'canceled') {
      setResult('success');
    } else {
      setResult('error');
    }
  };

  const paymentHandler = async () => {
    // * Create Checkout Scenario
    if (userSub.selectedTierId === 'NO_TIER_SELECTED') {
      const checkout = await createCheckout(paymentId, seats, companyDetails.cid);
      if (checkout?.error) {
        setResult('error');
      }
    } else {
      // * Add Seat Scenario
      if (userSub.selectedTierId === paymentId) {
        const response = await updateQuantity(companyDetails.cid, seats);
        if (response?.error) {
          setResult('error');
        }
        if (response.quantity == seats) {
          setResult('success');
        }
      }
      // * upgrade/downgrade Scenario
      if (userSub.selectedTierId !== paymentId) {
        const response = await upgradePlan(companyDetails.cid, paymentId, seats);
        if (response?.error) {
          setResult('error');
        } else {
          setResult('success');
        }
      }
    }
  };

  const generatePopupMsg = () => {
    if (type === 'cancel') {
      if (result === 'success') {
        return 'Your subscription is canceled';
      } else {
        return 'Sorry! We couldn’t cancel your subscription';
      }
    }
    if (type === 'payment') {
      if (result !== 'success') {
        return 'Sorry! We couldn’t update your subscription';
      } else {
        return 'Your subscription has been updated!';
      }
    }
  };

  useEffect(() => {
    if (userSub?.numSeats !== 'NO_SEAT_SELECTED') {
      setSeats(Number(userSub.numSeats));
    }
  }, [userSub]);

  return (
    <>
      <div>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={open}
          onClose={() => close(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              {result ? (
                <div>
                  <div
                    className='d-flex w-100'
                    style={{
                      flexDirection: 'column',
                      textAlign: 'center',
                      margin: '52px 0',
                    }}
                  >
                    <img
                      width={70}
                      height={70}
                      className='m-auto'
                      src={require(`assets/images/${
                        result !== 'success' ? 'alert-circle.png' : 'check-circle.png'
                      }`)}
                      alt='result'
                    />
                    <Typography className='my-3 fw-bold' style={{ fontWeight: 600 }}>
                      {generatePopupMsg()}
                    </Typography>
                  </div>
                  <div className='res-contents'>
                    {result === 'success' ? (
                      <div className='text-center'>
                        <Button variant='contained' color='primary' onClick={() => history.go(0)}>
                          thanks
                        </Button>
                      </div>
                    ) : (
                      <div className={classes.modalFooter}>
                        <IconButton
                          className='jr-btn'
                          size='medium'
                          variant='contained'
                          aria-label='add'
                          onClick={() => history.push('help')}
                        >
                          Talk to helpdesk
                        </IconButton>
                        <IconButton
                          className='jr-btn'
                          size='medium'
                          variant='contained'
                          color='primary'
                          onClick={() => {
                            close(false);
                            setResult('');
                          }}
                        >
                          try again
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className={classes.modalHeader}>
                    <Typography variant='h6'>
                      {' '}
                      {type === 'payment' ? 'Review your selection' : 'Cancel Subscription?'}{' '}
                    </Typography>
                    <MuiIconButton onClick={() => close(false)} size='small'>
                      <CloseIcon />
                    </MuiIconButton>
                  </div>
                  <div className={classes.modalContent}>
                    {type === 'cancel' ? (
                      <ModalContents
                        seats={seats}
                        setSeats={setSeats}
                        tier={
                          prices.filter(f => f.stripePriceId === userSub.selectedTierId)[0].tierName
                        }
                        cancelSeats={userSub.numSeats}
                        type={type}
                      />
                    ) : (
                      <ModalContents
                        seats={seats}
                        setSeats={setSeats}
                        tier={tierName}
                        type={type}
                      />
                    )}
                  </div>
                  <div className={classes.modalFooter}>
                    <IconButton
                      className='jr-btn'
                      size='medium'
                      variant='contained'
                      aria-label='add'
                      onClick={() => close(false)}
                    >
                      {type === 'payment' ? 'Cancel' : "don't cancel"}
                    </IconButton>
                    <IconButton
                      className='jr-btn'
                      size='medium'
                      variant='contained'
                      color='primary'
                      onClick={type === 'payment' ? () => paymentHandler() : () => confirmCancel()}
                    >
                      {type === 'payment' ? "let's go" : 'i confirm'}
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
          </Fade>
        </Modal>
      </div>
    </>
  );
}

const ModalContents = ({ tier, type, cancelSeats, seats, setSeats }) => {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.header_text}>
        {type === 'payment'
          ? 'Please review the details including tier and seats'
          : 'Are you sure you want to cancel subscription?'}
      </Typography>
      <div className='mb-3'>
        <Typography variant='h6' className={classes.seaTtitle}>
          Tier
        </Typography>
        <Typography variant='h6' className={classes.tiers}>
          {tier}
        </Typography>
      </div>
      <div className='mb-2'>
        <Typography variant='h6' className={classes.seaTtitle}>
          Seats
        </Typography>
        {type === 'cancel' ? (
          <Select
            defaultValue={cancelSeats}
            id='grouped-select'
            className={classes.select}
            input={<BootstrapInput />}
            readOnly={true}
          >
            <MenuItem value={cancelSeats}>{cancelSeats}</MenuItem>
          </Select>
        ) : (
          <Select
            value={seats}
            id='grouped-select'
            onChange={e => setSeats(e.target.value)}
            className={classes.select}
            input={<BootstrapInput />}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </Select>
        )}
      </div>
    </div>
  );
};
