import React from 'react';
import { Popover, Select, MenuItem, FormControl, InputBase, Button } from '@material-ui/core';
import { useState } from 'react';
// import { ArrowRight, Sort } from 'iconoir-react';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '5px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'blue',
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  popover: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    '& .popover_body': {
      height: 'auto',
      width: '300px',
      padding: '20px',
      borderRadius: '4px',
      background: '#fff',
      '& .MuiFormControl-root': {
        width: '100%',
        marginBottom: '20px',
      },
    },
  },
}));

const SortTableRows = ({ updateTableRows, columns }) => {
  const classes = useStyles();
  const [orderArray, setOrderArray] = useState([]);

  const [sortKey, setSortKey] = useState('');
  const [sortOrderKey, setSortOrderKey] = useState('');

  const handler = v => {
    console.log(sortKey, v)
    setSortOrderKey(v);
    updateTableRows(sortKey, v);
  };


  const fieldHandler = v => {
    setSortOrderKey('');
    setSortKey(v);
    setOrderArray([]);
    // const fieldIndex = columns.findIndex(x => x.id === v);
    // const { dataType } = columns[fieldIndex];
    let sortString = 'a2z';
    setSortOrderKey('a2z');
    setOrderArray([
      { start: 'A', end: 'Z', value: 'a2z' },
      { start: 'Z', end: 'A', value: 'z2a' },
    ]);
    // if (dataType === 'Number') {
    //   sortString = '129';
    //   setSortOrderKey('129');
    //   setOrderArray([
    //     { start: '1', end: '9', value: '129' },
    //     { start: '9', end: '1', value: '921' },
    //   ]);
    // } else {
    //   setSortOrderKey('a2z');
    //   sortString = 'a2z';
    //   setOrderArray([
    //     { start: 'A', end: 'Z', value: 'a2z' },
    //     { start: 'Z', end: 'A', value: 'z2a' },
    //   ]);
    // }
    updateTableRows(v, sortString);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className='popover_body'>
          <div>
            <FormControl>
              <Select input={<BootstrapInput />} onChange={(e)=> fieldHandler(e.target.value)} value={sortKey}>
                {columns.map((cl, key) => (
                  <MenuItem value={cl.title} key={key}>
                    {cl.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {orderArray.length > 0 && (
            <div>
              <FormControl>
                <Select input={<BootstrapInput />} onChange={(e)=>handler(e.target.value)} value={sortOrderKey}>
                  {orderArray.map((order, key) => (
                    <MenuItem key={key} value={order.value}>
                      {order.start} {'>'} {order.end}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </Popover>

      <Button size='small' aria-describedby={id} variant='contained' onClick={handleClick}>
        Sort
      </Button>
    </div>
  );
};

export default SortTableRows;
