import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  Typography,
  IconButton as MuiIconButton,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from 'components/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

import {
  hideMessage,
  showAuthMessage,
  updateProfile,
  updatePassword,
  update_profile_details,
} from 'actions/Auth';
import { withStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 4),
    background: theme.palette.background.default,
  },
  backLink: {
    marginBottom: theme.spacing(3),
    fontWeight: 600,
    cursor: 'pointer',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    // margin : 'auto'
  },
  smallPaper: {
    padding: theme.spacing(2),
    maxWidth: 250,
  },
  formWrapper: {
    padding: theme.spacing(3),
    borderRadius: '6px',
    boxShadow: `0px 4px 24px rgba(0, 0, 0, 0.16)`,
    background: '#fff',
    margin: theme.spacing(3, 0),
  },
  form: {
    margin: 0,
    '& > h6': {
      marginBottom: theme.spacing(3),
      fontWeight: 600,
    },
    '& label': {
      fontSize: '18px',
      color: theme.palette.text.primary,
    },
  },
  formControl: {
    width: '100%',
  },
  formBtnWrapper: {
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'flex-end',
    '& > button': {
      textTransform: 'capitalize',
    },
  },
  readOnlyInput: {
    color: theme.palette.text.disabled,
  },
  inputIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3.5),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const ManageProfile = props => {
  const initialState = {
    firstName: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid first name is required. Must be at least 3 characters',
    },
    lastName: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid last name is required. Must be at least 3 characters',
    },
    email: {
      value: '',
      required: false,
      message: 'Please enter a valid email',
    },
    phone: {
      value: '',
      required: false,
    },
    oldPassword: {
      value: '',
      required: true,
      min: 6,
      message: 'Old password is required',
      visible: false,
    },
    newPassword: {
      value: '',
      required: true,
      min: 6,
      message: 'New password is required',
      visible: false,
    },
    confirmPassword: {
      value: '',
      required: true,
      min: 6,
      message: 'Confirm password is required',
      visible: false,
    },
  };

  const {
    loader,
    alertMessage,
    showMessage,
    infoMessage,
    userDetails,
    isUpdateProfileSuccess,
  } = useSelector(({ auth }) => auth);
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        storeDispatch(hideMessage());
      }, 2000);
    }
  }, [showMessage]);

  useEffect(() => {
    try {
      if (userDetails) {
        dispatch({
          type: 'addInput',
          payload: { name: 'firstName', value: userDetails.fn },
        });
        dispatch({
          type: 'addInput',
          payload: { name: 'lastName', value: userDetails.ln },
        });
        dispatch({
          type: 'addInput',
          payload: { name: 'email', value: userDetails.eml },
        });
        dispatch({
          type: 'addInput',
          payload: { name: 'phone', value: userDetails.ph },
        });
      }
    } catch (ex) {
      console.log('Manage profile use effect ' + ex.message);
    }
  }, []);

  useEffect(() => {
    if (isUpdateProfileSuccess) updateChangedProfile();
  }, [isUpdateProfileSuccess]);

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      case 'toggleVisiblity':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            visible: !state[action.payload.name].visible,
          },
        };
      case 'hideAllPassword':
        return {
          ...state,
          oldPassword: {
            ...state.oldPassword,
            visible: false,
          },
          newPassword: {
            ...state.newPassword,
            visible: false,
          },
          confirmPassword: {
            ...state.confirmPassword,
            visible: false,
          },
        };
      default:
        throw new Error();
    }
  }

  const handleOnChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const handlePasswordUpdateSubmit = () => {
    if (state.oldPassword.value.trim() === '')
      storeDispatch(showAuthMessage('Old password cannot be empty'));
    else if (state.newPassword.value.trim() === '' || state.newPassword.value.length < 6)
      storeDispatch(showAuthMessage('New password must be at least 6 characters'));
    else if (state.oldPassword.value === state.newPassword.value)
      storeDispatch(showAuthMessage('Old password and new password should not be same'));
    else if (state.newPassword.value !== state.confirmPassword.value)
      storeDispatch(showAuthMessage('Confirm password does not match with the new password'));
    else {
      dispatch({ type: 'hideAllPassword' });
      let inputObj = {
        email: state.email.value,
        oldPassword: state.oldPassword.value,
        newPassword: state.newPassword.value,
      };
      storeDispatch(updatePassword(inputObj));
    }
  };

  const handleProfileUpdateSubmit = () => {
    if (state.firstName.value.trim() === '' || state.firstName.value.length < 3) {
      storeDispatch(showAuthMessage('First name should be at least 3 characters'));
      return;
    }
    if (state.lastName.value.trim() === '' || state.lastName.value.length < 3) {
      storeDispatch(showAuthMessage('Last name should be at least 3 characters'));
      return;
    }

    let inputObj = {
      firstName: state.firstName.value, //required
      lastName: state.lastName.value, // required
      phone: state.phone.value,
    };

    storeDispatch(updateProfile(inputObj));
  };

  const updateChangedProfile = () => {
    let parseUserDetails = userDetails;
    parseUserDetails.fn = state.firstName.value;
    parseUserDetails.ln = state.lastName.value;
    parseUserDetails.ph = state.phone.value;

    // localStorage.setItem("userDetails", JSON.stringify(parseUserDetails));

    //udpate userDetails in the store
    storeDispatch(update_profile_details(parseUserDetails));
  };

  const toggleVisibility = name => e => {
    dispatch({ type: 'toggleVisiblity', payload: { name } });
  };

  return (
    <>
      <Box className={classes.root}>
        <Typography
          variant='subtitle2'
          className={classes.backLink}
          onClick={() => props.history.goBack()}
        >
          {' '}
          {'< '}Back
        </Typography>
        <div className={classes.formWrapper}>
          <form noValidate autoComplete='off' className={classes.form}>
            <Typography variant='h6' gutterBottom>
              Update Personal Details
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor='firstName' required>
                    First Name
                  </InputLabel>
                  <BootstrapInput
                    type='text'
                    id='firstName'
                    name='firstName'
                    onChange={handleOnChange}
                    margin='none'
                    value={state.firstName.value}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor='lastName' required>
                    Last Name
                  </InputLabel>
                  <BootstrapInput
                    type='text'
                    id='lastName'
                    name='lastName'
                    onChange={handleOnChange}
                    margin='none'
                    value={state.lastName.value}
                    required
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor='email'>
                    Email
                  </InputLabel>
                  <BootstrapInput
                    type='email'
                    id='email'
                    name='email'
                    onChange={handleOnChange}
                    margin='none'
                    value={state.email.value}
                    readOnly
                    className={classes.readOnlyInput}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink htmlFor='phone'>
                    Phone
                  </InputLabel>
                  <BootstrapInput
                    type='text'
                    id='phone'
                    name='phone'
                    onChange={handleOnChange}
                    margin='none'
                    value={state.phone.value}
                  />
                </FormControl>
              </Grid>

              {showMessage && (
                <Grid item xs={12}>
                  <Collapse in={showMessage}>
                    <Alert severity={infoMessage !== '' ? 'success' : 'error'} icon={false}>
                      {infoMessage !== '' ? infoMessage : alertMessage}
                    </Alert>
                  </Collapse>
                </Grid>
              )}
            </Grid>

            <div className={classes.formBtnWrapper}>
              <IconButton
                variant='contained'
                color='primary'
                size='large'
                onClick={handleProfileUpdateSubmit}
              >
                <span>Update</span>
              </IconButton>
            </div>
          </form>
        </div>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.formWrapper}>
              <form noValidate autoComplete='off' className={classes.form}>
                <Typography variant='h6' gutterBottom>
                  Update Password
                </Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink htmlFor='oldPassword'>
                        Old Password
                      </InputLabel>
                      <BootstrapInput
                        type={state.oldPassword.visible ? 'text' : 'password'}
                        id='oldPassword'
                        name='oldPassword'
                        onChange={handleOnChange}
                        margin='none'
                        value={state.oldPassword.value}
                        endAdornment={
                          <MuiIconButton
                            onClick={toggleVisibility('oldPassword')}
                            size='small'
                            className={classes.inputIcon}
                          >
                            {state.oldPassword.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </MuiIconButton>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink htmlFor='newPassword'>
                        New Password
                      </InputLabel>
                      <BootstrapInput
                        type={state.newPassword.visible ? 'text' : 'password'}
                        id='newPassword'
                        name='newPassword'
                        onChange={handleOnChange}
                        margin='none'
                        value={state.newPassword.value}
                        endAdornment={
                          <MuiIconButton
                            onClick={toggleVisibility('newPassword')}
                            size='small'
                            className={classes.inputIcon}
                          >
                            {state.newPassword.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </MuiIconButton>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink htmlFor='confirmPassword'>
                        Confirm Password
                      </InputLabel>
                      <BootstrapInput
                        type={state.confirmPassword.visible ? 'text' : 'password'}
                        id='confirmPassword'
                        name='confirmPassword'
                        onChange={handleOnChange}
                        margin='none'
                        value={state.confirmPassword.value}
                        endAdornment={
                          <MuiIconButton
                            onClick={toggleVisibility('confirmPassword')}
                            size='small'
                            className={classes.inputIcon}
                          >
                            {state.confirmPassword.visible ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </MuiIconButton>
                        }
                      />
                    </FormControl>
                  </Grid>
                  {showMessage && (
                    <Grid item xs={12}>
                      <Collapse in={showMessage}>
                        <Alert severity={infoMessage !== '' ? 'success' : 'error'} icon={false}>
                          {infoMessage !== '' ? infoMessage : alertMessage}
                        </Alert>
                      </Collapse>
                    </Grid>
                  )}
                </Grid>
                <div className={classes.formBtnWrapper}>
                  <IconButton
                    variant='contained'
                    color='primary'
                    size='large'
                    onClick={handlePasswordUpdateSubmit}
                  >
                    <span>Update</span>
                  </IconButton>
                </div>
              </form>
            </div>
          </Grid>
        </Grid>

        <Backdrop className={classes.backdrop} open={loader}>
          <div className='fixContainer'>
            <div className='loader-view'>
              <CircularProgress />
            </div>
          </div>
        </Backdrop>
      </Box>
    </>
  );
};

export default ManageProfile;
