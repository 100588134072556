import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '../../../components/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AlertUi from 'containers/Msg-UI/AlertUi';
import SuccessUi from 'containers/Msg-UI/SuccessUi';
import '../../authStylings.css';

import { hideMessage, showAuthMessage, showAuthLoader, get_invitation_details } from 'actions/Auth';
import { isEmpty } from '../../../util';

const AcceptInvitation = props => {
  const initialState = {
    invitationId: { value: '', required: true, message: 'Valid Invite code is requried' },
  };

  const { loader, alertMessage, showMessage, invitationDetails, infoMessage } = useSelector(
    ({ auth }) => auth
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const classes = useStyles();

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      default:
        throw new Error();
    }
  }

  const handleOnChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const handleJoin = () => {
    let inputObj = validate();
    if (inputObj != false) {
      console.log('form ' + inputObj);
      storeDispatch(get_invitation_details(inputObj));
    }
  };

  const validate = () => {
    let inputAPI = {};
    for (var element in state) {
      if (validInput(state[element])) {
        storeDispatch(showAuthMessage(state[element].message));
        return false;
      }
      if (state[element].value != '') inputAPI[element] = state[element].value;
    }

    return inputAPI;
  };

  const validInput = input => {
    return input.required
      ? input.value == '' ||
          (input.min ? input.value.length < input.min : false) ||
          (input.hasOwnProperty('regex') ? !input.value.match(input.regex) : false)
      : input.value != '' &&
          (input.min ? input.value.length < input.min : false) &&
          (input.hasOwnProperty('regex') ? !input.value.match(input.regex) : false);
  };

  //   useEffect(() => {
  //     if (showMessage) {
  //       // setTimeout(() => {
  //       //   storeDispatch(hideMessage());
  //       // }, 1000);
  //     }
  //   }, [showMessage]);

  useEffect(() => {
    if (!isEmpty(invitationDetails)) props.history.push('/ReviewInvitation');
  }, [invitationDetails]);

  console.log('from acceptInvitation component', infoMessage);

  return (
    <div className='auth-card'>
      <div className={classes.main}>
        <div className={classes.left + ' animated slideInUpTiny animation-duration-3'}>
          <div style={{ marginBottom: '100px' }}>
            <h1
              style={{
                color: '#222F3C',
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              Enter invitation code
            </h1>
            <p style={{ fontSize: '14px' }}>Welcome. Please share your invite code</p>
          </div>
          <div style={{ marginTop: '-200px' }}>
            {showMessage && alertMessage !== '' && (
              <AlertUi variant='error'>{alertMessage}</AlertUi>
            )}
            {showMessage && infoMessage !== '' ? (
              <SuccessUi successMsg={infoMessage} />
            ) : (
              <form>
                <fieldset>
                  <TextField
                    id='invitationId'
                    label='Invite Code'
                    type='text'
                    name='invitationId'
                    margin='normal'
                    onChange={handleOnChange}
                    className='col-xs-12 col-lg-12 col-sm-12'
                  />
                  <div className='mt-3 text-right'>
                    <IconButton variant='contained' onClick={handleJoin} color='primary'>
                      <IntlMessages id='appModule.join' />
                    </IconButton>
                  </div>
                </fieldset>
              </form>
            )}
          </div>
          <div />
        </div>
        {/* right side image */}
        <div className={classes.right}>
          {/* <img width='70%' src={require('assets/images/conveyer-belt.png')} alt='conveyer' /> */}
        </div>
        <Backdrop className={classes.backdrop} open={loader}>
          <div className='fixContainer'>
            <div className='loader-view'>
              <CircularProgress />
            </div>
          </div>
        </Backdrop>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    minHeight: '80vh',
    backgroundColor: '#ffff',
    margin: '50px',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(196, 196, 196, 0.12)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '60px 60px 40px',
  },
  right: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#fff',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
}));

export default AcceptInvitation;
