import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DataGridWrapper from './DataGridWrapper';
import { GridColumnIcon } from "@glideapps/glide-data-grid";
import "@glideapps/glide-data-grid/dist/index.css";
import { auxBaseUrl } from 'util/urls';

export default function DataGrid() {
    const classes = useStyles();
    const [tableData, setTableData] = useState([]);
    const [tableIndex, setTableIndex] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [copyTableRows, setCopyTableRows] = useState([]);

    const renderTableData = async () => {
        try {
            const data = await fetchGridData();
            const index = Object.keys(data[0]);
            setTableData(data);
            setTableRows(data);
            setCopyTableRows(data);
            setTableIndex(index);
            setTableColumns(index.map(col => ({ title: `${col}`, width: 200, icon: GridColumnIcon.HeaderString })));
        } catch (error) {
            console.log(error)
        }
    }

    const fetchGridData = async () => {
        try {
            const response = await fetch(`${auxBaseUrl}utils/getGridData`, {
                method: "POST"
            });
            return await response.json();
        } catch (error) {
            console.log("Fetch error", error)
        }
    }

    useEffect(() => {
        renderTableData();
    }, []);


    const updateTableRowsOnFilter = (filterKey, condition, val) => {
        const value = val.toLowerCase();
        const filterArray = copyTableRows.filter(function (obj) {
            let propValue = obj[filterKey];

            try {
                switch (condition) {
                    case 'contains':
                        return propValue?.toLowerCase().includes(value);
                    case 'does not contain':
                        return !propValue?.toLowerCase().includes(value);
                    case 'is equal to':
                        return propValue?.toLowerCase() === value;
                    case 'is not equal to':
                        return propValue?.toLowerCase() !== value;
                    case 'is empty':
                        return !propValue || propValue.length === 0;
                    case 'is not empty':
                        return propValue && propValue.length > 0;
                    default:
                        return false;
                }
            } catch (error) {
                console.log(error);
            }
        });

        if (filterArray.length === 0) {
            // let newRowObj = {};
            // for (let u = 0; u < tableColumns.length; u++) {
            //     newRowObj[tableColumns[u].id] = '';
            // }

            // setTableRows([newRowObj]);
            return;
        } else {
            setTableRows([...filterArray]);
        }
    };
    const clearFilterFromRows = () => {
        setTableRows([...copyTableRows]);
    };


    const updateTableRowsOnSort = (sKey, sortOrder) => {
        let ascending = sortOrder === 'a2z' ? true : false;
        tableRows.sort(function (a, b) {
            if (typeof a[sKey] === 'string' && typeof b[sKey] === 'string') {
                try {
                    return ascending
                        ? a[sKey]?.localeCompare(b[sKey])
                        : b[sKey]?.localeCompare(a[sKey]);
                } catch (error) {
                    console.log('error on sort');
                }
            } else {
                try {
                    return ascending ? a[sKey] - b[sKey] : b[sKey] - a[sKey];
                } catch (error) {
                    console.log('error on sort');
                }
            }
        });

        setTableRows([...tableRows]);
    };

    return (
        <div className={classes.main}>
            {tableData.length ? (<DataGridWrapper updateTableRowsOnSort={updateTableRowsOnSort} updateTableRowsOnFilter={updateTableRowsOnFilter} clearFilterFromRows={clearFilterFromRows} data={tableRows} tableRows={tableRows} columns={tableColumns} index={tableIndex} />) : "Loading..."}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    main: {
        display: "grid",
        placeContent: "center",
        width: "100vw"
    }
}));
