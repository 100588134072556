import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { Button, Divider } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { contactsAPI, baseListUrl } from 'util/urls';
import CircularProgress from '@material-ui/core/CircularProgress';
import { auth } from '../../../firebase/firebase';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#f9f8f86b',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  DelConfirmModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  DelConfrimModalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    position: 'relative',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    '& .modalTitle': {
      fontWeight: 600,
      marginBottom: theme.spacing(3),
    },
    '& .modalBtns': {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
      '& > button': {
        border: '1px solid #c3c3c3',
        background: '#fff',
        boxShadow: 'none',
      },
    },
    '& .modalDescription': {
      fontWeight: 400,
      fontSize: theme.spacing(2),
    },
  },
}));

const getAuthToken = async () => await auth.currentUser.getIdToken();

const DeleteConfirmModal = ({ openModal, closeModal, listItem, uid, onConfirmDelete }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const deleteItemFromList = async () => {
    const payload = {
      uid: uid,
      lid: listItem.lid,
    };
    setIsLoading(true);
    try {
      const token = await getAuthToken();
      const res = await fetch(baseListUrl + contactsAPI + '/deleteListFromMain', {
        method: 'POST',
        headers: new Headers({
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(payload),
      });

      if (res?.ok) {
        onConfirmDelete();
        closeModal(false);
        setIsLoading(false);
        window.location.reload(false);
      }
    } catch (error) {
      closeModal(false);
      setIsLoading(false);
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.DelConfirmModal}
        open={openModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.DelConfrimModalPaper}>
            {isLoading && (
              <div className={classes.backdrop}>
                <CircularProgress />
              </div>
            )}
            <div>
              <h3 id='transition-modal-title' className='modalTitle'>
                Confirm Deletion
              </h3>
              <p id='transition-modal-description' className='modalDescription'>
                Are you sure you want to delete <strong>{listItem?.lnm} ?</strong>
                <br />
                <br />
                You will lose all data stored in this stream and this cannot be undone.
              </p>
              <Divider />
              <div className='modalBtns'>
                <Button variant='contained' onClick={deleteItemFromList}>
                  Delete
                </Button>
                <Button variant='contained' onClick={() => closeModal(false)}>
                  Don't Delete
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default DeleteConfirmModal;
