import React, { useEffect } from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import URLSearchParams from 'url-search-params';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import '../App.css';
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import AppLocale from '../lngProvider';
import EmailConfirmationCheck from '../containers/EmailConfirmationCheck';
import ActionAuth from '../containers/ActionAuth';
import AcceptInvitation from './InviteUser/AcceptInvitation';
import ReviewInvitation from '../containers/InviteUser/ReviewInvitation';
import DataGrid from '../containers/DataGrid';

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK,
} from 'constants/ThemeColors';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import Waitlist from './Waitlist';
import {
  setFirebaseAuthStateLoaded,
  setInitUrl,
  userSignInSuccess,
  userSignOut,
} from '../actions/Auth';
import RTL from 'util/RTL';
// import asyncComponent from 'util/asyncComponent';
import { setDarkTheme, setThemeColor } from '../actions/Setting';
import AppLayout from './AppLayout';
import { auth } from '../firebase/firebase';
import CrmCallback from 'app/routes/CrmCallback';
import Error404 from 'app/routes/ErrorPages/404';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: sessionStorage['gotoRegister'] ? '/signup' : '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const getUrlVars = () => {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
};

const App = props => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const isDarkTheme = darkTheme;
  const { match, location } = props;

  /* const goToNextPage = async () => {

    await new Promise((res, rej) => {
      auth.onAuthStateChanged(async function (user) {
        if (user) {
          if (!user.emailVerified)
            props.history.push('/email-confirmation-check');
          else
            props.history.push('/app/home');
        }
      });
    });

  } */

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      // console.log("use effect App.js "+ props.history.location.pathname);
      dispatch(setInitUrl(props.history.location.pathname));
    }
    const params = new URLSearchParams(props.location.search);
    if (params.has('theme-name')) {
      dispatch(setThemeColor(params.get('theme-name')));
    }
    if (params.has('dark-theme')) {
      dispatch(setDarkTheme());
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);

  useEffect(() => {
    let unsubscribe;
    unsubscribe = auth.onAuthStateChanged(async function(user) {
      if (user) {
        dispatch(setFirebaseAuthStateLoaded());
      } else dispatch(userSignOut());
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme({ ...indigoTheme, direction: 'rtl' });
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      default:
        createMuiTheme(indigoTheme);
    }
    return applyTheme;
  };

  let applyTheme = createMuiTheme(indigoTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createMuiTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
  }

  console.log('location pathname = ' + location.pathname);
  console.log('initUrl = ' + initURL);

  if (location.pathname === '/') {
    if (authUser === null) {
      const token = getUrlVars()['token'];
      if (token) {
        localStorage.setItem('user_id', token);
        dispatch(userSignInSuccess(token));
      }
      console.log('step 1 redirect to /signin');
      return <Redirect to={'/signin'} />;
    } else if (
      initURL === '' ||
      initURL === '/' ||
      initURL === '/signin' ||
      initURL === '/signup'
    ) {
      // await goToNextPage();
      console.log('step 2 redirect to ' + initURL);
      return <Redirect to={'/app/home'} />;
    } else {
      console.log('step 3 redirect to ' + initURL);
      return <Redirect to={initURL} />;
    }
  }
  if (isDirectionRTL) {
    applyTheme.direction = 'rtl';
    document.body.classList.add('rtl');
  } else {
    document.body.classList.remove('rtl');
    applyTheme.direction = 'ltr';
  }

  // console.log("App js match url "+ match.url);

  const currentAppLocale = AppLocale[locale.locale];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <RTL>
            <div className='app-main'>
              <Switch>
                <Route path={`/app/crm/callback`} component={CrmCallback} />
                <RestrictedRoute
                  path={`${match.url}app`}
                  authUser={authUser}
                  component={AppLayout}
                />
                <Route path='/signin' component={SignIn} />
                <Route path='/welcome' component={SignUp} />
                <Route path='/signup' component={Waitlist} />
                <Route path='/forgotpassword' component={ForgotPassword} />
                <Route path='/email-confirmation-check' component={EmailConfirmationCheck} />
                <Route path='/authaction' component={ActionAuth} />
                <Route path='/AcceptInvitation' component={AcceptInvitation} />
                <Route path='/reviewInvitation' component={ReviewInvitation} />
                <Route path='/data-grid' component={DataGrid} />
                <Route path='/404' component={Error404} />
                <Redirect
                  to={{
                    pathname: '/404',
                  }}
                />
              </Switch>
            </div>
          </RTL>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
