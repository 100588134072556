import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Paper,
  Stepper,
  Step,
  StepLabel,
  TextField,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import { getSteps } from './stepperData';
import Cards from 'assets/images/payment/Cards.svg';
import creditCard from 'assets/images/payment/creditCard.svg';
import cvvIcon from 'assets/images/payment/cvvIcon.svg';
import userIcon from 'assets/images/payment/userIcon.svg';
import Mail from 'assets/images/payment/Mail.svg';
import SmartPhone from 'assets/images/payment/SmartPhone.svg';
import { baseSubscriptionUrl } from 'util/urls';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import StripeInput from './StripeInput';
import { getAuthToken } from '../../../sagas/Auth';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '30px',
  },
  title: {
    textAlign: 'center',
    '& h1': {
      fontSize: '1.8rem',
      fontWeight: '900',
    },
  },
  hideElement: {
    display: 'none',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '800px 350px',
    gridTemplateRows: '650px',
    placeContent: 'center',
    gap: '80px',
  },
  paymentMain: {
    height: '85%',
    borderRadius: '5px',
    display: 'grid',
    gridTemplateRows: '20% 80%',
  },
  paymentStepper: {
    // borderBottom: "1px solid #BDBDBD !important",
    borderRadius: '5px 5px 0 0',
  },
  paymentFormWrapper: {
    padding: theme.spacing(3),
  },
  paymentForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: theme.spacing(2),
  },
  paymentFormInline: {
    display: 'grid',
    gridTemplateColumns: '3.5fr 4fr 4fr',
    gap: '20px',
  },
  paymentFormInline2: {
    display: 'grid',
    gridTemplateColumns: '60% 1fr',
    gap: '30px',
    '& img': {
      alignSelf: 'center',
    },
  },
  paymentButton: {
    alignSelf: 'flex-end',
    width: '25%',
    padding: '10px 15px',
    fontWeight: 'bold',
  },
  paymentUserForm: {
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gap: '20px 10px',
    gridTemplateAreas: `"fname fname lname lname"
                        "email email email email"
                        "phone phone phone phone"
                        "addr1 addr1 addr2 addr2"
                        "city state country zip"
                        "btnback btnback btn btn"`,
  },
  orderSummary: {
    height: '100%',
    borderRadius: '5px',
    '& h1': {
      padding: theme.spacing(3),
      fontWeight: 'bold',
      borderBottom: '1px solid #BDBDBD',
    },
  },
  orderSummaryBody: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    '& div': {
      // border: "1px solid cyan",
    },
  },
  planDetails: {
    display: 'flex',
    flexDirection: 'column',
    '& div:nth-child(1)': {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      '& h3': {
        fontWeight: '800',
      },
    },
    '& div:nth-child(2)': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
  },
  promo: {
    display: 'grid',
    gridTemplateColumns: '4fr 3fr',
    gap: '30px',
  },
  totalAmount: {
    display: 'flex',
    padding: '30px 0',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    '& h3': {
      fontWeight: '800',
      margin: '0',
    },
  },
}));

const PaymentCheckout = () => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [prices, setPrices] = useState([]);
  const [showCardPaymentForm, setShowCardPaymentForm] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState('');
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [paymentError, setPaymentError] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const { companyDetails, userDetails } = useSelector(({ auth }) => auth);
  const [planQuantity, setPlanQuantity] = useState(1);
  const [stripeC, setStripeC] = useState({});

  const [form1State, setForm1State] = useState({
    cardNumber: { empty: true, complete: false },
    cardExpiry: { empty: true, complete: false },
    cardCvc: { empty: true, complete: false },
  });

  const fetchPrices = async () => {
    const res = await fetch(baseSubscriptionUrl + 'api/pgController/prices', {
      method: 'GET',
    })
      .then(async response => {
        return await response.json();
      })
      .catch(err => err);
    console.log(res);

    await setPrices(res.prices);
  };

  const fetchStripeCustomer = async () => {
    try {
      const cid = companyDetails.cid;
      const token = await getAuthToken();
      let headers = new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      });
      const payload = {
        cid: cid,
      };
      const fetchCustomer = await fetch(
        baseSubscriptionUrl + 'api/pgController/getStripeCustomerInfoByCid',
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload),
        }
      );
      return await fetchCustomer.json();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const cid = companyDetails.cid;
      const token = await getAuthToken();
      let headers = new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      });
      const payload = {
        cid: cid,
      };
      const fetchCompany = await fetch(
        baseSubscriptionUrl + 'api/subscController/getCompanyDetails',
        {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(payload),
        }
      );
      return await fetchCompany.json();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPrices();
    setSelectedPrice(history.location?.state?.selectedPlan || null);
  }, [history]);

  useEffect(() => {
    (async () => {
      const res = await fetchStripeCustomer();
      setStripeC({ ...res?.customer });
    })();
    console.log(stripeC);
  }, []);

  const selectPriceAndShowCard = selectedPrice => {
    setSelectedPrice(selectedPrice);
    console.log('Selected Price Check out ->' + selectedPrice);
    setShowCardPaymentForm(true);
  };

  const createSubs = async paymentMethod => {
    const payload = {
      paymentMethodId: paymentMethod.id,
      cid: companyDetails.cid,
      priceId: selectedPrice.id,
      seats: planQuantity,
    };
    const token = await getAuthToken();

    let headers = new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    return await fetch(baseSubscriptionUrl + 'api/pgController/create-subscription', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(err => err);
  };

  const handlePay = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setPaymentError('');

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);
    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: `${userDetails.fn} ${userDetails.ln}`,
        address: {
          country: 'US',
          city: 'New York',
          line1: '123 Berry street',
        },
      },
    });

    if (error) {
      console.log('[error]', error);
      setPaymentError(error.message);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      //  * check if user exists in stripe
      if (!stripeC?.subscriptions?.data?.[0]?.items?.data?.[0]?.plan?.id) {
        const response = await createSubs(paymentMethod);
        if (response.error) {
          setPaymentError(response.error.message);
          return;
        }
        console.log(response);
        if (response.status === 'active') {
          setShowInfoMessage('subscription successful🥳');
        } else if (response.status === 'requires_action') {
          setShowInfoMessage('requires action');
        } else if (response.status === 'requires_payment_method') {
          setShowInfoMessage('requires another payment method');
        }
      } else {
        const sub = stripeC?.subscriptions?.data[0];
        const itemId = sub.items.data[0].id;
        const companyDetailsFromDb = await fetchCompanyDetails();
        const toalSeats = companyDetailsFromDb?.seatsTotal + planQuantity;
        const response = await updateSubs({
          subId: itemId,
          quantity: toalSeats,
        });
        console.log('response from updation', response);
      }
    }
  };

  const updateSubs = async ({ subId, quantity }) => {
    try {
      const payload = {
        subId,
        quantity,
      };
      const token = await getAuthToken();

      let headers = new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      });
      return await fetch(baseSubscriptionUrl + 'api/pgController/update-subscription', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      })
        .then(async response => {
          return await response.json();
        })
        .catch(err => err);
    } catch (err) {
      console.log('[Update subs Error] ', err);
    }
  };

  if (!selectedPrice) {
    return <div>Plan Not Selected</div>;
  }
  return (
    <Box className={classes.root}>
      <div className={classes.title}>
        <h1>Billing Information</h1>
      </div>
      <div className={classes.gridContainer}>
        <Paper className={classes.paymentMain}>
          <div className={classes.paymentStepper}>
            <Stepper className={classes.paymentStepper} activeStep={activeStep} alternativeLabel>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {/* ! Active Step 1 */}
          <div
            className={`${classes.paymentFormWrapper} ${
              activeStep === 0 ? '' : classes.hideElement
            }`}
          >
            <h3 style={{ fontWeight: 'bold' }}>Credit Card Information</h3>
            <form className={classes.paymentForm} onSubmit={handlePay}>
              <div className={classes.paymentFormInline2}>
                {/* CARD NUMBER */}
                <TextField
                  label='Credit Card Number'
                  name='ccnumber'
                  variant='outlined'
                  size='small'
                  error={form1State?.cardNumber?.error}
                  helperText={
                    form1State?.cardNumber?.error && form1State?.cardNumber?.error?.message
                  }
                  onChange={event => setForm1State({ ...form1State, cardNumber: event })}
                  required
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardNumberElement,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <img src={creditCard} alt='icon' />
                      </InputAdornment>
                    ),
                  }}
                />
                <img src={Cards} alt='icon' />
              </div>
              {/**
               * ! Name on Card
               *
               */}
              <TextField
                label='Name on the card'
                variant='outlined'
                size='small'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <img src={userIcon} alt='icon' />
                    </InputAdornment>
                  ),
                }}
              />
              {/* CARD EXPIRY */}
              <div className={classes.paymentFormInline}>
                <TextField
                  required
                  label='Expiry Date'
                  variant='outlined'
                  size='small'
                  error={form1State?.cardExpiry?.error}
                  helperText={
                    form1State?.cardExpiry?.error && form1State?.cardExpiry?.error?.message
                  }
                  onChange={event => setForm1State({ ...form1State, cardExpiry: event })}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardExpiryElement,
                    },
                  }}
                />
                {/* * CVV or CVC whateves */}
                <TextField
                  required
                  label='Security Code'
                  variant='outlined'
                  size='small'
                  error={form1State?.cardCvc?.error}
                  helperText={form1State?.cardCvc?.error && form1State?.cardCvc?.error?.message}
                  onChange={event => setForm1State({ ...form1State, cardCvc: event })}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardCvcElement,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <img src={cvvIcon} alt='icon' />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField required select label='Type of Card' variant='outlined' size='small'>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'master'}>Master</MenuItem>
                  <MenuItem value={'visa'}>Visa</MenuItem>
                  <MenuItem value={'amex'}>Amex</MenuItem>
                </TextField>
              </div>
              <TextField required select label='Bank' variant='outlined' size='small'>
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={'citi'}>CitiBank</MenuItem>
                <MenuItem value={'hsbc'}>HSBC</MenuItem>
                <MenuItem value={'amex'}>American Express</MenuItem>
              </TextField>
              <Button
                className={classes.paymentButton}
                size='large'
                variant='outlined'
                color='primary'
                disabled={
                  !(
                    !form1State?.cardNumber?.error &&
                    !form1State?.cardExpiry?.error &&
                    !form1State?.cardCvc?.error &&
                    form1State?.cardNumber?.complete &&
                    form1State?.cardExpiry?.complete &&
                    form1State?.cardCvc?.complete &&
                    form1State
                  )
                }
                onClick={() => setActiveStep(1)}
              >
                Continue
              </Button>
            </form>
          </div>

          {/* Active Step 2  */}
          <div
            className={`${classes.paymentFormWrapper} ${
              activeStep === 1 ? '' : classes.hideElement
            }`}
          >
            <h3 style={{ fontWeight: 'bold' }}>Credit Card Information</h3>
            <form className={classes.paymentUserForm}>
              <TextField
                required
                label='First Name'
                variant='outlined'
                size='small'
                style={{ gridArea: 'fname' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <img src={userIcon} alt='icon' />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label='Last Name'
                variant='outlined'
                size='small'
                style={{ gridArea: 'lname' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <img src={userIcon} alt='icon' />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label='Email'
                variant='outlined'
                size='small'
                style={{ gridArea: 'email' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <img src={Mail} alt='icon' />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label='Phone Number'
                variant='outlined'
                size='small'
                style={{ gridArea: 'phone' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <img src={SmartPhone} alt='icon' />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label='Address Line 1'
                variant='outlined'
                size='small'
                style={{ gridArea: 'addr1' }}
              />
              <TextField
                label='Address Line 2'
                variant='outlined'
                size='small'
                style={{ gridArea: 'addr2' }}
              />
              <TextField
                label='City'
                style={{ gridArea: 'city' }}
                variant='outlined'
                size='small'
              />
              <TextField
                label='State'
                style={{ gridArea: 'state' }}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Country'
                style={{ gridArea: 'country' }}
                variant='outlined'
                size='small'
              />
              <TextField
                label='Zipcode'
                style={{ gridArea: 'zip' }}
                variant='outlined'
                size='small'
              />
              <Button
                className={classes.paymentButton}
                style={{
                  gridArea: 'btnback',
                  justifySelf: 'start',
                  width: '40%',
                }}
                size='large'
                variant='outlined'
                color='primary'
                onClick={() => setActiveStep(0)}
              >
                Go Back
              </Button>
              <Button
                className={classes.paymentButton}
                style={{ gridArea: 'btn', justifySelf: 'end', width: '40%' }}
                size='large'
                variant='outlined'
                color='primary'
                onClick={() => {}}
              >
                Save
              </Button>
            </form>
          </div>
        </Paper>
        <Paper className={classes.orderSummary}>
          <h1>Order Summary</h1>
          <div className={classes.orderSummaryBody}>
            {/* plan details */}
            <div className={classes.planDetails}>
              <div>
                <h3>{selectedPrice.product.name} Plan</h3>
                <h3>${selectedPrice.unit_amount / 100}</h3>
              </div>
              <div>
                <TextField
                  select
                  variant='outlined'
                  size='small'
                  label='Users'
                  value={planQuantity}
                  style={{ width: '30%' }}
                  onChange={e => setPlanQuantity(e.target.value)}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </TextField>

                <p>{selectedPrice.recurring.interval + 'ly'}</p>
              </div>
            </div>
            {/* Promo code form */}
            <div className={classes.promo}>
              <TextField id='outlined-basic' label='Promo Code' variant='outlined' />
              <Button
                style={{ textTransform: 'none' }}
                variant='contained'
                size='large'
                color='primary'
                disabled
                // disableElevation
              >
                Apply
              </Button>
            </div>
            {/* Total Amount */}
            <div className={classes.totalAmount}>
              <h3>Total (Charged {selectedPrice.recurring.interval + 'ly'})</h3>
              <h3>${(selectedPrice.unit_amount / 100) * planQuantity}</h3>
            </div>
            {/* Payment Button */}
            <div>
              <Button
                style={{
                  textTransform: 'none',
                  fontWeight: '800',
                  fontSize: '1.2rem',
                  width: '100%',
                  padding: '15px 40px',
                }}
                variant='contained'
                size='large'
                color='primary'
                onClick={handlePay}
                disabled={
                  !(
                    !form1State?.cardNumber?.error &&
                    !form1State?.cardExpiry?.error &&
                    !form1State?.cardCvc?.error &&
                    form1State?.cardNumber?.complete &&
                    form1State?.cardExpiry?.complete &&
                    form1State?.cardCvc?.complete &&
                    form1State &&
                    activeStep
                  )
                }
              >
                Make Payment
              </Button>
            </div>
            {/* Safety Image */}
            <div></div>
          </div>
        </Paper>
      </div>
    </Box>
  );
};

export default PaymentCheckout;
