import {
  SHOW_CHECK_UPDATE_MATCH_LIST,
  HIDE_CHECK_UPDATE_MATCH_LIST,
  START_CHECK_UPDATE,
  CHECK_UPDATE_SUCCESS,
  CHECK_UPDATE_LOADING,
  CHECK_UPDATE_ERROR,
  FORCE_UPDATE,
  FORCE_UPDATE_ERROR,
  FORCE_UPDATE_SUCCESS,
  COMPANY_UPDATE_ERROR,
  COMPANY_UPDATE_SUCCESS,
  START_COMPANY_UPDATE,
  COMPANY_UPDATE_LOADING,
} from 'constants/ActionTypes';
import {
  FETCH_ALL_CONTACT_SUCCESS,
  GET_ALL_CONTACT,
  HANDLE_REQUEST_CLOSE,
  HIDE_CONTACT_LOADER,
  SHOW_CONTACT_MESSAGE,
  SHOW_CONTACT_INFO_MESSAGE,
  HIDE_CONTACT_MESSAGE,
  GET_CONTACTS_LIST,
  GET_LIST_NAMES_RESET,
  CONTACT_LIST_RESULT,
  SET_CHECKED_UPDATED_LIST_RESULT,
  GET_LIST_NAMES,
  CREATE_CONTACT,
  MOVE_CONTACT,
  CONTACT_MOVED,
  GET_LIST_NAMES_SUCCESS,
  NEW_CONTACT_CREATED,
  CREATE_CUSTOM_LIST,
  VIEW_CUSTOM_LIST,
  SAVE_TO_CUSTOM_LIST,
  GET_LIST_NAME_BY_USER,
  UPDATE_LIST_SUB,
  GET_DEFAULT_LIST,
  SET_DEFAULT_LIST,
  UPDATE_PEOPLE_ENRICH,
  UPDATE_COMPANY_ENRICH,
  GET_LATEST_PEOPLE_N_RECORDS_FOR_USER,
  GET_LATEST_COMPANY_N_RECORDS_FOR_USER,
  GET_SPECIFIC_RECORDS_FOR_USER,
  MOVE_CONTACT_TO_LIST,
  MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS,
  MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS,
  CREATE_PEOPLE_CUSTOM_LIST_SUCCESS,
  CREATE_COMPANY_CUSTOM_LIST_SUCCESS,
  SAVE_TO_CUSTOM_LIST_SUCCESS,
  GET_PEOPLE_LIST_NAME_BY_USER_SUCCESS,
  GET_COMPANY_LIST_NAME_BY_USER_SUCCESS,
  UPDATE_LIST_SUB_SUCCESS,
  GET_DEFAULT_LIST_SUCCESS,
  SET_DEFAULT_PEOPLE_LIST_SUCCESS,
  SET_DEFAULT_COMPANY_LIST_SUCCESS,
  GET_LATEST_PEOPLE_N_RECORDS_FOR_USER_SUCCESS,
  GET_LATEST_COMPANY_N_RECORDS_FOR_USER_SUCCESS,
  GET_SPECIFIC_RECORDS_FOR_USER_SUCCESS,
  STOP_FETCHING_PEOPLELIST,
  STOP_FETCHING_COMPANYLIST,
  UDPATE_SET_DEFAULT_LISTNAME_PEOPLELIST,
  UDPATE_SET_DEFAULT_LISTNAME_COMPANYLIST,
  VIEW_CUSTOM_PEOPLE_LIST_SUCCESS,
  VIEW_CUSTOM_COMPANY_LIST_SUCCESS,
  UPDATE_PEOPLE_ENRICH_INFO,
  GET_RE_ENRICH_1_SUCCESS,
  START_ENRICHMENT_1_FETCHING,
  UPDATE_PEOPLE_ENRICH_INFO_SUCCESS,
  START_CONTACT_LOADER,
  GET_RE_ENRICH_1_FAILED,
  GET_ENRICH_2_FROM_PEOPLE_SUCCESS,
  GET_ENRICH_2_FROM_PEOPLE_FAILED,
  DELETE_PEOPLE_LIST_NAMES,
} from 'constants/ActionTypes';

import { getNumberOfPeopleLatestFetchRecords, getNumberOfCompanyLatestFetchRecords } from '../util';

const INIT_STATE = {
  contactsAlertMessage: '',
  contactsInfoMessage: '',
  contactsShowMessage: false,
  contactsLoader: false,
  noContentFoundMessage: 'No contact found in selected folder',
  selectedSectionId: 1,
  drawerState: false,
  user: {
    name: 'Robert Johnson',
    email: 'robert.johnson@example.com',
    avatar: 'https://via.placeholder.com/150x150',
  },
  searchUser: '',
  filterOption: 'All contacts',
  // allContact: [],
  // contactList: [], //only for prod
  // allContact: data,
  // contactList: data,
  selectedContact: null,
  selectedContacts: 0,
  addContactState: false,
  contactsList: [],
  contactListNames: [],
  newContactCreatedItem: {},
  contactMoved: false,
  // list controller firestore
  peopleListNames: [],
  companyListNames: [],

  peopleEnrichedList: [],
  companyEnrichedList: [],
  fetchingPeopleEnrichListOver: false,
  fetchingCompanyEnrichListOver: false,
  isEnrichment1Fetching: false,
  crmUserMatchData: {},
  showCrmUserMatchList: -1,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CONTACT_SUCCESS: {
      return {
        ...state,
        contactsLoader: false,
        allContact: action.payload,
        contactList: action.payload,
      };
    }
    case SHOW_CONTACT_MESSAGE: {
      return {
        ...state,
        contactsAlertMessage: action.payload,
        contactsShowMessage: true,
        contactsLoader: false,
        contactMoved: false,
        newContactCreatedItem: {},
      };
    }
    case SHOW_CONTACT_INFO_MESSAGE: {
      return {
        ...state,
        contactsInfoMessage: action.payload,
        contactsShowMessage: true,
        contactsLoader: false,
      };
    }

    case HIDE_CONTACT_MESSAGE: {
      return {
        ...state,
        contactsShowMessage: false,
        contactsAlertMessage: '',
        contactsInfoMessage: '',
      };
    }

    case GET_ALL_CONTACT: {
      let contactList = state.allContact.map(contact =>
        contact
          ? {
              ...contact,
              selected: true,
            }
          : contact
      );
      return {
        ...state,
        selectedContacts: contactList.length,
        allContact: contactList,
        contactList: contactList,
      };
    }

    case GET_LIST_NAMES: {
      return {
        ...state,
        contactsLoader: true,
        contactListNames: [],
      };
    }

    case GET_LIST_NAMES_SUCCESS: {
      return {
        ...state,
        contactsLoader: false,
        contactListNames: action.payload,
      };
    }

    case GET_CONTACTS_LIST: {
      return {
        ...state,
        contactsLoader: true,
        contactsList: [],
      };
    }

    case CONTACT_LIST_RESULT: {
      let contactList = action.payload.map(contact =>
        contact
          ? {
              ...contact,
              selected: false,
            }
          : contact
      );
      return {
        ...state,
        contactsLoader: false,
        contactsList: contactList,
      };
    }

    case SET_CHECKED_UPDATED_LIST_RESULT: {
      return {
        ...state,
        contactsLoader: false,
        contactsList: action.payload,
      };
    }

    case CREATE_CONTACT: {
      return {
        ...state,
        contactsLoader: true,
        newContactCreatedItem: {},
      };
    }

    case MOVE_CONTACT: {
      return {
        ...state,
        contactsLoader: true,
        contactMoved: false,
      };
    }

    case NEW_CONTACT_CREATED: {
      return {
        ...state,
        contactsLoader: false,
        newContactCreatedItem: action.payload,
        contactsInfoMessage: action.payload.message,
        contactsShowMessage: true,
      };
    }

    case CONTACT_MOVED: {
      return {
        ...state,
        // contactsLoader: false,
        contactMoved: true,
        contactsInfoMessage: action.payload,
        contactsShowMessage: true,
        newContactCreatedItem: {},
      };
    }

    /**fire store list manager */

    case CREATE_CUSTOM_LIST: {
      return {
        ...state,
        contactsLoader: true,
      };
    }

    case CREATE_PEOPLE_CUSTOM_LIST_SUCCESS: {
      return {
        ...state,
        peopleListNames: [...state.peopleListNames, action.payload],
        contactsLoader: false,
      };
    }

    case DELETE_PEOPLE_LIST_NAMES: {
      const filteredList = state.peopleListNames.filter(list => list.lid !== action.payload);
      return {
        ...state,
        peopleListNames: filteredList,
        contactsLoader: false,
      };
    }

    case CREATE_COMPANY_CUSTOM_LIST_SUCCESS: {
      return {
        ...state,
        companyListNames: [...state.companyListNames, action.payload],
        contactsLoader: false,
      };
    }

    case VIEW_CUSTOM_LIST: {
      //first set of records
      if (action.payload.page == 0)
        return {
          ...state,
          contactsLoader: true,
          peopleEnrichedList: [],
          companyEnrichedList: [],
          fetchingPeopleEnrichListOver: false,
          fetchingCompanyEnrichListOver: false,
        };
      return {
        ...state,
        contactsLoader: true,
      };
    }

    case VIEW_CUSTOM_PEOPLE_LIST_SUCCESS: {
      return {
        ...state,
        contactsLoader: false,
        peopleEnrichedList: [...state.peopleEnrichedList, ...action.payload],
      };
    }

    case VIEW_CUSTOM_COMPANY_LIST_SUCCESS: {
      return {
        ...state,
        contactsLoader: false,
        companyEnrichedList: [...state.companyEnrichedList, ...action.payload],
      };
    }

    case SAVE_TO_CUSTOM_LIST: {
      return {
        ...state,
        contactsLoader: true,
      };
    }
    case SAVE_TO_CUSTOM_LIST_SUCCESS: {
      return {
        ...state,
        contactsLoader: false,
      };
    }
    case GET_LIST_NAME_BY_USER: {
      return {
        ...state,
        // contactsLoader: true,
      };
    }
    case GET_PEOPLE_LIST_NAME_BY_USER_SUCCESS: {
      return {
        ...state,
        peopleListNames: action.payload,
        // contactsLoader: false,
      };
    }

    case GET_COMPANY_LIST_NAME_BY_USER_SUCCESS: {
      return {
        ...state,
        companyListNames: action.payload,
        contactsLoader: false,
      };
    }

    case UPDATE_LIST_SUB: {
      return {
        ...state,
        contactsLoader: true,
      };
    }
    case UPDATE_LIST_SUB_SUCCESS: {
      return {
        ...state,
        contactsLoader: false,
      };
    }

    case GET_DEFAULT_LIST: {
      return {
        ...state,
      };
    }
    case GET_DEFAULT_LIST_SUCCESS: {
      return {
        ...state,
      };
    }
    case SET_DEFAULT_LIST: {
      return {
        ...state,
        contactsLoader: true,
      };
    }

    case SET_DEFAULT_PEOPLE_LIST_SUCCESS: {
      return {
        ...state,
        peopleListNames: [...action.payload],
        contactsLoader: false,
      };
    }

    case SET_DEFAULT_COMPANY_LIST_SUCCESS: {
      return {
        ...state,
        companyListNames: [...action.payload],
        contactsLoader: false,
      };
    }

    case UPDATE_PEOPLE_ENRICH: {
      return {
        ...state,
        peopleEnrichedList: action.payload,
      };
    }

    case UPDATE_COMPANY_ENRICH: {
      return {
        ...state,
        companyEnrichedList: action.payload,
      };
    }

    case UPDATE_PEOPLE_ENRICH_INFO_SUCCESS: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
        contactsLoader: false,
      };
    }

    case GET_LATEST_PEOPLE_N_RECORDS_FOR_USER: {
      //first set of records
      if (action.payload.recordCount == getNumberOfPeopleLatestFetchRecords)
        return {
          ...state,
          peopleEnrichedList: [],
          contactsLoader: true,
          fetchingPeopleEnrichListOver: false,
        };
      else
        return {
          ...state,
          contactsLoader: true,
        };
    }

    case GET_LATEST_PEOPLE_N_RECORDS_FOR_USER_SUCCESS: {
      if (state.peopleEnrichedList.length == action.payload.length)
        return {
          ...state,
          peopleEnrichedList: [...action.payload],
          contactsLoader: false,
          fetchingPeopleEnrichListOver: true,
        };

      return {
        ...state,
        peopleEnrichedList: [...action.payload],
        contactsLoader: false,
      };
    }

    case GET_LATEST_COMPANY_N_RECORDS_FOR_USER: {
      //first set of records
      if (action.payload.recordCount == getNumberOfCompanyLatestFetchRecords)
        return {
          ...state,
          companyEnrichedList: [],
          contactsLoader: true,
          fetchingCompanyEnrichListOver: false,
        };
      else
        return {
          ...state,
          contactsLoader: true,
        };
    }

    case GET_LATEST_COMPANY_N_RECORDS_FOR_USER_SUCCESS: {
      if (state.companyEnrichedList.length == action.payload.length)
        return {
          ...state,
          companyEnrichedList: [...action.payload],
          contactsLoader: false,
          fetchingCompanyEnrichListOver: true,
        };

      return {
        ...state,
        companyEnrichedList: [...action.payload],
        contactsLoader: false,
      };
    }

    case STOP_FETCHING_PEOPLELIST: {
      return {
        ...state,
        fetchingPeopleEnrichListOver: true,
        contactsLoader: false,
      };
    }

    case STOP_FETCHING_COMPANYLIST: {
      return {
        ...state,
        fetchingCompanyEnrichListOver: true,
        contactsLoader: false,
      };
    }

    case GET_SPECIFIC_RECORDS_FOR_USER: {
      return {
        ...state,
        contactsLoader: true,
      };
    }

    case GET_SPECIFIC_RECORDS_FOR_USER_SUCCESS: {
      return {
        ...state,
        contactsLoader: false,
      };
    }

    case MOVE_CONTACT_TO_LIST: {
      return {
        ...state,
        contactsLoader: true,
      };
    }

    case MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS: {
      //find with record id
      let peopleEnrichindex = state.peopleEnrichedList.findIndex(
        item => item.identity.recordId == action.payload.recodId
      );

      if (peopleEnrichindex != -1) {
        state.peopleEnrichedList[peopleEnrichindex] = {
          ...state.peopleEnrichedList[peopleEnrichindex],
          identity: {
            ...state.peopleEnrichedList[peopleEnrichindex].identity,
            lid: action.payload.lidTo,
            lnm: action.payload.lidToName,
          },
        };

        console.log('moved people in contact reducer ', state.peopleEnrichedList);

        return {
          ...state,
          contactsLoader: false,
          peopleEnrichedList: [...state.peopleEnrichedList],
        };
      }

      return {
        ...state,
        contactsLoader: false,
      };
    }

    /* difference from people in identity block is in company 
    search page there is not default list */
    case MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS: {
      //find with record id
      let companyEnrichindex = state.companyEnrichedList.findIndex(
        item => item.identity.recordId == action.payload.recodId
      );

      if (companyEnrichindex != -1) {
        state.companyEnrichedList[companyEnrichindex] = {
          ...state.companyEnrichedList[companyEnrichindex],
          identity: {
            ...state.companyEnrichedList[companyEnrichindex].identity,
            lid: action.payload.lidTo,
            lnm: action.payload.lidToName,
          },
          lid: action.payload.lidTo,
          lnm: action.payload.lidToName,
        };

        return {
          ...state,
          contactsLoader: false,
          companyEnrichedList: [...state.companyEnrichedList],
        };
      }
      return {
        ...state,
        contactsLoader: false,
      };
    }

    case HANDLE_REQUEST_CLOSE: {
      return {
        ...state,
        contactsShowMessage: false,
        contactsAlertMessage: '',
        contactsInfoMessage: '',
      };
    }

    case HIDE_CONTACT_LOADER: {
      return { ...state, contactsLoader: false };
    }

    case START_CONTACT_LOADER: {
      return { ...state, contactsLoader: true };
    }

    case START_ENRICHMENT_1_FETCHING: {
      return {
        ...state,
        isEnrichment1Fetching: true,
      };
    }

    case GET_RE_ENRICH_1_SUCCESS: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
        isEnrichment1Fetching: false,
      };
    }

    case GET_RE_ENRICH_1_FAILED:
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
        isEnrichment1Fetching: false,
      };

    case GET_ENRICH_2_FROM_PEOPLE_SUCCESS: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
        isEnrichment1Fetching: false,
      };
    }

    case GET_ENRICH_2_FROM_PEOPLE_FAILED: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
        isEnrichment1Fetching: false,
      };
    }

    case START_CHECK_UPDATE: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
      };
    }

    case SHOW_CHECK_UPDATE_MATCH_LIST: {
      return {
        ...state,
        showCrmUserMatchList: action.payload.peopleIdx,
        crmUserMatchData: action.payload.data,
      };
    }
    case HIDE_CHECK_UPDATE_MATCH_LIST: {
      return {
        ...state,
        showCrmUserMatchList: -1,
        crmUserMatchData: {},
      };
    }

    case CHECK_UPDATE_SUCCESS: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
      };
    }

    case CHECK_UPDATE_LOADING: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.idx === people.id) {
          return {
            ...people,
            identity: {
              ...people.identity,
              sync: {
                crm1: { cStatus: 'LOADING' },
              },
            },
          };
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
      };
    }

    case CHECK_UPDATE_ERROR: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.idx === people.id) {
          return {
            ...people,
            identity: {
              ...people.identity,
              sync: {
                status: 'ERROR',
              },
            },
          };
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
      };
    }

    case FORCE_UPDATE_SUCCESS: {
      const { peopleEnrichedList } = state;
      const newPeopleEnrichedList = peopleEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        peopleEnrichedList: newPeopleEnrichedList,
      };
    }

    case FORCE_UPDATE_ERROR: {
      return {
        ...state,
        showCrmUserMatchList: -1,
      };
    }

    case START_COMPANY_UPDATE: {
      const { companyEnrichedList } = state;
      const newcompanyEnrichedList = companyEnrichedList.map(people => {
        if (action.payload.id === people.id) {
          return action.payload;
        }
        return people;
      });
      return {
        ...state,
        companyEnrichedList: newcompanyEnrichedList,
      };
    }

    case COMPANY_UPDATE_LOADING: {
      const { companyEnrichedList } = state;
      const newcompanyEnrichedList = companyEnrichedList.map(people => {
        if (action.payload.idx === people.id) {
          return {
            ...people,
            identity: {
              ...people.identity,
              sync: {
                crm1: { cStatus: 'LOADING' },
              },
            },
          };
        }
        return people;
      });
      return {
        ...state,
        companyEnrichedList: newcompanyEnrichedList,
      };
    }

    case COMPANY_UPDATE_SUCCESS: {
      const { companyEnrichedList } = state;
      const newcompanyEnrichedList = companyEnrichedList.map(people => {
        if (action.payload.idx === people.id) {
          return {
            ...people,
            identity: {
              ...people.identity,
              sync: {
                crm1: { cStatus: 'SYNC' },
              },
            },
          };
        }
        return people;
      });
      return {
        ...state,
        companyEnrichedList: newcompanyEnrichedList,
      };
    }

    case COMPANY_UPDATE_ERROR: {
      const { companyEnrichedList } = state;
      const newcompanyEnrichedList = companyEnrichedList.map(people => {
        if (action.payload.idx === people.id) {
          return {
            ...people,
            identity: {
              ...people.identity,
              sync: {
                status: 'ERROR',
              },
            },
          };
        }
        return people;
      });
      return {
        ...state,
        companyEnrichedList: newcompanyEnrichedList,
      };
    }

    default:
      return state;
  }
};
