import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  makeStyles,
  IconButton,
  CircularProgress,
  InputAdornment,
  Button,
} from '@material-ui/core';

import IntlMessages from 'util/IntlMessages';
import { hideMessage, showAuthLoader, forgorPasswordResetEmail } from 'actions/Auth';
import AlertUi from './Msg-UI/AlertUi';
import SuccessUi from './Msg-UI/SuccessUi';
import './authStylings.css';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    minHeight: '80vh',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    backgroundColor: '#ffff',
    margin: '50px',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(196, 196, 196, 0.12)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '60px 60px 40px',
  },
  right: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#fff',
  },
}));

const ForgotPassword = props => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const { loader, alertMessage, showMessage, infoMessage, authUser } = useSelector(
    ({ auth }) => auth
  );

  const emailKeyUP = ({ target: { value } }) => setEmail(value);
  const handleReset = () => {
    dispatch(showAuthLoader());
    dispatch(forgorPasswordResetEmail(email));
  };

  useEffect(() => {
    if (showMessage) {
      if (infoMessage) {
        setTimeout(() => {
          dispatch(hideMessage());
          history.push('/signin');
        }, 3000);
      }
    }
  }, [showMessage]);

  // const hide = () => {
  //   dispatch(hideMessage());
  // };

  return (
    <div className='auth-card'>
      <div className={classes.main}>
        <div className={classes.left + ' animated slideInUpTiny animation-duration-3'}>
          {/* Header */}
          <div style={{ marginBottom: '100px' }}>
            <h1
              style={{
                color: '#222F3C',
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              <IntlMessages id='appModule.forgotPassword' />
            </h1>
            <p style={{ fontSize: '14px' }}>Recover your password to continue and explore within</p>
          </div>
          {/* Form */}
          <div style={{ marginTop: '-200px' }}>
            {showMessage && alertMessage !== '' && (
              <AlertUi variant='error'>{alertMessage}</AlertUi>
            )}
            {showMessage && infoMessage !== '' ? (
              <div>
                <SuccessUi successMsg={infoMessage} path='/signIn' />
                {/* <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={hide}
                >
                  Resend Link
                </span> */}
              </div>
            ) : (
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id='signIn.email' />}
                    fullWidth
                    onChange={emailKeyUP}
                    defaultValue={email}
                    margin='normal'
                    className='mt-1 my-sm-3'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {' '}
                          <img src={require('assets/images/user-icon.svg')} alt='user' />
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                  <div className='my-3 d-flex align-items-center justify-content-between'>
                    <Button
                      onClick={handleReset}
                      variant='contained'
                      color='primary'
                      style={{ textTransform: 'capitalize' }}
                    >
                      {loader ? (
                        <CircularProgress
                          color='inherit'
                          size={'0.875rem'}
                          style={{ margin: '5px 0px' }}
                        />
                      ) : (
                        <IntlMessages id='appModule.resetPassword' />
                      )}
                    </Button>
                  </div>
                  <p>
                    <Link to='/signIn'>
                      <IntlMessages id='appModule.backToLogin' />
                    </Link>
                  </p>
                </fieldset>
              </form>
            )}
          </div>
          {/* Foot */}
          <div>
            <h5>
              <Link to='/signup'>
                <IntlMessages id='signIn.signUp' />
              </Link>
            </h5>
          </div>
        </div>
        <div className={classes.right}>
          {/* <h2
            style={{
              color: "#717171",
              fontSize: "22px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Discover Accounts, Score and Qualify <br /> leads with us
          </h2> */}
          {/* <img width='70%' src={require('assets/images/conveyer-belt.png')} alt='conveyer' /> */}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
