import {
  SHOW_JOBS_MESSAGE,
  SHOW_JOBS_INFO_MESSAGE,
  HIDE_JOBS_MESSAGE,
  STOP_JOB_LOADER,
  PLAYBOOK_DETAILS_EMPTY,
  SUBMIT_PLAYBOOK_EMPTY,
  SUBMIT_PLAYBOOK,
  SUBMIT_PLAYBOOK_SUCCESS,
  SUBMIT_JOBS_SUCCESS,
  SUBMIT_JOBS_SUCCESS_RESET,
  SUBMIT_PEOPLE_JOBS_SUCCESS,
  SUBMIT_COMPANY_JOBS_SUCCESS,
  COMPANY_NAEMS_BATCH_LIST_ADD,
  COMPANY_NAEMS_BATCH_LIST_CLEAR,
  CLEAR_SNP_ALL_DETAILS,
  SAVE_SNP_PLAYBOOKINFO,
  SAVE_SNP_SAVE_JOBID,
  SNP_PLAYBOOK_SUBMIT,
  GET_SNP_JOBS_PUBSUB,
  GET_SNP_T1_JOBS_PUBSUB_SUCCESS,
  GET_SNP_T2_JOBS_PUBSUB_SUCCESS,
  GET_SNP_T1_JOBS_PUBSUB_FAILED,
  GET_SNP_T2_JOBS_PUBSUB_FAILED,
  GET_SNP_JOB_DETAILS_SUCCESS,

  //snc
  CLEAR_SNC_ALL_DETAILS,
  SAVE_SNC_PLAYBOOKINFO,
  SAVE_SNC_SAVE_JOBID,
  SNC_PLAYBOOK_SUBMIT,
  GET_SNC_JOBS_PUBSUB,
  GET_SNC_T1_JOBS_PUBSUB_SUCCESS,
  GET_SNC_T2_JOBS_PUBSUB_SUCCESS,
  GET_SNC_T1_JOBS_PUBSUB_FAILED,
  GET_SNC_T2_JOBS_PUBSUB_FAILED,
  GET_SNC_JOB_DETAILS_SUCCESS,
  GET_ENRICH_1,
  GET_ENRICH_2,
  ENRICH_ADD_JOB_ID_1,
  ENRICH_ADD_JOB_ID_2,
  GET_SNP_JOBS_PUBSUB_ENRICH_1,
  GET_SNP_JOBS_PUBSUB_ENRICH_2,
  GET_SNP_JOBS_PUBSUB_ENRICH_1_SUCCESS,
  GET_SNP_JOBS_PUBSUB_ENRICH_2_SUCCESS,
  SET_ENRICH_PEOPLE_CONTACTS_LIST,
  SET_ENRICH_PEOPLE_CONTACTS_LIST_ITEM,
  SET_ENRICH_COMPANY_CONTACTS_LIST,
  SAVE_TO_CUSTOM_LIST_SUCCESS,
  SALES_NAV_USER_SCRAPE,
  SALES_NAV_COMPANY_SCRAPE,
  SUBMIT_JOB_RESULT,
  GET_JOBS,
  GET_JOBS_SUCCESS,
  GET_JOB_DETAILS_EMPTY,
  GET_JOB_DETAILS_SUCCESS,
  ASSIGN_PLAYBOOK_DETAILS,
  SET_USERINFO_CHECKED_UPDATED_LIST_RESULT,
  SET_COMPANYINFO_CHECKED_UPDATED_LIST_RESULT,
  GET_SNP_LIST,
  GET_SNC_LIST,
  ADD_SNP_LIST,
  ADD_SNC_LIST,
} from 'constants/ActionTypes';

export const displayMessage = message => {
  return {
    type: SHOW_JOBS_MESSAGE,
    payload: message,
  };
};

export const showSuccessMessage = message => {
  return {
    type: SHOW_JOBS_INFO_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_JOBS_MESSAGE,
  };
};

export const stopJobLoader = () => {
  return {
    type: STOP_JOB_LOADER,
  };
};

export const playbookGetDetailsEmpty = () => {
  return {
    type: PLAYBOOK_DETAILS_EMPTY,
  };
};

export const submitPlaybookJobIdEmpty = () => {
  return {
    type: SUBMIT_PLAYBOOK_EMPTY,
  };
};

export const storePlaybookResult = result => {
  return {
    type: ASSIGN_PLAYBOOK_DETAILS,
    payload: result,
  };
};

export const submitPlaybook = (body, pid) => {
  return {
    type: SUBMIT_PLAYBOOK,
    payload: { body, pid },
  };
};

export const submitPlaybookSuccess = jobid => {
  return {
    type: SUBMIT_PLAYBOOK_SUCCESS,
    payload: jobid,
  };
};

export const submitJobResult = (jobid, playbookInput) => {
  return {
    type: SUBMIT_JOB_RESULT,
    payload: { jobid, playbookInput },
  };
};

export const submitPeoplesJobSuccess = searchDeatils => {
  return {
    type: SUBMIT_PEOPLE_JOBS_SUCCESS,
    payload: searchDeatils,
  };
};

export const submitCompanyJobSuccess = searchDeatils => {
  return {
    type: SUBMIT_COMPANY_JOBS_SUCCESS,
    payload: searchDeatils,
  };
};

export const companyNamesBatchListAdd = companiesList => {
  return {
    type: COMPANY_NAEMS_BATCH_LIST_ADD,
    payload: companiesList,
  };
};

export const companyNamesBatchListClear = () => {
  return {
    type: COMPANY_NAEMS_BATCH_LIST_CLEAR,
  };
};

export const getJobsSuccess = result => {
  return {
    type: GET_JOBS_SUCCESS,
    payload: result,
  };
};

export const getJobDetailsEmpty = jid => {
  return {
    type: GET_JOB_DETAILS_EMPTY,
  };
};

export const getJobDetailsSuccess = data => {
  return {
    type: GET_JOB_DETAILS_SUCCESS,
    payload: data,
  };
};

/**SALES NAV PEOPLE SCRAPER */
/**API */

export const clearSNPeopleTaskResult = () => {
  return {
    type: CLEAR_SNP_ALL_DETAILS,
  };
};

export const saveSNPeoplePlaybookInfo = result => {
  return {
    type: SAVE_SNP_PLAYBOOKINFO,
    payload: result,
  };
};

/**API */

export const submitSNPeoplePlaybookSaveJobId = jobid => {
  return {
    type: SAVE_SNP_SAVE_JOBID,
    payload: jobid,
  };
};

/**API */
/* export const snPeopleScrapePlaybookSubmit = (jobid, playbookInput) => {
  return {
    type: SNP_PLAYBOOK_SUBMIT,
    payload: { jobid, playbookInput }
  };
};
 */

export const getSNPJobsPubSub = (jobId, pageNumber, searchType) => {
  return {
    type: GET_SNP_JOBS_PUBSUB,
    payload: { jobId, pageNumber, searchType },
  };
};

export const getSNPT1JobsPubSubSuccess = result => {
  return {
    type: GET_SNP_T1_JOBS_PUBSUB_SUCCESS,
    payload: result,
  };
};

export const getSNPT2JobsPubSubSuccess = result => {
  return {
    type: GET_SNP_T2_JOBS_PUBSUB_SUCCESS,
    payload: result,
  };
};

export const getSNPT1JobsPubSubFailed = message => {
  return {
    type: GET_SNP_T1_JOBS_PUBSUB_FAILED,
    payload: message,
  };
};

export const getSNPT2JobsPubSubFailed = data => {
  return {
    type: GET_SNP_T2_JOBS_PUBSUB_FAILED,
    payload: data,
  };
};

export const getsnpJobDetailsSuccess = data => {
  return {
    type: GET_SNP_JOB_DETAILS_SUCCESS,
    payload: data,
  };
};

/**SALES NAV COMPANY SCRAPER */
/**API */

export const clearSNCompanyTaskResult = () => {
  return {
    type: CLEAR_SNC_ALL_DETAILS,
  };
};

export const saveSNCompanyPlaybookInfo = result => {
  return {
    type: SAVE_SNC_PLAYBOOKINFO,
    payload: result,
  };
};

/**API */

export const submitSNCompanyPlaybookSaveJobId = jobid => {
  return {
    type: SAVE_SNC_SAVE_JOBID,
    payload: jobid,
  };
};

/**API */
/* export const snCompanyScrapePlaybookSubmit = (jobid, playbookInput) => {
  return {
    type: SNC_PLAYBOOK_SUBMIT,
    payload: { jobid, playbookInput }
  };
}; */

export const getSNCJobsPubSub = (jobId, pageNumber, searchType) => {
  return {
    type: GET_SNC_JOBS_PUBSUB,
    payload: { jobId, pageNumber, searchType },
  };
};

export const getSNCT1JobsPubSubSuccess = result => {
  return {
    type: GET_SNC_T1_JOBS_PUBSUB_SUCCESS,
    payload: result,
  };
};

export const getSNCT2JobsPubSubSuccess = result => {
  return {
    type: GET_SNC_T2_JOBS_PUBSUB_SUCCESS,
    payload: result,
  };
};

export const getSNCT1JobsPubSubFailed = message => {
  return {
    type: GET_SNC_T1_JOBS_PUBSUB_FAILED,
    payload: message,
  };
};

export const getSNCT2JobsPubSubFailed = data => {
  return {
    type: GET_SNC_T2_JOBS_PUBSUB_FAILED,
    payload: data,
  };
};

export const getsncJobDetailsSuccess = data => {
  return {
    type: GET_SNC_JOB_DETAILS_SUCCESS,
    payload: data,
  };
};

export const salesNavUserScrapeSubmit = playbookInput => {
  return {
    type: SALES_NAV_USER_SCRAPE,
    payload: playbookInput,
  };
};

export const salesNavCompanyScrapeSubmit = playbookInput => {
  return {
    type: SALES_NAV_COMPANY_SCRAPE,
    payload: playbookInput,
  };
};

export const setUserJobsSelectUpdated = data => {
  return {
    type: SET_USERINFO_CHECKED_UPDATED_LIST_RESULT,
    payload: data,
  };
};

export const setCompanyJobsSelectUpdated = data => {
  return {
    type: SET_COMPANYINFO_CHECKED_UPDATED_LIST_RESULT,
    payload: data,
  };
};

/**Enrichment */
export const getEnrich1ResultFromAPI = jobs => {
  return {
    type: GET_ENRICH_1,
    payload: jobs,
  };
};

export const getEnrich2ResultFromAPI = jobs => {
  return {
    type: GET_ENRICH_2,
    payload: jobs,
  };
};

export const enrich1AddJobIds = mapToData => {
  return {
    type: ENRICH_ADD_JOB_ID_1,
    payload: { mapToData },
  };
};

export const enrich2AddJobIds = mapToData => {
  return {
    type: ENRICH_ADD_JOB_ID_2,
    payload: { mapToData },
  };
};

export const getSNPListAPI = payload => {
  return {
    type: GET_SNP_LIST,
    payload: { payload },
  };
};

export const getSNCListAPI = payload => {
  return {
    type: GET_SNC_LIST,
    payload: { payload },
  };
};

export const addSNPListResponse = payload => {
  return {
    type: ADD_SNP_LIST,
    payload: { payload },
  };
};

export const addSNCListResponse = payload => {
  return {
    type: ADD_SNC_LIST,
    payload: { payload },
  };
};

export const getEnrich1ResultPubSubFromAPI = jobIds => {
  return {
    type: GET_SNP_JOBS_PUBSUB_ENRICH_1,
    payload: jobIds,
  };
};

export const getEnrich2ResultPubSubFromAPI = jobIds => {
  return {
    type: GET_SNP_JOBS_PUBSUB_ENRICH_2,
    payload: jobIds,
  };
};

export const getSNPJobsPubSubEnrich1Success = data => {
  return {
    type: GET_SNP_JOBS_PUBSUB_ENRICH_1_SUCCESS,
    payload: data,
  };
};

export const getSNPJobsPubSubEnrich2Success = data => {
  return {
    type: GET_SNP_JOBS_PUBSUB_ENRICH_2_SUCCESS,
    payload: data,
  };
};

export const set_enrich_people_item = data => {
  return {
    type: SET_ENRICH_PEOPLE_CONTACTS_LIST_ITEM,
    payload: data,
  };
};

export const set_enrich_people_list = data => {
  return {
    type: SET_ENRICH_PEOPLE_CONTACTS_LIST,
    payload: data,
  };
};

export const set_enrich_company_list = data => {
  return {
    type: SET_ENRICH_COMPANY_CONTACTS_LIST,
    payload: data,
  };
};

export const saveToCompanyListNameSuccess = data => {
  return {
    type: SAVE_TO_CUSTOM_LIST_SUCCESS,
    payload: data,
  };
};
