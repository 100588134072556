import React, { useState, useEffect, useReducer } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Modal,
  Select,
  MenuItem,
  InputBase,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { getSearchTypeFromInput, maxAllowedChips, maxAllowedTechnologyChips } from '../../util';
import IconButton from '../IconButton';
import InputField from 'components/Input';
import TagsView from 'components/TagsView';
import PopupSearch from './PopupSearch';
import { companySize } from '../../util/dropdownData';

// dropdown input box styles
const BootstrapInput = withStyles(theme => ({
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'blue',
    },
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  root: {
    flexGrow: 1,
  },
  searchBtn: {
    width: '100%',
    padding: '18px',
    borderRadius: '5px',
  },
  fields: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '14px',
    color: '#484848',
    fontWeight: '400',
  },
  bottomBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'end',
    padding: '15px',
    borderTop: '1px solid #ebebeb',
  },
  container: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
    height: '80vh',
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px 15px',
    width: '324px',
    height: '49px',
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    borderRadius: '5px',
    outline: 'none',
  },
  formWrapper: {
    '& .tweak_cardsBtns': {
      margin: '8px 0 20px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      '& > button': {
        fontSize: '14px',
        border: '1px solid #222F3C',
        borderRadius: '5px',
        background: 'transparent',
        padding: '5px 14px',
        fontWeight: 400,
      },
      '& .activeBtn': {
        backgroundColor: '#172EEC',
        color: '#fff',
        borderColor: '#172EEC',
      },
    },
    '& .subHead': {
      marginBottom: '16px',

      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#717171',
    },
    '& .inputLabelText': {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '17px',
      color: '#717171',
      marginBottom: '5px',
    },
    '& .IB-all': {
      '& h5': {
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '16px',
        lineHeight: '19px',
        color: '#222F3C',
      },
      padding: '15px',
      border: 'solid 0.5px #B4B4B4',
      borderRadius: '5px',
      marginBottom: '21px',
    },
    padding: '15px',
    maxHeight: '75vh',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      height: '77px',
      background: '#DDDDDD',
      opacity: 0.5,
      border: '0.5px solid #717171',
      borderRadius: '10px',
    },
  },
  modal: {
    display: 'grid',
    placeItems: 'center',
  },
  paper: {
    width: '50vh',
    padding: '20px',
  },
  formControl: {
    width: '100%',
    // margin: theme.spacing(1, 0),
    '& > div': {
      padding: 0,
    },
    '& > select:focus': {
      borderRadius: theme.shape.borderRadius,
      '&::after': {
        width: '0',
        height: '0',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: '6px solid #f00',
        position: 'absolute',
        top: '40%',
        right: '5px',
        content: '""',
        zIndex: 98,
      },
    },
    '& input': {
      // margin: '10px 0',
      '&::placeholder': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '19px',
        color: '#A6AAAF',
      },
      '&:focus': {
        borderColor: 'blue',
      },
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const countryList = [
  'United States',
  'United Kingdom',
  'Australia',
  'Germany',
  'Canada',
  'Netherlands',
  'Singapore',
  'United Arab Emirates',
  'France',
  'India',
];

const cityList = [
  'San Francisco',
  'New York',
  'Southern California',
  'Boston',
  'London',
  'Seattle',
  'Portland',
  'Vancouver',
  'Dallas',
  'Houston',
  'San Antonio',
  'Tel Aviv',
  'Washington DC',
  'Chicago',
  'Denver',
  'Toronto',
  'Atlanta',
  'Paris',
  'Amsterdam',
  'Philadelphia',
  'Bangalore',
  'Madrid',
  'Berlin',
  'Dublin',
  'New Delhi',
  'Sydney',
  'Singapore',
  'Miami',
  'Barcelona',
];

const nicheList = [
  { displayText: 'SaaS', value: '"Saas"' },
  { displayText: 'Startup', value: '"series .. a|b|c|d"' },
  { displayText: 'Fast growing startup', value: '"Fast growing" "Startup"' },
  { displayText: 'Fast growing SaaS', value: '"Fast growing" "saas"' },
  { displayText: 'Non startup', value: '-"series .. a|b|c|d"' },
];

const TagsInput = ({ searchSubmit, searchType, loading, setContextSearch, setGeoForward }) => {
  // const initialState = fillForm(previousInput);

  const initialState = {
    technology: {
      title: 'technology',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedTechnologyChips },
    },
    keywords: {
      title: 'keywords',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedChips },
    },
    industry: {
      title: 'industries',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedChips },
    },
    city: {
      title: 'city',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    state: {
      title: 'state',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    country: {
      title: 'country',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    revenue: {
      title: 'revenue details',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedChips },
    },
    companySize: {
      title: 'company sizes',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    // "growthSignal": { value: "", required: false, atleastOne : "T1"},
    // "developerInput": { value: "", required: false,  atleastOne : "T1" },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [companyNameEnable, setcompanyNameEnable] = useState(true);
  const [restFormEnable, setrestFormEnable] = useState(true);
  const [sizeEnable, setSizeEnable] = useState(true);
  const [revenueEnable, setRevenueEnable] = useState(true);
  const [formValidationMessage, setFormValidationMessage] = useState('');
  const [contextSpec, setContextSpec] = useState('');

  // * Modal on input click
  const [modal, setModal] = useState(false);

  const [searchInputJson, setSearchInputJson] = useState({
    seniority: '',
    department: '',
    title: '',
    since: '',
    pTopic: [],
    sTopic: [],
    tStack: [],
    sdt: [],
    geo: '',
    size: '',
    industry: '',
    pageNum: '',
    cLabel: [],
    cRoot: [],
    nameVc: [],
  });

  const [keywordState, setKeywordState] = useState({
    IB1_topic: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB1_geo: { value: '', arr: [], maxAllowed: 1 },
    IB1_niche: { value: '', arr: [], maxAllowed: 1 },
    IB1_tech: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB2_title: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB2_topic: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB2_tech: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB2_geo: { value: '', arr: [], maxAllowed: 1 },
    IB3_dep: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB3_sen: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB3_geo: { value: '', arr: [], maxAllowed: 1 },
    IB3_title: { value: '', arr: [], maxAllowed: 5 },
    IB3_topic: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB3_tech: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB4_dep: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB4_sen: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB4_title: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB4_topic: { value: '', arr: [], maxAllowed: maxAllowedChips },
    IB4_geo: { value: '', arr: [], maxAllowed: 1 },
    IB5_cLabel: { value: '', arr: [], maxAllowed: 1 },
    IB5_size: { value: '', arr: [], maxAllowed: 1 },
    IB5_geo: { value: '', arr: [], maxAllowed: 1 },
    IB6_intent: { value: '', arr: [], maxAllowed: 5 },
    // IB6_size: { value: '', arr: [] },
    IB7_intent: { value: '', arr: [], maxAllowed: 5 },
    // IB7_size: { value: '', arr: [] },
    IB8_cLabel: { value: '', arr: [], maxAllowed: 1 },
    IB8_size: { value: '', arr: [], maxAllowed: 1 },
  });

  const [cardFilledState, setCardFilledState] = useState('');
  const [companyLabelArrState, setCompanyLabelArrState] = useState([]);
  const [isRevOrSize, setIsRevOrSize] = useState('');
  const [showTotalMarket, setShowTotalMarket] = useState(false);

  const cardFilled = function() {
    for (let key in keywordState) {
      if (keywordState[key].arr.length || keywordState[key].value) {
        return key;
      }
    }
    return '';
  };

  const clearOthersCards = name => {
    for (const key in keywordState) {
      if (key.substring(0, 3) !== name.substring(0, 3)) {
        keywordState[key].value = '';
        keywordState[key].arr = [];
      }
    }
  };

  useEffect(() => {
    setCardFilledState(cardFilled());
    setGeoForward(keywordState['IB1_geo'].value);
  }, [keywordState]);

  // ? Keyword Handlers

  const handleKeywordChange = ({ target: { name, value } }) => {
    clearOthersCards(name);
    dispatch({ type: 'addInput', payload: { name: 'keywords', value } });
    switch (name) {
      case 'IB1_topic':
        setKeywordState({
          ...keywordState,
          IB1_topic: { ...keywordState.IB1_topic, value: value },
        });
        break;
      case 'IB1_tech':
        setKeywordState({
          ...keywordState,
          IB1_tech: { ...keywordState.IB1_tech, value: value },
        });
        break;
      case 'IB2_title':
        setKeywordState({
          ...keywordState,
          IB2_title: { ...keywordState.IB2_title, value: value },
        });
        break;
      case 'IB2_topic':
        setKeywordState({
          ...keywordState,
          IB2_topic: { ...keywordState.IB2_topic, value: value },
        });
        break;
      case 'IB3_dep':
        setKeywordState({
          ...keywordState,
          IB3_dep: { ...keywordState.IB3_dep, value: value },
        });
        break;
      case 'IB3_sen':
        setKeywordState({
          ...keywordState,
          IB3_sen: { ...keywordState.IB3_sen, value: value },
        });
        break;
      case 'IB3_topic':
        setKeywordState({
          ...keywordState,
          IB3_topic: { ...keywordState.IB3_topic, value: value },
        });
        break;
      case 'IB3_title':
        setKeywordState({
          ...keywordState,
          IB3_title: { ...keywordState.IB3_title, value: value },
        });
        break;
      case 'IB4_sen':
        setKeywordState({
          ...keywordState,
          IB4_sen: { ...keywordState.IB4_sen, value: value },
        });
        break;
      case 'IB4_dep':
        setKeywordState({
          ...keywordState,
          IB4_dep: { ...keywordState.IB4_dep, value: value },
        });
        break;
      case 'IB4_title':
        setKeywordState({
          ...keywordState,
          IB4_title: { ...keywordState.IB4_title, value: value },
        });
        break;
      case 'IB4_topic':
        setKeywordState({
          ...keywordState,
          IB4_topic: { ...keywordState.IB4_topic, value: value },
        });
        break;
      case 'IB5_cLabel':
        setKeywordState({
          ...keywordState,
          IB5_cLabel: { ...keywordState.IB5_cLabel, value: value },
        });
        break;
      case 'IB6_intent':
        setKeywordState({
          ...keywordState,
          IB6_intent: { ...keywordState.IB6_intent, value: value },
        });
        break;
      case 'IB7_intent':
        setKeywordState({
          ...keywordState,
          IB7_intent: { ...keywordState.IB7_intent, value: value },
        });
        break;
      case 'IB8_cLabel':
        setKeywordState({
          ...keywordState,
          IB8_cLabel: { ...keywordState.IB8_cLabel, value: value },
        });
        break;
    }
  };

  const addKeywordChip = ({ key, target: { name, value } }) => {
    if (key === 'Enter' && value != '') {
      const stateItem = { ...keywordState[name] };

      const maxChipsAllowed = stateItem.maxAllowed;
      const nexChipKey = stateItem.arr.length + 1;

      const notDuplicated = stateItem.arr.find(item => item == value);
      if (nexChipKey <= maxChipsAllowed && !notDuplicated) {
        // const cData = stateItem.chip.chipData;
        // cData.push({
        //   key: nexChipKey,
        //   name: value,
        // });

        clearOthersCards(name);
        // ! Update State for IB1_topic || IB1_tech || IB2_title arrayis
        // ! AND
        // ! Clear input field for IB1_topic || IB1_tech || IB2_title
        switch (name) {
          case 'IB1_topic':
            setKeywordState({
              ...keywordState,
              IB1_topic: {
                ...keywordState.IB1_topic,
                value: '',
                arr: [...keywordState.IB1_topic.arr, value],
              },
            });
            break;
          case 'IB1_tech':
            setKeywordState({
              ...keywordState,
              IB1_tech: {
                ...keywordState.IB1_tech,
                value: '',
                arr: [...keywordState.IB1_tech.arr, value],
              },
            });
            break;
          case 'IB2_title':
            setKeywordState({
              ...keywordState,
              IB2_title: {
                ...keywordState.IB2_title,
                value: '',
                arr: [...keywordState.IB2_title.arr, value],
              },
            });
            break;
          case 'IB2_topic':
            setKeywordState({
              ...keywordState,
              IB2_topic: {
                ...keywordState.IB2_topic,
                value: '',
                arr: [...keywordState.IB2_topic.arr, value],
              },
            });
            break;
          case 'IB2_tech':
            setKeywordState({
              ...keywordState,
              IB2_tech: {
                ...keywordState.IB2_tech,
                value: '',
                arr: [...keywordState.IB2_tech.arr, value],
              },
            });
            break;
          case 'IB3_dep':
            setKeywordState({
              ...keywordState,
              IB3_dep: {
                ...keywordState.IB3_dep,
                value: '',
                arr: [...keywordState.IB3_dep.arr, value],
              },
            });
            break;
          case 'IB3_sen':
            setKeywordState({
              ...keywordState,
              IB3_sen: {
                ...keywordState.IB3_sen,
                value: '',
                arr: [...keywordState.IB3_sen.arr, value],
              },
            });
            break;
          case 'IB3_topic':
            setKeywordState({
              ...keywordState,
              IB3_topic: {
                ...keywordState.IB3_topic,
                value: '',
                arr: [...keywordState.IB3_topic.arr, value],
              },
            });
            break;
          case 'IB3_title':
            setKeywordState({
              ...keywordState,
              IB3_title: {
                ...keywordState.IB3_title,
                value: '',
                arr: [...keywordState.IB3_title.arr, value],
              },
            });
            break;
          case 'IB3_tech':
            setKeywordState({
              ...keywordState,
              IB3_tech: {
                ...keywordState.IB3_tech,
                value: '',
                arr: [...keywordState.IB3_tech.arr, value],
              },
            });
            break;
          case 'IB4_sen':
            setKeywordState({
              ...keywordState,
              IB4_sen: {
                ...keywordState.IB4_sen,
                value: '',
                arr: [...keywordState.IB4_sen.arr, value],
              },
            });
            break;
          case 'IB4_dep':
            setKeywordState({
              ...keywordState,
              IB4_dep: {
                ...keywordState.IB4_dep,
                value: '',
                arr: [...keywordState.IB4_dep.arr, value],
              },
            });
            break;
          case 'IB4_title':
            setKeywordState({
              ...keywordState,
              IB4_title: {
                ...keywordState.IB4_title,
                value: '',
                arr: [...keywordState.IB4_title.arr, value],
              },
            });
            break;
          case 'IB4_topic':
            setKeywordState({
              ...keywordState,
              IB4_topic: {
                ...keywordState.IB4_topic,
                value: '',
                arr: [...keywordState.IB4_topic.arr, value],
              },
            });
            break;
          case 'IB5_cLabel':
            setKeywordState({
              ...keywordState,
              IB5_cLabel: {
                ...keywordState.IB5_cLabel,
                value: '',
                arr: [...keywordState.IB5_cLabel.arr, value],
              },
            });
            break;
          case 'IB6_intent':
            setKeywordState({
              ...keywordState,
              IB6_intent: {
                ...keywordState.IB6_intent,
                value: '',
                arr: [...keywordState.IB6_intent.arr, value],
              },
            });
            break;
          case 'IB7_intent':
            setKeywordState({
              ...keywordState,
              IB7_intent: {
                ...keywordState.IB7_intent,
                value: '',
                arr: [...keywordState.IB7_intent.arr, value],
              },
            });
            break;
          case 'IB8_cLabel':
            setKeywordState({
              ...keywordState,
              IB8_cLabel: {
                ...keywordState.IB8_cLabel,
                value: '',
                arr: [...keywordState.IB8_cLabel.arr, value],
              },
            });
            break;
        }
        // ? Central State Update
        // dispatch({
        //   type: 'updateChip',
        //   payload: {
        //     name: 'keywords',
        //     cData,
        //   },
        // });
        // ? Central State input clear
        // dispatch({
        //   type: 'addInput',
        //   payload: {
        //     name: 'keywords',
        //     value: '',
        //   },
        // });
      } else if (nexChipKey > maxChipsAllowed)
        // showValidationMessage("Not allowed more than " + maxChipsAllowed);
        setFormValidationMessage(
          'For optimal result search up to ' + maxChipsAllowed + ' ' + 'keywords in one go'
        );
      else if (notDuplicated)
        // showValidationMessage("Duplicates are allowed");
        setFormValidationMessage('Duplicates are not allowed');
    }
  };

  const addKeywordChipArray = ({ name, value = [] }) => {
    if (value.length) {
      const stateItem = { ...keywordState[name] };
      const maxChipsAllowed = stateItem.maxAllowed;
      const nexChipKey = stateItem.arr.length + 1;
      const allowedChips = stateItem.maxAllowed - stateItem.arr.length;
      let notDuplicated = false;
      value.forEach(v => {
        if (stateItem.arr.find(item => item === v)) notDuplicated = true;
      });
      console.log(allowedChips, maxAllowedChips, nexChipKey, value);
      if (value.length) {
        // console.log(value);
        // const cData = stateItem.chip.chipData;
        // value.forEach(v => {
        //   cData.push({
        //     key: nexChipKey,
        //     name: v,
        //   });
        // });

        clearOthersCards(name);
        // ! Update State for IB1_topic || IB1_tech || IB2_title arrayis
        // ! AND
        // ! Clear input field for IB1_topic || IB1_tech || IB2_title
        switch (name) {
          case 'IB1_topic':
            setKeywordState({
              ...keywordState,
              IB1_topic: {
                ...keywordState.IB1_topic,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB1_tech':
            setKeywordState({
              ...keywordState,
              IB1_tech: {
                ...keywordState.IB1_tech,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB2_title':
            setKeywordState({
              ...keywordState,
              IB2_title: {
                ...keywordState.IB2_title,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB2_topic':
            setKeywordState({
              ...keywordState,
              IB2_topic: {
                ...keywordState.IB2_topic,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB2_tech':
            setKeywordState({
              ...keywordState,
              IB2_tech: {
                ...keywordState.IB2_tech,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB3_dep':
            setKeywordState({
              ...keywordState,
              IB3_dep: {
                ...keywordState.IB3_dep,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB3_sen':
            setKeywordState({
              ...keywordState,
              IB3_sen: {
                ...keywordState.IB3_sen,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB3_topic':
            setKeywordState({
              ...keywordState,
              IB3_topic: {
                ...keywordState.IB3_topic,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB4_sen':
            setKeywordState({
              ...keywordState,
              IB4_sen: {
                ...keywordState.IB4_sen,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB3_tech':
            setKeywordState({
              ...keywordState,
              IB3_tech: {
                ...keywordState.IB3_tech,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB4_dep':
            setKeywordState({
              ...keywordState,
              IB4_dep: {
                ...keywordState.IB4_dep,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB4_topic':
            setKeywordState({
              ...keywordState,
              IB4_topic: {
                ...keywordState.IB4_topic,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB5_cLabel':
            setKeywordState({
              ...keywordState,
              IB5_cLabel: {
                ...keywordState.IB5_cLabel,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB6_intent':
            setKeywordState({
              ...keywordState,
              IB6_intent: {
                ...keywordState.IB6_intent,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB7_intent':
            setKeywordState({
              ...keywordState,
              IB7_intent: {
                ...keywordState.IB7_intent,
                value: '',
                arr: [...value],
              },
            });
            break;
          case 'IB8_cLabel':
            setKeywordState({
              ...keywordState,
              IB8_cLabel: {
                ...keywordState.IB8_cLabel,
                value: '',
                arr: [...value],
              },
            });
            break;
        }
        // ? Central State Update
        // dispatch({
        //   type: 'updateChip',
        //   payload: {
        //     name: 'keywords',
        //     cData,
        //   },
        // });
        // // ? Central State input clear
        // dispatch({
        //   type: 'addInput',
        //   payload: {
        //     name: 'keywords',
        //     value: '',
        //   },
        // });
      } else if (value.length > allowedChips) {
      }
      // showValidationMessage("Not allowed more than " + maxChipsAllowed);
      // setFormValidationMessage(
      //   'For optimal result search up to ' +
      //     maxChipsAllowed +
      //     ' ' +
      //     stateItem.title +
      //     ' in one go'
      // );
      else if (notDuplicated) {
      }
      // showValidationMessage("Duplicates are allowed");
      // setFormValidationMessage('Duplicates are not allowed');
    }
  };

  const deleteKeywordChip = (name, data) => {
    const jsons = searchInputJson;

    let inputKeysArr = Object.keys(jsons);

    inputKeysArr.forEach(key => {
      if (key === 'pTopic') {
        if (jsons[key].length > 0) {
          const dl = jsons.pTopic.filter(f => f !== data);
          jsons.pTopic = dl;
        }
      }

      if (key === 'sTopic') {
        if (jsons[key].length > 0) {
          const dl = jsons.pTopic.filter(f => f !== data);
          jsons.sTopic = dl;
        }
      }

      if (key === 'tStack') {
        if (jsons[key].length > 0) {
          const dl = jsons.pTopic.filter(f => f !== data);
          jsons.tStack = dl;
        }
      }
    });

    setSearchInputJson(jsons);

    // * Central State
    // const cData = [...state['keywords'].chip.chipData];
    // const chipToDelete = cData.indexOf(data);
    // cData.splice(chipToDelete, 1);
    // * Keyword State
    switch (name) {
      case 'IB1_topic':
        let arrIB1_topicFiltered = keywordState.IB1_topic.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB1_topic: {
            ...keywordState.IB1_topic,
            arr: [...arrIB1_topicFiltered],
          },
        });
        break;
      case 'IB1_tech':
        let arrIB1_techFiltered = keywordState.IB1_tech.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB1_tech: {
            ...keywordState.IB1_tech,
            arr: [...arrIB1_techFiltered],
          },
        });
        break;
      case 'IB2_title':
        let arrIB2_titleFiltered = keywordState.IB2_title.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB2_title: {
            ...keywordState.IB2_title,
            arr: [...arrIB2_titleFiltered],
          },
        });
        break;
      case 'IB2_topic':
        let arrIB2_topicFiltered = keywordState.IB2_topic.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB2_topic: {
            ...keywordState.IB2_topic,
            arr: [...arrIB2_topicFiltered],
          },
        });
        break;
      case 'IB2_tech':
        let arrIB2_techFiltered = keywordState.IB2_tech.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB2_tech: {
            ...keywordState.IB2_tech,
            arr: [...arrIB2_techFiltered],
          },
        });
        break;
      case 'IB3_topic':
        let arrIB3_topicFiltered = keywordState.IB3_topic.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB3_topic: {
            ...keywordState.IB3_topic,
            arr: [...arrIB3_topicFiltered],
          },
        });
        break;
      case 'IB3_sen':
        let arrIB3_senFiltered = keywordState.IB3_sen.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB3_sen: {
            ...keywordState.IB3_sen,
            arr: [...arrIB3_senFiltered],
          },
        });
        break;
      case 'IB3_dep':
        let arrIB3_depFiltered = keywordState.IB3_dep.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB3_dep: {
            ...keywordState.IB3_dep,
            arr: [...arrIB3_depFiltered],
          },
        });
        break;
      case 'IB3_title':
        let arrIB3_titleFiltered = keywordState.IB3_title.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB3_title: {
            ...keywordState.IB3_title,
            arr: [...arrIB3_titleFiltered],
          },
        });
        break;
      case 'IB3_tech':
        let arrIB3_techFiltered = keywordState.IB3_tech.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB3_tech: {
            ...keywordState.IB3_tech,
            arr: [...arrIB3_techFiltered],
          },
        });
        break;
      case 'IB4_sen':
        let arrIB4_senFiltered = keywordState.IB4_sen.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB4_sen: {
            ...keywordState.IB4_sen,
            arr: [...arrIB4_senFiltered],
          },
        });
        break;
      case 'IB4_dep':
        let arrIB4_depFiltered = keywordState.IB4_dep.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB4_dep: {
            ...keywordState.IB4_dep,
            arr: [...arrIB4_depFiltered],
          },
        });
        break;
      case 'IB4_title':
        let arrIB4_titleFiltered = keywordState.IB4_title.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB4_title: {
            ...keywordState.IB4_title,
            arr: [...arrIB4_titleFiltered],
          },
        });
        break;
      case 'IB4_topic':
        let arrIB4_topicFiltered = keywordState.IB4_topic.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB4_topic: {
            ...keywordState.IB4_topic,
            arr: [...arrIB4_topicFiltered],
          },
        });
        break;
      case 'IB5_cLabel':
        let arrIB5_cLabelFiltered = keywordState.IB5_cLabel.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB5_cLabel: {
            ...keywordState.IB5_cLabel,
            arr: [...arrIB5_cLabelFiltered],
          },
        });
        break;
      case 'IB6_intent':
        let arrIB6_intent = keywordState.IB6_intent.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB6_intent: {
            ...keywordState.IB6_intent,
            arr: [...arrIB6_intent],
          },
        });
        break;
      case 'IB7_intent':
        let arrIB7_intent = keywordState.IB7_intent.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB7_intent: {
            ...keywordState.IB7_intent,
            arr: [...arrIB7_intent],
          },
        });
        break;
      case 'IB8_cLabel':
        let arrIB8_cLabelFiltered = keywordState.IB8_cLabel.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          IB8_cLabel: {
            ...keywordState.IB8_cLabel,
            arr: [...arrIB8_cLabelFiltered],
          },
        });
        break;
    }
    // console.log(keywordState);
    // dispatch({ type: 'updateChip', payload: { name: 'keywords', cData } });
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      case 'updateChip':
        let item = { ...state[action.payload.name] };
        item.chip = { ...item.chip };
        item.chip.chipData = [...action.payload.cData];

        return {
          ...state,
          [action.payload.name]: item,
        };

        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            chip: { ...item },
          },
        };
      default:
        throw new Error();
    }
  }

  //use reducer changes
  useEffect(() => {
    setFormValidationMessage('');

    Object.keys(state).forEach(item => {
      if (
        state[item].value != '' ||
        (state[item].hasOwnProperty('chip') && state[item].chip.hasOwnProperty('chipData'))
      ) {
        switch (item) {
          case 'companyName':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setrestFormEnable(false);
            } else {
              setrestFormEnable(true);
            }
            break;

          case 'industry':
          case 'keywords':
          case 'location':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setcompanyNameEnable(false);
            } else {
              setcompanyNameEnable(true);
            }

            break;
          case 'companySize':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setcompanyNameEnable(false);
              setRevenueEnable(false);
            } else {
              setcompanyNameEnable(true);
              setRevenueEnable(true);
            }

            break;

          case 'revenue':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setcompanyNameEnable(false);
              setSizeEnable(false);
            } else if (state['companySize'].value == '' && state[item].chip.chipData.length == 0) {
              setcompanyNameEnable(true);
              setSizeEnable(true);
            }
            break;

          default:
            break;
        }
      }
    });
  }, [state]);

  const addChip = (name, value) => {
    const stateItem = { ...keywordState[name] };
    const maxChipsAllowed = stateItem.maxAllowed;
    const nexChipKey = stateItem.arr.length + 1;
    const notDuplicated = stateItem.arr.find(item => item == value);
    if (nexChipKey <= maxChipsAllowed && !notDuplicated) {
      const cData = keywordState[name].arr;
      cData.push(value);
      let vals = '';
      if (name.includes('niche') || name.includes('geo')) {
        vals = value;
      } else {
        vals = '';
      }
      setKeywordState({
        ...keywordState,
        [name]: {
          ...keywordState[name],
          value: vals,
          arr: cData,
        },
      });
    } else if (nexChipKey > maxChipsAllowed)
      // showValidationMessage("Not allowed more than " + maxChipsAllowed);
      setFormValidationMessage(
        'For optimal result search up to ' + maxChipsAllowed + ' ' + stateItem + ' in one go'
      );
    else if (notDuplicated)
      // showValidationMessage("Duplicates are allowed");
      setFormValidationMessage('Duplicates are not allowed');
  };

  const handleSearchSubmit = () => {
    // if just typed but not added as a chip
    Object.keys(keywordState).forEach(item => {
      if (
        keywordState[item].value !== '' &&
        keywordState[item].hasOwnProperty('arr') &&
        keywordState[item].arr.length === 0
      )
        addChip(item, keywordState[item].value);
    });
    let inputJson = {};
    const searchState = Object.keys(keywordState);
    const jsons = searchInputJson;

    searchState.forEach(value => {
      // Handles department, seniority, title values

      const keys = ['sen', 'dep', 'title'];
      for (const key of keys) {
        if (!value.includes(key)) continue;

        const { arr: IBArray } = keywordState[value];
        if (!IBArray.length) continue;

        if (value === 'IB2_title' || value === 'IB3_title' || value === 'IB4_title') {
          let IBArray = keywordState[value].arr;
          if (IBArray.length > 0) {
            jsons.title = IBArray;
            jsons.department = [];
            jsons.seniority = [];
          }
        }else {
          if (value.includes('dep')) {
            let IBArray = keywordState[value].arr;
            if (IBArray.length > 0) {
              jsons.department = IBArray[0];
            }
          }

          if (value.includes('sen')) {
            let IBArray = keywordState[value].arr;
            if (IBArray.length > 0) {
              jsons.seniority = IBArray[0];
            }
          }
        }
      }

      if (value.includes('topic')) {
        let IBArray = keywordState[value].arr;
        if (IBArray.length > 0) {
          jsons.pTopic = IBArray;
        }
      }
      if (value.includes('tech')) {
        let IBArray = keywordState[value].arr;
        if (IBArray.length > 0) {
          jsons.tStack.push(IBArray[0]);
        }
      }
      if (value.includes('cLabel')) {
        let IBArray = keywordState[value].arr;
        if (IBArray.length > 0) {
          jsons.cLabel = companyLabelArrState;
        }
      }
      if (value.includes('IB6_intent')) {
        let IBArray = keywordState[value].arr;
        if (IBArray.length > 0) {
          jsons.cRoot = IBArray;
        }
      }
      if (value.includes('IB7_intent')) {
        let IBArray = keywordState[value].arr;
        if (IBArray.length > 0) {
          jsons.nameVc = IBArray;
        }
      }
      if (value.includes('geo')) {
        let IBArray = keywordState[value].value;
        if (IBArray) {
          jsons.geo = IBArray;
        }
      }
    });

    jsons.context = contextSpec;
    setContextSearch(contextSpec);
    inputJson = jsons;

    setSearchInputJson(inputJson);
    console.log(cardFilledState);
    const selectedST = getSearchTypeFromInput(cardFilledState);

    // console.log(state);
    console.log(selectedST);
    if (keywordState.IB1_niche.value) {
      let niche = nicheList.find(niche => niche.displayText === keywordState.IB1_niche.value);
      searchInputJson.context = [niche.value, `${searchInputJson.context}`];
    } else {
      searchInputJson.context = [`${searchInputJson.context}`];
    }
    console.log('searchInput: ', searchInputJson);
    searchSubmit(searchInputJson, selectedST);
    // clearing searchInput state after hitting search
    setSearchInputJson({
      seniority: '',
      department: '',
      title: '',
      since: '',
      pTopic: [],
      sTopic: [],
      tStack: [],
      sdt: [],
      geo: '',
      size: '',
      industry: '',
      pageNum: '',
      cLabel: [],
      cRoot: [],
      nameVc: [],
    });
  };

  const handleDropdownVal = e => {
    const val = e.target.value;
    const name = e.target.name;
    if (val === '') {
      setCardFilledState('');
      setKeywordState({ ...keywordState, [name]: { ...keywordState[name], value: '' } });
      setSearchInputJson({ ...searchInputJson, geo: '' });
      return;
    }
    clearOthersCards(name);
    setCardFilledState(name);
    setKeywordState({ ...keywordState, [name]: { ...keywordState[name], value: val } });
    setSearchInputJson({ ...searchInputJson, geo: val });
  };

  const handleNicheItems = e => {
    const val = e.target.value;
    const name = e.target.name;
    if (val === '') {
      setCardFilledState('');
      setKeywordState({ ...keywordState, [name]: { ...keywordState[name], value: '' } });
      return;
    }
    clearOthersCards(name);
    setCardFilledState(name);
    setKeywordState({ ...keywordState, [name]: { ...keywordState[name], value: val } });
    console.log(keywordState[name].value);
  };

  const handleCompanySizeVal = e => {
    let name = e.target.name;
    const val = e.target.value;
    if (name === 'IB5_revenue') {
      name = 'IB5_size';
      setIsRevOrSize('rev');
    } else {
      setIsRevOrSize('size');
    }

    if (val === '') {
      setCardFilledState('');
      setKeywordState({ ...keywordState, [name]: { ...keywordState[name], value: '' } });
      setSearchInputJson({ ...searchInputJson, size: '' });
      setIsRevOrSize('');
      return;
    }
    clearOthersCards(name);
    setCardFilledState(name);
    setKeywordState({ ...keywordState, [name]: { ...keywordState[name], value: val } });
    setSearchInputJson({ ...searchInputJson, size: val });
  };

  // disable search button if no input is filled
  const arrayOfKeywordState = Object.entries(keywordState).map(([key, value]) => ({
    key,
    items: value,
  }));
  const isSearchBtnDisabled = arrayOfKeywordState.some(
    item => item.items.value !== '' || item.items.arr.length
  );

  const classes = useStyles();
  return (
    <div className={classes.container}>
      {formValidationMessage.length > 0 && <Alert severity='error'>{formValidationMessage}</Alert>}

      <div className={classes.formWrapper}>
        {/* tweak cards buttons */}
        <div className='tweak_cardsBtns'>
          <button
            onClick={() => setShowTotalMarket(false)}
            className={!showTotalMarket ? 'activeBtn' : ''}
          >
            Opportunity Cluster
          </button>
          <button
            onClick={() => setShowTotalMarket(true)}
            className={showTotalMarket ? 'activeBtn' : ''}
          >
            Total Market
          </button>
        </div>
        {!showTotalMarket ? (
          <>
            {/* ib 1 */}
            <div className='IB1 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>Awareness Phase</span>
              </h5>
              {/* IB1_topic */}
              <p className='subHead'>Interest surge around topics that define problem category</p>
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Topics</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB1_topic'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB1_topic.value}
                  placeholder='OKR, Performance Marketing'
                  readOnly={true}
                  disabled={keywordState.IB1_tech.arr.length}
                  onClick={() => {
                    if (keywordState.IB1_tech.arr.length) {
                      return;
                    } else {
                      setModal({
                        type: 'topics',
                        name: 'IB1_topic',
                        maxChips: keywordState['IB1_topic'].maxAllowed,
                        headerText: 'Topics Keywords',
                        chipState: keywordState.IB1_topic.arr,
                        buttonText: 'Add Topics',
                        addContext: true,
                      });
                    }
                  }}
                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB1_topic'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* IB1_tech */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Technology</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB1_tech'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB1_tech.value}
                  placeholder='Salesforce, Gong'
                  readOnly={true}
                  disabled={keywordState.IB1_topic.arr.length}
                  onClick={() => {
                    if (keywordState.IB1_topic.arr.length) {
                      return;
                    } else {
                      setModal({
                        type: 'technology',
                        name: 'IB1_tech',
                        maxChips: keywordState['IB1_tech'].maxAllowed,
                        headerText: 'Technology Stack Keywords',
                        chipState: keywordState.IB1_tech.arr,
                        buttonText: 'Add Techstack',
                        addContext: true,
                      });
                    }
                  }}
                />
                <TagsView
                  inputName='IB1_tech'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* bottom form parts will placed here */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Geo</span>
                </p>
                <Select
                  name='IB1_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB1_geo.value}
                  onChange={handleDropdownVal}
                >
                  <MenuItem value=''>None</MenuItem>
                  {countryList.map((items, index) => (
                    <MenuItem value={items}>{items}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl + ' mt-3'}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Niche</span>
                </p>
                <Select
                  name='IB1_niche'
                  value={keywordState.IB1_niche.value}
                  onChange={handleNicheItems}
                  input={<BootstrapInput />}
                  // MenuProps={MenuProps}
                >
                  <MenuItem value=''>None</MenuItem>
                  {nicheList.map(({ displayText, value }) => (
                    <MenuItem key={displayText} value={displayText}>
                      {displayText}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/* IB 2 */}
            <div className='IB2 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>Interest Phase</span>
              </h5>
              {/* IB2_title */}
              <p className='subHead'>Investing in solution category (Hiring Your Ideal User)</p>
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Your Ideal User’s Title</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB2_title'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB2_title.value}
                  placeholder='Data Scientist'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB2_title'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* IB2_topic */}
              <FormControl className={classes.formControl}>
                <p className='subHead'>
                  Interest surge around topics that define solution category
                </p>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Topics</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB2_topic'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB2_topic.value}
                  placeholder='Snowflake'
                  readOnly={true}
                  disabled={keywordState.IB2_tech.arr.length}
                  onClick={() => {
                    if (keywordState.IB2_tech.arr.length) {
                      return;
                    } else {
                      setModal({
                        type: 'topics',
                        name: 'IB2_topic',
                        maxChips: keywordState['IB2_topic'].maxAllowed,
                        headerText: 'Topics Keywords',
                        chipState: keywordState.IB2_topic.arr,
                        buttonText: 'Add Topics',
                        addContext: true,
                      });
                    }
                  }}
                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB2_topic'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* IB2_tech */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Technology</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB2_tech'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB2_tech.value}
                  placeholder='Salesforce, Gong'
                  readOnly={true}
                  disabled={keywordState.IB2_topic.arr.length}
                  onClick={() => {
                    if (keywordState.IB2_topic.arr.length) {
                      return;
                    } else {
                      setModal({
                        type: 'technology',
                        name: 'IB2_tech',
                        maxChips: keywordState['IB2_tech'].maxAllowed,
                        headerText: 'Technology Stack Keywords',
                        chipState: keywordState.IB2_tech.arr,
                        buttonText: 'Add Techstack',
                        addContext: true,
                      });
                    }
                  }}
                />
                <TagsView
                  inputName='IB2_tech'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* dropdown */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Geo</span>
                </p>
                <Select
                  name='IB2_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB2_geo.value}
                  onChange={handleDropdownVal}
                >
                  <MenuItem value=''>None</MenuItem>
                  {countryList.map((items, index) => (
                    <MenuItem value={items}>{items}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* ib 3 */}
            <div className='IB2 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>Interest Phase</span>
              </h5>
              {/* IB3 Title */}
              <p className='subHead'>
                Investing in solution category (Onboarding Your Ideal Buyer)
              </p>
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Your Ideal User’s Title</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB3_title'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB3_title.value}
                  placeholder='Data scientist'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB3_title'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* IB3_department */}
              {/* <p className='subHead'>
                Investing in solution category (Onboarding Your Ideal Buyer)
              </p>
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Your Ideal Buyer's Department</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB3_dep'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB3_dep.value}
                  placeholder='Marketing'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB3_dep'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl> */}
              {/* IB3_sen */}
              {/* <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Your Ideal Buyer's Seniority</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB3_sen'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB3_sen.value}
                  placeholder='Director'
                  readOnly={true}
                  onClick={() => {
                    setModal({
                      type: 'seniority',
                      name: 'IB3_sen',
                      maxChips: keywordState['IB3_sen'].maxAllowed,
                      headerText: 'Seniority Keywords',
                      chipState: keywordState.IB3_sen.arr,
                      buttonText: 'Add Seniority',
                    });
                  }}
                />
                <TagsView
                  inputName='IB3_sen'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl> */}
              {/* IB3_topic */}
              <FormControl className={classes.formControl}>
                <p className='subHead'>
                  Interest surge around topics that define solution category
                </p>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Topics</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB3_topic'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB3_topic.value}
                  placeholder='ABM'
                  readOnly={true}
                  disabled={keywordState.IB3_tech.arr.length}
                  onClick={() => {
                    if (keywordState.IB3_tech.arr.length) {
                      return;
                    } else {
                      setModal({
                        type: 'topics',
                        name: 'IB3_topic',
                        maxChips: keywordState['IB3_topic'].maxAllowed,
                        headerText: 'Topics Keywords',
                        chipState: keywordState.IB3_topic.arr,
                        buttonText: 'Add Topics',
                        addContext: true,
                      });
                    }
                  }}
                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB3_topic'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* IB3_tech */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Technology</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB3_tech'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB3_tech.value}
                  placeholder='Salesforce, Gong'
                  readOnly={true}
                  disabled={keywordState.IB3_topic.arr.length}
                  onClick={() => {
                    if (keywordState.IB3_topic.arr.length) {
                      return;
                    } else {
                      setModal({
                        type: 'technology',
                        name: 'IB3_tech',
                        maxChips: keywordState['IB3_tech'].maxAllowed,
                        headerText: 'Technology Stack Keywords',
                        chipState: keywordState.IB3_tech.arr,
                        buttonText: 'Add Techstack',
                        addContext: true,
                      });
                    }
                  }}
                />
                <TagsView
                  inputName='IB3_tech'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* IB3_Geo */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Geo</span>
                </p>
                <Select
                  name='IB3_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB3_geo.value}
                  onChange={handleDropdownVal}
                >
                  <MenuItem value=''>None</MenuItem>
                  {countryList.map((items, index) => (
                    <MenuItem value={items}>{items}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* ib 4 */}
            <div className='IB2 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>Evaluation Phase</span>
              </h5>
              {/* IB4_dep
              <p className='subHead'>In a state of active solution evaluation</p>
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Your Ideal Buyer's Department</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB4_dep'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB4_dep.value}
                  placeholder='Marketing'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB4_dep'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
               */}
              {/* IB4_sen
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Your Ideal Buyer's Seniority</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB4_sen'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB4_sen.value}
                  placeholder='OKR, Performance Reviews, Employee Eng...'
                  readOnly={true}
                  onClick={() => {
                    setModal({
                      type: 'seniority',
                      name: 'IB4_sen',
                      maxChips: keywordState['IB4_sen'].maxAllowed,
                      headerText: 'Seniority Keywords',
                      chipState: keywordState.IB4_sen.arr,
                      buttonText: 'Add Seniority',
                    });
                  }}
                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB4_sen'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl> */}
              {/* IB4_title */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Your Ideal User’s Title</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB4_title'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB4_title.value}
                  placeholder='Data Scientist'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB4_title'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* IB4_topic4 */}
              <FormControl className={classes.formControl}>
                <p className='subHead'>Topics that buyer showed affinity</p>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Topics affinity</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB4_topic'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB4_topic.value}
                  placeholder='Marketing automation, Inbound Sales'
                  readOnly={true}
                  onClick={() => {
                    setModal({
                      type: 'topics',
                      name: 'IB4_topic',
                      maxChips: keywordState['IB4_topic'].maxAllowed,
                      headerText: 'Topics Keywords',
                      chipState: keywordState.IB4_topic.arr,
                      buttonText: 'Add Topics',
                    });
                  }}
                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB4_topic'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* dropdown */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Geo</span>
                </p>
                <Select
                  name='IB4_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB4_geo.value}
                  onChange={handleDropdownVal}
                >
                  <MenuItem value=''>None</MenuItem>
                  {countryList.map((items, index) => (
                    <MenuItem value={items}>{items}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </>
        ) : (
          <>
            {/* IB5 items */}
            <div className='IB2 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>SaaS Universe</span>
              </h5>
              <p className='subHead'>Select accounts from 800+ SaaS category</p>
              {/* IB5_cLabel */}
              {/* <p className='subHead'>In a state of active solution evaluation</p> */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Category</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB5_cLabel'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  readOnly={true}
                  onClick={() => {
                    setModal({
                      type: 'topics',
                      name: 'IB5_cLabel',
                      maxChips: 1,
                      headerText: 'Topics Keywords',
                      setCompanyLabelArrState,
                      chipState: keywordState.IB5_cLabel.arr,
                      buttonText: 'Add Topics',
                    });
                  }}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB5_cLabel.value}
                  placeholder='Marketing'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB5_cLabel'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* size dropdown */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Size</span>
                </p>
                <Select
                  name='IB5_size'
                  input={<BootstrapInput />}
                  value={isRevOrSize === 'size' ? keywordState.IB5_size.value : ''}
                  onChange={handleCompanySizeVal}
                  disabled={isRevOrSize === 'rev' ? true : false || (isRevOrSize === '' && false)}
                >
                  <MenuItem value=''>None</MenuItem>
                  {companySize.map(({ value, label }) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* IB5 revenue */}
              <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Revenue</span>
                </p>
                <Select
                  name='IB5_revenue'
                  input={<BootstrapInput />}
                  value={isRevOrSize === 'rev' ? keywordState.IB5_size.value : ''}
                  onChange={handleCompanySizeVal}
                  disabled={isRevOrSize === 'size' ? true : false || (isRevOrSize === '' && false)}
                >
                  <MenuItem value=''>None</MenuItem>
                  {companySize
                    .map(({ label, value }) => ({
                      rev:
                        label === '1-10'
                          ? '$175k - $1.75m'
                          : label === '11-50'
                          ? '$1.925m - $8.75m'
                          : label === '51-200'
                          ? '$8.925m - $35m'
                          : label === '201-500'
                          ? '$35.175m - $87.5m'
                          : label === '501-1000'
                          ? '$87.675m - $175m'
                          : label === '1001-5000'
                          ? '$175.175m - $875m'
                          : label === '5001-10000'
                          ? '$875.175m - $1.75b'
                          : '$1.75b+',
                      val: value,
                    }))
                    .map(({ rev, val }) => (
                      <MenuItem value={val}>{rev}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              {/*geo dropdown IB_5 */}
              <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Location</span>
                </p>
                <Select
                  name='IB5_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB5_geo.value}
                  onChange={handleDropdownVal}
                >
                  <MenuItem value=''>None</MenuItem>
                  {cityList
                    .sort((a, b) => a.localeCompare(b))
                    .map(city => (
                      <MenuItem value={city}>{city}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            {/* IB6 items */}
            <div className='IB2 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>Lookalike Accounts</span>
              </h5>
              <p className='subHead'>Specify your target software companies. Get more lookalikes</p>
              {/* IB6_cLabel */}
              {/* <p className='subHead'>In a state of active solution evaluation</p> */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Target Companies (Up to 5)</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB6_intent'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB6_intent.value}
                  placeholder='Salesloft'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB6_intent'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* size dropdown */}
              {/* <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Size</span>
                </p>
                <Select
                  name='IB6_size'
                  input={<BootstrapInput />}
                  value={keywordState.IB6_size.IB5_size.value}
                  onChange={handleCompanySizeVal}
                  
                >
                  <MenuItem value=''>None</MenuItem>
                  {companySize.map(({ value, label }) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              {/* IB6 revenue */}
              {/* <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
              <p className='inputLabelText'>
                <span>
                  <img
                    style={{ marginRight: '8px' }}
                    src={require('assets/images/UsersThree-form.png')}
                    alt='userThree'
                  />
                </span>
                <span>Revenue</span>
              </p>
              <Select
                name='IB5_revenue'
                input={<BootstrapInput />}
                value={isRevOrSize === 'rev' ? searchInputJson.size : ''}
                onChange={handleCompanySizeVal}
                disabled={
                  (cardFilledState.length && !cardFilledState.startsWith('IB5')) ||
                  isRevOrSize === 'size'
                    ? true
                    : false || (isRevOrSize === '' && false)
                }
              >
                <MenuItem value=''>None</MenuItem>
                {companySize
                  .map(({ label, value }) => ({
                    rev:
                      label === '1-10'
                        ? '$175k - $1.75m'
                        : label === '11-50'
                        ? '$1.925m - $8.75m'
                        : label === '51-200'
                        ? '$8.925m - $35m'
                        : label === '201-500'
                        ? '$35.175m - $87.5m'
                        : label === '501-1000'
                        ? '$87.675m - $175m'
                        : label === '1001-5000'
                        ? '$175.175m - $875m'
                        : label === '5001-10000'
                        ? '$875.175m - $1.75b'
                        : '$1.75b+',
                    val: value,
                  }))
                  .map(({ rev, val }) => (
                    <MenuItem value={val}>{rev}</MenuItem>
                  ))}
              </Select>
            </FormControl> */}
              {/*geo dropdown IB_6 */}
              {/* <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Location</span>
                </p>
                <Select
                  name='IB6_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB6_geo.value}
                  onChange={handleDropdownVal}
                  
                >
                  <MenuItem value=''>None</MenuItem>
                  {cityList
                    .sort((a, b) => a.localeCompare(b))
                    .map(city => (
                      <MenuItem value={city}>{city}</MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </div>
            {/* IB7 items */}
            <div className='IB2 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>VC Portfolio</span>
              </h5>
              <p className='subHead'>Specify VC and find portfolio companies</p>
              {/* IB7_cLabel */}
              {/* <p className='subHead'>In a state of active solution evaluation</p> */}
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Specify VC (Up to 5)</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB7_intent'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB7_intent.value}
                  onClick={()=> {
                    setModal({
                      type: 'vc',
                      name: 'IB7_intent',
                      maxChips: 5,
                      headerText: 'Popular Venture Capitals',
                      setCompanyLabelArrState,
                      chipState: keywordState.IB7_intent.arr,
                      buttonText: 'Add Vc',
                    });
                  }}
                  placeholder='Sequoia Capital'

                  // disabled={state.technology.chip.chipData.length}
                />
                <TagsView
                  inputName='IB7_intent'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              {/* size dropdown */}
              {/* <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Size</span>
                </p>
                <Select
                  name='IB7_size'
                  input={<BootstrapInput />}
                  value={keywordState.IB7_size.IB5_size.value}
                  onChange={handleCompanySizeVal}
                  
                >
                  <MenuItem value=''>None</MenuItem>
                  {companySize.map(({ value, label }) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              {/* IB7 revenue */}
              {/* <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
              <p className='inputLabelText'>
                <span>
                  <img
                    style={{ marginRight: '8px' }}
                    src={require('assets/images/UsersThree-form.png')}
                    alt='userThree'
                  />
                </span>
                <span>Revenue</span>
              </p>
              <Select
                name='IB5_revenue'
                input={<BootstrapInput />}
                value={isRevOrSize === 'rev' ? searchInputJson.size : ''}
                onChange={handleCompanySizeVal}
                disabled={
                  (cardFilledState.length && !cardFilledState.startsWith('IB5')) ||
                  isRevOrSize === 'size'
                    ? true
                    : false || (isRevOrSize === '' && false)
                }
              >
                <MenuItem value=''>None</MenuItem>
                {companySize
                  .map(({ label, value }) => ({
                    rev:
                      label === '1-10'
                        ? '$175k - $1.75m'
                        : label === '11-50'
                        ? '$1.925m - $8.75m'
                        : label === '51-200'
                        ? '$8.925m - $35m'
                        : label === '201-500'
                        ? '$35.175m - $87.5m'
                        : label === '501-1000'
                        ? '$87.675m - $175m'
                        : label === '1001-5000'
                        ? '$175.175m - $875m'
                        : label === '5001-10000'
                        ? '$875.175m - $1.75b'
                        : '$1.75b+',
                    val: value,
                  }))
                  .map(({ rev, val }) => (
                    <MenuItem value={val}>{rev}</MenuItem>
                  ))}
              </Select>
            </FormControl> */}
              {/*geo dropdown IB_7 */}
              {/* <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Location</span>
                </p>
                <Select
                  name='IB7_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB7_geo.value}
                  onChange={handleDropdownVal}
                  
                >
                  <MenuItem value=''>None</MenuItem>
                  {cityList
                    .sort((a, b) => a.localeCompare(b))
                    .map(city => (
                      <MenuItem value={city}>{city}</MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </div>
            {/* IB8 items */}
            {/* <div className='IB2 IB-all'>
              <h5>
                <span style={{ marginRight: '5px' }}>
                  <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
                </span>
                <span>Dummy search 3</span>
              </h5>
              <p className='subHead'>Select accounts from 800+ SaaS category</p>
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Category</span>
                </p>
                <InputField
                  type='text'
                  id='keywords'
                  name='IB8_cLabel'
                  onChange={handleKeywordChange}
                  onKeyPress={addKeywordChip}
                  readOnly={true}
                  onClick={() => {
                    if (cardFilledState.length && !cardFilledState.startsWith('IB8')) {
                      return;
                    } else {
                      setModal({
                        type: 'topics',
                        name: 'IB8_cLabel',
                        maxChips: 1,
                        headerText: 'Topics Keywords',
                        setCompanyLabelArrState,
                        chipState: keywordState.IB8_cLabel.arr,
                        buttonText: 'Add Topics',
                      });
                    }
                  }}
                  margin='none'
                  className='col-xs-12 col-lg-12'
                  fullWidth
                  value={keywordState.IB8_cLabel.value}
                  placeholder='Marketing'
                  
                />
                <TagsView
                  inputName='IB8_cLabel'
                  state={keywordState}
                  deleteChip={deleteKeywordChip}
                  type='keywords'
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/codesandbox-black.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Size</span>
                </p>
                <Select
                  name='IB8_size'
                  input={<BootstrapInput />}
                  value={keywordState.IB8_size.IB5_size.value}
                  onChange={handleCompanySizeVal}
                  
                >
                  <MenuItem value=''>None</MenuItem>
                  {companySize.map(({ value, label }) => (
                    <MenuItem value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
              <p className='inputLabelText'>
                <span>
                  <img
                    style={{ marginRight: '8px' }}
                    src={require('assets/images/UsersThree-form.png')}
                    alt='userThree'
                  />
                </span>
                <span>Revenue</span>
              </p>
              <Select
                name='IB5_revenue'
                input={<BootstrapInput />}
                value={isRevOrSize === 'rev' ? searchInputJson.size : ''}
                onChange={handleCompanySizeVal}
                disabled={
                  (cardFilledState.length && !cardFilledState.startsWith('IB5')) ||
                  isRevOrSize === 'size'
                    ? true
                    : false || (isRevOrSize === '' && false)
                }
              >
                <MenuItem value=''>None</MenuItem>
                {companySize
                  .map(({ label, value }) => ({
                    rev:
                      label === '1-10'
                        ? '$175k - $1.75m'
                        : label === '11-50'
                        ? '$1.925m - $8.75m'
                        : label === '51-200'
                        ? '$8.925m - $35m'
                        : label === '201-500'
                        ? '$35.175m - $87.5m'
                        : label === '501-1000'
                        ? '$87.675m - $175m'
                        : label === '1001-5000'
                        ? '$175.175m - $875m'
                        : label === '5001-10000'
                        ? '$875.175m - $1.75b'
                        : '$1.75b+',
                    val: value,
                  }))
                  .map(({ rev, val }) => (
                    <MenuItem value={val}>{rev}</MenuItem>
                  ))}
              </Select>
            </FormControl>
              
              <FormControl className={classes.formControl} style={{ marginTop: '12px' }}>
                <p className='inputLabelText'>
                  <span>
                    <img
                      style={{ marginRight: '8px' }}
                      src={require('assets/images/UsersThree-form.png')}
                      alt='userThree'
                    />
                  </span>
                  <span>Location</span>
                </p>
                <Select
                  name='IB8_geo'
                  input={<BootstrapInput />}
                  value={keywordState.IB8_geo.value}
                  onChange={handleDropdownVal}
                  
                >
                  <MenuItem value=''>None</MenuItem>
                  {cityList
                    .sort((a, b) => a.localeCompare(b))
                    .map(city => (
                      <MenuItem value={city}>{city}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div> */}
          </>
        )}
      </div>

      <div className={classes.bottomBox}>
        <IconButton
          variant='contained'
          size='small'
          color='primary'
          onClick={handleSearchSubmit}
          icon={require('../../assets/images/logo.png')}
          className={classes.searchBtn}
          disabled={loading || !isSearchBtnDisabled}
        >
          <span>Search</span>
        </IconButton>
      </div>

      {/* Modal */}
      <Modal className={classes.modal} open={modal} onClose={() => setModal(false)}>
        <PopupSearch
          name={modal.name}
          addKeywordChip={addKeywordChipArray}
          addContextSpec={setContextSpec}
          closeModal={() => setModal(false)}
          maxChips={modal.maxChips}
          inputPlaceholder={'Start Typing...'}
          headerText={modal.headerText}
          buttonText={modal.buttonText}
          chipState={modal.chipState}
          compnayLabelSetter={modal?.setCompanyLabelArrState}
          type={modal.type}
          addContext={modal.addContext || false}
        />
      </Modal>
    </div>
  );
};

export default TagsInput;
