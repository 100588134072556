import { all } from 'redux-saga/effects';
import contactSagas from './Contact';
import authSagas from './Auth';
import jobsSagas from './Jobs';
import paymentSagas from './Payment';
import searchSagas from './Search';

export default function* rootSaga(getState) {
  yield all([contactSagas(), authSagas(), jobsSagas(), paymentSagas(), searchSagas()]);
}
