import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';
import SideBarContent from './SideBarContent';
import { makeStyles } from '@material-ui/core/styles';

const SideBar = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { navCollapsed, drawerType, width, navigationStyle } = useSelector(
    ({ settings }) => settings
  );

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  const onToggleCollapsedNav = e => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  let drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ''
    : 'd-flex';
  let type = 'permanent';
  if (
    drawerType.includes(COLLAPSED_DRAWER) ||
    (drawerType.includes(FIXED_DRAWER) && width < 1200)
  ) {
    type = 'temporary';
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    drawerStyle = '';
    type = 'temporary';
  }
  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      {/* <Drawer className="app-sidebar-content"
              variant={type}
              open={type.includes('temporary') ? navCollapsed : true}
              onClose={onToggleCollapsedNav}
              classes={{
                paper: 'side-nav',
              }}
      > */}
      <Drawer
        className={(classes.drawer, 'app-sidebar-min-fix')}
        //{[classes.drawer, 'app-sidebar-min-fix']}
        variant='permanent'
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor='left'
      >
        <UserInfo />
        <SideBarContent />
      </Drawer>
    </div>
  );
};

const drawerWidth = 80;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: '#EFEEEE',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#EFEEEE',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default withRouter(SideBar);
