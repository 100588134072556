import React, { useEffect, useState, useCallback } from 'react';
import { Table, Button, Dialog, Paper } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FilterListPopOver from '../../app/routes/filterListPopover';
import Checkbox from '@material-ui/core/Checkbox';
import AutoCompleteListName from 'components/AutoCompleteList';
import VisualiseNetwork from 'components/visNetwork';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const tick = require('../../assets/images/doubletick.png');
const warning = require('../../assets/images/warning.png');

function ResultListTable(
  {
    result,
    showFilter,
    filterList,
    autoCompleteData,
    filterType,
    handleFilterSelect,
    selectionAvailable,
    onAllCheckChange,
    onindividualCheckChange,
    onClickListItem,
    moreInfo,
    itemClick,
    autoSelected,
    autonewItemCreated,
    resultType = 'peopleList',
  },
  ref
) {
  const classes = useStyles();
  const companyListClasses = useStylesForCompanyList();
  const initialStyleClasses = useStylesInitial();
  const [fields, setFields] = useState([]);
  const styles = {};

  const [openModal, setOpenModal] = useState(false);
  const [vizData, setVizData] = useState(null);
  const handleModalOpen = (accountName, intel) => {
    setOpenModal(true);
    setVizData({ accountName, intel });
  };
  const handleModalClose = () => setOpenModal(false);

  if (resultType === 'companySearch') {
    styles.searchListRow = classes.searchListRow;
    styles.searchListCell = classes.searchListCell;
    styles.syncBtn = classes.syncBtn;
    styles.searchListTable = classes.searchListTable;
  }
  if (resultType === 'companyList') {
    styles.searchListRow = companyListClasses.searchListRow;
    styles.searchListCell = companyListClasses.searchListCell;
    styles.syncBtn = companyListClasses.syncBtn;
    styles.searchListTable = companyListClasses.searchListTable;
  }

  useEffect(() => {
    let indexHasMoreProperties = 0;
    result.map((resultItem, i) => {
      let keysLength = Object.keys(resultItem).length;
      if (keysLength > indexHasMoreProperties) indexHasMoreProperties = i;
    });

    if (result && result.length > 0) {
      let keys = Object.keys(result[indexHasMoreProperties]);

      if (selectionAvailable) keys.splice(keys.indexOf('checked'), 1);

      if (keys.indexOf('id') > -1) keys.splice(keys.indexOf('id'), 1);
      setFields(keys);
    }
  }, [result]);

  const handleSelectedFilter = useCallback(type => {
    handleFilterSelect(type);
  }, []);

  const onClickRowItem = rowItem => {
    if (onClickListItem != null) onClickListItem(rowItem);
  };

  const dropDownSelected = (obj, selectedListObj) => {
    if (autoSelected) {
      autoSelected(obj, selectedListObj);
    }
  };

  const dropDownNewItemCreated = (obj, lnm) => {
    if (autonewItemCreated) autonewItemCreated(obj, lnm);
  };

  const checkHowManySelected = () => result.filter(item => item.checked.value);

  const displayText = (obj, field, styles = null) => {
    try {
      let val = obj[field].value;
      // console.log(val);
      switch (obj[field].type) {
        case 'text':
          return (
            <label className='align-middle'>
              <h5 className='m-0 list-wrap'>{obj[field].value}</h5>
              {obj[field].hintText != null && (
                <h6 className='m-0 list-wrap'>{obj[field].hintText}</h6>
              )}
            </label>
          );

        case 'info':
          return (
            <label className='align-middle'>
              <h5 className='m-0 list-wrap'>{obj[field].value}</h5>
              <h6 className='m-0 list-wrap'>
                {obj[field].hintText || '' + ' '}{' '}
                <span className='text-primary'>
                  <u>{obj[field].info}</u>
                </span>
              </h6>
            </label>
          );

        case 'autocomplete':
          return (
            <AutoCompleteListName
              selected={obj[field].value.selected}
              data={autoCompleteData}
              enabled={obj[field].value.enabled}
              dropDownSelected={selectedListObj => dropDownSelected(obj, selectedListObj)}
              dropDownNewItemCreated={lnm => dropDownNewItemCreated(obj, lnm)}
              width={200}
            />
          );

        case 'catchAllNValidity':
          return (
            <label className='align-middle'>
              {obj[field].value.valid == true && (
                <label className='list-wrap'>
                  <h5 className='breakAll'>
                    <u>
                      {obj[field].value.email} <img src={tick} width={15} />{' '}
                    </u>
                  </h5>

                  <h5>
                    <img src={tick} width={15} /> Not Catch All. Safe to send
                  </h5>
                </label>
              )}

              {obj[field].value.valid == false && obj[field].value.catchAll == true && (
                <label className='list-wrap'>
                  <h5 className='breakAll'>
                    <u>
                      {obj[field].value.email} <img src={warning} width={15} />
                    </u>
                  </h5>

                  <h5>
                    <img src={warning} width={15} /> Catch All. <br /> Confidence :{' '}
                    {obj[field].value.confidence}%
                  </h5>
                </label>
              )}
            </label>
          );

        case 'more':
          return (
            <label className='align-middle'>
              <h5 className='m-0 list-wrap'>
                {obj[field].value}
                {obj[field].value.indexOf(',') > 0 && (
                  <span className='pointer' onClick={() => moreInfo(obj, field)}>
                    {' '}
                    (more...){' '}
                  </span>
                )}
              </h5>
            </label>
          );

        case 'textButton':
          return (
            <label className='align-middle'>
              <a className='text-primary pointer' onClick={() => itemClick(obj, field)}>
                <u>{obj[field].value}</u>
              </a>
            </label>
          );

        case 'button':
          return (
            <div>
              <label className='align-middle'>
                {styles?.syncBtn ? (
                  <button
                    className={styles?.syncBtn}
                    onClick={() => itemClick(obj, field)}
                    disabled={obj[field].disabled}
                  >
                    <span style={{ margin: '0 auto' }}>{obj[field].value}</span>
                  </button>
                ) : (
                  <Button
                    variant='outlined'
                    color='primary'
                    size="small"
                    onClick={() => itemClick(obj, field)}
                    disabled={obj[field].disabled}
                    style={{ textTransform: "capitalize" }}
                  >
                    {obj[field].value}
                  </Button>
                )}
              </label>
              {/* <button className={initialStyleClasses.initialBtn}>+ linkedin add</button> */}
            </div>
          );

        case 'image':
          return (
            <img
              role='companyLogo'
              src={obj[field].value.src || obj[field].value}
              alt={obj[field].value.alt}
            />
          );
        case 'signalCell': {
          let row1Val = obj[field].cellInfo.row1.value;
          let row2Val = obj[field].cellInfo.row2.value;
          return (
            <div>
              <div className='signal_cells'>
                <div className='cell-info'>
                  <img
                    src={require('../../assets/images/search_res_box_imgs/stars-02.png')}
                    alt='icon'
                  />
                  <div className='ac'>
                    <p>{obj[field].cellInfo.row1.cueText1}</p>
                    <div className='ac_btn'>
                      {Array.isArray(row1Val) ? (
                        row1Val.map((val, idx) => <button key={idx}>{val}</button>)
                      ) : (
                        <button>{row1Val}</button>
                      )}
                    </div>
                    {obj[field].cellInfo.row1.cueText2 && (
                      <p style={{ margin: '10px 0' }}>{obj[field].cellInfo.row1.cueText2}</p>
                    )}
                  </div>
                </div>
              </div>
              {obj[field].cellInfo.row2.visible ? (
                <div className='signal_cells'>
                  <div className='cell-info'>
                    <img
                      src={require('../../assets/images/search_res_box_imgs/line-chart-up-01.png')}
                      alt='icon'
                    />
                    <div className='ac'>
                      <p className='ac_btn'>{obj[field].cellInfo.row2.cueText1}</p>
                      <div className='ac_btn'>
                        {Array.isArray(row2Val) ? (
                          row2Val.map((val, idx) => <button key={idx}>{val}</button>)
                        ) : (
                          <button>{row2Val}</button>
                        )}
                      </div>
                      {obj[field].cellInfo.row2.cueText2 && (
                        <p style={{ margin: '10px 0' }}>{obj[field].cellInfo.row2.cueText2}</p>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          );
        }
        case 'accountCell':
          return (
            <div className='account_cell'>
              <div className='big_text'>
                <p className='title'>{obj[field].cellInfo.row1.value}</p>
                <a target='_blank' href={obj[field].cellInfo.row2.value} className='small_text'>
                  {obj[field].cellInfo.row2.cueText1}
                </a>
                {obj[field].cellInfo.row3.visible ? (
                  <>
                    <br />
                    <a target='_blank' href={obj[field].cellInfo.row3.value} className='small_text'>
                      {obj[field].cellInfo.row3.cueText1}
                    </a>
                  </>
                ) : null}
              </div>
            </div>
          );
        case 'strengthCell':
          return (
            <div className='temp_cell'>
              <div className='tempStatus'>
                <img
                  src={require(`../../assets/images/search_res_box_imgs/${(obj[field].cellInfo.row1
                    .value === 'Hot' &&
                    'high') ||
                    (obj[field].cellInfo.row1.value === 'Moderate' && 'medium') ||
                    (obj[field].cellInfo.row1.value === 'Cold' && 'low')}.png`)}
                  alt='confidence'
                />
                <div className='spotted' style={{ marginTop: '23px' }}>
                  <p className='spot_head'>{obj[field].cellInfo.row2.cueText1}</p>
                  <div className='date'>
                    <p style={{ margiBottom: '7px' }}>
                      <img
                        style={{ marginRight: '5px' }}
                        src={require('../../assets/images/search_res_box_imgs/calendar-icon.png')}
                        alt='calender'
                      />
                      <span>
                        {obj[field].cellInfo.row2.value === ''
                          ? 'N/A'
                          : obj[field].cellInfo.row2.value}{' '}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        case 'intelCell':
          return (
            <div
              style={{
                padding: '10px',
                minWidth: '10rem',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '17px',
                color: '#000000',
              }}
            >
              {/* <p>{obj[field].cellInfo.row1.value}</p> */}
              <Button
                onClick={() =>
                  handleModalOpen(
                    obj['Account'].cellInfo.row1.value,
                    obj[field].cellInfo.row1.value
                  )
                }
                color='primary'
                size='medium'
                variant='outlined'
              >
                Reveal
              </Button>
            </div>
          );
        case 'confidenceCell':
          return (
            <div style={{ textAlign: 'center' }}>
              <img
                src={require(`../../assets/images/search_res_box_imgs/confidence_${(obj[field]
                  .cellInfo.row1.value === 'High' &&
                  'high') ||
                  (obj[field].cellInfo.row1.value === 'Low' && 'low') ||
                  (obj[field].cellInfo.row1.value === 'Medium' && 'medium')}.png`)}
                alt='confidence'
              />
            </div>
          );

        case 'anchor':
          return (
            <label className='align-middle'>
              <a className='text-primary pointer' onClick={() => window.open(obj[field].value)}>
                {obj[field].value}
              </a>
            </label>
          );

        case 'contactInfo':
          return (
            <div className='contactInfo'>
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: '#000000',
                  marginBottom: '10px',
                }}
              >
                <span>{val.mainText}</span>
              </div>
              {/* console.log(val); */}
              {Array.isArray(val.coordinates) &&
                val.coordinates.map((item, index) =>
                  item.link !== '' ? (
                    <div key={index}>
                      <a key={index} href={item.link} target='_blank' className='pointer'>
                        <img width={20} src={require(`../../assets/images/${item.image}.png`)} />
                        <span className='text-primary'>
                          <u>{item.text}</u>
                        </span>
                        <u></u>
                      </a>
                      <h5>{item.location}</h5>
                    </div>
                  ) : (
                    <span key={index}>"NA"</span>
                  )
                )}
              {/* {val.secondaryText.href ? (
                <a href={val.secondaryText.link}>{val.secondaryText.text}</a>
              ) : (
                <div>{val.secondaryText.text}</div>
              )} */}
              {val.list && <br />}
              {val.list &&
                val.list.map((item, idx) => (
                  <a key={idx} href={item.link} target='_blank' className='pointer'>
                    <img
                      width={20}
                      src={require(`../../assets/images/${item.logoImg}.png`)}
                      alt={item.logoAlt}
                    />
                  </a>
                ))}
              {val.geo && (
                <div>
                  {/* <img
                    style={{ marginRight: '5px' }}
                    src={require(`../../assets/images/location-icon.png`)}
                    alt='location-icon'
                  /> */}
                  <span className={initialStyleClasses.textStyles}>{val.geo}</span>
                </div>
              )}
            </div>
          );

        case 'intentInfo':
          return (
            <div>
              <div>
                <img
                  style={{ marginRight: '5px' }}
                  src={require('../../assets/images/FlowerLotus.png')}
                  alt='flowerLotus'
                />
                <span className={initialStyleClasses.textStyles}>{val.text}</span>
              </div>
              <div>
                <img
                  style={{ marginRight: '5px' }}
                  src={require(`../../assets/images/GitBranch.png`)}
                  alt='gitbranch'
                />
                <span className={initialStyleClasses.textStyles}>{val.secondaryText}</span>
              </div>
              <div>
                <img
                  style={{ marginRight: '5px' }}
                  src={require(`../../assets/images/ArrowFatLinesUp.png`)}
                  alt='arrowUp'
                />
                <span className={initialStyleClasses.textStyles}>
                  Confidence: <span style={{ color: '#A6AAAF' }}>{val.helperText.conf}</span>
                  {/* |
                  SpottedAt: <span style={{ color: '#A6AAAF' }}>{val.helperText.date}</span>{' '} */}
                </span>
              </div>
            </div>
          );

        case 'companyCordinate':
          return (
            <div>
              <div>
                <a href={val.coordinates[0].link} target='_blank' className='pointer'>
                  Linkedin
                </a>
              </div>
              <div>
                <a href={val.secondaryText.link} target='_blank' className='pointer'>
                  Website
                </a>
              </div>
            </div>
          );
        default:
          return (
            <label className='align-middle'>
              <h5 className='m-0 list-wrap'>{JSON.stringify(obj[field])}</h5>
            </label>
          );
      }
    } catch (error) {
      console.log(error);
      return <></>;
    }
  };

  const selectedList = selectionAvailable && checkHowManySelected();

  const filterTypeToDisplay = (obj, field, rowIndex, fieldIndex, styles) => {
    try {
      if (!obj || obj[field].value === '') return '-';
      else return <>{displayText(obj, field, styles)}</>;
    } catch (error) {
      return <></>;
    }
  };

  const displayTd = (result, fields) => {
    return result.map((n, i) => (
      <TableRow
        onClick={() => onClickRowItem(n)}
        key={i}
        className={styles.searchListRow + ' searchRow'}
      >
        {selectionAvailable && (
          <TableCell className={styles.searchListCell} key={i - 1}>
            <Checkbox
              color='primary'
              checked={n['checked'].value}
              onClick={() => onindividualCheckChange(i)}
              value='SelectMail'
            ></Checkbox>
          </TableCell>
        )}
        {fields.map((item, j) => (
          <TableCell className={styles.searchListCell} key={j}>
            {filterTypeToDisplay(n, item, i, j, styles)}
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  return (
    <div ref={ref} style={{ height: '100%' }} className='table-responsive-material custom-table'>
      {showFilter && (
        <FilterListPopOver
          filterList={filterList}
          filterType={filterType}
          handleSelectedFilter={handleSelectedFilter}
        />
      )}
      <Table className={styles.searchListTable}>
        <TableHead>
          <TableRow>
            {selectionAvailable && (
              <TableCell
                className='searchListTable_head'
                key={'0'}
                style={{
                  paddingBottom: '10px',
                  // paddingLeft: (item === 'Signal' && '96px') || (item === 'Confidence' && '59px'),
                  borderBottom: `${resultType === 'companyList' && '0.5px solid #A6AAAF'}`,
                }}
              >
                <Checkbox
                  color='primary'
                  indeterminate={selectedList.length > 0 && selectedList.length < result.length}
                  checked={selectedList.length > 0}
                  value='appList'
                  onClick={onAllCheckChange}
                />
              </TableCell>
            )}
            {fields.map((item, i) => (
              <TableCell
                className='searchListTable_head'
                key={i}
                style={{
                  paddingBottom: '10px',
                  // paddingLeft: (item === 'Signal' && '96px') || (item === 'Confidence' && '59px'),
                  borderBottom: `${resultType === 'companyList' && '0.5px solid #A6AAAF'}`,
                }}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{displayTd(result, fields)}</TableBody>
      </Table>
      <Dialog open={openModal} onClose={handleModalClose} title='Network Visualiser'>
        <Paper>
          <div className={classes.modalCloseButton}>
            <CloseIcon onClick={handleModalClose} />
          </div>
          {openModal && (
            <VisualiseNetwork accountName={vizData?.accountName} intel={vizData?.intel} />
          )}
        </Paper>
      </Dialog>
    </div>
  );
}

const useStylesInitial = makeStyles(theme => ({
  textStyles: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#000000',
  },
  initialBtn: {
    display: 'flex',
    marginTop: '9px',
    alignItems: 'center',
    padding: '5px 5px 5px 10px',
    width: '116px',
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    backgroundColor: '#ffff',
    borderRadius: '5px',
    textTransform: 'capitalize',
    color: '#000000',
    paddingBottom: '10px 25px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#ccc',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '&:hover': {
      backgroundColor: '#f0eded',
    },
  },
}));

const useStyles = makeStyles(theme => ({
  searchListTable: {
    '& th:nth-child(1)': {
      paddingLeft: '3rem',
    },
    '& tbody tr td:nth-child(1)': {
      paddingLeft: '3.125rem',
    },
    '& th:nth-child(4)': {
      paddingLeft: '3rem',
    },
    '& tbody tr td:nth-child(4)': {
      paddingLeft: '2.5rem',
    },
    '& .searchListTable_head': {
      fontSize: '16px',
    },
    '& .signal_cells': {
      marginBottom: '20px',
      '& .ac_btn': {
        marginTop: '7px',
        '& button': {
          color: '#585CA6',
          padding: '5px 12px',
          margin: '2px',
          fontSize: '14px',
          background: '#F0F3F7',
          fontWeight: '600',
          borderRadius: '5px',
          border: 'none',
        },
      },
      '& .cell-info': {
        display: 'grid',
        gridTemplateColumns: '25px 1fr',
        alignItems: 'start',
        justifyItems: 'stretch',
        gap: '8px',
        minWidth: '11rem',
        '& img': {
          margin: '5px 0',
        },
        '& .ac': {
          maxWidth: '200px',
          '& p': {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '17px',
            color: '#000000',
            marginBottom: '3px',
          },

          '& .ac_text': {
            gap: '0 5px',
          },
        },
        '& .check_box': {
          display: 'flex',
          alignItems: 'center',
          '& img': {
            width: '25px',
            height: '25px',
            borderRadius: '100%',
            marginRight: '3px',
          },
        },
      },
    },

    '& .account_cell': {
      marginRight: '1rem',
      width: '10rem',
      '& .title': {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#000000',
      },
      '& .small_text': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#222F3C',
      },
    },
    '& .temp_cell': {
      '& .spotted *': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#000000',
      },
      '& .spot_head': {
        marginBottom: '16px',
      },
    },
    '& .searchRow': {
      backgroundColor: '#ffff',
    },
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 20px',
    // padding: '0px 10px',
    '& tr td:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
    },
    '& tr td:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
    },
  },
  searchListRow: {
    backgroundColor: 'rgba(235, 235, 235, 0.3)',
  },
  searchListCell: {
    // minWidth: '140px !important',
    // border: 'none',
    "& img[role='companyLogo']": {
      maxWidth: '70px',
      width: '100%',
      border: '1px solid #DDDDDD',
      borderRadius: '5px',
    },
  },
  syncBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px 5px 10px',
    width: '115px',
    height: '27px',
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    backgroundColor: '#ffff',
    borderRadius: '5px',
    textTransform: 'uppercase',
    color: '#A6AAAF',
    // color: '#172EEC',
    paddingBottom: '10px 25px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#000000',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '&:hover': {
      backgroundColor: '#f0eded',
    },
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  modalCloseButton: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '8px',
    '& svg': { cursor: 'pointer' },
  },
}));

const useStylesForCompanyList = makeStyles(theme => ({
  // th: {

  // },
  searchListTable: {
    border: 'none',
    borderCollapse: 'separate',
    borderSpacing: '0px 15px',
  },
  searchListRow: {
    // backgroundColor: "rgba(235, 235, 235, 0.3)",
    // backgroundColor: "rgba(235, 235, 235, 0.3)",
  },
  searchListCell: {
    '&:first-child': {
      width: '1rem',
    },
    '&:nth-child(3n)': {
      width: '250px !important',
    },

    // border: "none",
    // borderBottom: '0.5px solid #A6AAAF',
    "& img[role='companyLogo']": {
      maxWidth: '70px',
      width: '100%',
      border: '1px solid #DDDDDD',
      borderRadius: '5px',
    },
  },
  syncBtn: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 5px 5px 5px',
    width: '115px',
    height: '27px',
    // border: '1px solid #DDDDDD',
    border: '1px solid rgba(23, 46, 236, 0.5)',
    boxSizing: 'border-box',
    backgroundColor: '#ffff',
    textTransform: 'uppercase',
    color: '#172EEC',
    paddingBottom: '10px 25px',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: '#ccc',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    '&:hover': {
      backgroundColor: '#f0eded',
    },
  },
}));

export default React.forwardRef(ResultListTable);
