import React, { useState, useMemo, useEffect } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Chip,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { useAutocomplete, Alert, createFilterOptions } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Button } from 'reactstrap';
import deleteImg from '../../assets/images/deleteIcon.svg';
// TOPICS
import topicsChildParent from './data/topics/childParent.json';
import topicsParentChild from './data/topics/parentChild.json';
// SENIORITY
import seniorityChildParent from './data/seniority/childParent';
import seniorityParentChild from './data/seniority/parentChild';
// TECHNOLOGY STACK
import technologyChildParent from './data/technology/childParent';
import technologyParentChild from './data/technology/parentChild';

// VC
import vcParentChild from './data/VC/vcParentChild.json';
import vcChildParent from './data/VC/vcChildParent.json';

const useStyles = makeStyles(theme => ({
  wrapper: {
    alignItems: 'flex-start',
  },
  paper: {
    width: 'clamp(450px,70vw,935px)',
    // height: '80vh',
    padding: '35px',
    listStyle: 'none',
  },
  selected: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  label: {
    display: 'block',
  },
  inputRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  input: {
    width: 'clamp(200px,65vw,863px)',
    padding: '12px',
    border: 'solid 1px #b4b4b4',
  },
  listbox: {
    width: 'clamp(200px,65vw,863px)',
    margin: 0,
    padding: '0 4px',
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid #b4b4b4',
    borderTop: 0,
    '& li': {
      padding: '7px',
    },
    '& li[data-focus="true"]': {
      border: '0px',
      backgroundColor: '#b4b4b4',
      color: 'white',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    '& li:active': {
      border: '0px',
      backgroundColor: '#b4b4b4',
      color: 'white',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  },
  chipsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    background: '#eef0ff',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    padding: '3px',
    margin: theme.spacing(0.3),
    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '40vh',
    margin: '40px 0px',
    width: 'clamp(200px,65vw,863px)',
  },
  tabs: {
    border: `1px solid #b4b4b4`,
    marginRight: '30px',
  },
  tabItems: {
    border: `1px solid #b4b4b4`,
    overflowY: 'scroll',
    width: '90%',
  },
  submitRoot: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    right: '0%',
    bottom: '0%',
  },
}));

const PopupSearch = ({
  name,
  addKeywordChip,
  addContextSpec,
  closeModal,
  maxChips = 1,
  contextMaxChips = 1,
  inputPlaceholder = 'Start Typing...',
  headerText = 'Advanced Search Recommendation',
  buttonText = 'Add keywords',
  chipState = [],
  type = 'topics',
  addContext = false,
  compnayLabelSetter,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(chipState);
  const [inputVal, setInputVal] = useState('');
  const [tabVal, setTabVal] = useState(0);
  const [contextInput, setContextInput] = useState('');
  const [contextChips, setContextChips] = useState([]);
  const [error, setError] = useState('');
  const [expandTab, setExpandTab] = useState('');
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: option => JSON.stringify(option),
  });

  const getSearchOptions = type => {
    switch (type) {
      case 'vc':
        return [vcChildParent, vcParentChild, Object.keys(vcParentChild)];
      case 'topics':
        return [topicsChildParent, topicsParentChild, Object.keys(topicsParentChild)];
      case 'seniority':
        return [seniorityChildParent, seniorityParentChild, Object.keys(seniorityParentChild)];
      case 'technology':
        return [technologyChildParent, technologyParentChild, Object.keys(technologyParentChild)];
    }
  };

  let [options, menuOptions, menuParents] = getSearchOptions(type);
  let contextOptions = [
    'Sales',
    'Marketing',
    'Finance',
    'HR',
    'Product Management',
    'Rev Ops',
    'Demand Gen',
    'Operation',
    'Customer Success',
    'Customer Support',
  ];

  const handleChange = (event, value, reason) => {
    if (reason === 'input') {
      setInputVal(value);
    }
    if (reason === 'reset' && !selected.includes(value) && selected.length < maxChips) {
      if (name === 'IB5_cLabel') {
        findHierarchies(value);
      }
      setSelected([...selected, value]);
      setInputVal('');
    }
  };

  const handleContextInput = (event, value, reason) => {
    if (reason === 'input') {
      setContextInput(value);
    }
    if (
      reason === 'reset' &&
      !contextChips.includes(value) &&
      contextChips.length < contextMaxChips
    ) {
      setContextChips([...contextChips, value]);
      setContextInput('');
    }
  };

  const handleContextChip = event => {
    if (event.key === 'Enter' || event.key === undefined) {
      if (
        !contextChips.includes(contextInput) &&
        event.key === 'Enter' &&
        contextChips.length < contextMaxChips &&
        contextInput
      ) {
        setContextChips([...contextChips, contextInput]);
        setContextInput('');
      }
      if (contextChips.includes(contextInput)) {
        setError('Duplicate keywords not allowed');
        setTimeout(() => {
          setError('');
        }, 2000);
      }

      if (contextChips.length === contextMaxChips) {
        setError(`Reached max number of keywords allowed`);
        setTimeout(() => {
          setError('');
        }, 2000);
      }
    }
  };

  const contextDeleteChip = data => {
    const arr = contextChips;
    const index = arr.indexOf(data);
    if (index > -1) arr.splice(index, 1);
    setContextChips([...arr]);
  };

  const handleAddChip = event => {
    if (event.key === 'Enter' || event.key === undefined) {
      if (!selected.includes(inputVal) && event.key === 'Enter' && selected.length < maxChips) {
        if (name === 'IB5_cLabel') {
          findHierarchies(event.target.value);
        }
        setSelected([...selected, inputVal]);
        setInputVal('');
      }
      if (selected.includes(inputVal)) {
        setError('Duplicate keywords not allowed');
        setTimeout(() => {
          setError('');
        }, 2000);
      }

      if (selected.length === maxChips) {
        setError(`Reached max number of keywords allowed`);
        setTimeout(() => {
          setError('');
        }, 2000);
      }
    }
  };

  const handleDeleteChip = data => {
    const arr = selected;
    const index = arr.indexOf(data);
    if (index > -1) arr.splice(index, 1);
    setSelected([...arr]);
  };

  const tabChange = (e, newVal) => {
    setTabVal(newVal);
  };

  const handleTabClick = event => {
    if (!selected.includes(event.target.innerText) && selected.length < maxChips) {
      if (name === 'IB5_cLabel') {
        findHierarchies(event.target.innerText);
      }

      setSelected([...selected, event.target.innerText]);
      setInputVal('');
    }

    if (selected.includes(event.target.innerText)) {
      setError('Duplicate keywords not allowed');
      setTimeout(() => {
        setError('');
      }, 2000);
    }

    if (selected.length === maxChips) {
      setError(`Reached max number of keywords allowed`);
      setTimeout(() => {
        setError('');
      }, 2000);
    }
  };

  const addKeywordsToSearch = () => {
    if (addContext) {
      if (!contextChips.length) {
        setError('Please enter context');
        setTimeout(() => {
          setError('');
        }, 2000);
        return;
      }
    }
    if (selected.length) {
      addKeywordChip({ name: name, value: selected });
      addContextSpec(contextChips[0]);
      closeModal();
    }
  };

  const expandTabHandler = name => {
    if (expandTab === name) {
      setExpandTab('');
    }
    if (!expandTab || expandTab !== name) {
      setExpandTab(name);
    }
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue,
  } = useAutocomplete({
    id: 'use-autocomplete-demo',
    options: options,
    getOptionLabel: option => {
      // Value selected with enter, right from the input
      if (typeof option === 'string') {
        return option;
      }
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      // Regular option
      return option.name;
    },
    filterOptions: filterOptions,
    freeSolo: true,
    selectOnFocus: true,
    inputValue: inputVal,
    onInputChange: handleChange,
  });

  const contextAuto = useAutocomplete({
    options: contextOptions,
    getOptionLabel: option => {
      // Add "xxx" option created dynamically
      if (option.inputValue) {
        return option.inputValue;
      }
      return option;
    },
    freeSolo: true,
    selectOnFocus: true,
    inputValue: contextInput,
    onInputChange: handleContextInput,
  });

  function findHierarchies(value) {
    let hierarchy = [];
    for (let key in topicsParentChild) {
      topicsParentChild[key].forEach(a => {
        if (
          a.category.toLowerCase() === value.toLowerCase() ||
          a.children.some(match => match.category.toLowerCase() === value.toLowerCase())
        ) {
          if (value.toLowerCase() !== a.category.toLowerCase()) {
            hierarchy = [key, a.category, value];
          } else {
            hierarchy = [key, a.category];
          }
        }
      });
    }

    compnayLabelSetter(hierarchy.length > 0 ? hierarchy : [value]);
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {headerText}
        </Typography>
        <br />
        {error && (
          <Alert style={{ marginBottom: '20px' }} severity='error'>
            {error}
          </Alert>
        )}
        {/* Input Search */}
        <div>
          <div className={classes.inputRoot} {...getRootProps()}>
            <input
              onKeyDown={handleAddChip}
              placeholder={inputPlaceholder}
              className={classes.input}
              autoFocus
              {...getInputProps()}
            />
          </div>
          {groupedOptions.length > 0 && inputValue ? (
            <ul className={classes.listbox} {...getListboxProps()}>
              {groupedOptions.map((option, index) => (
                <li {...getOptionProps({ option, index })}>{option.name}</li>
              ))}
            </ul>
          ) : null}
        </div>
        {/* Tabs Menu */}
        <div className={classes.tabRoot}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            scrollButtons={false}
            value={tabVal}
            onChange={tabChange}
            className={classes.tabs}
            indicatorColor={'primary'}
          >
            {menuParents.map((cat, index) => (
              <Tab
                style={{
                  textTransform: 'none',
                  textAlign: 'left',
                }}
                label={cat}
                key={index}
                classes={{
                  wrapper: classes.wrapper,
                }}
              />
            ))}
          </Tabs>
          <div className={classes.tabItems}>
            {menuOptions[menuParents[tabVal]].map((i, index) =>
              RenderNested(handleTabClick, index, i, expandTab, expandTabHandler)
            )}
          </div>
        </div>
        {/* Chips */}
        <div className={classes.chipsRoot}>
          {selected.map((data, index) => {
            return (
              <li key={index}>
                <Chip
                  label={data}
                  variant='outlined'
                  onDelete={() => handleDeleteChip(data)}
                  className={classes.chip}
                  deleteIcon={
                    <DeleteIcon
                      name={data.name || data.Description}
                      onClick={() => handleDeleteChip(data)}
                    />
                  }
                />
              </li>
            );
          })}
          {/* Context Field and Chips */}
          {addContext && (
            <div>
              <label htmlFor='department' style={{ margin: '10px 0px' }}>
                Which department you sell to?
              </label>
              <div>
                <div className={classes.inputRoot} {...contextAuto.getRootProps()}>
                  <input
                    onKeyDown={handleContextChip}
                    placeholder='Marketing'
                    className={classes.input}
                    autoFocus
                    {...contextAuto.getInputProps()}
                  />
                </div>
                {contextAuto.groupedOptions.length > 0 && contextAuto.inputValue ? (
                  <ul className={classes.listbox} {...contextAuto.getListboxProps()}>
                    {contextAuto.groupedOptions.map((option, index) => (
                      <li {...contextAuto.getOptionProps({ option, index })}>{option}</li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
          )}
          <div className={classes.chipsRoot} style={{ margin: '10px 0px' }}>
            {addContext &&
              contextChips.map((data, index) => {
                return (
                  <li key={index}>
                    <Chip
                      label={data}
                      variant='outlined'
                      onDelete={() => contextDeleteChip(data)}
                      className={classes.chip}
                      deleteIcon={
                        <DeleteIcon
                          name={data.name || data.Description}
                          onClick={() => contextDeleteChip(data)}
                        />
                      }
                    />
                  </li>
                );
              })}
          </div>
        </div>
        {/* Button */}
        <div className={classes.submitRoot}>
          <hr />
          <div style={{ textAlign: 'right' }}>
            <Button color='primary' onClick={addKeywordsToSearch}>
              {buttonText}
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

const DeleteIcon = ({ name, onClick }) => (
  <img alt={`Delete ${name}`} src={deleteImg} onClick={onClick} style={{ cursor: 'pointer' }} />
);

const RenderNested = (handleTabClick, index, item, expandTab, expandTabHandler) => {
  return (
    <div>
      <ListItem button onClick={handleTabClick} key={index}>
        <ListItemText
          style={{ fontSize: '0.875rem' }}
          value={item.category}
          primary={item.category}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => expandTabHandler(item.category)} edge='end'>
            {item.children.length ? (
              expandTab === item.category ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : null}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      {item.children.length > 0 && (
        <Collapse in={expandTab === item.category} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {item.children.map((cat, index) => (
              <ListItem key={index} onClick={handleTabClick} button style={{ paddingLeft: '50px' }}>
                <ListItemText
                  style={{ fontSize: '0.875rem' }}
                  value={cat.category}
                  primary={cat.category}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </div>
  );
};

export default PopupSearch;
