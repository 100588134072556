import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  SALES_NAV_USER_SCRAPE,
  SALES_NAV_COMPANY_SCRAPE,
  GET_SNP_JOBS_PUBSUB,
  GET_SNC_JOBS_PUBSUB,
  GET_ENRICH_1,
  GET_ENRICH_2,
  GET_SNP_JOBS_PUBSUB_ENRICH_1,
  GET_SNP_JOBS_PUBSUB_ENRICH_2,
  STARTED,
  SELECTED,
  DONE,
  FETCH_TIMEOUT,
  NOTSTARTED,
  REVIEW_THRESHOLD_ENRICH_COUNT,
} from 'constants/ActionTypes';
import { auth } from '../firebase/firebase';

import {
  baseurl,
  baseSearchUrl,
  jobsRoute,
  getPlayBook,
  submitJobInput,
  jobsList,
  getJobResultDetails,
  salesNavSearch_t1,
  salesNavSearch_t2,
  searchPeopleNCompany,
  pullJobs_t1,
  pullJobs_t2,
  salesNavUsersSearch,
  enrichEmail,
  emailMobileEnrich,
  pullJobs_BusinessEnrich_E1,
  pullJobs_PersonalEnrich_E2,
  peopleSearchURL,
  companySearchURL,
} from 'util/urls';
import {
  submitCompanyJobSuccess,
  submitPeoplesJobSuccess,
  getSNCT1JobsPubSubSuccess,
  getSNCT2JobsPubSubSuccess,
  getSNCT1JobsPubSubFailed,
  getSNCT2JobsPubSubFailed,
  saveSNCompanyPlaybookInfo,
  submitSNCompanyPlaybookSaveJobId,
  saveSNPeoplePlaybookInfo,
  submitSNPeoplePlaybookSaveJobId,
  getSNPT1JobsPubSubSuccess,
  getSNPT2JobsPubSubSuccess,
  getSNPT1JobsPubSubFailed,
  getSNPT2JobsPubSubFailed,
  getJobDetailsSuccess,
  displayMessage,
  enrich1AddJobIds,
  enrich2AddJobIds,
  getSNPJobsPubSubEnrich1Success,
  getSNPJobsPubSubEnrich2Success,
} from 'actions/jobs';
import {
  contactRecordsModifyResult,
  setEnrichmentPayload,
  enrichBusiness1,
  enrichPersonal2,
  isEmpty,
  mimicCompanySearchResult,
} from '../util';

const getPeopleListNames = state => state.contacts.peopleListNames;
const getCompanyListNames = state => state.contacts.companyListNames;
const getUserDetails = state => state.auth.userDetails;
let prevlen = 0; // for mimicing company search response

const getAuthToken = async () => await auth.currentUser.getIdToken();

const playBookSubmitAPI = async (payload, token) => {
  try {
    var form = new FormData();
    const blob = new Blob([JSON.stringify(payload.body)], { type: 'application/json' });
    form.append('file', blob);
    form.append('pid', payload.pid);
    // form.append("fields",JSON.stringify(payload.body));
    // form.append("uid", localStorage.getItem('user_id'));

    return await fetch(baseurl + jobsRoute + submitJobInput, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
      body: form,
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

const getJobsDetailsAPI = async (jid, token) => {
  try {
    return await fetch(baseurl + jobsRoute + getJobResultDetails + '/' + jid, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

/* const jobResultSubmitAPI = async (body, jid, token) => {
    try {
        var form = new FormData();
        const blob = new Blob([JSON.stringify(body)], { type: 'application/json' })
        form.append('file', blob);
        form.append("jid", jid);
        form.append("uid", localStorage.getItem('user_id'));

        return await fetch(baseurl + jobsRoute + submitJobEndResult, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        }).then(async (response) => {
            return await response.json();
        }).catch(error => error);

    } catch (error) {
        return error;
    }
}
 */

/**
 * Type 1
 * @param {*} payload
 * @param {*} token
 */
const getJobsFromPubSubAPI_t1 = async (payload, token) => {
  try {
    var form = new FormData();
    form.append('pageNumber', payload.pageNumber);
    form.append('jobId', payload.jobId);
    return await fetch(baseurl + jobsRoute + pullJobs_t1, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
      body: form,
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 *Type 2
 * @param {*} payload
 * @param {*} token
 */
const getJobsFromPubSubAPI_t2 = async (payload, token) => {
  try {
    var form = new FormData();
    form.append('pageNumber', payload.pageNumber);
    form.append('jobId', payload.jobId);
    return await fetch(baseurl + jobsRoute + pullJobs_t2, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
      body: form,
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 * Type 1 search
 * @param {*} payload
 * @param {*} token
 */
const salesNavScrapeAPI_t1 = async (payload, token) => {
  try {
    let callURL = baseurl + jobsRoute + salesNavSearch_t1;
    // var form = new FormData();
    // form.append("jobInput", JSON.stringify(payload));

    return await fetch(callURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ jobInput: [payload] }),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

/**
 * Type 2 search
 * @param {*} payload
 * @param {*} token
 * instead using salesNavSearch_test_t2
 */
/* const salesNavScrapeAPI_t2 = async (payload, token) => {
    try {

        let methodType = payload.task == "G2.0"
            ? "SALES_NAV_PEOPLE_SEARCH"
            : "SALES_NAV_COMPANY_SEARCH";

        let callURL = baseurl + jobsRoute + salesNavSearch_t2;
        var form = new FormData();
        form.append("jobInput", JSON.stringify(payload));
        form.append("methodType", methodType);

        return await fetch(callURL, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
            }),
            body: form
        }).then(async (response) => {
            return await response.json();
        }).catch(error => error);

    } catch (error) {
        return error;
    }
} */

/** */
const salesNavScrapeAPI_t2 = async (payload, token) => {
  try {
    /*  let methodType = payload.task == "G2.0"
            ? "SALES_NAV_PEOPLE_SEARCH"
            : "SALES_NAV_COMPANY_SEARCH";
 */
    let callURL = baseurl + jobsRoute + searchPeopleNCompany;
    // var form = new FormData();
    // form.append("jobInput", JSON.stringify(payload));
    // form.append("methodType", methodType);

    return await fetch(callURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

/* search controller api */
const searchControllerAPI_t2 = async (payload, token) => {
  try {
    let callURL;

    if (payload.mode.toLowerCase() == 'people') callURL = baseSearchUrl + peopleSearchURL;
    else if (payload.mode.toLowerCase() == 'company') callURL = baseSearchUrl + companySearchURL;

    return await fetch(callURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

/**Enrich API */

export async function getEnrichDetailsAPI_1(payload, token) {
  let didTimeOut = false;
  let callURL = baseurl + jobsRoute + enrichEmail;
  // var form = new FormData();
  // form.append("jobInput", JSON.stringify(payload));

  return new Promise(async function(resolve, reject) {
    const timeout = setTimeout(function() {
      didTimeOut = true;
      reject('Request timed out');
    }, FETCH_TIMEOUT);

    //API call
    await fetch(callURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        clearTimeout(timeout);

        if (!didTimeOut) {
          let responseJson = await response.json();
          resolve(responseJson);
        }
      })
      .catch(function(err) {
        console.log('fetch failed! ', err);

        // Rejection already happened with setTimeout
        if (didTimeOut) return;

        // Reject with error
        reject(err);
      });
  })
    .then(function(response) {
      // Request success and no timeout
      console.log('good promise, no timeout! ');
      return response;
    })
    .catch(function(err) {
      // Error: response error, request timeout or runtime error
      console.log('promise error! ', err);
      let response = {};

      if (err == 'Request timed out') {
        response.timedOut = true;
        response.data = payload;
        return response;
      } else {
        response.error = err;
        response.data = payload;
        return response;
      }
    });
}

export async function getEnrichDetailsAPI_2(payload, token) {
  let didTimeOut = false;
  let callURL = baseurl + jobsRoute + emailMobileEnrich;
  // var form = new FormData();
  // form.append("jobInput", JSON.stringify(payload));

  return new Promise(async function(resolve, reject) {
    const timeout = setTimeout(function() {
      didTimeOut = true;
      reject('Request timed out');
    }, FETCH_TIMEOUT);

    //API call
    return await fetch(callURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        clearTimeout(timeout);

        if (!didTimeOut) resolve(await response.json());

        return await response.json();
      })
      .catch(function(err) {
        console.log('fetch failed! ', err);

        // Rejection already happened with setTimeout
        if (didTimeOut) return;

        // Reject with error
        reject(err);
      });
  })
    .then(function(response) {
      // Request success and no timeout
      console.log('good promise, no timeout! ');
      return response;
    })
    .catch(function(err) {
      // Error: response error, request timeout or runtime error
      console.log('promise error! ', err);
      let response = {};

      if (err == 'Request timed out') {
        response.timedOut = true;
        response.data = payload;
        return response;
      } else {
        response.error = err;
        response.data = payload;
        return response;
      }
    });
}

async function getSNPJobsPubSubEnrichDetailsAPI_1(payload, token) {
  try {
    let callURL = baseurl + jobsRoute + pullJobs_BusinessEnrich_E1;
    var form = new FormData();
    form.append('jobIds', JSON.stringify(payload));
    return await fetch(callURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
      body: form,
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
}

async function getSNPJobsPubSubEnrichDetailsAPI_2(payload, token) {
  try {
    let callURL = baseurl + jobsRoute + pullJobs_PersonalEnrich_E2;
    var form = new FormData();
    form.append('jobIds', JSON.stringify(payload));
    return await fetch(callURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
      body: form,
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
}

function* getJobsDetails({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(getJobsDetailsAPI, payload, token);
    if (response.statusCode == 200) {
      yield put(getJobDetailsSuccess(response.data));
    } else yield put(displayMessage(response.message));
  } catch (error) {
    yield put(displayMessage(error.message));
  }
}

/**
 *
 * Using for
 * sales people T1 and T2
 * sales company T1 and T2
 * this will be called everytime when result is exausted for t2
 */
function* salesNavScrapeSubmit({ payload }) {
  try {
    console.log(payload);
    const token = yield call(getAuthToken);
    let response;
    if (payload.type == 'T1') response = yield call(salesNavScrapeAPI_t1, payload, token);
    else if (payload.type == 'T2') response = yield call(searchControllerAPI_t2, payload, token);

    if (response.statusCode == 200) {
      // mimicing response through dummy data
      // let len = Math.floor(Math.random() * (200 - 20)) + 20;
      // response = mimicCompanySearchResult(prevlen + len, response);
      // prevlen += len;

      let searchDeatils = {
        searchJobId: response.meta?.jobId || response.data.jobId,
        searchType: payload.type,
        taskName: payload.mode,
        task: payload.task,
        isSearchGoingOn: true,
        searchIterationsMax: 1, // (Only used for for T1. For T2 it is 1)
        searchIterationsDoneCount:
          payload.mode == 'People'
            ? parseInt(payload.peopleSearch.page) - 1
            : parseInt(payload.companySearch.page) - 1,
        payloadForm: payload,
        timeStamp: new Date(),
      };

      if (payload.mode == 'People') {
        // people sn search
        yield put(submitPeoplesJobSuccess(searchDeatils));

        if (payload.type == 'T2') {
          //T2 without pull jobs
          const peopleListNames = yield select(getPeopleListNames);
          console.log(peopleListNames);

          let resultObj = response.data.t2Data;

          const userDetails = yield select(getUserDetails);

          let formattedDetails = contactRecordsModifyResult(
            'people',
            resultObj.peopleJSON,
            payload.type,
            peopleListNames,
            userDetails
          );

          resultObj.peopleJSON = formattedDetails;

          yield put(getSNPT2JobsPubSubSuccess(resultObj));
        }
      } else {
        //company search
        yield put(submitCompanyJobSuccess(searchDeatils));
        if (payload.type == 'T2') {
          //T2 without pull jobs
          const companyListNames = yield select(getCompanyListNames);
          const userDetails = yield select(getUserDetails);

          console.log(companyListNames);

          let resultObj = {
            companySearch: payload.companySearch,
            jobId: response.meta.jobId,
            mode: payload.mode,
            type: payload.type,
            uid: userDetails.uid,
          };

          let formattedDetails = contactRecordsModifyResult(
            'company',
            response.data,
            payload.type,
            companyListNames,
            userDetails
          );
          resultObj.companyJSON = formattedDetails;

          yield put(getSNCT2JobsPubSubSuccess(resultObj));
        }
      }
    } else {
      throw new Error('status is other than 200');
    }
  } catch (error) {
    let searchDeatils = {
      searchJobId: '',
      searchType: payload.type,
      taskName: payload.mode,
      task: payload.task,
      isSearchGoingOn: false,
      searchIterationsMax: 1, // (Only used for for T1. For T2 it is 1)
      searchIterationsDoneCount:
        payload.mode == 'People'
          ? parseInt(payload.peopleSearch.page) - 1
          : parseInt(payload.companySearch.page) - 1,
      payloadForm: payload,
      timeStamp: new Date(),
    };

    if (payload.mode == 'People') yield put(getSNPT2JobsPubSubFailed(searchDeatils));
    else yield put(getSNCT2JobsPubSubFailed(searchDeatils));
    // yield put(displayMessage(error.message));
  }
}

/**SNP */

function* snPeoplePlaybookSubmit({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(playBookSubmitAPI, payload, token);
    if (response.statusCode == 200) {
      yield put(submitSNPeoplePlaybookSaveJobId(response.data.job_code));
    } else yield put(displayMessage(response.message));
  } catch (error) {
    yield put(displayMessage(error.message));
  }
}

/**
 * Using for
 * sales nav people search T1 and T2
 * @param {*} param0
 */
function* getSNPJobsFromPubSub({ payload }) {
  try {
    let response;

    const peopleListNames = yield select(getPeopleListNames);
    console.log(peopleListNames);

    const token = yield call(getAuthToken);
    let { searchType, jobId, pageNumber } = payload;
    if (searchType == 'T1')
      response = yield call(getJobsFromPubSubAPI_t1, { jobId, pageNumber }, token);
    else response = yield call(getJobsFromPubSubAPI_t2, { jobId, pageNumber }, token);

    if (response.statusCode == 200 && isEmpty(response.data.errorTrace)) {
      const userDetails = yield select(getUserDetails);

      let formattedDetails = contactRecordsModifyResult(
        'people',
        response.data.peopleJSON,
        searchType,
        peopleListNames,
        userDetails
      );
      response.data.peopleJSON = formattedDetails;

      if (searchType == 'T1') yield put(getSNPT1JobsPubSubSuccess(response.data));
      else yield put(getSNPT2JobsPubSubSuccess(response.data));
    } else if (response.statusCode == 200 && searchType == 'T1')
      //failure
      yield put(getSNPT1JobsPubSubFailed(response.data));
    else if (response.statusCode == 200 && searchType == 'T2')
      //failure
      yield put(getSNPT2JobsPubSubFailed(response.data));
  } catch (error) {
    console.log(error);
    yield put(displayMessage(error.message));
  }
}

/**SNC */

/**
 * Usin now
 * @param {*} param0
 */
function* getSNCJobsFromPubSub({ payload }) {
  try {
    let response;

    const companyListNames = yield select(getCompanyListNames);
    console.log(companyListNames);

    const token = yield call(getAuthToken);
    let { searchType, jobId, pageNumber } = payload;

    if (searchType == 'T1')
      response = yield call(getJobsFromPubSubAPI_t1, { jobId, pageNumber }, token);
    else response = yield call(getJobsFromPubSubAPI_t2, { jobId, pageNumber }, token);

    if (response.statusCode == 200 && isEmpty(response.data.errorTrace)) {
      const userDetails = yield select(getUserDetails);

      let formattedDetails = contactRecordsModifyResult(
        'company',
        response.data.companyJSON,
        searchType,
        companyListNames,
        userDetails
      );
      response.data.companyJSON = formattedDetails;

      if (searchType == 'T1') yield put(getSNCT1JobsPubSubSuccess(response.data));
      else yield put(getSNCT2JobsPubSubSuccess(response.data));
    } else if (response.statusCode == 200 && searchType == 'T1')
      //failure
      yield put(getSNCT1JobsPubSubFailed(response.data));
    else if (response.statusCode == 200 && searchType == 'T2')
      //failure
      yield put(getSNCT2JobsPubSubFailed(response.data));
  } catch (error) {
    console.log('getSNCJobsFromPubSub', error.message);
    yield put(displayMessage(error.message));
  }
}

/**Enrich */
/* function* getEnrichDetails_1({ payload }) {
    try {

         //all job details
         console.log(payload);

         //required payload
        let  reqPayloadFormat = setEnrichmentPayload(payload,enrichBusiness1.pid);

        console.log("reqPayloadFormat",reqPayloadFormat);

        const token = yield call(getAuthToken);
        let response = yield call(getEnrichDetailsAPI_1, reqPayloadFormat, token);
        if (response.statusCode == 200) {
            let relJobId = 0;
            payload.map(item => {
                if(item.enrichmentDetails.isEnrichmentGoingOn == STARTED)
                {    item.enrichmentDetails.enrichmentJobId = response.data.job_code_list[relJobId];
                    relJobId++;
                }
            })


            yield put(enrich1AddJobIds(payload));

        } else
            yield put(displayMessage(response.message));

    } catch (error) {
        console.log("getEnrichDetails_1 error", error);
        yield put(displayMessage(error));
    }
}


function* getEnrichDetails_2({ payload }) {
    try {
        
        //all job details
        console.log(payload);

        //required payload
        let  reqPayloadFormat = setEnrichmentPayload(payload,enrichPersonal2.pid);

        console.log("reqPayloadFormat",reqPayloadFormat);
        
        const token = yield call(getAuthToken);
        let response = yield call(getEnrichDetailsAPI_2, reqPayloadFormat, token);
        if (response.statusCode == 200) {

            let relJobId = 0;
            payload.map(item => {
                if(item.enrichmentDetails.isEnrichmentGoingOn == STARTED)
                {    item.enrichmentDetails.enrichmentJobId = response.data.job_code_list[relJobId];
                    relJobId++;
                }
            })

            yield put(enrich2AddJobIds(payload));
        } else
            yield put(displayMessage(response.message));

    } catch (error) {
        yield put(displayMessage(error));
        console.log("getEnrichDetails_2 error", error);
    }
} */

function* getEnrichDetails_test_1({ payload }) {
  try {
    // let isNoDataFound = false;

    //payload contains all job details

    //required payload
    let reqPayloadFormat = setEnrichmentPayload(payload, enrichBusiness1.pid);

    console.log('reqPayloadFormat', reqPayloadFormat);

    const token = yield call(getAuthToken);

    //CHANGE STATUS OF PAYLOAD isEnrichmentGoingOn TO STARTED
    payload = payload.map(item => {
      if (item.enrichmentDetails.isEnrichment_E1_GoingOn == SELECTED) {
        item.enrichmentDetails.isEnrichment_E1_GoingOn = STARTED;
        return item;
      }
      return item;
    });

    yield put(enrich1AddJobIds(payload));

    //ASYNCHRONOUSLY CALLING ENRICHMENT FOR CONTACTS RESULT
    yield Promise.all(
      reqPayloadFormat.map(async reqPayload => {
        let response = await getEnrichDetailsAPI_1(reqPayload, token);
        console.log(response.data.data);
        if (response.statusCode == 200) {
          let relJobId = 0;
          payload.map(item => {
            let firstOne = response.data.data;
            if (
              item.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED &&
              item.contactRecord.lid == firstOne.lid
            ) {
              item.enrichmentDetails.enrichmentJobId = response.data.job_code_list[relJobId];
              item.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;
              item.enrichmentDetails.reviewCount = item.enrichmentDetails.reviewCount + 1;

              item.contactRecord.bEmail = firstOne.bEmail;
              item.contactRecord.bPhone = firstOne.bPhone;
              item.contactRecord.website = firstOne.website;

              item.identity.recordId = firstOne.identity.recordId;
              item.identity.status = firstOne.identity.status;

              relJobId++;
            }
          });

          //time out or api error
        } else {
          let firstOne;
          /*  if (response.timedOut == true)
                    firstOne = response.data[0];
                else
                    firstOne = response.data; */

          firstOne = response.data;
          payload.map(item => {
            if (
              item.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED &&
              item.contactRecord.lid == firstOne.lid
            ) {
              item.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;
              item.enrichmentDetails.reviewCount = REVIEW_THRESHOLD_ENRICH_COUNT;
            }
          });
          throw new Error(response.message || 'Something went wrong');
        }
      })
    );

    yield put(enrich1AddJobIds(payload));
  } catch (error) {
    console.log('getEnrichDetails_test_1 error', error.message);
    yield put(displayMessage(error.message));
    yield put(enrich1AddJobIds(payload));
  }
}

//payload contains all job details
//it has asyncronous enrich calls

function* getEnrichDetails_test_2({ payload }) {
  try {
    // let isNoDataFound = false;
    //required payload
    let reqPayloadFormat = setEnrichmentPayload(payload, enrichPersonal2.pid);

    console.log('reqPayloadFormat', reqPayloadFormat);

    const token = yield call(getAuthToken);

    //CHANGE STATUS OF PAYLOAD isEnrichmentGoingOn TO STARTED
    payload = payload.map(item => {
      if (item.enrichmentDetails.isEnrichment_E2_GoingOn == SELECTED)
        item.enrichmentDetails.isEnrichment_E2_GoingOn = STARTED;

      if (item.enrichmentDetails.isEnrichment_E1_GoingOn == SELECTED)
        item.enrichmentDetails.isEnrichment_E1_GoingOn = STARTED;

      return item;
    });

    yield put(enrich2AddJobIds(payload));

    yield Promise.all(
      reqPayloadFormat.map(async reqPayload => {
        let response = await getEnrichDetailsAPI_2(reqPayload, token);

        console.log('getEnrichDetails_test_2', response);

        if (response.statusCode == 200) {
          let relJobId = 0;
          payload.map(item => {
            //we get alteast what we had sent to the api.

            let firstOne = response.data.data; //personal

            if (item.contactRecord.lid == firstOne.lid) {
              item.enrichmentDetails.enrichmentJobId = response.data.job_code_list[relJobId];
              item.enrichmentDetails.reviewCount = item.enrichmentDetails.reviewCount + 1;

              if (item.enrichmentDetails.isEnrichment_E2_GoingOn == STARTED) {
                //there will only two records one is for business and one for personal
                item.contactRecord.pEmail = firstOne.pEmail;
                item.contactRecord.pPhone = firstOne.pPhone;
                item.enrichmentDetails.isEnrichment_E2_GoingOn = DONE;

                item.identity.recordId = firstOne.identity.recordId;
                item.identity.status = firstOne.identity.status;
              }

              //sometimes standard credits may be exhausted. if so, we are modifuing isOnlyE2Required to true in the api
              if (
                item.enrichmentDetails.isOnlyE2Required ==
                  firstOne.enrichmentDetails.isOnlyE2Required &&
                !item.enrichmentDetails.isOnlyE2Required
              ) {
                // let secondOne = response.data.data[1][0].data;//business
                //there will only two records one is for business and one for personal
                item.contactRecord.bEmail = firstOne.bEmail;
                item.contactRecord.bPhone = firstOne.bPhone;
                item.contactRecord.website = firstOne.website;
                item.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;
              } else if (
                !item.enrichmentDetails.isOnlyE2Required && // E1 and E2
                item.enrichmentDetails.isEnrichment_E1_GoingOn != DONE
              ) {
                item.enrichmentDetails.isEnrichment_E1_GoingOn = NOTSTARTED;
              }
            }
          });

          //time out or api error
        } else {
          let firstOne;
          /* if (response.timedOut == true)
                    firstOne = response.data[0];
                else
                    firstOne = response.data; */

          firstOne = response.data;

          payload.map(item => {
            if (item.contactRecord.lid == firstOne.lid) {
              item.enrichmentDetails.reviewCount = REVIEW_THRESHOLD_ENRICH_COUNT;

              if (item.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED)
                item.enrichmentDetails.isEnrichment_E1_GoingOn = DONE;

              if (item.enrichmentDetails.isEnrichment_E2_GoingOn == STARTED)
                item.enrichmentDetails.isEnrichment_E2_GoingOn = DONE;
            }
          });

          throw new Error(response.message || 'Something went wrong');
        }
      })
    );

    yield put(enrich2AddJobIds(payload));
  } catch (error) {
    console.log('getEnrichDetails_test_2 error', error.message);
    yield put(displayMessage(error.message));
    yield put(enrich2AddJobIds(payload));
  }
}

//enrich pull jobs
function* getSNPJobsPubSubEnrichDetails_1({ payload }) {
  try {
    const token = yield call(getAuthToken);

    let response = yield call(getSNPJobsPubSubEnrichDetailsAPI_1, payload, token);

    if (response.statusCode == 200) {
      console.log('getSNPJobsPubSubEnrichDetails_1 result', response.data);
      yield put(getSNPJobsPubSubEnrich1Success(response.data)); //array
    }

    /*   else if (response.statusCode == 200)  //failure
              yield put(getSNPT1JobsPubSubFailed(response.data)); */
  } catch (error) {
    yield put(displayMessage(error.message));
  }
}

//enrich pull jobs

function* getSNPJobsPubSubEnrichDetails_2({ payload }) {
  try {
    const token = yield call(getAuthToken);

    let response = yield call(getSNPJobsPubSubEnrichDetailsAPI_2, payload, token);

    if (response.statusCode == 200) {
      console.log('getSNPJobsPubSubEnrichDetails_2 result', response.data);
      yield put(getSNPJobsPubSubEnrich2Success(response.data));
    }

    /*  else if (response.statusCode == 200)  //failure
             yield put(getSNPT1JobsPubSubFailed(response.data)); */
  } catch (error) {
    yield put(displayMessage(error.message));
  }
}

export function* salesUserNavScrape() {
  yield takeEvery(SALES_NAV_COMPANY_SCRAPE, salesNavScrapeSubmit);
}

export function* salesCompanyNavScrape() {
  yield takeEvery(SALES_NAV_USER_SCRAPE, salesNavScrapeSubmit);
}

/**SNP */
export function* getSNPJobsPubSub() {
  yield takeEvery(GET_SNP_JOBS_PUBSUB, getSNPJobsFromPubSub);
}

/**SNC */
export function* getSNCJobsPubSub() {
  yield takeEvery(GET_SNC_JOBS_PUBSUB, getSNCJobsFromPubSub);
}

/**Enrichment */

export function* getEnrich_1() {
  yield takeEvery(GET_ENRICH_1, getEnrichDetails_test_1);
}

export function* getEnrich_2() {
  yield takeEvery(GET_ENRICH_2, getEnrichDetails_test_2);
}

export function* getSNPJobsPubSubEnrich_1() {
  yield takeEvery(GET_SNP_JOBS_PUBSUB_ENRICH_1, getSNPJobsPubSubEnrichDetails_1);
}

export function* getSNPJobsPubSubEnrich_2() {
  yield takeEvery(GET_SNP_JOBS_PUBSUB_ENRICH_2, getSNPJobsPubSubEnrichDetails_2);
}

export default function* rootSaga() {
  yield all([
    //snp
    fork(getSNPJobsPubSub),

    //snp
    fork(getSNCJobsPubSub),

    //enrichment
    fork(getEnrich_1),
    fork(getEnrich_2),
    fork(getSNPJobsPubSubEnrich_1),
    fork(getSNPJobsPubSubEnrich_2),

    fork(salesUserNavScrape),
    fork(salesCompanyNavScrape),
  ]);
}
