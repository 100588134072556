import {
    Button,
    InputBase,
    Modal,
    Select,
    MenuItem,
    FormControl,
    Typography
} from '@material-ui/core';
import InputField from 'components/Input';
// import { Download } from 'iconoir-react';
import React, { useState } from 'react';
import XLSX, { utils, writeFileXLSX } from 'xlsx';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const BootstrapInput = withStyles(theme => ({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: '100%',
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: 'blue',
      },
    },
  }))(InputBase);
  
  
  const useStyles = makeStyles(theme=> ({
      modal: {
          display: "flex",
          justifyContent:'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          "& .modal_body": {
              height: 'auto',
              width: '400px',
              padding: '20px',
              borderRadius: '4px',
              background: '#fff',
              "& .MuiFormControl-root": {
                  width: '100%',
                  marginBottom: '20px'
              }
          }
      }
  }))

const DownloadTableData = ({ data, columns }) => {
    const classes = useStyles();
    const [state, setState] = useState(false);
    const [fileName, setFileName] = useState('output');

    const [fileType, setFileType] = useState('');

    const handler = () => setState(true);

    const closeHandler = (event) => {
        setState(false);
    };

    const handleFileType = (val) => {
        setFileType(val.target.value)
    };

    const exportDataFn = () => {
        const fieldsToRemove = ['qid', 'questionType', 'aid', 'q', 'a'];
        const dataArray = data.map((obj) => {
            const newObj = { ...obj }; // Create a copy of the object

            // Remove the specified fields from the object
            fieldsToRemove.forEach((field) => delete newObj[field]);

            return newObj; // Return the updated object
        });
        if (fileType === 'JSON') {
            const dataString = JSON.stringify(dataArray, null, 2);

            const blob = new Blob([dataString], {
                type: 'application/json',
            });

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'example';
            link.click();

            window.URL.revokeObjectURL(link.href);
        }

        if (fileType === 'CSV') {
            const items = dataArray;
            const replacer = (key, value) => (value === null ? '' : value); // Handle null values

            const header = Object.keys(items[0]);
            let csv = header.join(',') + '\r\n'; // Create CSV header row

            csv += items
                .map((item) => {
                    return header
                        .map((fieldName) =>
                            JSON.stringify(item[fieldName], replacer)
                        )
                        .join(',');
                })
                .join('\r\n');

            const csvData = new Blob([csv], {
                type: 'text/csv;charset=utf-8;',
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(csvData);
            link.download = 'untitled';
            link.click();
            window.URL.revokeObjectURL(link.href);
        }

        if (fileType === 'EXCEL') {
            // generate worksheet using data with the order specified in the columns array
            const ws = utils.json_to_sheet(dataArray, {
                header: columns.map((c) => c.id || c.title),
                dense: true,
            });
            // rewrite header row with titles
            utils.sheet_add_aoa(ws, [columns.map((c) => c.title || c.id)], {
                origin: 'A1',
            });
            // create workbook
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, 'Export'); // replace with sheet name
            // download file
            // XLSX.stream.to_csv(wb);
            writeFileXLSX(wb, 'output.xlsx');
        }
    };

    return (
        <>
            <Button variant='contained' size='small' onClick={() => handler()}>
                {/* <Download /> */}
                <span>Download</span>
            </Button>
            <Modal className={classes.modal} open={state} onClose={closeHandler} width="400px">
                <div className="modal_body">
                    <div className="text-center">
                        <h2 style={{fontWeight: '600'}}>
                            Export Data
                        </h2>
                        <p style={{fontSize: '14px'}}>
                            Into CSV, JSON, EXCEL format with one click.
                        </p>
                    </div>

                    <FormControl>
                        <InputField
                            placeholder="Give a file name"
                            value={fileName}
                            onChange={(e) => setFileName(e.target.value)}
                        />
                    </FormControl>

                    <FormControl>
                        <Select
                        input={<BootstrapInput />}
                        onChange={handleFileType}
                        value={fileType}
                        >
                            <MenuItem value="CSV">CSV</MenuItem>
                            <MenuItem value="JSON">JSON</MenuItem>
                            <MenuItem value="EXCEL">Excel</MenuItem>
                        </Select>
                    </FormControl>
                    
                    <div className='text-center'>
                        <Button
                            variant='contained'
                            color='primary'
                            disabled={fileType === ''}
                            onClick={exportDataFn}
                        >
                            Download {fileType} File
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DownloadTableData;
