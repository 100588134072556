import React from 'react';
import { List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import InfoTip from '../../components/InfoTip';

const NavMenuItem = props => {
  const { name, icon, link } = props;

  return (
    <List
      component='div'
      className='nav-menu-item'
      data-for={name}
      data-tip={name}
      data-iscapture='true'
    >
      <NavLink
        className='prepend-icon nav-menu-link'
        style={{ paddingLeft: name === 'Integration' && '12px' }}
        to={link}
      >
        {/* Display an icon if any */}
        {!!icon && <img src={icon} />}
        <span className='nav-text'>{name}</span>
      </NavLink>
      <InfoTip id={name} place='right' />
    </List>
  );
};

export default NavMenuItem;
