import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  Button,
  IconButton as MuiIconButton,
  Typography,
  makeStyles,
  Box,
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FilterListPopOver from '../../app/routes/filterListPopover';
import Checkbox from '@material-ui/core/Checkbox';
import Score from 'components/Score';
import NotesIcon from '@material-ui/icons/Notes';
import InfoTip from 'components/InfoTip';
import InteractivePopper from 'components/InteractivePopper';
// import AutoCompleteListName from  "components/AutoCompleteList";

const tick = require('../../assets/images/doubletick.png');
const warning = require('../../assets/images/warning.png');

const useStyles = makeStyles(theme => ({
  secondaryLink: {
    textTransform: 'capitalize',
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 400,
    display: 'inline-block',
    borderBottom: `1px solid #000`,
    cursor: 'pointer',
  },
  tableData: {
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  tableDataContent: {
    width: '100%',
    '& .__react_component_tooltip': {
      maxWidth: 200,
      textAlign: 'center',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      fontSize: 12,
    },
  },
  tableDataAction: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: `${theme.spacing(1)}px`,
  },
  textUnderline: {
    display: 'inline-block',
    borderBottom: '1px solid',
    fontWeight: 600,
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
}));

function PeopleResultListTable({
  result,
  showFilter,
  filterList,
  autoCompleteData,
  filterType,
  handleFilterSelect,
  selectionAvailable,
  onAllCheckChange,
  onindividualCheckChange,
  onClickListItem,
  moreInfo,
  itemClick,
  autoSelected,
  autonewItemCreated,
}) {
  const [fields, setFields] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let indexHasMoreProperties = 0;
    result.map((resultItem, i) => {
      let keysLength = Object.keys(resultItem).length;
      if (keysLength > indexHasMoreProperties) indexHasMoreProperties = i;
    });

    if (result && result.length > 0) {
      let keys = Object.keys(result[indexHasMoreProperties]);
      if (selectionAvailable) keys.splice(keys.indexOf('checked'), 1);

      if (keys.indexOf('id') > -1) keys.splice(keys.indexOf('id'), 1);
      setFields(keys);
    }
  }, [result]);

  const handleSelectedFilter = useCallback(type => {
    handleFilterSelect(type);
  }, []);

  const onClickRowItem = rowItem => {
    if (onClickListItem != null) onClickListItem(rowItem);
  };

  const dropDownSelected = (obj, selectedListObj) => {
    if (autoSelected) {
      autoSelected(obj, selectedListObj);
    }
  };

  const dropDownNewItemCreated = (obj, lnm) => {
    if (autonewItemCreated) autonewItemCreated(obj, lnm);
  };

  const checkHowManySelected = () => result.filter(item => item.checked.value);

  const displayText = (obj, field, rowIndex, fieldIndex) => {
    try {
      switch (obj[field].type) {
        case 'text':
          return (
            <div className={`${classes.tableData} align-middle`}>
              <Typography className={classes.tableDataContent} component='div'>
                <Box fontWeight='fontWeightBold' fontSize='body2.fontSize'>
                  {obj[field].value}
                </Box>

                {obj[field].hintText != null && (
                  <>
                    <Box
                      fontSize={12}
                      mb={0.5}
                      width={200}
                      data-for={`nameHintText-${rowIndex}${fieldIndex}`}
                      data-tip={obj[field].hintText}
                    >
                      {`${obj[field].hintText.slice(0, 25)}`}
                      {obj[field].hintText.length > 25 && ' ...'}
                    </Box>
                    {obj[field].hintText.length > 25 && (
                      <InfoTip id={`nameHintText-${rowIndex}${fieldIndex}`} place='bottom' />
                    )}
                  </>
                )}
              </Typography>

              <div className={classes.tableDataAction}>
                {obj[field].hasOwnProperty('titleScore') && <Score score={obj[field].titleScore} />}
                {obj[field].hasOwnProperty('notes') && (
                  <MuiIconButton
                    aria-label='notes-icon'
                    size='small'
                    onClick={() => itemClick(obj, obj[field].clickType)} // clickType
                  >
                    <NotesIcon />
                  </MuiIconButton>
                )}
              </div>
            </div>
          );

        case 'info':
          return (
            <div className={`${classes.tableData} align-middle`}>
              <Typography className={classes.tableDataContent} component='div'>
                <Box
                  fontWeight='fontWeightBold'
                  fontSize='body2.fontSize'
                  width={200}
                  data-for={`companyNameText-${rowIndex}${fieldIndex}`}
                  data-tip={obj[field].value}
                >
                  {obj[field].value === '' ? 'Sorry! no data found' : obj[field].value.slice(0, 20)}
                  {obj[field].value.length > 20 && ' ...'}
                </Box>
                {obj[field].value.length > 20 && (
                  <InfoTip id={`companyNameText-${rowIndex}${fieldIndex}`} place='bottom' />
                )}
                <Box
                  fontSize={12}
                  mb={0.5}
                  width={200}
                  data-for={`companyInfoText-${rowIndex}${fieldIndex}`}
                  data-tip={obj[field].info}
                >
                  {field === 'Company' && obj[field].info === '' && 'No Website Captured'}
                  {obj[field].hintText || '' + ' '}{' '}
                  <span className='text-primary'>
                    <u>
                      {obj[field].info.slice(0, 30)}
                      {obj[field].info.length > 30 && '...'}
                    </u>
                  </span>
                </Box>
                {obj[field].info.length > 30 && (
                  <InfoTip id={`companyInfoText-${rowIndex}${fieldIndex}`} place='bottom' />
                )}
              </Typography>
              {/* Add proper checks to display below div conditionally */}
              <div className={classes.tableDataAction}>
                {obj[field].hasOwnProperty('accountScore') && (
                  <Score score={obj[field].accountScore} />
                )}
                <MuiIconButton
                  aria-label='notes-icon'
                  size='small'
                  onClick={() => itemClick(obj, obj[field].clickType)} // clickType
                >
                  <NotesIcon />
                </MuiIconButton>
              </div>
            </div>
          );

        case 'autocomplete':
          const {
            selected: { lnm: listName },
          } = obj[field].value;
          return (
            <div className='align-middle'>
              <Typography className={classes.tableDataContent} component='div'>
                <Box
                  width={80}
                  maxWidth={100}
                  fontSize='body2.fontSize'
                  style={{ textTransform: 'capitalize' }}
                >
                  {listName}
                </Box>
              </Typography>
            </div>
            /**
             * @description Not using List DropDown feature now
              <AutoCompleteListName 
                selected={obj[field].value.selected} 
                data = {autoCompleteData} 
                enabled = {obj[field].value.enabled} 
                dropDownSelected = {(selectedListObj) => dropDownSelected(obj, selectedListObj)}
                dropDownNewItemCreated = {lnm => dropDownNewItemCreated(obj, lnm)}
                width = {200}
              />
            */
          );

        case 'catchAllNValidity':
          return (
            <div className={`${classes.tableData} align-middle`}>
              <Typography className={classes.tableDataContent} component='div'>
                {obj[field].value.valid == true && (
                  <>
                    <Box
                      fontSize='body2.fontSize'
                      mb={0.5}
                      className={classes.textUnderline}
                      maxWidth={220}
                    >
                      <InteractivePopper
                        text={obj[field].value.email}
                        uniqueId={`${rowIndex}${fieldIndex}`}
                        copyText
                      />
                    </Box>
                    <Box fontSize={14} mb={1} width={220} className={classes.alignCenter}>
                      <img src={tick} width={15} /> Not Catch All. Safe to send
                    </Box>
                  </>
                )}
                {obj[field].value.valid == false && obj[field].value.catchAll == true && (
                  <>
                    <Box
                      fontSize='body2.fontSize'
                      mb={0.5}
                      className={classes.textUnderline}
                      maxWidth={220}
                    >
                      <InteractivePopper
                        text={obj[field].value.email}
                        uniqueId={`${rowIndex}${fieldIndex}`}
                        copyText
                      />
                    </Box>
                    <Box fontSize={14} mb={1} width={220} className={classes.alignCenter}>
                      <img src={warning} width={15} /> Catch All.Confidence:{' '}
                      {obj[field].value.confidence || 0}%
                    </Box>
                  </>
                )}
              </Typography>
              <div className={classes.tableDataAction}>
                <a className={classes.secondaryLink} onClick={() => itemClick(obj, 'findEmail')}>
                  Find Again
                </a>
              </div>
            </div>
          );

        case 'more':
          return (
            <Box className={`align-middle`} width={130}>
              <Typography component='div'>
                <Box fontSize='body2.fontSize' letterSpacing={0.5} lineHeight='1.5'>
                  {obj[field].value}
                  {obj[field].value.indexOf(',') > 0 && (
                    <Box
                      fontSize='caption.fontSize'
                      className='pointer'
                      onClick={() => moreInfo(obj, field)}
                    >
                      {' '}
                      (more...){' '}
                    </Box>
                  )}
                </Box>
              </Typography>
            </Box>
          );

        case 'textButton':
          return (
            <label className='align-middle'>
              {obj['Name'].value === 'NOT FOUND' ? '"NA"' : 
              <a className='text-primary pointer' onClick={() => itemClick(obj, field)}>
                <u>{obj[field].value}</u>
              </a>
            }
            </label>
          );

        case 'button':
          return (
            <Box className='align-middle' minWidth={70}>
              <Button
                className='jr-btn'
                size='small'
                variant='outlined'
                color='primary'
                aria-label='add'
                onClick={() => itemClick(obj, field)}
                disabled={obj[field].disabled}
              >
                {obj[field].value}
              </Button>
            </Box>
          );

        case 'image':
          return <img src={obj[field].value} />;

        case 'coordinates':
          return (
            <div className={`${classes.tableData} align-middle`}>
              <Typography className={classes.tableDataContent} component='div'>
                {obj[field].list.map((item, index) =>
                  item.link != '' ? (
                    <Box key={index} minWidth={100} fontSize='body2.fontSize' width={180}>
                      <a key={index} href={item.link} target='_blank' className='pointer'>
                        <img
                          style={{ marginRight: '10px' }}
                          width={20}
                          src={require(`../../assets/images/${item.image}.png`)}
                        />
                        <span className='text-primary'>
                          <u>{item.text}</u>
                        </span>
                        <u></u>
                      </a>
                      <h5 className='mt-2'>{item.location}</h5>
                    </Box>
                  ) : (
                    <span key={index}>"NA"</span>
                  )
                )}
              </Typography>
            </div>
          );

        /* case "anchor":
          return (<div>
            {obj[field].list.map((item, index) =>
              item.link != ""
                ? <a key={index} href={item.link} target="_blank" ><img width={40} src={require(`../../assets/images/${item.image}.png`)} /></a>
                : <img key={index} width={40} src={require(`../../assets/images/${item.image}.png`)} />
            )}
          </div>) */

        case 'anchor':
          return (
            <label className='align-middle'>
              <a className='text-primary pointer' onClick={() => window.open(obj[field].value)}>
                {obj[field].value}
              </a>
            </label>
          );

        case 'infoTextAction': {
          return (
            <div className={`${classes.tableData} align-middle`}>
              <Typography className={classes.tableDataContent} component='div'>
                <Box
                  mb={0.5}
                  fontSize='body2.fontSize'
                  width={200}
                  data-for={`companyNameText-${rowIndex}${fieldIndex}`}
                  data-tip={obj[field].value}
                >
                  {obj[field].value}
                </Box>
              </Typography>
              {/* Add proper checks to display below div conditionally */}
              {obj[field].clickValue && (
                <div className={classes.tableDataAction}>
                  <a
                    className={classes.secondaryLink}
                    onClick={() => itemClick(obj, obj[field].clickType)} // clickType
                  >
                    {obj[field].clickValue}
                  </a>
                </div>
              )}
            </div>
          );
        }

        default:
          return (
            <label className='align-middle'>
              <h5 className='m-0 list-wrap'>{obj[field]}</h5>
            </label>
          );
      }
    } catch (error) {
      return <></>;
    }
  };

  const selectedList = selectionAvailable && checkHowManySelected();

  const filterTypeToDisplay = (obj, field, rowIndex, fieldIndex) => {
    try {
      return (
        <>
          {selectionAvailable && fieldIndex == 0 ? (
            <div className='d-flex align-items-center'>
              <Checkbox
                color='primary'
                checked={obj['checked'].value}
                onClick={() => onindividualCheckChange(rowIndex)}
                value='SelectMail'
              />
              {displayText(obj, field, rowIndex, fieldIndex)}
            </div>
          ) : (
            displayText(obj, field, rowIndex, fieldIndex)
          )}
        </>
      );
    } catch (error) {
      return <></>;
    }
  };

  const displayTd = (result, fields) =>
    result.map((n, i) => (
      <TableRow onClick={() => onClickRowItem(n)} key={i}>
        {fields.map((item, j) => (
          <TableCell key={j}> {filterTypeToDisplay(n, item, i, j)}</TableCell>
        ))}
      </TableRow>
    ));

  return (
    <div className='table-responsive-material custom-table'>
      {showFilter && (
        <FilterListPopOver
          filterList={filterList}
          filterType={filterType}
          handleSelectedFilter={handleSelectedFilter}
        />
      )}
      <Table>
        <TableHead>
          <TableRow>
            {fields.map((item, i) => (
              <TableCell key={i}>
                {selectionAvailable && i == 0 && (
                  <Checkbox
                    color='primary'
                    indeterminate={selectedList.length > 0 && selectedList.length < result.length}
                    checked={selectedList.length > 0}
                    value='appList'
                    onClick={onAllCheckChange}
                  />
                )}
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{displayTd(result, fields)}</TableBody>
      </Table>
    </div>
  );
}

export default PeopleResultListTable;
