import { getUserLoginDetailsSuccess } from "actions";
import React, { Component, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { auth } from "../firebase/firebase";
import { makeStyles } from "@material-ui/core";
import './authStylings.css';

// styles
const useStyles = makeStyles(theme => ({
  main: {
    width: "100%",
    minHeight: "80vh",
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    margin: '50px',
  },
  left: {
    height: "100%",
    display: "flex",
    backgroundColor: "rgba(248, 248, 248, 1);",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "60px 60px 40px",
  },
  right: {
    display: "grid",
    placeItems: "center",
    backgroundColor: "#fff",
  },
}));

// main component
const EmailConfirmation = ({ actionCode }) => {
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [statusMessage, setStatusMessage] = useState(
    "Verifying email id. Please wait..."
  );

  const classes = useStyles();

  const storeDispatch = useDispatch();
  useEffect(() => {
    if (actionCode) handleVerifyEmail(actionCode);
  }, []);

  /* useEffect(() => {
          auth.onAuthStateChanged(async(user) => {
              if (user) 
                  if (user.emailVerified){
                      setIsEmailVerified(true);
                  }
                          
          });
      }, [auth]) */

  function handleVerifyEmail(actionCode, continueUrl, lang) {
    auth
      .applyActionCode(actionCode)
      .then(resp => {
        console.log("email is verified", resp);
        sessionStorage.setItem("emailconfirmed", true);
        // localStorage.removeItem("userDetails");
        // localStorage.removeItem("companyDetails");
        storeDispatch(
          getUserLoginDetailsSuccess({
            userDetails: {},
            companyDetails: {},
          })
        );
        setIsEmailVerified(true);
        //immediately not getting updated
      })
      .catch(function(error) {
        setIsEmailVerified(false);
        setStatusMessage(error.message);
      });
  }

  return (
    <div className="auth-card">
      <div className={classes.main}>
        <div
          className={
            classes.left + " animated slideInUpTiny animation-duration-3"
          }
        >
          <div className="app-wrapper animated slideInUpTiny animation-duration-3 d-flex align-items-center justify-content-center">
            {isEmailVerified ? (
              <div className="text-center">
                <h2>Thanks for confirming your email id. </h2>
                <h2>
                  <Link to="/app/home">let's get started</Link>
                </h2>
              </div>
            ) : (
              <div className="text-center">
                <h2>{statusMessage}</h2>
              </div>
            )}
          </div>
        </div>
        {/* right side image */}
        <div className={classes.right}>
          {/* <h2
            style={{
              color: "#717171",
              fontSize: "22px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Discover Accounts, Score and Qualify <br /> leads with us
          </h2> */}
          {/* <img
            width="70%"
            src={require("assets/images/conveyer-belt.png")}
            alt="conveyer"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
