import React, { useState, useRef } from 'react';
import {
  Paper,
  Typography,
  Chip,
  Select,
  MenuItem,
  LinearProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { useAutocomplete } from '@material-ui/lab';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core';
import { Button } from 'reactstrap';
import deleteImg from '../../assets/images/deleteIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import {
  baseListUrl,
  createCustomListMethod,
  baseSearchMainUrl,
  searchRoute,
  findDecisionMaker,
} from 'util/urls';
import { auth } from '../../firebase/firebase';
import { getListNameByUser } from 'actions/Contact';

const countryList = [
  'United States',
  'United Kingdom',
  'Australia',
  'Germany',
  'Canada',
  'Netherlands',
  'Singapore',
  'United Arab Emirates',
  'France',
  'India',
];

let senorityOptions = [
  'Vice President',
  'General Manager',
  'Head of',
  'Director',
  'Manager',
  'Chief Marketing Officer',
  'Chief Operating Officer',
  'Chief Executive Officer',
  'Chief Sales Officer',
  'Chief Information Officer',
  'Chief Technology Officer',
  'Chief Human Resources Officer',
  'Chief Customer Success Officer',
  'Chief Financial Officer',
];
let departmentOptions = [
  'Business Development',
  'Sales',
  'Operations',
  'Engineering',
  'Information Technology',
  'Finance',
  'Marketing',
  'Strategy consulting',
  'Product Management',
  'Program Management',
  'Project Management',
  'Purchasing',
  'Quality Assurance',
  'Research',
  'Customer Success',
  'Human Resource',
];

const useStyles = makeStyles(theme => ({
  wrapper: {
    alignItems: 'flex-start',
  },
  paper: {
    width: 'clamp(450px,70vw,935px)',
    // height: '80vh',
    padding: '35px',
    listStyle: 'none',
  },
  selected: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  label: {
    display: 'block',
  },
  inputRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  input: {
    width: 'clamp(200px,65vw,863px)',
    padding: '12px',
    border: 'solid 1px #b4b4b4',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'blue',
    },
  },
  suggestionsRoot: {
    position: 'relative',
  },
  listbox: {
    width: 'clamp(200px,65vw,863px)',
    margin: 0,
    padding: '0 4px',
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid #b4b4b4',
    borderTop: 0,
    '& li': {
      padding: '7px',
    },
    '& li[data-focus="true"]': {
      border: '0px',
      backgroundColor: '#b4b4b4',
      color: 'white',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
    '& li:active': {
      border: '0px',
      backgroundColor: '#b4b4b4',
      color: 'white',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  },
  chipsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '10px 0px',
  },
  chip: {
    background: '#eef0ff',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    padding: '3px',
    margin: theme.spacing(0.3),
    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
  tabRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '40vh',
    margin: '40px 0px',
    width: 'clamp(200px,65vw,863px)',
  },
  tabs: {
    border: `1px solid #b4b4b4`,
    marginRight: '30px',
  },
  tabItems: {
    border: `1px solid #b4b4b4`,
    overflowY: 'scroll',
    width: '90%',
  },
  submitRoot: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    right: '0%',
    bottom: '0%',
  },
  loaderParent: {
    display: 'grid',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
  },
}));
const getAuthToken = async () => await auth.currentUser.getIdToken();

const DecisionMakerPopup = ({
  headerText = '',
  buttonText = 'Add keywords',
  closeModal,
  geoForward,
  fdmSelectedUniqueItem
}) => {
  const classes = useStyles();
  const boxRef = useRef();
  // #region --> Main State for all fields
  const [state, setState] = useState({
    title: { name: 'Title', input: '', chips: [], maxChips: 5 },
    include: { name: 'Include', input: '', chips: [], maxChips: 5, required: false },
    exclude: { name: 'Exclude', input: '', chips: [], maxChips: 5, required: false },
    geo: { name: 'Geo', input: geoForward || '', required: false },
    newlistName: { name: 'New List Name', input: '', required: true },
  });
  const [loader, setLoader] = useState({ loading: false, status: '' });
  // #endregion

  // #region --> Redux Store and Hooks
  const storeDispatch = useDispatch();
  const { searchResult } = useSelector(({ search }) => search);
  const selectedCompanies = fdmSelectedUniqueItem;
  const { companyDetails, userDetails } = useSelector(({ auth }) => auth);

  const [isEnrichCompanyOnly, setIsEnrichCompanyOnly] = useState(false);
  // #endregion

  // #region --> Error Message State
  const [error, setError] = useState('');
  // #endregion

  // #region --> Handlers
  const handleFieldChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    switch (name) {
      case 'title':
      case 'include':
      case 'exclude':
      case 'geo':
      case 'newlistName':
        setState({ ...state, [name]: { ...state[name], input: value } });
        return;
    }
  };
  const handleSenFieldChange = (event, value, reason) => {
    if (reason === 'input') {
      setState({ ...state, ['seniority']: { ...state['seniority'], input: value } });
    }
    if (
      reason === 'reset' &&
      !state['seniority'].chips.includes(value) &&
      state['seniority'].chips.length < state['seniority'].maxChips
    ) {
      setState({
        ...state,
        ['seniority']: {
          ...state['seniority'],
          input: '',
          chips: [...state['seniority'].chips, value],
        },
      });
    }
  };

  const handleDepFieldChange = (event, value, reason) => {
    if (reason === 'input') {
      setState({ ...state, ['department']: { ...state['department'], input: value } });
    }
    if (
      reason === 'reset' &&
      !state['department'].chips.includes(value) &&
      state['department'].chips.length < state['department'].maxChips
    ) {
      setState({
        ...state,
        ['department']: {
          ...state['department'],
          input: '',
          chips: [...state['department'].chips, value],
        },
      });
    }
  };

  const handleAddChip = event => {
    if (event.key === 'Enter' || event.key === undefined) {
      const name = event.target.name;
      const value = event.target.value;
      if (
        !state[name].chips.includes(value) &&
        event.key === 'Enter' &&
        state[name].chips.length < state[name].maxChips
      ) {
        setState({
          ...state,
          [name]: { ...state[name], input: '', chips: [...state[name].chips, value] },
        });
      }
      if (state[name].chips.includes(value)) {
        scrollBoxTop();
        setError('Duplicate keywords not allowed');
        setTimeout(() => {
          setError('');
        }, 2000);
      }

      if (state[name].chips.length === state[name].maxChips) {
        scrollBoxTop();
        setError(`Reached max number of keywords allowed`);
        setTimeout(() => {
          setError('');
        }, 2000);
      }
    }
  };

  const handleDeleteChip = (name, data) => {
    const arr = state[name].chips;
    const index = arr.indexOf(data);
    if (index > -1) arr.splice(index, 1);
    setState({ ...state, [name]: { ...state[name], chips: arr } });
  };

  const requiredFieldCheck = () => {
    const requiredFail = [];
    let tdFlag = false;

    if (!state['title'].chips.length && !isEnrichCompanyOnly) {
      tdFlag = true;
    }

    Object.keys(state).forEach(field => {
      if (state[field].required) {
        if (state[field].hasOwnProperty('chips') && !state[field].chips.length) {
          requiredFail.push(state[field].name);
        } else if (!state[field].hasOwnProperty('chips') && !state[field].input.length) {
          requiredFail.push(state[field].name);
        }
      }
    });

    if (requiredFail.length || tdFlag) {
      let str = '';
      scrollBoxTop();
      if (requiredFail.length) {
        str += `${requiredFail.join(',')} ${
          requiredFail.length > 1 ? 'fields are' : 'field is'
        } required! `;
      }
      if (tdFlag) str += `Please fill either Title or Department field`;
      setError(str);
      setTimeout(() => {
        setError('');
      }, 2000);
      return false;
    }
    return true;
  };

  const scrollBoxTop = () => boxRef.current && boxRef.current.scrollTo(0, 0);

  const handleLoader = (loading, status) => setLoader({ loading, status });

  const handleSubmit = async () => {
    try {
      if (requiredFieldCheck()) {
        // --> Create new List
        handleLoader(true, 'Creating new list...');
        const token = await getAuthToken();
        let response;
        if (!isEnrichCompanyOnly) {
          const newListPayload = {
            user_id: userDetails.uid,
            cat: 'people',
            type: 'user',
            lnm: state['newlistName'].input,
          };

          response = await fetchHandler(
            newListPayload,
            baseListUrl + createCustomListMethod,
            token
          );

          if (!response.lid) throw new Error('Something went wrong.');
        }

        const companyNewListPayload = {
          user_id: userDetails.uid,
          cat: 'company',
          type: 'user',
          lnm: state['newlistName'].input,
        };

        const companyResponse = await fetchHandler(
          companyNewListPayload,
          baseListUrl + createCustomListMethod,
          token
        );

        if (!companyResponse.lid) throw new Error('Something went wrong.');
        // --> dispatch action to update list names in store
        handleLoader(true, 'Processing task request...');
        let actionPayload = {
          uid: userDetails.uid,
          cat: 'people',
        };
        storeDispatch(getListNameByUser(actionPayload));
        // --> create payloads
        const payLoadList = [];
        for (let i = 0; i < selectedCompanies.length; i++) {
          if (isEnrichCompanyOnly) {
            const el = selectedCompanies[i];
              const { extra } = el;
              let payload = {
                title: '',
                cName: extra.cName || '',
                cDomain: '',
                cSig: extra.cSig || '',
                ljid: extra.ljid || '',
                include: state['include']['chips'] || [],
                exclude: state['exclude']['chips'] || [],
                geo: state['geo']['input'] || '',
                needEmail: true, // hardcoded to true
                needMobile: false, // hardcoded to false
                isEnrichCompanyOnly: isEnrichCompanyOnly,
                identity: {
                  recordId: '',
                  lid: response?.lid || '',
                  lnm: response?.lnm || '',
                  uid: userDetails.uid,
                  userName: '',
                  cid: companyDetails.cid,
                  cat: 'people', // hardcoded to "people"
                  type: 'user', // hardcoded tp "user"
                  sync: 'NA', // hardcoded to "NA"
                  clid: companyResponse.lid,
                  clnm: companyResponse.lnm,
                },
                sFlag: ''
              };
              if (payload.cName || payload.cSig) payLoadList.push(payload);
          } else {
            const title = state['title']['chips'];
            const el = selectedCompanies[i];
            const { companyRecord, extra, identity } = el;
            let payload = {
              title: title || '',
              cName: extra.cName || '',
              cDomain: '',
              cSig: extra.cSig || '',
              ljid: extra.ljid || '',
              include: state['include']['chips'] || [],
              exclude: state['exclude']['chips'] || [],
              geo: state['geo']['input'] || '',
              needEmail: true, // hardcoded to true
              needMobile: false, // hardcoded to false
              isEnrichCompanyOnly: isEnrichCompanyOnly,
              identity: {
                recordId: '',
                lid: response?.lid || '',
                lnm: response?.lnm || '',
                uid: userDetails.uid,
                userName: '',
                cid: companyDetails.cid,
                cat: 'people', // hardcoded to "people"
                type: 'user', // hardcoded tp "user"
                sync: 'NA', // hardcoded to "NA"
                clid: companyResponse.lid,
                clnm: companyResponse.lnm,
              },
              sFlag: ''
            };
            if (payload.cName || payload.cSig) payLoadList.push(payload);
          }
        }
        const promises = payLoadList.map((payload) => fetchHandler(payload, baseSearchMainUrl + searchRoute + findDecisionMaker,
          token));
        await Promise.all(promises);
        handleLoader(true, 'Task Request Proceesed Successfully!');
        setTimeout(() => {
          closeModal();
        }, 1000);
      }
    } catch (error) {
      scrollBoxTop();
      setError('Something went wrong! Please try again.');
      setTimeout(() => {
        setError('');
      }, 2000);
      handleLoader(false, 'Error');
    }
  };

  const downloadCsv = ()=> {
    if(selectedCompanies.length>0){
      const list = selectedCompanies.map((item)=> ({name: item.companyRecord.name}));
      const workSheet = XLSX.utils.json_to_sheet(list);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'companies');
      // Buffer
      XLSX.write(workBook,{bookType:'csv', type: 'buffer'});
      // binary string
      XLSX.write(workBook,{ bookType: 'csv', type: 'binary'});
      // download
      XLSX.writeFile(workBook,'companies.csv');
     }
  }
  // #endregion

  return (
    <div>
      <Paper style={{ overflowY: 'auto', height: '55vh' }} ref={boxRef} className={classes.paper}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {headerText}
        </Typography>
        <br />
        {error && (
          <Alert style={{ marginBottom: '20px' }} severity='error'>
            {error}
          </Alert>
        )}
        {loader.loading ? (
          <div className={classes.loaderParent}>
            <h2>{loader.status}</h2>
            <LinearProgress />
          </div>
        ) : (
          <div>
            {/* Title */}
            <div>
              <label style={{ margin: '10px 0px' }}>Title</label>
              <input
                name='title'
                onChange={handleFieldChange}
                value={state['title'].input}
                onKeyDown={handleAddChip}
                type='text'
                className={classes.input}
              />
            </div>
            <div className={classes.chipsRoot}>
              {state['title'].chips.length
                ? state['title'].chips.map((data, index) => {
                    return (
                      <li key={index}>
                        <Chip
                          label={data}
                          variant='outlined'
                          onDelete={() => handleDeleteChip('title', data)}
                          className={classes.chip}
                          deleteIcon={
                            <DeleteIcon
                              name={data.name || data.Description}
                              onClick={() => handleDeleteChip('title', data)}
                            />
                          }
                        />
                      </li>
                    );
                  })
                : ''}
            </div>
            {/* Include */}
            <div>
              <label style={{ margin: '10px 0px' }}>Include</label>
              <input
                name='include'
                onChange={handleFieldChange}
                value={state['include'].input}
                onKeyDown={handleAddChip}
                type='text'
                className={classes.input}
              />
            </div>
            <div className={classes.chipsRoot}>
              {state['include'].chips.length
                ? state['include'].chips.map((data, index) => {
                    return (
                      <li key={index}>
                        <Chip
                          label={data}
                          variant='outlined'
                          onDelete={() => handleDeleteChip('include', data)}
                          className={classes.chip}
                          deleteIcon={
                            <DeleteIcon
                              name={data.name || data.Description}
                              onClick={() => handleDeleteChip('include', data)}
                            />
                          }
                        />
                      </li>
                    );
                  })
                : ''}
            </div>
            {/* Exclude */}
            <div>
              <label style={{ margin: '10px 0px' }}>Exclude</label>
              <input
                name='exclude'
                onChange={handleFieldChange}
                value={state['exclude'].input}
                onKeyDown={handleAddChip}
                type='text'
                className={classes.input}
              />
            </div>
            <div className={classes.chipsRoot}>
              {state['exclude'].chips.length
                ? state['exclude'].chips.map((data, index) => {
                    return (
                      <li key={index}>
                        <Chip
                          label={data}
                          variant='outlined'
                          onDelete={() => handleDeleteChip('exclude', data)}
                          className={classes.chip}
                          deleteIcon={
                            <DeleteIcon
                              name={data.name || data.Description}
                              onClick={() => handleDeleteChip('exclude', data)}
                            />
                          }
                        />
                      </li>
                    );
                  })
                : ''}
            </div>
            {/* Geo */}
            <div>
              <label style={{ margin: '10px 0px' }}>Geo</label>
              <Select
                name='geo'
                value={state['geo'].input}
                className={classes.input}
                onChange={handleFieldChange}
              >
                <MenuItem value=''>None</MenuItem>
                {countryList.map((items, index) => (
                  <MenuItem value={items}>{items}</MenuItem>
                ))}
              </Select>
            </div>
            {/* New List Name */}
            <div>
              <label style={{ margin: '10px 0px' }}>
                <span>New List Name</span>
                <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                name='newlistName'
                onChange={handleFieldChange}
                value={state['newlistName'].input}
                type='text'
                className={classes.input}
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEnrichCompanyOnly}
                    onChange={() => setIsEnrichCompanyOnly(!isEnrichCompanyOnly)}
                    name='enrichCompanyOnly'
                    color='primary'
                  />
                }
                label='Enrich company Only'
              />
            </div>
            {/* Button */}
            <div className={classes.submitRoot}>
              <hr />
              <div style={{ textAlign: 'right' }}>
                <Button color='primary' onClick={downloadCsv}>
                  Download ({fdmSelectedUniqueItem.length})
                </Button>
                <Button color='primary' onClick={handleSubmit}>
                  {buttonText}
                </Button>
              </div>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
};

const DeleteIcon = ({ name, onClick }) => (
  <img alt={`Delete ${name}`} src={deleteImg} onClick={onClick} style={{ cursor: 'pointer' }} />
);

const fetchHandler = async (payload, url, token) => {
  try {
    return await fetch(url, {
      method: 'POST',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(payload),
    })
      .then(async response => {
        return await response.json();
      })
      .catch(error => error);
  } catch (error) {
    return error;
  }
};

export default DecisionMakerPopup;
