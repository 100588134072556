import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { hideCheckUpdateMatchList } from 'actions';

import Fade from '@material-ui/core/Fade';
import { Divider, IconButton as MuiIconButton, InputBase, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from 'components/IconButton';

import { forceUpdate } from '../../actions/Contact';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: 450,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[8],
  },
  header: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2, 3),

    '& > button': {
      position: 'absolute',
      right: '0',
      top: '0',
      transform: `translate(-40%, 50%)`,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  content: {
    padding: theme.spacing(2, 3),
    minHeight: '100%',
  },
  divider: {
    margin: theme.spacing(0, 0, 1, 0),
    backgroundColor: theme.palette.grey[400],
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
  btn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    fontWeight: '750',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    background: 'transparent',
    padding: '10px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: '550',
    fontSize: '20px',
    paddingTop: '20px',
  },
  data: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    border: '0.20px solid gray',
    marginTop: '15px',
    padding: '15px',
    fontSize: '15px',
    fontWeight: '550',
    verticalAlign: 'middle',
  },
  forceupdate: {
    padding: '10px',
    backgroundColor: '#0065b8',
    color: 'white',
    outline: 'none',
    border: 'none',
  },
  users: {
    margin: '20px',
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const CrmUserMatchPopup = () => {
  const classes = useStyles();
  const storeDispatch = useDispatch();
  const { crmUserMatchData, showCrmUserMatchList, peopleEnrichedList } = useSelector(
    ({ contacts }) => contacts
  );
  const { userDetails } = useSelector(({ auth }) => auth);

  const [users, setUsers] = useState([]);
  const [selectedPeopleRecord, setSelectedPeopleRecord] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let peopleRecord;
    if (showCrmUserMatchList !== -1) {
      peopleRecord = peopleEnrichedList.find(people => people.id === showCrmUserMatchList);
      setSelectedPeopleRecord(peopleRecord);
    }
    if (Array.isArray(crmUserMatchData.users)) {
      setUsers(
        crmUserMatchData.users.map(user => ({
          firstName: peopleRecord.contactRecord?.firstName || 'FirstName',
          lastName: peopleRecord.contactRecord?.lastName || 'LastName',
          id: user.id,
          email: user.email,
        }))
      );
    }
  }, [peopleEnrichedList]);

  useEffect(() => {
    if (showCrmUserMatchList === -1) {
      setLoading(false);
    }
  }, [showCrmUserMatchList]);

  const closePopup = () => {
    storeDispatch(hideCheckUpdateMatchList());
  };

  const forceUpdateHandler = recordID => {
    const payload = {
      uid: userDetails.uid,
      peopleRecord: selectedPeopleRecord,
      recordID,
    };
    setLoading(true);
    storeDispatch(forceUpdate(payload));
  };

  if (users.length !== 0) {
    return (
      <Fade
        in={showCrmUserMatchList !== -1}
        className={classes.root}
        onClick={e => e.stopPropagation()}
      >
        <div className={classes.paper}>
          <div className={classes.header}>
            <Typography variant='h5' className={classes.fontBold}>
              We found some existing records.
            </Typography>
            <MuiIconButton onClick={closePopup} size='small'>
              <CloseIcon />
            </MuiIconButton>
          </div>
          <div className={classes.content}>
            <Typography variant='body2' gutterBottom>
              Select the one you want to force update
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div>
            {users.map((user, idx) => {
              return (
                <div className={classes.users} key={idx}>
                  <div className={classes.data}>
                    <div className={classes.user}>
                      <div>
                        {user.firstName} {user.lastName}
                      </div>
                      <div>Email: {user.email}</div>
                    </div>
                    <div>
                      <button
                        className={classes.forceupdate}
                        onClick={() => {
                          forceUpdateHandler(user.id);
                        }}
                      >
                        Force Update {loading && `...`}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={classes.buttonWrapper}>
            <IconButton
              className='jr-btn'
              variant='contained'
              aria-label='cancel'
              onClick={closePopup}
            >
              <span>Cancel</span>
            </IconButton>
          </div>
        </div>
      </Fade>
    );
  } else {
    return <div style={{ color: '#000' }}>Loading...</div>;
  }
};

export default CrmUserMatchPopup;
