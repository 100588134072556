export const baseurl = process.env.REACT_APP_BASE_URL;
export const baseIAMUrl = process.env.REACT_APP_IAM_BASE_URL;
export const baseListUrl = process.env.REACT_APP_LIST_BASE_URL;
export const baseScrapeUrl = process.env.REACT_APP_SCRAPE_BASE_URL;
export const baseUrl = process.env.REACT_APP_LIST_BASE_URL;
export const baseSubscriptionUrl = process.env.REACT_APP_SUBSCRIPTION_BASE_URL;
export const baseSearchUrl = process.env.REACT_APP_SEARCH_CONTROLLER_URL;
export const baseSearchMainUrl = process.env.REACT_APP_SEARCH_MAIN_URL;

export const basePaymentUrl = process.env.REACT_APP_PAYMENT_BASE_URL;
export const redirectUrl = process.env.REACT_APP_REDIRECT_URL; //redirect url from reset password page

export const pubsub_project_id = process.env.PUBSUB_PROJECT_ID;
export const pubsub_project_key = process.env.PUBSUB_PROJECT_KEY;
export const pubsub_client_email = process.env.PUBSUB_CLIENT_EMAIL;

export const crmBaseUrl = process.env.REACT_APP_CRM_BASE_URL;
export const auxBaseUrl = process.env.REACT_APP_AUX_BASE_URL;

export const iamRoute = 'api/IAMController/';
export const userRoute = 'auth/';
export const jobsRoute = 'jobs/';
export const saveProfile = 'saveProfile';
export const installNewUser = 'installNewUser';
export const subscriptionUser = 'api/subscController/';
export const getUserDetailsUrl = 'getUserDetails';
export const getRouteDetailsUrl = 'getRouteDetails';
export const updateProfileUrl = 'updateProfile';
export const jobsList = 'getJobs';
export const getJobInputDetails = 'getJobInputDetails';
export const getPlayBook = 'getPlayBook';
export const submitJobInput = 'submitJobInput';
export const salesNavUsersSearch = 'salesNavUsersSearch';
export const salesNavCompaniesSearch = 'salesNavCompaniesSearch';
export const submitJobEndResult = 'submitJobResult';
export const getJobResultDetails = 'getJobResultDetails';
export const submitJobInputFile = 'submitJobInputFile';
export const submitJobInputForm = 'submitJobInputForm';
export const salesNavSearch_t1 = 'salesNavSearch_t1';
export const salesNavSearch_t2 = 'salesNavSearch_t2';
export const searchPeopleNCompany = 'searchPeopleNCompany';
export const peopleSearchURL = 'T2PeopleSearch';
export const companySearchURL = 'T2CompanySearch-v2';
export const auxReportUrl = 'reports';
export const contactsList = 'getContactsList';

export const contactsAPI = 'listManager';

export const crmUserMainURL = 'crmusermain/';
export const crmCompanyMainURL = 'crmcompanymain/';

/** IAM CONTROLLER **/

export const getInvitedUsersListMethod = 'getInvitedUsersList';
export const addInviteMethod = 'addInvite';
export const getInviteDetailMethod = 'getInviteDetail';
export const confirmUserJoinedMethod = 'confirmUserJoined';

/* SUBSCRIPTION */
export const getCompanyUsersMethod = 'getCompanyUsers';
export const getCompanyDetailsMethod = 'getCompanyDetails';
export const addUserToTierMethod = 'addUserToTier';
export const removeUserFromTierMethod = 'removeUserFromTier';
export const assignStdCreditsToUserMethod = 'assignStdCreditsToUser';
export const assignPremCreditsToUserMethod = 'assignPremCreditsToUser';
export const getCompanyTierMethod = 'getCompanyTier';
export const getActiveUserCountMethod = 'getActiveUserCount';

/**list manager fire store API's  **/
export const createCustomListMethod = contactsAPI + '/createCustomList';
export const saveToCustomListMethod = contactsAPI + '/saveToCustomList';
export const viewCustomListMethod = contactsAPI + '/viewCustomList';
export const getListNamesByUserMethod = contactsAPI + '/getListNamesByUser';
export const updateListSubMethod = contactsAPI + '/updateListSub';
export const getLatestNRceordsForUserMethod = contactsAPI + '/getLatestNRecordsForUser';
export const setDefaultListMethod = contactsAPI + '/setDefaultList';
export const getDefaultListMethod = contactsAPI + '/getDefaultList';
export const moveFromCustomListOneToAnotherMethod = contactsAPI + '/moveFromCustomListOneToAnother';
export const specificRecordsMethod = contactsAPI + '/';

export const enrichEmail = 'enrichEmail';
export const emailMobileEnrich = 'emailMobileEnrich';

export const pullJobs_t1 = 'pullJobs_t1'; //pubsub pull
export const pullJobs_t2 = 'pullJobs_t2'; //pubsub pull
export const pullJobs_BusinessEnrich_E1 = 'pullJobs_BusinessEnrich_E1'; //enrich pull
export const pullJobs_PersonalEnrich_E2 = 'pullJobs_PersonalEnrich_E2'; //enrich pull

/**Payment */
export const subscribePayment = 'subscribe';

/** CRM */
export const checkUpdate = 'checkUpdate';
export const forceUpdate = 'forceUpdate';
export const companyUpdate = 'saveOrUpdateCompanyCrm';
export const initialMap = 'getDefaultCrmFields';
export const getCrmFields = 'getCrmFields';
export const getCompanyMap = '';

/** search */
export const searchRoute = 'api/search/';
export const execMovementMethod = 'exec-movement-intent-search';
export const recruitmentIntentMethod = 'recruitment-intent-search-v2';
export const topicSurgeMethod = 'topic-intent-search';
export const findDecisionMaker = 'find-decision-maker-async';
export const companyLabelMethod = 'company-label-intent';

export const similarSearchMethod = 'similar-company-intent';
export const sameVcSearchMethod = 'same-vc-intent';
export const dummySearchMethod3 = 'company-label-intent';
