import { getAuthToken } from '../sagas/Auth';
import { baseSubscriptionUrl } from './urls';

const getAllPricingPage = async (cid = '') => {
  const payload = { cid };
  const token = await getAuthToken();
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  const response = await fetch(baseSubscriptionUrl + 'api/pgController/get-pricing', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const data = await response.json();
    return data;
  }
  return {error: "Failed to fetch"}
};

const createCheckout = async (priceId, quantity, cid) => {
  const payload = {
    priceId,
    quantity,
    cid,
  };
  const token = await getAuthToken();
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  const response = await fetch(baseSubscriptionUrl + 'api/pgController/create-checkout', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const data = await response.json();
    // below lines for reference on returned obj
    if (data.url) window.location.href = data.url;
    if (data.Error) console.log(data.Error);
    return data;
  }
  return {error: "Failed to fetch"}
};

const updateQuantity = async (cid, quantity) => {
  const payload = {
    cid,
    quantity,
  };
  const token = await getAuthToken();
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  const response = await fetch(
    baseSubscriptionUrl + 'api/pgController/update-subscription-quantity',
    {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    }
  );
  if (response.ok) {
    const data = await response.json();
    return data;
  }
  return {error: "Failed to fetch"}
};

const upgradePlan = async (cid, priceId, quantity) => {
  const payload = {
    cid,
    priceId,
    quantity,
  };
  const token = await getAuthToken();
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  const response = await fetch(baseSubscriptionUrl + 'api/pgController/upgrade-subscription', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const data = await response.json();
    return data;
  }
  return {error: "Failed to fetch"}
};

const cancelPlan = async cid => {
  const payload = { cid };
  const token = await getAuthToken();
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  const response = await fetch(baseSubscriptionUrl + 'api/pgController/cancel-subscription', {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload),
  });
  if (response.ok) {
    const data = await response.json();
    return data;
  }
  return {error: "Failed to fetch"}
};

export { getAllPricingPage, createCheckout, updateQuantity, upgradePlan, cancelPlan };
