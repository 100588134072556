import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.backgroundColor,
    padding: theme.spacing(0, 1),
    margin: theme.spacing(0.5),
    marginLeft: 0,
    color: props => props.textColor,
    textTransform: 'capitalize',
    display: 'inline-block',
  },
}));

const Score = props => {
  const { score } = props;
  const theme = useTheme();

  let displayScoreText = score.value;
  let backgroundColor;
  let textColor = theme.palette.common.white;

  if (score.value.toLowerCase().includes('high')) {
    backgroundColor = '#39C607';
  } else if (score.value.toLowerCase().includes('medium')) {
    backgroundColor = '#C67A07';
  } else if (score.value.toLowerCase().includes('low')) {
    backgroundColor = '#A8AAA7';
  } else {
    displayScoreText = 'No score';
    backgroundColor = theme.palette.grey[100];
    textColor = theme.palette.text.primary;
  }

  const classes = useStyles({ backgroundColor, textColor });

  return (
    <div className={classes.root}>
      <Typography variant='caption'>{`${displayScoreText}`}</Typography>
    </div>
  );
};

Score.propTypes = {
  score: PropTypes.object.isRequired,
};

Score.defaultProps = {
  // no score is provided
  score: {
    value: '',
    reason: '',
  },
};

export default Score;
