import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import { NavLink } from 'react-router-dom';
import InfoTip from '../InfoTip';

const Navigation = props => {
  const { menuItems } = props;
  return (
    <>
      <List component='nav' disablePadding className='side-nav-menu'>
        {menuItems.map((item, index) => {
          switch (item.type) {
            case 'section':
              return <NavSection {...item} key={index} />;
            case 'collapse':
              return <NavCollapse {...item} key={index} />;
            case 'item':
              return <NavMenuItem {...item} key={index} />;
          }
        })}
      </List>

      <div className='sidenav-footer'>
        <List component='div' className='nav-menu-item'>
          <NavLink
            className='prepend-icon nav-menu-link'
            to='/app/settings'
            data-for='settings'
            data-tip='Settings'
            data-iscapture='true'
          >
            <img src={require('../../assets/images/settings-icon.png')} alt='setting' />
          </NavLink>
          <InfoTip id='settings' place='right' />
        </List>
        <List component='nav' disablePadding className='side-nav-menu'>
          <div className='nav-section'>
            <List component='div' className='nav-menu-item'>
              <NavLink
                className='prepend-icon nav-menu-link'
                to='/app/help'
                data-for='help'
                data-tip='Help'
                data-iscapture='true'
              >
                <img src={require('../../assets/images/help-icon.png')} alt='setting' />
              </NavLink>
              <InfoTip id='help' place='right' />
            </List>
          </div>
        </List>
      </div>
    </>
  );
};

export default Navigation;
