import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
  baseSearchMainUrl,
  execMovementMethod,
  searchRoute,
  recruitmentIntentMethod,
  topicSurgeMethod,
  companyLabelMethod,
  similarSearchMethod,
  sameVcSearchMethod,
  dummySearchMethod3,
} from 'util/urls';

import { contactRecordsModifyResult } from '../util';

import {
  FETCH_SEARCH_RESULT,
  FETCH_SEARCH_LIST_RESULT,
  SEARCH_EXEC_MOVEMENT,
  SEARCH_RECRUITMENT_BUYER,
  SEARCH_RECRUITMENT_USER,
  SEARCH_TOPIC_SURGE,
  START_PREFETCH_SEARCH,
  START_PREFETCH_SEARCH_LIST,
  SEARCH_COMPANY_LABEL,
  SEARCH_SIMILAR_INTENT,
  SEARCH_SAME_VC_INTENT,
  SEARCH_DUMMY_3,
} from 'constants/ActionTypes';
import {
  errorPrefetchSearch,
  errorPrefetchSearchList,
  searchFetchError,
  searchFetchSuccess,
  searchMoreSuccess,
  setPrefetchSearchLoading,
  setSearchLoading,
  setSearchLoadingFalse,
  successPrefetchSearch,
  successPrefetchSearchList,
  updateSearchDetails,
  updateSearchDetailsList,
  updateSearchResult,
} from 'actions';
import { auth } from '../firebase/firebase';

const getAuthToken = async () => await auth.currentUser.getIdToken();
const getUserDetails = state => state.auth.userDetails;
const getCompanyListNames = state => state.contacts.companyListNames;

const postFetchCall = async (url, payload, token) => {
  const headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  try {
    const res = await fetch(url, { method: 'POST', headers, body: JSON.stringify(payload) });
    if (!res.ok) {
      return { error: 'something went wrong!' };
    }
    return await res.json();
  } catch (err) {
    let errMsg = err?.response?.data.error || 'Sorry, something went wrong';
    return { error: errMsg };
  }
};

const getSearchUrlFromSearchType = searchType => {
  let url = baseSearchMainUrl + searchRoute;
  switch (searchType) {
    case SEARCH_EXEC_MOVEMENT:
      return (url += execMovementMethod);
    case SEARCH_RECRUITMENT_BUYER:
    case SEARCH_RECRUITMENT_USER:
      return (url += recruitmentIntentMethod);
    case SEARCH_TOPIC_SURGE:
      return (url += topicSurgeMethod);
    case SEARCH_COMPANY_LABEL:
      return (url += companyLabelMethod);
    case SEARCH_SIMILAR_INTENT:
      return (url += similarSearchMethod);
    case SEARCH_SAME_VC_INTENT:
      return (url += sameVcSearchMethod);
    case SEARCH_DUMMY_3:
      return (url += dummySearchMethod3);
    default:
      return url;
  }
};

const isPrefetchDone = (recordNeed, recordGot) => {
  return recordGot > recordNeed;
};

const prefetchThreshold = 2;

/**
 * using for search
 */
function* searchSubmit({ payload }) {
  try {
    // start loading
    yield put(setSearchLoading());
    const {
      metaFields: { recipeType },
    } = payload;
    const token = yield call(getAuthToken);
    let response;
    let responseIntentList = [];
    let url = getSearchUrlFromSearchType(recipeType);

    let searchDetails = {
      searchType: recipeType,
      payloadForm: payload,
      isSearchGoingOn: true,
    };

    response = yield call(postFetchCall, url, payload, token);

    if (response.error) {
      yield put(searchFetchError(searchDetails));
      return;
    }

    const userDetails = yield select(getUserDetails);
    const companyListNames = yield select(getCompanyListNames);

    let formattedList = contactRecordsModifyResult(
      'search',
      response.intentList,
      'list',
      companyListNames,
      userDetails
    );

    yield put(searchFetchSuccess({ searchDetails, searchResult: formattedList }));

    // make another api call to get more data
    if (response.intentList.length > 11) {
      yield put(setPrefetchSearchLoading());
      payload = {
        ...payload,
        intentSpec: { ...payload.intentSpec, pageNum: payload.intentSpec.pageNum + 1 },
      };
      searchDetails = { ...searchDetails, payloadForm: payload };

      response = yield call(postFetchCall, url, payload, token);
      if (response.error) {
        yield put(searchFetchError(searchDetails));
        return;
      }
      formattedList = contactRecordsModifyResult(
        'search',
        response.intentList,
        'list',
        companyListNames,
        userDetails
      );

      yield put(searchMoreSuccess({ searchDetails, searchResult: formattedList }));
    }
  } catch (error) {
    console.log('erro', error);
    let searchDetails = {
      searchType: payload.recipeType,
      payloadForm: payload,
      isSearchGoingOn: true,
    };
    yield put(searchFetchError(searchDetails));
  }
}

/**
 * using for search with multiple titles
 */
function* searchListSubmit({ payload: payloadList }) {
  try {
    // start loading
    yield put(setSearchLoading());
    const token = yield call(getAuthToken);
    const searchDetailsList = [];
    const userDetails = yield select(getUserDetails);
    const companyListNames = yield select(getCompanyListNames);
    let formattedList;
    for (let i = 0; i < payloadList.length; i++) {
      const payload = payloadList[i];
      let response;
      let url = getSearchUrlFromSearchType(payload.metaFields.recipeType);
      let searchDetails = {
        searchType: payload.metaFields.recipeType,
        payloadForm: payload,
        isSearchGoingOn: true,
      };
      searchDetailsList.push(searchDetails);
      response = yield call(postFetchCall, url, payload, token);
      if (response.error) {
        yield put(searchFetchError(searchDetails));
        return;
      }

      formattedList = contactRecordsModifyResult(
        'search',
        response.intentList,
        'list',
        companyListNames,
        userDetails
      );
      yield put(searchFetchSuccess({ searchDetailsList, searchResult: formattedList || [] }));
    }

    yield put(setSearchLoadingFalse())
  } catch (error) {
    console.log('error', error);
    let searchDetails = {
      searchType: payloadList[0].metaFields.recipeType,
      payloadForm: payloadList[0],
      isSearchGoingOn: true,
    };
    yield put(searchFetchError(searchDetails));
  }
}

function* prefetchSearchResultAPICall(payload, token) {
  try {
    const {
      metaFields: { recipeType },
    } = payload;
    let response;
    let url = getSearchUrlFromSearchType(recipeType);

    let searchDetails = {
      searchType: recipeType,
      payloadForm: payload,
      isSearchGoingOn: true,
    };

    response = yield call(postFetchCall, url, payload, token);

    if (response.error) {
      throw response.error;
    }

    const userDetails = yield select(getUserDetails);
    const companyListNames = yield select(getCompanyListNames);

    let formattedList = contactRecordsModifyResult(
      'search',
      response.intentList,
      'list',
      companyListNames,
      userDetails
    );

    return { success: true, searchDetails, searchResult: formattedList };
  } catch (err) {
    return err;
  }
}

function* prefetchSearchSubmit({ payload }) {
  let { searchInput, recordNeed, recordGot } = payload;
  let searchDetails = {},
    searchResult = [];
  let response;
  try {
    // start loading
    yield put(setPrefetchSearchLoading());
    const token = yield call(getAuthToken);
    response = yield call(prefetchSearchResultAPICall, searchInput, token); // #st API call
    if (response.success) {
      // store it in search store
      yield put(updateSearchDetails(response.searchDetails));
      yield put(updateSearchResult(response.searchResult));

      searchDetails = response.searchDetails;
      searchResult = response.searchResult;
      recordGot += response.searchResult.length;

      if (!isPrefetchDone(recordNeed, recordGot)) {
        searchInput.payloadForm.pageNum += 1;
        response = yield call(prefetchSearchResultAPICall, searchInput, token); // 2nd API call

        if (response.success) {
          yield put(updateSearchDetails(response.searchDetails));
          yield put(updateSearchResult(response.searchResult));

          searchDetails = response.searchDetails;
          searchResult = response.searchResult;
          recordGot += response.searchResult.length;
          if (!isPrefetchDone(recordNeed, recordGot)) {
            // EOR
            yield put(errorPrefetchSearch({ searchDetails }));
          } else {
            // success
            yield put(successPrefetchSearch());
          }
        } else {
          // EOR
          yield put(errorPrefetchSearch({ searchDetails }));
        }
      } else {
        // success
        yield put(successPrefetchSearch());
      }
    } else {
      // EOR
      console.log(response);
      yield put(errorPrefetchSearch({ searchDetails }));
    }
  } catch (err) {
    // erorr
    console.log(response);

    yield put(errorPrefetchSearch({ searchDetails }));
  }
}

function* prefetchSearchListSubmit({ payload: payloadList }) {
  let { searchInputArr, recordNeed, recordGot } = payloadList;
  for (let i = 0; i < searchInputArr.length; i++) {
    let searchInput = searchInputArr[i];
    let searchDetails = {},
      searchResult = [];
    let response;
    try {
      // start loading
      yield put(setPrefetchSearchLoading());
      const token = yield call(getAuthToken);
      response = yield call(prefetchSearchResultAPICall, searchInput, token); // #st API call
      if (response.success) {
        // store it in search store
        yield put(updateSearchDetailsList({ idx: i, details: response.searchDetails }));
        yield put(updateSearchResult(response.searchResult));

        searchDetails = response.searchDetails;
        searchResult = response.searchResult;
        recordGot += response.searchResult.length;

        if (!isPrefetchDone(recordNeed, recordGot)) {
          searchInput.payloadForm.pageNum += 1;
          response = yield call(prefetchSearchResultAPICall, searchInput, token); // 2nd API call

          if (response.success) {
            yield put(updateSearchDetailsList({ idx: i, details: response.searchDetails }));
            yield put(updateSearchResult(response.searchResult));

            searchDetails = response.searchDetails;
            searchResult = response.searchResult;
            recordGot += response.searchResult.length;
            if (!isPrefetchDone(recordNeed, recordGot)) {
              // EOR
              yield put(errorPrefetchSearchList({ idx: i, details: searchDetails }));
            } else {
              // success
              yield put(successPrefetchSearchList(i));
            }
          } else {
            // EOR
            yield put(errorPrefetchSearchList({ idx: i, details: searchDetails }));
          }
        } else {
          // success
          yield put(successPrefetchSearchList(i));
        }
      } else {
        // EOR
        console.log(response);
        yield put(errorPrefetchSearchList({ idx: i, details: searchDetails }));
      }
    } catch (err) {
      // erorr
      console.log(response);

      yield put(errorPrefetchSearchList({ idx: i, details: searchDetails }));
    }
  }
}

export function* fetchSearchResult() {
  yield takeEvery(FETCH_SEARCH_RESULT, searchSubmit);
}
export function* fetchSearchListResult() {
  yield takeEvery(FETCH_SEARCH_LIST_RESULT, searchListSubmit);
}

export function* prefetchNextSearchResult() {
  yield takeEvery(START_PREFETCH_SEARCH, prefetchSearchSubmit);
}

export function* prefetchNextSearchListResult() {
  yield takeEvery(START_PREFETCH_SEARCH_LIST, prefetchSearchListSubmit);
}

export default function* rootSaga() {
  yield all([fork(fetchSearchResult)]);
  yield all([fork(fetchSearchListResult)]);
  yield all([fork(prefetchNextSearchResult)]);
  yield all([fork(prefetchNextSearchListResult)]);
}
