import React from 'react';
import { useLocation } from 'react-router-dom';
import EmailConfirmation from './EmailConfirmation';
import ResetPassword from './ResetPassword';

const ActionAuth = props => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const mode = urlParams.get('mode');
  const actionCode = urlParams.get('oobCode');

  // Components being render based on mode
  switch (mode) {
    case 'resetPassword':
      // Display email recovery handler and UI.
      return <ResetPassword actionCode={actionCode} />;
    case 'verifyEmail':
      // Display email verification handler and UI.
      return <EmailConfirmation actionCode={actionCode} />;
    default:
      // Error: invalid mode.
      return (
        <div className='app-wrapper animated slideInUpTiny animation-duration-3 d-flex align-items-center justify-content-center'>
          <div className='text-center'>
            <h1>Error encountered</h1>
            <p>The selected page mode is invalid.</p>
          </div>
        </div>
      );
  }
};

export default ActionAuth;
