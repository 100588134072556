import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showContactInfoMessage } from 'actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(0.5, 1),
    fontSize: '14px',
    background: '#222',
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: `${theme.spacing(0.5)}px`,
  },
  textButton: {
    border: 'none',
    outline: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: 0,
    fontWeight: 600,
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  textUnderline: {
    display: 'inline-block',
    borderBottom: '1px solid',
    fontWeight: 600,
  },
  copyIcon: {
    color: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
      width: '.8em',
      height: '.8em',
    },
  },
}));

const InteractivePopper = props => {
  const { text, uniqueId, copyText } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const storeDispatch = useDispatch();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    storeDispatch(showContactInfoMessage('Copied!!'));
  };

  const open = Boolean(anchorEl);
  const id = open ? `email-${uniqueId}-popper` : undefined;

  return (
    <>
      {text.length > 25 && (
        <>
          <button
            aria-describedby={id}
            type='text'
            onClick={handleClick}
            className={classes.textButton}
          >
            {text.slice(0, 25)} <span>...</span>
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography className={classes.typography}>
              {text}
              {copyText && (
                <CopyToClipboard text={text} onCopy={handleCopy}>
                  <MuiIconButton size='small' className={classes.copyIcon}>
                    <FileCopyIcon />
                  </MuiIconButton>
                </CopyToClipboard>
              )}
            </Typography>
          </Popover>
        </>
      )}
      {text.length <= 25 && <>{text}</>}
    </>
  );
};

InteractivePopper.propTypes = {
  text: PropTypes.string.isRequired,
  uniqueId: PropTypes.string.isRequired,
  copyText: PropTypes.bool.isRequired,
};

export default InteractivePopper;
