import React, { useEffect, useState } from 'react';
import { updatePeopleEnrichInfo } from 'actions';
import { useDispatch } from 'react-redux';

import {
  Container,
  Grid,
  InputBase,
  makeStyles,
  TextField,
  Typography,
  InputLabel,
} from '@material-ui/core';
import IconButton from '../IconButton';
import { IconButton as MuiIconButton } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DeleteIcon from '@material-ui/icons/Delete';
import NativeSelect from '@material-ui/core/NativeSelect';
import { withStyles } from '@material-ui/styles';

// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
// import { formatPhoneNumberIntl } from 'react-phone-number-input';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
    margin: theme.spacing(3, 2, 2),
    backgroundColor: theme.palette.common.white,
  },
  textFieldWrapper: {
    marginBottom: theme.spacing(2),
  },
  pageHeader: {
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  textArea: {
    width: `100%`,
    padding: theme.spacing(1),
  },
  formContent: {
    marginBottom: theme.spacing(2),
    '& option': {
      textTransform: 'capitalize',
    },
  },
  formControl: {
    width: '100%',
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
  },
  radioButtonGroup: {
    flexDirection: 'row',
  },
  phoneInputWrapper: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(1)}px`,
    '& input': {
      width: '100%',
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 12px',
    },
  },
}));

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
  },
}))(InputBase);

const EditPeopleDataModal = props => {
  const { peopleData, setSelectedPeopleData } = props;
  const classes = useStyles();
  const storeDispatch = useDispatch();

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    title: '',
    companyName: '',
    domain: '',
    location: '',
    lurl: '',
    bEmail: '',
    safeToSend: '',
    catchAll: '',
    confidence: '',
    titleScore: '',
    titleNote: '',
    accountScore: '',
    accountNote: '',
    personalNote: '',
  });

  const [personalMobile, setPersonalMobile] = useState([]);
  const [personalizationNotes, setPersonalizationNotes] = useState([]);

  useEffect(() => {
    // set current selected people Data when Modal is open
    if (peopleData) {
      const {
        contactRecord: {
          firstName,
          lastName,
          title,
          company,
          website,
          location,
          lurl,
          bEmail,
          pPhone,
        },
        customInfo: { accountScore, titleScore, personalizationNotes },
      } = peopleData;
      setValues({
        firstName: firstName,
        lastName: lastName,
        title: title,
        companyName: company.name,
        domain: website.domain,
        location: location.place,
        lurl: lurl,
        bEmail: bEmail[0]?.email,
        safeToSend: bEmail[0]?.valid || null,
        catchAll: bEmail[0]?.catchAll || null,
        confidence: bEmail[0]?.confidence || '0',
        titleScore: titleScore.value || 'none',
        titleNote: titleScore.reason,
        accountScore: accountScore.value || 'none',
        accountNote: accountScore.reason,
      });
      setPersonalMobile(pPhone);
      setPersonalizationNotes(personalizationNotes);
    }
  }, []);

  const handleOnChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnRadioChange = e => {
    // convert string boolean("true" or "false") to boolean
    const value = e.target.value === 'true';
    // update local form state
    setValues({
      ...values,
      [e.target.name]: value,
    });
  };

  const handleAddPhoneBtn = e => {
    const cnt = personalMobile.length;
    if (cnt && personalMobile[cnt - 1].number.trim() === '') {
      return;
    }
    setPersonalMobile(prevState => [
      ...prevState,
      { up: '', userCorrection: '', number: '', down: '' },
    ]);
  };

  const handleDeletePhone = idx => e => {
    const newPersonalMobile = [...personalMobile];
    newPersonalMobile.splice(idx, 1);
    setPersonalMobile(newPersonalMobile);
  };

  const handlePhoneChange = idx => val => {
    const phoneNumber = personalMobile[idx];
    const newPhoneNumber = {
      ...phoneNumber,
      number: val,
      // number: formatPhoneNumberIntl(val),
    };
    const newMobileArray = [...personalMobile];
    newMobileArray.splice(idx, 1, newPhoneNumber);
    setPersonalMobile(newMobileArray);
  };

  const handleAddNoteBtn = e => {
    const cnt = personalizationNotes.length;
    if (cnt && personalizationNotes[cnt - 1].msg.trim() === '') return;
    setPersonalizationNotes(prevState => [...prevState, { msg: '', date: Date.now() }]);
  };

  const handlePersonalNotesChange = idx => e => {
    const note = personalizationNotes[idx];
    const updatedNote = {
      ...note,
      msg: e.target.value,
    };
    const updatedNotesArray = [...personalizationNotes];
    updatedNotesArray.splice(idx, 1, updatedNote);
    setPersonalizationNotes(updatedNotesArray);
  };

  const handleDeletePersonalNote = idx => e => {
    const newPersonalNotes = [...personalizationNotes];
    newPersonalNotes.splice(idx, 1);
    setPersonalizationNotes(newPersonalNotes);
  };

  const goBack = () => setSelectedPeopleData(null);

  const handleSubmit = e => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      title,
      companyName,
      domain,
      location,
      lurl,
      bEmail,
      catchAll,
      confidence,
      titleScore,
      accountScore,
      titleNote,
      accountNote,
    } = values;
    storeDispatch(
      updatePeopleEnrichInfo({
        ...peopleData,
        contactRecord: {
          ...peopleData.contactRecord,
          firstName,
          lastName,
          title,
          company: {
            ...peopleData.contactRecord.company,
            name: companyName,
          },
          website: {
            ...peopleData.contactRecord.website,
            domain,
          },
          location: {
            ...peopleData.contactRecord.location,
            place: location,
          },
          lurl,
          bEmail: [
            {
              ...peopleData.contactRecord.bEmail[0],
              email: bEmail,
              catchAll,
              confidence,
            },
          ],
          pPhone: personalMobile,
        },
        customInfo: {
          ...peopleData.customInfo,
          personalizationNotes,
          titleScore: {
            ...peopleData.customInfo.titleScore,
            value: titleScore,
            reason: titleNote,
          },
          accountScore: {
            ...peopleData.customInfo.accountScore,
            value: accountScore,
            reason: accountNote,
          },
        },
      })
    );
    goBack();
  };

  const scoreValues = ['Highly Fit', 'Medium Fit', 'Low Fit'];

  return (
    <div className={classes.root}>
      <div className='align-self-start mb-2'>
        <h6 className='font-weight-light pointer' onClick={goBack}>
          {'< '} Back
        </h6>
      </div>
      <Container className={classes.pageHeader}>
        <Typography variant='h6' color='textPrimary'>
          Edit Details
        </Typography>
        <Typography variant='subtitle2' color='textPrimary' gutterBottom>
          Update any details of the contact
        </Typography>
      </Container>
      <form>
        <Grid container spacing={6} className={classes.formContent}>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='firstName'>
                First Name
              </InputLabel>
              <BootstrapInput
                type='text'
                id='firstName'
                name='firstName'
                onChange={handleOnChange}
                margin='none'
                value={values.firstName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='lastName'>
                Last Name
              </InputLabel>
              <BootstrapInput
                type='text'
                id='lastName'
                name='lastName'
                onChange={handleOnChange}
                margin='none'
                value={values.lastName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='title'>
                Title
              </InputLabel>
              <BootstrapInput
                type='text'
                id='title'
                name='title'
                onChange={handleOnChange}
                margin='none'
                value={values.title}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='companyName'>
                Company Name
              </InputLabel>
              <BootstrapInput
                type='text'
                id='companyName'
                name='companyName'
                onChange={handleOnChange}
                margin='none'
                value={values.companyName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='domain'>
                Domain
              </InputLabel>
              <BootstrapInput
                type='url'
                id='domain'
                name='domain'
                onChange={handleOnChange}
                margin='none'
                value={values.domain}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='location'>
                Location
              </InputLabel>
              <BootstrapInput
                type='text'
                id='location'
                name='location'
                onChange={handleOnChange}
                margin='none'
                value={values.location}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='lurl'>
                LinkedIn Url
              </InputLabel>
              <BootstrapInput
                type='text'
                id='lurl'
                name='lurl'
                onChange={handleOnChange}
                margin='none'
                value={values.lurl}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink htmlFor='bEmail'>
                Business Email
              </InputLabel>
              <BootstrapInput
                type='text'
                id='bEmail'
                name='bEmail'
                onChange={handleOnChange}
                margin='none'
                value={values.bEmail}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Safe To Send</FormLabel>
              <RadioGroup
                aria-label='safeToSend'
                name='safeToSend'
                value={values.safeToSend}
                onChange={handleOnRadioChange}
                className={classes.radioButtonGroup}
              >
                <FormControlLabel value={true} control={<Radio />} label='True' />
                <FormControlLabel value={false} control={<Radio />} label='False' />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl component='fieldset' size='small'>
              <FormLabel component='legend'>Catch All</FormLabel>
              <RadioGroup
                aria-label='catchAll'
                name='catchAll'
                value={values.catchAll}
                onChange={handleOnRadioChange}
                className={classes.radioButtonGroup}
              >
                <FormControlLabel value={true} control={<Radio />} label='True' />
                <FormControlLabel value={false} control={<Radio />} label='False' />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item container xs={12} spacing={3}>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor='confidence'>
                  Confidence
                </InputLabel>
                <BootstrapInput
                  type='number'
                  id='confidence'
                  name='confidence'
                  onChange={handleOnChange}
                  margin='none'
                  value={values.confidence}
                  endAdornment={<InputAdornment position='end'>{'%'}</InputAdornment>}
                  inputProps={{
                    step: '.1',
                    min: '0',
                    max: '100',
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor='titleScore'>
                  Title Score
                </InputLabel>
                <NativeSelect
                  value={values.titleScore}
                  onChange={handleOnChange}
                  name='titleScore'
                  inputProps={{
                    id: 'titleScore',
                  }}
                  size='small'
                  fullWidth
                  input={<BootstrapInput />}
                >
                  <option key={0} value='none'>
                    No Score
                  </option>
                  {scoreValues.map((score, idx) => (
                    <option key={idx + 1} value={score}>
                      {score}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor='accountScore'>
                  Account Score
                </InputLabel>
                <NativeSelect
                  value={values.accountScore}
                  onChange={handleOnChange}
                  name='accountScore'
                  inputProps={{
                    id: 'accountScore',
                  }}
                  size='small'
                  fullWidth
                  input={<BootstrapInput />}
                >
                  <option key={0} value='none'>
                    No Score
                  </option>
                  {scoreValues.map((score, idx) => (
                    <option key={idx + 1} value={score}>
                      {score}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Personal Mobile</FormLabel>
              {personalMobile &&
                personalMobile.map((phone, idx) => (
                  <div key={idx} className={classes.phoneInputWrapper}>
                    {/* <PhoneInput
                      name='pPhone'
                      value={phone.number}
                      onChange={handlePhoneChange(idx)}
                    /> */}
                    <MuiIconButton size='small' onClick={handleDeletePhone(idx)}>
                      <DeleteIcon />
                    </MuiIconButton>
                  </div>
                ))}
              <span>
                <IconButton color='primary' onClick={handleAddPhoneBtn}>
                  Add Phone
                </IconButton>
              </span>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormLabel component='legend'>Title Note</FormLabel>
              <TextareaAutosize
                className={classes.textArea}
                aria-label='minimum height'
                rowsMin={3}
                placeholder='Add title note here...'
                name='titleNote'
                value={values.titleNote}
                onChange={handleOnChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormLabel component='legend'>Account Note</FormLabel>
              <TextareaAutosize
                className={classes.textArea}
                aria-label='minimum height'
                rowsMin={3}
                placeholder='Add account note here...'
                name='accountNote'
                value={values.accountNote}
                onChange={handleOnChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormLabel component='legend'>Personalization Notes</FormLabel>
              {personalizationNotes &&
                personalizationNotes.map((note, idx) => (
                  <div key={idx} className={classes.phoneInputWrapper}>
                    <TextareaAutosize
                      aria-label='minimum height'
                      rowsMin={3}
                      placeholder='Add a Personal Note'
                      onChange={handlePersonalNotesChange(idx)}
                      value={note.msg}
                      className={classes.textArea}
                    />
                    <MuiIconButton size='small' onClick={handleDeletePersonalNote(idx)}>
                      <DeleteIcon />
                    </MuiIconButton>
                  </div>
                ))}
              <span>
                <IconButton color='primary' onClick={handleAddNoteBtn}>
                  Add Note
                </IconButton>
              </span>
            </FormControl>
          </Grid>
        </Grid>

        <div className={classes.formFooter}>
          <IconButton
            className='jr-btn'
            size='medium'
            variant='contained'
            onClick={goBack}
            type='button'
          >
            <span>Cancel</span>
          </IconButton>

          <IconButton
            className='jr-btn'
            size='medium'
            variant='contained'
            color='primary'
            aria-label='add'
            onClick={handleSubmit}
            type='button'
          >
            <span>Save</span>
          </IconButton>
        </div>
      </form>
    </div>
  );
};

export default EditPeopleDataModal;
