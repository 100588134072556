import React, { Component, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth } from '../firebase/firebase';
import { userSignOut } from 'actions/Auth';
import { makeStyles } from '@material-ui/core';
import './authStylings.css';

// stylings
const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    minHeight: '80vh',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    backgroundColor: '#ffff',
    margin: '50px',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(196, 196, 196, 0.12)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '60px 60px 40px',
  },
  right: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#fff',
  },
}));

// main components
const EmailConfirmationCheck = props => {
  const [userEmail, setUserEmail] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles();

  const reRegister = () => {
    window.sessionStorage.setItem('gotoRegister', true);
    dispatch(userSignOut());
  };
  useEffect(() => {
    let unsubscribe;
    unsubscribe = auth.onAuthStateChanged(async function(user) {
      if (user) {
        console.log('firebase email verified = ' + user.emailVerified);
        setUserEmail(user.email);
        if (user.emailVerified) props.history.push('/app/home');
      }
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <div className='auth-card'>
      <div className={classes.main}>
        <div className={classes.left + ' animated slideInUpTiny animation-duration-3'}>
          <div
            className='text-center d-flex justify-content-center align-items-center'
            style={{ height: '100%' }}
          >
            <div>
              <h4 style={{ lineHeight: '1.7' }}>
                We have sent a confirmation email to <span color='primary'>{userEmail}</span>
              </h4>
              <h4>Please check</h4>
              <Link to='' onClick={reRegister}>
                Is this wrong email? Please re-register
              </Link>
            </div>
          </div>
        </div>
        {/* right side image */}
        <div className={classes.right}>
          {/* <h2
              style={{
                color: "#717171",
                fontSize: "22px",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Discover Accounts, Score and Qualify <br /> leads with us
            </h2> */}
          {/* <img width='70%' src={require('assets/images/conveyer-belt.png')} alt='conveyer' /> */}
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmationCheck;
