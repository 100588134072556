import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Button, Paper, Typography, Box } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import MoveContactsPopup from '../../../components/MoveContactsPopup';
import CircularProgress from '@material-ui/core/CircularProgress';

/** */
import ResultListTable from '../../../components/resultListTable';
import TablePagination from '../../../components/tablePagination';

import {
  hideContactMessage,
  getLatestPeopleNRecords,
  moveContactToList,
  updateCompanyEnrich,
  getViewCustomList,
  startCompanyUpdate,
} from 'actions/Contact';

import {
  handleDownloadSearchCSV,
  getNumberOfCompanyLatestFetchRecords,
  companyTableDisplayData,
} from '../../../util';

import MoveIcon from 'assets/images/MoveIcon.svg';
import CloudDownload from 'assets/images/CloudDownload.svg';

const Company = props => {
  const {
    contactsLoader,
    contactsShowMessage,
    contactsAlertMessage,
    contactsInfoMessage,
    companyEnrichedList,
    companyListNames,
    fetchingCompanyEnrichListOver,
  } = useSelector(({ contacts }) => contacts);

  const storeDispatch = useDispatch();
  const classes = useStyles();

  /**
   * State variables
   */
  /** */
  const showFilter = false;
  const [movepopupOpen, setMovepopupOpen] = useState(false);
  const [currentPageList, setCurrentPageList] = useState([]);
  const [finalResult, setFinalResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // 10 per page
  const [allItemSelected, setAllItemSelected] = useState(false);
  const [selectedListId, setSelectedListId] = React.useState(null);
  const [currentCompanyListAPIPage, setcurrentCompanyListAPIPage] = useState(0); //starts from 0

  const getDefaultCompanyListName = () => companyListNames.find(item => item.isDefault == true);



  useEffect(() => {
    if (
      companyEnrichedList.length > 0 &&
      companyEnrichedList.length >= (currentPage - 1) * perPage
    ) {
      let showList = companyEnrichedList.slice((currentPage - 1) * perPage, currentPage * perPage);
      let finalList = companyTableDisplayData('Company', showList, allItemSelected, false);
      setCurrentPageList(finalList);
      setFinalResult(finalList);
      console.log('final list', finalList);

      if (!fetchingCompanyEnrichListOver && currentPage > companyEnrichedList.length / perPage) {
        //companyEnrichedList related to the selected listname
        if (selectedListId) getViewList(selectedListId, currentCompanyListAPIPage + 1);
        else
          getLatestNRecords(getNumberOfCompanyLatestFetchRecords * (currentCompanyListAPIPage + 2)); //50 *

        setcurrentCompanyListAPIPage(currentCompanyListAPIPage + 1);
      }
    } else setCurrentPageList([]);
  }, [currentPage, companyEnrichedList]);

  // Size option set array for mapping
  let newSizeArr = [];
  let uniqueSizeArr = [];
  finalResult.map(x =>{
  let a = (x['Size/ Industry/ Geo'].value.mainText);
  newSizeArr = [...newSizeArr,a];
  uniqueSizeArr = [...new Set(newSizeArr)];
  });

  uniqueSizeArr.forEach((el, i) => {
    if (el.trim() === "") {
      uniqueSizeArr[i] = "empty";
    }
  });

  uniqueSizeArr.sort((x, y) => {
    if (x === "10001+") return 1;
    if (y === "10001+") return -1;

    const xLower = Number(x.split(' ')[0]); 
    const yLower = Number(y.split(' ')[0]); 

    return xLower - yLower; 
  });

  // End of company size set array

  console.log(uniqueSizeArr)

  const updateCompanyData = companyRecordSet => {
    storeDispatch(startCompanyUpdate(companyRecordSet));
  };

  const handleResultListItemClick = (infoRow, field) => {
    let type = infoRow[field]?.clickType || field;
    let selectedIndex = infoRow.id.value;

    switch (type) {
      case 'crm-save': {
        let companyRecordSet;
        companyRecordSet = companyEnrichedList.find(people => people.id === selectedIndex);
        updateCompanyData(companyRecordSet);
        break;
      }

      default:
        console.log('Click type is not found', type);
        break;
    }
  };

  const handleGoPrevious = changePage => setCurrentPage(changePage);
  const handleGoNext = changePage => setCurrentPage(changePage);

  /**
   * select All
   */
  const onAllCheckChange = () => {
    let selctAllItems = false;

    let checkedList = checkHowManySelected();
    checkedList.length == 0
      ? (selctAllItems = true)
      : checkedList.length == currentPageList.length
      ? (selctAllItems = false)
      : (selctAllItems = true);

    let checkedItemList = currentPageList.map(item => {
      item.checked.value = selctAllItems;
      return item;
    });

    let list;

    //If all items need to select
    if (selctAllItems)
      list = companyEnrichedList.map(job => {
        return {
          ...job,
          selected: true,
        };
      });
    else
      list = companyEnrichedList.map(job => {
        return {
          ...job,
          selected: false,
        };
      });

    setAllItemSelected(selctAllItems);
    setCurrentPageList([...checkedItemList]);

    storeDispatch(updateCompanyEnrich([...list]));
  };

  const checkHowManySelected = () => currentPageList.filter(item => item.checked.value);

  /**
   * each item click event
   * @param {*} clickedIndex
   */
  const onindividualCheckChange = clickedIndex => {
    let selectedIndex = (currentPage - 1) * perPage + clickedIndex;
    let changeList = currentPageList.map((item, i) => {
      if (i == clickedIndex) {
        item.checked.value = !item.checked.value;
        companyEnrichedList[selectedIndex].selected = !companyEnrichedList[selectedIndex].selected;

        storeDispatch(updateCompanyEnrich([...companyEnrichedList]));
      }

      return item;
    });

    let selectedList = checkHowManySelected();
    if (selectedList.length == currentPageList.length) setAllItemSelected(true);
    else setAllItemSelected(false);

    setCurrentPageList([...changeList]);
  };

  const selectedjobs = () => companyEnrichedList.filter(contact => contact.selected);

  const isReachedEndOfPage = () => {
    if (fetchingCompanyEnrichListOver && currentPage >= companyEnrichedList.length / perPage)
      return true;

    return false;
  };

  const filterSize = (tookSize)=>{
    const filterResult = finalResult.filter(size =>
      size['Size/ Industry/ Geo'].value.mainText == tookSize);
      console.log(filterResult);
  }


  const handleListNameSelectChange = event => {
    let selectedLid = event.target.value;
    setSelectedListId(selectedLid);
    setcurrentCompanyListAPIPage(0);
    setCurrentPageList([]);
    setCurrentPage(1);
    setAllItemSelected(false);

    if (selectedLid == 'none') {
      //load N records
      getLatestNRecords(getNumberOfCompanyLatestFetchRecords);
    } else {
      //filter
      getViewList(selectedLid, 0);
    }
  };

 
  const handleListSizeSelectChange = event => {
    let selectedSize = event.target.value;
    console.log(selectedSize);
    setSelectedListId(selectedSize);

    if (selectedSize == 'none') {
      //load N records
      getLatestNRecords(getNumberOfCompanyLatestFetchRecords);
    } else {
      //filter
      filterSize(selectedSize);
    }
  };

  /*
    *
    call people list api
    hide message
    */
  useEffect(() => {
    getLatestNRecords(getNumberOfCompanyLatestFetchRecords)
    return () => {
      storeDispatch(hideContactMessage());

    };
  }, []);

  useEffect(() => {
    if (contactsShowMessage)
      // setTimeout(() => {
      storeDispatch(hideContactMessage());
    // }, 100);
  }, [contactsShowMessage]);

  const getLatestNRecords = recordCount => {
    let payload = {
      uid: localStorage.getItem('user_id'),
      cat: 'company',
      recordCount: recordCount,
    };

    storeDispatch(getLatestPeopleNRecords(payload));
  };

  const getViewList = (lid, page) => {
    let payload = {
      uid: localStorage.getItem('user_id'),
      lid: lid,
      cat: 'company',
      page: page,
    };

    storeDispatch(getViewCustomList(payload));
  };
  const noResultFound = () => {
    if (companyEnrichedList.length > 0) return false;

    return true;
  };

  const downloadFile = () => {
    handleDownloadSearchCSV(selectedjobs(), 'company');
  };

  //multi move selected
  const multiMoveToSelectedListName = listObj => {
    let payloadList = [];
    companyEnrichedList.map(job => {
      if (
        currentPageList.findIndex(
          selectedJob =>
            selectedJob.checked.value &&
            job.id == selectedJob.id.value &&
            job.identity.recordId != ''
        ) > -1 &&
        job.lid != listObj.lid
      ) {
        let payload = {
          recodId: job.identity.recordId,
          cat: job.identity.cat,
          lidFrom: job.identity.lid,
          lidTo: listObj.lid,
          lidToName: listObj.lnm,
          isNew: false,
        };
        payloadList.push(payload);
      }
    });

    console.log('multiMoveToSelectedListName ', payloadList);

    if (payloadList.length > 0) storeDispatch(moveContactToList(payloadList));

    setMovepopupOpen(false);
  };

  //multi move to new list name
  const multiMoveToNewListName = lnm => {
    let payloadList = [];
    companyEnrichedList.map(job => {
      if (
        currentPageList.findIndex(
          selectedJob =>
            selectedJob.checked.value &&
            job.id == selectedJob.id.value &&
            job.identity.recordId != ''
        ) > -1
      ) {
        let payload = {
          recodId: job.identity.recordId,
          cat: job.identity.cat,
          lidFrom: job.identity.lid,
          lidTo: '',
          lidToName: lnm,
          isNew: true,
        };
        payloadList.push(payload);
      }

      return job;
    });

    console.log('multiMoveToNewListName ', payloadList);

    if (payloadList.length > 0) storeDispatch(moveContactToList(payloadList));

    setMovepopupOpen(false);
  };

  //move item
  //when selected another listitem from the list
  const handleListItemSelected = (row, selectedListObj) => {
    let selectedIndex = row.id.value;
    let record = companyEnrichedList[selectedIndex];
    let identityRow = record.identity;
    console.log('selected row', record);
    console.log('selected list name', selectedListObj);

    let payload = {
      recodId: identityRow.recordId,
      cat: identityRow.cat,
      lidFrom: identityRow.lid,
      lidTo: selectedListObj.lid,
      lidToName: selectedListObj.lnm,
      isNew: false,
    };

    storeDispatch(moveContactToList([payload]));
  };

  //create and move
  const handleNewListItemCreated = (row, lnm) => {
    let selectedIndex = row.id.value;
    let record = companyEnrichedList[selectedIndex];
    let identityRow = record.identity;
    console.log('selected row', record);
    console.log('selected list name', lnm);

    let payload = {
      recodId: identityRow.recordId,
      cat: identityRow.cat,
      lidFrom: identityRow.lid,
      lidTo: '',
      lidToName: lnm,
      isNew: true,
    };

    storeDispatch(moveContactToList([payload]));
  };

  return (
    <div className='app-wrapper'>
      <div className='animated slideInUpTiny animation-duration-3'>
        <div className={classes.mainGrid}>
          {/* List Select */}
          <div className={classes.firstDiv}>
            <Typography variant='h6'>
              <Box fontWeight='fontWeightBold'>Target Accounts</Box>
            </Typography>
            <Typography variant='body2' gutterBottom>
              Collection of accounts that are best fit for account based marketing
            </Typography>
            {(selectedListId || companyEnrichedList.length > 0) && (
              <div className='d-flex mt-5'>
                <TextField
                  id='outlined-select-names-native'
                  select
                  label='Pick one stream'
                  value={selectedListId || ''}
                  onChange={handleListNameSelectChange}
                  variant='outlined'
                  className={classes.dropDown}
                  helperText='Filter by list name'
                  SelectProps={{
                    native: true,
                  }}
                  size='small'
                >
                  <option key={0} value='none'>
                    None
                  </option>

                  {companyListNames.sort((a, b) =>
                          a.lnm.toLowerCase() < b.lnm.toLowerCase()
                            ? -1
                            : a.lnm.toLowerCase() > b.lnm.toLowerCase()
                            ? 1
                            : 0
                        ).map(option => (
                    <option key={option.lid} value={option.lid}>
                      {option.lnm}
                    </option>
                  ))}
                </TextField>
                <div
                  className='d-flex flex-grow-1 align-items-center justify-content-end'
                  style={{ gap: '10px' }}
                >
                  {checkHowManySelected().length > 0 && (
                    <>
                      <Button
                        variant='contained'
                        color='primary'
                        aria-label='add'
                        startIcon={<img src={MoveIcon} />}
                        onClick={() => setMovepopupOpen(!movepopupOpen)}
                      >
                        <strong style={{ textTransform: 'capitalize' }}>Move</strong>
                      </Button>
                      <Button
                        variant='outlined'
                        color='primary'
                        aria-label='add'
                        onClick={downloadFile}
                        startIcon={<img src={CloudDownload} />}
                      >
                        <strong style={{ textTransform: 'capitalize' }}>Download</strong>
                      </Button>
                    </>
                  )}
                </div>

                  {/* New Option box */}
                  {/* <TextField
                  id='outlined-select-names-native'
                  select
                  label='Pick one stream'
                  value={selectedListId || ''}
                  onChange={handleListSizeSelectChange}
                  variant='outlined'
                  className={classes.dropDown}
                  helperText='Filter by Company Size'
                  SelectProps={{
                    native: true,
                  }}
                  size='small'
                >
                  <option key={0} value='none'>
                    None
                  </option>
                  {uniqueSizeArr.map((option) =>(
                    <option key={option.option} value={option}>
                    {option}
                  </option>
                  ))}
                </TextField>
                <div
                  className='d-flex flex-grow-1 align-items-center justify-content-end'
                  style={{ gap: '10px' }}
                >
                  {checkHowManySelected().length > 0 && (
                    <>
                      <Button
                        variant='contained'
                        color='primary'
                        aria-label='add'
                        startIcon={<img src={MoveIcon} />}
                        onClick={() => setMovepopupOpen(!movepopupOpen)}
                      >
                        <strong style={{ textTransform: 'capitalize' }}>Move</strong>
                      </Button>
                      <Button
                        variant='outlined'
                        color='primary'
                        aria-label='add'
                        onClick={downloadFile}
                        startIcon={<img src={CloudDownload} />}
                      >
                        <strong style={{ textTransform: 'capitalize' }}>Download</strong>
                      </Button>
                    </>
                  )}
                </div> */}
                {/* Option box end */}
               
              </div>
            )}
          </div>
          {/* Results */}
          <Paper className={classes.listGrid}>
            {!contactsLoader && noResultFound() && (
              <strong style={{ textAlign: 'center' }}> Sorry !! No results found.</strong>
            )}

            {companyEnrichedList.length > 0 && (
              <div className={classes.searchScroll}>
                {currentPageList.length > 0 && (
                  <ResultListTable
                    result={currentPageList}
                    showFilter={showFilter}
                    selectionAvailable={true}
                    onAllCheckChange={onAllCheckChange}
                    onindividualCheckChange={onindividualCheckChange}
                    // moreInfo={moreInfo}
                    itemClick={handleResultListItemClick}
                    autoCompleteData={companyListNames}
                    autoSelected={handleListItemSelected}
                    autonewItemCreated={handleNewListItemCreated}
                    resultType='companyList'
                  />
                )}
              </div>
            )}
            {companyEnrichedList.length > 0 && (
              <TablePagination
                currentPage={currentPage}
                isreachedEnd={isReachedEndOfPage}
                handleGoPrevious={handleGoPrevious}
                handleGoNext={handleGoNext}
              />
            )}
          </Paper>
          {/* Pagination */}
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={movepopupOpen}>
        <MoveContactsPopup
          open={movepopupOpen}
          data={companyListNames}
          selected={getDefaultCompanyListName}
          closePopup={() => setMovepopupOpen(false)}
          moveToSelectedListName={multiMoveToSelectedListName}
          moveToNewListName={multiMoveToNewListName}
        />
      </Backdrop>

      <Backdrop className={classes.backdrop} open={contactsLoader}>
        <div className='fixContainer'>
          <div className='loader-view'>
            <CircularProgress />
          </div>
        </div>
      </Backdrop>

      {contactsShowMessage != '' &&
        contactsAlertMessage != '' &&
        NotificationManager.error(contactsAlertMessage)}
      {contactsShowMessage &&
        contactsInfoMessage != '' &&
        NotificationManager.success(contactsInfoMessage)}
      <NotificationContainer />
    </div>
  );
};

export default Company;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  root: {
    flexGrow: 1,
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 600,
  },
  dropDown: {
    width: 250,
  },
  searchGif: {
    maxWidth: 70,
  },
  resultGrid: {
    display: 'grid',
    gridTemplateRows: '1fr',
  },
  searchScroll: {
    overflowY: 'hidden',
    height: '100%',
  },

  formPaper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 600,
  },

  mainGrid: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    // backgroundColor: "red",
    height: '86vh',
  },
  listGrid: {
    maxHeight: '74vh',
    display: 'grid',
    alignItems: 'center',
    gridTemplateRows: '1fr auto',
    marginTop: '6px',
    width: '85%',
    [theme.breakpoints.up('lg')]: {
      width: '80%',
    },
  },
}));
