import Papa from 'papaparse';
import {
  NOTSTARTED,
  SELECTED,
  STARTED,
  DONE,
  REVIEW_THRESHOLD_ENRICH_COUNT,
  SEARCH_EXEC_MOVEMENT,
  SEARCH_RECRUITMENT_BUYER,
  SEARCH_RECRUITMENT_USER,
  SEARCH_TOPIC_SURGE,
  SEARCH_COMPANY_LABEL,
  SEARCH_SIMILAR_INTENT,
  SEARCH_SAME_VC_INTENT,
  SEARCH_DUMMY_3,
} from 'constants/ActionTypes';
// import faker from 'faker';

import moment from 'moment';

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

const salesNavUserPlaybook = { pid: 8, name: 'Sales navigator user search' };
const salesNavCompanyPlaybook = {
  pid: 9,
  name: 'Sales navigator company search',
};
const enrichBusiness1 = { pid: 11, name: 'Enrich business contact info' };
const enrichPersonal2 = { pid: 12, name: 'Enrich personal contact info' };

const salesNavMaxPageScrape = 2;

const getPlaybooksList = [
  { pid: 1, name: 'Send connection' },
  { pid: 2, name: 'Send message' },
  { pid: 3, name: 'Read message' },
  { pid: 4, name: 'Get company details' },
  { pid: 5, name: 'Endorse skill' },
  { pid: 6, name: 'Discover email' },
  { pid: 7, name: 'Profile search' },
];

const getTotalPlaybooksList = [
  { pid: 1, name: 'Send connection' },
  { pid: 2, name: 'Send message' },
  { pid: 3, name: 'Read message' },
  { pid: 4, name: 'Get company details' },
  { pid: 5, name: 'Endorse skill' },
  { pid: 6, name: 'Discover email' },
  { pid: 7, name: 'Profile search' },
  { pid: 8, name: 'Sales nav user search' },
  { pid: 9, name: 'Sales nav company search' },
  { pid: 10, name: 'Get contact info' },
];

function handleDownloadCSV(contactsList, array, fileName) {
  var csv =
    contactsList && contactsList.length > 0 ? Papa.unparse(contactsList) : Papa.unparse(array);
  var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  var csvURL = null;
  if (navigator.msSaveBlob) csvURL = navigator.msSaveBlob(csvData, fileName + '.csv');
  else csvURL = window.URL.createObjectURL(csvData);

  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName + '.csv');
  tempLink.click();
}

function handleDownloadSearchCSV(contactsList, searchType) {
  console.log(contactsList);

  var csv = Papa.unparse(modifyListForCSV(contactsList, searchType));

  var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  var csvURL = null;
  if (navigator.msSaveBlob) csvURL = navigator.msSaveBlob(csvData, searchType + '.csv');
  else csvURL = window.URL.createObjectURL(csvData);

  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', searchType + '.csv');
  tempLink.click();
}

const modifyListForCSV = (contactList, searchType) => {
  switch (searchType) {
    case 'people':
      return contactList.map(item => {
        let contact = item.contactRecord;
        let customInfo = item.customInfo;

        let personalNote = customInfo.personalizationNotes.reduce(
          (acc, cur) => acc + cur.msg.parsedDelta + '\n\n',
          ''
        );

        return {
          'First Name': contact.firstName,
          'Last Name': contact.lastName,
          Title: contact.title,
          'Company Name': contact.company.name,
          Website: 'https://' + contact.website.domain,
          Domain: contact.website.domain,
          'Profile Score': customInfo.titleScore.value,
          'Account Score': customInfo.accountScore.value,
          'Account Notes': `${customInfo.titleScore.reason.parsedDelta}\n\n${customInfo.accountScore.reason.parsedDelta}`,
          'Personalization Notes': personalNote,
          Location: contact.location.address,
          Country: '',
          Industry: contact.industry,

          'Business Email 1': contact.bEmail.length === 1 ? contact.bEmail[0].email : '',
          'Business Email 1 Safe to Send':
            contact.bEmail.length === 1 ? contact.bEmail[0].valid : '',
          'Business Email 1 CatchAll':
            contact.bEmail.length === 1 ? contact.bEmail[0].catchAll : '',
          'Business Email 1 Confidence':
            contact.bEmail.length === 1 ? `${contact.bEmail[0].confidence} %` : '',

          'Business Phone1': contact.bPhone.length > 1 ? contact.bPhone[0].number : '',

          'Personal Phone1': contact.pPhone.length > 1 ? contact.pPhone[0].number : '',
          'Personal Phone2': contact.pPhone.length > 2 ? contact.pPhone[1].number : '',
          'Personal Phone3': contact.pPhone.length > 3 ? contact.pPhone[2].number : '',
          'Personal Phone4': contact.pPhone.length > 4 ? contact.pPhone[3].number : '',
          'Personal Phone5': contact.pPhone.length > 5 ? contact.pPhone[4].number : '',

          'Personal Email1': contact.pEmail.length > 1 ? contact.pEmail[0].email : '',
          'Personal Email2': contact.pEmail.length > 2 ? contact.pEmail[1].email : '',

          LinkedIn: contact.lurl,
          Facebook: '',
          Twitter: '',
        };
      });

    case 'company':
      return contactList.map(item => {
        let contact = item.companyRecord;
        let location = contact.location.split(', ');
        return {
          'Company Name': contact.name,
          Website: 'https://' + contact.domain,
          Domain: contact.domain,
          Location: contact.location,
          About: contact.about,
          Funding: contact.funding || '',
          Industry: contact.industry,
          Size: contact.size,
          // "Revenue": contact.revenue,
          Country: location[location.length - 1],
          'Business Phone1': '',
          'Business Phone2': '',
          'Business Phone3': '',
          LinkedIn: contact.lurl,
          Facebook: contact.social.fb,
          Twitter: contact.social.twitter,
          CrunchBase: contact.social.cb,
        };
      });

    default:
      return [];
  }
};

/**
 * Manipulating result
 * to save it in the store
 *
 */
const contactRecordsModifyResult = (navSearch, data, type, listNames, userDetails) => {
  let peopleDefaultLeadList;
  let companyDefaultLeadList;
  let list = [];
  let { fn, ln, cid, cn } = userDetails;

  switch (navSearch + '_' + type) {
    case 'people_list':
      list = data.map((dataItem, index) => {
        let item = dataItem.targetSet;
        return {
          contactRecord: {
            firstName: item.firstName,
            lastName: item.lastName,
            // "link": item.lid,//linkedin profile URL
            lurl: item.lurl, //linkedin profile URL
            lid: item.lid,
            createdTime: '',
            title: item.title,
            imgUrl: '',
            company: item.company,
            industry: '',
            website: item.website,
            location: item.location,
            keywords: item.keywords,
            bEmail: item.bEmail,
            bPhone: item.bPhone,
            pEmail: item.pEmail,
            pPhone: item.pPhone,
            social: item.social,
          },
          customInfo: item?.customInfo,
          enrichmentDetails: {
            enrichmentJobId: item.jobId || '',
            enrichmentType: item.enrichmentDetails.enrichmentType,
            isEnrichment_E1_GoingOn:
              item.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED
                ? DONE
                : item.enrichmentDetails.isEnrichment_E1_GoingOn,
            isEnrichment_E2_GoingOn:
              item.enrichmentDetails.isEnrichment_E2_GoingOn == STARTED
                ? DONE
                : item.enrichmentDetails.isEnrichment_E2_GoingOn,
            reviewThreholdCount: REVIEW_THRESHOLD_ENRICH_COUNT,
            reviewCount: 0,
            isOnlyE2Required: item.enrichmentDetails.isOnlyE2Required,
          },
          identity: {
            ...item.identity,
            recordId: dataItem.cnid,
            lid: dataItem.lid,
            lnm: dataItem.lnm,
          },
          customInfo: {
            ...item.customInfo,
          },
          selected: false,
          id: index,
        };
      });
      break;

    case 'company_list':
      list = data.map((dataItem, index) => {
        let { identity } = dataItem.targetSet;
        let companyRecord = dataItem.targetSet;
        if (!identity) {
          // for old records
          identity = {
            recordId: dataItem.compid,
            cat: dataItem.cat,
            lid: dataItem.lid,
            lnm: dataItem.lnm,
            // status: dataItem.status,
            cid: dataItem.cid,
            uid: dataItem.uid,
            type: dataItem.type,
            sync: dataItem.sync,
          };
        }
        delete dataItem.targetSet;
        return {
          identity: {
            ...identity,
            recordId: dataItem.compid,
            lid: dataItem.lid,
            lnm: dataItem.lnm,
          },
          companyRecord,
          id: index,
          selected: false,
        };
      });
      break;

    case 'people_T1':
      peopleDefaultLeadList = listNames.find(item => item.isDefault == true);
      list = data.map((item, index) => {
        return {
          contactRecord: {
            firstName: item.firstname,
            lastName: item.lastname,
            // "link": item.lid,//linkedin profile URL
            lurl: item.lid, //linkedin profile URL
            lid: '',
            createdTime: '',
            title: item.designation,
            imgUrl: '',
            company: {
              name: item.companyName,
              up: '',
              down: '',
              userCorrection: '',
            },
            industry: '',
            website: {
              domain: '',
              url: '',
              up: '',
              down: '',
              userCorrection: '',
            },
            location: {
              place: '',
              address: '',
              exactAddress: '',
              up: '',
              down: '',
              userCorrection: '',
            },
            keywords: [],
            bEmail: [],
            bPhone: [],
            pEmail: [],
            pPhone: [],
            social: {
              twitter: `https://www.twitter.com/search?q=${item.firstname} ${item.lastname}`,
              fB: `https://www.facebook.com/public/${item.firstname} ${item.lastname}`,
              li: item.lid,
            },
          },
          enrichmentDetails: {
            enrichmentJobId: '',
            enrichmentType: '',
            isEnrichment_E1_GoingOn: NOTSTARTED,
            isEnrichment_E2_GoingOn: NOTSTARTED,
            reviewThreholdCount: REVIEW_THRESHOLD_ENRICH_COUNT,
            reviewCount: 0,
            isOnlyE2Required: false,
          },
          identity: {
            recordId: '', // API will return
            lid: peopleDefaultLeadList.lid,
            lnm: peopleDefaultLeadList.lnm,
            uid: localStorage.user_id, //Front end will fill up
            userName: fn + ' ' + ln, // Front end will fill up
            cid: cid, // Front end will fill up
            cat: 'people', // Front end will fill up “people/company”
            type: 'user', //user/system
            status: '',
            sync: 'NA',
          },
          customInfo: {
            personalizationNotes: [],
            titleScore: {
              value: '',
              reason: '',
            },
            accountScore: {
              value: '',
              reason: '',
            },
          },
          selected: false,
          id: index,
        };
      });
      break;

    case 'company_T1':
      companyDefaultLeadList = listNames.find(item => item.isDefault == true);
      list = data.map((item, index) => {
        return {
          contactRecord: {
            name: '',
            about: '',
            founded: '',
            funding: '',
            industry: item.cIndustry,
            size: item.cEmpCnt,
            // "link": item.link,//linkedin profile URL
            lurl: item.link, //linkedin profile URL
            createdTime: '',
            company: {
              name: item.cName,
              up: '',
              down: '',
              userCorrection: '',
            },
            industry: '',
            website: {
              domain: item.domain,
              url: item.website,
              up: '',
              down: '',
              userCorrection: '',
            },
            location: {
              place: item.city,
              address: '',
              exactAddress: '',
              up: '',
              down: '',
              userCorrection: '',
            },
            keywords: [],
            bEmail: [],
            bPhone: [],
            pEmail: [],
            pPhone: [],
            social: {
              twitter: `https://www.twitter.com/search?q=${item.firstname} ${item.lastname}`,
              fB: `https://www.facebook.com/public/${item.firstname} ${item.lastname}`,
              li: item.lid,
            },
          },

          identity: {
            recordId: '', // API will return
            lid: '', //companyDefaultLeadList.lid,
            lnm: '', //companyDefaultLeadList.lnm,
            uid: localStorage.user_id, //Front end will fill up
            userName: fn + ' ' + ln, // Front end will fill up
            cid: '', // Front end will fill up
            cat: 'company', // Front end will fill up “people/company”
            type: 'user', //user/system
            // status: "done",
            sync: 'NA',
          },
          enrichmentDetails: {
            enrichmentJobId: '',
            enrichmentType: '',
            isEnrichment_E1_GoingOn: NOTSTARTED,
            isEnrichment_E2_GoingOn: NOTSTARTED,
            reviewThreholdCount: REVIEW_THRESHOLD_ENRICH_COUNT,
            reviewCount: 0,
            isOnlyE2Required: false,
          },
          selected: false,
          id: index,
        };
      });
      break;

    case 'people_T2':
      peopleDefaultLeadList = listNames.find(item => item.isDefault == true);
      list = data.map((item, index) => {
        return {
          contactRecord: {
            firstName: item.fName,
            lastName: item.lName,
            // "link": item.link, //linkedin profile URL
            lurl: item.link, //linkedin profile URL
            lid: item.lid, //linkedin username
            createdTime: '',
            title: item.title,
            imgUrl: '',
            company: {
              name: item.companyName,
              up: '',
              down: '',
              userCorrection: '',
            },
            industry: '',
            website: {
              domain: '',
              url: '',
              up: '',
              down: '',
              userCorrection: '',
            },
            location: {
              place: item.location,
              address: '',
              exactAddress: '',
              up: '',
              down: '',
              userCorrection: '',
            },
            keywords: [],
            bEmail: [],
            bPhone: [],
            pEmail: [],
            pPhone: [],
            social: {
              twitter: `https://www.twitter.com/search?q=${item.fName} ${item.lName}`,
              fB: `https://www.facebook.com/public/${item.fName} ${item.lName}`,
              li: item.link,
            },
          },
          enrichmentDetails: {
            enrichmentJobId: '',
            enrichmentType: '',
            isEnrichment_E1_GoingOn: NOTSTARTED,
            isEnrichment_E2_GoingOn: NOTSTARTED,
            reviewThreholdCount: REVIEW_THRESHOLD_ENRICH_COUNT,
            reviewCount: 0,
            isOnlyE2Required: false,
          },
          identity: {
            recordId: '', // API will return
            lid: peopleDefaultLeadList.lid,
            lnm: peopleDefaultLeadList.lnm,
            uid: localStorage.user_id, //Front end will fill up
            userName: fn + ' ' + ln, // Front end will fill up
            cid: '', // Front end will fill up
            cat: 'people', // Front end will fill up “people/company”
            type: 'user', //user/system
            status: '',
            sync: 'NA',
          },
          customInfo: {
            personalizationNotes: [],
            titleScore: {
              value: '',
              reason: '',
            },
            accountScore: {
              value: '',
              reason: '',
            },
          },
          selected: false,
          id: index,
        };
      });
      break;

    case 'company_T2':
      companyDefaultLeadList = listNames.find(item => item.isDefault == true);
      list = data.map((item, index) => {
        return {
          companyRecord: {
            name: item.cName,
            about: item.cDesc,
            domain: item.cDomain,
            founded: '',
            funding: '',
            logo: item.cLogoUrl,
            industry: item.cIndustry,
            size: item.cSize,
            lurl: item.cLi,
            lid: item.cLi.indexOf('/') !== -1 ? item.cLi.split('/').reverse()[0] : '',
            createdTime: '', //Time when this record is added
            tags: item.cTags,
            location: `${item.cCity}, ${item.cState}, ${item.cCountry}`,
            phone: '',
            intent: {
              type: '',
              date: '',
              conf: '',
              source: '',
              detail: '',
            },
            social: {
              twitter: item.cTwt, //Full URL
              fb: item.cFb,
              Li: item.cLi, //Full Url
              cb: item.cCb,
            },
            sync: 'NA',
          },
          identity: {
            recordId: '', // API will return
            lid: '',
            lnm: '',
            cid: '',
            uid: localStorage.user_id, //Front end will fill up
            userName: fn + ' ' + ln, // Front end will fill up
            cat: 'company', // Front end will fill up “people/company”
            type: 'user', //user/system
            status: '',
            sync: 'NA',
          },
          selected: false,
          id: index,
        };
      });

      break;

    case `search_list`:
      companyDefaultLeadList = listNames.find(item => item.isDefault == true);
      list = data.map((item, index) => {
        return {
          companyRecord: {
            name: item.cName ? item.cName : item.name,
            about: item.cDesc,
            domain: item.cDomain,
            founded: '',
            funding: '',
            logo: item.cLogoUrl,
            industry: item.cIndustry,
            size: item.cSize,
            lurl: `https://www.linkedin.com/company/${item.cSig}`,
            lid: item.cSig,
            createdTime: '', //Time when this record is added
            tags: item.cTags,
            location: `${item.cCity}, ${item.cState}, ${item.cCountry}`,
            phone: '',
            intent: {
              type: '',
              date: '',
              conf: '',
              source: '',
              detail: '',
            },
            social: {
              twitter: '', //Full URL
              fb: '',
              Li: '', //Full Url
              cb: '',
            },
            sync: 'NA',
          },
          identity: {
            recordId: '', // API will return
            lid: '',
            lnm: '',
            cid: '',
            uid: localStorage.user_id, //Front end will fill up
            userName: fn + ' ' + ln, // Front end will fill up
            cat: 'company', // Front end will fill up “people/company”
            type: 'user', //user/system
            status: '',
            sync: 'NA',
          },
          selected: false,
          id: index,
          extra: {
            ...item,
          },
        };
      });

      break;
    default:
      break;
  }

  return list;
};

/**
 * Ouput details
 *
 */
function tableDisplayData(mode, data, allItemSelected, showReview) {
  let list = [];
  switch (mode) {
    case 'People':
      list = data.map((item, i) => {
        let displayData = {
          // "Profile Picture": { type: 'image', value: item.contactRecord.imageUrl },
          // "Designation": { type: 'text', value: item.contactRecord.designation },
          Name: {
            type: 'text',
            value: item.contactRecord.firstName + ' ' + item.contactRecord.lastName,
            hintText: item.contactRecord.title,
          },
          id: { type: 'text', value: item.id },
          checked: {
            type: 'checkbox',
            value: allItemSelected ? allItemSelected : item.selected,
          },
        };

        let currentEnrichResult = {};
        if (item.enrichmentDetails.enrichmentType == 'E1')
          currentEnrichResult = enrichTableDataRefresh_E1(item, showReview);
        else currentEnrichResult = enrichTableDataRefresh_E2(item, showReview);

        // console.log("currentEnrichResult",currentEnrichResult);

        let keys = Object.keys(currentEnrichResult);
        keys.map(item => (displayData[item] = currentEnrichResult[item]));

        return displayData;
      });
      break;
    case 'Company':
      list = data.map(item => {
        const { companyRecord } = item;
        let crmStatus = '-';
        let disableSaveBtn = false;
        if (companyRecord.sync === 'NA') {
          crmStatus = 'Sync';
        } else if (
          companyRecord.sync.crm1?.cStatus === 'LOADING' ||
          companyRecord.sync.crm2?.cStatus === 'LOADING'
        ) {
          crmStatus = 'Syncing...';
        } else if (companyRecord.sync.crm1?.cStatus === 'SYNC') {
          crmStatus = 'Synced';
          disableSaveBtn = true;
        } else if (companyRecord.sync.crm1.cStatus === 'NT_SYNC') {
          //TODO: now check for cCode in crm1 or crm2
          crmStatus = 'Error';
        }
        let { social } = companyRecord;
        let logoUrl =
          companyRecord.logo.indexOf("'") !== -1
            ? companyRecord.logo.split("'")[1]
            : companyRecord.logo;
        return {
          '': {
            type: 'image',
            value: { src: logoUrl, alt: companyRecord.name },
          },
          Url: {
            type: 'url',
            value: {
              secondaryText: {
                text: companyRecord.domain,
                link: companyRecord.domain,
                href: true,
              },
              list: [
                {
                  title: 'Linkedin',
                  link: social.Li,
                },
              ],
            },
          },
          Company: {
            type: 'contactInfo',
            value: {
              mainText: companyRecord.name,
              secondaryText: {
                text: companyRecord.domain,
                link: companyRecord.domain,
                href: true,
              },
              list: [
                {
                  title: 'Linkedin',
                  link: social.Li,
                },
                {
                  logoImg: 'linkedin_logo',
                  logoAlt: 'LinkedIn',
                  link: social.Li,
                },
                {
                  logoImg: 'twitter_logo',
                  logoAlt: 'Twitter',
                  link: social.twitter,
                },
                {
                  logoImg: 'facebook_logo',
                  logoAlt: 'Facebook',
                  link: social.fb,
                },
                {
                  logoImg: 'crunchbase_logo',
                  logoAlt: 'CrunchBase',
                  link: social.cb,
                },
              ],
            },
          },
          'Size/ Industry/ Geo': {
            type: 'contactInfo',
            value: {
              mainText: companyRecord.size,
              secondaryText: { text: companyRecord.industry, href: false },
              geo: companyRecord.location,
            },
          },
          Intel: {
            type: 'intentInfo',
            value: {
              text: 'Uses Salesforce',
              secondaryText: 'Sources: From web breadcrumb',
              helperText: { date: '12th May 2021', conf: 'High' },
            },
          },
          id: { type: 'text', value: item.id },
          checked: {
            type: 'checkbox',
            value: allItemSelected ? allItemSelected : item.selected,
          },
          Action: {
            type: 'button',
            value: crmStatus,
            clickType: 'crm-save',
            disabled: disableSaveBtn,
          },
        };
      });
      break;
    case 10:
      list.push({
        emails: { type: 'text', value: data.emails },
        contact: { type: 'text', value: data.phones },
      });
      break;

    /*  case "invitationList":
                 list.push({
                     "Name": { type: "text", value: data.name },
                     "Email": { type: "text", value: data.email },
                     "Role": { type: "text", value: data.role },
                     "Status": { type: "text", value: data.status },
                     "Invite Date": { type: "text", value: data.invitedDate }
                 });
                 break; */

    default:
      break;
  }

  return list;
}

function getSearchTableDisplayData(searchType, data, allItemSelected, contextSearch = '') {
  let list = [];
  list = data.map(item => {
    const { companyRecord, extra } = item;
    let actTopicList = Array.isArray(extra.actTopic)
      ? extra.actTopic.filter(topic => topic.found === 'Yes').map(topic => topic.name)
      : [];
    let actTechList = Array.isArray(extra.actTech)
      ? extra.actTech.filter(tech => tech.found).map(tech => tech.name)
      : [];
    let cLabel = Array.isArray(extra.actCLabel)
      ? extra.actCLabel.filter(label => label.found).map(label => label.name)
      : [];

    let cRoot = Array.isArray(extra.actCRoot)
      ? extra.actCRoot.filter(label => label.found).map(label => label.name)
      : [];

    let nameVc = Array.isArray(extra.actVc)
      ? extra.actVc.filter(label => label.found).map(label => label.name)
      : [];

    let actTitle = Array.isArray(extra.actTitle) ? extra.actTitle.map(t => t.t) : [];
    let actDepartment = Array.isArray(extra.actDepartment) ? extra.actDepartment.map(t => t.t) : [];
    let actSeniority = Array.isArray(extra.actSeniority) ? extra.actSeniority.map(t => t.t) : [];
    let sdt = '';

    if (actTitle.length && actTitle[0]) {
      sdt = actTitle[0];
    } else {
      if (actDepartment.length && actDepartment[0]) sdt += actDepartment[0] + ' ';
      if (actSeniority.length && actSeniority[0]) sdt += actSeniority[0];
    }
    if (typeof sdt !== 'string') {
      sdt = '';
    }
    const displayTableJson = {
      Account: {
        type: 'accountCell',
        cellInfo: {
          row1: {
            visible: !!companyRecord?.name,
            value: decodeURI(companyRecord?.name)?.toUpperCase(),
          },
          row2: {
            visible: false,
            cueText1: '',
            value: '',
          },
          row3: {
            visible: false,
            cueText1: '',
            value: '',
          },
        },
      },
      Reasons: {
        type: 'signalCell',
        cellInfo: {
          row1: {
            visible: !!extra.actTitle,
            cueText1: '',
            cueText2: '',
            value: '',
          },
          row2: {
            visible: actTopicList.length,
            cueText1: 'Account interest spiking?',
            cueText2: 'Topic?',
            value: actTopicList,
          },
        },
      },
      Strength: {
        type: 'strengthCell',
        cellInfo: {
          row1: {
            visible: true,
            value: extra?.detail?.signalStrength,
          },
          row2: {
            visible: !!extra?.detail?.publishDate,
            cueText1: 'Spotted on',
            value: extra?.detail?.publishDate,
          },
        },
      },
      'Raw Intel': {
        type: 'intelCell',
        cellInfo: {
          row1: {
            visible: true,
            value: extra?.detail?.intel,
          },
        },
      },
      Confidence: {
        type: 'confidenceCell',
        cellInfo: {
          row1: {
            visible: true,
            value: extra.conf,
          },
        },
      },
      id: { type: 'text', value: item.id },
      checked: { value: item.selected },
    };
    switch (searchType) {
      case SEARCH_RECRUITMENT_BUYER:
        displayTableJson.Reasons.cellInfo.row1.visible = true;
        displayTableJson.Reasons.cellInfo.row1.cueText1 = 'Investing in';
        displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row1.value = sdt.trim();

        if (actTopicList.length) {
          displayTableJson.Reasons.cellInfo.row2.visible = actTopicList.length;
          displayTableJson.Reasons.cellInfo.row2.cueText1 = 'Interest in topic(s)';
          displayTableJson.Reasons.cellInfo.row2.cueText2 = '';
          displayTableJson.Reasons.cellInfo.row2.value = actTopicList;
        } else if (actTechList.length) {
          displayTableJson.Reasons.cellInfo.row2.visible = actTechList.length;
          displayTableJson.Reasons.cellInfo.row2.cueText1 = 'Interest in topic(s)';
          displayTableJson.Reasons.cellInfo.row2.cueText2 = '';
          displayTableJson.Reasons.cellInfo.row2.value = actTechList;
        }

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Account details';
        displayTableJson.Account.cellInfo.row2.value = `https://www.linkedin.com/search/results/companies/?keywords=${companyRecord.lid}`;
        displayTableJson.Account.cellInfo.row3.visible = true;
        displayTableJson.Account.cellInfo.row3.cueText1 = 'Decision Makers';
        displayTableJson.Account.cellInfo.row3.value = `https://www.linkedin.com/search/results/all/?keywords=${companyRecord.lid}%20AND%20(Director%20OR%20VP%20OR%20Chief%20OR%20General)&origin=GLOBAL_SEARCH_HEADER&sid=L9C`;

        break;

      case SEARCH_RECRUITMENT_USER:
        displayTableJson.Reasons.cellInfo.row1.visible = true;
        displayTableJson.Reasons.cellInfo.row1.cueText1 = 'Investing in';
        displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row1.value = sdt.trim();

        if (actTopicList.length) {
          displayTableJson.Reasons.cellInfo.row2.visible = actTopicList.length;
          displayTableJson.Reasons.cellInfo.row2.cueText1 = 'Interest in topic(s)';
          displayTableJson.Reasons.cellInfo.row2.cueText2 = '';
          displayTableJson.Reasons.cellInfo.row2.value = actTopicList;
        } else if (actTechList.length) {
          displayTableJson.Reasons.cellInfo.row2.visible = actTechList.length;
          displayTableJson.Reasons.cellInfo.row2.cueText1 = 'Interest in topic(s)';
          displayTableJson.Reasons.cellInfo.row2.cueText2 = '';
          displayTableJson.Reasons.cellInfo.row2.value = actTechList;
        }

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Account details';
        displayTableJson.Account.cellInfo.row2.value = `https://www.linkedin.com/search/results/companies/?keywords=${companyRecord.lid}`;
        displayTableJson.Account.cellInfo.row3.visible = true;
        displayTableJson.Account.cellInfo.row3.cueText1 = 'Decision Makers';
        displayTableJson.Account.cellInfo.row3.value = `https://www.linkedin.com/search/results/all/?keywords=${companyRecord.lid}%20AND%20(Director%20OR%20VP%20OR%20Chief%20OR%20General)&origin=GLOBAL_SEARCH_HEADER&sid=L9C`;
        break;

      case SEARCH_TOPIC_SURGE:
        if (actTopicList.length) {
          displayTableJson.Reasons.cellInfo.row1.visible = true;
          displayTableJson.Reasons.cellInfo.row1.cueText1 = `Interest in topic${
            actTopicList.length > 1 ? 's' : ''
          }`;
          displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
          displayTableJson.Reasons.cellInfo.row1.value = actTopicList;
        } else {
          displayTableJson.Reasons.cellInfo.row1.visible = true;
          displayTableJson.Reasons.cellInfo.row1.cueText1 = `Interest in topic${
            actTechList.length > 1 ? 's' : ''
          }`;
          displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
          displayTableJson.Reasons.cellInfo.row1.value = actTechList;
        }

        // TODO: change it later
        displayTableJson.Reasons.cellInfo.row2.visible = contextSearch.length; // why? Backend is not sending
        displayTableJson.Reasons.cellInfo.row2.cueText1 = 'Investing in';
        displayTableJson.Reasons.cellInfo.row2.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row2.value = contextSearch;

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Account details';
        displayTableJson.Account.cellInfo.row2.value = `https://www.linkedin.com/search/results/companies/?keywords=${companyRecord.lid}`;
        displayTableJson.Account.cellInfo.row3.visible = true;
        displayTableJson.Account.cellInfo.row3.cueText1 = 'Decision Makers';
        displayTableJson.Account.cellInfo.row3.value = `https://www.linkedin.com/search/results/all/?keywords=${companyRecord.lid}%20AND%20(Director%20OR%20VP%20OR%20Chief%20OR%20General)&origin=GLOBAL_SEARCH_HEADER&sid=L9C`;

        break;

      case SEARCH_EXEC_MOVEMENT:
        displayTableJson.Reasons.cellInfo.row1.visible = true;
        displayTableJson.Reasons.cellInfo.row1.cueText1 = 'Decision maker joined as';
        displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row1.value = sdt.trim();

        displayTableJson.Reasons.cellInfo.row2.visible = actTopicList.length;
        displayTableJson.Reasons.cellInfo.row2.cueText1 = "Decision Maker's Affinity in topic?";
        displayTableJson.Reasons.cellInfo.row2.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row2.value = actTopicList;

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Track Decision Maker';
        displayTableJson.Account.cellInfo.row2.value = extra.detail.personUrl;

        break;
      case SEARCH_COMPANY_LABEL:
        displayTableJson.Reasons.cellInfo.row1.visible = true;
        displayTableJson.Reasons.cellInfo.row1.cueText1 = `Specialisation in`;
        displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row1.value = cLabel;

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Account details';
        displayTableJson.Account.cellInfo.row2.value = `https://www.linkedin.com/search/results/companies/?keywords=${companyRecord.lid}`;
        displayTableJson.Account.cellInfo.row3.visible = true;
        displayTableJson.Account.cellInfo.row3.cueText1 = 'Decision Makers';
        displayTableJson.Account.cellInfo.row3.value = `https://www.linkedin.com/search/results/all/?keywords=${companyRecord.lid}%20AND%20(Director%20OR%20VP%20OR%20Chief%20OR%20General)&origin=GLOBAL_SEARCH_HEADER&sid=L9C`;
        break;
      case SEARCH_SIMILAR_INTENT:
        displayTableJson.Reasons.cellInfo.row1.visible = true;
        displayTableJson.Reasons.cellInfo.row1.cueText1 = `Identical to`;
        displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row1.value = cRoot;

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Account details';
        displayTableJson.Account.cellInfo.row2.value = `https://www.linkedin.com/search/results/companies/?keywords=${companyRecord.lid}`;
        displayTableJson.Account.cellInfo.row3.visible = true;
        displayTableJson.Account.cellInfo.row3.cueText1 = 'Decision Makers';
        displayTableJson.Account.cellInfo.row3.value = `https://www.linkedin.com/search/results/all/?keywords=${companyRecord.lid}%20AND%20(Director%20OR%20VP%20OR%20Chief%20OR%20General)&origin=GLOBAL_SEARCH_HEADER&sid=L9C`;
        break;
      case SEARCH_SAME_VC_INTENT:
        displayTableJson.Reasons.cellInfo.row1.visible = true;
        displayTableJson.Reasons.cellInfo.row1.cueText1 = `Investor Includes`;
        displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row1.value = nameVc;

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Account details';
        displayTableJson.Account.cellInfo.row2.value = `https://www.linkedin.com/search/results/companies/?keywords=${companyRecord.lid}`;
        displayTableJson.Account.cellInfo.row3.visible = true;
        displayTableJson.Account.cellInfo.row3.cueText1 = 'Decision Makers';
        displayTableJson.Account.cellInfo.row3.value = `https://www.linkedin.com/search/results/all/?keywords=${companyRecord.lid}%20AND%20(Director%20OR%20VP%20OR%20Chief%20OR%20General)&origin=GLOBAL_SEARCH_HEADER&sid=L9C`;
        break;
      case SEARCH_DUMMY_3:
        displayTableJson.Reasons.cellInfo.row1.visible = true;
        displayTableJson.Reasons.cellInfo.row1.cueText1 = `Specialisation in`;
        displayTableJson.Reasons.cellInfo.row1.cueText2 = '';
        displayTableJson.Reasons.cellInfo.row1.value = cLabel;

        displayTableJson.Account.cellInfo.row2.visible = true;
        displayTableJson.Account.cellInfo.row2.cueText1 = 'Account details';
        displayTableJson.Account.cellInfo.row2.value = `https://www.linkedin.com/search/results/companies/?keywords=${companyRecord.lid}`;
        displayTableJson.Account.cellInfo.row3.visible = true;
        displayTableJson.Account.cellInfo.row3.cueText1 = 'Decision Makers';
        displayTableJson.Account.cellInfo.row3.value = `https://www.linkedin.com/search/results/all/?keywords=${companyRecord.lid}%20AND%20(Director%20OR%20VP%20OR%20Chief%20OR%20General)&origin=GLOBAL_SEARCH_HEADER&sid=L9C`;
        break;
      default:
        break;
    }
    return displayTableJson;
  });
  return list;
}

function companyTableDisplayData(mode, data, allItemSelected, showReview) {
  let list = [];
  switch (mode) {
    case 'People':
      list = data.map((item, i) => {
        let displayData = {
          // "Profile Picture": { type: 'image', value: item.contactRecord.imageUrl },
          // "Designation": { type: 'text', value: item.contactRecord.designation },
          Name: {
            type: 'text',
            value: item.contactRecord.firstName + ' ' + item.contactRecord.lastName,
            hintText: item.contactRecord.title,
          },
          id: { type: 'text', value: item.id },
          checked: {
            type: 'checkbox',
            value: allItemSelected ? allItemSelected : item.selected,
          },
        };

        let currentEnrichResult = {};
        if (item.enrichmentDetails.enrichmentType == 'E1')
          currentEnrichResult = enrichTableDataRefresh_E1(item, showReview);
        else currentEnrichResult = enrichTableDataRefresh_E2(item, showReview);

        // console.log("currentEnrichResult",currentEnrichResult);

        let keys = Object.keys(currentEnrichResult);
        keys.map(item => (displayData[item] = currentEnrichResult[item]));

        return displayData;
      });
      break;
    case 'Company':
      list = data.map(item => {
        // console.log(item);
        const { name, domain, size, industry, location, lurl } = item.companyRecord;
        let identityRecord = item.identity;

        let crmStatus;
        let disableSaveBtn = false;
        if (identityRecord.sync === 'NA' || identityRecord.sync === 'true') {
          crmStatus = '+ Save';
        } else if (
          identityRecord.sync.crm1?.cStatus === 'LOADING' ||
          identityRecord.sync.crm2?.cStatus === 'LOADING'
        ) {
          crmStatus = 'Saving...';
        } else if (identityRecord.sync.crm1?.cStatus === 'SYNC') {
          crmStatus = 'Crm saved';
          disableSaveBtn = true;
        } else if (identityRecord.sync.crm1.cStatus === 'NT_SYNC') {
          //TODO: now check for cCode in crm1 or crm2
          crmStatus = 'Save⚠';
        }

        return {
          Company: {
            type: 'contactInfo',
            value: {
              coordinates: [],
              mainText: name,
              secondaryText: {
                text: domain,
                link: domain,
                href: true,
              },
            },
          },
          Coordinate: {
            type: 'companyCordinate',
            value: {
              coordinates: [
                {
                  image: 'linkedin_logo',
                  text: '',
                  link: 'contactRecord.social.Li',
                  link: lurl,
                  text: '',
                  location: '',
                },
                // { "image": "facebook_logo", "link": contactRecord.social.fB },
                // { "image": "twitter_logo", "link": contactRecord.social.twitter }
              ],
              // mainText: name,
              secondaryText: {
                text: domain,
                link: domain,
                href: true,
              },
            },
          },
          'Size/ Industry/ Geo': {
            type: 'contactInfo',
            value: {
              mainText: size,
              secondaryText: { text: industry, href: false },
              geo: location,
            },
          },

          id: { type: 'text', value: item.id },
          checked: {
            type: 'checkbox',
            value: allItemSelected ? allItemSelected : item.selected,
          },

          Action: {
            type: 'button',
            value: crmStatus,
            clickType: 'crm-save',
            disabled: disableSaveBtn,
          },
        };
      });
      break;

    case 'IntentTracker':
      list = data.map(item => {
        const { uid, id, desc, path, rep_name, cr_date } = item;
        // ? What is the disable condition?
        let disableSaveBtn = false;

        return {
          id: { type: 'text', value: id },
          Report: {
            type: 'text',
            value: rep_name,
          },
          Description: {
            type: 'text',
            value: item.desc,
            hintText: `Created: ${new Date(cr_date._seconds).toLocaleDateString()}`,
          },
          Action: {
            type: 'button',
            value: "Download",
            clickType: 'crm-save',
            disabled: disableSaveBtn,
          },
        };
      });
      break;

    case 10:
      list.push({
        emails: { type: 'text', value: data.emails },
        contact: { type: 'text', value: data.phones },
      });
      break;

    /*  case "invitationList":
                 list.push({
                     "Name": { type: "text", value: data.name },
                     "Email": { type: "text", value: data.email },
                     "Role": { type: "text", value: data.role },
                     "Status": { type: "text", value: data.status },
                     "Invite Date": { type: "text", value: data.invitedDate }
                 });
                 break; */

    default:
      break;
  }

  return list;
}

/**
 *
 * @param {string} mode
 * @param {array} data
 * @param {Boolean} allItemSelected
 * @param {Boolean} showReview
 * @returns display list
 */
function peopleTableDisplayData(mode, data, allItemSelected, showReview) {
  let list = [];
  switch (mode) {
    case 'People':
      list = data.map((item, i) => {
        let displayData = {
          // "Profile Picture": { type: 'image', value: item.contactRecord.imageUrl },
          // "Designation": { type: 'text', value: item.contactRecord.designation },
          Name: {
            type: 'text',
            value: item.contactRecord.firstName + ' ' + item.contactRecord.lastName,
            hintText: item.contactRecord.title,
            titleScore: item?.customInfo?.titleScore,
            notes: item?.customInfo?.personalizationNotes,
            clickType: 'pNotes',
          },
          id: { type: 'text', value: item.id },
          checked: {
            type: 'checkbox',
            value: allItemSelected ? allItemSelected : item.selected,
          },
        };

        let currentEnrichResult = {};
        if (item.enrichmentDetails.enrichmentType == 'E1')
          currentEnrichResult = enrichPeopleTableDataRefresh_E1(item, showReview);
        else currentEnrichResult = enrichPeopleTableDataRefresh_E2(item, showReview);

        // console.log("currentEnrichResult",currentEnrichResult);

        let keys = Object.keys(currentEnrichResult);
        keys.map(item => (displayData[item] = currentEnrichResult[item]));

        return displayData;
      });
      break;
    case 'Company':
      list = data.map(item => {
        const { company, website, size, industry, location, lurl } = item.contactRecord;
        let identityRecord = item.identity;

        return {
          Name: { type: 'text', value: company.name },
          Website: { type: 'anchor', value: website.url },
          Size: { type: 'text', value: size },
          Industry: { type: 'text', value: industry },
          Location: { type: 'text', value: location.place },
          Coordinates: {
            type: 'coordinates',
            list: [
              {
                image: 'linkedin_logo',
                link: lurl,
                text: 'Linkedin Profile',
                location: '',
              },
              // { "image": "facebook_logo", "link": contactRecord.social.fB },
              // { "image": "twitter_logo", "link": contactRecord.social.twitter }
            ],
          },
          Stream: {
            type: 'autocomplete',
            value: {
              selected: {
                lid: item.lid || identityRecord.lid,
                lnm: item.lnm || identityRecord.lnm,
              },
              enabled: true,
            },
          },

          id: { type: 'text', value: item.id },
          checked: {
            type: 'checkbox',
            value: allItemSelected ? allItemSelected : item.selected,
          },
        };
      });
      break;
    case 10:
      list.push({
        emails: { type: 'text', value: data.emails },
        contact: { type: 'text', value: data.phones },
      });
      break;


      
    default:
      break;
  }

  return list;
}

/**
 *
 * @param {*} jobList
 * @param {*} pid
 */
const setEnrichmentPayload = (jobList, pid) => {
  let payload = [];

  jobList.map(item => {
    if (
      item.enrichmentDetails.isEnrichment_E1_GoingOn == SELECTED ||
      item.enrichmentDetails.isEnrichment_E2_GoingOn == SELECTED
    ) {
      // payload.push(item.contactRecord);
      const {
        firstName,
        lastName,
        lid,
        lurl,
        createdTime,
        title,
        company,
        website,
        location,
        bEmail,
        bPhone,
        pPhone,
        pEmail,
        social,
      } = item.contactRecord;

      let paylodItem = {
        ...item.contactRecord,
        /*  firstName: firstName,
        lastName: lastName,
        lid: lid,
        lurl: lurl,
        createdTime: createdTime,
        title: title, */
        company: {
          name: company.name ? company.name.trim() : company.name,
          up: '',
          down: '',
          userCorrection: '',
        },
        /*  industry: "",
        website: website,
        location: location,
        keywords: [],
        bEmail: bEmail,
        bPhone: bPhone,
        pEmail: pEmail,
        pPhone: pPhone,
        social: social, */
        identity: item.identity,
        enrichmentDetails: item.enrichmentDetails,
        customInfo: item.customInfo,
      };

      paylodItem.taskName = 'Enrich Personal Contact';
      payload.push(paylodItem);
    }
  });

  return payload;
};

/**
 *
 * @param {*} item
 */
const enrichTableDataRefresh_E1 = (item, showReview) => {
  let contactRecord = item.contactRecord;
  let enrichRecord = item.enrichmentDetails;
  let identityRecord = item.identity;

  switch (enrichRecord.isEnrichment_E1_GoingOn) {
    case NOTSTARTED:
    default:
      return {
        Company: {
          type: 'text',
          value: contactRecord.company.name,
        },
        /* "Business Email": {
                    type: 'info', value: "Business Email (Hidden)",
                    info: "Click Find"
                }, */

        'Business Email': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich1',
        },

        'Personal Phone': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich2',
        },

        /*  "Personal Phone": {
                     type: 'info', value: "Mobile Number (Hidden)",
                     info: "Click Find"
                 }, */

        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.social.li,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            selected: { lid: identityRecord.lid, lnm: identityRecord.lnm },
            enabled: false,
          },
        },
        Action: { type: 'button', value: 'Save' },
      };
    case STARTED: {
      let personalPhone = {
        type: 'textButton',
        value: 'Find',
        clickType: 'Enrich2',
      };
      if (contactRecord.pPhone.length == 1)
        personalPhone = { type: 'text', value: contactRecord.pPhone[0].number };
      else if (contactRecord.pPhone.length > 1) {
        let number = contactRecord.pPhone
          .map(item => item.number)
          .slice(0, 2)
          .join(', \n');
        personalPhone = {
          type: 'more',
          value: number,
          allValues: contactRecord.pPhone,
        };
      }

      return {
        Company: {
          type: 'text',
          value: contactRecord.company.name,
        },
        'Business Email': {
          type: 'text',
          value: 'Searching details',
        },
        'Personal Phone': personalPhone,
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.social.li,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            selected: { lid: identityRecord.lid, lnm: identityRecord.lnm },
            enabled: false,
          },
        },
        Action: { type: 'button', value: 'Save' },
      };
    }
    case DONE:
      let businessEmail = {};
      businessEmail = getBusinessEmailFromContactResult(contactRecord, enrichRecord, showReview);
      let personalPhone = {
        type: 'textButton',
        value: 'Find',
        clickType: 'Enrich2',
      };
      if (contactRecord.pPhone.length == 1)
        personalPhone = { type: 'text', value: contactRecord.pPhone[0].number };
      else if (contactRecord.pPhone.length > 1) {
        let number = contactRecord.pPhone
          .map(item => item.number)
          .slice(0, 2)
          .join(', \n');
        personalPhone = {
          type: 'more',
          value: number,
          allValues: contactRecord.pPhone,
        };
      }

      return {
        Company: {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info:
            contactRecord.website.domain != '' ? 'https://www.' + contactRecord.website.domain : '',
        },
        'Business Email': businessEmail,
        'Personal Phone': personalPhone,
        //   "B-Phone": { type: "text", value: contactRecord.bPhone.length > 0 ? contactRecord.bPhone[0].number : "NA" },
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.social.li,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },

        Stream: {
          type: 'autocomplete',
          value: {
            selected: { lid: identityRecord.lid, lnm: identityRecord.lnm },
            enabled: true,
          },
        },
        Action: { type: 'button', value: 'Save' },
      };
  }
};

//which containes enrichment E1 and E2
const enrichTableDataRefresh_E2 = (item, showReview) => {
  let contactRecord = item.contactRecord;
  let enrichRecord = item.enrichmentDetails;
  let identity = item.identity;

  switch (enrichRecord.isEnrichment_E2_GoingOn) {
    case NOTSTARTED:
    default:
      return {
        Company: {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info: contactRecord.website.domain != '' ? 'https://' + contactRecord.website.domain : '',
        },
        /* "Business Email": {
                    type: 'info', value: "Business Email (Hidden)",
                    info: "Click Find"
                }, */
        'Business Email': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich1',
        },
        'Personal Phone': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich2',
        },
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.social.li,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            data: '',
            selected: { lid: identity.lid, lnm: identity.lnm },
            enabled: false,
          },
        },
        Action: { type: 'button', value: 'Save' },
      };
    case STARTED: {
      let businessEmail = {};
      let companyInfo = {};

      //enrichment E1 is already done then E2 selected
      if (enrichRecord.isOnlyE2Required && enrichRecord.isEnrichment_E1_GoingOn == DONE) {
        businessEmail = getBusinessEmailFromContactResult(contactRecord, enrichRecord, showReview);
        companyInfo = {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info: contactRecord.website.domain != '' ? 'https://' + contactRecord.website.domain : '',
        };
      } else {
        businessEmail = { type: 'text', value: 'Searching details' };
        companyInfo = {
          type: 'text',
          value: contactRecord.company.name,
        };
      }

      return {
        Company: companyInfo,
        'Business Email': businessEmail,
        'Personal Phone': { type: 'text', value: 'Searching details' },
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.social.li,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            data: '',
            selected: { lid: identity.lid, lnm: identity.lnm },
            enabled: false,
          },
        },
        Action: { type: 'button', value: 'Save' },
      };
    }

    case DONE: {
      let personalPhone = {};
      let businessEmail = {};
      let companyInfo = {};

      if (contactRecord.pPhone.length == 0)
        personalPhone = { type: 'text', value: 'Sorry! No data found' };
      else if (contactRecord.pPhone.length == 1)
        personalPhone = { type: 'text', value: contactRecord.pPhone[0].number };
      else if (contactRecord.pPhone.length > 1) {
        let number = contactRecord.pPhone
          .map(item => item.number)
          .slice(0, 2)
          .join(', \n');
        personalPhone = {
          type: 'more',
          value: number,
          allValues: contactRecord.pPhone,
        };
      }

      //when E1 is not done, E2 directly hit
      if (enrichRecord.isEnrichment_E1_GoingOn == NOTSTARTED && !enrichRecord.isOnlyE2Required) {
        businessEmail = {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich1',
        };
        companyInfo = {
          type: 'text',
          value: contactRecord.company.name,
        };
      } else if (enrichRecord.isEnrichment_E1_GoingOn == STARTED) {
        businessEmail = { type: 'text', value: 'Searching details' };
        companyInfo = {
          type: 'text',
          value: contactRecord.company.name,
        };
      } else {
        companyInfo = {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info: contactRecord.website.domain != '' ? 'https://' + contactRecord.website.domain : '',
        };
        businessEmail = getBusinessEmailFromContactResult(contactRecord, enrichRecord, showReview);
      }

      return {
        Company: companyInfo,
        'Business Email': businessEmail,
        'Personal Phone': personalPhone,
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.social.li,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            data: '',
            selected: { lid: identity.lid, lnm: identity.lnm },
            enabled: true,
          },
        },
        Action: { type: 'button', value: 'Save' },
      };
    }
  }
};

/**
 *
 * @param {*} item
 */

const enrichPeopleTableDataRefresh_E1 = (item, showReview) => {
  let contactRecord = item.contactRecord;
  let enrichRecord = item.enrichmentDetails;
  let identityRecord = item.identity;
  let customInfo = item?.customInfo;

  let crmStatus;
  let disableSaveBtn = false;
  if (identityRecord.sync === 'NA') {
    crmStatus = 'Save';
  } else if (
    identityRecord.sync.crm1?.cStatus === 'LOADING' ||
    identityRecord.sync.crm2?.cStatus === 'LOADING'
  ) {
    crmStatus = 'Saving...';
  } else if (identityRecord.sync.crm1?.cStatus === 'SYNC') {
    crmStatus = 'Saved';
    disableSaveBtn = true;
  } else if (identityRecord.sync.crm1.cStatus === 'NT_SYNC') {
    //TODO: now check for cCode in crm1 or crm2
    crmStatus = 'Save⚠';
  }

  switch (enrichRecord.isEnrichment_E1_GoingOn) {
    case NOTSTARTED:
    default:
      return {
        Company: {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info: contactRecord.website.domain != '' ? 'https://' + contactRecord.website.domain : '',
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        },
        /* "Business Email": {
                    type: 'info', value: "Business Email (Hidden)",
                    info: "Click Find"
                }, */

        'Business Email': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich1',
        },

        'Personal Phone': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich2',
        },

        /*  "Personal Phone": {
                     type: 'info', value: "Mobile Number (Hidden)",
                     info: "Click Find"
                 }, */

        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.lurl,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            selected: { lid: identityRecord.lid, lnm: identityRecord.lnm },
            enabled: false,
          },
        },
        // Action: { type: 'button', value: 'Edit', clickType: 'edit' },
        'CRM Action': {
          type: 'button',
          value: crmStatus,
          clickType: 'crm-save',
          disabled: disableSaveBtn,
        },
      };
    case STARTED: {
      let personalPhone = {
        type: 'textButton',
        value: 'Find',
        clickType: 'Enrich2',
      };
      if (contactRecord.pPhone.length == 1)
        personalPhone = { type: 'text', value: contactRecord.pPhone[0].number };
      else if (contactRecord.pPhone.length > 1) {
        let number = contactRecord.pPhone
          .map(item => item.number)
          .slice(0, 2)
          .join(', \n');
        personalPhone = {
          type: 'more',
          value: number,
          allValues: contactRecord.pPhone,
        };
      }

      return {
        Company: {
          type: 'text',
          value: contactRecord.company.name,
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        },
        'Business Email': {
          type: 'infoTextAction',
          value: 'Searching details',
        },
        'Personal Phone': personalPhone,
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.lurl,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            selected: { lid: identityRecord.lid, lnm: identityRecord.lnm },
            enabled: false,
          },
        },
        // Action: { type: 'button', value: 'Edit', clickType: 'edit' },
        'CRM Action': {
          type: 'button',
          value: crmStatus,
          clickType: 'crm-save',
          disabled: disableSaveBtn,
        },
      };
    }
    case DONE:
      let businessEmail = {};
      businessEmail = getBusinessEmailFromContactResult(
        contactRecord,
        enrichRecord,
        showReview,
        true
      );
      let personalPhone = {
        type: 'textButton',
        value: 'Find',
        clickType: 'Enrich2',
      };
      if (contactRecord.pPhone.length == 1)
        personalPhone = { type: 'text', value: contactRecord.pPhone[0].number };
      else if (contactRecord.pPhone.length > 1) {
        let number = contactRecord.pPhone
          .map(item => item.number)
          .slice(0, 2)
          .join(', \n');
        personalPhone = {
          type: 'more',
          value: number,
          allValues: contactRecord.pPhone,
        };
      }

      return {
        Company: {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info:
            contactRecord.website.domain != '' ? 'https://www.' + contactRecord.website.domain : '',
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        },
        'Business Email': businessEmail,
        'Personal Phone': personalPhone,
        //   "B-Phone": { type: "text", value: contactRecord.bPhone.length > 0 ? contactRecord.bPhone[0].number : "NA" },
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.lurl,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },

        Stream: {
          type: 'autocomplete',
          value: {
            selected: { lid: identityRecord.lid, lnm: identityRecord.lnm },
            enabled: true,
          },
        },
        // Action: { type: 'button', value: 'Edit', clickType: 'edit' },
        'CRM Action': {
          type: 'button',
          value: crmStatus,
          clickType: 'crm-save',
          disabled: disableSaveBtn,
        },
      };
  }
};

//which containes enrichment E1 and E2
const enrichPeopleTableDataRefresh_E2 = (item, showReview) => {
  let contactRecord = item.contactRecord;
  let enrichRecord = item.enrichmentDetails;
  let identity = item.identity;
  let customInfo = item?.customInfo;

  let crmStatus;
  let disableSaveBtn = false;
  if (identity.sync === 'NA') {
    crmStatus = 'Save';
  } else if (
    identity.sync.crm1?.cStatus === 'LOADING' ||
    identity.sync.crm2?.cStatus === 'LOADING'
  ) {
    crmStatus = 'Saving...';
  } else if (identity.sync.crm1?.cStatus === 'SYNC') {
    crmStatus = 'Saved';
    disableSaveBtn = true;
  } else if (identity.sync.crm1.cStatus === 'NT_SYNC') {
    //TODO: now check for cCode in crm1 or crm2
    crmStatus = 'Save⚠';
  }

  switch (enrichRecord.isEnrichment_E2_GoingOn) {
    case NOTSTARTED:
    default:
      return {
        Company: {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info: contactRecord.website.domain != '' ? 'https://' + contactRecord.website.domain : '',
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        },
        /* "Business Email": {
                    type: 'info', value: "Business Email (Hidden)",
                    info: "Click Find"
                }, */
        'Business Email': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich1',
        },
        'Personal Phone': {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich2',
        },
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.lurl,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            data: '',
            selected: { lid: identity.lid, lnm: identity.lnm },
            enabled: false,
          },
        },
        // Action: { type: 'button', value: 'Edit', clickType: 'edit' },
        'CRM Action': {
          type: 'button',
          value: crmStatus,
          clickType: 'crm-save',
          disabled: disableSaveBtn,
        },
      };
    case STARTED: {
      let businessEmail = {};
      let companyInfo = {};

      //enrichment E1 is already done then E2 selected
      if (enrichRecord.isOnlyE2Required && enrichRecord.isEnrichment_E1_GoingOn == DONE) {
        businessEmail = getBusinessEmailFromContactResult(
          contactRecord,
          enrichRecord,
          showReview,
          true
        );
        companyInfo = {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info: contactRecord.website.domain != '' ? 'https://' + contactRecord.website.domain : '',
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        };
      } else {
        businessEmail = { type: 'text', value: 'Searching details' };
        companyInfo = {
          type: 'text',
          value: contactRecord.company.name,
          score: customInfo?.accountScore,
          clickType: 'notes',
        };
      }

      return {
        Company: companyInfo,
        'Business Email': businessEmail,
        'Personal Phone': { type: 'text', value: 'Searching details' },
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.lurl,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            data: '',
            selected: { lid: identity.lid, lnm: identity.lnm },
            enabled: false,
          },
        },
        // Action: { type: 'button', value: 'Edit', clickType: 'edit' },
        'CRM Action': {
          type: 'button',
          value: crmStatus,
          clickType: 'crm-save',
          disabled: disableSaveBtn,
        },
      };
    }

    case DONE: {
      let personalPhone = {};
      let businessEmail = {};
      let companyInfo = {};

      if (contactRecord.pPhone.length == 0)
        personalPhone = { type: 'text', value: 'Sorry! No data found' };
      else if (contactRecord.pPhone.length == 1)
        personalPhone = { type: 'text', value: contactRecord.pPhone[0].number };
      else if (contactRecord.pPhone.length > 1) {
        let number = contactRecord.pPhone
          .map(item => item.number)
          .slice(0, 2)
          .join(', \n');
        personalPhone = {
          type: 'more',
          value: number,
          allValues: contactRecord.pPhone,
        };
      }

      //when E1 is not done, E2 directly hit
      if (enrichRecord.isEnrichment_E1_GoingOn == NOTSTARTED && !enrichRecord.isOnlyE2Required) {
        businessEmail = {
          type: 'textButton',
          value: 'Find',
          clickType: 'Enrich1',
        };
        companyInfo = {
          type: 'text',
          value: contactRecord.company.name,
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        };
      } else if (enrichRecord.isEnrichment_E1_GoingOn == STARTED) {
        businessEmail = { type: 'text', value: 'Searching details' };
        companyInfo = {
          type: 'text',
          value: contactRecord.company.name,
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        };
      } else {
        companyInfo = {
          type: 'info',
          value: contactRecord.company.name,
          hintText: '',
          info: contactRecord.website.domain != '' ? 'https://' + contactRecord.website.domain : '',
          accountScore: customInfo?.accountScore,
          clickType: 'notes',
        };
        businessEmail = getBusinessEmailFromContactResult(
          contactRecord,
          enrichRecord,
          showReview,
          true
        );
      }

      return {
        Company: companyInfo,
        'Business Email': businessEmail,
        'Personal Phone': personalPhone,
        Coordinates: {
          type: 'coordinates',
          list: [
            {
              image: 'linkedin_logo',
              link: contactRecord.lurl,
              text: 'Linkedin Profile',
              location: contactRecord.location.place,
            },
            // { "image": "facebook_logo", "link": contactRecord.social.fB },
            // { "image": "twitter_logo", "link": contactRecord.social.twitter }
          ],
        },
        Stream: {
          type: 'autocomplete',
          value: {
            data: '',
            selected: { lid: identity.lid, lnm: identity.lnm },
            enabled: true,
          },
        },
        // Action: { type: 'button', value: 'Edit', clickType: 'edit' },
        'CRM Action': {
          type: 'button',
          value: crmStatus,
          clickType: 'crm-save',
          disabled: disableSaveBtn,
        },
      };
    }
  }
};

/**
 * IF Email is empty
Domain empty → Review domain dialog
	Domain non empty → Fallback text (“Sorry. No data found”)
ELSE	
Valid = TRUE
	Green Tick Icon
Valid = FALSE
	CATCH_ALL=TRUE
		Yellow icon + Confidence score(if confidence!= Blank)
		
	CATCH_ALL=FALSE
        Fallback text (“No data found”)
        
 * @param {*} contactRecord 
 * @param {*} enrichRecord 
 * @param {Boolean} isListPage is data coming from PeopleList Page
 */
const getBusinessEmailFromContactResult = (
  contactRecord,
  enrichRecord,
  showReview,
  isListPage = false
) => {
  let businessEmail = {};

  if (
    (enrichRecord.reviewCount >= enrichRecord.reviewThreholdCount &&
      contactRecord.bEmail.length == 0) ||
    (contactRecord.bEmail.length == 0 && contactRecord.website.domain != '') ||
    (contactRecord.bEmail.length > 0 &&
      contactRecord.bEmail[0].valid == false &&
      contactRecord.bEmail[0].catchAll == false)
  )
    businessEmail = isListPage
      ? {
          type: 'infoTextAction',
          value: 'Sorry! No data found',
          clickValue: 'Find Again',
          clickType: 'findEmail',
        }
      : { type: 'text', value: 'Sorry! No data found' };
  else if (contactRecord.bEmail.length == 0 && contactRecord.website.domain == '' && showReview)
    businessEmail = {
      type: 'textButton',
      value: 'Could you please review?',
      clickType: 'ReviewEmail',
    };
  else if (contactRecord.bEmail.length == 0 && contactRecord.website.domain == '' && !showReview)
    businessEmail = isListPage
      ? {
          type: 'infoTextAction',
          value: 'Sorry! No data found',
          clickValue: 'Find Again',
          clickType: 'findEmail',
        }
      : { type: 'text', value: 'Sorry! No data found' };
  else
    businessEmail = {
      type: 'catchAllNValidity',
      value: contactRecord.bEmail[0],
    };

  return businessEmail;
};

const showCatchAllForbEmail = bEmail => {
  if (bEmail.valid == false && bEmail.catchAll == true) return bEmail.confidence + ' %';
  return '';
};

const modifyEnrich1APIResponse = (responseData, idx) => {
  return {
    contactRecord: {
      firstName: responseData.firstName,
      lastName: responseData.lastName,
      lurl: responseData.lurl, //linkedin profile URL
      lid: responseData.lid,
      createdTime: responseData.createdTime,
      title: responseData.title,
      imgUrl: responseData.imgUrl,
      company: responseData.company,
      industry: responseData.industry,
      website: responseData.website,
      location: responseData.location,
      keywords: responseData.keywords,
      bEmail: responseData.bEmail,
      bPhone: responseData.bPhone,
      pEmail: responseData.pEmail,
      pPhone: responseData.pPhone,
      social: responseData.social,
    },
    customInfo: responseData?.customInfo,
    enrichmentDetails: {
      ...responseData.enrichmentDetails,
      isEnrichment_E1_GoingOn: DONE,
      reviewCount: responseData.enrichmentDetails.reviewCount + 1,
    },
    identity: {
      ...responseData.identity,
    },
    customInfo: {
      ...responseData.customInfo,
    },
    selected: false,
    id: idx,
  };
};

const modifyEnrich2APIResponse = (responseData, idx) => {
  let enrichDetails = responseData.enrichmentDetails;
  let e1Status = enrichDetails.isEnrichment_E1_GoingOn;
  if (enrichDetails.isOnlyE2Required) {
    e1Status = DONE;
  } else if (!enrichDetails.isOnlyE2Required && enrichDetails.isEnrichment_E1_GoingOn !== DONE) {
    e1Status = NOTSTARTED;
  }
  return {
    contactRecord: {
      firstName: responseData.firstName,
      lastName: responseData.lastName,
      lurl: responseData.lurl, //linkedin profile URL
      lid: responseData.lid,
      createdTime: responseData.createdTime,
      title: responseData.title,
      imgUrl: responseData.imgUrl,
      company: responseData.company,
      industry: responseData.industry,
      website: responseData.website,
      location: responseData.location,
      keywords: responseData.keywords,
      bEmail: responseData.bEmail,
      bPhone: responseData.bPhone,
      pEmail: responseData.pEmail,
      pPhone: responseData.pPhone,
      social: responseData.social,
    },
    customInfo: responseData?.customInfo,
    enrichmentDetails: {
      ...responseData.enrichmentDetails,
      isEnrichment_E2_GoingOn: DONE,
      isEnrichment_E1_GoingOn: e1Status,
      reviewCount: enrichDetails.reviewCount + 1,
    },
    identity: {
      ...responseData.identity,
    },
    customInfo: {
      ...responseData.customInfo,
    },
    selected: false,
    id: idx,
  };
};

const modifyPeopleJSON = (responseData, idx) => {
  const targetSet = responseData.targetSet;
  return {
    contactRecord: {
      firstName: targetSet.firstName,
      lastName: targetSet.lastName,
      lurl: targetSet.lurl, //linkedin profile URL
      lid: targetSet.lid,
      createdTime: targetSet.createdTime,
      title: targetSet.title,
      imgUrl: targetSet.imgUrl,
      company: targetSet.company,
      industry: targetSet.industry,
      website: targetSet.website,
      location: targetSet.location,
      keywords: targetSet.keywords,
      bEmail: targetSet.bEmail,
      bPhone: targetSet.bPhone,
      pEmail: targetSet.pEmail,
      pPhone: targetSet.pPhone,
      social: targetSet.social,
    },
    customInfo: targetSet?.customInfo,
    enrichmentDetails: {
      ...targetSet.enrichmentDetails,
    },
    identity: {
      ...targetSet.identity,
    },
    customInfo: {
      ...targetSet.customInfo,
    },
    selected: false,
    id: idx,
  };
};

/**
 *
 * TODO: complete function for company json
 */
const getPeopleOrCompanyJSON = (data, type) => {
  switch (type) {
    case 'people':
      return {
        ...data?.contactRecord,
        enrichmentDetails: data?.enrichmentDetails,
        identity: data?.identity,
        customInfo: data?.customInfo,
      };
    case 'company':
      return data;
    default:
      return data;
  }
};

// const mimicCompanySearchResult = (len, response) => {
//   const data = [];
//   for (let idx = 0; idx < len; idx++) {
//     data.push({
//       cCity: faker.address.cityName(),
//       cName: faker.name.findName(),
//       cIndustry: 'Information Technology and Services',
//       cTwt: '',
//       cStock: '',
//       cTags: [
//         'IoT',
//         'API Management',
//         'Dev-Ops',
//         'Banking',
//         'Finance',
//         'Logistics',
//         'Big Data',
//         'Machine Learning',
//       ],
//       cDesc: faker.lorem.words(20),
//       lastUpd: {
//         _seconds: 1632518007,
//         _nanoseconds: 101064000,
//       },
//       cTechStack: ['Apache JMeter', 'JavaScript', 'Apache JMeter'],
//       cSize: '11-50',
//       cFb: '',
//       cLogoUrl:
//         'https://media-exp1.licdn.com/dms/image/C510BAQHjPYGz6Axx7w/company-logo_200_200/0/1574850375758?e=2159024400&v=beta&t=dgW8wpg8ZyUTwn1Mr_9Kmp2pe-uR4gvf9ESVhG9wDvw',
//       cLi: 'https://www.linkedin.com/company/xanbell-technologies',
//       cDomain: faker.internet.domainName(),
//       cState: faker.address.state(),
//       cCountry: faker.address.country(),
//       cCb: 'https://www.crunchbase.com/organization/xanbell-technologies',
//     });
//   }
//   response.data = data;
//   return response;
// };

const getGrowthSize = growth => {
  switch (growth) {
    case 'Extreme growth (>50% YoY)':
      return {
        min: 50,
        max: 90,
      };
    case 'Moderate growth (25% - 50% YoY)':
      return {
        min: 25,
        max: 50,
      };
    case 'Low growth  (<25% YoY)':
      return {
        min: 25,
        max: 0,
      };

    default:
      return {};
  }
};

/**
 *
 * @param {object} formData user searchInput JSON
 * @param {String} searchType searchType
 * @param {object} userDetails cid & uid
 * @param {Number} pageNum page number
 * @returns search payload based on given search type
 */
const getSearchPayloadFromSearchType = (formData, searchType, userDetails, pageNum) => {
  let payload = {
    metaFields: {
      // recipeId: 123456789012345, Removing recipeId
      recipeName: searchType,
      recipeType: searchType,
      cId: userDetails.cid,
      uId: userDetails.uid,
      crDt: moment().format('YYYY-MM-DD'),
      lstUpd: moment().format('YYYY-MM-DD'),
      intentType: searchType,
    },
    intentSpec: {
      seniority: '',
      department: '',
      title: '',
      since: 1,
      pTopic: [],
      sTopic: [],
      tStack: [],
      sdt: [],
      geo: '',
      size: '',
      idustry: '',
      skipEnrichment: 'Yes',
      doStore: 'No',
      pageNum,
      cLabel: [],
      cRoot: [],
      nameVc: [],
    },
  };
  let payloadList = [];

  switch (searchType) {
    case SEARCH_RECRUITMENT_BUYER:
      if (formData.title.length > 1) {
      formData.title.forEach(t => {
        let p = JSON.parse(JSON.stringify(payload));
        p.intentSpec.title = [t];
        p.intentSpec.pTopic = formData.pTopic;
        p.intentSpec.tStack = formData.tStack;
        p.intentSpec.geo = formData.geo || '';
        payloadList.push(p);
      });
      return payloadList;
      } else { 
        payload.intentSpec.title = formData.title;
        payload.intentSpec.pTopic = formData.pTopic;
        payload.intentSpec.tStack = formData.tStack;
        payload.intentSpec.geo = formData.geo || '';
        return payload;
      }
      

    case SEARCH_RECRUITMENT_USER:
      if (formData.title.length > 1) {
        formData.title.forEach(t => {
          let p = JSON.parse(JSON.stringify(payload));
          p.intentSpec.title = [t];
          p.intentSpec.pTopic = formData.pTopic;
          p.intentSpec.tStack = formData.tStack;
          p.intentSpec.geo = formData.geo || '';
          payloadList.push(p);
        });
        return payloadList;
      } else {
        payload.intentSpec.title = formData.title;
        payload.intentSpec.pTopic = formData.pTopic;
        payload.intentSpec.tStack = formData.tStack;
        payload.intentSpec.geo = formData.geo || '';
        return payload;
      }

    case SEARCH_EXEC_MOVEMENT:
      if (formData.title.length > 1) {
        formData.title.forEach(t => {
          let p = JSON.parse(JSON.stringify(payload));
          p.intentSpec.title = [t];
          p.intentSpec.pTopic = formData.pTopic;
          p.intentSpec.since = 1; //TODO: hardcoded value
          if (formData.geo) p.intentSpec.geo = formData.geo;
          payloadList.push(p);
        })
        return payloadList;
      } else {   
        payload.intentSpec.seniority = formData.seniority;
        payload.intentSpec.department = formData.department;
        payload.intentSpec.title = formData.title;
        payload.intentSpec.pTopic = formData.pTopic;
        payload.intentSpec.since = 1; //TODO: hardcoded value
        if (formData.geo) {
          payload.intentSpec.geo = formData.geo;
        }
        return payload;
      }

    case SEARCH_TOPIC_SURGE:
      payload.intentSpec.pTopic = formData.pTopic;
      payload.intentSpec.sTopic = formData.sTopic;
      payload.intentSpec.tStack = formData.tStack;
      if (formData.geo) {
        payload.intentSpec.geo = formData.geo;
      }
      if (formData.context) {
        payload.intentSpec.context = formData.context;
      }
      return payload;
    case SEARCH_COMPANY_LABEL: {
      payload.intentSpec.cLabel = formData.cLabel;
      payload.intentSpec.size = formData.size;
      payload.intentSpec.geo = formData.geo;

      const targetedProperties = ['size', 'cLabel', 'geo', 'pageNum'];
      const filteredIntentSpec = Object.keys(payload.intentSpec)
        .filter(key => targetedProperties.includes(key))
        .reduce((acc, key) => {
          acc[key] = payload.intentSpec[key];
          return acc;
        }, {});

      payload.intentSpec = filteredIntentSpec;

      return payload;
    }
    case SEARCH_SIMILAR_INTENT: {
      payload.intentSpec.cRoot = formData.cRoot;

      const targetedProperties = ['cRoot', 'pageNum'];
      const filteredIntentSpec = Object.keys(payload.intentSpec)
        .filter(key => targetedProperties.includes(key))
        .reduce((acc, key) => {
          acc[key] = payload.intentSpec[key];
          return acc;
        }, {});

      payload.intentSpec = filteredIntentSpec;

      return payload;
    }
    case SEARCH_SAME_VC_INTENT: {
      payload.intentSpec.nameVc = formData.nameVc;

      const targetedProperties = ['nameVc', 'pageNum'];
      const filteredIntentSpec = Object.keys(payload.intentSpec)
        .filter(key => targetedProperties.includes(key))
        .reduce((acc, key) => {
          acc[key] = payload.intentSpec[key];
          return acc;
        }, {});

      payload.intentSpec = filteredIntentSpec;

      return payload;
    }
    case SEARCH_DUMMY_3: {
      payload.intentSpec.cLabel = formData.cLabel;
      payload.intentSpec.size = formData.size;
      payload.intentSpec.geo = formData.geo;

      const targetedProperties = ['size', 'cLabel', 'geo', 'pageNum'];
      const filteredIntentSpec = Object.keys(payload.intentSpec)
        .filter(key => targetedProperties.includes(key))
        .reduce((acc, key) => {
          acc[key] = payload.intentSpec[key];
          return acc;
        }, {});

      payload.intentSpec = filteredIntentSpec;

      return payload;
    }
    default:
      return payload;
  }
};

const getSearchTypeFromInput = selectedField => {
  if (selectedField.startsWith('IB1')) {
    return SEARCH_TOPIC_SURGE;
  } else if (selectedField.startsWith('IB2')) {
    return SEARCH_RECRUITMENT_USER;
  } else if (selectedField.startsWith('IB3')) {
    return SEARCH_RECRUITMENT_BUYER;
  } else if (selectedField.startsWith('IB4')) {
    return SEARCH_EXEC_MOVEMENT;
  } else if (selectedField.startsWith('IB5')) {
    return SEARCH_COMPANY_LABEL;
  } else if (selectedField.startsWith('IB6')) {
    return SEARCH_SIMILAR_INTENT;
  } else if (selectedField.startsWith('IB7')) {
    return SEARCH_SAME_VC_INTENT;
  } else if (selectedField.startsWith('IB8')) {
    return SEARCH_DUMMY_3;
  }
  return '';
};

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = epoch => {
  const buildDate = moment(epoch).format('DD-MM-YYY HH:MM');
  return buildDate;
};

export const getNumberOfPeopleLatestFetchRecords = 50;
export const getNumberOfCompanyLatestFetchRecords = 50;

export const maxAllowedChips = 4;
export const maxAllowedTechnologyChips = 1;
export const maxCompanySize = 2;

export {
  isEmpty,
  phoneRegex,
  emailRegex,
  getPlaybooksList,
  getTotalPlaybooksList,
  salesNavUserPlaybook,
  salesNavCompanyPlaybook,
  handleDownloadCSV,
  handleDownloadSearchCSV,
  tableDisplayData,
  contactRecordsModifyResult,
  enrichBusiness1,
  enrichPersonal2,
  salesNavMaxPageScrape,
  setEnrichmentPayload,
  peopleTableDisplayData,
  modifyEnrich1APIResponse,
  modifyEnrich2APIResponse,
  getPeopleOrCompanyJSON,
  modifyPeopleJSON,
  companyTableDisplayData,
  // mimicCompanySearchResult,
  getGrowthSize,
  getSearchTableDisplayData,
  getSearchPayloadFromSearchType,
  getSearchTypeFromInput,
};
