import React, { useEffect } from 'react';

const CrmCallback = () => {
  useEffect(async () => {
    // props.params;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authCode = urlParams.get('code');

    // send message to original window, telling it was success
    if (window.opener) {
      // send code to the opening window
      window.opener.postMessage({
        source: 'glowRadiusRedirect',
        payload: authCode,
      });
      window.close();
    }
  }, []);

  return (
    <div className='app-wrapper animated slideInUpTiny animation-duration-3 d-flex align-items-center justify-content-center'>
      <h1>Please wait...</h1>
    </div>
  );
};

export default CrmCallback;
