import {
  FETCH_ALL_CONTACT,
  FETCH_ALL_CONTACT_SUCCESS,
  GET_ALL_CONTACT,
  HANDLE_REQUEST_CLOSE,
  HIDE_CONTACT_LOADER,
  SHOW_MESSAGE,
  SHOW_INFO_MESSAGE,
  GET_CONTACTS_LIST,
  CONTACT_LIST_RESULT,
  SET_CHECKED_UPDATED_LIST_RESULT,
  GET_CONTACTS_LIST_RESET,
  GET_LIST_NAMES,
  GET_LIST_NAMES_SUCCESS,
  GET_LIST_NAMES_RESET,
  CREATE_CONTACT,
  MOVE_CONTACT,
  NEW_CONTACT_CREATED,
  CONTACT_MOVED,
  SHOW_CONTACT_MESSAGE,
  SHOW_CONTACT_INFO_MESSAGE,
  HIDE_CONTACT_MESSAGE,

  /**fire store list manager */
  CREATE_CUSTOM_LIST,
  VIEW_CUSTOM_LIST,
  SAVE_TO_CUSTOM_LIST,
  GET_LIST_NAME_BY_USER,
  UPDATE_LIST_SUB,
  GET_DEFAULT_LIST,
  SET_DEFAULT_LIST,
  GET_LATEST_PEOPLE_N_RECORDS_FOR_USER,
  GET_LATEST_COMPANY_N_RECORDS_FOR_USER,
  GET_SPECIFIC_RECORDS_FOR_USER,
  CREATE_PEOPLE_CUSTOM_LIST_SUCCESS,
  CREATE_COMPANY_CUSTOM_LIST_SUCCESS,
  STOP_FETCHING_PEOPLELIST,
  STOP_FETCHING_COMPANYLIST,
  VIEW_CUSTOM_PEOPLE_LIST_SUCCESS,
  VIEW_CUSTOM_COMPANY_LIST_SUCCESS,
  SAVE_TO_CUSTOM_LIST_SUCCESS,
  GET_PEOPLE_LIST_NAME_BY_USER_SUCCESS,
  GET_COMPANY_LIST_NAME_BY_USER_SUCCESS,
  UPDATE_LIST_SUB_SUCCESS,
  GET_DEFAULT_LIST_SUCCESS,
  SET_DEFAULT_PEOPLE_LIST_SUCCESS,
  SET_DEFAULT_COMPANY_LIST_SUCCESS,
  GET_LATEST_PEOPLE_N_RECORDS_FOR_USER_SUCCESS,
  GET_LATEST_COMPANY_N_RECORDS_FOR_USER_SUCCESS,
  UPDATE_PEOPLE_ENRICH,
  UPDATE_COMPANY_ENRICH,
  GET_SPECIFIC_RECORDS_FOR_USER_SUCCESS,
  MOVE_CONTACT_TO_LIST,
  MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS,
  MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS,
  UDPATE_SET_DEFAULT_LISTNAME_PEOPLELIST,
  UDPATE_SET_DEFAULT_LISTNAME_COMPANYLIST,
  UPDATE_PEOPLE_ENRICH_INFO,
  GET_RE_ENRICH_1,
  GET_RE_ENRICH_1_SUCCESS,
  GET_RE_ENRICH_1_FAILED,
  START_ENRICHMENT_1_FETCHING,
  UPDATE_PEOPLE_ENRICH_INFO_SUCCESS,
  START_CONTACT_LOADER,
  GET_ENRICH_2_FROM_PEOPLE,
  GET_ENRICH_2_FROM_PEOPLE_SUCCESS,
  GET_ENRICH_2_FROM_PEOPLE_FAILED,
  CHECK_UPDATE,
  CHECK_UPDATE_SUCCESS,
  CHECK_UPDATE_LOADING,
  CHECK_UPDATE_ERROR,
  START_CHECK_UPDATE,
  SHOW_CHECK_UPDATE_MATCH_LIST,
  HIDE_CHECK_UPDATE_MATCH_LIST,
  FORCE_UPDATE,
  FORCE_UPDATE_SUCCESS,
  FORCE_UPDATE_ERROR,
  START_COMPANY_UPDATE,
  COMPANY_UPDATE_ERROR,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_LOADING,
  DELETE_PEOPLE_LIST_NAMES,
} from 'constants/ActionTypes';

export const fetchContacts = () => {
  return {
    type: FETCH_ALL_CONTACT,
  };
};

export const fetchContactsSuccess = contact => {
  return {
    type: FETCH_ALL_CONTACT_SUCCESS,
    payload: contact,
  };
};
export const showContactMessage = message => {
  return {
    type: SHOW_CONTACT_MESSAGE,
    payload: message,
  };
};

export const showContactInfoMessage = message => {
  return {
    type: SHOW_CONTACT_INFO_MESSAGE,
    payload: message,
  };
};

export const hideContactMessage = message => {
  return {
    type: HIDE_CONTACT_MESSAGE,
    payload: message,
  };
};

export const getAllContact = () => {
  return {
    type: GET_ALL_CONTACT,
    payload: '',
  };
};

export const handleRequestClose = () => {
  return {
    type: HANDLE_REQUEST_CLOSE,
  };
};
export const hideContactLoader = () => {
  return {
    type: HIDE_CONTACT_LOADER,
  };
};

export const getContactsList = data => {
  return {
    type: GET_CONTACTS_LIST,
    payload: data,
  };
};

export const contactListSetResult = data => {
  return {
    type: CONTACT_LIST_RESULT,
    payload: data,
  };
};

export const setSelectUpdated = data => {
  return {
    type: SET_CHECKED_UPDATED_LIST_RESULT,
    payload: data,
  };
};

export const getContactsListReset = data => {
  return {
    type: GET_CONTACTS_LIST_RESET,
    payload: data,
  };
};

export const getListNames = data => {
  return {
    type: GET_LIST_NAMES,
    payload: data,
  };
};

export const getListNamesSuccess = data => {
  return {
    type: GET_LIST_NAMES_SUCCESS,
    payload: data,
  };
};

export const getListNamesReset = data => {
  return {
    type: GET_LIST_NAMES_RESET,
    payload: data,
  };
};

export const createContact = data => {
  return {
    type: CREATE_CONTACT,
    payload: data,
  };
};

export const moveContact = data => {
  return {
    type: MOVE_CONTACT,
    payload: data,
  };
};

export const createContactSuccess = data => {
  return {
    type: NEW_CONTACT_CREATED,
    payload: data,
  };
};

export const moveContactSuccess = data => {
  return {
    type: CONTACT_MOVED,
    payload: data,
  };
};

/**Fire store contacts */

export const createCustomListName = listName => {
  return {
    type: CREATE_CUSTOM_LIST,
    payload: listName,
  };
};

export const getViewCustomList = data => {
  return {
    type: VIEW_CUSTOM_LIST,
    payload: data,
  };
};

export const saveToCustomList = data => {
  return {
    type: SAVE_TO_CUSTOM_LIST,
    payload: data,
  };
};

export const getListNameByUser = data => {
  return {
    type: GET_LIST_NAME_BY_USER,
    payload: data,
  };
};

export const updateListSub = data => {
  return {
    type: UPDATE_LIST_SUB,
    payload: data,
  };
};

export const getDefaultList = data => {
  return {
    type: GET_DEFAULT_LIST,
    payload: data,
  };
};

export const setDefaultList = data => {
  return {
    type: SET_DEFAULT_LIST,
    payload: data,
  };
};

export const getLatestPeopleNRecords = data => {
  return {
    type: GET_LATEST_PEOPLE_N_RECORDS_FOR_USER,
    payload: data,
  };
};

export const getLatestCompanyNRecords = data => {
  return {
    type: GET_LATEST_COMPANY_N_RECORDS_FOR_USER,
    payload: data,
  };
};

export const stopFetchingPeopleList = data => {
  return {
    type: STOP_FETCHING_PEOPLELIST,
    payload: data,
  };
};

export const stopFetchingCompanyList = data => {
  return {
    type: STOP_FETCHING_COMPANYLIST,
    payload: data,
  };
};

export const getSpecificRecords = data => {
  return {
    type: GET_SPECIFIC_RECORDS_FOR_USER,
    payload: data,
  };
};

export const createPeopleListSuccess = data => {
  return {
    type: CREATE_PEOPLE_CUSTOM_LIST_SUCCESS,
    payload: data,
  };
};

export const createCompanyListSuccess = data => {
  return {
    type: CREATE_COMPANY_CUSTOM_LIST_SUCCESS,
    payload: data,
  };
};

export const viewPeopleListSuccess = data => {
  return {
    type: VIEW_CUSTOM_PEOPLE_LIST_SUCCESS,
    payload: data,
  };
};

export const viewCompanyListSuccess = data => {
  return {
    type: VIEW_CUSTOM_COMPANY_LIST_SUCCESS,
    payload: data,
  };
};

export const saveToCompanyListNameSuccess = data => {
  return {
    type: SAVE_TO_CUSTOM_LIST_SUCCESS,
    payload: data,
  };
};

export const getPeopleListByUserSuccess = data => {
  return {
    type: GET_PEOPLE_LIST_NAME_BY_USER_SUCCESS,
    payload: data,
  };
};

export const getCompanyListByUserSuccess = data => {
  return {
    type: GET_COMPANY_LIST_NAME_BY_USER_SUCCESS,
    payload: data,
  };
};

export const updateListSuccess = data => {
  return {
    type: UPDATE_LIST_SUB_SUCCESS,
    payload: data,
  };
};

export const getDefListSuccess = data => {
  return {
    type: GET_DEFAULT_LIST_SUCCESS,
    payload: data,
  };
};

export const setPeopleDefListSuccess = data => {
  return {
    type: SET_DEFAULT_PEOPLE_LIST_SUCCESS,
    payload: data,
  };
};

export const setCompanyDefListSuccess = data => {
  return {
    type: SET_DEFAULT_COMPANY_LIST_SUCCESS,
    payload: data,
  };
};

export const updateSetDefaultListNameInPeopleList = data => {
  return {
    type: UDPATE_SET_DEFAULT_LISTNAME_PEOPLELIST,
    payload: data,
  };
};

export const updateSetDefaultListNameInCompanyhList = data => {
  return {
    type: UDPATE_SET_DEFAULT_LISTNAME_COMPANYLIST,
    payload: data,
  };
};

export const updatePoepleEnrich = data => {
  return {
    type: UPDATE_PEOPLE_ENRICH,
    payload: data,
  };
};

export const updateCompanyEnrich = data => {
  return {
    type: UPDATE_COMPANY_ENRICH,
    payload: data,
  };
};

export const updatePeopleEnrichInfo = data => {
  return {
    type: UPDATE_PEOPLE_ENRICH_INFO,
    payload: data,
  };
};

export const startContactLoader = () => {
  return {
    type: START_CONTACT_LOADER,
  };
};

export const updatePeopleEnrichInfoSuccess = data => {
  return {
    type: UPDATE_PEOPLE_ENRICH_INFO_SUCCESS,
    payload: data,
  };
};

export const getLatestPeopleNRecordsSuccess = data => {
  return {
    type: GET_LATEST_PEOPLE_N_RECORDS_FOR_USER_SUCCESS,
    payload: data,
  };
};

export const getLatestCompanyNRecordsSuccess = data => {
  return {
    type: GET_LATEST_COMPANY_N_RECORDS_FOR_USER_SUCCESS,
    payload: data,
  };
};

export const getSpecificRecordsSuccess = data => {
  return {
    type: GET_SPECIFIC_RECORDS_FOR_USER_SUCCESS,
    payload: data,
  };
};

export const moveContactToList = data => {
  return {
    type: MOVE_CONTACT_TO_LIST,
    payload: data,
  };
};

export const movePeopleContactToListSuccess = data => {
  console.log('each move', data);
  return {
    type: MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS,
    payload: data,
  };
};

export const moveCompanyContactToListSuccess = data => {
  return {
    type: MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS,
    payload: data,
  };
};

// Enrichment

export const startEnrichment1Fetching = () => {
  return {
    type: START_ENRICHMENT_1_FETCHING,
  };
};

export const getReEnrich1ResultFromAPI = data => {
  return {
    type: GET_RE_ENRICH_1,
    payload: data,
  };
};

export const getReEnrich1Success = data => {
  return {
    type: GET_RE_ENRICH_1_SUCCESS,
    payload: data,
  };
};

export const getReEnrich1Failed = data => {
  return {
    type: GET_RE_ENRICH_1_FAILED,
    payload: data,
  };
};

export const getEnrich2FromPeople = data => {
  return {
    type: GET_ENRICH_2_FROM_PEOPLE,
    payload: data,
  };
};

export const getEnrich2FromPeopleSuccess = data => {
  return {
    type: GET_ENRICH_2_FROM_PEOPLE_SUCCESS,
    payload: data,
  };
};

export const getEnrich2FromPeopleFailed = data => {
  return {
    type: GET_ENRICH_2_FROM_PEOPLE_FAILED,
    payload: data,
  };
};

// crm

export const checkUpdate = data => {
  return {
    type: CHECK_UPDATE,
    payload: data,
  };
};

export const checkUpdateSuccess = data => {
  return {
    type: CHECK_UPDATE_SUCCESS,
    payload: data,
  };
};

export const checkUpdateLoading = data => {
  return {
    type: CHECK_UPDATE_LOADING,
    payload: data,
  };
};

export const checkUpdateError = data => {
  return {
    type: CHECK_UPDATE_ERROR,
    payload: data,
  };
};

export const startCheckUpdate = data => {
  return {
    type: START_CHECK_UPDATE,
    payload: data,
  };
};

export const showCheckUpdateMatchList = data => {
  return {
    type: SHOW_CHECK_UPDATE_MATCH_LIST,
    payload: data,
  };
};

export const hideCheckUpdateMatchList = () => {
  return {
    type: HIDE_CHECK_UPDATE_MATCH_LIST,
    payload: null,
  };
};

export const forceUpdate = data => {
  return {
    type: FORCE_UPDATE,
    payload: data,
  };
};

export const forceUpdateSuccess = data => {
  return {
    type: FORCE_UPDATE_SUCCESS,
    payload: data,
  };
};

export const forceUpdateError = data => {
  return {
    type: FORCE_UPDATE_ERROR,
    payload: data,
  };
};

export const startCompanyUpdate = data => {
  return {
    type: START_COMPANY_UPDATE,
    payload: data,
  };
};

export const companyUpdateLoading = data => {
  return {
    type: COMPANY_UPDATE_LOADING,
    payload: data,
  };
};

export const companyUpdateSuccess = data => {
  return {
    type: COMPANY_UPDATE_SUCCESS,
    payload: data,
  };
};

export const companyUpdateError = data => {
  return {
    type: COMPANY_UPDATE_ERROR,
    payload: data,
  };
};

export const deletePeopleList = id => {
  return {
    type: DELETE_PEOPLE_LIST_NAMES,
    payload: id,
  };
};
