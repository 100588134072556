import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SHOW_INFO_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  PROFILE,
  PROFILE_SUCCESS,
  ADD_PROFILE_DETAILS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  FORGOT_PASSWORD_RESET_EMAIL,
  FORGOT_PASSWORD_RESET_EMAIL_SUCCESS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS,
  PROFILE_DETAILS_SUBMITTED,
  PROFILE_DETAILS_NOT_SUBMITTED,
  ADD_INVITE,
  GET_INVITATION_DETAILS,
  SAVE_INVITATION_DETAILS,
  CONFIRM_INVITATION,
  CONFIRM_INVITATION_SUCCESS,
  SET_FIREBASE_AUTH_STATE_SUCCESS,
  GET_ROUTE_DETAILS,
  GET_ROUTE_DETAILS_SUCCESS,
} from 'constants/ActionTypes';

export const userSignUp = user => {
  return {
    type: SIGNUP_USER,
    payload: user,
  };
};
export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignUpSuccess = authUser => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  };
};

export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};

export const updateProfile = input => {
  return {
    type: UPDATE_PROFILE,
    payload: input,
  };
};

export const updateProfileSuccess = () => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
  };
};

export const updatePassword = input => {
  return {
    type: UPDATE_PASSWORD,
    payload: input,
  };
};

export const updatePasswordSuccess = () => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
  };
};

export const profileSuccess = () => {
  return {
    type: PROFILE_SUCCESS,
  };
};

export const navProfile = () => {
  return {
    type: PROFILE,
  };
};

export const addUserDetails = body => {
  return {
    type: ADD_PROFILE_DETAILS,
    payload: body,
  };
};

export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const showSuccessMessage = message => {
  return {
    type: SHOW_INFO_MESSAGE,
    payload: message,
  };
};

export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER,
  };
};
export const userGoogleSignInSuccess = authUser => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER,
  };
};
export const userFacebookSignInSuccess = authUser => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER,
  };
};
export const userTwitterSignInSuccess = authUser => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER,
  };
};
export const userGithubSignInSuccess = authUser => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
  };
};

export const forgorPasswordResetEmail = email => {
  return {
    type: FORGOT_PASSWORD_RESET_EMAIL,
    email,
  };
};

export const getUserLoginDetails = payload => {
  return {
    type: GET_USER_DETAILS,
    payload,
  };
};

export const getUserLoginDetailsSuccess = data => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getRouteDetails = payload => {
  return {
    type: GET_ROUTE_DETAILS,
    payload,
  };
};

export const getRouteDetailsSuccess = data => {
  return {
    type: GET_ROUTE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const update_profile_details = data => {
  return {
    type: UPDATE_PROFILE_DETAILS,
    payload: data,
  };
};

export const profileDetailsAreSubmitted = () => {
  return {
    type: PROFILE_DETAILS_SUBMITTED,
  };
};

export const profileDetailsAreNotSubmitted = () => {
  return {
    type: PROFILE_DETAILS_NOT_SUBMITTED,
  };
};

export const forgorPasswordResetEmailSuccess = () => {
  return {
    type: FORGOT_PASSWORD_RESET_EMAIL_SUCCESS,
  };
};

export const add_invite = data => {
  return {
    type: ADD_INVITE,
    payload: data,
  };
};

export const get_invitation_details = data => {
  return {
    type: GET_INVITATION_DETAILS,
    payload: data,
  };
};

export const saveInvitedDetails = data => {
  return {
    type: SAVE_INVITATION_DETAILS,
    payload: data,
  };
};

export const confirm_invitation = data => {
  return {
    type: CONFIRM_INVITATION,
    payload: data,
  };
};

export const confirm_invitation_success = data => {
  return {
    type: CONFIRM_INVITATION_SUCCESS,
  };
};

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const setFirebaseAuthStateLoaded = () => {
  return {
    type: SET_FIREBASE_AUTH_STATE_SUCCESS,
  };
};
