import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, IconButton as MuiIconButton } from '@material-ui/core';
import IconButton from 'components/IconButton';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiBackdrop-root': {
      backgroundColor: '#f9f8f86b',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 500,
    border: 'none',
    outline: 'none',
    borderRadius: 6,
    boxShadow: `0px 4px 24px rgba(0, 0, 0, 0.16)`,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& > h6': {
      fontWeight: 600,
    },
  },
  modalFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: `${theme.spacing(1)}px`,
    padding: theme.spacing(1, 0, 0),
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  modalContent: {
    padding: theme.spacing(1, 0),
    '& .MuiAlert-root': {
      margin: theme.spacing(1, 0),
    },
  },

  alertMsg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& .alert-circle': {
      height: '188px',
      width: '188px',
      '& img': { height: '100%', width: '100%' },
    },
  },
}));

const TransitionModal = props => {
  const classes = useStyles();
  const {
    open,
    handleClose,
    modalTitle,
    description,
    actionButtonText,
    actionLoadingButtonText,
    actionButtonHandler,
    showMessage,
    severity,
    message,
    loading,
  } = props;

  const handleActionButtonClick = e => {
    e.preventDefault();
    actionButtonHandler();
    // handleClose();
  };

  return (
    <div>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <Typography variant='h6'> {modalTitle} </Typography>
              <MuiIconButton onClick={handleClose} size='small'>
                <CloseIcon />
              </MuiIconButton>
            </div>
            <div className={classes.modalContent}>
              <Typography variant='caption' component='div' color='textSecondary'>
                {description}
              </Typography>
              {props.children}
              {showMessage && severity === 'error' && (
                <Collapse in={showMessage}>
                  <Alert severity='error' icon={false}>
                    {message}
                  </Alert>
                </Collapse>
              )}
              {showMessage && severity === 'success' && (
                <div className={classes.alertMsg}>
                  <div className='alert-circle'>
                    <img src={require('assets/images/check-circle.png')} alt='alert-icon' />
                  </div>
                  <p>{message}</p>
                </div>
              )}
            </div>
            <div className={classes.modalFooter}>
              <IconButton
                className='jr-btn'
                size='medium'
                variant='contained'
                aria-label='add'
                onClick={handleClose}
              >
                Cancel
              </IconButton>
              <IconButton
                className='jr-btn'
                size='medium'
                variant='contained'
                color='primary'
                onClick={handleActionButtonClick}
              >
                {loading ? actionLoadingButtonText : actionButtonText}
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionModal;

TransitionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  // setOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string.isRequired,
  actionButtonHandler: PropTypes.func.isRequired,
  showMessage: PropTypes.bool.isRequired,
  severity: PropTypes.oneOf(['error', 'info', 'success']),
  message: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  actionLoadingButtonText: PropTypes.string.isRequired,
};

TransitionModal.defaultProps = {
  showMessage: false,
  message: '',
  severity: 'info',
  modalTitle: 'this is title of the modal',
  description: 'this is description of the modal',
  open: false,
  loading: false,
  actionLoadingButtonText: 'Updating',
  actionButtonText: 'Update',
};
