import React from 'react';
import { ListSubheader } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

let FilterListPopOver = props => {
  const { filterList, filterType, handleSelectedFilter } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = type => () => {
    props.handleSelectedFilter(type);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  return (
    <Toolbar className='table-header'>
      <div className='title'>
        <Typography variant='title'>Showing : {filterType}</Typography>
      </div>
      <div className='spacer' />
      <div className='actions'>
        <Tooltip title='' onClick={handleClick}>
          <IconButton aria-label='Filter list'>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List>
            <ListSubheader>Filter</ListSubheader>
            {filterList.map((item, index) => (
              <ListItem key={index} button onClick={handleFilter(item)}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Popover>
      </div>
    </Toolbar>
  );
};

export default FilterListPopOver;
