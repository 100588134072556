import React, { useState, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, NativeSelect } from '@material-ui/core';
import { companySize, cityArray, countryArray } from '../../util/dropdownData';
import { isEmpty, maxAllowedChips, maxAllowedTechnologyChips, maxCompanySize } from '../../util';
import IconButton from '../IconButton';
import InputField from 'components/Input';
import TagsView from 'components/TagsView';

const industry = require('../../assets/data/industry_list.json');

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  root: {
    flexGrow: 1,
  },
  searchBtn: {
    width: '100%',
    padding: '18px',
    borderRadius: '5px',
  },
  fields: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '14px',
    color: '#484848',
    fontWeight: '400',
  },
  bottomBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'end',
    padding: '15px',
    borderTop: '1px solid #ebebeb',
  },
  container: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
    height: '80vh',
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px 15px',
    width: '324px',
    height: '49px',
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    borderRadius: '5px',
    outline: 'none',
  },
  formWrapper: {
    padding: '15px',
    maxHeight: '75vh',
    overflowY: 'scroll',
    '& .form_cards': {
      padding: '15px',
      border: 'solid 0.5px #B4B4B4',
      marginBottom: '25px',
      borderRadius: '5px',
      '& .headings': {
        color: '#222F3C',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '800',
        lineHeight: '19px',
      },
      '& .subHeadings': {
        display: 'flex',
        gap: '0 2px',
        '& > p': {
          color: '#717171',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '17px',
          marginBottom: '7px',
        },
      },
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      height: '77px',
      background: '#DDDDDD',
      opacity: 0.5,
      border: '0.5px solid #717171',
      borderRadius: '10px',
    },
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0),
    '& > div': {
      padding: 0,
    },
    '& > select:focus': {
      borderRadius: theme.shape.borderRadius,
      '&::after': {
        width: '0',
        height: '0',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderTop: '6px solid #f00',
        position: 'absolute',
        top: '40%',
        right: '5px',
        content: '""',
        zIndex: 98,
      },
    },
    '& input': {
      margin: '10px 0',
      '&:focus': {
        borderColor: 'blue',
      },
    },
  },
}));

const chipFillWithData = (formObj, keyName) =>
  formObj[keyName].map((item, index) => {
    return { key: index, name: item };
  });

// const fillForm = inputFormData => {
//   if (!isEmpty(inputFormData)) {
//     const formObj = inputFormData.payloadForm.companySearch;

//     return {
//       companyName: {
//         value: '',
//         required: false,
//         chip: {
//           chipData: chipFillWithData(formObj, 'companyName'),
//           maxAllowed: maxAllowedChips,
//         },
//       },
//       companySize: {
//         value: '',
//         required: false,
//         chip: {
//           chipData: chipFillWithData(formObj, 'size'),
//           maxAllowed: maxCompanySize,
//         },
//       },
//       industry: {
//         value: '',
//         required: false,
//         chip: {
//           chipData: chipFillWithData(formObj, 'industry'),
//           maxAllowed: maxAllowedChips,
//         },
//       },
//       keywords: {
//         value: '',
//         required: false,
//         chip: {
//           chipData: chipFillWithData(formObj, 'keywords'),
//           maxAllowed: maxAllowedChips,
//         },
//       },
//       location: { value: '', required: false },
//       revenue: {
//         value: '',
//         required: false,
//         chip: {
//           chipData: chipFillWithData(formObj, 'revenue'),
//           maxAllowed: maxAllowedChips,
//         },
//       },
//       growthSignal: { value: '', required: false, atleastOne: 'T1' },
//       developerInput: {
//         value: formObj['searchString'],
//         required: false,
//         atleastOne: 'T1',
//       },
//     };
//   } else
//     return {
//       companyName: {
//         value: '',
//         required: false,
//         chip: { chipData: [], maxAllowed: maxAllowedChips },
//       },
//       companySize: {
//         value: '',
//         required: false,
//         chip: { chipData: [], maxAllowed: maxCompanySize },
//       },
//       industry: {
//         value: '',
//         required: false,
//         chip: { chipData: [], maxAllowed: maxAllowedChips },
//       },
//       keywords: {
//         value: '',
//         required: false,
//         chip: { chipData: [], maxAllowed: maxAllowedChips },
//       },
//       location: { value: '', required: false },
//       revenue: {
//         value: '',
//         required: false,
//         chip: { chipData: [], maxAllowed: maxAllowedChips },
//       },
//       growthSignal: { value: '', required: false, atleastOne: 'T1' },
//       developerInput: { value: '', required: false, atleastOne: 'T1' },
//     };
// };

const TagsInput = ({ searchSubmit }) => {
  const initialState = {
    technology: {
      title: 'technology',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedTechnologyChips },
    },
    keywords: {
      title: 'keywords',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedChips },
    },
    industry: {
      title: 'industries',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedChips },
    },
    city: {
      title: 'city',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    state: {
      title: 'state',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    country: {
      title: 'country',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    revenue: {
      title: 'revenue details',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: maxAllowedChips },
    },
    companySize: {
      title: 'company sizes',
      value: '',
      required: false,
      chip: { chipData: [], maxAllowed: 1 },
    },
    // "growthSignal": { value: "", required: false, atleastOne : "T1"},
    // "developerInput": { value: "", required: false,  atleastOne : "T1" },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const [companyNameEnable, setcompanyNameEnable] = useState(true);
  const [restFormEnable, setrestFormEnable] = useState(true);
  const [sizeEnable, setSizeEnable] = useState(true);
  const [revenueEnable, setRevenueEnable] = useState(true);
  const [formValidationMessage, setFormValidationMessage] = useState('');
  const [keywordState, setKeywordState] = useState({
    K1: { value: '', arr: [] },
    K2: { value: '', arr: [] },
    K3: { value: '', arr: [] },
  });
  const [technologyState, setTechnologyState] = useState({
    T1: { value: '', arr: [] },
    T2: { value: '', arr: [] },
  });

  // ? Keyword Handlers

  const handleKeywordChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name: 'keywords', value } });
    switch (name) {
      case 'K1':
        setKeywordState({
          ...keywordState,
          K1: { ...keywordState.K1, value: value },
        });
        break;
      case 'K2':
        setKeywordState({
          ...keywordState,
          K2: { ...keywordState.K2, value: value },
        });
        break;
      case 'K3':
        setKeywordState({
          ...keywordState,
          K3: { ...keywordState.K3, value: value },
        });
        break;
    }
  };

  const addKeywordChip = ({ key, target: { name, value } }) => {
    if (key === 'Enter' && value != '') {
      const stateItem = { ...state['keywords'] };
      const maxChipsAllowed = stateItem.chip.maxAllowed;
      const nexChipKey = stateItem.chip.chipData.length + 1;

      const notDuplicated = stateItem.chip.chipData.find(item => item.name == value);
      if (nexChipKey <= maxChipsAllowed && !notDuplicated) {
        const cData = stateItem.chip.chipData;
        cData.push({
          key: nexChipKey,
          name: value,
        });
        // ! Update State for K1 || K2 || K3 arrayis
        // ! AND
        // ! Clear input field for K1 || K2 || K3
        switch (name) {
          case 'K1':
            setKeywordState({
              ...keywordState,
              K1: {
                ...keywordState.K1,
                value: '',
                arr: [...keywordState.K1.arr, value],
              },
            });
            break;
          case 'K2':
            setKeywordState({
              ...keywordState,
              K2: {
                ...keywordState.K2,
                value: '',
                arr: [...keywordState.K2.arr, value],
              },
            });
            break;
          case 'K3':
            setKeywordState({
              ...keywordState,
              K3: {
                ...keywordState.K3,
                value: '',
                arr: [...keywordState.K3.arr, value],
              },
            });
            break;
        }
        // ? Central State Update
        dispatch({
          type: 'updateChip',
          payload: {
            name: 'keywords',
            cData,
          },
        });
        // ? Central State input clear
        dispatch({
          type: 'addInput',
          payload: {
            name: 'keywords',
            value: '',
          },
        });
      } else if (nexChipKey > maxChipsAllowed)
        // showValidationMessage("Not allowed more than " + maxChipsAllowed);
        setFormValidationMessage(
          'For optimal result search up to ' +
            maxChipsAllowed +
            ' ' +
            stateItem.title +
            ' in one go'
        );
      else if (notDuplicated)
        // showValidationMessage("Duplicates are allowed");
        setFormValidationMessage('Duplicates are not allowed');
    }
  };

  const deleteKeywordChip = (name, data) => {
    console.log(name, `${data}`);
    // * Central State
    const cData = [...state['keywords'].chip.chipData];
    const chipToDelete = cData.indexOf(data);
    cData.splice(chipToDelete, 1);
    // * Keyword State
    switch (name) {
      case 'K1':
        let arrK1Filtered = keywordState.K1.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          K1: {
            ...keywordState.K1,
            arr: [...arrK1Filtered],
          },
        });
        break;
      case 'K2':
        let arrK2Filtered = keywordState.K2.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          K2: {
            ...keywordState.K2,
            arr: [...arrK2Filtered],
          },
        });
        break;
      case 'K3':
        let arrK3Filtered = keywordState.K3.arr.filter(el => !(el === data));
        setKeywordState({
          ...keywordState,
          K3: {
            ...keywordState.K3,
            arr: [...arrK3Filtered],
          },
        });
        break;
    }
    // console.log(keywordState);
    dispatch({ type: 'updateChip', payload: { name: 'keywords', cData } });
  };

  // ? Technology Hanldlers

  const handleTechnologyChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name: 'technology', value } });
    switch (name) {
      case 'T1':
        setTechnologyState({
          ...technologyState,
          T1: { ...technologyState.T1, value: value },
        });
        break;
      case 'T2':
        setTechnologyState({
          ...technologyState,
          T2: { ...technologyState.T2, value: value },
        });
        break;
    }
  };

  const addTechnologyChip = ({ key, target: { name, value } }) => {
    if (key === 'Enter' && value != '') {
      const stateItem = { ...state['technology'] };
      const maxChipsAllowed = stateItem.chip.maxAllowed;
      const nexChipKey = stateItem.chip.chipData.length + 1;

      const notDuplicated = stateItem.chip.chipData.find(item => item.name == value);
      if (nexChipKey <= maxChipsAllowed && !notDuplicated) {
        const cData = stateItem.chip.chipData;
        cData.push({
          key: nexChipKey,
          name: value,
        });
        // ! Update State for T1 || T2 arrays
        // ! AND
        // ! Clear input field for T1 || T2
        switch (name) {
          case 'T1':
            setTechnologyState({
              ...technologyState,
              T1: {
                ...technologyState.T1,
                value: '',
                arr: [...technologyState.T1.arr, value],
              },
            });
            break;
          case 'T2':
            setTechnologyState({
              ...technologyState,
              T2: {
                ...technologyState.T2,
                value: '',
                arr: [...technologyState.T2.arr, value],
              },
            });
            break;
        }
        // ? Central State Update
        dispatch({
          type: 'updateChip',
          payload: {
            name: 'technology',
            cData,
          },
        });
        // ? Central State input clear
        dispatch({
          type: 'addInput',
          payload: {
            name: 'technology',
            value: '',
          },
        });
      } else if (nexChipKey > maxChipsAllowed)
        // showValidationMessage("Not allowed more than " + maxChipsAllowed);
        setFormValidationMessage(
          'For optimal result search up to ' +
            maxChipsAllowed +
            ' ' +
            stateItem.title +
            ' in one go'
        );
      else if (notDuplicated)
        // showValidationMessage("Duplicates are allowed");
        setFormValidationMessage('Duplicates are not allowed');
    }
  };

  const deleteTechnologyChip = (name, data) => {
    console.log(name, `${data}`);
    // * Central State
    const cData = [...state['technology'].chip.chipData];
    const chipToDelete = cData.indexOf(data);
    cData.splice(chipToDelete, 1);
    // * Technology State
    switch (name) {
      case 'T1':
        let arrT1Filtered = technologyState.T1.arr.filter(el => !(el === data));
        setTechnologyState({
          ...technologyState,
          T1: {
            ...technologyState.T1,
            arr: [...arrT1Filtered],
          },
        });
        break;
      case 'T2':
        let arrT2Filtered = technologyState.T2.arr.filter(el => !(el === data));
        setTechnologyState({
          ...technologyState,
          T2: {
            ...technologyState.T2,
            arr: [...arrT2Filtered],
          },
        });
        break;
    }
    dispatch({
      type: 'updateChip',
      payload: {
        name: 'technology',
        cData,
      },
    });
  };

  useEffect(() => {
    console.log(keywordState);
    console.log(state.technology);
    console.log(technologyState);
  }, [keywordState, state, technologyState]);

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      case 'updateChip':
        let item = { ...state[action.payload.name] };
        item.chip = { ...item.chip };
        item.chip.chipData = [...action.payload.cData];

        return {
          ...state,
          [action.payload.name]: item,
        };

        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            chip: { ...item },
          },
        };
      default:
        throw new Error();
    }
  }

  //use reducer changes
  useEffect(() => {
    setFormValidationMessage('');

    Object.keys(state).forEach(item => {
      if (
        state[item].value != '' ||
        (state[item].hasOwnProperty('chip') && state[item].chip.hasOwnProperty('chipData'))
      ) {
        switch (item) {
          case 'companyName':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setrestFormEnable(false);
            } else {
              setrestFormEnable(true);
            }
            break;

          case 'industry':
          case 'keywords':
          case 'location':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setcompanyNameEnable(false);
            } else {
              setcompanyNameEnable(true);
            }

            break;
          case 'companySize':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setcompanyNameEnable(false);
              setRevenueEnable(false);
            } else {
              setcompanyNameEnable(true);
              setRevenueEnable(true);
            }

            break;

          case 'revenue':
            if (state[item].value != '' || state[item].chip.chipData.length > 0) {
              setcompanyNameEnable(false);
              setSizeEnable(false);
            } else if (state['companySize'].value == '' && state[item].chip.chipData.length == 0) {
              setcompanyNameEnable(true);
              setSizeEnable(true);
            }
            break;

          default:
            break;
        }
      }
    });
  }, [state]);

  const handleOnInputChange = ({ target: { name, value } }) => {
    console.log(state);
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const handleOnSelectionChange = ({ target: { name, value } }) => {
    const cData = [{ key: 1, name: value }];
    dispatch({ type: 'updateChip', payload: { name, cData } });
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const addChip = (name, value) => {
    const stateItem = { ...state[name] };
    const maxChipsAllowed = stateItem.chip.maxAllowed;
    const nexChipKey = stateItem.chip.chipData.length + 1;

    const notDuplicated = stateItem.chip.chipData.find(item => item.name == value);
    if (nexChipKey <= maxChipsAllowed && !notDuplicated) {
      const cData = stateItem.chip.chipData;
      cData.push({ key: nexChipKey, name: value });
      dispatch({ type: 'updateChip', payload: { name, cData } });
      dispatch({ type: 'addInput', payload: { name, value: '' } });
    } else if (nexChipKey > maxChipsAllowed)
      // showValidationMessage("Not allowed more than " + maxChipsAllowed);
      setFormValidationMessage(
        'For optimal result search up to ' + maxChipsAllowed + ' ' + stateItem.title + ' in one go'
      );
    else if (notDuplicated)
      // showValidationMessage("Duplicates are allowed");
      setFormValidationMessage('Duplicates are not allowed');
  };

  const handleRequestChipDelete = (name, data) => {
    const cData = [...state[name].chip.chipData];
    const chipToDelete = cData.indexOf(data);
    cData.splice(chipToDelete, 1);
    dispatch({ type: 'updateChip', payload: { name, cData } });
  };

  const handleRequestChipAdd = ({ key, target: { name, value } }) => {
    if (key === 'Enter' && value != '') addChip(name, value);
  };

  const handleSearchSubmit = () => {
    // if just typed but not added as a chip
    Object.keys(state).forEach(item => {
      if (
        state[item].value != '' &&
        state[item].hasOwnProperty('chip') &&
        state[item].chip.chipData.length == 0
      )
        addChip(item, state[item].value);
    });
    // console.log(state);
    searchSubmit(state);
  };

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {formValidationMessage.length > 0 && (
        <p className='jr-fs-l text-black font-weight-medium formBox mt-2 mb-3'>
          {formValidationMessage}
        </p>
      )}

      <div className={classes.formWrapper}>
        <div className='form_cards'>
          <h5 className='headings'>BACKDOOR SIGNALS</h5>
          {/* K1 */}
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>What keywords describe your best fit customers?</p>
            </div>
            <InputField
              type='text'
              id='keywords'
              name='K1'
              onChange={handleKeywordChange}
              onKeyPress={addKeywordChip}
              margin='none'
              className='col-xs-12 col-lg-12'
              fullWidth
              value={keywordState.K1.value}
              placeholder='SaaS, HRMS, FinTech'
              disabled={state.technology.chip.chipData.length}
            />
            <TagsView
              inputName='K1'
              state={keywordState}
              deleteChip={deleteKeywordChip}
              type='keywords'
            />
          </FormControl>
          {/* K2 */}
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>What topics your best fit customers interact with?</p>
            </div>
            <InputField
              type='text'
              id='keywords'
              name='K2'
              onChange={handleKeywordChange}
              onKeyPress={addKeywordChip}
              margin='none'
              className='col-xs-12 col-lg-12'
              fullWidth
              value={keywordState.K2.value}
              placeholder='OKR, Performance Reviews, Employee Eng...'
              disabled={state.technology.chip.chipData.length}
            />
            <TagsView
              inputName='K2'
              state={keywordState}
              deleteChip={deleteKeywordChip}
              type='keywords'
            />
          </FormControl>
          {/* K3 */}
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>What keywords describe customers that your competition is selling to?</p>
            </div>
            <InputField
              type='text'
              id='keywords'
              name='K3'
              onChange={handleKeywordChange}
              onKeyPress={addKeywordChip}
              margin='none'
              className='col-xs-12 col-lg-12'
              fullWidth
              value={keywordState.K3.value}
              placeholder='Marketing automation, Inbound Sales'
              disabled={state.technology.chip.chipData.length}
            />
            <TagsView
              inputName='K3'
              state={keywordState}
              deleteChip={deleteKeywordChip}
              type='keywords'
            />
          </FormControl>
          {/* T1 */}
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>What products customers co-use with your product/service?</p>
            </div>
            <InputField
              type='text'
              id='technology'
              name='T1'
              onChange={handleTechnologyChange}
              onKeyPress={addTechnologyChip}
              margin='none'
              className='col-xs-12 col-lg-12'
              fullWidth
              value={technologyState.T1.value}
              placeholder='Slack, Gong, Pipedrive'
              disabled={
                state.keywords.chip.chipData.length ||
                technologyState.T2.value.length ||
                technologyState.T2.arr.length
              }
            />
            <TagsView
              inputName='T1'
              type='technology'
              state={technologyState}
              deleteChip={deleteTechnologyChip}
            />
          </FormControl>
          {/* T2 */}
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p> What competitive products customers evaluate you with?</p>
            </div>
            <InputField
              type='text'
              id='technology'
              name='T2'
              onChange={handleTechnologyChange}
              onKeyPress={addTechnologyChip}
              margin='none'
              className='col-xs-12 col-lg-12'
              fullWidth
              value={technologyState.T2.value}
              placeholder='Zendesk, Freshdesk'
              disabled={
                state.keywords.chip.chipData.length ||
                technologyState.T1.value.length ||
                technologyState.T1.arr.length
              }
            />
            <TagsView
              inputName='T2'
              type='technology'
              state={technologyState}
              deleteChip={deleteTechnologyChip}
            />
          </FormControl>
        </div>
        {/*  */}
        <div className='form_cards'>
          <h5 className='headings'>FILTERS</h5>
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>Company Size</p>
            </div>
            <NativeSelect
              value={state.companySize.value}
              onChange={handleOnSelectionChange}
              disabled={!restFormEnable || !sizeEnable}
              name='companySize'
              inputProps={{
                id: 'companySize',
              }}
              size='small'
              fullWidth
              input={<InputField />}
            >
              <option key={0} value=''></option>
              {companySize.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>Industry</p>
            </div>
            <NativeSelect
              value={state.industry.value}
              onChange={handleOnSelectionChange}
              disabled={!restFormEnable || !sizeEnable}
              name='industry'
              inputProps={{
                id: 'industry',
              }}
              size='small'
              fullWidth
              input={<InputField />}
            >
              <option key={0} value=''></option>
              {industry.map(option => (
                <option key={option.Code} value={option.Description}>
                  {option.Description}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>City</p>
            </div>
            <NativeSelect
              value={state.city.value}
              onChange={handleOnSelectionChange}
              disabled={!restFormEnable || !sizeEnable}
              name='city'
              inputProps={{
                id: 'city',
              }}
              size='small'
              fullWidth
              input={<InputField />}
            >
              <option key={0} value=''></option>
              {cityArray.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl className={classes.formControl}>
            <div className='subHeadings'>
              <span style={{ marginRight: '5px' }}>
                <img src={require('assets/images/thunder-icon.png')} alt='thunder-icon' />
              </span>
              <p>Country</p>
            </div>
            <NativeSelect
              value={state.country.value}
              onChange={handleOnSelectionChange}
              disabled={!restFormEnable || !sizeEnable}
              name='country'
              inputProps={{
                id: 'country',
              }}
              size='small'
              fullWidth
              input={<InputField />}
            >
              <option key={0} value=''></option>
              {countryArray.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
      </div>

      <div className={classes.bottomBox}>
        <IconButton
          variant='contained'
          size='small'
          color='primary'
          onClick={handleSearchSubmit}
          icon={require('../../assets/images/logo.png')}
          className={classes.searchBtn}
        >
          <span>Search</span>
        </IconButton>
      </div>
    </div>
  );
};

export default TagsInput;
