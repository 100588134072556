import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '../../../components/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import {
  hideMessage,
  showAuthMessage,
  showAuthLoader,
  confirm_invitation,
  userSignOut,
  userSignUp,
} from 'actions/Auth';
import { isEmpty } from '../../../util';

import AlertUi from 'containers/Msg-UI/AlertUi';
import SuccessUi from 'containers/Msg-UI/SuccessUi';
import '../../authStylings.css';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    minHeight: '80vh',
    backgroundColor: '#ffff',
    margin: '50px',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(196, 196, 196, 0.12)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '60px 60px 40px',
  },
  right: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#fff',
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  switchPaper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    margin: 'auto',
  },
}));

const ReviewInvitation = props => {
  const initialState = {
    fn: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid first name is required. Must be at least 3 characters',
    },
    ln: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid last name is required. Must be at least 3 characters',
    },
    // "cn": { value: "", min: 3, required: true, message: "Valid company name is required. Must be at least 3 characters" },
    password: {
      value: '',
      min: 6,
      required: true,
      message: 'Valid password is required. Must be at least 6 characters',
    },
    confirmPassword: {
      value: '',
      min: 6,
      required: true,
      message: 'Password did not match',
    },
  };

  const {
    loader,
    alertMessage,
    showMessage,
    infoMessage,
    invitationDetails,
    authUser,
    invitationSuccesss,
  } = useSelector(({ auth }) => auth);
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const classes = useStyles();
  const [loggedOutUser, setloggedOutUser] = useState(false);
  const [sentForLoginOrRegisterUser, setSentForLoginOrRegisterUser] = useState(false);

  const [newUser, setnewUser] = useState(false);

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      default:
        throw new Error();
    }
  }

  const handleOnChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const handleJoin = () => {
    let inputObj = validate();
    if (inputObj != false) {
      console.log('form ' + JSON.stringify(inputObj));
      //add more
      inputObj.invitationId = invitationDetails.invid;
      invitationDetails.fn = state.fn.value;
      invitationDetails.ln = state.ln.value;
      // invitationDetails.cn = state.cn.value;
      invitationDetails.password = state.password.value;
      sessionStorage.setItem('inviteDetails', JSON.stringify(invitationDetails));

      if (invitationDetails.type == 'INVITE_NEW') {
        //but if user already existed
        if (localStorage['user_id']) {
          //logout
          setloggedOutUser(true);
          storeDispatch(userSignOut());
        } else registerUser();
      }

      /* else if (invitationDetails.type == "INVITE_EXISTING") {
                if (localStorage["userDetails"]
                    && JSON.parse(localStorage["userDetails"]).eml == invitationDetails.eml) //same user so continue
                {
                    storeDispatch(confirm_invitation(inputObj));
                } else if (localStorage["userDetails"] &&
                    JSON.parse(localStorage["userDetails"]).eml != invitationDetails.eml) { //logout

                } else if (!localStorage["userDetails"]) { //sign in

                }
            } */
    }
  };

  const validate = () => {
    let inputAPI = {};
    for (var element in state) {
      if (validInput(state[element], element)) {
        storeDispatch(showAuthMessage(state[element].message));
        return false;
      }
      if (state[element].value != '') inputAPI[element] = state[element].value;
    }

    return inputAPI;
  };

  const validInput = (input, key) => {
    if (key === 'confirmPassword' && state['password'].value !== input.value) return true;
    return input.required
      ? input.value == '' ||
          (input.min ? input.value.length < input.min : false) ||
          (input.hasOwnProperty('regex') ? !input.value.match(input.regex) : false)
      : input.value != '' &&
          (input.min ? input.value.length < input.min : false) &&
          (input.hasOwnProperty('regex') ? !input.value.match(input.regex) : false);
  };

  const registerUser = () => {
    let registerPayload = {};
    registerPayload.email = invitationDetails.eml;
    registerPayload.password = state.password.value;
    registerPayload.fn = state.fn.value;
    registerPayload.ln = state.ln.value;
    registerPayload.rol = invitationDetails.rol;
    registerPayload.cn = invitationDetails.cn;
    registerPayload.cid = invitationDetails.cid;
    registerPayload.emailCheck = false;

    storeDispatch(showAuthLoader());
    setSentForLoginOrRegisterUser(true);
    storeDispatch(userSignUp(registerPayload));
  };

  // useEffect(() => {
  //     if (showMessage) {
  //         // setTimeout(() => {
  //             storeDispatch(hideMessage());
  //         // }, 1000);
  //     }
  // }, [showMessage]);

  useEffect(() => {
    if (loggedOutUser && authUser == null)
      //logged out
      registerUser();
    else if (sentForLoginOrRegisterUser && authUser) {
      console.log('invitationDetails', JSON.stringify(invitationDetails));
      storeDispatch(confirm_invitation({ invitationId: invitationDetails.invid }));
    }
  }, [authUser]);

  useEffect(() => {
    if (invitationSuccesss) {
      console.log('invitationSuccesss');
      // props.history.push("/app/home");
    }
  }, [invitationSuccesss]);

  useEffect(() => {
    if (!isEmpty(invitationDetails)) {
      dispatch({
        type: 'addInput',
        payload: { name: 'fn', value: invitationDetails.fn },
      });
      dispatch({
        type: 'addInput',
        payload: { name: 'ln', value: invitationDetails.ln },
      });
      invitationDetails.type == 'INVITE_NEW' ? setnewUser(true) : setnewUser(false);
    }
  }, [invitationDetails]);

  useEffect(() => {
    if (localStorage.getItem('user_id')) {
      localStorage.removeItem('user_id');
    }
  }, []);

  return (
    <div className='auth-card'>
      <div className={classes.main}>
        <div className={classes.left + ' animated slideInUpTiny animation-duration-3'}>
          <div style={{ marginBottom: '100px' }}>
            <h1
              style={{
                color: '#222F3C',
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              Review invitation
            </h1>
            <p style={{ fontSize: '14px' }}>Welcome. Please make sure you review details</p>
          </div>
          <div style={{ marginTop: '-200px' }}>
            {showMessage && alertMessage !== '' && (
              <AlertUi variant='error'>{alertMessage}</AlertUi>
            )}
            {invitationSuccesss ? (
              <SuccessUi
                successMsg='Account created successfully'
                path='/app/home'
                initMsg='Go to'
                btnTxt='Home'
              />
            ) : (
              <form>
                <fieldset>
                  <TextField
                    id='firstname'
                    label={<IntlMessages id='signUp.firstName' />}
                    type='text'
                    name='fn'
                    value={state.fn.value}
                    margin='normal'
                    onChange={handleOnChange}
                    className='col-xs-12'
                    fullWidth
                  />
                  <TextField
                    id='lastname'
                    label={<IntlMessages id='signUp.lastName' />}
                    name='ln'
                    value={state.ln.value}
                    margin='normal'
                    onChange={handleOnChange}
                    className='col-xs-12'
                    fullWidth
                  />
                  {/* <TextField
                                                            id="companyname"
                  label={<IntlMessages id="signUp.companyName" />}
                                                            name="cn"
                                                            margin="normal"
                                                            onChange={handleOnChange}
                                                            className="col-xs-12"
                                                            fullWidth
                                                                    disabled={true}
                                                        /> */}
                  {newUser && (
                    <>
                      <TextField
                        type='password'
                        name='password'
                        onChange={handleOnChange}
                        label={<IntlMessages id='appModule.password' />}
                        margin='normal'
                        className='col-xs-12'
                        fullWidth
                      />
                      <TextField
                        type='password'
                        name='confirmPassword'
                        onChange={handleOnChange}
                        label={<IntlMessages id='appModule.confirmPassword' />}
                        margin='normal'
                        className='col-xs-12'
                        fullWidth
                      />
                    </>
                  )}
                  <div className='mt-3 text-right'>
                    <IconButton variant='contained' onClick={handleJoin} color='primary'>
                      <IntlMessages id='appModule.done' />
                    </IconButton>
                  </div>
                </fieldset>
              </form>
            )}
          </div>
          <div></div>
        </div>
        <div className={classes.right}>
          {/* <img width='70%' src={require('assets/images/conveyer-belt.png')} alt='conveyer' /> */}
        </div>
        {/* Backdrop */}
        <Backdrop className={classes.backdrop} open={loader}>
          <div className='fixContainer'>
            <div className='loader-view'>
              <CircularProgress />
            </div>
          </div>
        </Backdrop>
      </div>
    </div>
  );
};

export default ReviewInvitation;
