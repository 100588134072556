import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthToken } from 'sagas/Auth';
import { makeStyles } from '@material-ui/core/styles';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Paper,
  Switch,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { crmBaseUrl, crmCompanyMainURL, crmUserMainURL } from 'util/urls';
import CrmMapForm from 'components/CrmMapForm';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(3, 3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    gap: '30px',
  },
  paper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  stepperWrap: {
    borderBottom: '1px solid #ddd',
    padding: '10px 0',
  },
  connectWait: {
    textAlign: 'center',
    height: '100%',
    display: 'grid',
    placeContent: 'center',
    padding: '35px',
    '& h4': {
      color: '#646A71',
    },
  },
  connectStep: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr',
    padding: '35px',
  },
  cardWrap: {
    marginTop: '40px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    gap: '20px',
  },
  card: {
    '& .innerCard': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& p': { fontSize: '14px', fontWeight: '400', fontStyle: 'normal' },
      '& .card-icon': {
        width: '100px',
        height: '68px',
        marginBottom: '40px',
        '& img': {
          // height: '100%',
          width: '100%',
        },
      },
    },
    backgroundColor: '#ffff',
    padding: theme.spacing(2),
    width: '253px',
    height: '389px',
    borderRadius: '10px',
    boxShadow: ' 0px 7px 22px 0px #00000026',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '& h4': {
      fontWeight: '700',
      fontStyle: 'normal',
      fontSize: '20px',
      lineheight: '24.2px',
      color: '#717171',
    },
  },

  buttons: {
    background: '#fff',
    '&:hover': {
      background: '#182EEC',
      transition: '0.3s',
      color: '#fff',
    },
    '&:disabled': {
      background: '#717171',
      cursor: 'not-allowed',
      color: '#fff',
      border: 'none',
    },
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '19px',
    lineHeight: '23.2px',
    color: '#182EEC',
    width: '182.4px',
    height: '54px',
    borderRadius: '4px',
    padding: '15px 60px 15px 60px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '15px 60px',
    border: '1px solid#182EEC',
    cursor: 'pointer',
    textAlign: 'center',
    margin: '15px 0px',
    outline: 'none',
  },
  configureForm: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
    gap: '50px',
  },
  tabs: {
    textAlign: 'left',
  },
  tabBtn: {
    color: 'blue',
    outline: 'none',
    background: '#fff',
    borderRadius: '0px',
    padding: '10px 20px',
    fontSize: '1rem',
    fontWeight: 'bold',
    transition: 'all 100ms ease-in-out',
    border: '1px solid blue',
    '&:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderRight: 'none',
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      borderLeft: 'none',
    },
  },
  active: {
    outline: 'none',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    // borderRadius: "5px",
    border: '1px solid blue',
    padding: '10px 20px',
    fontSize: '1rem',
    fontWeight: 'bold',
    transform: 'scale(1)',
    transition: 'all 100ms ease-in-out',
    '&:first-child': {
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderRight: 'none',
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      borderLeft: 'none',
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px 40px',
  },
}));

function getSteps() {
  return ['CONNECT', 'CONFIGURE', 'MAPPING'];
}

const REQUIRED_SYS_FIELDS = [
  'FIRST_NAME',
  'LAST_NAME',
  'WEBSITE',
  'COMPANY_NAME',
  'COMPANY_DOMAIN',
];

const crmAPI = async (token, payload, url, requestMethod = 'POST') => {
  let headers = new Headers({
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
  });
  let options = {
    method: requestMethod,
    headers: headers,
    body: JSON.stringify(payload),
  };
  // remove option.body, if requestMethod===GET
  requestMethod === 'GET' && delete options.body;
  return await fetch(url, options)
    .then(async response => {
      return await response.json();
    })
    .catch(err => {
      console.log(err);
      return err;
    });
};

let windowObjectRef = null;
let previousUrl = null;

const salesforceURL = `https://login.salesforce.com/services/oauth2/authorize?client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SALESFORCE_REDIRECT_URL}&response_type=code`;

const hubspotURL = `https://app-eu1.hubspot.com/oauth/authorize?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.objects.companies.read`;

// const zohoURL = `https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.ALL,ZohoCRM.settings.ALL&client_id=${process.env.REACT_APP_ZOHO_CLIENT_ID}&response_type=code&access_type=offline&redirect_uri=${process.env.REACT_APP_ZOHO_REDIRECT_URL}`;

const pipedriveURL = `https://oauth.pipedrive.com/oauth/authorize?client_id=${process.env.REACT_APP_PIPEDRIVE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_PIPEDRIVE_REDIRECT_URL}`;

const getCrmNameFromUrl = url => {
  if (url.indexOf('salesforce') !== -1) {
    return 'salesforce';
  } else if (url.indexOf('hubspot') !== -1) {
    return 'hubspot';
  } else if (url.indexOf('zoho') !== -1) {
    return 'zoho';
  } else if (url.indexOf('pipedrive') !== -1) return 'pipedrive';
  return '';
};

const crmFormTabs = {
  salesforce: ['leads', 'contacts', 'accounts'],
  pipedrive: ['persons', 'organizations'],
  hubspot: ['contacts', 'companies'],
};

const Integration = () => {
  const { userDetails, companyDetails } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isNewUser, setIsNewUser] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [crmInitialPeopleMap, setCrmInitialPeopleMap] = useState(null);
  const [crmInitialCompanyMap, setCrmInitialCompanyMap] = useState(null);
  const [crmName, setCrmName] = useState('');
  const [peopleCrmFields, setPeopleCrmFields] = useState([]);
  const [companyCrmFields, setCompanyCrmFields] = useState([]);
  const [sysFields, setSysFields] = useState({ peopleFields: [], companyFields: [] });
  const [isLeadDb, setIsLeadDb] = useState(true);
  const [isAutoAccAdd, setIsAutoAccAdd] = useState(true);
  const [activeMapForm, setActiveMapForm] = useState(0);
  const [connectWaitMessage, setConnectWaitMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [saveDataError, setErrorSavaData] = useState(false);
  const steps = getSteps();

  useEffect(() => {
    checkNewUser();
  }, []);

  const checkNewUser = async () => {
    try {
      const token = await getAuthToken();
      const response = await crmAPI(
        token,
        undefined,
        process.env.REACT_APP_CRM_BASE_URL + crmUserMainURL + 'checkUser',
        'GET'
      );
      if (!response.error) {
        // user has already setup mapping
        setIsNewUser(response);
        setCrmName(response.crmNm);
        setIsLeadDb(response.isLeadDb);
        setIsAutoAccAdd(response.autoAccAdd);
        setCrmInitialPeopleMap(response.peopleMap.map((f, idx) => ({ ...f, id: idx })));
        setCrmInitialCompanyMap(response.companyMap.map((f, idx) => ({ ...f, id: idx })));
      } else {
        setIsNewUser('');
      }
    } catch (err) {
      console.log('error in checking whether user exists or not');
    } finally {
      setInitialLoader(false);
    }
  };
  const disconnectCrm = async () => {
    try {
      setInitialLoader(true);
      const token = await getAuthToken();
      const response = await crmAPI(
        token,
        { uid: userDetails.uid, cid: companyDetails.cid },
        process.env.REACT_APP_CRM_BASE_URL + crmUserMainURL + 'disconnect',
        'POST'
      );
      if (response.Error) {
        await checkNewUser();
      } else {
        await checkNewUser();
      }
    } catch (err) {
      console.log('error in disconnect crm');
    }
  };

  const handleNext = async () => {
    if (activeStep === 2) {
      !isNewUser ? initiateRecords() : saveData();
    } else setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const resetState = () => {
    setActiveStep(0);
    setTokens(null);
    setCrmInitialPeopleMap(null);
    setCrmInitialCompanyMap(null);
    setCrmName('');
    setPeopleCrmFields([]);
    setCompanyCrmFields([]);
  };

  const receiveMessage = e => {
    if (e.origin !== process.env.REACT_APP_REDIRECT_URL) {
      if (!(process.env.NODE_ENV === 'development' && e.origin === 'http://localhost:3000')) {
        return;
      }
    }
    const { data } = e;
    // if we trust the sender and the source is our popup
    if (data.source === 'glowRadiusRedirect') {
      if (data.payload) {
        onSuccess(data.payload);
        setConnectWaitMessage(true);
      } else {
        setConnectWaitMessage(false);
      }
      // remove any existing listeners
      window.removeEventListener('message', receiveMessage);
    }
  };

  const showInfoOrAlertMessage = (msg, alert = false) => {
    alert ? NotificationManager.error(msg) : NotificationManager.success(msg);
  };

  const onSuccess = async code => {
    const token = await getAuthToken();
    const crmName = getCrmNameFromUrl(previousUrl);
    const payload = {
      crmNm: crmName,
      authCode: code,
    };
    !isNewUser && setCrmName(crmName);
    try {
      const response = await crmAPI(
        token,
        payload,
        process.env.REACT_APP_CRM_BASE_URL + 'crmusermain/getTokens'
      ).catch(err => {
        console.log('error while exchanging tokens', err);
        setConnectWaitMessage(false);
      });
      if (response.error) {
        console.log('error while exchanging tokens', response.err);
        setConnectWaitMessage(false);
      } else {
        /**
         * oauth success scenario, now get crm fields
         * & if admin, get default mapping (json file)
         * else get crmcompanymain map (from firebase record)
         * increase step by 1
         */
        setTokens(response);
        const { rol, uid } = userDetails;
        const { cid } = companyDetails;

        // based on rol=user||admin prefill form details
        let defaultFields,
          url = '';
        if (!isNewUser) {
          if (rol && rol.toLowerCase() === 'admin') {
            url = crmBaseUrl + crmUserMainURL + 'getDefaultMapForAdmin';
            defaultFields = await crmAPI(token, { crmNm: crmName }, url);
          } else if (rol && rol.toLowerCase() === 'user') {
            defaultFields = await crmAPI(
              token,
              { cid },
              crmBaseUrl + crmCompanyMainURL + 'getMapGlobal'
            );
          } else {
            console.log('something went wrong!');
            showInfoOrAlertMessage('Sorry, something went wrong!', true);
            setConnectWaitMessage(false);
            return;
          }
          if (defaultFields.error) {
            let msg =
              defaultFields.error.code === 'USER_DOESNOT_EXIST' &&
              'Admin has not set up any crm yet!';
            showInfoOrAlertMessage(msg || 'Sorry, something went wrong!', true);
            setConnectWaitMessage(false);
            return;
          }

          setCrmInitialPeopleMap(defaultFields.peopleMap.map((f, idx) => ({ ...f, id: idx })));
          setCrmInitialCompanyMap(defaultFields.companyMap.map((f, idx) => ({ ...f, id: idx })));
        } else {
          // if not new user, then save new tokens
          const updateTokens = await crmAPI(
            token,
            {
              accsTk: response.accessToken,
              refrTk: response.refreshToken,
              instUrl: response.instanceUrl || '',
            },
            crmBaseUrl + crmUserMainURL + 'setTokens'
          );

          if (updateTokens.error) {
            console.log('error while updating tokens for user');
            let errMsg = updateTokens.error?.message || 'Sorry, something went wrong!';
            showInfoOrAlertMessage(errMsg, true);
            return;
          }
          // defaultFields will be companymap & peopleMap stored in isNewUser
          defaultFields = { companyMap: isNewUser.companyMap, peopleMap: isNewUser.peopleMap };
        }

        await getCrmFields(response, crmName, defaultFields);
        await getSystemFields(defaultFields, crmName);

        setActiveStep(prev => prev + 1);
        setConnectWaitMessage(false);
      }
    } catch (err) {
      console.log('error while setting up', err);
    }
  };

  const openWindowPopup = url => {
    let height = window.innerHeight;
    let left = (window.screen.width - 550) / 2;
    let top = (window.screen.height - height) / 4;
    let windowFeatures = `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=${height},left=${left},top=${top}`;
    if (windowObjectRef === null || windowObjectRef.closed) {
      windowObjectRef = window.open(url, `GlowRadius - OAuth Redirect`, windowFeatures);
    } else if (previousUrl !== url) {
      windowObjectRef = window.open(url, `GlowRadius - OAuth Redirect`, windowFeatures);
      windowObjectRef.focus();
    } else {
      windowObjectRef.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener('message', receiveMessage, false);
    // assign the previous URL
    previousUrl = url;
  };

  const connectToCRM = url => {
    openWindowPopup(url);
  };

  const handleOnChange = (val, type, idx, changeType, destination) => {
    let formMap = type === 'peopleMap' ? crmInitialPeopleMap : crmInitialCompanyMap;
    let field = formMap[idx];
    let prev = '';
    let updateSysFields = false,
      updateCrmFields = false;
    if (!field) {
      return;
    }
    if (changeType === 'sysFieldChange') {
      prev = field.sysFieldName;
      field.sysFieldName = val;
      updateSysFields = true;
    } else if (changeType === 'crmFieldNameChange') {
      prev = field.crmFieldName;
      let fields = type === 'peopleMap' ? peopleCrmFields : companyCrmFields;
      let selectedField = fields.find(
        f => f.name === val && (crmName === 'salesforce' ? f.destination == destination : true)
      );
      if (!selectedField) {
        return;
      }
      field.crmFieldName = selectedField.name;
      field.crmFieldType = selectedField.type;
      field.crmFieldLength = selectedField.length;
      updateCrmFields = true;
    } else if (changeType === 'crmFieldUpdateChange') {
      field.whenUpdate = val;
    } else if (changeType === 'crmFieldBooleanChange') {
      field.cOrgWise = !field.cOrgWise;
    }

    let updatedMap = formMap.map(f => {
      if (f.id === idx) {
        return field;
      }
      return f;
    });

    if (type === 'peopleMap') {
      setCrmInitialPeopleMap(updatedMap);
    } else {
      setCrmInitialCompanyMap(updatedMap);
    }
    if (updateSysFields) {
      let fields = type === 'peopleMap' ? sysFields.peopleFields : sysFields.companyFields;
      let updatedFields = fields.map(f => {
        if (f.name === prev && (crmName === 'salesforce' ? f.destination == destination : true)) {
          return { ...f, selected: false };
        }
        if (f.name === val && (crmName === 'salesforce' ? f.destination == destination : true)) {
          return { ...f, selected: true };
        }
        return f;
      });
      let updatedSysFields = { ...sysFields };
      if (type === 'peopleMap') {
        updatedSysFields.peopleFields = updatedFields;
      } else {
        updatedSysFields.companyFields = updatedFields;
      }
      setSysFields(updatedSysFields);
    }

    if (updateCrmFields) {
      let fields = type === 'peopleMap' ? peopleCrmFields : companyCrmFields;
      let updatedFields = fields.map(f => {
        if (f.name === prev && (crmName === 'salesforce' ? f.destination == destination : true)) {
          return { ...f, selected: false };
        } else if (
          f.name === val &&
          (crmName === 'salesforce' ? f.destination == destination : true)
        ) {
          return { ...f, selected: true };
        }
        return f;
      });
      if (type === 'peopleMap') {
        setPeopleCrmFields(updatedFields);
      } else {
        setCompanyCrmFields(updatedFields);
      }
    }
  };

  const getCrmFields = async (crmTokens, crmName, defaultFields) => {
    const response = crmTokens;
    const token = await getAuthToken();
    // make a request to get crm fields
    let crmFields = await crmAPI(
      token,
      {
        accsTk: response.accessToken,
        refrTk: response.refreshToken,
        instUrl: response.instanceUrl || '',
        crmNm: crmName,
      },
      crmBaseUrl + crmUserMainURL + 'getCrmFields'
    );

    if (crmFields.error) {
      let errMsg = crmFields.error?.message || 'Sorry, something went wrong!';
      showInfoOrAlertMessage(errMsg, true);
      setConnectWaitMessage(false);
      throw crmFields.error;
    }

    crmFields.peopleFields = crmFields.peopleFields.map(field => {
      if (
        defaultFields.peopleMap.find(f =>
          f.destination !== undefined
            ? f.destination === field.destination && f.crmFieldName === field.name
            : f.crmFieldName === field.name
        )
      ) {
        return { ...field, selected: true };
      }
      return { ...field, selected: false };
    });

    crmFields.companyFields = crmFields.companyFields.map(field => {
      if (defaultFields.companyMap.find(f => f.crmFieldName === field.name)) {
        return { ...field, selected: true };
      }
      return { ...field, selected: false };
    });

    setPeopleCrmFields(crmFields.peopleFields);
    setCompanyCrmFields(crmFields.companyFields);
  };

  const getSystemFields = async (defaultFields, crmName) => {
    const token = await getAuthToken();
    // make a request to get system fields
    try {
      let systemFields = await crmAPI(
        token,
        {},
        crmBaseUrl + crmUserMainURL + 'getSystemFields',
        'GET'
      );
      if (systemFields.error) {
        let errMsg = systemFields.error?.message || 'Sorry, something went wrong!';
        showInfoOrAlertMessage(errMsg, true);
        setConnectWaitMessage(false);
        throw systemFields.error;
      }
      systemFields.peopleFields = systemFields.peopleFields.map(fName => {
        if (defaultFields.peopleMap.find(f => f.sysFieldName === fName)) {
          return {
            name: fName,
            selected: true,
          };
        }
        return {
          name: fName,
          selected: false,
        };
      });

      systemFields.companyFields = systemFields.companyFields.map(fName => {
        if (defaultFields.companyMap.find(f => f.sysFieldName === fName)) {
          return {
            name: fName,
            selected: true,
          };
        }
        return {
          name: fName,
          selected: false,
        };
      });

      if (crmName === 'salesforce') {
        const leadArr = systemFields.peopleFields.map(el => ({ ...el, destination: 'lead' }));
        const contactArr = systemFields.peopleFields.map(el => ({ ...el, destination: 'contact' }));
        systemFields.peopleFields = [...leadArr, ...contactArr];
      }
      setSysFields(systemFields);
    } catch (err) {
      console.log('err in getting system fields', err);
      showInfoOrAlertMessage('Sorry, something went wrong!', true);
      setConnectWaitMessage(false);
      throw err;
    }
  };

  const addField = type => {
    if (type === 'peopleMap') {
      let newSysField = {},
        crmFieldName,
        crmFieldType,
        crmFieldLength,
        crmRequired;
      if (crmName === 'salesforce') {
        // if activeMapForm === 0, then Lead
        let field = {};
        if (activeMapForm === 0) {
          field = peopleCrmFields.filter(f => f.destination === 'lead').find(f => !f.selected);
        } else if (activeMapForm === 1) {
          field = peopleCrmFields.filter(f => f.destination === 'contact').find(f => !f.selected);
        }
        if (!field) {
          console.log('all crm fields have been selected!');
          return;
        }
        crmFieldName = field.name;
        crmFieldType = field.type;
        crmFieldLength = field.length;
        crmRequired = field.required;
      }
      newSysField = sysFields.peopleFields.find(f => !f.selected);
      if (!newSysField) {
        console.log('a;; system fields are used up!');
        return;
      }

      let newField = {
        sysFieldName: newSysField.name,
        crmFieldName,
        crmFieldType,
        crmFieldLength,
        required: crmRequired,
        cOrgWise: true,
        whenUpdate: 'ALWAYS_UPDATE',
        id: crmInitialPeopleMap.length,
      };
      if (crmName === 'salesforce') {
        newField.destination = activeMapForm === 0 ? 'lead' : 'contact';
      }
      setCrmInitialPeopleMap([...crmInitialPeopleMap, newField]);
      // update peopleCrmFields
      setPeopleCrmFields(
        peopleCrmFields.map(f => {
          if (f.name === crmFieldName) {
            return { ...f, selected: true };
          }
          return f;
        })
      );
      // update systemFields
      setSysFields({
        ...sysFields,
        peopleFields: sysFields.peopleFields.map(f => {
          if (f.name === newSysField.name) {
            return { ...f, selected: true };
          }
          return f;
        }),
      });
    } else {
      let newSysField = {},
        newCrmField = {};
      newCrmField = companyCrmFields.find(f => !f.selected);
      if (!newCrmField) {
        console.log('all crm field are used up!');
        return;
      }
      newSysField = sysFields.companyFields.find(f => !f.selected);
      if (!newSysField) {
        console.log('all system fiels have been used up!');
        return;
      }
      let newField = {
        id: crmInitialCompanyMap.length,
        sysFieldName: newSysField.name,
        crmFieldName: newCrmField.name,
        crmFieldType: newCrmField.type,
        crmFieldLength: newCrmField.length,
        cOrgWise: true,
        whenUpdate: 'ALWAYS_UPDATE',
      };
      setCrmInitialCompanyMap([...crmInitialCompanyMap, newField]);
      // update peopleCrmFields
      setCompanyCrmFields(
        companyCrmFields.map(f => {
          if (f.name === newSysField.crmFieldName) {
            return { ...f, selected: true };
          }
          return f;
        })
      );
      // update systemFields
      setSysFields({
        ...sysFields,
        companyFields: sysFields.companyFields.map(f => {
          if (f.name === newSysField.name) {
            return { ...f, selected: true };
          }
          return f;
        }),
      });
    }
  };

  const deleteField = id => {
    if (activeMapForm === 0) {
      const updatePeopleInitCrmMap = crmInitialPeopleMap.filter(i => i.id !== id);
      setCrmInitialPeopleMap(updatePeopleInitCrmMap);
    } else if (activeMapForm === 1) {
      const maps = crmName === 'salesforce' ? crmInitialPeopleMap : crmInitialCompanyMap;
      const updateMaps = maps.filter(i => i.id !== id);
      if (crmName === 'salesforce') {
        setCrmInitialPeopleMap(updateMaps);
      } else {
        setCrmInitialCompanyMap(updateMaps);
      }
    } else {
      const updateCompanyInitCrmMap = crmInitialCompanyMap.filter(i => i.id !== id);
      setCrmInitialCompanyMap(updateCompanyInitCrmMap);
    }
  };

  const getStepContent = step => {
    const crmTabs = crmFormTabs[crmName];
    switch (step) {
      case 0: // Connect to CRM step
        return (
          <>
            {connectWaitMessage ? (
              <div className={classes.connectWait}>
                <h4>
                  We are connecting... <br />
                  Please wait for 5 seconds you will be automatically be taken to the next step
                </h4>
              </div>
            ) : (
              <div className={classes.connectStep}>
                <h1 style={{ fontWeight: '600' }}>Connect your CRM</h1>
                <h4 style={{ color: '#646A71' }}>
                  Hook up your favorite CRM with GlowRadius and seamlessly transfer data
                </h4>
                <div className={classes.cardWrap}>
                  {/* Salesforce */}
                  <div className={classes.card}>
                    <div className='innerCard'>
                      <div className='card-icon'>
                        <img src='https://www.salesforce.com/content/dam/sfdc-docs/www/logos/logo-salesforce.svg' />
                      </div>
                      <h4>Salesforce</h4>
                      <button
                        className={classes.buttons}
                        onClick={() => connectToCRM(salesforceURL)}
                        disabled={isNewUser && isNewUser.crmNm !== 'salesforce'}
                      >
                        <span>Connect</span>
                      </button>
                      <h5>Connect Salesforce with GlowRadius</h5>
                      {isNewUser && isNewUser.crmNm === 'salesforce' && (
                        <button onClick={() => disconnectCrm()}>disconnect</button>
                      )}
                    </div>
                  </div>
                  {/* Hubspot */}
                  <div className={classes.card}>
                    <div className='innerCard'>
                      <div className='card-icon'>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/3/3f/HubSpot_Logo.svg' />
                      </div>
                      <h4>Hubspot</h4>
                      <button
                        className={classes.buttons}
                        onClick={() => connectToCRM(hubspotURL)}
                        disabled={isNewUser && isNewUser.crmNm !== 'hubspot'}
                      >
                        <span>Connect</span>
                      </button>
                      <h5>Connect Salesforce with GlowRadius</h5>
                      {isNewUser && isNewUser.crmNm === 'hubspot' && (
                        <button onClick={() => disconnectCrm()}>disconnect</button>
                      )}
                    </div>
                  </div>
                  {/* Pipedrive */}
                  <div className={classes.card}>
                    <div className='innerCard'>
                      <div className='card-icon'>
                        <img src='https://upload.wikimedia.org/wikipedia/commons/5/5d/Pipedrive_Logo.svg' />
                      </div>
                      <h4>Pipedrive</h4>
                      <button
                        className={classes.buttons}
                        onClick={() => connectToCRM(pipedriveURL)}
                        disabled={isNewUser && isNewUser.crmNm !== 'pipedrive'}
                      >
                        <span>Connect</span>
                      </button>
                      <h5>Connect Salesforce with Pipedrive</h5>
                      {isNewUser && isNewUser.crmNm === 'pipedrive' && (
                        <button onClick={() => disconnectCrm()}>disconnect</button>
                      )}
                    </div>
                  </div>
                  {/* Salesloft */}
                  <div className={classes.card}>
                    <div className='innerCard'>
                      <div className='card-icon'>
                        <img src='https://salesloft.com/wp-content/themes/salesloft-v2/images/svg/logos/salesloft-on-light.svg' />
                      </div>
                      <h4>Salesloft</h4>
                      <button className={classes.buttons} disabled onClick={() => {}}>
                        <span>Coming Soon!</span>
                      </button>
                      <h5>Connect Salesforce with Salesloft</h5>
                    </div>
                  </div>
                  {/* Outreach */}
                  <div className={classes.card}>
                    <div className='innerCard'>
                      <div className='card-icon'>
                        <img src='https://www.outreach.io/_resources/img/logo.min.svg' />
                      </div>
                      <h4>Outreach</h4>
                      <button className={classes.buttons} disabled onClick={() => {}}>
                        <span>Coming Soon!</span>
                      </button>
                      <h5>Connect Salesforce with Outreach</h5>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      case 1: // Configure extra options step
        return (
          <div className={classes.connectStep}>
            <h1 style={{ fontWeight: '600' }}>Configuration Settings</h1>
            <h4 style={{ color: '#646A71' }}>
              Config settings - other Sales software account improves your Glowradius experience
            </h4>
            <div className={classes.configureForm}>
              <FormControl component='fieldset' size='small' disabled={crmName !== 'salesforce'}>
                <FormLabel component='legend'>Saving Option</FormLabel>
                <RadioGroup
                  aria-label='savingOption'
                  name='savingOption'
                  value={isLeadDb}
                  onChange={() => setIsLeadDb(!isLeadDb)}
                  className='d-flex flex-row'
                  style={{ gap: '50px' }}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio color='primary' />}
                    label='Save as contact'
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio color='primary' />}
                    label='Save as lead'
                  />
                </RadioGroup>
              </FormControl>
              <FormControl>
                <FormLabel style={{ color: '#484848' }} component='legend'>
                  Auto account creation
                </FormLabel>
                <p
                  style={{
                    fontSize: '12px',
                    color: '#484848',
                    marginBottom: '0px',
                  }}
                >
                  When saving as a Contact, GlowRadius will try to use an existing account.
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    color: '#484848',
                    marginBottom: '5px',
                  }}
                >
                  If no existing account is found, GlowRadius can create it for you based on our
                  company database
                </p>
                <FormControlLabel
                  style={{ color: '#484848' }}
                  control={
                    <Checkbox
                      color='primary'
                      checked={isAutoAccAdd}
                      onChange={() => setIsAutoAccAdd(!isAutoAccAdd)}
                      name='isAutoAccAdd'
                    />
                  }
                  label='Yes, automatically create accounts when needed'
                />
              </FormControl>
              <FormControl component='fieldset' size='small'>
                <FormControlLabel
                  value=''
                  control={<Switch defaultChecked color='primary' />}
                  label='Allow my contact to Import without validation Email'
                  // labelPlacement="start"
                />
              </FormControl>
            </div>
          </div>
        );
      case 2: // Map Form Step
        return (
          <div className={classes.connectStep}>
            <h1 style={{ fontWeight: '600' }}>
              <span style={{ textTransform: 'capitalize' }}>{crmName}</span> Field Mapping
            </h1>
            <h4 style={{ color: '#646A71' }}>
              Connecting other Sales software account improves your Glowradius experience
            </h4>
            <div className={classes.tabs}>
              {crmTabs.map((tab, idx) => (
                <button
                  style={{ textTransform: 'capitalize' }}
                  key={idx}
                  onClick={() => setActiveMapForm(idx)}
                  className={(activeMapForm === idx && classes.active) || classes.tabBtn}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div style={{ display: 'flex' }}>
              {activeMapForm === 0 && (
                <CrmMapForm
                  crmName={crmName}
                  crmInitialMap={
                    crmName === 'salesforce'
                      ? crmInitialPeopleMap.filter(f => f.destination === 'lead')
                      : crmInitialPeopleMap
                  }
                  crmFields={
                    crmName === 'salesforce'
                      ? peopleCrmFields.filter(f => f.destination === 'lead')
                      : peopleCrmFields
                  }
                  handleOnChange={handleOnChange}
                  type='peopleMap'
                  disableCheckBox={userDetails.rol === 'User'}
                  addField={addField}
                  deleteField={deleteField}
                  systemFields={
                    crmName === 'salesforce'
                      ? {
                          peopleFields: sysFields.peopleFields.filter(
                            f => f.destination === 'lead'
                          ),
                        }
                      : sysFields
                  }
                />
              )}
              {activeMapForm === 1 && (
                <CrmMapForm
                  crmName={crmName}
                  crmInitialMap={
                    crmName === 'salesforce'
                      ? crmInitialPeopleMap.filter(f => f.destination === 'contact')
                      : crmInitialCompanyMap
                  }
                  crmFields={
                    crmName === 'salesforce'
                      ? peopleCrmFields.filter(f => f.destination === 'contact')
                      : companyCrmFields
                  }
                  handleOnChange={handleOnChange}
                  type={crmName === 'salesforce' ? 'peopleMap' : 'companyMap'}
                  disableCheckBox={userDetails.rol === 'User'}
                  addField={addField}
                  deleteField={deleteField}
                  systemFields={
                    crmName === 'salesforce'
                      ? {
                          peopleFields: sysFields.peopleFields.filter(
                            f => f.destination === 'contact'
                          ),
                        }
                      : sysFields
                  }
                />
              )}
              {crmName === 'salesforce' && activeMapForm === 2 && (
                <CrmMapForm
                  crmName={crmName}
                  crmInitialMap={crmInitialCompanyMap}
                  crmFields={companyCrmFields}
                  handleOnChange={handleOnChange}
                  type='companyMap'
                  disableCheckBox={userDetails.rol === 'User'}
                  addField={addField}
                  deleteField={deleteField}
                  systemFields={sysFields}
                />
              )}
            </div>
          </div>
        );
      case 3: // Finish, Success or Error screen
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '3rem',
            }}
          >
            <div>
              <div style={{ height: '200px', width: '200px', textAlign: 'center', margin: 'auto' }}>
                <img
                  src={require(!saveDataError
                    ? 'assets/images/check-circle.png'
                    : 'assets/images/alert-circle.png')}
                  alt='check-circle'
                />
              </div>
              <p
                style={{
                  fontSize: '32px',
                  lineHeight: '40px',
                  textAlign: 'center',
                  fontWeight: '800',
                }}
              >
                {!saveDataError
                  ? 'CRM Successfully Integrated'
                  : "We couldn't complete integration"}
              </p>
            </div>
          </div>
        );

      default:
        return 'Default text';
    }
  };

  const initiateRecords = async () => {
    const token = await getAuthToken();
    const { uid, rol } = userDetails;
    const { cid } = companyDetails;
    //TODO: validate maps
    // remove id key from map fields, as it is only for frontend
    let peopleMap = crmInitialPeopleMap.map(f => {
      delete f.id;
      return f;
    });
    let companyMap = crmInitialCompanyMap.map(f => {
      delete f.id;
      return f;
    });
    const userPayload = {
      uid,
      cid,
      peopleMap,
      companyMap,
      accsTk: tokens.accessToken,
      refrTk: tokens.refreshToken,
      instUrl: tokens.instanceUrl || '',
      isLeadDb,
      autoAccAdd: isAutoAccAdd,
      crmNm: crmName,
    };
    const companyPayload = {
      cid,
      crmNm: crmName,
      peopleMap: crmInitialPeopleMap,
      companyMap: crmInitialCompanyMap,
      isLeadDb,
      autoAccAdd: isAutoAccAdd,
    };
    let userMainRes, companyMainRes;
    try {
      setLoader(true);
      userMainRes = await crmAPI(
        token,
        userPayload,
        crmBaseUrl + crmUserMainURL + 'initiateCrmUserMain'
      );
      if (rol === 'Admin') {
        companyMainRes = await crmAPI(
          token,
          companyPayload,
          crmBaseUrl + crmCompanyMainURL + 'initiateCrmCompanyMain'
        );
      }
      if (userMainRes.error || (companyMainRes && companyMainRes.error)) {
        // show message & reset state
        return resetState();
      }
      setActiveStep(prev => prev + 1);
    } catch (err) {
      // show message
      // reset state
      resetState();
    } finally {
      setLoader(false);
    }
  };

  const saveData = async () => {
    const authToken = await getAuthToken();
    const { rol, uid } = userDetails;
    const { cid } = companyDetails;

    const peopleMap = crmInitialPeopleMap.map(m => {
      delete m.id;
      return m;
    });
    const companyMap = crmInitialCompanyMap.map(m => {
      delete m.id;
      return m;
    });
    try {
      setLoader(true);
      if (rol === 'Admin') {
        let payload = { cid };
        const peopleMapRes = await crmAPI(
          authToken,
          { ...payload, map: peopleMap, uid: uid },
          crmBaseUrl + crmCompanyMainURL + 'updatePeopleMapGlobal'
        );
        const companyMapRes = await crmAPI(
          authToken,
          { ...payload, map: companyMap },
          crmBaseUrl + crmCompanyMainURL + 'updateCompanyMapGlobal'
        );
        const updateConfig = await crmAPI(
          authToken,
          { isLeadDb: isLeadDb, autoAccAdd: isAutoAccAdd, uid: uid },
          crmBaseUrl + crmUserMainURL + 'updateConfig'
        );

        if (peopleMapRes.error || companyMapRes.error || !updateConfig?.success) {
          // show message
          // showInfoOrAlertMessage('Sorry something went wrong!', true);
          setErrorSavaData(true);
        }
      } else {
        let payload = { uid };
        const peopleMapRes = await crmAPI(
          authToken,
          { ...payload, map: crmInitialPeopleMap },
          crmBaseUrl + crmUserMainURL + 'setPeopleMapForSpecificUser'
        );
        const companyMapRes = await crmAPI(
          authToken,
          { ...payload, map: crmInitialCompanyMap },
          crmBaseUrl + crmUserMainURL + 'setCompanyMapForSpecificUser'
        );

        if (peopleMapRes.error || companyMapRes.error) {
          // show message
          // showInfoOrAlertMessage('Sorry something went wrong!', true);
          setErrorSavaData(true);
        }
      }
      setActiveStep(prev => prev + 1);
    } catch (err) {
      console.log('error while updating maps for user');
    } finally {
      setLoader(false);
    }
  };
  return (
    <div className='app-wrapper animated slideInUpTiny animation-duration-3'>
      <div className={classes.root}>
        <Paper className={classes.paper} style={{ display: initialLoader ? 'flex' : 'block' }}>
          {initialLoader ? (
            <p style={{ textAlign: 'center', fontSize: '18px' }}>Loading...</p>
          ) : (
            <>
              {/* Stepper */}
              <div className={classes.stepperWrap}>
                <Stepper
                  className={classes.paymentStepper}
                  activeStep={activeStep}
                  alternativeLabel
                >
                  {steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              {/* Step Content */}
              <div>{getStepContent(activeStep)}</div>
              {/* Buttons */}
              <div>
                {activeStep >= 1 && (
                  <div className={classes.stepBtns}>
                    {activeStep === 3 ? (
                      <div></div>
                    ) : (
                      <>
                        <Button
                          disabled={activeStep <= 1 || activeStep === 3}
                          onClick={handleBack}
                          className={classes.backButton}
                          color='gray'
                        >
                          <KeyboardArrowLeft /> Back
                        </Button>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={activeStep <= 2 && !loader ? handleNext : null}
                          disabled={activeStep > 2}
                        >
                          {loader ? (
                            <CircularProgress
                              color='inherit'
                              size={'0.875rem'}
                              style={{ margin: '5px 15px' }}
                            />
                          ) : activeStep === 2 ? (
                            'Save'
                          ) : (
                            'Next'
                          )}
                          {loader ? '' : <KeyboardArrowRight />}
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </Paper>
        {/* Notifications? */}
        <NotificationContainer />
      </div>
    </div>
  );
};

export default Integration;
