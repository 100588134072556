import {
  SHOW_JOBS_MESSAGE,
  SHOW_JOBS_INFO_MESSAGE,
  HIDE_JOBS_MESSAGE,
  STOP_JOB_LOADER,
  GET_JOBS,
  GET_JOBS_SUCCESS,
  GET_JOB_DETAILS_EMPTY,
  GET_JOB_DETAILS_SUCCESS,

  //snp
  CLEAR_SNP_ALL_DETAILS,
  SAVE_SNP_SAVE_JOBID,
  SNC_SUBMIT,
  GET_SNP_JOBS_PUBSUB,
  GET_SNP_T1_JOBS_PUBSUB_SUCCESS,
  GET_SNP_T2_JOBS_PUBSUB_SUCCESS,
  GET_SNP_T1_JOBS_PUBSUB_FAILED,
  GET_SNP_T2_JOBS_PUBSUB_FAILED,
  GET_SNP_JOB_DETAILS_SUCCESS,
  GET_SNP_JOBS_PUBSUB_ENRICH_1_SUCCESS,
  GET_SNP_JOBS_PUBSUB_ENRICH_2_SUCCESS,
  SET_ENRICH_PEOPLE_CONTACTS_LIST,
  SET_ENRICH_PEOPLE_CONTACTS_LIST_ITEM,
  SET_ENRICH_COMPANY_CONTACTS_LIST,

  //snc
  CLEAR_SNC_ALL_DETAILS,
  SAVE_SNC_PLAYBOOKINFO,
  SAVE_SNC_SAVE_JOBID,
  SALES_NAV_USER_SCRAPE,
  SALES_NAV_COMPANY_SCRAPE,
  SUBMIT_PEOPLE_JOBS_SUCCESS,
  SET_USERINFO_CHECKED_UPDATED_LIST_RESULT,
  SET_COMPANYINFO_CHECKED_UPDATED_LIST_RESULT,
  SUBMIT_COMPANY_JOBS_SUCCESS,
  SNP_SUBMIT,
  GET_SNC_JOBS_PUBSUB,
  GET_SNC_T1_JOBS_PUBSUB_SUCCESS,
  GET_SNC_T2_JOBS_PUBSUB_SUCCESS,
  GET_SNC_T1_JOBS_PUBSUB_FAILED,
  GET_SNC_T2_JOBS_PUBSUB_FAILED,
  GET_SNC_JOB_DETAILS_SUCCESS,
  COMPANY_NAEMS_BATCH_LIST_ADD,
  COMPANY_NAEMS_BATCH_LIST_CLEAR,

  //enrich
  GET_ENRICH_1,
  GET_ENRICH_2,
  ENRICH_ADD_JOB_ID_1,
  ENRICH_ADD_JOB_ID_2,
  SAVE_TO_CUSTOM_LIST_SUCCESS,
  MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS,
  MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS,
  UDPATE_SET_DEFAULT_LISTNAME_PEOPLELIST,
  UDPATE_SET_DEFAULT_LISTNAME_COMPANYLIST,
} from 'constants/ActionTypes';
import { isEmpty } from '../util';

const INIT_STATE = {
  alertMessage: '',
  infoMessage: '',
  showMessage: false,
  loader: false,
  jobsList: {},
  jobDetails: {},
  snp_jobid: '',
  snp_searchDetails: {},
  snp_jobResult: {},
  snc_jobid: '',
  snc_searchDetails: {},
  snc_jobResult: {},
  snc_playbookInfo: {},
  companyNamesBatchList: [], // it holds company names when user selected items from company search list for people search
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_JOBS_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        infoMessage: false,
        showMessage: true,
        loader: false,
      };
    }
    case SHOW_JOBS_INFO_MESSAGE: {
      return {
        ...state,
        infoMessage: action.payload,
        alertMessage: '',
        showMessage: true,
        loader: false,
      };
    }

    case HIDE_JOBS_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        infoMessage: '',
        showMessage: false,
        loader: false,
      };
    }

    case STOP_JOB_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }

    case GET_JOBS: {
      return {
        ...state,
        loader: true,
        jobsList: {},
      };
    }

    case GET_JOBS_SUCCESS: {
      return {
        ...state,
        loader: false,
        jobsList: action.payload,
      };
    }

    case GET_JOB_DETAILS_EMPTY: {
      return {
        ...state,
        jobDetails: {},
      };
    }

    case GET_JOB_DETAILS_SUCCESS: {
      return {
        ...state,
        loader: false,
        jobDetails: action.payload,
      };
    }

    case SALES_NAV_USER_SCRAPE: {
      // reset snp_searchDetails and snp_jobResult for page 1
      if (action.payload.peopleSearch.page == 1)
        return {
          ...state,
          loader: true,
          snp_jobResult: {},
          snp_searchDetails: {},
        };

      return {
        ...state,
        loader: true,
      };
    }

    case SALES_NAV_COMPANY_SCRAPE: {
      // reset snc_jobResult and snc_searchDetails for page 1
      if (action.payload.companySearch.page == 1)
        return {
          ...state,
          loader: true,
          snc_jobResult: {},
          snc_searchDetails: {},
        };

      return {
        ...state,
        loader: true,
      };
    }

    case SNP_SUBMIT: {
      return {
        ...state,
        loader: true,
      };
    }

    case SAVE_SNP_SAVE_JOBID: {
      return {
        ...state,
        snp_jobid: action.payload,
      };
    }

    case GET_SNP_JOBS_PUBSUB: {
      return {
        ...state,
        loader: true,
      };
    }

    case SUBMIT_PEOPLE_JOBS_SUCCESS: {
      return {
        ...state,
        snp_searchDetails: action.payload,
      };
    }

    case SUBMIT_COMPANY_JOBS_SUCCESS: {
      return {
        ...state,
        snc_searchDetails: action.payload,
      };
    }

    case COMPANY_NAEMS_BATCH_LIST_ADD: {
      return {
        ...state,
        companyNamesBatchList: action.payload,
      };
    }

    case COMPANY_NAEMS_BATCH_LIST_CLEAR: {
      return {
        ...state,
        companyNamesBatchList: [],
      };
    }

    //Type 1 search
    case GET_SNP_T1_JOBS_PUBSUB_SUCCESS: {
      if (
        !isEmpty(state.snp_jobResult) &&
        state.snp_jobResult.peopleJSON &&
        state.snp_jobResult.peopleJSON.length > 0
      )
        action.payload.peopleJSON = [
          ...state.snp_jobResult.peopleJSON,
          ...action.payload.peopleJSON,
        ];

      let { maxPagesRead, page } = action.payload.peopleSearch;
      // If there is only one page.
      // It will be in highlighted state and has different selector. so will get 0 page count, then make it 1
      maxPagesRead = maxPagesRead == 0 ? 1 : maxPagesRead;

      state.snp_searchDetails.searchIterationsMax = maxPagesRead;
      state.snp_searchDetails.searchIterationsDoneCount = page;
      if (maxPagesRead == page) state.snp_searchDetails.isSearchGoingOn = false;

      return {
        ...state,
        loader: false,
        snp_jobResult: action.payload,
        snp_searchDetails: { ...state.snp_searchDetails },
      };
    }

    //Type 2 search
    case GET_SNP_T2_JOBS_PUBSUB_SUCCESS: {
      if (
        !isEmpty(state.snp_jobResult) &&
        state.snp_jobResult.peopleJSON &&
        state.snp_jobResult.peopleJSON.length > 0
      )
        action.payload.peopleJSON = [
          ...state.snp_jobResult.peopleJSON,
          ...action.payload.peopleJSON,
        ];

      let { page } = action.payload.peopleSearch;
      state.snp_searchDetails.searchIterationsDoneCount = page;

      return {
        ...state,
        loader: false,
        snp_jobResult: action.payload,
        snp_searchDetails: { ...state.snp_searchDetails },
      };
    }

    //failed without single page result
    case GET_SNP_T1_JOBS_PUBSUB_FAILED: {
      let { maxPagesRead, page } = action.payload.peopleSearch;
      state.snp_searchDetails.searchIterationsMax = maxPagesRead;
      state.snp_searchDetails.searchIterationsDoneCount = page;
      if (maxPagesRead == page) state.snp_searchDetails.isSearchGoingOn = false;

      return {
        ...state,
        loader: false,
        snp_jobResult: {},
        snp_searchDetails: { ...state.snp_searchDetails },
        infoMessage: action.payload.oStatus,
        showMessage: true,
      };
    }

    case GET_SNP_T2_JOBS_PUBSUB_FAILED: {
      state.snp_searchDetails = action.payload;

      // let error = {};

      //when there is no result at all
      /* if(state.snp_searchDetails.searchIterationsDoneCount == 0)
      {
         error = {
          alertMessage: "No result found",
          showMessage: true
        }
       
      } */

      return {
        ...state,
        loader: false,
        snp_searchDetails: { ...state.snp_searchDetails },
        // ...error
      };
    }

    case GET_SNP_JOB_DETAILS_SUCCESS: {
      return {
        ...state,
        // loader: false,
        snp_jobResult: action.payload,
      };
    }

    case CLEAR_SNP_ALL_DETAILS: {
      return {
        ...state,
        snp_jobResult: {},
        snp_searchDetails: {},
      };
    }

    /*SNC */

    case CLEAR_SNC_ALL_DETAILS: {
      return {
        ...state,
        snc_playbookInfo: {},
        snc_jobResult: {},
        snc_searchDetails: {},
      };
    }

    case SAVE_SNC_SAVE_JOBID: {
      return {
        ...state,
        snc_jobid: action.payload,
      };
    }

    case GET_SNC_JOBS_PUBSUB: {
      return {
        ...state,
        loader: true,
      };
    }

    /**
     * For T1
     */
    case GET_SNC_T1_JOBS_PUBSUB_SUCCESS: {
      if (
        !isEmpty(state.snc_jobResult) &&
        state.snc_jobResult.companyJSON &&
        state.snc_jobResult.companyJSON.length > 0
      )
        action.payload.companyJSON = [
          ...state.snc_jobResult.companyJSON,
          ...action.payload.companyJSON,
        ];

      let { maxPagesRead, page } = action.payload.companySearch;
      // If there is only one page.
      // It will be in highlighted state and has different selector. so will get 0 page count, then make it 1
      maxPagesRead = maxPagesRead == 0 ? 1 : maxPagesRead;

      state.snc_searchDetails.searchIterationsMax = maxPagesRead;
      state.snc_searchDetails.searchIterationsDoneCount = page;
      if (maxPagesRead == page) state.snc_searchDetails.isSearchGoingOn = false;

      return {
        ...state,
        loader: false,
        snc_jobResult: action.payload,
        snc_searchDetails: { ...state.snc_searchDetails },
      };
    }

    //Type 2 search
    case GET_SNC_T2_JOBS_PUBSUB_SUCCESS: {
      // replace whole array result
      if (
        !isEmpty(state.snc_jobResult) &&
        state.snc_jobResult.companyJSON &&
        state.snc_jobResult.companyJSON.length > 0
      )
        action.payload.companyJSON = [...action.payload.companyJSON];

      let { page } = action.payload.companySearch;
      state.snc_searchDetails.searchIterationsDoneCount = page;

      console.log(action.payload);

      return {
        ...state,
        loader: false,
        snc_jobResult: action.payload,
        snc_searchDetails: { ...state.snc_searchDetails },
      };
    }

    case GET_SNC_T1_JOBS_PUBSUB_FAILED: {
      let { maxPagesRead, page } = action.payload.companySearch;
      state.snc_searchDetails.searchIterationsMax = maxPagesRead;
      state.snc_searchDetails.searchIterationsDoneCount = page;
      if (maxPagesRead == page) state.snc_searchDetails.isSearchGoingOn = false;

      return {
        ...state,
        loader: false,
        snp_jobResult: {},
        snc_searchDetails: { ...state.snc_searchDetails },
        infoMessage: action.payload.oStatus,
        showMessage: true,
      };
    }

    case GET_SNC_T2_JOBS_PUBSUB_FAILED: {
      // let error = {};

      state.snc_searchDetails = action.payload;
      //when there is no result at all
      /* if(state.snp_searchDetails.searchIterationsDoneCount == 0)
      {
         error = {
          alertMessage: "No result found",
          showMessage: true
        }
       
      } */

      return {
        ...state,
        loader: false,
        snc_searchDetails: { ...state.snc_searchDetails },
        // ...error
      };
    }

    case SET_USERINFO_CHECKED_UPDATED_LIST_RESULT: {
      return {
        ...state,
        loader: false,
        snp_jobResult: action.payload,
      };
    }

    case SET_COMPANYINFO_CHECKED_UPDATED_LIST_RESULT: {
      return {
        ...state,
        loader: false,
        snc_jobResult: action.payload,
      };
    }

    case GET_SNC_JOB_DETAILS_SUCCESS: {
      return {
        ...state,
        // loader: false,
        snc_jobResult: action.payload,
      };
    }

    case SAVE_SNC_PLAYBOOKINFO: {
      return {
        ...state,
        loader: false,
        snc_playbookInfo: action.payload,
      };
    }

    case SNC_SUBMIT: {
      return {
        ...state,
        loader: true,
      };
    }

    // ENRICH 1 API WILL BE CALLED
    case GET_ENRICH_1: {
      let changedTargetSet = action.payload;

      return {
        ...state,
        // loader: true,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...changedTargetSet],
        },
      };
    }

    // ENRICH 2 API WILL BE CALLED

    case GET_ENRICH_2: {
      let changedTargetSet = action.payload;

      return {
        ...state,
        // loader: true,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...changedTargetSet],
        },
      };
    }

    /**ENRICHMENT type1
     * USING THIS FOR SAVING ENRICHMENTS RESULT AND JOB ID
     */
    case ENRICH_ADD_JOB_ID_1:
    case ENRICH_ADD_JOB_ID_2: {
      let { mapToData } = action.payload;

      console.log('mapToData', mapToData);

      /*   let snp_jobResult_with_enrich_jobid_list = state.snp_jobResult.targetSet.map((item, index) => {
  
            let isFoundObj = mapToData.filter(map => map.id == item.id)
  
            if (isFoundObj.length > 0)
              item.enrichmentDetails.enrichmentJobId = isFoundObj[0].jodId;
  
            return item;
          });*/

      return {
        ...state,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...mapToData],
        },
      };
    }

    //PULL PUBUSB SUCESS
    //NOT USING
    case GET_SNP_JOBS_PUBSUB_ENRICH_1_SUCCESS: {
      let enrichPubsubData = action.payload;
      let snp_enrich_updated = state.snp_jobResult.peopleJSON.map(job => {
        let gotEnrichForJob = enrichPubsubData.filter(
          enrich => enrich.jobId == job.enrichmentDetails.enrichmentJobId
        );
        if (gotEnrichForJob.length > 0) {
          job.contactRecord.bEmail = gotEnrichForJob[0].bEmail;
          job.contactRecord.bPhone = gotEnrichForJob[0].bPhone;
          job.enrichmentDetails.isEnrichment_E1_GoingOn = 'DONE';
        }
        return job;
      });

      return {
        ...state,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...snp_enrich_updated],
        },
      };
    }

    //NOT USING

    case GET_SNP_JOBS_PUBSUB_ENRICH_2_SUCCESS: {
      let enrichPubsubData = action.payload;
      let snp_enrich_updated = state.snp_jobResult.peopleJSON.map(job => {
        let gotEnrichForJob_1 = enrichPubsubData.filter(
          enrich => enrich.jobId == job.enrichmentDetails.enrichmentJobId + '_1'
        );
        if (gotEnrichForJob_1.length > 0) {
          job.contactRecord.bEmail =
            gotEnrichForJob_1[0].bEmail.length == 0 ? ['-'] : gotEnrichForJob_1[0].bEmail;
          job.contactRecord.bPhone =
            gotEnrichForJob_1[0].bPhone.length == 0 ? ['-'] : gotEnrichForJob_1[0].bPhone;
        }

        let gotEnrichForJob_2 = enrichPubsubData.filter(
          enrich => enrich.jobId == job.enrichmentDetails.enrichmentJobId + '_2'
        );
        if (gotEnrichForJob_2.length > 0) {
          job.contactRecord.pEmail =
            gotEnrichForJob_2[0].pEmail.length == 0 ? ['-'] : gotEnrichForJob_2[0].pEmail;
          job.contactRecord.pPhone =
            gotEnrichForJob_2[0].pPhone.length == 0 ? ['-'] : gotEnrichForJob_2[0].pPhone;
        }

        let { bEmail, bPhone, pPhone, pEmail } = job.contactRecord;
        console.log(job.contactRecord);

        if ((bEmail.length > 0 || bPhone.length > 0) && (pPhone.length > 0 || pEmail.length > 0))
          job.enrichmentDetails.isEnrichment_E1_GoingOn = 'DONE';
        job.enrichmentDetails.isEnrichment_E2_GoingOn = 'DONE';
        // job.enrichmentDetails.reviewCount = job.enrichmentDetails.reviewCount + 1;

        return job;
      });

      return {
        ...state,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...snp_enrich_updated],
        },
      };
    }

    case SET_ENRICH_PEOPLE_CONTACTS_LIST_ITEM: {
      let peopleJSON = [];
      peopleJSON.push(action.payload);
      return {
        ...state,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...peopleJSON],
        },
      };
    }

    case SET_ENRICH_PEOPLE_CONTACTS_LIST: {
      return {
        ...state,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...action.payload],
        },
      };
    }

    case SET_ENRICH_COMPANY_CONTACTS_LIST: {
      let companyJSON = [];
      companyJSON.push(action.payload);
      return {
        ...state,
        snc_jobResult: {
          ...state.snc_jobResult,
          companyJSON: [...companyJSON],
        },
      };
    }

    case SAVE_TO_CUSTOM_LIST_SUCCESS: {
      let companyJSONList = state.snc_jobResult.companyJSON.map(item => {
        action.payload.map(resp => {
          if (resp.id === item.id) {
            item.identity.recordId = resp.compid;
            item.identity.status = resp.status;
            item.identity.lid = resp.lid;
            item.identity.lnm = resp.lnm;
          }
        });
        return item;
      });

      return {
        ...state,
        snc_jobResult: {
          ...state.snc_jobResult,
          companyJSON: [...companyJSONList],
        },
      };
    }

    /** Move list item from one list to another**/

    case MOVE_PEOPLE_CONTACT_TO_LIST_SUCCESS: {
      if (!state.snp_jobResult.peopleJSON)
        return {
          ...state,
          loader: false,
        };

      //find with record id
      let snp_jobResultindex = state.snp_jobResult.peopleJSON.findIndex(
        item => item.identity.recordId == action.payload.recodId
      );

      if (snp_jobResultindex == -1)
        return {
          ...state,
          loader: false,
        };

      let peopleJson = [...state.snp_jobResult.peopleJSON];

      peopleJson[snp_jobResultindex] = {
        ...peopleJson[snp_jobResultindex],
        identity: {
          ...peopleJson[snp_jobResultindex].identity,
          lid: action.payload.lidTo,
          lnm: action.payload.lidToName,
        },
      };

      return {
        ...state,
        loader: false,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: peopleJson,
        },
      };
    }

    /** Move list item from one list to another**/
    case MOVE_COMPANY_CONTACT_TO_LIST_SUCCESS: {
      if (!state.snc_jobResult.companyJSON)
        return {
          ...state,
          loader: false,
        };

      //find with record id
      let snc_jobResultindex = state.snc_jobResult.companyJSON.findIndex(
        item => item.identity.recordId == action.payload.recodId
      );

      if (snc_jobResultindex == -1)
        return {
          ...state,
          loader: false,
        };

      let companyJSON = [...state.snc_jobResult.companyJSON];

      companyJSON[snc_jobResultindex] = {
        ...companyJSON[snc_jobResultindex],
        identity: {
          ...companyJSON[snc_jobResultindex].identity,
          lid: action.payload.lidTo,
          lnm: action.payload.lidToName,
        },
      };

      return {
        ...state,
        loader: false,
        snc_jobResult: {
          ...state.snc_jobResult,
          companyJSON: companyJSON,
        },
      };
    }

    case UDPATE_SET_DEFAULT_LISTNAME_PEOPLELIST: {
      let { lid, lnm } = action.payload;
      console.log('update set default people', action.payload);
      let updatedWithDefaultlist = state.snp_jobResult.peopleJSON.map(item => {
        if (item.identity.recordId == '') {
          item.identity.lid = lid;
          item.identity.lnm = lnm;
        }
        return item;
      });

      console.log('updatedWithDefaultlist', updatedWithDefaultlist);

      return {
        ...state,
        snp_jobResult: {
          ...state.snp_jobResult,
          peopleJSON: [...updatedWithDefaultlist],
        },
      };
    }

    case UDPATE_SET_DEFAULT_LISTNAME_COMPANYLIST: {
      let { lid, lnm } = action.payload;
      console.log('update set default people', action.payload);
      let updatedWithDefaultlist = state.snc_jobResult.companyJSON.map(item => {
        if (item.identity.recordId == '') {
          item.identity.lid = lid;
          item.identity.lnm = lnm;
        }
        return item;
      });

      return {
        ...state,
        snc_jobResult: {
          ...state.snc_jobResult,
          companyJSON: [...updatedWithDefaultlist],
        },
      };
    }

    default:
      return state;
  }
};
