import React, { useState } from 'react';
import AutoCompleteListName from 'components/AutoCompleteList';
import { Button } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import {
  Divider,
  IconButton as MuiIconButton,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.common.white,
    position: 'absolute',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: 450,
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[8],
    minHeight: 350,
  },
  header: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(2, 3),

    '& > button': {
      position: 'absolute',
      right: '0',
      top: '0',
      transform: `translate(-40%, 50%)`,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  content: {
    minHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mainContent: {
    padding: theme.spacing(2, 3),
  },
  divider: {
    backgroundColor: theme.palette.grey[400],
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  helpText: {
    fontSize: '0.75rem',
    margin: theme.spacing(2, 0),
  },
  footer: {
    width: '100%',
  },
}));

const MoveContactsPopup = props => {
  const { open, data, selected, closePopup, moveToSelectedListName, moveToNewListName } = props;
  const classes = useStyles();

  const [createListButtons, setCreateListButtons] = useState(false);
  const [moveButtons, setMoveButtons] = useState(false);

  const [createListName, setCreateListName] = useState(null);
  const [moveToListObj, setMoveToListObj] = useState({});

  const handleSetDropDownSelected = listObj => {
    console.log('new object', listObj);
    setCreateListButtons(false);
    setMoveButtons(true);
    setMoveToListObj(listObj);
  };
  const handleSetDropDownNewItemCreated = lnm => {
    console.log('new list name', lnm);
    setCreateListButtons(true);
    setMoveButtons(false);
    setCreateListName(lnm);
  };

  const handleCreateList = () => {
    moveToNewListName(createListName);
  };
  const handleMoveContacts = () => {
    moveToSelectedListName(moveToListObj);
  };

  const cancel = () => {
    setMoveButtons(false);
    setCreateListButtons(false);
  };

  const handleClose = () => closePopup();

  return (
    <Fade in={open} className={classes.root} onClick={e => e.stopPropagation()}>
      <div className={classes.paper}>
        <div className={classes.header}>
          <Typography variant='h6' className={classes.fontBold}>
            Move contacts to different Streams
          </Typography>
          <MuiIconButton onClick={handleClose} size='small'>
            <CloseIcon />
          </MuiIconButton>
        </div>
        <div className={classes.content}>
          <div className={classes.mainContent}>
            <Typography variant='body2' className={classes.fontBold} gutterBottom>
              Where do you want to move this contact?
            </Typography>
            <form className={classes.form}>
              <AutoCompleteListName
                selected={selected}
                data={data}
                enabled={true}
                dropDownSelected={handleSetDropDownSelected}
                dropDownNewItemCreated={handleSetDropDownNewItemCreated}
                width={'100%'}
              />

              {/*  <Autocomplete
                      value={autocomplete}
                      onChange={onSearchChange}
                      filterOptions={filterOptions}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="listNamesFilterAutocomplete"
                      options={listNames}
                      getOptionLabel={getOptionLabel}
                      // getOptionDisabled={option => option.title.indexOf('Create a new list') > -1}
                      getOptionDisabled={option => option.title.indexOf(selectedName) > -1}
                      renderOption={(option) => option.title}
                      style={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                          <TextField {...params} label="Select a list or create a new" variant="outlined" />
                      )}
                  /> */}
              <Typography className={classes.helpText} color='textSecondary'>
                Start typing your destination stream below. If it exists, it will show up or you can
                create a new
              </Typography>
            </form>
          </div>
          {(createListButtons || moveButtons) && (
            <div className={classes.footer}>
              <Divider className={classes.divider} />
              <div className={classes.buttonWrapper}>
                <Button className='jr-btn' variant='contained' aria-label='cancel' onClick={cancel}>
                  <span>Cancel</span>
                </Button>

                {createListButtons && (
                  <Button
                    className='jr-btn'
                    variant='contained'
                    color='primary'
                    aria-label='save'
                    onClick={handleCreateList}
                  >
                    <span>Create</span>
                  </Button>
                )}

                {moveButtons && (
                  <Button
                    className='jr-btn'
                    variant='contained'
                    color='primary'
                    aria-label='save'
                    onClick={handleMoveContacts}
                  >
                    <span>Move</span>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Fade>
  );

  // return (
  //   <div className="popup-content">
  //     <div className="align-self-end">
  //       <h6 className="font-weight-light pointer m-2" onClick={handleClose}>
  //         Close
  //       </h6>
  //     </div>
  //     <div className="d-flex flex-column flex-grow-1">
  //       <p className="text-dark">Where do you want to move the contact?</p>
  //       <form className="flex-grow-1">
  //         <AutoCompleteListName
  //           selected={selected}
  //           data={data}
  //           enabled={true}
  //           dropDownSelected={handleSetDropDownSelected}
  //           dropDownNewItemCreated={handleSetDropDownNewItemCreated}
  //           width={300}
  //         />

  //         {/*  <Autocomplete
  //                   value={autocomplete}
  //                   onChange={onSearchChange}
  //                   filterOptions={filterOptions}
  //                   selectOnFocus
  //                   clearOnBlur
  //                   handleHomeEndKeys
  //                   id="listNamesFilterAutocomplete"
  //                   options={listNames}
  //                   getOptionLabel={getOptionLabel}
  //                   // getOptionDisabled={option => option.title.indexOf('Create a new list') > -1}
  //                   getOptionDisabled={option => option.title.indexOf(selectedName) > -1}
  //                   renderOption={(option) => option.title}
  //                   style={{ width: 300 }}
  //                   freeSolo
  //                   renderInput={(params) => (
  //                       <TextField {...params} label="Select a list or create a new" variant="outlined" />
  //                   )}
  //               /> */}
  //       </form>
  //       {(createListButtons || moveButtons) && (
  //         <div className="d-flex justify-content-end">
  //           <Button
  //             className="jr-btn"
  //             variant="contained"
  //             aria-label="cancel"
  //             onClick={cancel}
  //           >
  //             <span>Cancel</span>
  //           </Button>

  //           {createListButtons && (
  //             <Button
  //               className="jr-btn"
  //               variant="contained"
  //               color="primary"
  //               aria-label="save"
  //               onClick={handleCreateList}
  //             >
  //               <span>Create</span>
  //             </Button>
  //           )}

  //           {moveButtons && (
  //             <Button
  //               className="jr-btn"
  //               variant="contained"
  //               color="primary"
  //               aria-label="save"
  //               onClick={handleMoveContacts}
  //             >
  //               <span>Move</span>
  //             </Button>
  //           )}
  //         </div>
  //       )}
  //     </div>
  //   </div>
  // );
};

export default MoveContactsPopup;
