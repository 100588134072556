import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import './iconButton.css';

const IconButton = props => {
  return (
    <Button {...props} style={styles.button}>
      {!props.disabled && props.enable_icon && (
        <img src={props.enable_icon} style={styles.buttonImage} />
      )}
      {props.disabled && props.disable_icon && (
        <img src={props.disable_icon} style={styles.buttonImage} />
      )}
      <span style={styles.buttonText}>{props.children}</span>
    </Button>
  );
};

const styles = {
  buttonImage: {
    height: '20px',
    verticalAlign: 'middle',
    border: 'none',
    marginRight: '5px',
  },
  buttonText: {},
};

const themestyles = theme => ({
  root: {
    padding: '5px 10px',
    border: 'none',
    fontFamily: 'Inter-light-Font',
    boxShadow: 'none',
    borderRadius: 'initial',
  },
  /*  disabledButton : {
    padding: "4px",
    border: "none",
    backgroundColor: "initial !important",
    border: "1px solid #c3c3c3 !important",
    opacity: 0.5
   } */
});

export default withStyles(themestyles, { withTheme: true })(IconButton);
