import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
} from '../firebase/firebase';
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
  SIGNUP_USER,
  ADD_PROFILE_DETAILS,
  FORGOT_PASSWORD_RESET_EMAIL,
  PROFILE,
  GET_USER_DETAILS,
  ADD_INVITE,
  GET_INVITATION_DETAILS,
  CONFIRM_INVITATION,
  GET_ROUTE_DETAILS,
} from 'constants/ActionTypes';
import {
  showAuthMessage,
  showSuccessMessage,
  userSignInSuccess,
  userSignOutSuccess,
  navProfile,
  profileSuccess,
  userSignUpSuccess,
  forgorPasswordResetEmailSuccess,
} from 'actions/Auth';
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  addUserDetails,
  getUserLoginDetailsSuccess,
  profileDetailsAreSubmitted,
  saveInvitedDetails,
  confirm_invitation_success,
  updateProfileSuccess,
  updatePasswordSuccess,
  getRouteDetailsSuccess,
} from '../actions/Auth';

import {
  baseurl,
  baseIAMUrl,
  iamRoute,
  userRoute,
  saveProfile,
  baseSubscriptionUrl,
  subscriptionUser,
  getRouteDetailsUrl,
  installNewUser,
  redirectUrl,
  getUserDetailsUrl,
  updateProfileUrl,
  addInviteMethod,
  getInviteDetailMethod,
  confirmUserJoinedMethod,
} from 'util/urls';

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

/*  */

export const getAuthToken = async () => {
  return new Promise(async (resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        unsubscribe();
        const token = await user.getIdToken();
        resolve(token);
      }
    }, reject);
  });
};

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await auth
    .signOut()
    .then(authUser => authUser)
    .catch(error => error);

const updatePasswordRequest = async newPassword =>
  await auth.currentUser
    .updatePassword(newPassword)
    .then(result => result)
    .catch(error => error);

const signInUserWithGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await auth
    .signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await auth
    .signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const resetUserRequest = async emailAddress => {
  var actionCodeSettings = {
    // After password reset, the user will be give the ability to go back
    // to this page.
    url: redirectUrl,
    handleCodeInApp: false,
  };

  return await auth
    .sendPasswordResetEmail(emailAddress, actionCodeSettings)
    .then(result => result)
    .catch(error => error);
};

const sendVerificationEmail = async () => {
  var actionCodeSettings = {
    // after email confirm, the user will be given the ability to go back
    // to this page.
    url: redirectUrl,
    handleCodeInApp: false,
  };
  return await auth.currentUser
    .sendEmailVerification(actionCodeSettings)
    .then(result => result)
    .catch(error => error);
};

const submitProfileDetailsAPI = async (payload, token) => {
  // const { fn, ln, cntry, cn, cty, ph, rol, des, phCnExt, uid } = payload;

  return await fetch(baseIAMUrl + iamRoute + installNewUser, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(payload),
  })
    .then(async response => {
      return await response.json();
    })
    .catch(err => err);
};

const invitationPostAPI = async (payload, url, token) => {
  let headers = token
    ? new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      })
    : new Headers({
        'Content-Type': 'application/json',
      });

  return await fetch(baseIAMUrl + iamRoute + url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(payload),
  })
    .then(async response => {
      return await response.json();
    })
    .catch(err => err);
};

/**
 *Get company detais and user details
 * @param {*} token
 */
const getUserLoginDetailsAPI = async token => {
  return await fetch(baseIAMUrl + iamRoute + getUserDetailsUrl, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
  })
    .then(async response => {
      return await response.json();
    })
    .catch(err => err);
};

const getRouteDetailsAPI = async token => {
  return await fetch(baseSubscriptionUrl + subscriptionUser + getRouteDetailsUrl, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
  })
    .then(async response => {
      return await response.json();
    })
    .catch(err => err);
};

const updateUserDetailsAPI = async (token, payload) => {
  return await fetch(baseIAMUrl + iamRoute + updateProfileUrl, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(payload),
  })
    .then(async response => {
      return await response.json();
    })
    .catch(err => err);
};

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));

      delete payload.email;
      delete payload.password;

      payload.uid = signUpUser.user.uid;
      yield put(addUserDetails(payload));
    }
  } catch (error) {
    console.log('error', error.message);
    yield put(showAuthMessage(error.message));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userGithubSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      console.log(signInUser.user);

      localStorage.setItem('user_id', signInUser.user.uid);
      yield put(userSignInSuccess(signInUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.clear();
      yield put(userSignOutSuccess());
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* updateUserProfile({ payload }) {
  try {
    const token = yield call(getAuthToken);

    const updateDetails = yield call(updateUserDetailsAPI, token, {
      fn: payload.firstName,
      ln: payload.lastName,
      ph: payload.phone,
    });

    if (updateDetails.hasOwnProperty('Msg')) yield put(updateProfileSuccess());
    else yield put(showAuthMessage(updateDetails.Error.Msg));
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* updateUserPassword({ payload }) {
  try {
    let { email, oldPassword, newPassword } = payload;

    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, oldPassword);

    if (signInUser.message) throw new Error('Sorry. Old password is not correct');
    else {
      const updatePasswordResult = yield call(updatePasswordRequest, newPassword);

      if (updatePasswordResult === undefined) {
        yield put(showSuccessMessage('Password updated successfully'));
        // yield put(updatePasswordSuccess());
      } else {
        throw new Error(updatePasswordResult.message);
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* submitProfileDetails({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(submitProfileDetailsAPI, payload, token);
    if (!response.hasOwnProperty('Error')) {
      // yield put(profileDetailsAreSubmitted());

      /* send email to only uninvited user */
      if (response.emailCheck) yield call(sendVerificationEmail);

      yield put(profileSuccess());
    } else yield put(showAuthMessage(response.Error.Msg));
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* getUserLoginDetails({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(getUserLoginDetailsAPI, token);
    console.log(response);
    if (!response.hasOwnProperty('Error')) {
      response.userDetails.firebaseEmailVerified = payload.firebaseEmailVerified;
      // localStorage.setItem("userDetails", JSON.stringify(response.userDetails));
      // localStorage.setItem("companyDetails", JSON.stringify(response.companyDetails));
      yield put(getUserLoginDetailsSuccess(response));
    } else {
      yield put(showAuthMessage(response.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* getRouterDetails() {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(getRouteDetailsAPI, token);
    console.log(response);
    if (!response.hasOwnProperty('Error')) {
      yield put(getRouteDetailsSuccess(response));
    } else {
      yield put(showAuthMessage(response.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* resetPassword({ email }) {
  try {
    const resetPasswordUser = yield call(resetUserRequest, email);
    if (resetPasswordUser === undefined) {
      yield put(showSuccessMessage('Password reset email has been sent'));
    } else {
      yield put(showAuthMessage(resetPasswordUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* addInvitation({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(invitationPostAPI, payload, addInviteMethod, token);
    if (!response.hasOwnProperty('Error')) {
      yield put(showSuccessMessage('Invitation has been sent succesfully'));
    } else yield put(showAuthMessage(response.Error.Msg));
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* getInviteDetails({ payload }) {
  try {
    // const token = yield call(getAuthToken);
    const response = yield call(invitationPostAPI, payload, getInviteDetailMethod);
    if (!response.hasOwnProperty('Error')) {
      yield put(saveInvitedDetails(response));
    } else yield put(showAuthMessage(response.Error.Msg));
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* confirmUserJoined({ payload }) {
  try {
    const token = yield call(getAuthToken);
    const response = yield call(invitationPostAPI, payload, confirmUserJoinedMethod, token);
    if (!response.hasOwnProperty('Error')) {
      yield put(confirm_invitation_success());
    } else yield put(showAuthMessage(response.Error.Msg));
  } catch (error) {
    yield put(showAuthMessage(error.message));
  }
}

function* moveToProfile() {
  yield put(navProfile());
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* updateProfile() {
  yield takeEvery(UPDATE_PROFILE, updateUserProfile);
}

export function* updatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updateUserPassword);
}

export function* callProfileAPI() {
  yield takeEvery(ADD_PROFILE_DETAILS, submitProfileDetails);
}

export function* resetPasswordUser() {
  yield takeEvery(FORGOT_PASSWORD_RESET_EMAIL, resetPassword);
}

export function* getUserDetails() {
  yield takeEvery(GET_USER_DETAILS, getUserLoginDetails);
}

export function* getRouteDetails() {
  yield takeEvery(GET_ROUTE_DETAILS, getRouterDetails);
}

export function* navToProfile() {
  yield takeEvery(PROFILE, moveToProfile);
}

export function* addInvite() {
  yield takeEvery(ADD_INVITE, addInvitation);
}

export function* getUserInvitationDetails() {
  yield takeEvery(GET_INVITATION_DETAILS, getInviteDetails);
}

export function* confirmInvitation() {
  yield takeEvery(CONFIRM_INVITATION, confirmUserJoined);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser),

    fork(updateProfile),
    fork(updatePassword),

    // fork(navToProfile),
    fork(getUserDetails),
    fork(callProfileAPI),
    fork(resetPasswordUser),
    fork(getRouteDetails),

    /**user invite */
    fork(addInvite),
    fork(getUserInvitationDetails),
    fork(confirmInvitation),
  ]);
}
