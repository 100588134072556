import React from 'react';

const UserInfo = () => {
  /* const dispatch = useDispatch();

  const [anchorE1, setAnchorE1] = useState(null);
  const [open, setOpen] = useState(false);
  const [displayName, setDisplayName] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);

  const handleClick = event => {
    setOpen(true);
    setAnchorE1(event.currentTarget);
  };

  const handleRequestClose = () => {
    setOpen(false);
  };

  //TODO : Need to keep it in action later
  const getUserDetailsFromAPI = (token) => {
    fetch('http://192.168.43.84:5000/api/auth/whoami', {
      method: 'GET',
      headers: new Headers({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
    }).then(async(response) => {
        console.log(await response.json());
        if(response.status == 200){
        }
    }).catch(console.log);
  }

  useEffect(() => {

    if(!displayName)
      auth.onAuthStateChanged(async function (user) {
        if (user) {
          setDisplayName(user.displayName);
          setPhotoURL(user.photoURL);
          let token = await auth.currentUser.getIdToken();
          // getUserDetailsFromAPI(token); //get user details from server by passing token

        } else {
          // No user is signed in.
        }
      });

  }, [setDisplayName]);

  const navToProfile = () => {
    dispatch(navProfile());
  } */

  return (
    <div style={{ margin: '12px 0', width: '100%', textAlign: 'center' }}>
      <img src={require('assets/images/GR-logo-new.png')} alt='logo' style={{ height: '100%' }} />

      {/*  <Avatar
        alt='...'
        src={photoURL != undefined ? photoURL : "https://via.placeholder.com/150x150"}
        className="user-avatar "
      />
      <div className="user-detail">
        <h4 className="user-name" onClick={handleClick}>{displayName} <i
          className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
        </h4>
      </div>
      <Menu className="user-info"
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={handleRequestClose}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <MenuItem onClick={navToProfile}>
          <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.profile" />
        </MenuItem>
        <MenuItem onClick={handleRequestClose}>
          <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />
          <IntlMessages id="popup.setting" />
        </MenuItem>
        <MenuItem onClick={() => {
          handleRequestClose();
          dispatch(userSignOut());
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

          <IntlMessages id="popup.logout" />
        </MenuItem>
      </Menu> */}
    </div>
  );
};

export default UserInfo;
