import { useState, useEffect, useRef } from 'react';
import { Network } from 'vis-network/standalone/esm/vis-network';

export default (props, callback) => {
  const { edges, nodes, options } = props;

  const [network, addNetwork] = useState(null);
  const ref = useRef(null);

  const data = { nodes, edges };

  useEffect(() => {
    if (ref.current) {
      const instance = new Network(ref.current, data, options);
      addNetwork(instance);
    }
    return () => network?.destroy();
  }, [callback]);

  return {
    network,
    ref,
  };
};
