import React, { useState, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { TextField } from '@material-ui/core';
import IconButton from '../../../components/IconButton';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AutoCompleteListName from 'components/AutoCompleteList';
import CircularProgress from '@material-ui/core/CircularProgress';

/** */
import ResultListTable from '../../../components/resultListTable';
import TablePagination from '../../../components/tablePagination';
import MoveContactsPopup from '../../../components/MoveContactsPopup';
import Popover from '@material-ui/core/Popover';

import {
  displayMessage,
  showSuccessMessage,
  clearSNPeopleTaskResult,
  clearSNCompanyTaskResult,
  salesNavUserScrapeSubmit,
  salesNavCompanyScrapeSubmit,
  stopJobLoader,
  getSNPJobsPubSub,
  getSNCJobsPubSub,
  setUserJobsSelectUpdated,
  setCompanyJobsSelectUpdated,
  getEnrich1ResultFromAPI,
  getEnrich2ResultFromAPI,
  hideMessage,
  getSNPT2JobsPubSubFailed,
  getSNCT2JobsPubSubFailed,
  companyNamesBatchListAdd,
  companyNamesBatchListClear,
} from 'actions/jobs';

import {
  getListNameByUser,
  createCustomListName,
  setDefaultList,
  moveContactToList,
  saveToCustomList,
  hideContactMessage,
} from 'actions/Contact';

import { STARTED, SELECTED, PULL_TIMEOUT, DONE } from 'constants/ActionTypes';
import {
  handleDownloadSearchCSV,
  tableDisplayData,
  isEmpty,
  salesNavMaxPageScrape,
  maxAllowedChips,
} from '../../../util';
import TagsInput from 'components/DummySearchBox';
import SeaBottle from 'assets/images/SeaBottle.svg';

const SalesNavSearchTest = props => {
  let T1_page1 = 60;
  let T2_page1 = 30;
  let T1_page2 = 20;
  let T2_page2 = 30;

  const {
    loader,
    alertMessage,
    showMessage,
    infoMessage,
    snp_searchDetails,
    snp_jobResult,
    snc_searchDetails,
    snc_jobResult,
    companyNamesBatchList,
  } = useSelector(({ jobs }) => jobs);

  const {
    contactsLoader,
    contactsAlertMessage,
    contactsShowMessage,
    contactsInfoMessage,
    peopleListNames,
    companyListNames,
  } = useSelector(({ contacts }) => contacts);

  const { companyDetails, userDetails } = useSelector(({ auth }) => auth);
  const classes = useStyles();
  const storeDispatch = useDispatch();

  /**
   * State variables
   */
  const [searchType, setSearchType] = useState(''); //T1 or T2
  const [search, setSearch] = useState(''); //people or company
  const [snSearch, setSNSearch] = useState(''); //user or company search value stores when first api result is avaialble
  const [showNavForm, setShowNavForm] = useState(false);
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [movepopupOpen, setMovepopupOpen] = useState(false);
  const [openReviewEmail, setOpenReviewEmail] = useState(false);
  const [reviewEmailIndex, setReviewEmailIndex] = useState(0);
  const [moreInfoList, setMoreInfoList] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [designation, setDesignation] = useState('');
  const [tabPos, setTabpos] = useState(0);
  const [counter, setCounter] = useState(0);
  const [openDefaultListName, setOpenDefaultListName] = useState(false);
  const [expectedAPITime, setexpectedAPITime] = useState(30); // 30 seconds for T1, 60 for T2
  // const [selectedCompanyNames, setSelectedCompanyNames] = useState([]);
  // const [userSearchStarted, setUserSearchStarted] = useState(false);
  const [userClickedSearchFirstTime, setUserClickedSearchFirstTime] = useState(false);

  /** */
  const showFilter = false;

  const [currentPageList, setCurrentPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // 10 per page
  const [allItemSelected, setAllItemSelected] = useState(false);
  const [isEnrichFetchingStarted, setIsEnrichFetchingStarted] = useState(false);
  const [enrichanchor, setEnrichanchor] = React.useState(null);

  const [createNewAndSetBool, setCreateNewAndSetBool] = useState(false);
  const [existingListNameBool, setExistingListNameBool] = useState(false);

  const [newSetListName, setNewSetListName] = useState(null);
  const [existSetListObj, setExistSetListObj] = useState({});
  // const [firebaseAuthEmailVerified, setFirebaseAuthEmailVerified] = useState(null);

  /** */
  const jobDetails = snSearch == 'people' ? snp_jobResult : snc_jobResult;
  // const searchDetails = snSearch == 'people' ? snp_searchDetails : snp_searchDetails;
  const resultJSONKey = snSearch == 'people' ? 'peopleJSON' : 'companyJSON';

  const { isSearchGoingOn, searchIterationsMax, searchJobId, searchIterationsDoneCount } =
    snSearch == 'people' ? snp_searchDetails : snc_searchDetails;

  const getDefaultPeopleListName = () => peopleListNames.find(item => item.isDefault == true);
  const getDefaultCompanyListName = () => companyListNames.find(item => item.isDefault == true);

  /**More info */
  const initialState = {
    domain: { value: '', required: true, message: '' },
    firstname: { value: '', required: true, message: '' },
    lastname: { value: '', required: true, message: '' },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };

      default:
        throw new Error();
    }
  }

  const handleOnInputChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
    console.log(state);
  };

  /**check if moved here from people page */
  useEffect(() => {
    let rowItem = props.location.state;
    console.log('moved from people', rowItem);
    if (rowItem && rowItem.hasOwnProperty('status')) {
      setSNSearch('people');
      setSearch('people');
      setCurrentPage(1);
      setIsEnrichFetchingStarted(true); //flag
    }
  }, []);

  useEffect(() => {
    getIntialDetailsFromAPI();
  }, []);

  /**GET USER DETAILS IF NOT CAPTURED ON WEB */
  const getIntialDetailsFromAPI = () => {
    getListNames();
  };

  /**get all list names */
  const getListNames = () => {
    console.log('peopleListNames', peopleListNames);
    console.log('companyListNames', companyListNames);
    console.log('uid', localStorage.getItem('user_id'));

    if (peopleListNames.length == 0) {
      let payload = {
        uid: localStorage.getItem('user_id'),
        cat: 'people',
      };
      storeDispatch(getListNameByUser(payload));
    }

    if (companyListNames.length == 0) {
      let payload = {
        uid: localStorage.getItem('user_id'),
        cat: 'company',
      };
      storeDispatch(getListNameByUser(payload));
    }
  };
  /**
   * Get details from API
   */

  /** */ useEffect(() => {
    return () => {
      clearSNTimeInterval();
      clearEnrichTimeInterval();
    };
  }, []);

  /**SNS search pull jobs */
  useEffect(() => {
    clearSNTimeInterval();

    //T1 only for first page, for all other pages pubsub called when clicked on next page
    //T2 for all pages, every next page click will call sales nav seach t2 api

    if (
      (isSearchGoingOn && searchIterationsDoneCount == 0) ||
      (isSearchGoingOn &&
        searchType == 'T2' &&
        currentPage > jobDetails[resultJSONKey].length / perPage)
    )
      getSearchResultsFromPubSub();
  }, [searchJobId, searchIterationsDoneCount, isSearchGoingOn]);

  /**Enrichment
   *
   * Only for people search
   */
  useEffect(() => {
    console.log('jobDetails', jobDetails);

    if (
      snSearch == 'people' &&
      isEnrichFetchingStarted &&
      !isEmpty(jobDetails) &&
      jobDetails.peopleJSON &&
      jobDetails.peopleJSON.length > 0
    ) {
      let jobIdsList_E1 = [],
        jobIdsList_E2 = [];

      jobDetails.peopleJSON.map(item => {
        if (
          item.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED &&
          item.enrichmentDetails.enrichmentType == 'E1'
        )
          jobIdsList_E1.push(item.enrichmentDetails.enrichmentJobId);
      });

      jobDetails.peopleJSON.map(item => {
        if (
          item.enrichmentDetails.isEnrichment_E2_GoingOn == STARTED &&
          item.enrichmentDetails.enrichmentType == 'E2'
        ) {
          jobIdsList_E2.push(item.enrichmentDetails.enrichmentJobId);
        }
      });

      console.log('jobIdsList_E1 ', jobIdsList_E1);
      console.log('jobIdsList_E2 ', jobIdsList_E2);

      if (jobIdsList_E1.length == 0 && jobIdsList_E2.length == 0) {
        setIsEnrichFetchingStarted(false); //flag

        storeDispatch(stopJobLoader());

        setCurrentPageList(currentPageList.map(item => (item.checked.value = true)));

        onAllCheckChange();

        clearEnrichTimeInterval();
      } else if (!jobIdsList_E1.includes('') && !jobIdsList_E2.includes('')) {
        clearEnrichTimeInterval();
        /* enrichPullAPI(jobIdsList_E1, jobIdsList_E2);
                window.enrichPullInterval = setInterval(() => {
                    enrichPullAPI(jobIdsList_E1, jobIdsList_E2);

                }, 5000); */
      }
    }

    // return () => clearSNTimeInterval();
  }, [jobDetails, isEnrichFetchingStarted]);

  useEffect(() => {
    if (
      !isEmpty(jobDetails) &&
      jobDetails[resultJSONKey] &&
      jobDetails[resultJSONKey].length > 0 &&
      jobDetails[resultJSONKey].length >= (currentPage - 1) * perPage
    ) {
      let showList = jobDetails[resultJSONKey].slice(
        (currentPage - 1) * perPage,
        currentPage * perPage
      );

      let finalList = tableDisplayData(jobDetails.mode, showList, allItemSelected, true);
      setCurrentPageList(finalList);

      if (currentPage == 1 && jobDetails[resultJSONKey].length < perPage) {
        /*  if (searchType == "T1")
                    getSearchResultsFromPubSub();
                else  */
        if (searchType == 'T2' && snSearch == 'people') storeDispatch(getSNPT2JobsPubSubFailed());
        else if (searchType == 'T2' && snSearch == 'company')
          storeDispatch(getSNCT2JobsPubSubFailed());

        setIsEnrichFetchingStarted(false); //flag
      } else if (isSearchGoingOn && currentPage > jobDetails[resultJSONKey].length / perPage) {
        console.log('next call');
        if (searchType == 'T1') getSearchResultsFromPubSub();
        else if (searchType == 'T2' && snSearch == 'people')
          submitUserSearch_t2(parseInt(searchIterationsDoneCount) + 1);
        //parent fn
        else if (searchType == 'T2' && snSearch == 'company')
          submitCompanySearch_t2(parseInt(searchIterationsDoneCount) + 1); //parent fn
      }
    } else if (jobDetails.oStatus && jobDetails.oStatus.indexOf('Failure') >= 0) {
      clearSNTimeInterval();
      setCurrentPage(1);
      setCurrentPageList([]);
      showError(jobDetails.oStatus);
    } else setCurrentPageList([]);
  }, [currentPage, jobDetails]);

  const getSearchResultsFromPubSub = () => {
    if (
      searchType == 'T1' &&
      isSearchGoingOn &&
      searchIterationsDoneCount < searchIterationsMax
      /* || searchType == "T2" && isSearchGoingOn */
    ) {
      let isPullTimedOut = false;
      window.pullTimeOutInterval = setTimeout(() => {
        isPullTimedOut = true;
      }, PULL_TIMEOUT);

      snSearchPullAPI();
      window.snPullInterval = setInterval(() => {
        if (isPullTimedOut) {
          clearTimeout(window.pullTimeOutInterval);
          clearSNTimeInterval();
          storeDispatch(displayMessage('Sorry! No data found'));
        } else snSearchPullAPI();
      }, 20000);
    }
  };

  const handleGoPrevious = changePage => setCurrentPage(changePage);
  const handleGoNext = changePage => setCurrentPage(changePage);

  const clearSNTimeInterval = () => {
    if (window.snPullInterval != undefined && window.snPullInterval != 'undefined') {
      window.clearInterval(window.snPullInterval);
    }

    if (window.pullTimeOutInterval != undefined && window.pullTimeOutInterval != 'undefined') {
      window.clearTimeout(window.pullTimeOutInterval);
    }
  };

  const snSearchPullAPI = () => {
    snSearch == 'people'
      ? storeDispatch(
          getSNPJobsPubSub(searchJobId, parseInt(searchIterationsDoneCount) + 1, searchType)
        )
      : storeDispatch(
          getSNCJobsPubSub(searchJobId, parseInt(searchIterationsDoneCount) + 1, searchType)
        );
  };

  /* const enrichPullAPI = (jobIdsList_E1, jobIdsList_E2) => {
        if (jobIdsList_E1.length > 0)
            storeDispatch(getEnrich1ResultPubSubFromAPI(jobIdsList_E1));

        if (jobIdsList_E2.length > 0)
            storeDispatch(getEnrich2ResultPubSubFromAPI(jobIdsList_E2));
    } */

  const clearEnrichTimeInterval = () => {
    if (window.enrichPullInterval != undefined && window.enrichPullInterval != 'undefined') {
      window.clearInterval(window.enrichPullInterval);
    }
  };

  /**
   * select All
   */
  const onAllCheckChange = () => {
    let list;
    let selctAllItems = false;
    let checkedList = checkHowManySelected();
    checkedList.length == 0
      ? (selctAllItems = true)
      : checkedList.length == currentPageList.length
      ? (selctAllItems = false)
      : (selctAllItems = true);

    let checkedItemList = currentPageList.map(item => {
      item.checked.value = selctAllItems;
      return item;
    });

    //If all items need to select
    if (selctAllItems)
      list = jobDetails[resultJSONKey].map(job => {
        return {
          ...job,
          selected: true,
        };
      });
    else
      list = jobDetails[resultJSONKey].map(job => {
        return {
          ...job,
          selected: false,
        };
      });

    setAllItemSelected(selctAllItems);
    setCurrentPageList([...checkedItemList]);

    jobDetails[resultJSONKey] = [...list];
    if (snSearch == 'people') storeDispatch(setUserJobsSelectUpdated(jobDetails));
    else storeDispatch(setCompanyJobsSelectUpdated(jobDetails));
  };

  /* Un select list items if selected in prior search */
  const unSelectAllList = () => {
    if (allItemSelected) {
      let checkedItemList = currentPageList.map(item => {
        item.checked.value = false;
        return item;
      });

      let list = jobDetails[resultJSONKey].map(job => {
        return {
          ...job,
          selected: false,
        };
      });

      setAllItemSelected(false);
      setCurrentPageList([...checkedItemList]);

      jobDetails[resultJSONKey] = [...list];

      if (snSearch == 'people') storeDispatch(setUserJobsSelectUpdated(jobDetails));
      else storeDispatch(setCompanyJobsSelectUpdated(jobDetails));
    }
  };

  const checkHowManySelected = () => currentPageList.filter(item => item.checked.value);

  /**
   * each item click event
   * @param {*} clickedIndex
   */
  const onindividualCheckChange = clickedIndex => {
    let selectedIndex = (currentPage - 1) * perPage + clickedIndex;
    let changeList = currentPageList.map((item, i) => {
      if (i == clickedIndex) {
        item.checked.value = !item.checked.value;
        jobDetails[resultJSONKey][selectedIndex].selected = !jobDetails[resultJSONKey][
          selectedIndex
        ].selected;

        if (snSearch == 'people') storeDispatch(setUserJobsSelectUpdated({ ...jobDetails }));
        else storeDispatch(setCompanyJobsSelectUpdated({ ...jobDetails }));
      }

      return item;
    });

    let selectedList = checkHowManySelected();
    if (selectedList.length == currentPageList.length) setAllItemSelected(true);
    else setAllItemSelected(false);

    setCurrentPageList([...changeList]);
  };

  const isItemSelected = clickedIndex => {
    let selectedIndex = (currentPage - 1) * perPage + clickedIndex;
    return jobDetails[resultJSONKey][selectedIndex].selected;
  };

  const selectedjobs = () => jobDetails[resultJSONKey].filter(contact => contact.selected);

  const handleClose = () => {
    setEnrichanchor(null);
  };

  const isReachedEndOfPage = () => {
    if (
      !isSearchGoingOn &&
      jobDetails[resultJSONKey] &&
      currentPage >= jobDetails[resultJSONKey].length / perPage
    )
      return true;

    return false;
  };

  const peopleSearchSubmit = formData => {
    try {
      if (!validateForm(formData)) {
        storeDispatch(displayMessage('Please fill up at least one field'));
      } else {
        emptyPeopleStoreData();
        storeDispatch(clearSNCompanyTaskResult());

        //Check if type T1 or T2
        let taskType = findSearchType(formData);

        if (taskType == 'T1') submitUserSearch_t1(formData);
        else submitUserSearch_t2(1, formData); //page 1

        handleFormClose();
      }
    } catch (error) {
      console.log(error.message);
      storeDispatch(displayMessage('Something went wrong'));
    }
  };

  const companySearchSubmit = formData => {
    try {
      if (!validateForm(formData)) {
        storeDispatch(displayMessage('Please fill up at least one field'));
      } else {
        /*  else if(isEmpty(userDetails) 
            || isEmpty(companyDetails) 
            || peopleListNames.length  ==  0 
            || companyListNames.length  == 0){

            } */
        setUserClickedSearchFirstTime(true);
        emptyCompanyStoreData();
        storeDispatch(clearSNPeopleTaskResult());

        //Check if type T1 or T2
        let taskType = findSearchType(formData);

        if (taskType == 'T1') submitCompanySearch_t1(formData);
        else submitCompanySearch_t2(1, formData); //page 1

        handleFormClose();
      }
    } catch (error) {
      console.log(error.message);
      storeDispatch(displayMessage('Something went wrong'));
    }
  };

  //Atleast one should be filled up
  const validateForm = formData => {
    let keys = Object.keys(formData);
    let found = keys.filter(
      item =>
        (formData[item].hasOwnProperty('chip') && formData[item].chip.chipData.length > 0) ||
        (!formData[item].hasOwnProperty('chip') && formData[item].value != '')
    );

    if (found.length > 0) return true;
    return false;
  };

  const findSearchType = formData => {
    console.log(formData);
    let keys = Object.keys(formData);
    let found = keys.filter(
      item =>
        formData[item].atleastOne == 'T1' &&
        ((formData[item].hasOwnProperty('chip') && formData[item].chip.chipData.length > 0) ||
          formData[item].value != '')
    );

    if (found.length > 0) return 'T1';

    return 'T2';
  };

  const emptyPeopleStoreData = () => {
    unSelectAllList();
    setCurrentPage(1);
    setCounter(0);
    setSearchType('');
    setSearch('');
    //which resets snDisplayList component
    setSNSearch('');
    storeDispatch(clearSNPeopleTaskResult());
  };

  const emptyCompanyStoreData = () => {
    unSelectAllList();
    setCurrentPage(1);
    setCounter(0);
    setSearchType('');
    setSearch('');
    //which resets snDisplayList component
    setSNSearch('');
    storeDispatch(clearSNCompanyTaskResult());
  };

  const submitUserSearch_t1 = formData => {
    let searchInput = {
      type: 'T1',
      mode: 'People',
      // "pid": 8,
      peopleSearch: {
        companyName: formData.companyName.chip.chipData.map(item => item.name) || [],
        size: formData.companySize.chip.chipData.map(item => item.name) || [],
        industry: formData.industry.chip.chipData.map(item => item.name) || [],
        keywords: formData.keywords.chip.chipData.map(item => item.name) || [],
        revenue: formData.revenue.chip.chipData.map(item => item.name) || [],
        title: formData.titles.chip.chipData.map(item => item.name) || [],
        personName: formData.personName.value,
        RecRT: formData.changeJob.value,
        page: 1,
        maxPagesRead: salesNavMaxPageScrape, //req only for T1
        // "Gd": "in",
        searchString: formData.developerInput.value,
      },
      errorTrace: {},
    };

    console.log(searchInput);
    setSearchType('T1');
    setSearch('people');
    storeDispatch(salesNavUserScrapeSubmit(searchInput));
  };

  const submitUserSearch_t2 = (page, formData) => {
    let searchInput;
    /***
     * From page 2 for t2 we have to send the payload with a change of page param
     */
    if (!formData) {
      // undefined
      snp_searchDetails.payloadForm.peopleSearch.page = page;
      searchInput = snp_searchDetails.payloadForm;
    } else
      searchInput = {
        type: 'T2',
        mode: 'People',
        // "pid": 10,
        peopleSearch: {
          companyName: formData.companyName.chip.chipData.map(item => item.name) || [],
          // "size": formData.companySize.chip.chipData,
          // "industry": formData.industry.chip.chipData,
          keywords: formData.keywords.chip.chipData.map(item => item.name) || [],
          // "revenue": formData.revenue.chip.chipData,
          title: formData.titles.chip.chipData.map(item => item.name) || [],
          personName: formData.personName.value,
          // "RecRT": formData.changeJob.value,
          page: page,
          maxPagesRead: 1, //req only for T1
          // "Gd": "in",
          searchString: formData.developerInput.value,
        },
        errorTrace: {},
      };

    console.log(searchInput);
    setSearchType('T2');
    setSearch('people');
    storeDispatch(salesNavUserScrapeSubmit(searchInput));
  };

  const submitCompanySearch_t1 = formData => {
    let searchInput = {
      type: 'T1',
      mode: 'Company',
      // "pid": 9,
      companySearch: {
        companyName: formData.companyName.chip.chipData.map(item => item.name) || [],
        size: formData.companySize.chip.chipData.map(item => item.name) || [],
        industry: formData.industry.chip.chipData.map(item => item.name) || [],
        keywords: formData.keywords.chip.chipData.map(item => item.name) || [],
        revenue: formData.revenue.chip.chipData.map(item => item.name) || [],
        sizeExpansion: growthSize(formData.growthSignal.value) || {},
        searchString: formData.developerInput.value,
        page: 1,
        maxPagesRead: salesNavMaxPageScrape, //req only for T1
      },

      errorTrace: {},
    };

    console.log(searchInput);
    setSearchType('T1');
    setSearch('company');
    storeDispatch(salesNavCompanyScrapeSubmit(searchInput));
  };

  //TO-DO - change it to array later
  const getCompanyT2Size = formData => {
    let sizes = formData.companySize.chip.chipData.map(item => item.name);
    if (sizes) return sizes[0];
    else return '';
  };

  const submitCompanySearch_t2 = (page, formData) => {
    let searchInput;

    /***
     * From page 2 for t2 we have to send the payload with a change of page param
     */
    if (!formData) {
      // undefined
      snc_searchDetails.payloadForm.companySearch.page = page;
      searchInput = snc_searchDetails.payloadForm;
    } else {
      let companySearch = {
        size: formData.companySize.value || undefined,
        city: formData.city.value || undefined,
        state: formData.state.value || undefined,
        country: formData.country.value || undefined,
        page: page,
      };
      if (formData.technology.chip.chipData.length !== 0) {
        companySearch['technology'] = formData.technology.chip.chipData.map(item => item.name);
      }
      if (formData.keywords.chip.chipData.length !== 0) {
        companySearch['keywords'] = formData.keywords.chip.chipData.map(item => item.name);
      }
      if (formData.industry.chip.chipData.length !== 0) {
        companySearch['industry'] = formData.industry.chip.chipData.map(item => item.name);
      }
      searchInput = {
        type: 'T2',
        mode: 'Company',
        companySearch,
      };
    }

    console.log(searchInput);
    setSearchType('T2');
    setSearch('company');
    storeDispatch(salesNavCompanyScrapeSubmit(searchInput));
  };

  /*
    *
   hide message
    */
  useEffect(() => {
    return () => {
      storeDispatch(hideMessage());
      storeDispatch(hideContactMessage());
    };
  }, []);

  useEffect(() => {
    if (showMessage)
      // setTimeout(() => {
      storeDispatch(hideMessage());
    storeDispatch(hideContactMessage());
    // }, 100);
  }, [showMessage, contactsShowMessage]);

  useEffect(() => {
    console.log(snp_searchDetails);

    if (!isEmpty(snp_searchDetails) && !isEmpty(snc_searchDetails))
      snp_searchDetails.timeStamp > snc_searchDetails.timeStamp
        ? setSNSearch('people')
        : setSNSearch('company');
    else if (isEmpty(snp_searchDetails) && !isEmpty(snc_searchDetails)) setSNSearch('company');
    else if (!isEmpty(snp_searchDetails) && isEmpty(snc_searchDetails)) setSNSearch('people');
  }, [snp_searchDetails, snc_searchDetails]);

  useEffect(() => {
    if (loader && searchType != '' && search != '') {
      stopProgress();
      console.log(isLoadingFirstPage());
      let expectedTime = expectedAPITime;

      if (isLoadingFirstPage())
        //first page expected time
        searchType == 'T1' ? (expectedTime = T1_page1) : (expectedTime = T2_page1);
      //from second page
      else searchType == 'T1' ? (expectedTime = T1_page2) : (expectedTime = T2_page2);

      setexpectedAPITime(expectedTime);

      let counterCopy = 0;
      window.progressInterval = setInterval(() => {
        counterCopy = startProgress(counterCopy, expectedTime);
      }, 1000);
    } else stopProgress();
  }, [loader, searchType, search]);

  const startProgress = (counter, expectedTime) => {
    if (counter >= expectedTime) {
      stopProgress();
    } else {
      counter += 1;
      setCounter(counter);
    }
    return counter;
  };

  const stopProgress = () => {
    if (window.progressInterval) window.clearInterval(window.progressInterval);
    window.progressInterval = undefined;
    // setProgressBar(0);
  };

  const timeLeftToLoadAPI = () => {
    // let timeLeft = expectedAPITime - Math.round(progressBar/(100/expectedAPITime));
    let timeLeft = expectedAPITime - counter;
    if (timeLeft > 0) return `${timeLeft} seconds left...`;
    //if(isExepctAPITimeOver)
    else return "It's taking more time than usual";
  };

  const showError = message => {
    storeDispatch(displayMessage(message));
  };

  const handleTabChange = (event, value) => {
    // setSelectedCompanyNames([]);
    if (companyNamesBatchList.length < maxAllowedChips)
      storeDispatch(companyNamesBatchListClear([]));
    setTabpos(value);
  };

  const handleFormClose = () => {
    setShowNavForm(false);
  };

  const noResultFound = () => {
    if (
      snSearch == 'people' &&
      !isEmpty(snp_searchDetails) &&
      !isEmpty(snp_jobResult) &&
      snp_jobResult.peopleJSON.length > 0
    )
      return false;
    else if (
      snSearch == 'company' &&
      !isEmpty(snc_searchDetails) &&
      !isEmpty(snc_jobResult) &&
      snc_jobResult.companyJSON.length > 0
    )
      return false;
    else if (!isEmpty(snp_searchDetails) || !isEmpty(snc_searchDetails)) return true;

    return false;
  };

  const isLoadingFirstPage = () => {
    if (
      (search == 'people' &&
        !isEmpty(snp_searchDetails) &&
        snp_searchDetails.searchIterationsDoneCount == 0) ||
      (search == 'company' &&
        !isEmpty(snc_searchDetails) &&
        snc_searchDetails.searchIterationsDoneCount == 0)
    )
      //1 page
      return true;
    else if (
      (search == 'people' && isEmpty(snp_searchDetails)) ||
      (search == 'company' && isEmpty(snc_searchDetails))
    )
      return true;

    return false;
  };

  const downloadFile = () => {
    handleDownloadSearchCSV(selectedjobs(), snSearch);
  };

  const open = Boolean(enrichanchor);
  const popoverId = open ? 'simple-popover' : undefined;

  /**Enrichment is only for people search */
  const handleEnrich1 = () => {
    handleClose();
    let selectedforEnrich = jobDetails[resultJSONKey].map(job => {
      if (
        currentPageList.findIndex(
          selectedJob =>
            selectedJob.checked.value &&
            job.id == selectedJob.id.value &&
            job.enrichmentDetails.isEnrichment_E1_GoingOn != STARTED &&
            job.enrichmentDetails.isEnrichment_E1_GoingOn != DONE &&
            job.enrichmentDetails.isEnrichment_E2_GoingOn != STARTED &&
            job.enrichmentDetails.isEnrichment_E2_GoingOn != DONE
        ) > -1
      ) {
        job = { ...job };
        job.enrichmentDetails.enrichmentType = 'E1';
        job.enrichmentDetails.isEnrichment_E1_GoingOn = SELECTED;
      }

      return job;
    });

    //contains all records
    console.log('handleEnrich1', selectedforEnrich);

    //all list with started status
    if (selectedforEnrich.length > 0) {
      setIsEnrichFetchingStarted(true); //flag
      storeDispatch(getEnrich1ResultFromAPI(selectedforEnrich));
    }
  };

  const handleEnrich2 = () => {
    handleClose();
    let selectedforEnrich = jobDetails[resultJSONKey].map(job => {
      if (
        currentPageList.findIndex(
          selectedJob =>
            selectedJob.checked.value &&
            job.id == selectedJob.id.value &&
            job.enrichmentDetails.isEnrichment_E2_GoingOn != STARTED &&
            job.enrichmentDetails.isEnrichment_E2_GoingOn != DONE
        ) > -1
      ) {
        job = { ...job };
        job.enrichmentDetails.enrichmentType = 'E2';

        job.enrichmentDetails.isEnrichment_E2_GoingOn = SELECTED;

        //is E1 is going on or E1 is already done, we don't have to again call E1
        if (
          job.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED ||
          job.enrichmentDetails.isEnrichment_E1_GoingOn == DONE
        ) {
          job.enrichmentDetails.isOnlyE2Required = true;
        } else job.enrichmentDetails.isEnrichment_E1_GoingOn = SELECTED;
      }
      return job;
    });

    //all list with started status
    if (selectedforEnrich.length > 0) {
      console.log('handleEnrich2', selectedforEnrich);
      setIsEnrichFetchingStarted(true); //flag
      storeDispatch(getEnrich2ResultFromAPI(selectedforEnrich));
    }
  };

  //for people and company search
  const handleFind = event => {
    let selectedItems = checkHowManySelected();

    if (selectedItems.length == 0 && snSearch == 'people')
      storeDispatch(displayMessage('Please select at least one contact'));
    else if (selectedItems.length == 0 && snSearch == 'company')
      storeDispatch(displayMessage('Please select at least one contact'));
    /* else if (selectedItems.length > 2 && snSearch == "company")
            storeDispatch(displayMessage("Upto 2 companies are allowed to search at a time")); */ else if (
      snSearch == 'company'
    )
      populateCompanyNamesInSearchForm(selectedItems);
    else setEnrichanchor(event.currentTarget);
  };

  /**If company search table items checked */
  const populateCompanyNamesInSearchForm = selectedItems => {
    let uniqueSelectedItems = [];
    selectedItems.map(item => {
      if (!uniqueSelectedItems.includes(item)) uniqueSelectedItems.push(item);
    });

    //this is used only when user comes from people page to here
    // setSelectedCompanyNames(uniqueSelectedItem);
    storeDispatch(companyNamesBatchListAdd(uniqueSelectedItems));
    setShowNavForm(true);
    setTabpos(0);
  };

  /**More info */
  const copyData = copyObj => {
    navigator.clipboard.writeText(copyObj.value);
    storeDispatch(showSuccessMessage('Copied!!'));
  };

  const handlecloseMoreInfoPopup = () => setOpenMoreInfo(!openMoreInfo);

  const contactNumbersMoreInfo = infoRow => {
    let list = infoRow['Personal Phone'].allValues;
    list = list.map((item, index) => {
      return { name: 'Phone ' + (index + 1), value: item.number };
    });
    setMoreInfoList(list);
  };

  const moreInfo = (infoRow, field) => {
    setOpenMoreInfo(!openMoreInfo);
    setCompanyName(infoRow.Name.value);
    setDesignation(infoRow.Name.hintText);
    switch (field) {
      case 'Personal Phone':
        contactNumbersMoreInfo(infoRow);
        break;
      default:
        console.log('something went wrong');
        break;
    }
  };

  const handleResultListItemClick = (infoRow, field) => {
    let type = infoRow[field].clickType;
    let selectedIndex = infoRow.id.value;
    let enrichDetails;

    switch (type) {
      case 'ReviewEmail':
        //people json
        let contactRecord = jobDetails[resultJSONKey][selectedIndex].contactRecord;

        setReviewEmailIndex(selectedIndex);
        setOpenReviewEmail(!openReviewEmail);

        dispatch({
          type: 'addInput',
          payload: { name: 'firstname', value: contactRecord.firstName },
        });
        dispatch({
          type: 'addInput',
          payload: { name: 'lastname', value: contactRecord.lastName },
        });
        dispatch({ type: 'addInput', payload: { name: 'domain', value: '' } });

        break;

      case 'Enrich1': {
        let peopleJsonSelectedContact = jobDetails[resultJSONKey][selectedIndex];
        console.log(peopleJsonSelectedContact);
        enrichDetails = { ...peopleJsonSelectedContact.enrichmentDetails };
        enrichDetails.enrichmentType = 'E1';
        enrichDetails.isEnrichment_E1_GoingOn = SELECTED;

        jobDetails[resultJSONKey][selectedIndex].enrichmentDetails = enrichDetails;
        setIsEnrichFetchingStarted(true); //flag
        storeDispatch(getEnrich1ResultFromAPI(jobDetails[resultJSONKey]));

        break;
      }

      case 'Enrich2': {
        let peopleJsonSelectedContact = jobDetails[resultJSONKey][selectedIndex];
        console.log(peopleJsonSelectedContact);
        enrichDetails = { ...peopleJsonSelectedContact.enrichmentDetails };
        enrichDetails.enrichmentType = 'E2';
        // enrichDetails.isEnrichment_E1_GoingOn = SELECTED;
        enrichDetails.isEnrichment_E2_GoingOn = SELECTED;

        /* enrichDetails.isOnlyE2Required = (peopleJsonSelectedContact.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED
                    || peopleJsonSelectedContact.enrichmentDetails.isEnrichment_E1_GoingOn == DONE); */

        if (
          peopleJsonSelectedContact.enrichmentDetails.isEnrichment_E1_GoingOn == STARTED ||
          peopleJsonSelectedContact.enrichmentDetails.isEnrichment_E1_GoingOn == DONE
        ) {
          enrichDetails.isOnlyE2Required = true;
        } else enrichDetails.isEnrichment_E1_GoingOn = SELECTED;

        jobDetails[resultJSONKey][selectedIndex].enrichmentDetails = enrichDetails;
        setIsEnrichFetchingStarted(true); //flag
        storeDispatch(getEnrich2ResultFromAPI(jobDetails[resultJSONKey]));

        break;
      }

      default:
        console.log('Click type not found');
        break;
    }
  };

  /**Review popup window */
  const handlecloseReviewPopup = () => setOpenReviewEmail(!openReviewEmail);

  const handleFindReviewBusinessEmail = () => {
    let enrichDetails = {
      ...jobDetails[resultJSONKey][reviewEmailIndex].enrichmentDetails,
    };
    let contactRecords = {
      ...jobDetails[resultJSONKey][reviewEmailIndex].contactRecord,
    };

    enrichDetails.enrichmentType = 'E1';
    enrichDetails.isEnrichment_E1_GoingOn = SELECTED;
    contactRecords.website.domain = state.domain.value;

    jobDetails[resultJSONKey][reviewEmailIndex].enrichmentDetails = enrichDetails;
    jobDetails[resultJSONKey][reviewEmailIndex].contactRecord = contactRecords;

    handlecloseReviewPopup();
    setIsEnrichFetchingStarted(true); //flag
    storeDispatch(getEnrich1ResultFromAPI(jobDetails[resultJSONKey]));
  };

  const HandleListNameChangeDefaultOpen = event => {
    setOpenDefaultListName(!openDefaultListName);
  };

  // const peopleDefaultListName = () => peopleListNames.find(item => item.isDefault == true)
  // const companyDefaultListName = () => companyListNames.find(item => item.isDefault == true)

  const handlecloseOpenDefaultListName = () => setOpenDefaultListName(!openDefaultListName);

  //set default popup
  const handleSetDropDownSelected = listObj => {
    setCreateNewAndSetBool(false);
    setExistingListNameBool(true);
    setExistSetListObj(listObj);
  };

  const handleSetDropDownNewItemCreated = lnm => {
    setCreateNewAndSetBool(true);
    setExistingListNameBool(false);
    setNewSetListName(lnm);
  };

  const cancelDefaultListpopup = () => {
    setCreateNewAndSetBool(false);
    setExistingListNameBool(false);
  };

  const handleSetDefault = () => {
    console.log('handleSetDefault ', existSetListObj);
    setDefaultListName(existSetListObj);
  };

  const handleCreateNewSetDefault = () => {
    console.log('handleCreateNewSetDefault ', newSetListName);
    createListName(newSetListName, true);
    handlecloseOpenDefaultListName();
  };

  //people multi move selcted or
  /**Save company records or move items if already saved */

  const multiSaveOrMoveToSelectedListName = listObj => {
    switch (snSearch) {
      case 'people': {
        let payloadList = [];
        jobDetails[resultJSONKey].map(job => {
          if (
            currentPageList.findIndex(
              selectedJob =>
                selectedJob.checked.value &&
                job.id == selectedJob.id.value &&
                job.identity.recordId != ''
            ) > -1
          ) {
            let payload = {
              recodId: job.identity.recordId,
              cat: job.identity.cat,
              lidFrom: job.identity.lid,
              lidTo: listObj.lid,
              lidToName: listObj.lnm,
              isNew: false,
            };
            payloadList.push(payload);
          }
        });

        console.log('multiSaveOrMoveToSelectedListName ', payloadList);
        if (payloadList.length > 0) storeDispatch(moveContactToList(payloadList));

        setMovepopupOpen(false);
        break;
      }

      case 'company':
        {
          let savePayloadList = [];
          let movePayloadList = [];
          jobDetails[resultJSONKey].map(job => {
            if (
              currentPageList.findIndex(
                selectedJob => selectedJob.checked.value && job.id === selectedJob.id.value
              ) > -1
            ) {
              if (job.identity.recordId === '')
                savePayloadList.push({
                  uid: userDetails.uid,
                  cat: 'company',
                  cid: companyDetails.cid,
                  status: listObj.status,
                  lid: listObj.lid,
                  lnm: listObj.lnm,
                  type: 'user',
                  sync: 'NA',
                  targetSet: {
                    ...job.companyRecord,
                    identity: {
                      ...job.identity,
                      uid: userDetails.uid,
                      cat: 'company',
                      cid: companyDetails.cid,
                      status: listObj.status,
                      lid: listObj.lid,
                      lnm: listObj.lnm,
                      type: 'user',
                      sync: 'NA',
                    },
                  },
                  isNew: false,
                  id: job.id,
                });
              else if (job.identity.recordId !== '')
                movePayloadList.push({
                  recodId: job.identity.recordId,
                  cat: 'company',
                  lidFrom: job.identity.lid,
                  lidTo: listObj.lid,
                  lidToName: listObj.lnm,
                  isNew: false,
                });
            }
          });

          if (savePayloadList.length > 0) storeDispatch(saveToCustomList(savePayloadList));

          if (movePayloadList.length > 0) storeDispatch(moveContactToList(movePayloadList));

          setMovepopupOpen(false);
        }
        break;

      default:
        break;
    }
  };

  //multi move to new list name
  const multiSaveOrMoveToNewListName = lnm => {
    switch (snSearch) {
      case 'people':
        {
          let payloadList = [];
          jobDetails[resultJSONKey].map(job => {
            if (
              currentPageList.findIndex(
                selectedJob =>
                  selectedJob.checked.value &&
                  job.id == selectedJob.id.value &&
                  job.identity.recordId != ''
              ) > -1
            ) {
              let payload = {
                recodId: job.identity.recordId,
                cat: job.identity.cat,
                lidFrom: job.identity.lid,
                lidTo: '',
                lidToName: lnm,
                isNew: true,
              };
              payloadList.push(payload);
            }

            return job;
          });

          console.log('multiSaveOrMoveToNewListName ', payloadList);

          if (payloadList.length > 0) storeDispatch(moveContactToList(payloadList));

          setMovepopupOpen(false);
        }
        break;

      case 'company':
        let savePayloadList = [];
        let movePayloadList = [];
        jobDetails[resultJSONKey].map(job => {
          if (
            currentPageList.findIndex(
              selectedJob => selectedJob.checked.value && job.id === selectedJob.id.value
            ) > -1
          ) {
            if (job.identity.recordId === '')
              savePayloadList.push({
                uid: userDetails.uid,
                cat: 'company',
                cid: companyDetails.cid,
                lid: '',
                lnm: lnm,
                type: 'user',
                sync: 'NA',
                targetSet: {
                  ...job.companyRecord,
                  identity: {
                    ...job.identity,
                    uid: userDetails.uid,
                    cat: 'company',
                    cid: companyDetails.cid,
                    lid: '',
                    lnm: lnm,
                    type: 'user',
                    sync: 'NA',
                  },
                },
                isNew: true,
                id: job.id,
              });
            else if (job.identity.recordId !== '')
              movePayloadList.push({
                recodId: job.identity.recordId,
                cat: job.identity.cat,
                lidFrom: job.identity.lid,
                lidTo: '',
                lidToName: lnm,
                isNew: true,
              });
          }
        });

        if (savePayloadList.length > 0) storeDispatch(saveToCustomList(savePayloadList));

        if (movePayloadList.length > 0) storeDispatch(moveContactToList(movePayloadList));

        setMovepopupOpen(false);
        break;

      default:
        break;
    }
  };

  //move item
  //when selected another listitem from the list..inline
  // for company, inline save and move available
  const handleListItemSelected = (row, selectedListObj) => {
    let selectedIndex = row.id.value;
    let record = jobDetails[resultJSONKey][selectedIndex];
    let identityRow = record.identity;
    console.log('selected row', record);
    console.log('selected list name', selectedListObj);

    //company - save to list or move to list
    if (snSearch == 'company' && identityRow.recordId == '') {
      //save

      let savePayloadObj = {
        uid: userDetails.uid,
        cat: 'company',
        cid: companyDetails.cid,
        status: selectedListObj.status,
        lid: selectedListObj.lid,
        lnm: selectedListObj.lnm,
        type: selectedListObj.type,
        sync: 'true',
        targetSet: {
          ...record.companyRecord,
          identity: {
            uid: userDetails.uid,
            cat: 'company',
            cid: companyDetails.cid,
            status: selectedListObj.status,
            lid: selectedListObj.lid,
            lnm: selectedListObj.lnm,
            type: selectedListObj.type,
            sync: 'NA',
          },
        },
        isNew: false,
      };
      storeDispatch(saveToCustomList([savePayloadObj]));
    } else {
      //people or company record id not empty
      let payload = {
        recodId: identityRow.recordId,
        cat: identityRow.cat,
        lidFrom: identityRow.lid,
        lidTo: selectedListObj.lid,
        lidToName: selectedListObj.lnm,
        isNew: false,
      };

      storeDispatch(moveContactToList([payload]));
    }
  };

  //create and move.. inline
  // for company, inline save and move available
  const handleNewListItemCreated = (row, lnm) => {
    let selectedIndex = row.id.value;
    let record = jobDetails[resultJSONKey][selectedIndex];
    let identityRow = record.identity;
    console.log('selected row', record);
    console.log('selected list name', lnm);

    //company - save to list or move to list
    if (snSearch == 'company' && identityRow.recordId == '') {
      let savePayloadObj = {
        lid: '',
        cat: 'company',
        cid: companyDetails.cid,
        // "status": "done",
        uid: localStorage.getItem('user_id'),
        lnm: lnm,
        type: 'user',
        sync: 'true',
        targetSet: {
          ...record,
          lid: '',
          cat: 'company',
          cid: companyDetails.cid,
          // "status": "done",
          uid: localStorage.getItem('user_id'),
          lnm: lnm,
          type: 'user',
          sync: 'NA',
        },
        isNew: true,
      };

      storeDispatch(saveToCustomList([savePayloadObj]));
    } else {
      let payload = {
        recodId: identityRow.recordId,
        cat: identityRow.cat,
        lidFrom: identityRow.lid,
        lidTo: '',
        lidToName: lnm,
        isNew: true,
      };

      storeDispatch(moveContactToList([payload]));
    }
  };

  const createListName = (listName, makeDef) => {
    let payload = {
      user_id: localStorage.getItem('user_id'),
      cat: snSearch,
      type: 'user',
      lnm: listName,
      makeDefault: makeDef,
    };
    storeDispatch(createCustomListName(payload));
  };

  const setDefaultListName = listObj => {
    let payload = {
      uid: localStorage.getItem('user_id'),
      cat: snSearch,
      lid: listObj.lid,
      // "lnm": listObj.lnm,
    };
    storeDispatch(setDefaultList(payload));
    handlecloseOpenDefaultListName();
  };

  const handleClearBatches = () => {
    storeDispatch(companyNamesBatchListClear([]));
  };

  return (
    <div>
      <div>
        <div>
          <div>
            <div className={classes.box}>
              <div className={classes.top}>
                <div>
                  <h1>Insider's Search</h1>
                  <p>
                    What if you had access to backdoor intelligence in all companies in your TAM
                  </p>
                </div>

                {!loader && currentPageList.length > 0 && (
                  <div style={{ display: 'grid', alignItems: 'end' }}>
                    <div className='d-flex justify-content-end mt-2 mr-3'>
                      {snSearch == 'people' ? (
                        <>
                          <div className='mr-2'>
                            <IconButton
                              aria-describedby={popoverId}
                              className='jr-btn'
                              size='medium'
                              variant='contained'
                              color='primary'
                              aria-label='add'
                              enable_icon={require('assets/images/supervised_user_circle-white-18dp.svg')}
                              disable_icon={require('assets/images/supervised_user_circle-black-18dp.svg')}
                              onClick={handleFind}
                              disabled={checkHowManySelected().length == 0}
                            >
                              <span>Find Bulk</span>
                            </IconButton>
                            <Popover
                              id={popoverId}
                              open={open}
                              anchorEl={enrichanchor}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <Menu
                                id={popoverId}
                                anchorEl={enrichanchor}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                              >
                                <MenuItem onClick={handleEnrich1}>Business Email</MenuItem>
                                <MenuItem onClick={handleEnrich2}>
                                  Business Email and Personal Phone
                                </MenuItem>
                              </Menu>
                            </Popover>
                          </div>

                          <IconButton
                            className='jr-btn'
                            variant='contained'
                            color='primary'
                            enable_icon={require('assets/images/cloud_download-white-18dp.svg')}
                            disable_icon={require('assets/images/cloud_download-black-18dp.svg')}
                            disabled={checkHowManySelected().length == 0}
                            onClick={downloadFile}
                            //     data-for="downloadFileTooltip"
                            //   data-tip="Please select at least one record"
                            //   data-iscapture="true"
                          >
                            <span>Download</span>
                          </IconButton>
                          {/* <InfoTip
            id="downloadFileTooltip"
            place="top"
          /> */}

                          <IconButton
                            className='jr-btn'
                            variant='contained'
                            color='primary'
                            onClick={() => setMovepopupOpen(!movepopupOpen)}
                            enable_icon={require('assets/images/multiple_stop-white-18dp.svg')}
                            disable_icon={require('assets/images/multiple_stop-black-18dp.svg')}
                            disabled={checkHowManySelected().length == 0}
                          >
                            <span>Move To List</span>
                          </IconButton>

                          <IconButton
                            className='jr-btn'
                            size='small'
                            variant='contained'
                            color='primary'
                            aria-label='add'
                            enable_icon={require('assets/images/add-white-18dp.svg')}
                            disable_icon={require('assets/images/add-black-18dp.svg')}
                            onClick={HandleListNameChangeDefaultOpen}
                          >
                            <span>Set Default list</span>
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            className='jr-btn'
                            size='medium'
                            variant='contained'
                            color='primary'
                            onClick={handleFind}
                            enable_icon={require('assets/images/person_search-white-18dp.svg')}
                            disable_icon={require('assets/images/person_search-black-18dp.svg')}
                            disabled={checkHowManySelected().length == 0}
                          >
                            <span>Find Key People</span>
                          </IconButton>
                          <IconButton
                            className='jr-btn'
                            variant='contained'
                            color='primary'
                            enable_icon={require('assets/images/cloud_download-white-18dp.svg')}
                            disable_icon={require('assets/images/cloud_download-black-18dp.svg')}
                            disabled={checkHowManySelected().length == 0}
                            onClick={downloadFile}
                            // data-for="downloadButtonTooltip"
                            // data-tip="Please select at least one record"
                            // data-iscapture="true"
                          >
                            <span>Download</span>
                          </IconButton>

                          {/*  <InfoTip
            id="downloadButtonTooltip"
            place="top"
          /> */}

                          <IconButton
                            className='jr-btn'
                            variant='contained'
                            color='primary'
                            aria-label='add'
                            onClick={() => setMovepopupOpen(!movepopupOpen)}
                            enable_icon={require('assets/images/multiple_stop-white-18dp.svg')}
                            disable_icon={require('assets/images/multiple_stop-black-18dp.svg')}
                            disabled={checkHowManySelected().length == 0}
                          >
                            <span>Save To List</span>
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.bottom}>
                <div className={classes.searchBox}>
                  <Paper className={classes.switchPaper}>
                    <TagsInput
                      showValidationMessage={message => storeDispatch(displayMessage(message))}
                      searchSubmit={companySearchSubmit}
                      previousInput={snc_searchDetails}
                      handleFormClose={handleFormClose}
                      className={classes.form}
                    />
                  </Paper>
                </div>
                {/* resultbox */}
                <div className={classes.resultBox}>
                  <div>
                    {!userClickedSearchFirstTime && !loader && (
                      <div className={classes.beforeSearch}>
                        <img src={require('assets/images/BeforeSearch.svg')} alt={loader} />
                        <p>
                          Find companies that are using a particular technology, belong <br />
                          to particular industry etc. across 10 Million company profiles.
                        </p>
                      </div>
                    )}
                    {/* Loading */}
                    {loader &&
                      (isLoadingFirstPage() ? (
                        <div className={classes.loadingSearch}>
                          <img src={require('assets/images/SearchIllustration.svg')} alt={loader} />
                          <p>Searching across 10 Million companies...</p>
                          <p>{timeLeftToLoadAPI()}</p>
                        </div>
                      ) : (
                        <div className={classes.loadingSearch}>
                          <img src={require('assets/images/SearchIllustration.svg')} alt={loader} />
                          <p>Fetching next batch of records...</p>
                          <p>{timeLeftToLoadAPI()}</p>
                        </div>
                      ))}
                    {/* is not loading and NO data */}
                    {!loader && noResultFound() && (
                      <div className={classes.notFound}>
                        <img
                          style={{
                            width: '100%',
                            maxWidth: '400px',
                            marginBottom: '60px',
                          }}
                          src={SeaBottle}
                          alt='seaBottle'
                        />
                        <h3>
                          No results found for this search criteria. Please try with a <br />{' '}
                          different filter.
                        </h3>
                        <div>
                          <p>
                            Search Tips:
                            <li>Try widening your search with more keywords.</li>
                            <li>Use less filters fields.</li>
                          </p>
                        </div>
                      </div>
                    )}
                    {/* Is not loading and has data */}
                    {!loader && currentPageList.length > 0 && (
                      <div className={classes.resultGrid}>
                        <div className={classes.searchScroll}>
                          <ResultListTable
                            result={currentPageList}
                            showFilter={showFilter}
                            selectionAvailable={true}
                            onAllCheckChange={onAllCheckChange}
                            onindividualCheckChange={onindividualCheckChange}
                            moreInfo={moreInfo}
                            autoCompleteData={
                              snSearch == 'people' ? peopleListNames : companyListNames
                            }
                            itemClick={handleResultListItemClick}
                            autoSelected={handleListItemSelected}
                            autonewItemCreated={handleNewListItemCreated}
                            className={classes.background}
                            resultType='companySearch'
                          />
                        </div>
                        <TablePagination
                          currentPage={currentPage}
                          isreachedEnd={isReachedEndOfPage}
                          handleGoPrevious={handleGoPrevious}
                          handleGoNext={handleGoNext}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Backdrop className={classes.backdrop} open={openReviewEmail}>
            <div className='popup-content'>
              <div className='align-self-end'>
                <h6 className='font-weight-light pointer m-2' onClick={handlecloseReviewPopup}>
                  Close
                </h6>
              </div>
              <div>
                <h3>Please Review Details</h3>
                <TextField
                  id='domain'
                  name='domain'
                  label='Domain'
                  value={state.domain.value}
                  onChange={handleOnInputChange}
                  helperText='Please add company domain. Example: amazon.com'
                  fullWidth
                />
                <TextField
                  id='firstname'
                  name='firstname'
                  label='Fist Name'
                  value={state.firstname.value}
                  helperText='Is this correct? Otherwise please edit'
                  onChange={handleOnInputChange}
                  className='mt-2'
                  fullWidth
                />
                <TextField
                  id='lastname'
                  name='lastname'
                  label='Last Name'
                  value={state.lastname.value}
                  onChange={handleOnInputChange}
                  helperText='Is this correct? Otherwise please edit'
                  className='mt-2'
                  fullWidth
                />
              </div>
              <div className='d-flex justify-content-end mt-4'>
                <IconButton className='jr-btn' size='small' onClick={handlecloseReviewPopup}>
                  <span>Cancel</span>
                </IconButton>
                <IconButton
                  className='jr-btn'
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={handleFindReviewBusinessEmail}
                >
                  <span>Find Business Email</span>
                </IconButton>
              </div>
            </div>
          </Backdrop>

          <Backdrop className={classes.backdrop} open={movepopupOpen}>
            <MoveContactsPopup
              open={movepopupOpen}
              data={snSearch == 'people' ? peopleListNames : companyListNames}
              selected={snSearch == 'people' ? getDefaultPeopleListName : getDefaultCompanyListName}
              closePopup={() => setMovepopupOpen(false)}
              moveToSelectedListName={multiSaveOrMoveToSelectedListName}
              moveToNewListName={multiSaveOrMoveToNewListName}
            />
            )
          </Backdrop>

          <Backdrop className={classes.backdrop} open={openDefaultListName}>
            <div className='popup-content'>
              <div className='align-self-end'>
                <h6
                  className='font-weight-light pointer m-2'
                  onClick={handlecloseOpenDefaultListName}
                >
                  Close
                </h6>
              </div>
              <div className='d-flex flex-column flex-grow-1'>
                <p className='text-dark'>Choose default list name ?</p>
                <form className='flex-grow-1'>
                  <AutoCompleteListName
                    selected={
                      snSearch == 'people'
                        ? getDefaultPeopleListName()
                        : getDefaultCompanyListName()
                    }
                    data={snSearch == 'people' ? peopleListNames : companyListNames}
                    enabled={true}
                    dropDownSelected={handleSetDropDownSelected}
                    dropDownNewItemCreated={handleSetDropDownNewItemCreated}
                  />
                </form>
                <div className='d-flex justify-content-end'>
                  <IconButton
                    className='jr-btn'
                    variant='contained'
                    aria-label='cancel'
                    onClick={cancelDefaultListpopup}
                  >
                    <span>Cancel</span>
                  </IconButton>

                  {existingListNameBool && (
                    <IconButton
                      className='jr-btn'
                      size='small'
                      color='primary'
                      variant='contained'
                      aria-label='set'
                      onClick={handleSetDefault}
                    >
                      <span>Set</span>
                    </IconButton>
                  )}

                  {createNewAndSetBool && (
                    <IconButton
                      className='jr-btn'
                      size='small'
                      color='primary'
                      variant='contained'
                      aria-label='set'
                      onClick={handleCreateNewSetDefault}
                    >
                      <span>Create & Set</span>
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          </Backdrop>

          <Backdrop className={classes.backdrop} open={contactsLoader}>
            <div className='fixContainer'>
              <div className='loader-view'>
                <CircularProgress />
              </div>
            </div>
          </Backdrop>

          {showMessage != '' && alertMessage != '' && NotificationManager.error(alertMessage)}
          {showMessage && infoMessage != '' && NotificationManager.success(infoMessage)}

          {contactsShowMessage != '' &&
            contactsAlertMessage != '' &&
            NotificationManager.error(contactsAlertMessage)}
          {contactsShowMessage &&
            contactsInfoMessage != '' &&
            NotificationManager.success(contactsInfoMessage)}

          <NotificationContainer />
        </div>
      </div>
    </div>
  );
};

const growthSize = growth => {
  switch (growth) {
    case 'Extreme growth (>50% YoY)':
      return {
        min: 50,
        max: 90,
      };
    case 'Moderate growth (25% - 50% YoY)':
      return {
        min: 25,
        max: 50,
      };
    case 'Low growth  (<25% YoY)':
      return {
        min: 25,
        max: 0,
      };

    default:
      return {};
  }
};

export default SalesNavSearchTest;

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: '#f9f8f86b',
  },
  root: {
    flexGrow: 1,
  },
  switchPaper: {},

  searchGif: {
    maxWidth: 70,
  },

  formPaper: {
    padding: '0px',
    margin: 'auto',
    maxWidth: 600,
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  top: {
    padding: '15px 20px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  bottom: {
    display: 'grid',
    gridTemplateColumns: 'minmax(250px,20%) 1fr',
    gridTemplateRows: '80vh',
    gap: '20px',
    height: '100%',
    padding: '20px',
  },
  searchBox: {
    display: 'grid',
    backgroundColor: '#FFFFFF',
    height: '100%',
    width: '100%',
    borderRadius: '5px',
  },
  resultBox: {
    display: 'grid',
    backgroundColor: '#FFFFFF',
    height: '100%',
    width: '100%',
    borderRadius: '5px',
  },
  resultGrid: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
  },
  form: {
    margin: '10px',
    marginLeft: '20px',
    overflowY: 'scroll',
    marginBottom: '90px',
    paddingRight: '10px',
  },
  background: {
    height: '100%',
    width: '100%',
    diplay: 'grid',
    placeContent: 'center',
  },
  beforeSearch: {
    height: '100%',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': { textAlign: 'center' },
    '& img': {
      width: '100%',
      maxWidth: '300px',
    },
  },
  loadingSearch: {
    height: '100%',
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': { color: '#182EEC' },
    '& img': {
      width: '100%',
      maxWidth: '300px',
    },
  },
  searchScroll: {
    overflowY: 'scroll',
    maxHeight: '80vh',
  },
  notFound: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': { textAlign: 'center' },
    '& img': {
      width: '100%',
    },
  },
}));

function TabContainer(props) {
  return <div>{props.children}</div>;
}
