import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  IconButton,
  CircularProgress,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { phoneRegex } from '../util';
import './signUp.css';
import {
  hideMessage,
  showAuthMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn,
  addUserDetails,
} from 'actions/Auth';
import AlertUi from './Msg-UI/AlertUi';
import './authStylings.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { iamRoute, baseIAMUrl } from '../util/urls';

const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '450px 1fr',
    minHeight: '80vh',
    backgroundColor: '#ffff',
    margin: '50px',
  },
  left: {
    display: 'flex',
    backgroundColor: 'rgba(196, 196, 196, 0.12)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '60px 60px 40px',
  },
  right: {
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#fff',
  },
  stepBtn: {
    marginTop: '80px',
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      minWidth: '0px',
    },
  },
  savingsBox: {
    marginTop: '40px',
    padding: '15px 20px',
    width: '100%',
    border: '1px dashed #A6AAAF',
    backgroundColor: '#EEF0FF',
    '& h4': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'black',
      '& span': {
        color: 'blue',
      },
    },
  },
}));

const SignUp = props => {
  const classes = useStyles();
  const maxStep = 2; // starts at 0
  const [formStep, setFormStep] = useState(0);
  const [savings, setSavings] = useState({ accounts: '', lifetime: '' });
  const [showPassOne, setShowPassOne] = useState(false);
  const [showPassTwo, setShowPassTwo] = useState(false);
  const captchaRef = useRef(null);
  // input eye icon handlers
  const handleClickShowPassword = () => {
    setShowPassOne(!showPassOne);
  };
  const handleClickConfShowPassword = () => {
    setShowPassTwo(!showPassTwo);
  };

  const savingsCalculation = () => {
    if (savings.accounts && savings.lifetime) return savings.accounts * savings.lifetime;

    return 6000;
  };

  const initialState = {
    email: { value: '', required: true, message: 'Valid email Id is required' },
    password: {
      value: '',
      min: 6,
      required: true,
      message: 'Valid password is required. Must be at least 6 characters',
    },
    confirmPassword: {
      value: '',
      min: 6,
      required: true,
      message: "Your Passwords don't match. Please try again.",
    },
    fn: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid first name is required. Must be at least 3 characters',
    },
    ln: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid last name is required. Must be at least 3 characters',
    },
    cn: {
      value: '',
      min: 3,
      required: true,
      message: 'Valid company name is required. Must be at least 3 characters',
    },
  };

  const { loader, alertMessage, showMessage, authUser, initURL, userDetailsFailed } = useSelector(
    ({ auth }) => auth
  );
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  // const [signUpInput, setSignUpInput] = useState({})

  function reducer(state, action) {
    switch (action.type) {
      case 'addInput':
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: action.payload.value,
          },
        };
      default:
        throw new Error();
    }
  }

  const handleOnChange = ({ target: { name, value } }) => {
    dispatch({ type: 'addInput', payload: { name, value } });
  };

  const validate = () => {
    let inputAPI = {};
    for (var element in state) {
      if (element === 'confirmPassword') {
        if (state[element].value !== state['password'].value) {
          storeDispatch(showAuthMessage(state[element].message));
          return false;
        }
      }
      if (validInput(state[element])) {
        storeDispatch(showAuthMessage(state[element].message));
        return false;
      }
      if (state[element].value != '' && element !== 'confirmPassword')
        inputAPI[element] = state[element].value;
    }

    return inputAPI;
  };
  const validate1 = () => {
    for (var element in state) {
      if (element === 'confirmPassword') {
        if (state[element].value !== state['password'].value) {
          storeDispatch(showAuthMessage(state[element].message));
          return false;
        } else {
          return true;
        }
      } else if (element === 'email') {
        if (!state[element].value || isDisposableEmail(state[element].value)) {
          storeDispatch(showAuthMessage(state[element].message));
          return false;
        }
      } else if (validInput(state[element]) && element !== 'fn') {
        storeDispatch(showAuthMessage(state[element].message));
        return false;
      }
    }
  };

  const validInput = input => {
    return input.required
      ? input.value == '' || input.value.length < input.min
      : input.value != '' &&
          input.value.length < input.min &&
          (input.hasOwnProperty('regex') ? !input.value.match(input.regex) : true);
  };

  const registerUser = async () => {
    const recaptchaValue = captchaRef.current.getValue();
    if (!recaptchaValue) {
      return storeDispatch(showAuthMessage('Please complete the captcha!'));
    }

    try {
      const res = await fetch(baseIAMUrl + iamRoute + 'verifycaptcha', {
        method: 'POST',
        body: JSON.stringify({ recaptcha: recaptchaValue }),
        headers: { 'Content-Type': 'application/json' },
      });

      if (!res.ok) {
        return storeDispatch(showAuthMessage('something went wrong!'));
      }

      const captchaRes = await res.json();
      if (!captchaRes?.success) {
        return storeDispatch(showAuthMessage('Invalid captcha. Please try again!'));
      }
      let inputObj = validate();
      if (inputObj != false) {
        inputObj.rol = 'Admin';
        inputObj.emailCheck = true;
        // setSignUpInput(inputObj);
        console.log(JSON.stringify(inputObj));
        storeDispatch(showAuthLoader());
        storeDispatch(userSignUp(inputObj));
      }
    } catch (error) {
      if (error) {
        console.log(error);
        return storeDispatch(showAuthMessage('something went wrong!'));
      }
    }
  };

  const handleNext = e => {
    console.log(formStep);
    if (formStep === 0) {
      if (validate1() !== false) setFormStep(formStep + 1);
    } else if (formStep !== maxStep) {
      console.log('validating?');
      if (validate() !== false) setFormStep(formStep + 1);
    } else if (formStep === maxStep) {
      registerUser();
    }
  };
  const handleBack = e => {
    if (formStep !== 0) {
      setFormStep(formStep - 1);
    }
  };

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        storeDispatch(hideMessage());
      }, 4000);
    }
    if (initURL === '/') {
      props.history.push('/');
    }
  }, [showMessage, initURL]);

  /*  useEffect(() => {
   
    console.log("authUser " + authUser);
    if (authUser !== null && userDetailsFailed) {

      storeDispatch(showAuthLoader());
      delete signUpInput.email;
      delete signUpInput.password;
      storeDispatch(addUserDetails(signUpInput));
    }

  }, [authUser]); */

  /*  useEffect(() => {
    console.log("userDetailsFailed ", userDetailsFailed);

    if(!userDetailsFailed){
        props.history.push('/');
    }
  }, [userDetailsFailed])
 */

  useEffect(() => sessionStorage.removeItem('gotoRegister'));

  return (
    <div className='auth-card'>
      <div className={classes.main}>
        <div className={classes.left + ' animated slideInUpTiny animation-duration-3'}>
          {/* Header */}
          <div style={{ marginBottom: '100px' }}>
            <h1
              style={{
                color: '#222F3C',
                fontSize: '32px',
                fontWeight: 'bold',
              }}
            >
              <IntlMessages id='appModule.signup' />
            </h1>
            <p style={{ fontSize: '14px' }}>
              <IntlMessages id='signUp.tagLine' />
            </p>
          </div>
          {/* Form */}
          <div style={{ marginTop: '-100px' }}>
            {showMessage && alertMessage !== '' && (
              <AlertUi variant='error'>{alertMessage}</AlertUi>
            )}
            <form>
              <fieldset>
                {formStep === 0 && (
                  <div className='row m-0'>
                    <TextField
                      type='email'
                      name='email'
                      onChange={handleOnChange}
                      label={<IntlMessages id='appModule.email' />}
                      margin='none'
                      fullWidth
                      required
                      value={state['email'].value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {' '}
                            <img src={require('assets/images/user-icon.svg')} alt='user' />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type={showPassOne ? 'text' : 'password'}
                      name='password'
                      onChange={handleOnChange}
                      label={<IntlMessages id='appModule.password' />}
                      margin='normal'
                      fullWidth
                      required
                      value={state['password'].value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              style={{ padding: '0px', opacity: '0.6' }}
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                            >
                              {showPassOne ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      type={showPassTwo ? 'text' : 'password'}
                      name='confirmPassword'
                      onChange={handleOnChange}
                      label={<IntlMessages id='appModule.confirmPassword' />}
                      margin='normal'
                      fullWidth
                      required
                      value={state['confirmPassword'].value}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              style={{ padding: '0px', opacity: '0.6' }}
                              aria-label='toggle password visibility'
                              onClick={handleClickConfShowPassword}
                            >
                              {showPassTwo ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
                {formStep === 1 && (
                  <div className='row m-0'>
                    <TextField
                      id='firstname'
                      label={<IntlMessages id='signUp.firstName' />}
                      type='text'
                      name='fn'
                      margin='normal'
                      onChange={handleOnChange}
                      fullWidth
                      value={state['fn'].value}
                      required
                    />
                    <TextField
                      id='lastname'
                      label={<IntlMessages id='signUp.lastName' />}
                      name='ln'
                      margin='normal'
                      onChange={handleOnChange}
                      fullWidth
                      value={state['ln'].value}
                      required
                    />
                    <TextField
                      id='companyname'
                      label={<IntlMessages id='signUp.companyName' />}
                      name='cn'
                      margin='normal'
                      onChange={handleOnChange}
                      fullWidth
                      value={state['cn'].value}
                      required
                    />
                  </div>
                )}

                {formStep === 2 && (
                  <div className='row m-0'>
                    <TextField
                      className='fix-input'
                      type='number'
                      name='accounts'
                      onChange={e => setSavings({ ...savings, accounts: e.target.value })}
                      label={'Your target monthly quota (no of accounts)'}
                      margin='normal'
                      fullWidth
                      required
                      value={savings.accounts}
                      placeholder='8'
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      type='number'
                      name='lifetime'
                      onChange={e => setSavings({ ...savings, lifetime: e.target.value })}
                      label={'Lifetime value of account'}
                      margin='normal'
                      fullWidth
                      required
                      value={savings.lifetime}
                      placeholder='5000'
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>USD</InputAdornment>,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <div className={classes.savingsBox} style={{ marginBottom: '10px' }}>
                      <h4>
                        You have a potential of adding <br /> <span>{savingsCalculation()}</span>{' '}
                        USD
                      </h4>
                    </div>
                    {/* Recaptcha */}
                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    />
                  </div>
                )}
              </fieldset>
            </form>
            <div className={classes.stepBtn}>
              {Boolean(formStep) && (
                <Button onClick={handleBack} size='small' color='grey' variant='contained'>
                  <KeyboardArrowLeft />
                </Button>
              )}
              <Button
                style={{ marginLeft: 'auto' }}
                onClick={handleNext}
                size='small'
                color='primary'
                variant='contained'
              >
                {loader ? (
                  <CircularProgress
                    color='inherit'
                    size={'0.875rem'}
                    style={{ margin: '5px 15px' }}
                  />
                ) : formStep === maxStep ? (
                  <IntlMessages id='appModule.signup' />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            </div>
          </div>
          {/* Foot */}
          <div>
            <h5>
              <Link to='/signin'>
                <IntlMessages id='signUp.alreadyMember' />
              </Link>
            </h5>
          </div>
        </div>
        <div className={classes.right}>
          {/* <h2
            style={{
              color: "#717171",
              fontSize: "22px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Discover Accounts, Score and Qualify <br /> leads with us
          </h2> */}
          {/* <img width='70%' src={require('assets/images/conveyer-belt.png')} alt='conveyer' /> */}
        </div>
      </div>
    </div>
  );
};

const isDisposableEmail = e =>
  [
    'obscur.io',
    'vusra.com',
    'oceore.com',
    'krunsea.com',
    'nzaif.com',
    'musezoo.com',
    'kellychibale-researchgroup-uct.com',
    'fxcoral.biz',
    'syswift.com',
    'tagbert.com',
  ].includes(e.split('@')[1]);

export default SignUp;
