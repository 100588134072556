import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/styles';
import deleteImg from '../../assets/images/deleteIcon.svg';

const useStyles = makeStyles(theme => ({
  emptyTags: {
    margin: theme.spacing(1, 0),
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '4px',
    margin: theme.spacing(1, 0),
  },
  chip: {
    background: '#eef0ff',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    padding: '3px',

    '& .MuiChip-label': {
      paddingLeft: '6px',
      paddingRight: '6px',
    },
  },
}));

const TagsView = ({ state, inputName, deleteChip, type = '' }) => {
  const classes = useStyles();
  let tags;

  switch (type) {
    case 'keywords':
      tags = state[inputName]?.arr;
      if (!tags || tags.length === 0) {
        return <div className={classes.emptyTags}></div>;
      }

      return (
        <div className={classes.tagsWrapper}>
          {tags.map(data => {
            return (
              <Chip
                variant='outlined'
                className={classes.chip}
                label={data}
                key={data}
                onDelete={() => deleteChip(inputName, data)}
                deleteIcon={<DeleteIcon name={data} onClick={() => deleteChip(inputName, data)} />}
              />
            );
          })}
        </div>
      );
    case 'technology':
      tags = state[inputName]?.arr;
      if (!tags || tags.length === 0) {
        return <div className={classes.emptyTags}></div>;
      }

      return (
        <div className={classes.tagsWrapper}>
          {tags.map(data => {
            return (
              <Chip
                variant='outlined'
                className={classes.chip}
                label={data}
                key={data}
                onDelete={() => deleteChip(inputName, data)}
                deleteIcon={<DeleteIcon name={data} onClick={() => deleteChip(inputName, data)} />}
              />
            );
          })}
        </div>
      );
    case '':
    default:
      tags = state[inputName]?.chip?.chipData;
      if (!tags || tags.length === 0) {
        return <div className={classes.emptyTags}></div>;
      }

      return (
        <div className={classes.tagsWrapper}>
          {tags.map(data => {
            return (
              <Chip
                variant='outlined'
                className={classes.chip}
                label={data.name || data.Description}
                key={data.key}
                onDelete={data => deleteChip(inputName, data)}
                deleteIcon={
                  <DeleteIcon
                    name={data.name || data.Description}
                    onClick={data => deleteChip(inputName, data)}
                  />
                }
              />
            );
          })}
        </div>
      );
  }
};

export default TagsView;

const DeleteIcon = ({ name, onClick }) => (
  <img alt={`Delete ${name}`} src={deleteImg} onClick={onClick} style={{ cursor: 'pointer' }} />
);
