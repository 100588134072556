import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import asyncComponent from '../../util/asyncComponent';
import { withRouter } from 'react-router';
import UserProfile from './userProfile';
import InvitedUsers from './invitedUsers';
import ManageUsers from './manageUsers';
import ManageProfile from './manageProfile';
import Settings from './settings';
import Help from './help';
import People from './people';
import Company from './company';
import Pricing from './pricingPage';
import PaymentCheckout from './paymentCheckout';

import Integration from './integration';
import DummySearch from './dummysearch';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import EditCrmMapFields from './editCrmMapFields';
import Search from './search';
import IntentTracker from './IntentTracker/IntentTracker';
import SuccessOrErrScreen from './pricingPage/SuccessOrErrScreen';
import Error404 from './ErrorPages/404';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Routes = ({ match, tierRoutes }) => (
  <Switch>
    {tierRoutes.routes.includes("search") && <Route path={`${match.url}/home`} component={Search} />}
    <Route path={`${match.url}/people`} component={People} />
    <Route path={`${match.url}/company`} component={Company} />
    <Route path={`${match.url}/settings`} component={Settings} />
    <Route path={`${match.url}/user-profile`} component={UserProfile} />
    <Route path={`${match.url}/invitedUsers`} component={InvitedUsers} />
    <Route path={`${match.url}/manageUsers`} component={ManageUsers} />
    <Route path={`${match.url}/manageProfile`} component={ManageProfile} />
    {tierRoutes.routes.includes("crm-integration") && <Route path={`${match.url}/integration`} component={Integration} />}
    <Route path={`${match.url}/edit-map-fields`} component={EditCrmMapFields} />
    <Route path={`${match.url}/help`} component={Help} />
    {tierRoutes.routes.includes("intent-tracker") && <Route path={`${match.url}/intentTracker`} component={IntentTracker} />}
    <Route path={`${match.url}/pricing`} component={Pricing} />
    <Route
      path={`${match.url}/payment-success`}
      component={() => <SuccessOrErrScreen success={true} />}
    />
    <Route
      path={`${match.url}/payment-failure`}
      component={() => <SuccessOrErrScreen success={false} />}
    />
    <Route path={`${match.url}/search`} component={DummySearch} />
    <Route path={`${match.url}/404`} component={Error404} />
    {/* <Elements stripe={stripePromise}>
      <Route path={`${match.url}/payment-checkout`} component={PaymentCheckout} />
    </Elements> */}
    {/* <Route path={`${match.url}/dashboard`} component={Dashboard} /> */}
    {/* <Route path={`${match.url}/list`} component={List} /> */}
    {/* <Route path={`${match.url}/list-home`} component={Home} /> */}
    {/* <Route path={`${match.url}/upgrade`} component={Upgrade} /> */}
    {/* <Route path={`${match.url}/dummysearch`} component={SalesNavSearchTest} /> */}
    {/* <Route path={`${match.url}/custom-views`} component={CustomViews} /> */}

    {/* <Route path={`${match.url}/dsUserInput`} component={DSUserInput} /> */}
    {/* <Route path={`${match.url}/dsUserOutput`} component={DSUserOutput} /> */}
    {/* <Route path={`${match.url}/salesNavUserSearch`} component={SalesNavUserSearch} /> */}
    {/* <Route path={`${match.url}/salesNavCompanySearch`} component={SalesNavCompanySearch} /> */}
    {/* <Route path={`${match.url}/home`} component={SalesNavSearch} /> */}
    {/* <Route path={`${match.url}/dsOutputResultDetails`} component={DSOutputResultDetails} /> */}
    {/* <Route path={`${match.url}/upload`} component={Upload} /> */}
    {/* <Route path={`${match.url}/csvResult`} component={CSVResult} /> */}
    {/* <Route path={`${match.url}/social-apps`} component={SocialApps} /> */}
    {/* <Route path={`${match.url}/components`} component={Components} /> */}
    {/* <Route path={`${match.url}/icons`} component={Icons} /> */}
    {/* <Route path={`${match.url}/form`} component={Form} /> */}
    {/* <Route path={`${match.url}/editor`} component={Editors} /> */}
    {/* <Route path={`${match.url}/pickers`} component={Pickers} /> */}
    {/* <Route path={`${match.url}/extensions`} component={Extensions} /> */}
    {/* <Route path={`${match.url}/table`} component={Table} /> */}
    {/* <Route path={`${match.url}/chart`} component={Chart} /> */}
    {/* <Route path={`${match.url}/map`} component={Map} /> */}
    {/* <Route path={`${match.url}/calendar`} component={Calendar} /> */}
    {/* <Route path={`${match.url}/time-line`} component={TimeLine} /> */}

    {/* <Route path={`${match.url}/widgets`} component={Widgets} /> */}
    {/* <Route path={`${match.url}/metrics`} component={Metrics} /> */}
    {/* <Route path={`${match.url}/extra-elements`} component={ExtraElements} /> */}
    {/* <Route path={`${match.url}/ecommerce`} component={eCommerce} /> */}
    {/* <Route path={`${match.url}/app-module`} component={AppModule} /> */}
    {/* <Route path={`${match.url}/menu-levels`} component={MenuLevels} /> */}
    {/*  <Route path={`${match.url}/to-do`}
                     component={asyncComponent(() => import("./todo/basic"))} />
              <Route path={`${match.url}/to-do-redux`}
                     component={asyncComponent(() => import("./todo/redux"))} />
              <Route path={`${match.url}/mail`}
                     component={asyncComponent(() => import("./mail/basic"))} />
              <Route path={`${match.url}/mail-redux`}
                     component={asyncComponent(() => import("./mail/redux"))} />
              <Route path={`${match.url}/chat`}
                     component={asyncComponent(() => import("./chatPanel/basic"))} />
              <Route path={`${match.url}/chat-redux`}
                     component={asyncComponent(() => import("./chatPanel/redux"))} />
              <Route path={`${match.url}/contact`}
                     component={asyncComponent(() => import("./contact/basic"))} />
              <Route path={`${match.url}/contact-redux`}
                     component={asyncComponent(() => import("./contact/redux"))} /> */}
    <Redirect to={`${match.url}/404`} />
  </Switch>
);

export default withRouter(Routes);
